const POST_CODE_PATTERN = '^[0-9]{5}';
const PHONE_PATTERN = '^[(+]{0,1}([0-9]+)?([ -]?([0-9]+( [0-9]+)*)[ -]?)?[0-9]+([ -/][0-9]+)*$';
const EMAIL_PATTERN =
  '^(([a-zA-Z0-9!#$%&\'*+\\/=?^_~-]+(\\.[a-zA-Z0-9!#$%&\'*+\\/=?^_~-]+)*)|(\\".+\\"))@(([a-zA-Z0-9\\-]+\\.)+[a-zA-Z0-9\\-]{2,})$';

const EMAIL_REG_EXP = RegExp(
  /^(([a-zA-Z0-9!#$%&'*+\/=?^_~-]+(\.[a-zA-Z0-9!#$%&'*+\/=?^_~-]+)*)|(\".+\"))@(([a-zA-Z0-9\-]+\.)+[a-zA-Z0-9\-]{2,})$/i, // eslint-disable-line no-useless-escape
);

const BIC_REG_EXP = RegExp(/^[A-Za-z]{6}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/);

const PHONE_REG_EXP = RegExp(/^[(+]{0,1}([0-9]+)?([ -]?([0-9]+( [0-9]+)*)[ -]?)?[0-9]+([ -/][0-9]+)*$/);

const TEXT_FIELD_REG_EXP = RegExp(
  /(^[a-zA-Z0-9\-_/&\s?%;:,=!#@^.+\\$*()[\]"<>'\176\300-\317\321-\326\331-\335\337-\366\371-\375\377]*$)/,
);

export {
  EMAIL_REG_EXP,
  PHONE_REG_EXP,
  BIC_REG_EXP,
  TEXT_FIELD_REG_EXP,
  POST_CODE_PATTERN,
  PHONE_PATTERN,
  EMAIL_PATTERN,
};
