/* eslint-disable no-underscore-dangle */
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { Components } from '../../../types/models';

const useStyles = makeStyles((theme) =>
  createStyles({
    tableItem: {
      alignItems: 'stretch',

      [theme.breakpoints.down('sm')]: {
        padding: '15px',
        flexBasis: '100%',
      },

      '& .tableHeader': {
        display: 'none',

        [theme.breakpoints.down('sm')]: {
          display: 'block',
        },
      },

      '&.isFirstItem .tableHeader': {
        [theme.breakpoints.up('md')]: {
          display: 'block',
          marginBottom: '10px',
        },
      },

      '&.isFirstItem': {
        [theme.breakpoints.up('md')]: {
          marginBottom: '10px',
        },
      },

      '& .tableRow': {
        [theme.breakpoints.down('sm')]: {
          marginTop: '15px',
        },
      },

      '& .showMd': {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },

      '& .hideMd': {
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
    },
    progress: {
      height: '14px',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        width: '75%',
      },
    },
    button: {
      lineHeight: '1.3',
      textTransform: 'capitalize',
      textAlign: 'left',
      marginLeft: '5px',
      '&:focus': {
        outline: 'none',
      },
      '& .MuiSvgIcon-root': {
        color: '#1b618c',
      },
      [theme.breakpoints.down('sm')]: {
        paddingRight: '0',
      },
    },
    link: {
      cursor: 'pointer',
      color: '#1b618c',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'underline',
        color: '#4e6e61',
      },
    },
  }),
);

const StatusLabelMap: any = {
  DRAFT: 'in Bearbeitung',
  SUBMITTED: 'abgeschlossen',
};

type CreditRequestListItemProps = {
  isFirstItem?: boolean;
  status?: Components.Schemas.EnumCreditRequestStatus;
  requestIdentifier?: string;
  creditAmount?: number;
  currentStep?: Components.Schemas.EnumCreditRequestSteps;
  editAction?: () => void;
  deleteAction?: () => void;
};

const CreditRequestListItem = ({
  status,
  isFirstItem,
  requestIdentifier,
  creditAmount,
  currentStep,
  editAction,
  deleteAction,
}: CreditRequestListItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const currentProgress = (currentStep: Components.Schemas.EnumCreditRequestSteps | undefined) => {
    return (parseInt(currentStep as string, 10) / 5) * 100;
  };

  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      alignItems="center"
      className={classnames(classes.tableItem, { isFirstItem })}
    >
      <Grid container item xs={12} md={2} spacing={2} className="hideMd">
        <Grid item xs={6} md={12} className="tableHeader">
          {' '}
        </Grid>
        {deleteAction && (
          <Grid container item xs={6} md={12} justifyContent="flex-end" alignItems="flex-end">
            <IconButton className={classes.button} aria-label="delete" onClick={deleteAction} data-testid="delete">
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} md={4}>
        <Grid item xs={6} md={12} className="tableHeader">
          <Typography variant="h5">{t('creditRequestList.table.name')}</Typography>
        </Grid>
        <Grid container item xs={6} md={12} alignItems="center" onClick={editAction} data-testid="requestIdentifier">
          <Typography variant="body1">
            <span className={classes.link}>{requestIdentifier || t('creditRequestList.defaultName')}</span>
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={2} className="tableRow">
        <Grid item xs={6} md={12} className="tableHeader">
          <Typography variant="h5">{t('creditRequestList.table.status')}</Typography>
        </Grid>
        <Grid container item xs={6} md={12} alignItems="center" data-testid="status">
          <Typography variant="body1">{status ? StatusLabelMap[status] : ''}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={2} className="tableRow">
        <Grid item xs={6} md={12} className="tableHeader">
          <Typography variant="h5">{t('creditRequestList.table.amount')}</Typography>
        </Grid>
        <Grid container item xs={6} md={12} alignItems="center" data-testid="amount">
          <Typography variant="body1">{creditAmount}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={2} className="tableRow">
        <Grid item xs={6} md={12} className="tableHeader">
          <Typography variant="h5">{t('creditRequestList.table.progress')}</Typography>
        </Grid>
        <Grid container item xs={6} md={12} alignItems="center" data-testid="progress">
          <LinearProgress
            className={classes.progress}
            variant="determinate"
            color="primary"
            value={currentProgress(currentStep)}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} md={2} spacing={2} className="showMd">
        <Grid item xs={6} md={12} className="tableHeader">
          {' '}
        </Grid>
        {deleteAction && (
          <Grid container item xs={6} md={12} justifyContent="flex-end" alignItems="flex-end">
            <IconButton className={classes.button} aria-label="delete" onClick={deleteAction} data-testid="delete">
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CreditRequestListItem;
