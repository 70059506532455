import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormSlider from '../../components/form/FormSlider';
import FormNumberInput from '../../components/form/FormNumberInput';
import FormAutocomplete from '../../components/form/FormAutocomplete';

export default function AdditionalRequestInformation({
  handleUsagePurposeChange,
  usagePurposeOptions,
  usagePurposeDisabled,
}: any) {
  const { t } = useTranslation();

  return (
    <Grid container item className="formSection" data-testid="additionalRequestInformation">
      <Typography variant="h2" className="header">
        {t('oneApp.AdditionalRequestInformation.title')}
      </Typography>
      <Typography variant="body1" className="textContainer">
        {t('oneApp.AdditionalRequestInformation.infoText')}{' '}
      </Typography>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormNumberInput
            name="program.creditAmount"
            className="formItem"
            label={t('oneApp.Program.creditAmount')}
            tooltip={t('oneApp.Program.creditAmountTooltip')}
            thousandSeparators
            decimalScale={2}
            integerScale={13}
            data-testid="creditAmount"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            name="program.usagePurpose"
            className="formItem"
            disabled={usagePurposeDisabled}
            label={t('oneApp.Program.usagePurpose')}
            tooltip={t('oneApp.Program.usagePurposeTooltip')}
            onChange={handleUsagePurposeChange}
            data={usagePurposeOptions}
            data-testid="usagePurpose-autocomplete"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} className="formGroup">
        <Grid item xs={12} md={6}>
          <FormSlider
            name="program.duration"
            min={0}
            max={30}
            step={1}
            label={t('oneApp.AdditionalRequestInformation.duration')}
            tooltip={t('oneApp.AdditionalRequestInformation.durationTooltip')}
            data-testid="duration-select"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormSlider
            name="program.freeYears"
            min={0}
            max={30}
            step={1}
            label={t('oneApp.AdditionalRequestInformation.freeYears')}
            // waiting for the tooltip text to be delivered
            // tooltip={t('oneApp.AdditionalRequestInformation.freeYearsTooltip')}
            data-testid="freeYears-select"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} className="formGroup">
        <Grid item xs={12} md={6}>
          <FormSlider
            name="program.fixedInterestPeriod"
            min={0}
            max={30}
            step={1}
            label={t('oneApp.AdditionalRequestInformation.fixedInterestPeriod')}
            data-testid="fixedInterestPeriod-select"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
