import axiosClient from './axiosClient';
import { Components } from '../../types/models';

export const client = axiosClient();

export const getIKKProductList = async (productType: string): Promise<Components.Schemas.ProductFoeaList> => {
  if (!productType) {
    return new Promise((resolve) => {
      resolve({ data: [] });
    });
  }
  const response = await client.get(
    `/ikk/getProducts?productType=${(productType || '').toLowerCase()}&ikkRelevant=true`,
    {
      withCredentials: false,
    },
  );
  response.data.data
    .sort((a: Components.Schemas.Product, b: Components.Schemas.Product) => (a.productId || 0) - (b.productId || 0))
    .sort((a: Components.Schemas.Product, b: Components.Schemas.Product) => (a.orderFoea || 0) - (b.orderFoea || 0));
  return response.data;
};

export const getIKKProductDetails = async (productId: string): Promise<Components.Schemas.ProductDetailsFoea> => {
  const response = await client.get(`/ikk/getProductDetails?productId=${productId || ''}`, {
    withCredentials: false,
  });
  return response.data;
};
