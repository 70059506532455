import React, { useCallback, useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

import './assets/styles/tailwind.min.css';
import './index.css';
import './i18n';

import PrivateRoute from './common/auth/PrivateRoute';
import Main from './pages/Main';
import OneApp from './pages/oneApp';
import IKK from './pages/ikk';

import { CreditRequestsProvider } from './context/creditRequestContext';

const App = () => {
  const history = createBrowserHistory();
  const { t } = useTranslation();

  const setDocumentTitle = useCallback(
    (pathname: string) => {
      const regex1 = /\/creditrequestsoneapp\/[a-z0-9]*\/summary/;
      const lowerCasePathname = pathname.toLowerCase();

      if (lowerCasePathname === '/oneapp') {
        document.title = t('htmlTitles.oneAppPageTitle');
      } else if (lowerCasePathname === '/oneapp/summary') {
        document.title = t('htmlTitles.oneAppSummaryPageTitle');
      } else if (lowerCasePathname === '/creditrequests') {
        document.title = t('htmlTitles.creditRequests');
      } else if (lowerCasePathname.match(regex1)) {
        document.title = t('htmlTitles.requestDetailPage');
      } else {
        document.title = t('htmlTitles.defaultPageTitle');
      }
    },
    [t],
  );

  useEffect(() => {
    setDocumentTitle(history.location.pathname);
  }, [setDocumentTitle, history.location.pathname]);

  history.listen((location) => {
    setDocumentTitle(location.pathname);
  });

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router history={history}>
        <CreditRequestsProvider>
          <Switch>
            <Route path="/oneApp" component={OneApp} />
            <Route path="/ikk" component={IKK} />
            <PrivateRoute render={() => <Main />} />
          </Switch>
        </CreditRequestsProvider>
      </Router>
    </MuiPickersUtilsProvider>
  );
};

export default App;
