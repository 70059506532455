import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import FormAreaTextField from '../../components/form/FormAreaTextField';
import FormRadioGroup from '../../components/form/FormRadioGroup';
import { validators } from '../../formValidation';

const useStyles = makeStyles(() => ({
  list: {
    marginLeft: '2rem',
    listStyleType: 'disc',
  },
  textarea: {
    marginBottom: '20px',
    width: '100%',
  },
}));

export default function SustainabilityDirective() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [fundedByKfW, setFundedByKfW] = React.useState<any>(null);
  const [permitsObtained, setPermitsObtained] = React.useState<any>(null);

  const yesOrNoLookup: { [key: string]: string } = t('ikk.ProjectDescription.SustainabilityDirective.yesOrNoLookup', {
    returnObjects: true,
  });

  const handleOption1Change = (evt: Event, value: any) => {
    setFundedByKfW(value);
  };

  const handleOption2Change = (evt: Event, value: any) => {
    setPermitsObtained(value);
  };

  return (
    <Grid container item className="formSection" data-testid="sustainability">
      <Typography variant="h3" className="header">
        {t('ikk.ProjectDescription.SustainabilityDirective.title')}
      </Typography>
      <Typography variant="body1">{t('ikk.ProjectDescription.SustainabilityDirective.infoText1')}</Typography>
      <ul className={classes.list}>
        <li>
          <Typography>{t('ikk.ProjectDescription.SustainabilityDirective.bullet1')}</Typography>
        </li>
        <li>
          <Typography>{t('ikk.ProjectDescription.SustainabilityDirective.bullet2')}</Typography>
        </li>
        <li>
          <Typography>{t('ikk.ProjectDescription.SustainabilityDirective.bullet3')}</Typography>
        </li>
      </ul>
      {/* <Typography variant="body1">{t('ikk.ProjectDescription.SustainabilityDirective.option1')}</Typography> */}
      <FormRadioGroup
        name="project.sustainabilityDirective.fundedByKfW"
        options={yesOrNoLookup}
        label={t('ikk.ProjectDescription.SustainabilityDirective.option1')}
        validate={validators.common.notEmpty}
        errorMsg={t('validation.emptyField')}
        onChange={handleOption1Change}
        defaultValue={fundedByKfW}
        data-testid="sustainabilityDirectiveFundedByKfW"
      />
      {fundedByKfW === 'yes' && (
        <FormRadioGroup
          name="project.sustainabilityDirective.permitsObtained"
          options={yesOrNoLookup}
          label={t('ikk.ProjectDescription.SustainabilityDirective.option2')}
          validate={validators.common.notEmpty}
          errorMsg={t('validation.emptyField')}
          onChange={handleOption2Change}
          defaultValue={permitsObtained}
          data-testid="sustainabilityDirectivePermitsObtained"
        />
      )}
      {permitsObtained === 'no' && fundedByKfW === 'yes' && (
        <>
          <Typography variant="body1">{t('ikk.ProjectDescription.SustainabilityDirective.option3')}</Typography>
          <FormAreaTextField
            name="project.sustainabilityDirective.reason"
            subscription={{ value: true }}
            required
            minRows={8}
            maxLength={210}
            className={`textarea ${classes.textarea}`}
            data-testid="sustainabilityDirectiveReason"
          />
        </>
      )}
    </Grid>
  );
}
