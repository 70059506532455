import React, { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { LoadingButtonContainer } from 'components/LoadingButton';
import { makeStyles } from '@material-ui/core';

interface ConfirmationModalProps extends DialogProps {
  onCancel: () => void;
  onConfirm: () => void;
  header?: string | JSX.Element;
  id: string;
  content: string | JSX.Element;
  confirmButton?: string;
  cancelButton?: string;
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    '& .MuiDialogActions-root': {
      marginTop: theme.spacing(2),

      '@media (max-width:375px)': {
        flexDirection: 'column',

        '&.MuiDialogActions-spacing > :not(:first-child)': {
          margin: '10px 0 0 0',
        },
      },
    },
  },
}));

export default function C({
  onCancel,
  onConfirm,
  header,
  id,
  open,
  content,
  confirmButton,
  cancelButton,
  className,
  ...props
}: ConfirmationModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const classes = useStyles();
  const { t } = useTranslation();

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await onConfirm();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog id={id} onClose={onCancel} open={open} {...props} className={classnames(classes.dialog, className)}>
      {header && (
        <DialogTitle disableTypography>
          {typeof header === 'string' ? (
            <Typography align="center" variant="h3">
              {header}
            </Typography>
          ) : (
            header
          )}
        </DialogTitle>
      )}
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <LoadingButtonContainer
          onClick={onCancel}
          color="secondary"
          text={cancelButton || t('modals.confirmationModal.cancel')}
        />

        <LoadingButtonContainer
          onClick={handleConfirm}
          loading={isLoading}
          color="primary"
          text={confirmButton || t('modals.confirmationModal.confirm')}
        />
      </DialogActions>
    </Dialog>
  );
}
