import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'relative',
    display: 'block',
    backgroundColor: theme.palette.grey[300],
    height: '70px',
  },
  container: {
    position: 'relative',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    backgroundColor: theme.palette.grey[300],
  },
  boxContainer: {
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  box: {
    display: 'block',
    position: 'relative',
    minWidth: '0',
    padding: '2rem 0',

    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '760px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '760px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1024px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1280px',
    },

    overflowWrap: 'break-word',
    // TODO: use theme
    backgroundColor: 'white',
    width: '100%',
    marginBottom: '1.5rem',
  },
  children: {
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
  },
}));

interface Props {
  children: React.ReactNode;
  justify?: 'space-around' | 'space-between' | 'space-evenly' | 'center' | 'flex-end' | 'flex-start' | undefined;
}

function Layout({ children, justify }: Props) {
  const classes = useStyles();

  return (
    <>
      <section id="header" className={classes.header} />
      <section className={classes.container}>
        <Grid container justifyContent={justify} className={classes.boxContainer}>
          <Grid container direction="column" className={classes.box}>
            <div className={classes.children}>{children}</div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}

Layout.defaultProps = {
  justify: 'center',
};

export default Layout;
