import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormTextField from '../../components/form/FormTextField';
import { validators } from '../formValidation';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: '45px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '38px',
      paddingBottom: '20px !important',
    },
  },
  list: {
    marginLeft: '2rem',
    listStyleType: 'disc',
  },
  requestIdentifierWrapper: {
    width: '100%',
  },
  requestIdentifier: {
    width: '100%',
  },
  requestDetailsWrapper: {
    marginBottom: '-100px',
    visibility: 'hidden',
  },
  inputInfoText: {
    padding: '20px 0 10px 0',
    width: '100%',
  },
}));

const INPUT_PROPS = {
  requestIdentifier: {
    maxLength: 255,
  },
};

export default function GeneralRequestInformation() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container className="formSection">
      <Typography variant="h1" className={classes.pageTitle} role="heading">
        {t('ikk.title')}
      </Typography>
      <Typography variant="h2" className="header">
        {t('ikk.CommunalRequest.title')}
      </Typography>

      <Typography variant="body1">{t('ikk.CommunalRequest.infoText1')}</Typography>
      <ul className={classes.list}>
        <li>
          <Typography>{t('ikk.CommunalRequest.infoText2')}</Typography>
        </li>
        <li>
          <Typography>{t('ikk.CommunalRequest.infoText3')}</Typography>
        </li>
        <li>
          <Typography>{t('ikk.CommunalRequest.infoText4')}</Typography>
        </li>
        <li>
          <Typography>{t('ikk.CommunalRequest.infoText5')}</Typography>
        </li>
      </ul>
      <br />

      <Grid container className={classes.requestIdentifierWrapper}>
        {/* honeypot field */}
        <Grid container className={classes.requestDetailsWrapper}>
          <Grid item xs={12} md={6}>
            <FormTextField
              skipDefaultValidation
              name="requestInformation.requestDetails"
              label={t('ikk.requestInformation.requestDetails')}
              required
              validate={validators.requestInformation.requestDetails}
              data-testid="requestDetails"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
