import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import Grid from '@material-ui/core/Grid';

import FormSwitch from '../../components/form/FormSwitch';

export default function Foreclosure() {
  const { t } = useTranslation();
  const form = useForm();

  useEffect(() => {
    form.change('requestInformation.budgetEmergency', false);
    form.change('requestInformation.foreclosureMeasures', false);
  }, []);

  return (
    <Grid container item className="subSubSection" data-testid="Foreclosure">
      <Grid container>
        <FormSwitch
          name="requestInformation.budgetEmergency"
          label={t('ikk.ApplicantSelection.Foreclosure.budgetEmergency')}
          data-testid="budgetEmergency"
        />
      </Grid>
      <Grid container item xs={12} className="formSwitchSection">
        <FormSwitch
          name="requestInformation.foreclosureMeasures"
          label={t('ikk.ApplicantSelection.Foreclosure.foreclosureMeasures')}
          data-testid="budgetEmergency"
        />
      </Grid>
    </Grid>
  );
}
