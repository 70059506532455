import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { scroller } from 'react-scroll';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Components } from 'types/models';
import { getIKKProductList } from 'common/api/apiClientYggs';
import { validators } from '../../formValidation';
import FormRadioGroup from '../../components/form/FormRadioGroup';
import ProductList from './ProductList';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: '45px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '38px',
      paddingBottom: '20px !important',
    },
  },
  list: {
    marginLeft: '2rem',
    listStyleType: 'disc',
  },
  requestIdentifierWrapper: {
    width: '100%',
  },
  requestIdentifier: {
    width: '100%',
  },
  inputInfoText: {
    padding: '20px 0 10px 0',
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  headline: {
    paddingTop: '0 !important',
  },
}));
interface IProductState {
  products: Components.Schemas.ProductFoeaList | undefined;
  lastProductType: string | undefined;
}
export default function FindProduct({ productType, onProductTypeChange, onSelectedProductChange, urlParams }: any) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [recommendedProducts, setRecommendedProducts] = useState<IProductState>({
    products: undefined,
    lastProductType: undefined,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const scrollToSection = useCallback((id: string) => {
    scroller.scrollTo(id, {
      smooth: true,
    });
  }, []);

  const handleProductTypeChange = (evt: Event, value: any) => {
    (async () => {
      try {
        setIsLoading(true);
        const p = await getIKKProductList(value);
        setRecommendedProducts({
          products: p,
          lastProductType: value,
        });
        setIsLoading(false);
      } catch (err) {
        return null;
      }
    })();

    onProductTypeChange(evt, value);
    setRecommendedProducts({
      products: undefined,
      lastProductType: undefined,
    });
    scrollToSection('product-list');
  };

  const categoryLookup: { [key: string]: string } = t('ikk.FindProduct.categories.categoryLookup', {
    returnObjects: true,
  });

  const prodType = productType || urlParams?.productType;
  const productTypeFromURL = urlParams?.productType;

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const p = await getIKKProductList(productTypeFromURL);
        setRecommendedProducts({
          products: p,
          lastProductType: productTypeFromURL,
        });
        setIsLoading(false);
      } catch (err) {
        return null;
      }
    })();
  }, [productTypeFromURL]);

  return (
    <Grid container className="formSection">
      <Typography variant="h2" className={`header ${classes.headline}`}>
        {t('ikk.FindProduct.title')}
      </Typography>
      <Grid container className={classes.requestIdentifierWrapper}>
        <FormRadioGroup
          name="product.productCategory"
          options={categoryLookup}
          label={t('ikk.FindProduct.categories.label')}
          tooltip={t('ikk.FindProduct.categories.tooltip')}
          validate={validators.applicant.applicantType}
          errorMsg={t('validation.emptyField')}
          onChange={handleProductTypeChange}
          defaultValue={prodType}
        />
      </Grid>
      <Typography variant="body1" className={classes.fullWidth}>
        {t('ikk.FindProduct.infoText2')}
      </Typography>
      <Typography variant="body1" className={classes.fullWidth}>
        {t('ikk.FindProduct.infoText3')}
      </Typography>

      <ProductList
        productType={prodType}
        onSelectedProductChange={onSelectedProductChange}
        urlParams={urlParams}
        recommendedProducts={recommendedProducts}
        isLoading={isLoading}
      />
    </Grid>
  );
}
