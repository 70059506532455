import axios from 'axios';
import { baseURL } from './routes';

export default () => {
  const client = axios.create({
    baseURL,
    withCredentials: true,
  });

  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        window.location.assign(`${baseURL}/auth`);
      }
      throw error;
    },
  );

  return client;
};
