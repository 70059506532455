import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import CreditRequestsList from './CreditRequestList/CreditRequestsList';
import { ROOT, CREDIT_REQUESTS } from '../../common/constants/routes';

const CreditRequestsWrapper = () => (
  <div>
    <Switch>
      <Route strict exact path={CREDIT_REQUESTS} component={CreditRequestsList} />
      <Route>
        <Redirect to={ROOT} />
      </Route>
    </Switch>
  </div>
);

export default CreditRequestsWrapper;
