import { isEmpty } from 'util/formUtils';

/**
 * Company classification: KLU, KU, SME, GU
 */
export enum CompanySize {
  /** Micro enterprise (Kleinstunternehmen) */
  KLU = 1,
  /** Small company (Kleines Unternehmen) */
  KU = 2,
  /** Medium-sized company (Mittleres Unternehmen) */
  SME = 3,
  /** Large company (Großes Unternehmen) */
  GU = 4,
}

/**
 * Calculated company size classification based on company attributes
 * @returns display of text value
 */
export const getCompanySize = (employees: number, annualSales: number, totalAssets: number): CompanySize => {
  if (employees < 10 && (annualSales <= 2000000 || totalAssets <= 2000000)) {
    return CompanySize.KLU; // Micro enterprise;
  }

  if (employees < 50 && (annualSales <= 10000000 || totalAssets <= 10000000)) {
    return CompanySize.KU; // Small company
  }

  if (employees < 250 && (annualSales <= 50000000 || totalAssets <= 43000000)) {
    return CompanySize.SME; // Medium-sized company
  }

  return CompanySize.GU; // Large company
};

/**
 * Calculate if requested credit amount is valid for selected program and number of employees
 * @param numberOfEmployees
 * @param selectedProgram
 * @param creditAmount
 */
export const isCreditAmountValid = (
  numberOfEmployees?: number,
  selectedProgram?: string,
  creditAmount?: number,
): boolean => {
  if (!isEmpty(numberOfEmployees) && !isEmpty(creditAmount) && selectedProgram === '78') {
    if ((numberOfEmployees as number) <= 10 && (creditAmount as number) > 675000) {
      return false;
    }
    if ((numberOfEmployees as number) <= 50 && (creditAmount as number) > 1125000) {
      return false;
    }

    if ((numberOfEmployees as number) > 50 && (creditAmount as number) > 1800000) {
      return false;
    }
  }

  return true;
};
