import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import FormTextField from '../../components/form/FormTextField';

export default function ApplicantName() {
  const { t } = useTranslation();

  return (
    <Grid container item className="formSubSection" xs={12} md={6} data-testid="applicantName">
      <FormTextField
        name="applicant.applicantName"
        className="formItem"
        required
        label={t('ikk.ApplicantSelection.applicantName')}
        data-testid="applicantName"
      />
    </Grid>
  );
}
