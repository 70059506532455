import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import ConsentSection from './ConsentSection';

export default function Consent({ productDetails }: any) {
  const { t } = useTranslation();

  const [displayConsentPage, setDisplayConsentPage] = useState<any>(false);
  const [displayApplicantDeclaration, setDisplayApplicantDeclaration] = useState<any>(false);
  const [displayDataProtection, setDisplayDataProtection] = useState<any>(false);
  const [displayUseOfData, setDisplayUseOfData] = useState<any>(false);

  useEffect(() => {
    (async () => {
      if (productDetails) {
        try {
          if (productDetails && productDetails.ikkConsentDetails) {
            setDisplayConsentPage(true);
            if (productDetails.ikkConsentDetails[0].applicantDeclaration) {
              setDisplayApplicantDeclaration(true);
            }
            if (productDetails.ikkConsentDetails[1].dataProtection) {
              setDisplayDataProtection(true);
            }
            if (productDetails.ikkConsentDetails[2].useOfData) {
              setDisplayUseOfData(true);
            }
          }
        } catch (err) {
          return null;
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails]);

  if (!productDetails) {
    return <></>;
  }

  return (
    <Grid>
      {displayConsentPage && (
        <Grid container className="formSection">
          <Typography variant="h2" className="header">
            {t('ikk.Consent.title')}
          </Typography>
          {displayDataProtection && (
            <ConsentSection
              id="dataProtection"
              title={t('ikk.Consent.dataProtection')}
              data={productDetails.ikkConsentDetails[1].dataProtection}
              consentCheckboxText={t('ikk.Consent.declarationDataProtectionCheckbox')}
            />
          )}
          {displayUseOfData && (
            <ConsentSection
              id="useOfData"
              title={t('ikk.Consent.useOfData')}
              data={productDetails.ikkConsentDetails[2].useOfData}
            />
          )}
          {displayApplicantDeclaration && (
            <ConsentSection
              id="applicantDeclaration"
              title={t('ikk.Consent.applicantDeclaration')}
              data={productDetails.ikkConsentDetails[0].applicantDeclaration}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
}
