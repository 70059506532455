import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormTextField from '../../components/form/FormTextField';
import { POST_CODE_PATTERN, HOUSE_NUMBER_PATTERN } from '../../constants/index';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  hidden: {
    display: 'none',
  },
  inline: {
    display: 'inline-block',
    padding: '0 !important',
    marginTop: '-.5em',
  },
  infoText: {
    padding: '0 0 20px 0',
    width: '100%',
  },
}));

const INPUT_PROPS = {
  street: {
    maxLength: 60,
    formNoValidate: true,
  },
  houseNumber: {
    formNoValidate: true,
  },
  location: {
    maxLength: 35,
  },
  postcode: {
    maxLength: 5,
  },
};

export default function AddressForm() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container item className="formSubSection" data-testid="projectAddress">
      <Typography variant="h3" className={`${classes.fullWidth} subheader`}>
        {t('ikk.ProjectDescription.AddressForm.infoText2')}
      </Typography>

      <Typography variant="body1" className={`${classes.infoText}`}>
        {t('validation.invalidAddress')}
      </Typography>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="project.street"
            autoComplete="project-street-address"
            className="formItem"
            label={t('ikk.common.Address.street')}
            maxLength={INPUT_PROPS.street.maxLength}
            data-testid="investitionStreet"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="project.houseNumber"
            className="formItem"
            label={t('ikk.common.Address.houseNumber')}
            pattern={HOUSE_NUMBER_PATTERN}
            patternErrMsg="validation.invalidHouseNumber"
            data-testid="investitionHouseNumber"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="project.plz"
            label={t('ikk.common.Address.postcode')}
            pattern={POST_CODE_PATTERN}
            patternErrMsg="validation.invalidPostCodeFormat"
            className="formItem"
            required
            maxLength={INPUT_PROPS.postcode.maxLength}
            data-testid="investitionPLZ"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="project.town"
            label={t('ikk.common.Address.location')}
            className="formItem"
            required
            maxLength={INPUT_PROPS.location.maxLength}
            data-testid="investitionTown"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
