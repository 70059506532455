import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Add, Remove } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import FormNumberInput from '../../components/form/FormNumberInput';

import { Accordion, AccordionSummary, AccordionDetails } from '../../components/common/AccordionElements';

const useStyles = makeStyles(() => ({
  acordion: {
    borderTop: '2px solid rgba(0, 0, 0, .125)',
  },
}));

export default function AdditionalFinancialInformation() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const handleExpand = useCallback(
    () => (_: any, newExpanded: boolean) => {
      setExpanded(newExpanded);
    },
    [setExpanded],
  );

  return (
    <Grid container item xs={12} data-testid="additionalFinancialInformation">
      <Accordion expanded={expanded} onChange={handleExpand()}>
        <AccordionSummary
          expandIcon={expanded ? <Remove /> : <Add />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.acordion}
          data-testid="additionalFinancialInformation-accordion"
        >
          <Typography variant="h3">{t('oneApp.ProjectDetails.additionalFinancialInformationTitle')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container item className="formSubSection">
            <Typography variant="body1">
              {t('oneApp.ProjectDetails.pursposeInfoText1')}
              <Link href={t('oneApp.ProjectDetails.pursposeInfoText_link')} target="_blank">
                {t('oneApp.ProjectDetails.pursposeInfoText_linkText')}
              </Link>
              {t('oneApp.ProjectDetails.pursposeInfoText2')}
            </Typography>

            <Grid container item xs={12} justifyContent="center" className="formGroup">
              <Grid item xs={12} md={6}>
                <FormNumberInput
                  name="program.creditAmount"
                  disabled
                  className="formItem"
                  label={t('oneApp.ProjectDetails.creditAmount')}
                  tooltip={t('oneApp.ProjectDetails.creditAmountTooltip')}
                  thousandSeparators
                  decimalScale={2}
                  integerScale={13}
                  data-testid="creditAmount"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormNumberInput
                  name="project.investingFunds"
                  className="formItem"
                  label={t('oneApp.ProjectDetails.investingFunds')}
                  tooltip={t('oneApp.ProjectDetails.investingFundsTooltip')}
                  thousandSeparators
                  decimalScale={2}
                  integerScale={13}
                  data-testid="investingFunds"
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} justifyContent="center" className="formGroup">
              <Grid item xs={12} md={6} className="formGroup">
                <FormNumberInput
                  name="project.materialFunds"
                  className="formItem"
                  label={t('oneApp.ProjectDetails.materialFunds')}
                  tooltip={t('oneApp.ProjectDetails.materialFundsTooltip')}
                  thousandSeparators
                  decimalScale={2}
                  integerScale={13}
                  data-testid="materialFunds"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormNumberInput
                  name="project.operatingFunds"
                  className="formItem"
                  label={t('oneApp.ProjectDetails.operatingFunds')}
                  tooltip={t('oneApp.ProjectDetails.operatingFundsTooltip')}
                  thousandSeparators
                  decimalScale={2}
                  integerScale={13}
                  data-testid="operatingFunds"
                />
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
