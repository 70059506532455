import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import { LoadingButtonContainer } from 'components/LoadingButton';

export default function SpecialProgramUBR() {
  const { t } = useTranslation();
  return (
    <Grid container item className="formAttachmentSection">
      <Typography variant="h4">{t('oneApp.ProjectDetails.specialProgramUBRHeading')}</Typography>
      <Typography variant="body1" className="row">
        {t('oneApp.ProjectDetails.specialProgramUBRsubtitle')}
      </Typography>
      <Link href={t('oneApp.ProjectDetails.specialProgramUBRLink1')} target="_blank">
        <LoadingButtonContainer
          color="primary"
          type="button"
          text={t('oneApp.ProjectDetails.specialProgramUBRLinkText1')}
          classes="link"
          data-testid="specialProgramUBRLink1"
        />
      </Link>
      <Typography variant="body1">{t('oneApp.ProjectDetails.specialProgramUBRSubtitle2')}</Typography>
      <Link href={t('oneApp.ProjectDetails.specialProgramUBRLink2')} target="_blank">
        <LoadingButtonContainer
          color="primary"
          type="button"
          text={t('oneApp.ProjectDetails.specialProgramUBRLinkText2')}
          classes="link"
          data-testid="specialProgramUBRLink2"
        />
      </Link>
    </Grid>
  );
}
