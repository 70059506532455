import React, { useEffect, useCallback } from 'react';
import { Route } from 'react-router-dom';

import { getCurrentUser } from '../api/apiClient';
import { useLoginContext } from '../context/loginContext';

const PrivateRoute = ({ render }) => {
  const { user, setUser } = useLoginContext();

  const handleUserFetch = useCallback(async () => {
    const response = await getCurrentUser();
    setUser(response);
  }, [setUser]);

  useEffect(() => {
    handleUserFetch();
  }, [handleUserFetch]);

  const handleRender = () => {
    if (user === undefined) {
      return null;
    }

    return (
      <Route
        render={(routeProps) => {
          if (user) return render(routeProps);
        }}
      />
    );
  };

  return handleRender();
};

export default PrivateRoute;
