import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { POST_CODE_PATTERN } from 'common/constants/regexp';
import FormAutocomplete from 'pages/oneApp/components/form/FormAutocomplete';
import FormTextField from '../../components/form/FormTextField';
import countryList from '../../../../types/Land';

const INPUT_PROPS = {
  street: {
    maxLength: 60,
    formNoValidate: true,
  },
  houseNumber: {
    maxLength: 9,
    formNoValidate: true,
  },
  location: {
    maxLength: 35,
  },
  postcode: {
    maxLength: 5,
  },
};

export default function Address() {
  const { t } = useTranslation();

  return (
    <Grid container item className="formSubSection" data-testid="address">
      {/* // <Typography variant="h2" className="header">{t('oneApp.Address.title')} /> */}
      <Typography variant="h3" className="subheader">
        {t('oneApp.Address.title')}
      </Typography>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.street"
            autoComplete="street-address"
            className="formItem"
            required
            label={t('oneApp.Address.street')}
            maxLength={INPUT_PROPS.street.maxLength}
            data-testid="street"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.houseNumber"
            className="formItem"
            required
            label={t('oneApp.Address.houseNumber')}
            maxLength={INPUT_PROPS.houseNumber.maxLength}
            data-testid="houseNumber"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.postcode"
            autoComplete="postal-code"
            className="formItem"
            required
            label={t('oneApp.Address.postcode')}
            pattern={POST_CODE_PATTERN}
            patternErrMsg="validation.invalidPostCodeFormat"
            maxLength={INPUT_PROPS.postcode.maxLength}
            data-testid="postCode"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.location"
            autoComplete="address-level2"
            className="formItem"
            required
            label={t('oneApp.Address.location')}
            maxLength={INPUT_PROPS.location.maxLength}
            data-testid="location"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            name="applicant.country"
            inputAutoComplete="country-name"
            className="formItem"
            data={countryList}
            label={t('oneApp.Address.country')}
            required
            data-testid="country-select"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
