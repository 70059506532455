import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  arrowIcon: {
    fontSize: '12px',
  },
  formContainer: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      alignItems: 'center',
    },
  },
  formContentContainer: {
    maxWidth: '1175px',
    width: '100%',
  },
  section: {
    width: '100%',
    padding: '10px 0',
    [theme.breakpoints.up('sm')]: {
      '& > p': {
        width: '100%',
      },
    },
  },
  lastSection: {
    width: '100%',
    padding: '50px 0 0 0',
    [theme.breakpoints.up('sm')]: {
      '& > p': {
        width: '100%',
      },
    },
  },
  progressBarContainer: {
    zIndex: 100,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '120px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '120px',
    },
    [theme.breakpoints.down('md')]: {
      order: 1,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '170px',
    },
  },
  submitButton: {
    marginTop: '40px',
    minWidth: '152px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '30px',
      float: 'right',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  scrollContainer: {
    position: 'absolute',
    top: '80vh',
    right: '15vw',
    display: 'block',
    opacity: '1',
    [theme.breakpoints.down('md')]: {
      right: '10vw',
    },
    [theme.breakpoints.down('sm')]: {
      right: '8vw',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  scrollIndicator: {
    position: 'absolute',
  },
  scrollArrow: {
    opacity: '0',
    position: 'absolute',
    border: 'solid #1b618c',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    padding: '5px',
    transform: 'rotate(45deg)',
    animation: '$scrollAnimation 1500ms linear',
    animationIterationCount: '5',
    '&:nth-child(2)': {
      top: '10px',
      animationDelay: '125ms',
    },
    '&:last-child': {
      top: '20px',
      animationDelay: '250ms',
    },
    [theme.breakpoints.up('md')]: {
      padding: '8px',
    },
  },
  scrollDisappear: {
    opacity: '1',
    transition: 'opacity 1s',
  },
  noRobot: {
    justifyContent: 'flex-end',
    '& > div': {
      justifyContent: 'flex-end',
      paddingRight: '1.5rem',
    },
  },
}));

export default useStyles;
