import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { isEmpty, hasAllowedCharsOnly } from 'util/formUtils';
import ToolTipIcon from 'components/fields/ToolTipIcon';
import { getErrorProps } from '../../helpers/formFieldHelpers';

const useStyles = makeStyles(() => ({
  inlineBlock: {
    display: 'inline-block',
    width: 'auto',
  },
}));

const FormTextField = ({
  name,
  value,
  validate,
  maxLength,
  required,
  tooltip,
  pattern,
  patternErrMsg,
  'data-testid': dataTestId,
  skipDefaultValidation,
  inline,
  ...props
}: any) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const validateHandler = useCallback(
    (value, allValues, meta) => {
      let hasError;

      if (!skipDefaultValidation) {
        if (required) {
          hasError = isEmpty(value);
          if (hasError) return 'validation.emptyField';
        }

        if (value && value.length > 0) {
          if (!hasAllowedCharsOnly(value)) {
            hasError = 'validation.onlyAllowedCharacters';
            if (hasError) return hasError;
          }

          if (pattern) {
            hasError = !new RegExp(pattern).test(value);
            if (hasError) return patternErrMsg || true;
          }
        }
      }

      if (validate) return validate(value, allValues, meta);

      return false;
    },
    [pattern, patternErrMsg, required, validate, skipDefaultValidation],
  );

  return (
    <Field
      name={name}
      validate={validateHandler}
      subscription={{ value: true, modified: true, error: true, submitFailed: true }}
      render={({ input, meta }) => (
        <Grid container className={inline ? classes.inlineBlock : ''}>
          <Grid container item xs={10}>
            <TextField
              {...props}
              {...input}
              required={required}
              {...getErrorProps(t, meta.error, meta.modified, meta.submitFailed)}
              variant="outlined"
              data-testid={dataTestId}
              inputProps={{
                maxLength,
                'aria-labelledby': `${dataTestId}-hintText`,
                id: dataTestId,
              }}
              value={value}
              InputLabelProps={{
                htmlFor: dataTestId,
              }}
            />
          </Grid>
          {tooltip && (
            <Grid item xs={1} className="tooltip">
              <ToolTipIcon tip={tooltip} />
            </Grid>
          )}
        </Grid>
      )}
    />
  );
};

export default React.memo(FormTextField);
