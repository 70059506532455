import { withStyles } from '@material-ui/core/styles';

const GlobalStyles = withStyles((theme) => ({
  '@global': {
    '.errorMessage': {
      color: '#ca0036 !important',
      marginTop: '5px',
      fontSize: '1.6rem',
      lineHeight: 1.4,
      letterSpacing: 0,
      paddingBottom: '2rem',
      fontFamily: 'kfwRegular',
      textAlign: 'left',
    },
    '.textarea': {
      color: '#5a6166',
      fontSize: '1.6rem',
    },
    '.textarea:focus': {
      outline: 'none',
      border: '2px solid #60800f',
    },
    '.MuiFormControlLabel-root.error': {
      color: '#ca0036',
      '& .MuiRadio-root': {
        color: '#ca0036',
      },
    },
    '.MuiFormGroup-root.error': {
      color: '#ca0036',
    },
    '.MuiFormGroup-root.error .MuiTypography-body1': {
      color: '#ca0036',
    },
    '.MuiFormControlLabel-root.error .MuiCheckbox-root': {
      color: '#ca0036',
    },
    '.MuiFormControlLabel-root.error .MuiSwitch-track': {
      backgroundColor: '#ca0036',
      opacity: '0.85',
    },
    '.MuiFormControlLabel-root.error .MuiTypography-body1': {
      color: '#ca0036',
    },
    '.MuiFormGroup-root.error .MuiRadio-root': {
      color: '#ca0036',
    },
    '.full-width-container': {
      paddingLeft: '7.5vw',
      paddingRight: '7.5vw',
    },
    '.main-grid-header': {
      paddingLeft: '0.5rem',
      marginBottom: '2rem',
    },
    '.main-grid-content': {
      paddingLeft: '2rem',
      marginBottom: '1rem',
    },
    /* Button Style */
    '.MuiButton-root': {
      padding: '9px 13.5px 9px 13.5px',
      fontFamily: 'kfwRegular',
      fontSize: '13.5px',
      fontWeight: 'normal',
      textTransform: 'none',
      lineHeight: 1.5,
      color: '#fff',
      minHeight: '39px',
    },
    '.MuiButton-label': {
      minHeight: '21px',
      '& .MuiTypography-h4': {
        color: '#fff !important',
      },
    },
    '.MuiButton-endIcon': {
      marginLeft: '7.5px',
      marginRight: '0',
    },
    '.MuiButton-startIcon': {
      marginRight: '7.5px',
      marginLeft: '0',
    },
    '.MuiButton-iconSizeMedium > *:first-child': {
      fontSize: '14px',
    },
    '.MuiButton-contained': {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
    },
    '.MuiButton-containedPrimary': {
      backgroundColor: '#1b618c',
      '&:hover': {
        backgroundColor: '#60800f',
      },
      '&:disabled': {
        color: '#fff',
        backgroundColor: '#a4c0d1',
      },
    },
    '.MuiButton-containedSecondary': {
      backgroundColor: '#4e6e61',
      '&:hover': {
        backgroundColor: '#60800f',
      },
      '&:disabled': {
        color: '#fff',
        backgroundColor: '#b8c5c0',
      },
    },
    /* Typography */
    '.MuiTypography-h1': {
      color: '#4e6e61',
      fontFamily: 'kfw,-apple-system',
      fontSize: '45px',
      fontWeight: 'normal',
      lineHeight: '1.17',
    },
    '.MuiTypography-h2': {
      color: '#1b618c',
      fontFamily: 'kfw,-apple-system',
      fontSize: '28px',
      fontWeight: 'normal',
      lineHeight: '1.21',
    },
    '.MuiTypography-h3, .MuiTypography-h4': {
      color: '#4e6e61',
    },
    '.MuiTypography-h3': {
      fontSize: '22px',
      fontFamily: 'kfw,-apple-system',
      fontWeight: 'normal',
      lineHeight: '1.27',
    },
    '.MuiTypography-h4': {
      fontSize: '18px',
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: '1.44',
    },
    '.MuiTypography-h2, .MuiTypography-h3': {
      [theme.breakpoints.down('xs')]: {
        wordBreak: 'break-word',
      },
    },
    '.MuiTypography-h5, .MuiTypography-body1, .MuiTypography-caption': {
      color: '#5a6166',
      fontSize: '15px',
      fontFamily: 'Arial',
      fontWeight: 'normal',
      lineHeight: '1.47',
    },
    '.MuiTypography-body2, .MuiTypography-subtitle1': {
      fontSize: '13px',
    },
    '.MuiLink-root': {
      fontFamily: 'Arial',
      fontSize: '15px',
      textDecoration: 'underline',
      fontWeight: 'normal',
      lineHeight: 1.47,
      color: '#1b618c',
      '&:hover': {
        color: '#60800f',
      },
    },
    '.MuiSlider-markLabel': {
      fontFamily: 'kfwRegular',
      fontSize: '15px',
      fontWeight: 'normal',
      color: '#5a6166',
    },
    /* Text Input Style */
    '.MuiInputBase-root': {
      fontSize: '1.6rem',
    },
    '.MuiInputLabel-root': {
      fontFamily: 'Arial',
      fontWeight: 'normal',
      fontSize: '1.6rem',
    },
    '.MuiOutlinedInput-input': {
      fontFamily: 'Arial',
      fontWeight: 'normal',
      color: '#5a6166',
      fontSize: '1.6rem',
    },
    '@media (max-width:375px)': {
      '.MuiSlider-root': {
        '& .MuiSlider-markLabel:nth-of-type(n)': {
          display: 'none',
        },
        '& .MuiSlider-markLabel:nth-of-type(4)': {
          display: 'block',
        },
        '& .MuiSlider-markLabel:nth-last-of-type(2)': {
          display: 'block',
        },
      },
    },
    '.MuiOutlinedInput-root': {
      fontSize: '16px',
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#9ca0a3',
    },
    '.Mui-focused': {
      '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: '#60800f',
        fontSize: '16px',
      },
      '&.MuiFormLabel-root': {
        color: '#60800f',
      },
      '&.MuiInput-underline:before': {
        borderBottom: '2px solid #60800f',
      },
      '&.MuiInput-underline:after': {
        borderBottom: '2px solid #60800f',
      },
    },
    '.Mui-error': {
      '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ca0036',
      },
      '&.MuiInput-underline:before': {
        borderBottom: '2px solid #ca0036',
      },
      '&.MuiInput-underline:after': {
        borderBottom: '2px solid #ca0036',
      },
      '&.MuiFormLabel-root': {
        color: '#ca0036',
      },
      '&.MuiFormLabel-asterisk': {
        color: '#ca0036',
      },
      '&.MuiFormHelperText-root': {
        color: '#ca0036',
      },
    },
    '.Mui-disabled': {
      '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: '#dedfe0',
      },
      '&.MuiSelect-outlined': {
        color: '#bdc0c2',
      },
    },
    /* Switch Style */
    '.MuiSwitch-thumb': {
      backgroundColor: '#00446e',
      borderRadius: '4px',
      height: '3rem',
      width: '3rem',
      boxSizing: 'border-box',
      marginTop: '0.2rem',
    },
    '.MuiSwitch-track': {
      backgroundColor: '#f3f1e7 !important',
    },
    '.MuiSwitch-switchBase': {
      height: '3rem',
      padding: '0.3rem',
      '&.Mui-checked': {
        transform: 'translateX(3rem)',
      },
    },
    '.switch': {
      width: '6.8rem',
      height: '3.6rem',
      margin: '1rem',
      borderWidth: '1.5px',
      borderColor: '#00446e',
      borderRadius: '7px',
      backgroundColor: '#f3f1e7',
    },
    /* Slider Style */
    '.MuiSlider-root': {
      color: '#1b618c',
      '&:hover': {
        color: '#60800f',
      },
    },
    /* Stepper Style */
    '.MuiStepIcon-root': {
      color: '#a4c0d1',
      '&.MuiStepIcon-completed, &.MuiStepIcon-active': {
        color: '#1b618c',
      },
    },
    /* Linear Progress Style */
    '.MuiLinearProgress-colorPrimary': {
      backgroundColor: '#ebf3f7',
    },
    '.MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#1b618c',
    },
    '.icArrowsLeft': {
      fontFamily: 'kfw-icons',
      verticalAlign: 'baseline',
      '&:before': {
        content: '"\\e901"',
      },
    },
    '.icArrowsRight': {
      fontFamily: 'kfw-icons',
      verticalAlign: 'baseline',
      '&:before': {
        content: '"\\e902"',
      },
    },
    '.icArrowsDown': {
      fontFamily: 'kfw-icons',
      verticalAlign: 'baseline',
      fontSize: '14px',
      '&:before': {
        content: '"\\e908"',
      },
    },
    '.icArrowsDownExpandIcon': {
      fontFamily: 'kfw-icons',
      verticalAlign: 'baseline',
      fontSize: '14px',
      '&:before': {
        content: '"\\e908"',
      },
    },
    '.MuiAccordionSummary-expandIcon': {
      marginRight: '1rem',
    },
    '.MuiAutocomplete-option': {
      whiteSpace: 'break-word',
      wordBreak: 'break-word',
    },
    '.MuiAutocomplete-popperDisablePortal': {
      position: 'relative',
    },
    '.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      paddingTop: '0',
      paddingBottom: '0',
      marginBottom: '0.5rem',
    },
    '.MuiPickersBasePicker-container': {
      fontFamily: 'kfwRegular',
      fontSize: '15px',
      fontWeight: 'normal',
    },
    '.MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected ': {
      backgroundColor: '#1b618c',
    },
    '.MuiPickersCalendarHeader-switchHeader': {
      '& button:first-of-type': {
        fontFamily: 'kfw-icons',
        verticalAlign: 'baseline',
        fontSize: '1rem',
        '& .MuiIconButton-label': {
          display: 'none',
        },
        '&:before': {
          content: '"\\e901"',
        },
      },
      '& button:last-of-type': {
        fontFamily: 'kfw-icons',
        verticalAlign: 'baseline',
        fontSize: '1rem',
        '& .MuiIconButton-label': {
          display: 'none',
        },
        '&:before': {
          content: '"\\e902"',
        },
      },
    },
    '.MuiStepConnector-vertical': {
      padding: '8px 0',
    },
    '.MuiTypography-subtitle1 > .MuiList-root > .MuiListItem-root': {
      display: 'list-item',
      listStyleType: 'disc',
      listStylePosition: 'inside',
    },
    '.MuiListItem-root': {
      fontSize: '1.5rem',
      fontFamily: 'Arial',
    },
    '.MuiSelect-root': {
      fontSize: '1.5rem',
      fontFamily: 'Arial',
    },
    '.MuiAutocomplete-popper li': {
      fontSize: '15px',
      fontFamily: 'Arial',
    },
    '.MuiTooltip-tooltip': {
      fontSize: '12px',
      fontFamily: 'kfw,-apple-system',
      lineHeight: '1.4em',
      fontWeight: '500',
    },
    '.MuiInputAdornment-positionEnd': {
      '& button': {
        fontFamily: 'kfw-icons',
        verticalAlign: 'baseline',
        padding: '0',
        height: '26px',
        width: '26px',
        right: '-5px',
        '& .MuiIconButton-label': {
          display: 'none',
        },
        '&:before': {
          content: '"\\e922"',
          fontSize: '1rem',
        },
      },
    },
    '.MuiFormHelperText-contained': {
      color: '#ca0036',
      fontSize: '15px',
    },
  },
}))(() => null);

export default GlobalStyles;
