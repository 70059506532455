import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormNumberInput from '../../components/form/FormNumberInput';
import AdditionalRequestInformation from './AdditionalRequestInformation';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: '45px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '38px',
      paddingBottom: '20px !important',
    },
  },
  list: {
    marginLeft: '2rem',
    listStyleType: 'disc',
  },
  creditAmountWrapper: {
    width: '100%',
  },
  creditAmount: {
    width: '100%',
  },
  inputInfoText: {
    padding: '20px 0 10px 0',
    width: '100%',
  },
}));

export default function FinancingDetails({
  productType,
  creditAmountMainField,
  creditAmountShadowField,
  financingCreditAmountShadowField,
  productDetails,
}: any) {
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useForm();

  const [productValidation, setProductValidation] = useState<any>('');

  const additionalRequestedInformationProperties = {
    productType,
    productDetails,
  };

  const categoryKeyLookup: { [key: string]: string } = t('ikk.FindProduct.categories.categoryKeyLookup', {
    returnObjects: true,
  });

  const handleCreditAmountChange = (value: any) => {
    if (!creditAmountShadowField) {
      return;
    }
    form.batch(() => {
      form.change(creditAmountShadowField, value);
      form.change(financingCreditAmountShadowField, value);
    });
  };

  useEffect(() => {
    (async () => {
      if (productDetails) {
        try {
          const i = productDetails;
          if (i || i === {}) {
            setProductValidation(t('ikk.FinancingDetails.productValidationResponseEmpty'));
          } else if (
            i &&
            i.productVariants &&
            i.productVariants.filter((pv: any) => pv.channel === 'direkt').length > 0
          ) {
            setProductValidation(t('ikk.FinancingDetails.productValidationChannelDirektSuccess'));
          } else {
            setProductValidation(t('ikk.FinancingDetails.productValidationInvalid'));
          }
        } catch (err) {
          return null;
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails]);

  if (!productDetails) {
    return <></>;
  }

  return (
    <Grid container className="formSection">
      <Typography variant="h2" className="header">
        {t('ikk.FinancingDetails.title')}
      </Typography>

      <Typography variant="body1">{t('ikk.FinancingDetails.infoText1')}</Typography>
      <Grid container className={classes.creditAmountWrapper}>
        <Grid item xs={12} md={6}>
          <FormNumberInput
            name={creditAmountMainField}
            className={classes.creditAmount}
            label={
              categoryKeyLookup[productType] === categoryKeyLookup.grant
                ? t('ikk.FinancingDetails.grantAmount')
                : t('ikk.FinancingDetails.creditAmount')
            }
            tooltip={
              categoryKeyLookup[productType] === categoryKeyLookup.grant
                ? t('ikk.FinancingDetails.grantAmountTooltip')
                : t('ikk.FinancingDetails.creditAmountTooltip')
            }
            thousandSeparators
            decimalScale={2}
            integerScale={13}
            data-testid="creditAmount"
            required
            onChange={(evt: React.ChangeEvent<{ name: string; value: string }>) => {
              handleCreditAmountChange(evt.target.value);
            }}
          />
        </Grid>
        <Typography variant="caption" className={classes.inputInfoText} id="creditAmount-hintText">
          {t('ikk.FinancingDetails.creditAmountInfo')}
        </Typography>
      </Grid>
      <AdditionalRequestInformation {...additionalRequestedInformationProperties} />
      <Typography variant="body1" hidden id="creditAmount-notificationMessage">
        <b>{productValidation && t('ikk.FinancingDetails.productValidationNotification')}</b> <br />
        {productValidation}
      </Typography>
    </Grid>
  );
}
