import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { PHONE_PATTERN, EMAIL_PATTERN } from 'common/constants/regexp';
import FormTextField from '../../components/form/FormTextField';

const INPUT_PROPS = {
  email: {
    maxLength: 50,
  },
  phone: {
    maxLength: 20,
  },
};

export default function ContactDetails() {
  const { t } = useTranslation();

  return (
    <Grid container item className="formSubSection" data-testid="contactDetails">
      <Typography variant="h3" className="subheader">
        {t('oneApp.ContactDetails.title')}
      </Typography>
      <Typography variant="body1">{t('oneApp.ContactDetails.subtitle')}</Typography>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.email"
            autoComplete="email"
            type="email"
            className="formItem"
            label={t('oneApp.ContactDetails.email')}
            pattern={EMAIL_PATTERN}
            patternErrMsg="validation.invalidEmailFormat"
            maxLength={INPUT_PROPS.email.maxLength}
            data-testid="email"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.phone"
            autoComplete="tel"
            type="tel"
            className="formItem"
            label={t('oneApp.ContactDetails.phone')}
            pattern={PHONE_PATTERN}
            patternErrMsg="validation.invalidPhoneFormat"
            maxLength={INPUT_PROPS.phone.maxLength}
            data-testid="phone"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
