import React from 'react';
import { Field } from 'react-final-form';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ToolTipIcon from 'components/fields/ToolTipIcon';

const useStyles = makeStyles(() => ({
  sliderContainer: {
    height: '55px',
    marginBottom: '16px',
  },
  slider: {
    top: '10px',
    marginLeft: '50px',
    width: 'calc(100% - 105px)',
    '& .MuiSlider-valueLabel': {
      left: 'calc(-50% - 4px)',
    },
  },
  input: {
    width: '55px',
    '& input': {
      minWidth: '20px',
      textAlign: 'center',
      '-moz-appearance': 'textfield',
    },
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
      margin: '0',
    },
  },
  tooltipAlignment: {
    marginLeft: '-1rem',
  },
  displayContents: {
    display: 'contents',
  },
}));

const FormSlider = ({ name, value, label, tooltip, min, max, step, 'data-testid': dataTestId, ...props }: any) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState<number>(1);
  const marks = [
    {
      value: min,
      label: `${min} ${min !== 1 ? 'Jahre' : 'Jahr'}`,
    },
    {
      value: max,
      label: `${max} Jahre`,
    },
  ];

  return (
    <Field
      name={name}
      subscription={{ value: true, modified: true, error: true, submitFailed: true }}
      render={({ input }) => {
        const onChangeSliderHandler = (e: React.ChangeEvent<{}>, value: number) => {
          input.onChange({ ...e, target: { ...e.target, value } });
          setInputValue(value);
        };

        const onChangeInputHandler = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          const { value } = e.target;
          input.onChange(Number(value));
          setInputValue(Number(value));
        };

        const handleInputBlur = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          const { value } = event.target;
          if (value < min) {
            input.onChange(Number(min));
            setInputValue(min);
          } else if (value > max) {
            input.onChange(Number(max));
            setInputValue(max);
          }
        };

        return (
          <Grid container item xs={10}>
            <Grid item container lg={tooltip ? 7 : 12} alignItems="center" className={classes.sliderContainer}>
              <Grid item xs={10} className={classes.displayContents}>
                <Typography variant="body1" id={`${dataTestId}-label`}>
                  {label}
                </Typography>
              </Grid>
              {tooltip && (
                <Grid container item xs={1} className={classes.tooltipAlignment}>
                  <ToolTipIcon tip={tooltip} />
                </Grid>
              )}
            </Grid>

            <Grid container item xs={12}>
              <TextField
                className={classes.input}
                id={dataTestId}
                inputProps={{ min, max, step, 'aria-labelledby': `${dataTestId}-label` }}
                onChange={onChangeInputHandler}
                onBlur={handleInputBlur}
                type="number"
                value={inputValue.toString()}
                variant="outlined"
              />
              <Slider
                {...props}
                {...input}
                min={min}
                max={max}
                step={step}
                data-testid={dataTestId}
                className={classes.slider}
                aria-labelledby={`${dataTestId}-label`}
                valueLabelDisplay="on"
                onChange={onChangeSliderHandler}
                marks={marks}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default React.memo(FormSlider);
