import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Link } from '@material-ui/core';
import { LoadingButtonContainer } from 'components/LoadingButton';

export default function GbzaInformation() {
  const { t } = useTranslation();

  return (
    <Grid container item className="formAttachmentSection">
      <Typography variant="h3">{t('oneApp.ProjectDetails.gbzaHeading')}</Typography>
      <Typography variant="body1" className="row">
        {t('oneApp.ProjectDetails.detailText1')}
      </Typography>
      <Typography variant="body1" className="row">
        {t('oneApp.ProjectDetails.detailText2')}
      </Typography>
      <Typography variant="body1" className="row">
        {t('oneApp.ProjectDetails.detailText3')}
      </Typography>

      <Link href={t('oneApp.ProjectDetails.gbzaLink')} target="_blank">
        <LoadingButtonContainer
          color="primary"
          type="button"
          text={t('oneApp.ProjectDetails.gbzaLinkText')}
          classes="link"
          data-testid="gbzaLink"
        />
      </Link>
    </Grid>
  );
}
