import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FormTextField from '../../components/form/FormTextField';
import FormSelect from '../../components/form/FormSelect';
import FormDatePicker from '../../components/form/FormDatePicker';

export default function Certification() {
  const { t } = useTranslation();

  const certificationTypeLookup: { [key: string]: string } = t('oneApp.Certification.certificationTypeLookup', {
    returnObjects: true,
  });
  return (
    <Grid container item className="formSubSection" data-testid="certification">
      <Typography variant="h3" className="subheader">
        {t('oneApp.Certification.certificationTitle')}
      </Typography>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.certificationNumber"
            className="formItem"
            label={t('oneApp.Certification.certificationNumber')}
            maxLength={35}
            data-testid="certificationNumber"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSelect
            name="applicant.certificationType"
            className="formItem"
            label={t('oneApp.Certification.certificationType')}
            options={certificationTypeLookup}
            labelWidth={120}
            data-testid="certificationType-select"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.certificationDesc"
            className="formItem"
            label={t('oneApp.Certification.certificationDesc')}
            maxLength={35}
            data-testid="certificationDesc"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormDatePicker
            name="applicant.certificationDate"
            className="formItem"
            label={t('oneApp.Certification.certificationDate')}
            data-testid="certificationDate-date"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.certificationNotaryName"
            className="formItem"
            label={t('oneApp.Certification.certificationNotaryName')}
            maxLength={35}
            data-testid="certificationNotaryName"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.certificationNotaryOffice"
            className="formItem"
            label={t('oneApp.Certification.certificationNotaryOffice')}
            maxLength={35}
            data-testid="certificationNotaryOffice"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
