import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import { withStyles } from '@material-ui/core/styles';

export const Accordion = withStyles({
  root: {
    width: '100%',
    border: '0px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 'auto',
    },
  },
})(MuiAccordion);

export const AccordionSummary = withStyles(() => ({
  root: {
    backgroundColor: '#FFFF',
    borderBottom: '2px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    marginBottom: -1,
    minHeight: 56,
    '&.Mui-expanded': {
      minHeight: 56,
      borderBottom: 'none',
    },
  },
  content: {
    marginLeft: '20px',
    '&.Mui-expanded': {
      margin: '12px 20px',
    },
  },
  expandIcon: {
    order: -1,
    paddingLeft: '0px',
    paddingRight: '0px',
    color: '#1b618c',
  },
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles({
  root: {
    borderBottom: '2px solid rgba(0, 0, 0, .125)',
    '& .accordionSubsection:not(:last-child)': {
      marginBottom: '50px',
    },
  },
})(MuiAccordionDetails);
