import 'react-app-polyfill/ie11';
import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import CreditRequestsWrapper from './creditRequests/index';
import NavigationBar from '../components/layout/NavigationBar';
import DeleteAccount from './DeleteProfile';
import SummaryOneApp from './creditRequests/SummaryOneApp/SummaryOneApp';

import Footer from '../components/layout/Footer';
import Layout from '../components/layout/Layout';
import ContextNotification from '../components/notifications/ContextNotification';

import { ROOT, CREDIT_REQUESTS, DELETE_ACCOUNT, CREDIT_REQUESTS_ONE_APP } from '../common/constants/routes';

const muiTheme = createTheme({
  typography: {
    fontFamily: ['kfw', '-apple-system'].join(','),
    h1: {
      fontSize: '36px',
      fontWeight: 'normal',
      lineHeight: 1.17,
    },
    h2: {
      fontSize: '28px',
      fontWeight: 'normal',
      lineHeight: 1.21,
    },
    h3: {
      fontSize: '22px',
      fontWeight: 'normal',
      lineHeight: 1.27,
    },
    h4: {
      fontFamily: 'Arial',
      fontSize: '18px',
      fontWeight: 'normal',
      lineHeight: 1.44,
    },
    h5: {
      fontFamily: 'Arial',
      fontSize: '15px',
      fontWeight: 'bold',
      lineHeight: 1.47,
    },
    body1: {
      fontFamily: 'Arial',
      fontSize: '15px',
      fontWeight: 'normal',
      lineHeight: 1.47,
    },
    body2: {
      fontFamily: 'Arial',
      fontSize: '13px',
      fontWeight: 'normal',
      lineHeight: 1.5,
    },
    caption: {
      fontFamily: 'Arial',
      fontSize: '13px',
      fontWeight: 'normal',
      lineHeight: 1.5,
    },
  },
  palette: {
    primary: {
      light: '#cde1eb',
      main: '#5a9bbe',
    },
    secondary: {
      light: '#60800f',
      main: '#4e6e61',
    },
    grey: {
      100: '#F7FAFC',
      200: '#EDF2F7',
      300: '#ebf3f7',
      400: '#CBD5E0',
      500: '#A0AEC0',
      600: '#718096',
      700: '#4A5568',
      800: '#2D3748',
      900: '#1A202C',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: '0',
      },
    },
  },
  props: {
    MuiPaper: {
      square: true,
    },
  },
});

const Main = () => {
  return (
    <ThemeProvider theme={muiTheme}>
      <main>
        <NavigationBar />
        <Layout>
          <Switch>
            <Redirect exact from={ROOT} to={CREDIT_REQUESTS} />
            <Route path={CREDIT_REQUESTS} component={CreditRequestsWrapper} />
            <Route path={DELETE_ACCOUNT} component={DeleteAccount} />
            <Route exact path={`${CREDIT_REQUESTS_ONE_APP}/:id/summary`} component={SummaryOneApp} />
            <Route>
              <Redirect to={ROOT} />
            </Route>
          </Switch>
        </Layout>
      </main>
      <Footer />
      <ContextNotification />
    </ThemeProvider>
  );
};

export default Main;
