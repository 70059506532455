import React, { createContext, useContext, useState, useCallback } from 'react';

interface User {
  name?: String;
  oid?: String;
  exp: Number;
  iat: Number;
}

interface Props {
  user?: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

const defaults: Props = {
  setUser: () => {},
};

const Context = createContext(defaults);

export const useLoginContext = () => useContext(Context);

interface ProviderProps {
  children: React.ReactNode;
}

const Provider = ({ children }: ProviderProps) => {
  const [user, _setUser] = useState<User>();

  const setUser = useCallback((token) => {
    _setUser(token);
  }, []);

  const contextValue: Props = {
    user,
    setUser,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const LoginContextProvider = Provider;
