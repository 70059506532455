export const FUNDING_PURPOSE_IDS = {
  CORONA_FUNDING_PURPOSE_ID: 11,
};

export type FundingPurpose = {
  [key: string]: string | string[] | number[] | number;
  id: number;
  title: string;
  usagePurposeIds: number[];
  programIds: string[];
};

// use 999xx id for helper usagePurposes to map programs to funding purpose
export type UsagePurpose = {
  [key: string]: string | string[] | number | boolean | undefined;
  id: number;
  title: string;
  programIds: string[];
  isHidden?: boolean;
};

export type Program = {
  [key: string]: string | string[] | number[] | boolean;
  id: string;
  title: string;
  subtitle: string;
  description: string[];
  usagePurposeIds: number[];
  fundingPurposeIds: number[];
};

const fundingPurposes: { [key: string]: FundingPurpose } = {
  1: {
    id: 1,
    title: 'Investition Allgemein',
    usagePurposeIds: [2, 3, 4, 5],
    programIds: ['79/89', '365', '366', '375', '376'],
  },
  2: {
    id: 2,
    title: 'Gründung',
    usagePurposeIds: [2, 3, 4, 5],
    programIds: ['58', '67', '365', '366', '375', '376'],
  },
  5: {
    id: 5,
    title: 'Erneuerbare Energien',
    usagePurposeIds: [
      7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 180, 182, 183, 184, 185, 186, 187, 192,
      194, 195, 196, 197,
    ],
    programIds: ['270'],
  },
  6: {
    id: 6,
    title: 'Energie-Effizienz',
    usagePurposeIds: [
      27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 120, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210,
      211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 250, 258, 259, 260, 264, 265, 266, 267, 268, 269,
      270, 271, 272, 285, 286, 344,
    ],
    programIds: ['292', '295', '263'],
  },
  7: {
    id: 7,
    title: 'Kommunale u. soziale Infrastruktur',
    usagePurposeIds: [
      3, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72,
      73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 319, 320, 321, 322, 323,
      324, 325, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 188, 224, 242, 243, 156,
      329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340, 341, 342, 343, 345, 346, 347,
    ],
    programIds: ['148', '202', '206', '268/269'],
  },
  8: {
    id: 8,
    title: 'Umwelt',
    usagePurposeIds: [
      123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 244,
    ],
    programIds: ['240/241'],
  },
  9: {
    id: 9,
    title: 'Innovation/Digitalisierung',
    usagePurposeIds: [144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 242, 243, 156],
    programIds: ['206', '360/361/364', '380'],
  },
  10: {
    id: 10,
    title: 'Klimaschutz',
    usagePurposeIds: [240, 226, 227, 230, 233, 232, 235, 236, 237, 238, 239],
    programIds: ['293'],
  },
};

const usagePurposes: { [key: string]: UsagePurpose } = {
  2: {
    id: 2,
    title: 'Investitionen im AV aktivierungsfähig',
    programIds: ['58', '67', '79/89', '365', '366', '375', '376'],
  },
  3: {
    id: 3,
    title: 'Betriebsmittel, Inv.nicht im AV aktivierungsf.',
    programIds: ['67', '79/89', '148', '365', '366', '375', '376'],
  },
  4: {
    id: 4,
    title: 'Material-/ Warenlager',
    programIds: ['58', '67', '365', '366', '375', '376'],
  },
  5: {
    id: 5,
    title: 'Unternehmenserwerb und Beteiligungen',
    programIds: ['58', '67', '365', '366', '375', '376'],
  },
  7: {
    id: 7,
    title: 'Photovoltaikanlage -Aufdach/Fassade',
    programIds: ['270'],
  },
  8: {
    id: 8,
    title: 'Photovoltaikanlage - Freifläche',
    programIds: ['270'],
  },
  9: {
    id: 9,
    title: 'Offshore Windkraftanlage',
    programIds: ['270'],
  },
  10: {
    id: 10,
    title: 'Onshore Windkraftanlage',
    programIds: ['270'],
  },
  11: {
    id: 11,
    title: 'Solarkollektoranlage',
    programIds: ['270'],
  },
  12: {
    id: 12,
    title: 'Wasserkraftanlage',
    programIds: ['270'],
  },
  13: {
    id: 13,
    title: 'Geothermische Anlage zur Strom-/Wärmeerzeugung',
    programIds: ['270'],
  },
  14: {
    id: 14,
    title: 'Anlage zur Strom-/Wärmeerzeugung aus Biomasse',
    programIds: ['270'],
  },
  15: {
    id: 15,
    title: 'Anlage zur Strom-/Wärmeerzeugung aus Biogas',
    programIds: ['270'],
  },
  16: {
    id: 16,
    title: 'Anlage zur Erzeugung von Biogas',
    programIds: ['270'],
  },
  17: {
    id: 17,
    title: 'Anlage zur Aufbereitung/Einspeisung von Biogas',
    programIds: ['270'],
  },
  18: {
    id: 18,
    title: 'Objektnahe Nieder- und Mittelspannungsnetze',
    programIds: ['270'],
  },
  19: {
    id: 19,
    title: 'Erneuerbare-Energien Wärme-/Kältespeicher',
    programIds: ['270'],
  },
  20: {
    id: 20,
    title: 'EE-Wärme-/Kältenetze',
    programIds: ['270'],
  },
  21: {
    id: 21,
    title: 'Stromspeicher/Power-to-X-Anlage',
    programIds: ['270'],
  },
  22: {
    id: 22,
    title: 'Flexibilisierung Erneuerbare Energien-Anlagen',
    programIds: ['270'],
  },
  23: {
    id: 23,
    title: 'Überbetriebliches Lastmanagement',
    programIds: ['270'],
  },
  24: {
    id: 24,
    title: 'Moderne Messeinrichtungen/Messsysteme',
    programIds: ['270'],
  },
  25: {
    id: 25,
    title: 'Batteriespeicher erneuerbare Energien-Anlagen',
    programIds: ['270'],
  },
  27: {
    id: 27,
    title: 'Maschinen/Anlagen/ Prozesstechnik',
    programIds: ['292'],
  },
  28: {
    id: 28,
    title: 'Druckluft/Vakuum/ Absaugtechnik',
    programIds: ['292'],
  },
  29: {
    id: 29,
    title: 'Elektrische Antriebe/Pumpen',
    programIds: ['292'],
  },
  30: {
    id: 30,
    title: 'Prozesswärme',
    programIds: ['292'],
  },
  31: {
    id: 31,
    title: 'Prozesskälte',
    programIds: ['292'],
  },
  32: {
    id: 32,
    title: 'Prozesskälte: Kühlhäuser, Kühlräume',
    programIds: ['292'],
  },
  33: {
    id: 33,
    title: 'Wärmerückgewinnung/Abwärmenutzung (Prod.Proz.)',
    programIds: ['292'],
  },
  34: {
    id: 34,
    title: 'Mess-, Regel- und Steuerungstechnik',
    programIds: ['292'],
  },
  35: {
    id: 35,
    title: 'Informations- und Kommunikationstechnik',
    programIds: ['292'],
  },
  36: {
    id: 36,
    title: 'Effiziente Energieerzeugung: KWK-Anlagen, BHKW',
    programIds: ['292'],
  },
  37: {
    id: 37,
    title: 'Andere betriebl. Maßnahmen Energieeinsparung',
    programIds: ['292'],
  },
  45: {
    id: 45,
    title: 'Beteiligungen kommunale Daseinsvorsorge',
    programIds: ['148'],
  },
  46: {
    id: 46,
    title: 'Schulen',
    programIds: ['148'],
  },
  47: {
    id: 47,
    title: 'Weiterbildungseinrichtungen',
    programIds: ['148'],
  },
  48: {
    id: 48,
    title: 'Jugend- u.Veranstaltungszentren',
    programIds: ['148'],
  },
  49: {
    id: 49,
    title: 'Schwimmbäder',
    programIds: ['148'],
  },
  50: {
    id: 50,
    title: 'Sportreinrichtungen',
    programIds: ['148'],
  },
  51: {
    id: 51,
    title: 'kulturelle Einrichtungen',
    programIds: ['148'],
  },
  52: {
    id: 52,
    title: 'sonstige Maßnahmen: Bildung, Kultur, Sport',
    programIds: ['148'],
  },
  53: {
    id: 53,
    title: 'Energieverteilung',
    programIds: ['148'],
  },
  54: {
    id: 54,
    title: 'Energiespeicherung',
    programIds: ['148'],
  },
  55: {
    id: 55,
    title: 'Energieeffizienzmaßnahmen',
    programIds: ['148'],
  },
  56: {
    id: 56,
    title: 'sonstige Maßnahmen: Energie',
    programIds: ['148'],
  },
  57: {
    id: 57,
    title: 'Krankenhäuser',
    programIds: ['148'],
  },
  58: {
    id: 58,
    title: 'Altenpflege- u. Behinderteneinrichtungen',
    programIds: ['148'],
  },
  59: {
    id: 59,
    title: 'betreutes Wohnen',
    programIds: ['148'],
  },
  60: {
    id: 60,
    title: 'sonstige Maßnahmen: Gesundheit',
    programIds: ['148'],
  },
  61: {
    id: 61,
    title: 'Breitband',
    programIds: ['148'],
  },
  62: {
    id: 62,
    title: 'IT-Infrastruktur',
    programIds: ['148'],
  },
  63: {
    id: 63,
    title: 'sonstige Maßnahmen: Informationstechnologie',
    programIds: ['148'],
  },
  64: {
    id: 64,
    title: 'Grünanlagen, Spielplätze, Marktplätze',
    programIds: ['148'],
  },
  65: {
    id: 65,
    title: 'öffentliche Sicherheit',
    programIds: ['148'],
  },
  66: {
    id: 66,
    title: 'Erschließungsmaßnahmen (Baulanderschließung)',
    programIds: ['148'],
  },
  67: {
    id: 67,
    title: 'Tourismus',
    programIds: ['148'],
  },
  68: {
    id: 68,
    title: 'sonstige Maßnahmen: Stadt-und Dorfentwicklung',
    programIds: ['148'],
  },
  69: {
    id: 69,
    title: 'Straßen und Wege',
    programIds: ['148'],
  },
  70: {
    id: 70,
    title: 'Wasserstraßen',
    programIds: ['148'],
  },
  71: {
    id: 71,
    title: 'Schienenwege',
    programIds: ['148'],
  },
  72: {
    id: 72,
    title: 'Straßenbeleuchtung, Ampelanlagen',
    programIds: ['148'],
  },
  73: {
    id: 73,
    title: 'E-Mobilität inkl. Ladestationen',
    programIds: ['148'],
  },
  74: {
    id: 74,
    title: 'ÖPNV',
    programIds: ['148'],
  },
  75: {
    id: 75,
    title: 'Flughäfen/ Luftfahrt',
    programIds: ['148'],
  },
  76: {
    id: 76,
    title: 'sonstige Maßnahmen: Verkehrsinfrastruktur',
    programIds: ['148'],
  },
  77: {
    id: 77,
    title: 'sonstige Maßnahmen: Verkehrsinfrastruktur',
    programIds: ['148'],
  },
  78: {
    id: 78,
    title: 'Wasserversorgung',
    programIds: ['148'],
  },
  79: {
    id: 79,
    title: 'Abwasserentsorgung',
    programIds: ['148'],
  },
  80: {
    id: 80,
    title: 'Abfallwirtschaft',
    programIds: ['148'],
  },
  81: {
    id: 81,
    title: 'sonstige Maßnahmen: Wasser, Entsorgung',
    programIds: ['148'],
  },
  82: {
    id: 82,
    title: 'Hocheffiziente KWK-Anlage Erdgas zur Wärmever.',
    programIds: ['202'],
  },
  83: {
    id: 83,
    title: 'Hocheffiziente KWK-Anlage Biogas zur Wärmever.',
    programIds: ['202'],
  },
  84: {
    id: 84,
    title: 'Anlagen zur Nutzung industrieller Abwärme',
    programIds: ['202'],
  },
  85: {
    id: 85,
    title: 'KWKK-Systeme zur Wärme- und Kälteversorgung',
    programIds: ['202'],
  },
  86: {
    id: 86,
    title: 'Dezentrale Wärme- und Kältespeicher',
    programIds: ['202'],
  },
  87: {
    id: 87,
    title: 'Quartiersbezogene Wärmenetze',
    programIds: ['202'],
  },
  88: {
    id: 88,
    title: 'Quartiersbezogene Kältenetze: Versorgung KWKK',
    programIds: ['202'],
  },
  89: {
    id: 89,
    title: 'Energieeffiziente Motoren und Pumpen',
    programIds: ['202'],
  },
  90: {
    id: 90,
    title: 'Mess-/Regeltechnik der Ver-/ Entsorgungsanlage',
    programIds: ['202'],
  },
  91: {
    id: 91,
    title: 'Energierückgewinnungssysteme Gefällestrecken',
    programIds: ['202'],
  },
  92: {
    id: 92,
    title: 'Wärme(-rück)gewinnung öffentliche Kanalsysteme',
    programIds: ['202'],
  },
  93: {
    id: 93,
    title: 'KWK-Anlagen mit Klär- und Faulgas',
    programIds: ['202'],
  },
  94: {
    id: 94,
    title: 'Belüfter bei der aeroben Abwasserbehandlung',
    programIds: ['202'],
  },
  120: {
    id: 120,
    title: 'Verbindungsleitungen zur Weitergabe von Wärme',
    programIds: ['295'],
  },
  123: { id: 123, title: 'Optimierung  Ressourceneffiz./ Materialkreisläufe', programIds: ['240/241'] },
  124: { id: 124, title: 'Ressourceneffizienz:Rückgewinnung von Phosphor', programIds: ['240/241'] },
  125: { id: 125, title: 'Luftreinhaltung', programIds: ['240/241'] },
  126: { id: 126, title: 'Lärmminderung', programIds: ['240/241'] },
  127: { id: 127, title: 'Klimaschutzmaßnahmen durch Dekarbonisierung ', programIds: ['240/241'] },
  128: { id: 128, title: 'Anschaffung umweltfreundlicher Fahrzeuge', programIds: ['240/241'] },
  129: { id: 129, title: 'umweltfreundliches Schiff', programIds: ['240/241'] },
  130: { id: 130, title: 'sonstige umweltfreundliche Transportmittel', programIds: ['240/241'] },
  131: { id: 131, title: 'Ladeinfrastruktur / Betankungsanlagen- Landstromanlagen', programIds: ['240/241'] },
  132: { id: 132, title: 'Abfallvermeidung', programIds: ['240/241'] },
  133: { id: 133, title: 'Stoffliche Abfallverwertung', programIds: ['240/241'] },
  134: { id: 134, title: 'Energetische Abfallverwertung', programIds: ['240/241'] },
  135: { id: 135, title: 'Naturnahe Gestaltung von Firmengeländen', programIds: ['240/241'] },
  136: { id: 136, title: 'Abfallvorbehandlung', programIds: ['240/241'] },
  137: { id: 137, title: 'Abwasservermeidung', programIds: ['240/241'] },
  138: { id: 138, title: 'Frischwassereinsparung', programIds: ['240/241'] },
  139: { id: 139, title: 'Abwasserbehandlung', programIds: ['240/241'] },
  140: { id: 140, title: 'Bodenschutz/ Grundwasserschutz/Flächensanierung', programIds: ['240/241'] },
  141: { id: 141, title: 'Altlastensanierung', programIds: ['240/241'] },
  142: { id: 142, title: 'Deponiesanierung', programIds: ['240/241'] },
  143: { id: 143, title: 'Anpassung an den Klimawandel', programIds: ['240/241'] },
  144: { id: 144, title: 'Vorhaben neu EU: Betriebsmittel', programIds: ['360/361/364'] },
  145: { id: 145, title: 'Vorhaben neu EU: AfA auf Investitionen', programIds: ['360/361/364'] },
  146: { id: 146, title: 'Vorhaben neu EU: Kosten vereinf. ermittelt', programIds: ['360/361/364'] },
  147: { id: 147, title: 'Vorhaben neu Unternehmen: Investitionen', programIds: ['380'] },
  148: { id: 148, title: 'Vorhaben neu Unternehmen: Betriebsmittel', programIds: ['360/361/364', '380'] },
  149: { id: 149, title: 'Vorhaben neu Unternehmen: AfA Investitionen', programIds: ['360/361/364'] },
  150: {
    id: 150,
    title: 'Vorhaben neu Unternehmen: vereinfachteKosten',
    programIds: ['360/361/364', '380'],
  },
  151: { id: 151, title: 'innovatives Unternehmen: Investitionen', programIds: ['380'] },
  152: { id: 152, title: 'innovatives Unternehmen: Betriebsmittel', programIds: ['380'] },
  153: { id: 153, title: 'Digitalisierungsvorhaben: Investitionen', programIds: ['380'] },
  154: { id: 154, title: 'Digitalisierungsvorhaben: Betriebsmittel', programIds: ['380'] },
  155: { id: 155, title: 'Digitalisierungsvorhaben: vereinfachte Kosten', programIds: ['380'] },
  156: { id: 156, title: 'Digitalisierung in Krankenhäusern', programIds: ['206'] },
  198: {
    id: 198,
    title: 'Hocheffiziente Elektromotoren und -antriebe',
    programIds: ['295'],
  },
  199: {
    id: 199,
    title: 'Drehzahlregelung elektr. Motoren und Antriebe',
    programIds: ['295'],
  },
  200: {
    id: 200,
    title: 'Nassläufer-Umwälzpumpen',
    programIds: ['295'],
  },
  201: {
    id: 201,
    title: 'Kreisel- und Trockenläuferpumpen',
    programIds: ['295'],
  },
  202: {
    id: 202,
    title: 'Frequenzumrichter / Drehzahlreg. bei Pumpen',
    programIds: ['295'],
  },
  203: {
    id: 203,
    title: 'Hocheffiziente Ventilatoren',
    programIds: ['295'],
  },
  204: {
    id: 204,
    title: 'Drehzahlregelung bei Ventilatoren',
    programIds: ['295'],
  },
  205: {
    id: 205,
    title: 'Wärmerückgewinnung',
    programIds: ['295'],
  },
  206: {
    id: 206,
    title: 'Hocheffiziente Drucklufterzeuger',
    programIds: ['295'],
  },
  207: {
    id: 207,
    title: 'Nachrüstung übergeordnete Steuerung',
    programIds: ['295'],
  },
  208: {
    id: 208,
    title: 'Ultraschallmessgeräte Auffinden von Leckagen',
    programIds: ['295'],
  },
  209: {
    id: 209,
    title: 'Wärmerückgewinnung Drucklufterzeugungsanlagen',
    programIds: ['295'],
  },
  210: {
    id: 210,
    title: 'Anlagen Abwärmenutzung aus Abwasser',
    programIds: ['295'],
  },
  211: {
    id: 211,
    title: 'Therm. Isolierung/Dämmung von industr. Anlagen',
    programIds: ['295'],
  },
  212: {
    id: 212,
    title: 'Solarkollektoranlagen',
    programIds: ['295'],
  },
  213: {
    id: 213,
    title: 'Biomasse-Anlagen',
    programIds: ['295'],
  },
  214: {
    id: 214,
    title: 'Wärmepumpen',
    programIds: ['295'],
  },
  215: {
    id: 215,
    title: 'Energiemanagement-Softwarelösungen',
    programIds: ['295'],
  },
  216: {
    id: 216,
    title: 'Messtechnik und Sensorik',
    programIds: ['295'],
  },
  217: {
    id: 217,
    title: 'Steuerungs- und Regelungstechnik',
    programIds: ['295'],
  },
  218: {
    id: 218,
    title: 'Prozess- und Verfahrensumstellungen',
    programIds: ['295'],
  },
  219: {
    id: 219,
    title: 'Maßnahmen zur Abwärmenutzung',
    programIds: ['295'],
  },
  220: {
    id: 220,
    title: 'Maßn.an Anl. zur Wärmevers.,Kühlung u. Belüft.',
    programIds: ['295'],
  },
  221: {
    id: 221,
    title: 'Maßnahmen Prozesswärme/-kälte',
    programIds: ['295'],
  },
  222: {
    id: 222,
    title: 'Maßn. Vermeidung Energie- bzw. Ress.verluste',
    programIds: ['295'],
  },
  223: {
    id: 223,
    title: 'Einsparkonzept und Umsetzungsbegleitung',
    programIds: ['295'],
  },
  226: { id: 226, title: 'Klimafreundliche Produktionsverfahren', programIds: ['293'] },
  227: { id: 227, title: 'Erzeugung Strom, Wärme, Kälte aus Erneuerbaren Energien', programIds: ['293'] },
  230: {
    id: 230,
    title: 'Stromverteilnetze und Erzeugung Strom, Wärme, Kälte aus Abwärme und Gas',
    programIds: ['293'],
  },
  232: { id: 232, title: 'Erzeugung Fernwärme/-kälte', programIds: ['293'] },
  233: { id: 233, title: 'Verteilnetze Abwärmenutzung , Fernwärme/-kälte', programIds: ['293'] },
  234: { id: 234, title: 'Energiespeicher', programIds: ['293'] },
  235: { id: 235, title: 'Herstellung Biomasse, Biogas, Biokraftstoffe', programIds: ['293'] },
  236: { id: 236, title: 'Wasser-, Abwasser-, Abfallmanagement', programIds: ['293'] },
  237: { id: 237, title: 'CO2 Transport/ Speicherung', programIds: ['293'] },
  238: { id: 238, title: 'Integrierte Mobilitätsvorhaben', programIds: ['293'] },
  239: { id: 239, title: 'Green IT', programIds: ['293'] },
  240: { id: 240, title: 'Herstellung klimafreundlicher Technologien', programIds: ['293'] },
  242: { id: 242, title: 'Aktive Komponenten eines FTTH-/FTTB-Netzes', programIds: ['206'] },
  243: { id: 243, title: 'Errichtung eines passiven FTTH-/FTTB-Netzes', programIds: ['206'] },
  244: { id: 244, title: 'Klimaschutz außerhalb Energieeffizienz', programIds: ['240/241'] },
  250: { id: 250, title: 'Neubau Effizienzgebäude 40 Nachhaltigkeit', programIds: ['263'] },
  258: { id: 258, title: 'Sanierung Effizienzgebäude Denkmal', programIds: ['263'] },
  259: { id: 259, title: 'Sanierung Effizienzgebäude Denkmal Ern. En.', programIds: ['263'] },
  260: { id: 260, title: 'Sanierung Effizienzgebäude Denkmal Nachhaltig.', programIds: ['263'] },
  264: { id: 264, title: 'Sanierung Effizienzgebäude 70', programIds: ['263'] },
  265: { id: 265, title: 'Sanierung Effizienzgebäude 70 Erneuerbare En.', programIds: ['263'] },
  266: { id: 266, title: 'Sanierung Effizienzgebäude 70 Nachhaltigkeit', programIds: ['263'] },
  267: { id: 267, title: 'Sanierung Effizienzgebäude 55', programIds: ['263'] },
  268: { id: 268, title: 'Sanierung Effizienzgebäude 55 Erneuerbare En.', programIds: ['263'] },
  269: { id: 269, title: 'Sanierung Effizienzgebäude 55 Nachhaltigkeit', programIds: ['263'] },
  270: { id: 270, title: 'Sanierung Effizienzgebäude 40', programIds: ['263'] },
  271: { id: 271, title: 'Sanierung Effizienzgebäude 40 Erneuerbare En.', programIds: ['263'] },
  272: { id: 272, title: 'Sanierung Effizienzgebäude 40 Nachhaltigkeit', programIds: ['263'] },
  285: { id: 285, title: 'Fachplanung und Baubegleitung', programIds: ['263'] },
  286: { id: 286, title: 'Nachhaltigkeitszertifizierung', programIds: ['263'] },
  319: { id: 319, title: 'Elektrischer Quartiersspeicher', programIds: ['202'] },
  320: { id: 320, title: 'Energetisches Lastenmanagement Verkehrssektor', programIds: ['202'] },
  321: { id: 321, title: 'Stellfläche für Fahrzeuge mit alt. Antrieben', programIds: ['202'] },
  322: { id: 322, title: 'klimafreundliche Umgestaltung öffentl. Räume', programIds: ['202'] },
  323: { id: 323, title: 'Grüne Infrastruktur', programIds: ['202'] },
  324: { id: 324, title: 'Wassersensible Gestaltung öffentlicher Räume', programIds: ['202'] },
  325: { id: 325, title: 'Energieeffiziente Bewässerungsanlagen', programIds: ['202'] },
  329: { id: 329, title: 'Fahrzeuge für ÖPNV und Regionalverkehr', programIds: ['268/269'] },
  330: { id: 330, title: 'Fernzüge zur Personenbeförderung', programIds: ['268/269'] },
  331: { id: 331, title: 'Fahrzeuge zur Personenbeförderung Straßenfernverkehr', programIds: ['268/269'] },
  332: { id: 332, title: 'Schiffe zur Personenbeförderung', programIds: ['268/269'] },
  333: { id: 333, title: 'Pkw, Krafträder und leichte Nutzfahrzeuge', programIds: ['268/269'] },
  334: { id: 334, title: 'Fahrzeuge für aktive Mobilität', programIds: ['268/269'] },
  335: { id: 335, title: 'Züge zur Güterbeförderung', programIds: ['268/269'] },
  336: { id: 336, title: 'Schwere Nutzfahrzeuge zur Güterbeförderung Straße', programIds: ['268/269'] },
  337: { id: 337, title: 'Schiffe Güterbeförderung', programIds: ['268/269'] },
  338: { id: 338, title: 'Infrastruktur für aktive Mobilität', programIds: ['268/269'] },
  339: { id: 339, title: 'Infrastruktur für ÖPNV und Regionalverkehr', programIds: ['268/269'] },
  340: { id: 340, title: 'Infrastruktur für klimafreundlichen Verkehr Straße', programIds: ['268/269'] },
  341: { id: 341, title: 'Infrastruktur für klimafreundlichen Verkehr Wasser', programIds: ['268/269'] },
  342: { id: 342, title: 'Infrastruktur für klimafreundlichen Verkehr Luft', programIds: ['268/269'] },
  343: { id: 343, title: 'Digitalisierung für nachhaltige Mobilität', programIds: ['268/269'] },
  344: { id: 344, title: 'Maßn. zur außerbetrieblichen Abwärmenutzung', programIds: ['295'] },
  345: { id: 345, title: 'Wohnen Neubau/Ersterwerb', programIds: ['148'] },
  346: { id: 346, title: 'Wohnen Bestand', programIds: ['148'] },
  347: { id: 347, title: 'Wohnen Einzelmaßnahmen', programIds: ['148'] },
  349: { id: 349, title: 'Sanierung EG 55 Worst Performing Buildings', programIds: ['263'] },
  350: { id: 350, title: 'Sanierung EG 55 EE Worst Performing Buildings', programIds: ['263'] },
  351: { id: 351, title: 'Sanierung EG 55 NH Worst Performing Buildings', programIds: ['263'] },
  352: { id: 352, title: 'Sanierung EG 40 Worst Performing Buildings', programIds: ['263'] },
  353: { id: 353, title: 'Sanierung EG 40 EE Worst Performing Buildings', programIds: ['263'] },
  354: { id: 354, title: 'Sanierung EG 40 NH Worst Performing Buildings', programIds: ['263'] },
  357: { id: 357, title: 'Natürliche Klimaschutzmaßnahmen', programIds: ['240/241'] },
  358: { id: 358, title: 'Sanierung EG 70 EE Worst Performing Buildings', programIds: ['263'] },
  359: { id: 359, title: 'Tiefengeothermieanlagen', programIds: ['295'] },
  360: { id: 360, title: 'Konzentrierende Solarthermie', programIds: ['295'] },
  361: { id: 361, title: 'Biogasanlagen', programIds: ['295'] },
  362: { id: 362, title: 'Elektrifizierung von KU', programIds: ['295'] },
};

const programs: { [key: string]: Program } = {
  '67': {
    id: '67',
    fundingPurposeIds: [2],
    usagePurposeIds: [2, 3, 4, 5],
    title: 'ERP-Gründerkredit – StartGeld',
    subtitle: 'Bis zu 125.000 Euro für Ihr Gründungsvorhaben',
    description: [
      'Finanzierung von Investitionen und laufenden Kosten',
      'Existenzgründung und Festigung im Neben- oder Vollerwerb bis zu 5 Jahre nach Gründung',
      'Leichter Kreditzugang: KfW übernimmt 80% des Kreditrisikos',
    ],
  },
  '268/269': {
    id: '268/269',
    fundingPurposeIds: [7],
    usagePurposeIds: [329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340, 341, 342, 343],
    title: 'Investitionskredit Nach­haltige Mobilität',
    subtitle: 'Investieren Sie in nachhaltige und klima­freundliche Mobilität',
    description: [
      'Für grüne Verkehrsprojekte in Ihrem Unternehmen und im öffentlichen Raum',
      'als Standardvariante (268): bis zu 50 Millionen Euro Kredit pro Vorhaben',
      'als Individualvariante (269): individuell ab 15 Millionen Euro pro Vorhaben',
      ' bis zu 100 % Ihrer Investitionskosten',
    ],
  },
  '270': {
    id: '270',
    fundingPurposeIds: [5],
    usagePurposeIds: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
    title: 'Erneuerbare Energien – Standard',
    subtitle: 'Der Förderkredit für Strom und Wärme',
    description: [
      'Für Anlagen zur Erzeugung von Strom und Wärme, für Netze und Speicher',
      'Bis zu 50 Mio. Euro Kreditbetrag',
      'Für Photovoltaik, Wasser, Wind, Biogas und vieles mehr',
    ],
  },
  '292': {
    id: '292',
    fundingPurposeIds: [6],
    usagePurposeIds: [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37],
    title: 'Energieeffizienz in der Produktion',
    subtitle: 'Energiekosten im laufenden Betrieb einsparen',
    description: [
      'Förderkredit bis zu 25 Mio. Euro',
      'Bis zu 50 Mio. Euro Kreditbetrag',
      'Für Neuinvestitionen und Modernisierungen im In- und Ausland',
    ],
  },
  '295': {
    id: '295',
    fundingPurposeIds: [6],
    usagePurposeIds: [
      120, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219,
      220, 221, 222, 223, 344, 359, 360, 361, 362
    ],
    title: 'Bundesförderung für Energieeffizienz in der Wirtschaft',
    subtitle: 'Energiekosten durch hocheffiziente Technologien minimieren',
    description: [
      'Förderkredit bis zu 25 Mio. Euro',
      'Hohe Förderung für besonders effiziente Komponenten, Anlagen und Lösungen',
      'Weniger zurückzahlen: bis zu 55 % Tilgungszuschuss',
    ],
  },
  '58': {
    id: '58',
    fundingPurposeIds: [2],
    usagePurposeIds: [2, 4, 5],
    title: 'ERP-Kapital für Gründung',
    subtitle: 'Bis zu 500.000 Euro zur Stärkung des Eigenkapitals Ihres neuen Unternehmens',
    description: [
      'Keine Tilgungsraten in den ersten 7 Jahren, nur Zinszahlungen',
      'Existenzgründungen und Festigungen bis zu 3 Jahre nach Gründung',
      'Leichter Kreditzugang: KfW übernimmt das Kreditrisiko',
    ],
  },
  '240/241': {
    id: '240/241',
    fundingPurposeIds: [8],
    usagePurposeIds: [
      123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 244, 357
    ],
    title: 'KfW-Umweltprogramm',
    subtitle: 'Umwelt schützen und Ressourcen schonen',
    description: [
      'Bis zu 25 Mio. Euro Kreditbetrag',
      'Für Vorhaben im In- und Ausland',
      'Für Unternehmen und Freiberufler',
    ],
  },
  '293': {
    id: '293',
    fundingPurposeIds: [10],
    usagePurposeIds: [240, 226, 227, 230, 233, 232, 234, 235, 236, 237, 238, 239],
    title: 'Klimaschutzoffensive für Unternehmen',
    subtitle: 'Förderung von klimafreundlichen Aktivitäten in Anlehnung an die EU-Taxonomie',
    description: [
      'Bis zu 25 Mio. Euro Kreditbetrag',
      'Für Investitionen in Klimaschutzmaßnahmen in der EU',
      'Für Unternehmen und Freiberufler',
    ],
  },
  '360/361/364': {
    id: '360/361/364',
    fundingPurposeIds: [9],
    usagePurposeIds: [144, 145, 146, 148, 149, 150],
    title: 'ERP-Mezzanine für Innovation',
    subtitle: 'Das Finanzierungspaket für die Entwicklung neuer Produkte und Prozesse',
    description: [
      'Finanzierungspaket bis zu 5 Mio. Euro',
      'Für die Entwicklung neuer Produkte, Prozesse und Dienstleistungen',
      'Entlastet Ihr Unternehmen bei der Sicherheitenstellung',
    ],
  },
  '380': {
    id: '380',
    fundingPurposeIds: [9],
    usagePurposeIds: [147, 148, 150, 151, 152, 153, 154, 155],
    title: 'ERP-Digitalisierungskredit',
    subtitle: 'Der günstige Förderkredit für zukunftsweisende Vorhaben',
    description: [
      'Kreditbetrag zwischen 25.000 und 25 Mio. Euro',
      'Für Investitionen und Betriebsmittel in den Bereichen Innovation und Digitalisierung',
      'Für Unternehmen und Freiberufler',
    ],
  },
  '148': {
    id: '148',
    fundingPurposeIds: [7],
    usagePurposeIds: [
      3, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72,
      73, 74, 75, 76, 77, 78, 79, 80, 81, 188, 345, 346, 347,
    ],
    title: 'IKU – Kommunale und Soziale Unternehmen',
    subtitle: 'Bauen Sie die Infra­struktur in der Kommune aus',
    description: [
      'Förderkredit bis zu 50 Mio. Euro für viele verschiedene Verwendungszwecke',
      'Bis zu 30 Jahre Kreditlaufzeit und Zins­bindung für 10 oder 20 Jahre',
      'Kombination mit weiteren Förder­mitteln möglich',
    ],
  },
  '202': {
    id: '202',
    fundingPurposeIds: [7],
    usagePurposeIds: [82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 319, 320, 321, 322, 323, 324, 325],
    title: 'IKU – Quartiersversorgung',
    subtitle: 'Investieren Sie in energieeffiziente Versorgungssysteme',
    description: [
      'Förderkredit bis zu 50 Mio. Euro mit 10 % Tilgungszuschuss',
      'Für Investitionen in effiziente Systeme für Wärme, Kälte, Wasser und Abwasser',
      'Finanzierung zu 100 % möglich',
    ],
  },
  '206': {
    id: '206',
    fundingPurposeIds: [7, 9],
    usagePurposeIds: [242, 243, 156, 242, 243, 156],
    title: 'Investitionskredit Digitale Infrastruktur',
    subtitle: 'Bauen Sie Glasfasernetze aus',
    description: [
      'Als Standardkredit: bis zu 50 Mio. Euro',
      'Als Individualkredit: ab 25 Mio. Euro',
      'Bis zu 30 Jahre Kredit­laufzeit und bis zu 20 Jahre Zinsbindung',
    ],
  },
  '263': {
    id: '263',
    fundingPurposeIds: [6],
    usagePurposeIds: [
      250, 258, 259, 260, 264, 265, 266, 267, 268, 269, 270, 271, 272, 285, 286,
      349, 350, 351, 352, 353, 354 ,358
    ],
    title: 'Bundesförderung für Effiziente Gebäude - Nichtwohngebäude',
    subtitle: 'Gebäude energieeffizient bauen und sanieren',
    description: [
      'Bis zu 30 Mio. Euro Kredit für Effizienzgebäude bzw. bis zu 15 Mio. Euro für Einzelmaßnhmen',
      'Weniger zurückzahlen: zwischen 15 % und 50 % Tilgungszuschuss',
      'Zusätzliche Förderung möglich, z. B. für Baubegleitung und Nachhaltigkeits­zertifizierung',
    ],
  },
  '375': {
    id: '375',
    fundingPurposeIds: [1, 2],
    usagePurposeIds: [2, 3, 4, 5],
    title: 'ERP-Förderkredit großer Mittelstand ohne Haftungsfreistellung',
    subtitle: 'Kredit für Investitionen, Übernahmen, Warenlager und Betriebsmittel',
    description: [
      'Bis zu 25 Mio. Euro Kredit für Investitionen, Übernahmen, Warenlager und Betriebsmittel',
      'Bis zu 3 Jahre keine Tilgungsraten, nur Zinszahlungen',
      'Günstige Zinsen',
    ],
  },
  '365': {
    id: '365',
    fundingPurposeIds: [1, 2],
    usagePurposeIds: [2, 3, 4, 5],
    title: 'ERP-Förderkredit KMU ohne Haftungsfreistellung',
    subtitle: 'Kredit für Investitionen, Übernahmen, Warenlager und Betriebsmittel',
    description: [
      'Bis zu 25 Mio. Euro Kredit für Investitionen, Übernahmen, Warenlager und Betriebsmittel',
      'Bis zu 3 Jahre keine Tilgungsraten, nur Zinszahlungen',
      'Besonders günstige Zinsen für Unternehmen jünger als 5 Jahre und für Vorhaben in Regionalfördergebieten',
    ],
  },
  '376': {
    id: '376',
    fundingPurposeIds: [1, 2],
    usagePurposeIds: [2, 3, 4, 5],
    title: 'ERP-Förderkredit großer Mittelstand mit Haftungsfreistellung',
    subtitle: 'Kredit für Investitionen, Übernahmen, Warenlager und Betriebsmittel',
    description: [
      'Bis zu 3 Jahre keine Tilgungsraten, nur Zinszahlungen',
      'Leichter Kreditzugang: KfW übernimmt 50 % des Kreditrisikos',
      'Günstige Zinsen',
    ],
  },
  '366': {
    id: '366',
    fundingPurposeIds: [1, 2],
    usagePurposeIds: [2, 3, 4, 5],
    title: 'ERP-Förderkredit KMU mit Haftungsfreistellung',
    subtitle: 'Kredit für Investitionen, Übernahmen, Warenlager und Betriebsmittel',
    description: [
      'Bis zu 3 Jahre keine Tilgungsraten, nur Zinszahlungen',
      'Besonders günstige Zinsen für Unternehmen jünger als 5 Jahre und für Vorhaben in Regionalfördergebieten',
      'Leichter Kreditzugang: KfW übernimmt 50 % des Kreditrisikos',
    ],
  },
  '79/89': {
    id: '79/89',
    fundingPurposeIds: [1],
    usagePurposeIds: [2, 3],
    title: 'KfW Sonderprgramm UBR 2022',
    subtitle:
      'Kredit für Unternehmen die von Sanktionen ggü. Russland/Belarus oder Kriegshandlungen in der Ukraine betroffen sind',
    description: [
      'Nur zulässig für Unternehmen die mind. seit 2 Jahren am Markt aktiv sind',
      'Bis zu 100 Millionen Euro Kredit pro Vorhaben',
      'Variante für Unternehmen bis 500 Mio. EUR Umsatz (89) mit 80% Haftungsfreistellung und höheren Umsatz (79) mit 70% Haftungsfreistellung',
    ],
  },
};

const getProgramById = (id: string) => {
  // if program found return it
  const program = programs[id];
  if (program) return program;

  // otherwise find out if the id is not part of some composite key and return that program
  const programId = Object.keys(programs).find((program) => program.split('/').some((key: string) => key === id));
  if (programId) return programs[programId];
};

/**
 * Get programs by funding purpose Id
 * @param fundingPurposeId
 */
const getProgramsByFundingPurpose = (fundingPurposeId: string) => {
  // if corona funding purpose was selected get all corona program IDs
  const programIds: string[] = fundingPurposes[fundingPurposeId]?.programIds;

  // create unique set of corona program ids and map corresponding program details to them
  return programIds
    ? [...new Set(programIds)].map((id: string) => programs[id]).filter((option: Program) => option)
    : [];
};

const getFundingPurposeById = (id: string) => fundingPurposes[id];

export { fundingPurposes, usagePurposes, programs, getProgramById, getFundingPurposeById, getProgramsByFundingPurpose };
