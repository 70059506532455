/* eslint-disable no-param-reassign */
import type { /* MutableState, */ Mutator } from 'final-form';

// TODO fix types, when we have proper data structure, change state to MutableState
const setFieldError: Mutator = (args: any[], state: any) => {
  const [name, error] = args;

  const field = state.fields[name];

  if (field) {
    field.error = error;
  }
};

export default setFieldError;
