import 'react-app-polyfill/ie11';
import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { ONE_APP, ONE_APP_SUMMARY } from 'common/constants/routes';
import Footer from 'components/layout/Footer';
import ContextNotification from 'components/notifications/ContextNotification';
import Logo from 'components/Logo';
import { getViewportSize, getScreenOrientation } from '../../components/MappDL';

import OneAppForm from './OneAppForm/OneAppForm';

import OneAppStyles from './OneAppStyles';
import { OneAppContextProvider } from './context/OneAppContext';

import Summary from './Summary';

const useStyles = makeStyles((theme) => ({
  main: {
    background: '#fff',
    minHeight: '100vh',
    height: 'auto',
    paddingBottom: '100px',
  },
  contentContainer: {
    padding: '70px 20px 20px 20px',
  },
  logoContainer: {
    position: 'fixed',
    paddingTop: '30px',
    paddingLeft: '50px',
    top: '0px',
    height: '120px',
    left: '0px',
    zIndex: 1299,
    [theme.breakpoints.down('sm')]: {
      height: '70px',
      paddingTop: '10px',
      background: '#FFF',
    },
    [theme.breakpoints.up('md')]: {
      width: '190px',
    },
  },
}));

const OneApp = () => {
  const classes = useStyles();
  const initialOrientation = getScreenOrientation();
  const [orientation, setOrientation] = useState<string>(initialOrientation);
  const [shouldModalOpen, setShouldModalOpen] = useState<boolean>(true);

  /* eslint-disable-next-line func-names */
  window.addEventListener('orientationchange', function () {
    const newOrientation = getScreenOrientation();
    setOrientation(newOrientation);
  });

  const openModal = () => {
    if (shouldModalOpen) {
      setShouldModalOpen(false);
    }
  };

  useEffect(() => {
    const viewportSize = getViewportSize();
    if (
      window.localStorage.getItem('MIUCconsentGiven') &&
      window.localStorage.getItem('MIUCconsentGiven') === 'true' &&
      window.localStorage.getItem('uc_cn') &&
      window.localStorage.getItem('uc_cn') === 'true'
    ) {
      window.wts.push([
        'send',
        'link',
        {
          linkId: 'turn',
          customParameter: {
            5: `Viewport ${viewportSize}`,
          },
        },
      ]);
      window.mappDL.pp5 = `Viewport ${viewportSize}`;
    }
  }, [orientation]);

  return (
    <div onMouseLeave={openModal}>
      <div className={classes.main}>
        <Grid container className={classes.logoContainer} role="banner">
          <Logo />
        </Grid>

        <Grid container className={classes.contentContainer} role="main">
          <OneAppContextProvider>
            <Switch>
              <Route exact path={ONE_APP} component={OneAppForm} />
              <Route exact path={ONE_APP_SUMMARY} component={Summary} />
              <Route>
                <Redirect to={ONE_APP} />
              </Route>
            </Switch>
          </OneAppContextProvider>
        </Grid>
      </div>
      <Footer />
      <ContextNotification />
    </div>
  );
};

export default withStyles(OneAppStyles)(OneApp);
