import { OneAppForPDF } from 'types/OneAppModels';
import axiosClient from './axiosClient';

import { Components } from '../../types/models';
import { AZURE_FUNCTION_API_BASE } from './routes';

export const client = axiosClient();

export const getCurrentUser = async () => {
  const response = await client.get('/users/current');
  return response.data;
};

// OneApp related api requests

export const submitOneAppCreditRequest = async (
  creditRequest: Components.Schemas.CreditRequestOneApp,
): Promise<Components.Schemas.CreatedOneAppCreditRequestResponse> => {
  const response = await client.post('/creditRequests/oneApp/createCreditRequest', creditRequest);
  return response.data;
};

export const getSession = async (): Promise<Components.Schemas.SessionResponse> => {
  const response = await client.get('/auth/session');
  return response.data;
};

export const generateOneAppPdf = async (creditRequest: OneAppForPDF): Promise<{ data: Blob; fileName: string }> => {
  const { data, headers } = await client.post('/GenerateOneAppPdf', creditRequest, {
    baseURL: AZURE_FUNCTION_API_BASE,
    responseType: 'blob',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  });

  const fileName = headers['content-disposition']?.split('filename=')?.[1];
  return { data, fileName };
};

export const generateIKKPdf = async (
  creditRequest: Components.Schemas.CreditRequestIKK,
): Promise<{ data: Blob; fileName: string }> => {
  const { data, headers } = await client.post('/GenerateIKKPdf', creditRequest, {
    baseURL: AZURE_FUNCTION_API_BASE,
    responseType: 'blob',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  });

  const fileName = headers['content-disposition']?.split('filename=')?.[1];
  return { data, fileName };
};

export const importOneAppRegisteredCreditRequest = async () => {
  const response = await client.post('/oneAppRegistered/import');
  return response.data;
};

export const getOneAppRegisteredCreditRequests = async (): Promise<
  Components.Schemas.CreditRequestOneAppRegistered[]
> => {
  const response = await client.get('/oneAppRegistered');
  return response.data;
};

export const getOneAppRegisteredCreditRequestByID = async (
  id: string,
): Promise<Components.Schemas.CreditRequestOneAppRegistered> => {
  const response = await client.get(`/oneAppRegistered/${id}`);
  return response.data;
};

export const deleteOneAppRegisteredCreditRequestByID = async (
  id: string,
): Promise<Components.Schemas.CreditRequestOneAppRegistered> => {
  const response = await client.delete(`/oneAppRegistered/${id}`);
  return response.data;
};

// IKK related api requests

export const submitIKKCreditRequest = async (
  creditRequest: Components.Schemas.CreditRequestIKK,
): Promise<Components.Schemas.CreatedIKKRequestResponse> => {
  const response = await client.post('/ikk/createIKCreditRequest', creditRequest);
  return response.data;
};
