export default [
  { id: 0, title: 'deutsch' },
  { id: 121, title: 'albanisch' },
  { id: 122, title: 'bosnisch-herzegowinisch' },
  { id: 123, title: 'andorranisch' },
  { id: 124, title: 'belgisch' },
  { id: 125, title: 'bulgarisch' },
  { id: 126, title: 'dänisch' },
  { id: 127, title: 'estnisch' },
  { id: 128, title: 'finnisch' },
  { id: 129, title: 'französisch' },
  { id: 130, title: 'kroatisch' },
  { id: 131, title: 'slowenisch' },
  { id: 132, title: 'serbisch-montenegrinisch' },
  { id: 134, title: 'griechisch' },
  { id: 135, title: 'irisch' },
  { id: 136, title: 'isländisch' },
  { id: 137, title: 'italienisch' },
  { id: 139, title: 'lettisch' },
  { id: 140, title: 'montenegrinisch' },
  { id: 141, title: 'liechtensteinisch' },
  { id: 142, title: 'litauisch' },
  { id: 143, title: 'luxemburgisch' },
  { id: 144, title: 'mazedonisch' },
  { id: 145, title: 'maltesisch' },
  { id: 146, title: 'moldauisch' },
  { id: 147, title: 'monegassisch' },
  { id: 148, title: 'niederländisch' },
  { id: 149, title: 'norwegisch' },
  { id: 150, title: 'kosovarisch' },
  { id: 151, title: 'österreichisch' },
  { id: 152, title: 'polnisch' },
  { id: 153, title: 'portugiesisch' },
  { id: 154, title: 'rumänisch' },
  { id: 155, title: 'slowakisch' },
  { id: 156, title: 'san-marinesisch' },
  { id: 157, title: 'schwedisch' },
  { id: 158, title: 'schweizerisch' },
  { id: 160, title: 'russisch' },
  { id: 161, title: 'spanisch' },
  { id: 163, title: 'türkisch' },
  { id: 164, title: 'tschechisch' },
  { id: 165, title: 'ungarisch' },
  { id: 166, title: 'ukrainisch' },
  { id: 167, title: 'vatikanisch' },
  { id: 168, title: 'britisch' },
  { id: 169, title: 'weißrussisch (belarussisch)' },
  { id: 170, title: 'serbisch' },
  { id: 181, title: 'zyprisch' },
  { id: 199, title: 'sonst. europ. Staatsangeh.' },
  { id: 221, title: 'algerisch' },
  { id: 223, title: 'angolanisch' },
  { id: 224, title: 'eritreisch' },
  { id: 225, title: 'äthiopisch' },
  { id: 226, title: 'lesothisch' },
  { id: 227, title: 'botsuanisch' },
  { id: 229, title: 'beninisch' },
  { id: 230, title: 'dschibutisch' },
  { id: 231, title: 'ivorisch' },
  { id: 232, title: 'nigerianisch' },
  { id: 233, title: 'simbabwisch' },
  { id: 236, title: 'gabunisch' },
  { id: 237, title: 'gambisch' },
  { id: 238, title: 'ghanaisch' },
  { id: 239, title: 'mauretanisch' },
  { id: 242, title: 'kap-verdisch' },
  { id: 243, title: 'kenianisch' },
  { id: 244, title: 'komorisch' },
  { id: 245, title: 'kongolesisch (Republik Kongo)' },
  { id: 246, title: 'kongolesisch (Demokratische Republik Kongo)' },
  { id: 247, title: 'liberianisch' },
  { id: 248, title: 'libysch' },
  { id: 249, title: 'madagassisch' },
  { id: 251, title: 'malisch' },
  { id: 252, title: 'marokkanisch' },
  { id: 253, title: 'mauritisch' },
  { id: 254, title: 'mosambikanisch' },
  { id: 255, title: 'nigrisch' },
  { id: 256, title: 'malawisch' },
  { id: 257, title: 'sambisch' },
  { id: 258, title: 'burkinisch' },
  { id: 259, title: 'guinea-bissauisch' },
  { id: 261, title: 'guineisch' },
  { id: 262, title: 'kamerunisch' },
  { id: 263, title: 'südafrikanisch' },
  { id: 265, title: 'ruandisch' },
  { id: 267, title: 'namibisch' },
  { id: 268, title: 'sÔo-toméisch' },
  { id: 269, title: 'senegalesisch' },
  { id: 271, title: 'seychellisch' },
  { id: 272, title: 'sierra-leonisch' },
  { id: 273, title: 'somalisch' },
  { id: 274, title: 'äquatorialguineisch' },
  { id: 276, title: 'sudanesisch' },
  { id: 281, title: 'swasiländisch' },
  { id: 282, title: 'tansanisch' },
  { id: 283, title: 'togoisch' },
  { id: 284, title: 'tschadisch' },
  { id: 285, title: 'tunesisch' },
  { id: 286, title: 'ugandisch' },
  { id: 287, title: 'ägyptisch' },
  { id: 289, title: 'zentralafrikanisch' },
  { id: 291, title: 'burundisch' },
  { id: 299, title: 'sonst. afrik. Staatsangeh.' },
  { id: 320, title: 'antiguanisch' },
  { id: 322, title: 'barbadisch' },
  { id: 323, title: 'argentinisch' },
  { id: 324, title: 'bahamalsch' },
  { id: 326, title: 'bolivianisch' },
  { id: 327, title: 'brasilianisch' },
  { id: 328, title: 'guyanisch' },
  { id: 330, title: 'belizisch' },
  { id: 332, title: 'chilenisch' },
  { id: 333, title: 'dominicanisch' },
  { id: 334, title: 'costa-ricanisch' },
  { id: 335, title: 'dominikanisch' },
  { id: 336, title: 'ecuadorianisch' },
  { id: 337, title: 'salvadorianisch' },
  { id: 340, title: 'grenadisch' },
  { id: 345, title: 'guatemaltekisch' },
  { id: 346, title: 'haitianisch' },
  { id: 347, title: 'honduranisch' },
  { id: 348, title: 'kanadisch' },
  { id: 349, title: 'kolumbianisch' },
  { id: 351, title: 'kubanisch' },
  { id: 353, title: 'mexikanisch' },
  { id: 354, title: 'nicaraguanisch' },
  { id: 355, title: 'jamaikanisch' },
  { id: 357, title: 'panamaisch' },
  { id: 359, title: 'paraguayisch' },
  { id: 361, title: 'peruanisch' },
  { id: 364, title: 'surinamisch' },
  { id: 365, title: 'uruguayisch' },
  { id: 366, title: 'lucianisch' },
  { id: 367, title: 'venezolanisch' },
  { id: 368, title: 'amerikanisch' },
  { id: 369, title: 'vincentisch' },
  { id: 370, title: 'von St. Kitts und Nevis' },
  { id: 371, title: 'von Trinidad und Tobago' },
  { id: 399, title: 'sonst, amerik. Staatsangeh.' },
  { id: 421, title: 'jemenitisch' },
  { id: 422, title: 'armenisch' },
  { id: 423, title: 'afghanisch' },
  { id: 424, title: 'bahrainisch' },
  { id: 425, title: 'aserbaidschanisch' },
  { id: 426, title: 'bhutanisch' },
  { id: 427, title: 'myanmarisch' },
  { id: 429, title: 'bruneiisch' },
  { id: 430, title: 'georgisch' },
  { id: 431, title: 'sri-lankisch' },
  { id: 432, title: 'vietnamesisch' },
  { id: 434, title: 'koreanisch (Nordkorea)' },
  { id: 436, title: 'indisch' },
  { id: 437, title: 'indonesisch' },
  { id: 438, title: 'irakisch' },
  { id: 439, title: 'iranisch' },
  { id: 441, title: 'israelisch' },
  { id: 442, title: 'japanisch' },
  { id: 444, title: 'kasachisch' },
  { id: 445, title: 'jordanisch' },
  { id: 446, title: 'kambodschanisch' },
  { id: 447, title: 'katarisch' },
  { id: 448, title: 'kuwaitisch' },
  { id: 449, title: 'laotisch' },
  { id: 450, title: 'kirgisisch' },
  { id: 451, title: 'libanesisch' },
  { id: 454, title: 'maledivisch' },
  { id: 456, title: 'omanisch' },
  { id: 457, title: 'mongolisch' },
  { id: 458, title: 'nepalesisch' },
  { id: 460, title: 'bangladeschisch' },
  { id: 461, title: 'pakistanisch' },
  { id: 462, title: 'philippinisch' },
  { id: 465, title: 'chinesisch (Taiwan)' },
  { id: 467, title: 'koreanisch (Südkorea)' },
  { id: 469, title: 'der Vereinigten Arabischen Emirate' },
  { id: 470, title: 'tadschikisch' },
  { id: 471, title: 'turkmenisch' },
  { id: 472, title: 'saudi-arabisch' },
  { id: 474, title: 'singapurisch' },
  { id: 475, title: 'syrisch' },
  { id: 476, title: 'thailändisch' },
  { id: 477, title: 'usbekisch' },
  { id: 479, title: 'chinesisch' },
  { id: 482, title: 'malaysisch' },
  { id: 483, title: 'von Timor-Leste' },
  { id: 499, title: 'sonst, asiat. Staatsangeh.' },
  { id: 523, title: 'australisch' },
  { id: 524, title: 'salomonisch' },
  { id: 526, title: 'fidschianisch' },
  { id: 527, title: 'von den Cookinseln' },
  { id: 530, title: 'kiribatisch' },
  { id: 531, title: 'nauruisch' },
  { id: 532, title: 'vanuatuisch' },
  { id: 533, title: 'niueanisch' },
  { id: 536, title: 'neuseeländisch' },
  { id: 537, title: 'palauisch' },
  { id: 538, title: 'papua-neuguineisch' },
  { id: 540, title: 'tuvaluisch' },
  { id: 541, title: 'tongaisch' },
  { id: 543, title: 'samoanisch' },
  { id: 544, title: 'marshallisch' },
  { id: 545, title: 'mikronesisch' },
  { id: 599, title: 'sonst. ozean. Staatsangeh.' },
  { id: 997, title: 'staatenlos' },
  { id: 998, title: 'ungeklärt' },
  { id: 999, title: 'ohne Angabe' },
];
