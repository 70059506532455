import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormTextField from '../../components/form/FormTextField';

const INPUT_PROPS = {
  vatIdentificationNumber: {
    maxLength: 60,
  },
  taxNumber: {
    maxLength: 50,
  },
  regionlKeyOfStates: {
    maxLength: 12,
  },
};

export default function RegistrationData() {
  const { t } = useTranslation();

  return (
    <Grid container item className="formSubSection" data-testid="contactDetails">
      <Typography variant="h3" className="subheader">
        {t('ikk.ApplicantSelection.RegistrationData.title')}
      </Typography>
      <Typography variant="body1">{t('ikk.ApplicantSelection.RegistrationData.subtitle')}</Typography>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.vatIdentificationNumber"
            autoComplete="given-vatIdentificationNumber"
            className="formItem"
            label={t('ikk.ApplicantSelection.RegistrationData.vatIdentificationNumber')}
            maxLength={INPUT_PROPS.vatIdentificationNumber.maxLength}
            data-testid="vatIdentificationNumber"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.taxNumber"
            autoComplete="given-taxNumber"
            className="formItem"
            label={t('ikk.ApplicantSelection.RegistrationData.taxNumber')}
            maxLength={INPUT_PROPS.taxNumber.maxLength}
            data-testid="taxNumber"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
