import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import isNotEmpty from 'util/isNotEmpty';
import FormCheckboxGroup from '../../components/form/FormCheckboxGroup';
import FormCheckbox from '../../components/form/FormCheckbox';
import FormTextField from '../../components/form/FormTextField';
import { validators } from '../formValidation';
import { POST_CODE_PATTERN } from '../../constants/index';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold',
  },

  pageTitle: {
    fontSize: '45px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '38px',
      paddingBottom: '20px !important',
    },
  },
  list: {
    marginLeft: '2rem',
    listStyleType: 'disc',
  },
  requestIdentifierWrapper: {
    width: '100%',
  },
  requestIdentifier: {
    width: '100%',
  },
  inputInfoText: {
    padding: '20px 0 10px 0',
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  errorMessage: {
    color: '#ca0036 !important',
    marginTop: '5px',
    marginLeft: '3%',
  },
}));

const INPUT_PROPS = {
  location: {
    maxLength: 35,
  },
  postcode: {
    maxLength: 5,
  },
};

export default function InformationDistrictConcept() {
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useForm();
  const isGrantNumberReq = isNotEmpty(form.getFieldState('informationDistrictConcept.additions')?.value);

  const additionsLookup: { [key: string]: string } = t('ikk.InformationDistrictConcept.additionsLookup', {
    returnObjects: true,
  });

  const isGrantNumberProvided = isNotEmpty(form.getFieldState('informationDistrictConcept.grantNo')?.value);

  return (
    <Grid>
      <Grid container item className="formSubSection" data-testid="InformationDistrictConcept-declarations">
        <Grid item xs={12} md={12}>
          <Typography variant="h3">{t('ikk.InformationDistrictConcept.declarationsTitle')}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormTextField
            name="informationDistrictConcept.districtName"
            className="formItem"
            label={t('ikk.InformationDistrictConcept.districtName')}
            data-testid="districtName"
            required
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormTextField
            name="informationDistrictConcept.plz"
            label={t('ikk.common.Address.postcode')}
            pattern={POST_CODE_PATTERN}
            patternErrMsg="validation.invalidPostCodeFormat"
            className="formItem"
            required
            maxLength={INPUT_PROPS.postcode.maxLength}
            data-testid="investitionPLZ"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormTextField
            name="informationDistrictConcept.town"
            label={t('ikk.common.Address.location')}
            className="formItem"
            required
            maxLength={INPUT_PROPS.location.maxLength}
            data-testid="investitionTown"
          />
        </Grid>
        <FormCheckbox
          name="informationDistrictConcept.qualification"
          label={t('ikk.InformationDistrictConcept.qualification')}
          data-testid="informationDistrictConcept.qualification"
          validate={validators.ikkForm.notARobot}
          errorMsg={t('ikk.InformationDistrictConcept.qualificationError')}
        />
      </Grid>
      <Grid container item className="formSubSection" data-testid="InformationDistrictConcept-additional">
        <Typography variant="h3">{t('ikk.InformationDistrictConcept.title')}</Typography>
        <Typography variant="body1">{t('ikk.InformationDistrictConcept.infoText1')}</Typography>
        <Grid container item xs={12} justifyContent="center" className="formGroup">
          <Grid container className={classes.requestIdentifierWrapper}>
            <FormCheckboxGroup
              name="informationDistrictConcept.additions"
              options={additionsLookup}
              label={t('ikk.InformationDistrictConcept.categories.label')}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormTextField
            required={isGrantNumberReq}
            name="informationDistrictConcept.grantNo"
            className="formItem"
            label={t('ikk.InformationDistrictConcept.grantNo')}
            data-testid="grantNo"
            validate={validators.productSpecific.districtConcept}
            errorMsg={t('ikk.InformationDistrictConcept.grantNoError')}
          />
          {isGrantNumberReq && !isGrantNumberProvided && (
            <Grid item xs={12} md={8}>
              <Typography variant="body1" className={classes.errorMessage}>
                {t('ikk.InformationDistrictConcept.grantNoError')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
