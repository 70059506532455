import { ORDER } from 'common/constants';

/**
 * Sort array of objects by one key - helper
 * @param objA
 * @param objB
 * @param key key to compare objects by
 * @param order
 * @param transformFn function to transform values before comparing

 */
const sortByKeyHelper = (objA: any, objB: any, key: string, order = ORDER.ASC, transformFn?: Function) => {
  const valueA = transformFn ? transformFn(objA[key]) : objA[key];
  const valueB = transformFn ? transformFn(objB[key]) : objB[key];

  if (valueA > valueB) {
    return order === ORDER.ASC ? 1 : -1;
  }
  if (valueA < valueB) {
    return order === ORDER.ASC ? -1 : 1;
  }
  return 0;
};

/**
 * Sort array of objects by one key
 * @param arr
 * @param key key to compare objects by
 * @param order
 * @param transformFn function to transform values before comparing
 */
export const sortByKey = (arr: any[], key: string, order = ORDER.ASC, transformFn?: Function): any[] => {
  return [...arr].sort((a, b) => sortByKeyHelper(a, b, key, order, transformFn));
};
