/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import FileCopy from '@material-ui/icons/FileCopy';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { useCreditRequestsContext } from 'context/creditRequestContext';
import { LoadingButtonContainer } from 'components/LoadingButton';
import { CircularProgress } from '@material-ui/core';
import { deleteOneAppRegisteredCreditRequestByID } from '../../../common/api/apiClient';

import SectionHeader from '../../../components/SectionHeader';
import { ExpandibleInfoPanel } from '../../../components/ExpandibleInfoPanel';
import DeleteOneAppCreditRequest from '../../../components/modals/creditRequests/DeleteOneAppCreditRequest';

import { Components } from '../../../types/models';

import CreditRequestListItem from './CreditRequestListItem';

import { creditRequestOneAppSummaryUrl, ONE_APP } from '../../../common/constants/routes';

const useStyles = makeStyles((theme) =>
  createStyles({
    progress: {
      position: 'relative',
      left: 'calc(50% - 40px)',
    },
    sectionHeader: {
      margin: '1rem 0 1.5rem 0',
    },
    newButton: {
      marginTop: '1rem',
      maxWidth: '150px',

      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
    },
  }),
);

const CreditRequestsListPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const classes = useStyles();

  const { oneAppCreditRequests, loadOneAppCreditRequests } = useCreditRequestsContext();

  const [openDeleteOneAppConfirmModal, setOpenDeleteOneAppConfirmModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>('');
  const [selectedRequestIdentifier, setSelectedRequestIdentifier] = useState<string>('');

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        await loadOneAppCreditRequests();
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [loadOneAppCreditRequests]);

  const tableSize = useMemo(() => (oneAppCreditRequests ? oneAppCreditRequests.length : 0), [oneAppCreditRequests]);

  const tableTitle = useMemo(() => {
    switch (tableSize) {
      case 0:
        return t('creditRequestList.tableTitle_0');
      case 1:
        return t('creditRequestList.tableTitle_1');
      default:
        return t('creditRequestList.tableTitle_2');
    }
  }, [tableSize, t]);

  const redirectToOneApp = useCallback(() => {
    history.push(ONE_APP);
  }, [history]);

  const handleCancelDeleteOneAppRequest = () => {
    setOpenDeleteOneAppConfirmModal(false);
    setSelectedId('');
    setSelectedRequestIdentifier('');
  };

  const handleConfirmDeleteOneAppRequest = async () => {
    await deleteOneAppRegisteredCreditRequestByID(selectedId);
    handleCancelDeleteOneAppRequest();
    loadOneAppCreditRequests();
  };

  const handleDeleteOneAppCreditRequest = (id: string, requestIdentifier: string) => {
    setSelectedId(id);
    setSelectedRequestIdentifier(requestIdentifier);
    setOpenDeleteOneAppConfirmModal(true);
  };

  const handleEditCreditRequestOneApp = (creditRequestOneApp: Components.Schemas.CreditRequestOneApp) => {
    const { id } = creditRequestOneApp;
    history.push(creditRequestOneAppSummaryUrl(id as string));
  };

  if (isLoading) {
    return <CircularProgress className={classes.progress} />;
  }

  return (
    <>
      <Typography variant="h1">{t('creditRequestList.title')}</Typography>
      <ExpandibleInfoPanel />
      <SectionHeader icon={<FileCopy />} title={tableTitle} className={classes.sectionHeader} />
      {tableSize > 0 ? (
        <>
          <Grid container>
            {oneAppCreditRequests.map((row, index) => (
              <CreditRequestListItem
                key={row.id}
                isFirstItem={index === 0}
                status={row.status}
                creditAmount={row?.program?.creditAmount}
                currentStep={row.currentStep}
                requestIdentifier={row?.requestInformation?.requestIdentifier}
                editAction={() => handleEditCreditRequestOneApp(row)}
                deleteAction={() =>
                  handleDeleteOneAppCreditRequest(
                    row.id as string,
                    row?.requestInformation?.requestIdentifier as string,
                  )
                }
              />
            ))}
          </Grid>
          <DeleteOneAppCreditRequest
            open={openDeleteOneAppConfirmModal}
            requestIdentifier={selectedRequestIdentifier}
            onConfirm={handleConfirmDeleteOneAppRequest}
            onCancel={handleCancelDeleteOneAppRequest}
          />
        </>
      ) : (
        <Grid container direction="column">
          <Typography variant="body1">{t('creditRequestList.noCreditRequest')}</Typography>
          <LoadingButtonContainer
            classes={classes.newButton}
            onClick={redirectToOneApp}
            color="primary"
            text={t('creditRequestList.buttons.newRequest')}
          />
        </Grid>
      )}
    </>
  );
};

export default CreditRequestsListPage;
