import React, { useState, useEffect } from 'react';
import InvestmentPlan from './InvestmentPlan';
import FinancingPlan from './FinancingPlan';
import { useForm } from 'react-final-form';

export default function InvestPlan({
  productType,
  selectedProduct,
  financingCreditAmountShadowField,
  productDetails,
  creditAmountMainField,
}: any) {
  const form = useForm();
  const [displayInvestmentPlan, setDisplayInvestmentPlan] = useState<any>(false);
  const [displayFinancingPlan, setDisplayFinancingPlan] = useState<any>(false);

  const conceptGroup = [
    'investPlan.amount.kosten_konzepterstellung',
    'financingPlan.amount.beantragter_zuschuss_konzept',
  ];
  const mgmtGroup = [
    'investPlan.amount.kosten_sanierungsmanagement',
    'financingPlan.amount.beantragter_zuschuss_sanierungsmanagement',
  ];

  const validateHandler = (value: string, allValues: any, meta: any) => {
    const productId = form.getState().values.product.id;
    if (productId && productId === '432') {
      if (conceptGroup.includes(meta.name)) {
        const num1 = allValues.investPlan?.amount?.kosten_konzepterstellung;
        const num2 = allValues.financingPlan?.amount?.beantragter_zuschuss_konzept;
        if (num1 && num2 && num2 >= num1) {
          return 'validation.invalidSumOfPlans';
        }
      }

      if (mgmtGroup.includes(meta.name)) {
        const num1 = allValues.investPlan?.amount?.kosten_sanierungsmanagement;
        const num2 = allValues.financingPlan?.amount?.beantragter_zuschuss_sanierungsmanagement;
        if (num1 && num2 && num2 >= num1) {
          return 'validation.invalidSumOfPlans';
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (productDetails) {
        try {
          const i = productDetails;
          if (i && i.ikkDisplayedComponents) {
            setDisplayInvestmentPlan(i?.ikkDisplayedComponents?.includes('investitionPlan_investition'));
            setDisplayFinancingPlan(i?.ikkDisplayedComponents?.includes('investitionPlan_financing'));
          }
        } catch (err) {
          return null;
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails]);

  return (
    <>
      {displayInvestmentPlan && (
        <InvestmentPlan
          financingElementId="financingPlan"
          selectedProduct={selectedProduct}
          validate={validateHandler}
        />
      )}
      {displayFinancingPlan && (
        <FinancingPlan
          productType={productType}
          selectedProduct={selectedProduct}
          financingCreditAmountShadowField={financingCreditAmountShadowField}
          creditAmountMainField={creditAmountMainField}
          financingElementId="financingPlan"
          validate={validateHandler}
        />
      )}
    </>
  );
}
