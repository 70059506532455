import { baseURL } from '../api/routes';

export const B2C_PROFILE_ROUTE = `${baseURL}/auth/profileEdit`;
export const LOGIN = `${baseURL}/auth/openid/return`;
export const B2C_PROFILE_DELETE_ROUTE = `${baseURL}/auth/profileDelete`;

export const ROOT = '/';
export const ONE_APP = '/oneApp';
export const ONE_APP_SUMMARY = '/oneApp/summary';

export const IKK_APP = '/ikk';
export const IKK_SUMMARY = '/ikk/summary';

export const CREDIT_REQUESTS = '/creditRequests';
export const CREDIT_REQUESTS_ONE_APP = '/creditRequestsOneApp';
export const DELETE_ACCOUNT = '/deleteProfile';

export const creditRequestOneAppSummaryUrl = (id: string) => `/creditRequestsOneApp/${id}/summary`;

export default {
  ROOT,
  ONE_APP,
  ONE_APP_SUMMARY,
  IKK_APP,
  LOGIN,
  DELETE_ACCOUNT,
  CREDIT_REQUESTS,
  CREDIT_REQUESTS_ONE_APP,
  B2C_PROFILE_ROUTE,
  creditRequestOneAppSummaryUrl,
};
