import 'react-app-polyfill/ie11';
import React, { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { IKK_APP, IKK_SUMMARY } from 'common/constants/routes';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import Footer from 'components/layout/Footer';
import Logo from 'components/Logo';
import IKKForm from './IKKForm/IKKForm';
import IKKStyles from './IKKStyles';
import { IKKContextProvider } from './context/IKKContext';
import Summary from './IKSummary';
import useStyles from './index.styles';

const IKK = () => {
  const classes = useStyles();

  const [shouldModalOpen, setShouldModalOpen] = useState<boolean>(true);

  const openModal = () => {
    if (shouldModalOpen) {
      setShouldModalOpen(false);
    }
  };
  return (
    <div onMouseLeave={openModal}>
      <div className={classes.main}>
        <Grid container className={classes.logoContainer} role="banner">
          <Logo />
        </Grid>

        <Grid container className={classes.contentContainer} role="main">
          <IKKContextProvider>
            <Switch>
              <Route exact path={IKK_APP} component={IKKForm} />
              <Route exact path={IKK_SUMMARY} component={Summary} />
              <Route>
                <Redirect to={IKK_APP} />
              </Route>
            </Switch>
          </IKKContextProvider>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default withStyles(IKKStyles)(IKK);
