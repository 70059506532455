import React, { useCallback } from 'react';
import { Field } from 'react-final-form';

import { Grid } from '@material-ui/core';
import { Components } from 'types/models';

import AutocompleteField from 'components/fields/AutocompleteField';
import ToolTipIcon from 'components/fields/ToolTipIcon';
import { isEmpty } from 'util/formUtils';

const FormAutocomplete = ({ validate, tooltip, onChange, required, preventDefaultOnChange, ...props }: any) => {
  const validateHandler = useCallback(
    (value, allValues, meta) => {
      let hasError;

      if (required) {
        hasError = isEmpty(value);
        if (hasError) return hasError;
      }

      if (validate) return validate(value, allValues, meta);
    },
    [required, validate],
  );

  return (
    <Field
      {...props}
      validate={validateHandler}
      subscription={{ value: true }}
      render={({ input }) => {
        const onChangeHandler = (e: React.ChangeEvent<{}>, value: Components.Schemas.StrictKeyValueField) => {
          if (onChange) onChange(value);
          if (!preventDefaultOnChange) input.onChange({ ...e, target: { ...e.target, value } });
        };

        return (
          <Grid container>
            <Grid container item xs={10}>
              <AutocompleteField
                {...props}
                {...input}
                required={required}
                value={input.value || null}
                onChange={onChangeHandler}
                variant="outlined"
              />
            </Grid>
            {tooltip && (
              <Grid item xs={1} className="tooltip">
                <ToolTipIcon tip={tooltip} />
              </Grid>
            )}
          </Grid>
        );
      }}
    />
  );
};

export default React.memo(FormAutocomplete);
