import React from 'react';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import infoLogoSvg from '../../../../assets/img/kfw-info.svg';

interface Props {
  content?: React.ReactNode;
  title?: React.ReactNode;
  description?: string[];
}

const useStyles = makeStyles((theme) => ({
  infoBox: {
    backgroundColor: '#ebf3f7',
    padding: '1rem',

    '& h4': {
      marginBottom: '10px',
    },
    '& img': {
      height: 'auto',
      width: '2.4rem',
      paddingRight: '1rem',
      [theme.breakpoints.down('sm')]: {
        minWidth: '10%',
      },
    },
  },
  list: {
    marginLeft: '20px',

    '& li': {
      listStyleType: 'disc',
      marginLeft: '2rem',
    },
  },
  marginBottom: {
    marginBottom: '20px',
  },
}));

export default function InfoBox({ content, title, description, ...props }: Props) {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      className={classnames('noAutoPadding', classes.infoBox)}
      wrap="nowrap"
      alignItems="flex-start"
      {...props}
    >
      <img src={infoLogoSvg} alt="Info-Icon" />
      <Grid item>
        {title ? <Typography variant="h4">{title}</Typography> : null}
        {description ? (
          <div className={classes.marginBottom}>
            <ul className={classes.list}>
              {description.map((bulletPoint: string) => (
                <li key={bulletPoint.substr(0, 10) + bulletPoint.substr(bulletPoint.length - 5, bulletPoint.length)}>
                  <Typography variant="body1">{bulletPoint}</Typography>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        <Typography variant="body1">{content}</Typography>
      </Grid>
    </Grid>
  );
}
