import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FormTextField from '../../components/form/FormTextField';
import FormDatePicker from '../../components/form/FormDatePicker';

export default function License() {
  const { t } = useTranslation();

  return (
    <Grid container item className="formSubSection" data-testid="license">
      <Typography variant="h3" className="subheader">
        {t('oneApp.License.registredTradeLicenseTitle')}
      </Typography>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormDatePicker
            name="applicant.licenseIssuedDate"
            className="formItem"
            label={t('oneApp.License.licenseIssuedDate')}
            data-testid="licenseIssuedDate-date"
            tooltip={t('oneApp.License.licenseIssuedDate_tooltip')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.licenseIssuedPlace"
            className="formItem"
            label={t('oneApp.License.licenseIssuedPlace')}
            tooltip={t('oneApp.License.licenseIssuedPlace_tooltip')}
            maxLength={35}
            data-testid="licenseIssuedPlace"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
