import { B2C_PROFILE_ROUTE, B2C_PROFILE_DELETE_ROUTE } from '../constants/routes';
import { baseURL } from '../api/routes';

export const logoutByRedirect = (): void => {
  window.location.href = `${baseURL}/auth/logout?redirect=${window.location.protocol}//${window.location.host}`;
};

export const redirectToTermsOfUse = (): void => {
  window.open(
    'https://www.kfw.de/PDF/Download-Center/F%C3%B6rderprogramme-%28Inlandsf%C3%B6rderung%29/PDF-Dokumente/Foerderassistent/Nutzungsbedingungen_KfW-Foerderassistent.pdf',
  );
};

export const redirectToProfileEdit = (): void => {
  window.location.href = B2C_PROFILE_ROUTE;
};

export const redirectToProfileDelete = (): void => {
  window.location.href = B2C_PROFILE_DELETE_ROUTE;
};

export const titleChange = (title?: string): void => {
  const newTitle = title || 'Vorbereitung Kreditantrag - KfW-Corona-Hilfe';
  document.title = newTitle;
};
