import React, { createContext, useContext, useState } from 'react';

import { Components } from '../../../types/models';

interface Props {
  request?: Components.Schemas.CreditRequestOneApp;
  setRequest: React.Dispatch<React.SetStateAction<Components.Schemas.CreditRequestOneApp | undefined>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaults: Props = {
  setRequest: () => null,
  loading: false,
  setLoading: () => {},
};

const Context = createContext(defaults);

export const useOneAppContext = () => useContext(Context);

interface ProviderProps {
  children: React.ReactNode;
}

const Provider = ({ children }: ProviderProps) => {
  const [loading, setLoading] = useState<boolean>(defaults.loading);
  const [request, setRequest] = useState<Components.Schemas.CreditRequestOneApp | undefined>(defaults.request);

  const contextValue = {
    loading,
    setLoading,
    request,
    setRequest,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const OneAppContextProvider = Provider;
