import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddressForm from './AddressForm';
import ProjectDetails from './ProjectDetails';
import SustainabilityDirective from './SustainabilityDirective';
import ProjectPurpose from './ProjectPurpose';
import ProjectBegin from './ProjectBegin';
import ThirdPartyFunding from './ThirdPartyFunding';

export default function ProjectDescription({
  productType,
  selectedProduct,
  creditAmountMainField,
  creditAmountShadowField,
  productDetails,
}: any) {
  const { t } = useTranslation();

  const [displayProjectBegin, setDisplayProjectBegin] = useState<any>(false);
  const [displayAddressForm, setAddressForm] = useState<any>(false);
  const [displayProjectDetails, setDisplayProjectDetails] = useState<any>(false);
  const [displayProjectPurpose, setDisplayProjectPurpose] = useState<any>(false);
  const [displayThirdPartyFunding, setDisplayThirdPartyFunding] = useState<any>(false);
  const [displayForCreditProgram, setDisplayForCreditProgram] = useState<any>(false);

  useEffect(() => {
    (async () => {
      if (productDetails) {
        try {
          const i = productDetails;
          if (i && i.ikkDisplayedComponents) {
            setDisplayProjectBegin(i?.ikkDisplayedComponents?.includes('projectDescription_dateOfInvestment'));
            setAddressForm(i?.ikkDisplayedComponents?.includes('projectDescription_address'));
            setDisplayProjectDetails(i?.ikkDisplayedComponents?.includes('projectDescription_description'));
            setDisplayProjectPurpose(i?.ikkDisplayedComponents?.includes('projectDescription_purposes'));
            setDisplayThirdPartyFunding(i?.ikkDisplayedComponents?.includes('projectDescription_thirdPartyFunding'));
            setDisplayForCreditProgram(i?.productType === 'Kredit');
          }
        } catch (err) {
          return null;
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails]);

  return (
    <>
      {(displayProjectBegin || displayAddressForm || displayProjectDetails || displayProjectPurpose) && (
        <Grid container className="formSection">
          <Typography variant="h2" className="header">
            {t('ikk.ProjectDescription.title')}
          </Typography>
          {displayProjectBegin && <ProjectBegin selectedProduct={selectedProduct} />}
          {displayAddressForm && <AddressForm />}
          {displayProjectDetails && <ProjectDetails />}
          {displayForCreditProgram && <SustainabilityDirective />}
          {displayProjectPurpose && (
            <ProjectPurpose
              productType={productType}
              selectedProduct={selectedProduct}
              creditAmountMainField={creditAmountMainField}
              creditAmountShadowField={creditAmountShadowField}
              productDetails={productDetails}
            />
          )}
          {displayThirdPartyFunding && <ThirdPartyFunding productType={productType}/>}
        </Grid>
      )}
    </>
  );
}
