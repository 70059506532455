import React from 'react';
import classnames from 'classnames';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Switch, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import ToolTipIcon from 'components/fields/ToolTipIcon';

const useStyles = makeStyles(() => ({
  switchMargin: {
    marginLeft: '0rem !important',
  },
  labelMargin: {
    margin: '2rem',
  },
  alignTooltip: {
    flexWrap: 'nowrap',
  },
  displayContent: { display: 'contents' },
}));

const FormSwitch = ({
  name,
  label,
  className,
  tooltip,
  validate,
  onChange,
  value,
  checked,
  'data-testid': dataTestId,
  ...props
}: any) => {
  const classes = useStyles();
  const form = useForm();
  const { t } = useTranslation();
  return (
    <Grid container alignItems="baseline">
      <Grid item container xs={12}>
        <Field
          {...props}
          value={value}
          name={name}
          type="checkbox"
          validate={validate}
          subscription={{ value: true, modified: true, error: true, submitFailed: true }}
          render={({ input, meta }) => {
            const onChangeHandler = (e: React.ChangeEvent<{}>, v: boolean) => {
              if (onChange) onChange(value, v);
              input.onChange(e);
            };

            return (
              <FormControlLabel
                className={classnames(className, classes.switchMargin, {
                  error: meta.error && (meta.modified || meta.submitFailed),
                })}
                control={
                  <>
                    <Typography
                      style={{ fontWeight: form.getFieldState(name)?.value ? 'normal' : 'bold', color: '#00446e' }}
                    >
                      {t('ikk.common.formSwitchNo')}
                    </Typography>
                    <Switch
                      {...props}
                      {...input}
                      checked={checked}
                      data-testid={dataTestId}
                      onChange={onChangeHandler}
                      disableRipple
                      className="switch"
                      inputProps={{
                        'aria-labelledby': `${dataTestId}-label`,
                      }}
                    />
                    <Typography
                      style={{
                        fontWeight: form.getFieldState(name)?.value ? 'bold' : 'normal',
                        color: '#00446e',
                        marginRight: '2rem',
                      }}
                    >
                      {t('ikk.common.formSwitchYes')}
                    </Typography>
                  </>
                }
                label={
                  <Grid container alignItems="center" className={classes.alignTooltip}>
                    <Typography variant="body1" className={classes.displayContent}>
                      {label}
                      {tooltip && <ToolTipIcon tip={tooltip} />}
                    </Typography>
                  </Grid>
                }
                labelPlacement="end"
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(FormSwitch);
