import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import DoubleAccounting from './DoubleAccounting';
import CameralisticAccounting from './CameralisticAccounting';

import { validators } from '../formValidation';
import { ACCOUNTING_TYPE } from '../../constants/index';
import FormRadioGroup from '../../components/form/FormRadioGroup';

export default function CommunityAssociationFinancials() {
  const { t } = useTranslation();
  const [accountingType, setAccountingType] = React.useState<any>(null);

  const accountingLookup: { [key: string]: string } = t(
    'ikk.ApplicantSelection.CommunityAssociationFinancials.accountingLookup',
    {
      returnObjects: true,
    },
  );
  const onAccountingTypeChange = (evt: Event, value: any) => {
    setAccountingType(value);
  };

  return (
    <Grid container item className="subSubSection" data-testid="CommunityAssociationFinancials">
      <Typography variant="body1">{t('ikk.ApplicantSelection.CommunityAssociationFinancials.subtitle1')}</Typography>
      <Typography variant="body1">{t('ikk.ApplicantSelection.CommunityAssociationFinancials.subtitle2')}</Typography>
      <Grid container>
        <FormRadioGroup
          name="requestInformation.accountingType"
          options={accountingLookup}
          label={t('ikk.ApplicantSelection.CommunityAssociationFinancials.accountingLabel')}
          validate={validators.applicant.accountingType}
          errorMsg={t('validation.emptyField')}
          onChange={onAccountingTypeChange}
          defaultValue={accountingType}
        />
      </Grid>
      {/* TODO: this seems like a field used just for a subscription, but it isn't set anywhere, maybe it could be removed */}
      <Field name="requestInformation.requestApplicant" subscription={{ value: true }}>
        {() => {
          if (accountingType === ACCOUNTING_TYPE.DOUBLE) {
            return (
              <Grid item xs={12} key="doubleAccounting">
                <DoubleAccounting />
              </Grid>
            );
          }
          if (accountingType === ACCOUNTING_TYPE.CAMERALISTIC) {
            return (
              <Grid item xs={12} key="cameralisticAccounting">
                <CameralisticAccounting />
              </Grid>
            );
          }
          return null;
        }}
      </Field>
    </Grid>
  );
}
