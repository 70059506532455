import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import FormSlider from '../../components/form/FormSlider';

export default function AdditionalRequestInformation({ productType, productDetails }: any) {
  const { t } = useTranslation();
  const DEFAULT_DURATION = 0;
  const DEFAULT_FREEYEARS = 0;
  const DEFAULT_FIXEDINTERESTPERIOD = 0;
  const [durationValue, setDurationValue] = React.useState<number>(DEFAULT_DURATION);
  const [freeYearsValue, setFreeYearsValue] = React.useState<number>(DEFAULT_FREEYEARS);
  const [fixedInterestPeriodValue, setFixedInterestPeriodValue] = React.useState<number>(DEFAULT_FIXEDINTERESTPERIOD);

  const categoryKeyLookup: { [key: string]: string } = t('ikk.FindProduct.categories.categoryKeyLookup', {
    returnObjects: true,
  });

  const productChannel = 'bankdurchgeleitet'; // 'direkt'
  let interest;
  let selectedVariants;
  let termYearsStart = 0;
  let termYearsEnd = 30;
  let nonrepaymentYearsValidRangeStart = 0;
  let nonrepaymentYearsValidRangeEnd = 30;
  let fixedInterestYears = 30;

  if (productDetails && productDetails.data) {
    // eslint-disable-next-line prefer-destructuring
    interest = productDetails.data[0];
    if (interest) {
      selectedVariants = interest.productVariants.filter(
        // eslint-disable-next-line eqeqeq
        (productVariant: any) => productVariant.channel == productChannel,
      );
      const productVariant = selectedVariants[0];
      termYearsStart = productVariant?.termYears?.start;
      termYearsEnd = productVariant?.termYears?.end;
      nonrepaymentYearsValidRangeStart = productVariant?.nonrepaymentYearsValidRange?.start;
      nonrepaymentYearsValidRangeEnd = productVariant?.nonrepaymentYearsValidRange?.end;
      fixedInterestYears = productVariant?.fixedInterestYears;
    }
  }

  if (!productType || categoryKeyLookup[productType] === categoryKeyLookup.grant) {
    return <></>;
  }

  return (
    <Grid container item className="formSection" data-testid="additionalRequestInformation">
      <Grid container item xs={12} className="formGroup">
        {/* termYears */}
        <Grid item xs={12} md={6}>
          <FormSlider
            name="product.duration"
            value={durationValue}
            min={termYearsStart}
            max={termYearsEnd}
            step={1}
            label={t('ikk.AdditionalRequestInformation.duration')}
            tooltip={t('ikk.AdditionalRequestInformation.durationTooltip')}
            onChange={(v: number) => setDurationValue(v)}
            data-testid="duration-select"
            data-testid-number="duration-value"
          />
        </Grid>

        {/* nonRepaymentYears */}
        <Grid item xs={12} md={6}>
          <FormSlider
            name="product.freeYears"
            value={freeYearsValue}
            min={nonrepaymentYearsValidRangeStart}
            max={nonrepaymentYearsValidRangeEnd}
            step={1}
            label={t('ikk.AdditionalRequestInformation.freeYears')}
            tooltip={t('ikk.AdditionalRequestInformation.freeYearsTooltip')}
            onChange={(v: number) => setFreeYearsValue(v)}
            data-testid="freeYears-select"
            data-testid-number="freeYears-value"
          />
        </Grid>
      </Grid>

      {/* fixedInterestYears */}
      <Grid container item xs={12} className="formGroup">
        <Grid item xs={12} md={6}>
          <FormSlider
            name="product.fixedInterestPeriod"
            value={fixedInterestPeriodValue}
            min={0}
            max={fixedInterestYears}
            step={1}
            label={t('ikk.AdditionalRequestInformation.fixedInterestPeriod')}
            tooltip={t('ikk.AdditionalRequestInformation.fixedInterestPeriodTooltip')}
            onChange={(v: number) => setFixedInterestPeriodValue(v)}
            data-testid="fixedInterestPeriod-select"
            data-testid-number="fixedInterestPeriod-value"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
