import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { formatProgramId, formatShortLink } from 'util/formatUtils';
import SelectionCard from '../../components/common/SelectionCard';

const useStyles = makeStyles(() => ({
  card: {
    minWidth: '530px',
  },
  subtitle: {
    marginBottom: '10px',
  },
  link: {
    position: 'relative',
    padding: '20px 10px 0 0',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  arrowIcon: {
    fontSize: '12px',
  },
  list: {
    marginLeft: '20px',

    '& li': {
      position: 'relative',

      '&:before': {
        content: '"•"',
        verticalAlign: 'middle',
        paddingRight: '5px',
        position: 'absolute',
        left: '-10px',
      },
    },
  },
  content: {
    padding: '0 20px',
  },
}));

type Program = {
  title: string;
  subtitle: string;
  id: string;
  description: string[];
  link?: string;
};

type ProgramCardProps = {
  program: Program;
  className?: string;
};

export default function ProgramCard({ program, className }: ProgramCardProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const content = (
    <Grid container item className={classes.content}>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.subtitle}>
          {program.title}
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          {program.subtitle}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={8}>
        <ul className={classes.list}>
          {program.description.map((desc: string) => (
            <li key={desc.substr(0, 10)}>
              <Typography variant="body1">{desc}</Typography>
            </li>
          ))}
        </ul>
      </Grid>
      <Grid container item xs={12} lg={4} alignItems="flex-end" justifyContent="flex-end">
        <Link target="_blank" href={`https://www.kfw.de/${formatShortLink(program.id)}`} className={classes.link}>
          <span className={classnames(classes.arrowIcon, 'icArrowsRight')} />
          {t('oneApp.ProgramCard.toDetails')}
        </Link>
      </Grid>
    </Grid>
  );

  return <SelectionCard title={formatProgramId(program.id)} className={className} content={content} />;
}
