import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import germanTranslation from './locales/de/translation';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  de: {
    translation: germanTranslation,
  },
};

export const languages = ['de'];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'de',
    lng: 'de',

    // allow keys to be phrases having `:`
    nsSeparator: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
