const bankNameKeyLookup = [
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1100',
    id: 0,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 1,
  },
  {
    bankName: 'OLINDA Zweigniederlassung Deutschland',
    bic: 'QNTODEB2XXX',
    id: 2,
  },
  {
    bankName: 'Klarna Bank German Branch',
    bic: 'KLRNDEBEXXX',
    id: 3,
  },
  {
    bankName: 'Aareal Bank',
    bic: 'AARBDE5W100',
    id: 4,
  },
  {
    bankName: 'Afone Paiement',
    bic: 'AFOPDEB2XXX',
    id: 5,
  },
  {
    bankName: 'Financiere des Paiements Electroniques NL Deutschland',
    bic: 'FPEGDEB2XXX',
    id: 6,
  },
  {
    bankName: 'N26 Bank',
    bic: 'NTSBDEB1XXX',
    id: 7,
  },
  {
    bankName: 'Holvi Payment Services Zweigniederlassung Deutschland',
    bic: 'HOLVDEB1XXX',
    id: 8,
  },
  {
    bankName: 'BHF-BANK',
    bic: 'BHFBDEFF100',
    id: 9,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33BER',
    id: 10,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33BER',
    id: 11,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33BER',
    id: 12,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM488',
    id: 13,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM488',
    id: 14,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM488',
    id: 15,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM488',
    id: 16,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM488',
    id: 17,
  },
  {
    bankName: 'Berlin Hyp',
    bic: 'BHYPDEB2XXX',
    id: 18,
  },
  {
    bankName: 'ABK Allgemeine Beamten Bank',
    bic: 'ABKBDEB1XXX',
    id: 19,
  },
  {
    bankName: 'M.M. Warburg & Co (vormals Bankhaus Löbbecke)',
    bic: 'LOEBDEBBXXX',
    id: 20,
  },
  {
    bankName: 'North Channel Bank',
    bic: 'GENODEF1OGK',
    id: 21,
  },
  {
    bankName: 'Eurocity Bank',
    bic: 'DLGHDEB1XXX',
    id: 22,
  },
  {
    bankName: 'Eurocity Bank Gf GAA',
    bic: 'DLGHDEB1XXX',
    id: 23,
  },
  {
    bankName: 'EIS Einlagensicherungsbank',
    bic: 'EIEGDEB1XXX',
    id: 24,
  },
  {
    bankName: 'Santander Consumer Bank',
    bic: 'SCFBDE33XXX',
    id: 25,
  },
  {
    bankName: 'Commerzbank, Filiale Berlin 1',
    bic: 'COBADEBBXXX',
    id: 26,
  },
  {
    bankName: 'Commerzbank, Filiale Berlin 3',
    bic: 'COBADEBBXXX',
    id: 27,
  },
  {
    bankName: 'Commerzbank, CC SP',
    bic: 'COBADEFFXXX',
    id: 28,
  },
  {
    bankName: 'Commerzbank GF-B48',
    bic: 'COBADEFFXXX',
    id: 29,
  },
  {
    bankName: 'Commerzbank Gf 160',
    bic: 'COBADEFFXXX',
    id: 30,
  },
  {
    bankName: 'Commerzbank Gf 161',
    bic: 'COBADEFFXXX',
    id: 31,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 32,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 33,
  },
  {
    bankName: 'Commerzbank, Gf Web-K',
    bic: 'COBADEFFXXX',
    id: 34,
  },
  {
    bankName: 'Commerzbank Service-BZ',
    bic: 'COBADEFFXXX',
    id: 35,
  },
  {
    bankName: 'Landesbank Berlin - Berliner Sparkasse',
    bic: 'BELADEBEXXX',
    id: 36,
  },
  {
    bankName: 'S-Kreditpartner, Berlin',
    bic: 'SKPADEB1XXX',
    id: 37,
  },
  {
    bankName: 'LBS Ost Berlin',
    bic: 'LBSODEB1BLN',
    id: 38,
  },
  {
    bankName: 'DekaBank',
    bic: 'DGZFDEFFBER',
    id: 39,
  },
  {
    bankName: 'Pax-Bank',
    bic: 'GENODED1PA6',
    id: 40,
  },
  {
    bankName: 'Bank für Kirche und Diakonie - KD-Bank Gf Sonder-BLZ',
    bic: 'GENODED1KDB',
    id: 41,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBBXXX',
    id: 42,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBBER',
    id: 43,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB101',
    id: 44,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB101',
    id: 45,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP30',
    id: 46,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEBBP30',
    id: 47,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEBBP31',
    id: 48,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEBBP32',
    id: 49,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB110',
    id: 50,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP31',
    id: 51,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP32',
    id: 52,
  },
  {
    bankName: 'norisbank',
    bic: 'NORSDE51XXX',
    id: 53,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Filiale Berlin I',
    bic: 'DRESDEFF100',
    id: 54,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw A',
    bic: 'DRESDEFFI26',
    id: 55,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw B',
    bic: 'DRESDEFFXXX',
    id: 56,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 55',
    bic: 'DRESDEFF112',
    id: 57,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf ZW 57',
    bic: 'DRESDEFF114',
    id: 58,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf PCC DCC-ITGK 3',
    bic: 'DRESDEFFI53',
    id: 59,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
    bic: 'DRESDEFFI71',
    id: 60,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
    bic: 'DRESDEFFI72',
    id: 61,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank IBLZ',
    bic: 'DRESDEFFXXX',
    id: 62,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
    bic: 'DRESDEFFI73',
    id: 63,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Filiale Berlin III',
    bic: 'DRESDEFF199',
    id: 64,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI14',
    id: 65,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK 2',
    bic: 'DRESDEFFI99',
    id: 66,
  },
  {
    bankName: 'Berliner Volksbank',
    bic: 'BEVODEBBXXX',
    id: 67,
  },
  {
    bankName: 'Bank für Schiffahrt (BFS) Fil d Ostfr VB Leer',
    bic: 'GENODEF1BSB',
    id: 68,
  },
  {
    bankName: 'PSD Bank Berlin-Brandenburg',
    bic: 'GENODEF1P01',
    id: 69,
  },
  {
    bankName: 'Westend Bank',
    bic: 'MACODEB1XXX',
    id: 70,
  },
  {
    bankName: 'Investitionsbank Berlin',
    bic: 'IBBBDEBBXXX',
    id: 71,
  },
  {
    bankName: 'Quirin Privatbank',
    bic: 'QUBKDEBBXXX',
    id: 72,
  },
  {
    bankName: 'Weberbank',
    bic: 'WELADED1WBB',
    id: 73,
  },
  {
    bankName: 'Isbank Fil Berlin',
    bic: 'ISBKDEFXXXX',
    id: 74,
  },
  {
    bankName: 'flatexDEGIRO Bank',
    bic: 'BIWBDE33XXX',
    id: 75,
  },
  {
    bankName: 'Lloyds Bank',
    bic: 'BOFSDEB1XXX',
    id: 76,
  },
  {
    bankName: 'Sydbank Filiale Berlin',
    bic: 'SYBKDE22BER',
    id: 77,
  },
  {
    bankName: 'Tradegate Wertpapierhandelsbank Berlin',
    bic: 'TRDADEB1PBK',
    id: 78,
  },
  {
    bankName: 'Tradegate Wertpapierhandelsbank, GF DIR',
    bic: 'TRDADEBBDIR',
    id: 79,
  },
  {
    bankName: 'solarisBank',
    bic: 'SOBKDEBBXXX',
    id: 80,
  },
  {
    bankName: 'solarisBank Gf (S)',
    bic: 'SOBKDEB2XXX',
    id: 81,
  },
  {
    bankName: 'solarisBank Gf (PNTA)',
    bic: 'PNTADEBBXXX',
    id: 82,
  },
  {
    bankName: 'KfW Kreditanstalt für Wiederaufbau',
    bic: 'KFWIDEFF100',
    id: 83,
  },
  {
    bankName: 'Deutsche Kreditbank Berlin',
    bic: 'BYLADEM1001',
    id: 84,
  },
  {
    bankName: 'Merck Finck A Quintet Private Bank',
    bic: 'MEFIDEMM100',
    id: 85,
  },
  {
    bankName: 'Commerzbank Filiale Berlin 2',
    bic: 'COBADEBB120',
    id: 86,
  },
  {
    bankName: 'ZVA Norddeutsche Landesbank Gf SA',
    bic: 'NOLADE21DVS',
    id: 87,
  },
  {
    bankName: 'DZ BANK',
    bic: 'GENODEFF120',
    id: 88,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB160',
    id: 89,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB172',
    id: 90,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB122',
    id: 91,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB161',
    id: 92,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB180',
    id: 93,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB173',
    id: 94,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB174',
    id: 95,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB182',
    id: 96,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB170',
    id: 97,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB175',
    id: 98,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB187',
    id: 99,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB121',
    id: 100,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB163',
    id: 101,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB123',
    id: 102,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB120',
    id: 103,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB166',
    id: 104,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB167',
    id: 105,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB176',
    id: 106,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB177',
    id: 107,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB185',
    id: 108,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB186',
    id: 109,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB189',
    id: 110,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB183',
    id: 111,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB169',
    id: 112,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB124',
    id: 113,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB171',
    id: 114,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB162',
    id: 115,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB181',
    id: 116,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB125',
    id: 117,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB188',
    id: 118,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB164',
    id: 119,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB184',
    id: 120,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB126',
    id: 121,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB178',
    id: 122,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB127',
    id: 123,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB168',
    id: 124,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB165',
    id: 125,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB160',
    id: 126,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB171',
    id: 127,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB172',
    id: 128,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB122',
    id: 129,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB161',
    id: 130,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB180',
    id: 131,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB173',
    id: 132,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB174',
    id: 133,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB162',
    id: 134,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB182',
    id: 135,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB181',
    id: 136,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB170',
    id: 137,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB175',
    id: 138,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB183',
    id: 139,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB125',
    id: 140,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB187',
    id: 141,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB121',
    id: 142,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB163',
    id: 143,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB188',
    id: 144,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB164',
    id: 145,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB184',
    id: 146,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB165',
    id: 147,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB123',
    id: 148,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB120',
    id: 149,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB166',
    id: 150,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB167',
    id: 151,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB126',
    id: 152,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB176',
    id: 153,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB177',
    id: 154,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB185',
    id: 155,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB186',
    id: 156,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB178',
    id: 157,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB127',
    id: 158,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB189',
    id: 159,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB168',
    id: 160,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB169',
    id: 161,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB124',
    id: 162,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFFVAC',
    id: 163,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBPAL',
    id: 164,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEBBP33',
    id: 165,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP33',
    id: 166,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Filiale Berlin II',
    bic: 'DRESDEFF120',
    id: 167,
  },
  {
    bankName: 'Sparda-Bank Berlin',
    bic: 'GENODEF1S10',
    id: 168,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1130',
    id: 169,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 170,
  },
  {
    bankName: 'Ostseesparkasse Rostock',
    bic: 'NOLADE21ROS',
    id: 171,
  },
  {
    bankName: 'Sparkasse Vorpommern auf Rügen',
    bic: 'NOLADE21RUE',
    id: 172,
  },
  {
    bankName: 'Volksbank Wolgast -alt-',
    bic: 'GENODEF1WOG',
    id: 173,
  },
  {
    bankName: 'Volks- und Raiffeisenbank',
    bic: 'GENODEF1HWI',
    id: 174,
  },
  {
    bankName: 'Raiffeisenbank Wismar -alt-',
    bic: 'GENODEF1HWR',
    id: 175,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1DBR',
    id: 176,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBRXXX',
    id: 177,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB151',
    id: 178,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBR131',
    id: 179,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBR133',
    id: 180,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB130',
    id: 181,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB131',
    id: 182,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBR134',
    id: 183,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBR135',
    id: 184,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB141',
    id: 185,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB142',
    id: 186,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB143',
    id: 187,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB144',
    id: 188,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB152',
    id: 189,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB150',
    id: 190,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB153',
    id: 191,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB154',
    id: 192,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBR136',
    id: 193,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB140',
    id: 194,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBR138',
    id: 195,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB155',
    id: 196,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB156',
    id: 197,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB179',
    id: 198,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB148',
    id: 199,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB149',
    id: 200,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB159',
    id: 201,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB132',
    id: 202,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB145',
    id: 203,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB146',
    id: 204,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB147',
    id: 205,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBR137',
    id: 206,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB158',
    id: 207,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBB157',
    id: 208,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEBR132',
    id: 209,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBROS',
    id: 210,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB151',
    id: 211,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB131',
    id: 212,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB133',
    id: 213,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB130',
    id: 214,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB128',
    id: 215,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB132',
    id: 216,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB134',
    id: 217,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB135',
    id: 218,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB141',
    id: 219,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB142',
    id: 220,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB143',
    id: 221,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB144',
    id: 222,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB152',
    id: 223,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB150',
    id: 224,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB153',
    id: 225,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB145',
    id: 226,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB146',
    id: 227,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB147',
    id: 228,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB154',
    id: 229,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB136',
    id: 230,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB137',
    id: 231,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB140',
    id: 232,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB138',
    id: 233,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB155',
    id: 234,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB156',
    id: 235,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB179',
    id: 236,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB157',
    id: 237,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB158',
    id: 238,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB148',
    id: 239,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB149',
    id: 240,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB159',
    id: 241,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEBBP35',
    id: 242,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP35',
    id: 243,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF130',
    id: 244,
  },
  {
    bankName: 'Rostocker Volks- und Raiffeisenbank',
    bic: 'GENODEF1HR1',
    id: 245,
  },
  {
    bankName: 'Pommersche Volksbank',
    bic: 'GENODEF1HST',
    id: 246,
  },
  {
    bankName: 'Volksbank Wismar -alt-',
    bic: 'GENODEF1HWV',
    id: 247,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 248,
  },
  {
    bankName: 'Sparkasse Mecklenburg-Nordwest',
    bic: 'NOLADE21WIS',
    id: 249,
  },
  {
    bankName: 'Sparkasse Parchim-Lübz',
    bic: 'NOLADE21PCH',
    id: 250,
  },
  {
    bankName: 'Sparkasse Schwerin -alt-',
    bic: 'NOLADE21SNS',
    id: 251,
  },
  {
    bankName: 'Sparkasse Mecklenburg-Schwerin',
    bic: 'NOLADE21LWL',
    id: 252,
  },
  {
    bankName: 'VR Bank Mecklenburg',
    bic: 'GENODEF1GUE',
    id: 253,
  },
  {
    bankName: 'Raiffeisen-Volksbank -alt-',
    bic: 'GENODEF1GDB',
    id: 254,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF140',
    id: 255,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw W',
    bic: 'DRESDEFFI27',
    id: 256,
  },
  {
    bankName: 'VR-Bank -alt-',
    bic: 'GENODEF1SN1',
    id: 257,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1150',
    id: 258,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 259,
  },
  {
    bankName: 'Müritz-Sparkasse',
    bic: 'NOLADE21WRN',
    id: 260,
  },
  {
    bankName: 'Sparkasse Neubrandenburg-Demmin',
    bic: 'NOLADE21NBS',
    id: 261,
  },
  {
    bankName: 'Sparkasse Uecker-Randow',
    bic: 'NOLADE21PSW',
    id: 262,
  },
  {
    bankName: 'Sparkasse Vorpommern',
    bic: 'NOLADE21GRW',
    id: 263,
  },
  {
    bankName: 'Sparkasse Mecklenburg-Strelitz',
    bic: 'NOLADE21MST',
    id: 264,
  },
  {
    bankName: 'Raiffeisenbank Mecklenburger Seenplatte',
    bic: 'GENODEF1WRN',
    id: 265,
  },
  {
    bankName: 'Volksbank Vorpommern',
    bic: 'GENODEF1ANK',
    id: 266,
  },
  {
    bankName: 'Raiffeisenbank Malchin -alt-',
    bic: 'GENODEF1MAL',
    id: 267,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF150',
    id: 268,
  },
  {
    bankName: 'Volksbank Demmin',
    bic: 'GENODEF1DM1',
    id: 269,
  },
  {
    bankName: 'VR-Bank Uckermark-Randow',
    bic: 'GENODEF1PZ1',
    id: 270,
  },
  {
    bankName: 'Investitionsbank des Landes Brandenburg',
    bic: 'ILBXDE8XXXX',
    id: 271,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM470',
    id: 272,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 273,
  },
  {
    bankName: 'Mittelbrandenburgische Sparkasse in Potsdam',
    bic: 'WELADED1PMB',
    id: 274,
  },
  {
    bankName: 'Sparkasse Prignitz',
    bic: 'WELADED1PRP',
    id: 275,
  },
  {
    bankName: 'Sparkasse Ostprignitz-Ruppin',
    bic: 'WELADED1OPR',
    id: 276,
  },
  {
    bankName: 'LBS Ostdeutsche Landesbausparkasse',
    bic: 'LBSODEB1XXX',
    id: 277,
  },
  {
    bankName: 'Volks- und Raiffeisenbank Prignitz',
    bic: 'GENODEF1PER',
    id: 278,
  },
  {
    bankName: 'Raiffeisenbank Ostprignitz-Ruppin',
    bic: 'GENODEF1NPP',
    id: 279,
  },
  {
    bankName: 'VR-Bank Fläming-Elsterland',
    bic: 'GENODEF1LUK',
    id: 280,
  },
  {
    bankName: 'Brandenburger Bank',
    bic: 'GENODEF1BRB',
    id: 281,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF160',
    id: 282,
  },
  {
    bankName: 'Volksbank Rathenow',
    bic: 'GENODEF1RN1',
    id: 283,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM471',
    id: 284,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 285,
  },
  {
    bankName: 'Sparkasse Barnim',
    bic: 'WELADED1GZE',
    id: 286,
  },
  {
    bankName: 'Stadtsparkasse Schwedt',
    bic: 'WELADED1UMX',
    id: 287,
  },
  {
    bankName: 'Kreissparkasse Märkisch-Oderland',
    bic: 'WELADED1MOL',
    id: 288,
  },
  {
    bankName: 'Sparkasse Oder-Spree',
    bic: 'WELADED1LOS',
    id: 289,
  },
  {
    bankName: 'Sparkasse Uckermark',
    bic: 'WELADED1UMP',
    id: 290,
  },
  {
    bankName: 'Raiffeisenbank-Volksbank Oder-Spree',
    bic: 'GENODEF1BKW',
    id: 291,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF170',
    id: 292,
  },
  {
    bankName: 'VR Bank Fürstenwalde Seelow Wriezen',
    bic: 'GENODEF1FW1',
    id: 293,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM472',
    id: 294,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 295,
  },
  {
    bankName: 'Sparkasse Spree-Neiße',
    bic: 'WELADED1CBN',
    id: 296,
  },
  {
    bankName: 'Sparkasse Elbe-Elster',
    bic: 'WELADED1EES',
    id: 297,
  },
  {
    bankName: 'Sparkasse Niederlausitz',
    bic: 'WELADED1OSL',
    id: 298,
  },
  {
    bankName: 'VR Bank Lausitz',
    bic: 'GENODEF1FWA',
    id: 299,
  },
  {
    bankName: 'VR Bank Forst -alt-',
    bic: 'GENODEF1FOR',
    id: 300,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF180',
    id: 301,
  },
  {
    bankName: 'Spreewaldbank',
    bic: 'GENODEF1LN1',
    id: 302,
  },
  {
    bankName: 'Volksbank Spree-Neiße',
    bic: 'GENODEF1SPM',
    id: 303,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1200',
    id: 304,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 305,
  },
  {
    bankName: 'Aareal Bank',
    bic: 'AARBDE5W200',
    id: 306,
  },
  {
    bankName: 'Skandinaviska Enskilda Banken (publ) Hamburg Branch',
    bic: 'ESSEDEFFHAM',
    id: 307,
  },
  {
    bankName: 'Jyske Bank Fil Hamburg',
    bic: 'JYBADEHHXXX',
    id: 308,
  },
  {
    bankName: 'Signal Iduna Bauspar',
    bic: 'SIBSDEHHXXX',
    id: 309,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 310,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 311,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 312,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 313,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 314,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 315,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 316,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 317,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 318,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 319,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 320,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 321,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 322,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 323,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 324,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 325,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 326,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 327,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 328,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 329,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 330,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 331,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 332,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 333,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 334,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 335,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 336,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 337,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 338,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 339,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 340,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 341,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 342,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 343,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 344,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 345,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 346,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 347,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 348,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 349,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 350,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 351,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 352,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 353,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 354,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 355,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 356,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 357,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 358,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 359,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 360,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 361,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 362,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 363,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM300',
    id: 364,
  },
  {
    bankName: 'Varengold Bank',
    bic: 'VGAGDEHHXXX',
    id: 365,
  },
  {
    bankName: 'DONNER & REUSCHEL',
    bic: 'CHDBDEHHXXX',
    id: 366,
  },
  {
    bankName: 'Donner + Reuschel GF NL LUX',
    bic: 'CHDBDEHHXXX',
    id: 367,
  },
  {
    bankName: 'Marcard, Stein & Co Bankiers',
    bic: 'MCRDDEHHXXX',
    id: 368,
  },
  {
    bankName: 'Sydbank Fil Hamburg',
    bic: 'SYBKDE22HAM',
    id: 369,
  },
  {
    bankName: 'Merck Finck A Quintet Private Bank',
    bic: 'MEFIDEMM200',
    id: 370,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEHHXXX',
    id: 371,
  },
  {
    bankName: 'Commerzbank, Filiale Hamburg 2',
    bic: 'COBADEHHXXX',
    id: 372,
  },
  {
    bankName: 'Commerzbank, CC SP',
    bic: 'COBADEFFXXX',
    id: 373,
  },
  {
    bankName: 'Commerzbank GF RME',
    bic: 'COBADEFFXXX',
    id: 374,
  },
  {
    bankName: 'Commerzbank GF-H48',
    bic: 'COBADEFFXXX',
    id: 375,
  },
  {
    bankName: 'Commerzbank GF COC',
    bic: 'COBADEFFXXX',
    id: 376,
  },
  {
    bankName: 'Commerzbank Gf 260',
    bic: 'COBADEFFXXX',
    id: 377,
  },
  {
    bankName: 'Commerzbank Gf 261',
    bic: 'COBADEFFXXX',
    id: 378,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 379,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 380,
  },
  {
    bankName: 'Commerzbank - GF comdirect',
    bic: 'COBADEHDXXX',
    id: 381,
  },
  {
    bankName: 'Commerzbank - GF comdirect',
    bic: 'COBADEHD001',
    id: 382,
  },
  {
    bankName: 'Commerzbank - GF comdirect',
    bic: 'COBADEHD044',
    id: 383,
  },
  {
    bankName: 'Commerzbank - GF comdirect',
    bic: 'COBADEHD055',
    id: 384,
  },
  {
    bankName: 'Commerzbank - GF comdirect',
    bic: 'COBADEHD066',
    id: 385,
  },
  {
    bankName: 'Commerzbank - GF comdirect',
    bic: 'COBADEHD077',
    id: 386,
  },
  {
    bankName: 'Commerzbank - GF comdirect',
    bic: 'COBADEHD088',
    id: 387,
  },
  {
    bankName: 'Commerzbank - GF comdirect',
    bic: 'COBADEHD099',
    id: 388,
  },
  {
    bankName: 'Hamburg Commercial Bank, ehemals HSH Nordbank Hamburg',
    bic: 'HSHNDEHHXXX',
    id: 389,
  },
  {
    bankName: 'Hamburger Sparkasse',
    bic: 'HASPDEHHXXX',
    id: 390,
  },
  {
    bankName: 'DZ BANK',
    bic: 'GENODEFF200',
    id: 391,
  },
  {
    bankName: 'Norderstedter Bank -alt-',
    bic: 'GENODEF1NDR',
    id: 392,
  },
  {
    bankName: 'Kaltenkirchener Bank -alt-',
    bic: 'GENODEF1KLK',
    id: 393,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1BBR',
    id: 394,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1SST',
    id: 395,
  },
  {
    bankName: 'Raiffeisenbank Südstormarn Mölln',
    bic: 'GENODEF1GRS',
    id: 396,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1STV',
    id: 397,
  },
  {
    bankName: 'Raiffeisenbank Owschlag',
    bic: 'GENODEF1OWS',
    id: 398,
  },
  {
    bankName: 'Volksbank Ahlerstedt',
    bic: 'GENODEF1AST',
    id: 399,
  },
  {
    bankName: 'Volksbank Geest',
    bic: 'GENODEF1APE',
    id: 400,
  },
  {
    bankName: 'Volksbank Kehdingen Zndl. der Ostfriesischen Volksbank',
    bic: 'GENODEF1DRO',
    id: 401,
  },
  {
    bankName: 'Spar- und Kreditbank',
    bic: 'GENODEF1HAA',
    id: 402,
  },
  {
    bankName: 'Volksbank Fredenbeck',
    bic: 'GENODEF1FRB',
    id: 403,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODEF1815',
    id: 404,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1RRZ',
    id: 405,
  },
  {
    bankName: 'Raiffeisenbank Travemünde -alt-',
    bic: 'GENODEF1RLT',
    id: 406,
  },
  {
    bankName: 'Volksbank Winsener Marsch',
    bic: 'GENODEF1WIM',
    id: 407,
  },
  {
    bankName: 'Volksbank Wulfsen',
    bic: 'GENODEF1WUL',
    id: 408,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHHXXX',
    id: 409,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH205',
    id: 410,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH202',
    id: 411,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH207',
    id: 412,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH219',
    id: 413,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH221',
    id: 414,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH241',
    id: 415,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH201',
    id: 416,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH200',
    id: 417,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH203',
    id: 418,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH209',
    id: 419,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH206',
    id: 420,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH204',
    id: 421,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH211',
    id: 422,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH213',
    id: 423,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBHAM',
    id: 424,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB201',
    id: 425,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB219',
    id: 426,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB207',
    id: 427,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB211',
    id: 428,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB241',
    id: 429,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB221',
    id: 430,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB205',
    id: 431,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB200',
    id: 432,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB203',
    id: 433,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB202',
    id: 434,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB206',
    id: 435,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB213',
    id: 436,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB209',
    id: 437,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB204',
    id: 438,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEHHP34',
    id: 439,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP34',
    id: 440,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF200',
    id: 441,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 55',
    bic: 'DRESDEFF207',
    id: 442,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf ZW 57',
    bic: 'DRESDEFF208',
    id: 443,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf PCC DCC-ITGK 2',
    bic: 'DRESDEFFI56',
    id: 444,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 3',
    bic: 'DRESDEFFI63',
    id: 445,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
    bic: 'DRESDEFFI64',
    id: 446,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
    bic: 'DRESDEFFI74',
    id: 447,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
    bic: 'DRESDEFFI75',
    id: 448,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 7',
    bic: 'DRESDEFFJ33',
    id: 449,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 8',
    bic: 'DRESDEFFJ34',
    id: 450,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 9',
    bic: 'DRESDEFFJ35',
    id: 451,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 10',
    bic: 'DRESDEFFJ36',
    id: 452,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 11',
    bic: 'DRESDEFFJ37',
    id: 453,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI06',
    id: 454,
  },
  {
    bankName: 'DZ HYP',
    bic: 'DGHYDEH1XXX',
    id: 455,
  },
  {
    bankName: 'Augsburger Aktienbank (netbank)',
    bic: 'AUGBDE71NET',
    id: 456,
  },
  {
    bankName: 'Edekabank',
    bic: 'EDEKDEHHXXX',
    id: 457,
  },
  {
    bankName: 'EBANK Gf Cash',
    bic: 'EDEKDEHHXXX',
    id: 458,
  },
  {
    bankName: 'PSD Bank Nord',
    bic: 'GENODEF1P08',
    id: 459,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 460,
  },
  {
    bankName: 'MUFG Bank (Europe) Germany Branch',
    bic: 'BOTKDEH1XXX',
    id: 461,
  },
  {
    bankName: 'Bank of China Fil Hamburg',
    bic: 'BKCHDEFFHMB',
    id: 462,
  },
  {
    bankName: 'Joh. Berenberg, Gossler & Co',
    bic: 'BEGODEHHXXX',
    id: 463,
  },
  {
    bankName: 'M.M. Warburg & CO',
    bic: 'WBWCDEHHXXX',
    id: 464,
  },
  {
    bankName: 'BHF-BANK',
    bic: 'BHFBDEFF200',
    id: 465,
  },
  {
    bankName: 'start:bausparkasse',
    bic: 'DRBKDEH1XXX',
    id: 466,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33HAN',
    id: 467,
  },
  {
    bankName: 'Goyer & Göppel',
    bic: 'GOGODEH1XXX',
    id: 468,
  },
  {
    bankName: 'Hanseatic Bank',
    bic: 'HSTBDEHHXXX',
    id: 469,
  },
  {
    bankName: 'GRENKE BANK',
    bic: 'GREBDEH1XXX',
    id: 470,
  },
  {
    bankName: 'Barclays Bank Ireland Hamburg Branch',
    bic: 'BARCDEHAXXX',
    id: 471,
  },
  {
    bankName: 'Santander Consumer Bank',
    bic: 'SCFBDE33XXX',
    id: 472,
  },
  {
    bankName: 'Hamburger Volksbank',
    bic: 'GENODEF1HH2',
    id: 473,
  },
  {
    bankName: 'Hamburger Volksbank (Gf GAA)',
    bic: 'GENODEF1HH2',
    id: 474,
  },
  {
    bankName: 'Volksbank Raiffeisenbank',
    bic: 'GENODEF1HH4',
    id: 475,
  },
  {
    bankName: 'Volksbank Hamburg Ost-West -alt-',
    bic: 'GENODEF1HH1',
    id: 476,
  },
  {
    bankName: 'Vierländer Volksbank -alt-',
    bic: 'GENODEF1HH3',
    id: 477,
  },
  {
    bankName: 'MKB Mittelstandskreditbank',
    bic: 'GENODEF1MKB',
    id: 478,
  },
  {
    bankName: 'Bank Melli Iran',
    bic: 'MELIDEHHXXX',
    id: 479,
  },
  {
    bankName: 'Bank Saderat Iran',
    bic: 'SIHRDEH1HAM',
    id: 480,
  },
  {
    bankName: 'DNB Bank ASA - Filiale Deutschland',
    bic: 'DNBADEHXXXX',
    id: 481,
  },
  {
    bankName: 'Warburg, M.M. - Hypothekenbank',
    bic: 'MMWHDEH1XXX',
    id: 482,
  },
  {
    bankName: 'Banking Circle - German Branch',
    bic: 'SXPYDEHHXXX',
    id: 483,
  },
  {
    bankName: 'Otto M. Schröder Bank',
    bic: 'OSCBDEH1XXX',
    id: 484,
  },
  {
    bankName: 'Isbank Hamburg',
    bic: 'ISBKDEFXXXX',
    id: 485,
  },
  {
    bankName: 'Max Heinr. Sutor',
    bic: 'MHSBDEHBXXX',
    id: 486,
  },
  {
    bankName: 'Europäisch-Iranische Handelsbank',
    bic: 'EIHBDEHHXXX',
    id: 487,
  },
  {
    bankName: 'Danske Bank',
    bic: 'DABADEHHXXX',
    id: 488,
  },
  {
    bankName: 'Sparda-Bank Hamburg',
    bic: 'GENODEF1S11',
    id: 489,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC01',
    bic: 'HYVEDEMME01',
    id: 490,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC02',
    bic: 'HYVEDEMME02',
    id: 491,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC03',
    bic: 'HYVEDEMME03',
    id: 492,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC04',
    bic: 'HYVEDEMME04',
    id: 493,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC05',
    bic: 'HYVEDEMME05',
    id: 494,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC06',
    bic: 'HYVEDEMME06',
    id: 495,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC07',
    bic: 'HYVEDEMME07',
    id: 496,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC08',
    bic: 'HYVEDEMME08',
    id: 497,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC09',
    bic: 'HYVEDEMME09',
    id: 498,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC10',
    bic: 'HYVEDEMME10',
    id: 499,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC11',
    bic: 'HYVEDEMME11',
    id: 500,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC12',
    bic: 'HYVEDEMME12',
    id: 501,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC13',
    bic: 'HYVEDEMME13',
    id: 502,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC14',
    bic: 'HYVEDEMME14',
    id: 503,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC15',
    bic: 'HYVEDEMME15',
    id: 504,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC16',
    bic: 'HYVEDEMME16',
    id: 505,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC17',
    bic: 'HYVEDEMME17',
    id: 506,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC18',
    bic: 'HYVEDEMME18',
    id: 507,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC19',
    bic: 'HYVEDEMME19',
    id: 508,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC20',
    bic: 'HYVEDEMME20',
    id: 509,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC21',
    bic: 'HYVEDEMME21',
    id: 510,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC22',
    bic: 'HYVEDEMME22',
    id: 511,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC23',
    bic: 'HYVEDEMME23',
    id: 512,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC24',
    bic: 'HYVEDEMME24',
    id: 513,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC25',
    bic: 'HYVEDEMME25',
    id: 514,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC26',
    bic: 'HYVEDEMME26',
    id: 515,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC27',
    bic: 'HYVEDEMME27',
    id: 516,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC28',
    bic: 'HYVEDEMME28',
    id: 517,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC29',
    bic: 'HYVEDEMME29',
    id: 518,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC30',
    bic: 'HYVEDEMME30',
    id: 519,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC31',
    bic: 'HYVEDEMME31',
    id: 520,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC32',
    bic: 'HYVEDEMME32',
    id: 521,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC33',
    bic: 'HYVEDEMME33',
    id: 522,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC34',
    bic: 'HYVEDEMME34',
    id: 523,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC35',
    bic: 'HYVEDEMME35',
    id: 524,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC36',
    bic: 'HYVEDEMME36',
    id: 525,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC37',
    bic: 'HYVEDEMME37',
    id: 526,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC38',
    bic: 'HYVEDEMME38',
    id: 527,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC39',
    bic: 'HYVEDEMME39',
    id: 528,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC40',
    bic: 'HYVEDEMME40',
    id: 529,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC41',
    bic: 'HYVEDEMME41',
    id: 530,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC42',
    bic: 'HYVEDEMME42',
    id: 531,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC43',
    bic: 'HYVEDEMME43',
    id: 532,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC44',
    bic: 'HYVEDEMME44',
    id: 533,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC45',
    bic: 'HYVEDEMME45',
    id: 534,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC46',
    bic: 'HYVEDEMME46',
    id: 535,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC47',
    bic: 'HYVEDEMME47',
    id: 536,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC48',
    bic: 'HYVEDEMME48',
    id: 537,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC49',
    bic: 'HYVEDEMME49',
    id: 538,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC50',
    bic: 'HYVEDEMME50',
    id: 539,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC52',
    bic: 'HYVEDEMME52',
    id: 540,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC55',
    bic: 'HYVEDEMME55',
    id: 541,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC56',
    bic: 'HYVEDEMME56',
    id: 542,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC57',
    bic: 'HYVEDEMME57',
    id: 543,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC58',
    bic: 'HYVEDEMME58',
    id: 544,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC59',
    bic: 'HYVEDEMME59',
    id: 545,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC60',
    bic: 'HYVEDEMME60',
    id: 546,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC61',
    bic: 'HYVEDEMME61',
    id: 547,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC62',
    bic: 'HYVEDEMME62',
    id: 548,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC63',
    bic: 'HYVEDEMME63',
    id: 549,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC64',
    bic: 'HYVEDEMME64',
    id: 550,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC65',
    bic: 'HYVEDEMME65',
    id: 551,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC66',
    bic: 'HYVEDEMME66',
    id: 552,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC67',
    bic: 'HYVEDEMME67',
    id: 553,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC68',
    bic: 'HYVEDEMME68',
    id: 554,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC69',
    bic: 'HYVEDEMME69',
    id: 555,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC70',
    bic: 'HYVEDEMME70',
    id: 556,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC71',
    bic: 'HYVEDEMME71',
    id: 557,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC72',
    bic: 'HYVEDEMME72',
    id: 558,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC73',
    bic: 'HYVEDEMME73',
    id: 559,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC74',
    bic: 'HYVEDEMME74',
    id: 560,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC75',
    bic: 'HYVEDEMME75',
    id: 561,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC76',
    bic: 'HYVEDEMME76',
    id: 562,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC77',
    bic: 'HYVEDEMME77',
    id: 563,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC78',
    bic: 'HYVEDEMME78',
    id: 564,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC79',
    bic: 'HYVEDEMME79',
    id: 565,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC80',
    bic: 'HYVEDEMME80',
    id: 566,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC81',
    bic: 'HYVEDEMME81',
    id: 567,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC82',
    bic: 'HYVEDEMME82',
    id: 568,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC83',
    bic: 'HYVEDEMME83',
    id: 569,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC84',
    bic: 'HYVEDEMME84',
    id: 570,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC85',
    bic: 'HYVEDEMME85',
    id: 571,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC86',
    bic: 'HYVEDEMME86',
    id: 572,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC87',
    bic: 'HYVEDEMME87',
    id: 573,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC88',
    bic: 'HYVEDEMME88',
    id: 574,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC89',
    bic: 'HYVEDEMME89',
    id: 575,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC90',
    bic: 'HYVEDEMME90',
    id: 576,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC91',
    bic: 'HYVEDEMME91',
    id: 577,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC92',
    bic: 'HYVEDEMME92',
    id: 578,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC93',
    bic: 'HYVEDEMME93',
    id: 579,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC94',
    bic: 'HYVEDEMME94',
    id: 580,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC95',
    bic: 'HYVEDEMME95',
    id: 581,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC96',
    bic: 'HYVEDEMME96',
    id: 582,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC97',
    bic: 'HYVEDEMME97',
    id: 583,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC98',
    bic: 'HYVEDEMME98',
    id: 584,
  },
  {
    bankName: 'UniCredit Bank - HVB Settlement EAC99',
    bic: 'HYVEDEMME99',
    id: 585,
  },
  {
    bankName: 'Sparkasse Harburg-Buxtehude',
    bic: 'NOLADE21HAM',
    id: 586,
  },
  {
    bankName: 'Bundesbank eh Kiel',
    bic: 'MARKDEF1210',
    id: 587,
  },
  {
    bankName: 'Sydbank Filiale Kiel',
    bic: 'SYBKDE22KIE',
    id: 588,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 589,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 590,
  },
  {
    bankName: 'Hamburg Commercial Bank, ehemals HSH Nordbank Hamburg',
    bic: 'HSHNDEHHXXX',
    id: 591,
  },
  {
    bankName: 'Förde Sparkasse',
    bic: 'NOLADE21KIE',
    id: 592,
  },
  {
    bankName: 'Bordesholmer Sparkasse',
    bic: 'NOLADE21BOR',
    id: 593,
  },
  {
    bankName: 'Sparkasse Kreis Plön -alt-',
    bic: 'NOLADE21PLN',
    id: 594,
  },
  {
    bankName: 'Sparkasse Eckernförde -alt-',
    bic: 'NOLADE21ECK',
    id: 595,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH210',
    id: 596,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH214',
    id: 597,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB210',
    id: 598,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB214',
    id: 599,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF210',
    id: 600,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI07',
    id: 601,
  },
  {
    bankName: 'Kieler Volksbank',
    bic: 'GENODEF1KIL',
    id: 602,
  },
  {
    bankName: 'Kieler Volksbank (Gf GAA)',
    bic: 'GENODEF1KIL',
    id: 603,
  },
  {
    bankName: 'PSD Bank Kiel',
    bic: 'GENODEF1P11',
    id: 604,
  },
  {
    bankName: 'Eckernförder Bank Volksbank-Raiffeisenbank',
    bic: 'GENODEF1EFO',
    id: 605,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 606,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 607,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1WAS',
    id: 608,
  },
  {
    bankName: 'Raiffbk Kl-Kummerfeld -alt-',
    bic: 'GENODEF1BOO',
    id: 609,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH212',
    id: 610,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB212',
    id: 611,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF212',
    id: 612,
  },
  {
    bankName: 'VR Bank Neumünster',
    bic: 'GENODEF1NMS',
    id: 613,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 614,
  },
  {
    bankName: 'Sparkasse Holstein',
    bic: 'NOLADE21HOL',
    id: 615,
  },
  {
    bankName: 'VR Bank Ostholstein Nord-Plön',
    bic: 'GENODEF1NSH',
    id: 616,
  },
  {
    bankName: 'Volksbank Eutin Raiffeisenbank',
    bic: 'GENODEF1EUT',
    id: 617,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 618,
  },
  {
    bankName: 'Sparkasse Mittelholstein',
    bic: 'NOLADE21RDB',
    id: 619,
  },
  {
    bankName: 'Sparkasse Büdelsdorf -alt-',
    bic: 'NOLADE21BDF',
    id: 620,
  },
  {
    bankName: 'Sparkasse Hohenwestedt -alt-',
    bic: 'NOLADE21HWS',
    id: 621,
  },
  {
    bankName: 'Volksbank-Raiffeisenbank im Kreis Rendsburg -alt-',
    bic: 'GENODEF1NTO',
    id: 622,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1TOB',
    id: 623,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF214',
    id: 624,
  },
  {
    bankName: 'Sydbank Filiale Flensburg',
    bic: 'SYBKDE22XXX',
    id: 625,
  },
  {
    bankName: 'Union-Bank Flensburg',
    bic: 'UNBNDE21XXX',
    id: 626,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 627,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1HDW',
    id: 628,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH215',
    id: 629,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH216',
    id: 630,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB215',
    id: 631,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB216',
    id: 632,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEHHP01',
    id: 633,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP01',
    id: 634,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF215',
    id: 635,
  },
  {
    bankName: 'VR Bank Flensburg-Schleswig -alt-',
    bic: 'GENODEF1RSL',
    id: 636,
  },
  {
    bankName: 'VR Bank Schleswig-Mittelholstein',
    bic: 'GENODEF1SLW',
    id: 637,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 638,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 639,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 640,
  },
  {
    bankName: 'Nord-Ostsee Sparkasse',
    bic: 'NOLADE21NOS',
    id: 641,
  },
  {
    bankName: 'Spar- und Leihkasse zu Bredstedt -alt-',
    bic: 'NOLADE21BRD',
    id: 642,
  },
  {
    bankName: 'VR Bank Westküste',
    bic: 'GENODEF1HUM',
    id: 643,
  },
  {
    bankName: 'VR Bank Nord',
    bic: 'GENODEF1BDS',
    id: 644,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH217',
    id: 645,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB217',
    id: 646,
  },
  {
    bankName: 'Sylter Bank',
    bic: 'GENODEF1SYL',
    id: 647,
  },
  {
    bankName: 'Vereinigte VR Bank',
    bic: 'GENODEF1WYK',
    id: 648,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 649,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 650,
  },
  {
    bankName: 'Sparkasse Hennstedt-Wesselburen -alt-',
    bic: 'NOLADE21WEB',
    id: 651,
  },
  {
    bankName: 'Raiffeisenbank Heide -alt-',
    bic: 'GENODEF1RHE',
    id: 652,
  },
  {
    bankName: 'Dithmarscher Volks- und Raiffeisenbank',
    bic: 'GENODEF1DVR',
    id: 653,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 654,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 655,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 656,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 657,
  },
  {
    bankName: 'Sparkasse Elmshorn',
    bic: 'NOLADE21ELH',
    id: 658,
  },
  {
    bankName: 'Stadtsparkasse Wedel',
    bic: 'NOLADE21WED',
    id: 659,
  },
  {
    bankName: 'Raiffeisenbank Elbmarsch',
    bic: 'GENODEF1HTE',
    id: 660,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF221',
    id: 661,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF206',
    id: 662,
  },
  {
    bankName: 'Volksbank Elmshorn -alt-',
    bic: 'GENODEF1ELM',
    id: 663,
  },
  {
    bankName: 'VR Bank in Holstein',
    bic: 'GENODEF1PIN',
    id: 664,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 665,
  },
  {
    bankName: 'Sparkasse Westholstein',
    bic: 'NOLADE21WHO',
    id: 666,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF201',
    id: 667,
  },
  {
    bankName: 'VR Itzehoe Norderstedt Hohenwestedt -alt-',
    bic: 'GENODEF1VIT',
    id: 668,
  },
  {
    bankName: 'Bundesbank eh Lübeck',
    bic: 'MARKDEF1230',
    id: 669,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 670,
  },
  {
    bankName: 'Hamburg Commercial Bank, ehemals HSH Nordbank Hamburg',
    bic: 'HSHNDEHH230',
    id: 671,
  },
  {
    bankName: 'Sparkasse zu Lübeck',
    bic: 'NOLADE21SPL',
    id: 672,
  },
  {
    bankName: 'Sparkasse Südholstein',
    bic: 'NOLADE21SHO',
    id: 673,
  },
  {
    bankName: 'Kreissparkasse Herzogtum Lauenburg',
    bic: 'NOLADE21RZB',
    id: 674,
  },
  {
    bankName: 'Raiffeisenbank Leezen',
    bic: 'GENODEF1LZN',
    id: 675,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1BAR',
    id: 676,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1RLB',
    id: 677,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1BCH',
    id: 678,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEHHP02',
    id: 679,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP02',
    id: 680,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB237',
    id: 681,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHH222',
    id: 682,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF230',
    id: 683,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI08',
    id: 684,
  },
  {
    bankName: 'Volksbank Lübeck',
    bic: 'GENODEF1HLU',
    id: 685,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 686,
  },
  {
    bankName: 'Sparkasse Lüneburg',
    bic: 'NOLADE21LBG',
    id: 687,
  },
  {
    bankName: 'Volksbank Lüneburger Heide',
    bic: 'GENODEF1NBU',
    id: 688,
  },
  {
    bankName: 'Volksbank Bleckede-Dahlenburg -alt-',
    bic: 'GENODEF1DAB',
    id: 689,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB240',
    id: 690,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB242',
    id: 691,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H240',
    id: 692,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H241',
    id: 693,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP22',
    id: 694,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDE2HP22',
    id: 695,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF240',
    id: 696,
  },
  {
    bankName: 'Volksbank Lüneburg -alt-',
    bic: 'GENODEF1LUE',
    id: 697,
  },
  {
    bankName: 'Ritterschaftliches Kreditinstitut Stade',
    bic: 'GENODED1RKI',
    id: 698,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 699,
  },
  {
    bankName: 'Stadtsparkasse Cuxhaven',
    bic: 'BRLADE21CUX',
    id: 700,
  },
  {
    bankName: 'Sparkasse Stade-Altes Land',
    bic: 'NOLADE21STS',
    id: 701,
  },
  {
    bankName: 'Kreissparkasse Stade',
    bic: 'NOLADE21STK',
    id: 702,
  },
  {
    bankName: 'Sparkasse Rotenburg Osterholz',
    bic: 'BRLADE21ROB',
    id: 703,
  },
  {
    bankName: 'Zevener Volksbank',
    bic: 'GENODEF1SIT',
    id: 704,
  },
  {
    bankName: 'Spar- u Darlehnskasse Börde Lamstedt-Hechthausen',
    bic: 'GENODEF1LAS',
    id: 705,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF242',
    id: 706,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF241',
    id: 707,
  },
  {
    bankName: 'Volksbank Stade-Cuxhaven',
    bic: 'GENODEF1SDE',
    id: 708,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1250',
    id: 709,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 710,
  },
  {
    bankName: 'Aareal Bank',
    bic: 'AARBDE5W250',
    id: 711,
  },
  {
    bankName: 'NORD/LB vormals Deutsche Hypothekenbank',
    bic: 'DEHYDE2HXXX',
    id: 712,
  },
  {
    bankName: 'Calenberger Kreditverein',
    bic: 'CKVHDE21XXX',
    id: 713,
  },
  {
    bankName: 'BHF-BANK',
    bic: 'BHFBDEFF250',
    id: 714,
  },
  {
    bankName: 'Santander Consumer Bank',
    bic: 'SCFBDE33XXX',
    id: 715,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 716,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 717,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 718,
  },
  {
    bankName: 'Norddeutsche Landesbank Girozentrale',
    bic: 'NOLADE2HXXX',
    id: 719,
  },
  {
    bankName: 'ZVA Norddeutsche Landesbank SH',
    bic: 'NOLADE21CSH',
    id: 720,
  },
  {
    bankName: 'ZVA Norddeutsche Landesbank Gf MV',
    bic: 'NOLADE21CMV',
    id: 721,
  },
  {
    bankName: 'Sparkasse Hannover',
    bic: 'SPKHDE2HXXX',
    id: 722,
  },
  {
    bankName: 'LBS-Norddeutsche Landesbausparkasse',
    bic: 'NOLADE21LBS',
    id: 723,
  },
  {
    bankName: 'DZ BANK',
    bic: 'GENODEFF250',
    id: 724,
  },
  {
    bankName: 'DZ BANK',
    bic: 'GENODEFF280',
    id: 725,
  },
  {
    bankName: 'M.M. Warburg & Co (vormals Bankhaus Hallbaum)',
    bic: 'HALLDE2HXXX',
    id: 726,
  },
  {
    bankName: 'Volks- und Raiffeisenbank Leinebergland -alt-',
    bic: 'GENODEF1DES',
    id: 727,
  },
  {
    bankName: 'Raiffeisen-Volksbank Neustadt',
    bic: 'GENODEF1NST',
    id: 728,
  },
  {
    bankName: 'Volksbank Aller-Oker',
    bic: 'GENODEF1MUA',
    id: 729,
  },
  {
    bankName: 'Volksbank Diepholz-Barnstorf -alt-',
    bic: 'GENODEF1BNT',
    id: 730,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBHAN',
    id: 731,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB243',
    id: 732,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB250',
    id: 733,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB252',
    id: 734,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB931',
    id: 735,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB932',
    id: 736,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB256',
    id: 737,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB251',
    id: 738,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB933',
    id: 739,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB258',
    id: 740,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H265',
    id: 741,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2HXXX',
    id: 742,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H250',
    id: 743,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H283',
    id: 744,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H282',
    id: 745,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H252',
    id: 746,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H256',
    id: 747,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H258',
    id: 748,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H284',
    id: 749,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H251',
    id: 750,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP24',
    id: 751,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDE2HP24',
    id: 752,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF250',
    id: 753,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
    bic: 'DRESDEFFI65',
    id: 754,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI09',
    id: 755,
  },
  {
    bankName: 'Bank für Schiffahrt (BFS) Fil d Ostfr VB Leer',
    bic: 'GENODEF1BFS',
    id: 756,
  },
  {
    bankName: 'Sparda-Bank Hannover',
    bic: 'GENODEF1S09',
    id: 757,
  },
  {
    bankName: 'PSD Bank',
    bic: 'GENODEF1P09',
    id: 758,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33HAN',
    id: 759,
  },
  {
    bankName: 'Stadtsparkasse Barsinghausen',
    bic: 'NOLADE21BAH',
    id: 760,
  },
  {
    bankName: 'Stadtsparkasse Burgdorf',
    bic: 'NOLADE21BUF',
    id: 761,
  },
  {
    bankName: 'Kreissparkasse Fallingbostel in Walsrode',
    bic: 'NOLADE21WAL',
    id: 762,
  },
  {
    bankName: 'Stadtsparkasse Wunstorf',
    bic: 'NOLADE21WST',
    id: 763,
  },
  {
    bankName: 'Hannoversche Volksbank',
    bic: 'VOHADE2HXXX',
    id: 764,
  },
  {
    bankName: 'Hannoversche Volksbank GS nur für GAA',
    bic: 'VOHADE2HXXX',
    id: 765,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODEF1PAT',
    id: 766,
  },
  {
    bankName: 'Kreissparkasse Peine -alt-',
    bic: 'NOLADE21PEI',
    id: 767,
  },
  {
    bankName: 'Volksbank Peine -alt-',
    bic: 'GENODEF1PEV',
    id: 768,
  },
  {
    bankName: 'BHW Bausparkasse',
    bic: 'BHWBDE2HXXX',
    id: 769,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 770,
  },
  {
    bankName: 'Stadtsparkasse Hameln -alt-',
    bic: 'NOLADE21HMS',
    id: 771,
  },
  {
    bankName: 'Sparkasse Hameln-Weserbergland',
    bic: 'NOLADE21SWB',
    id: 772,
  },
  {
    bankName: 'Stadtsparkasse Bad Pyrmont',
    bic: 'NOLADE21PMT',
    id: 773,
  },
  {
    bankName: 'Volksbank Hameln-Stadthagen',
    bic: 'GENODEF1HMP',
    id: 774,
  },
  {
    bankName: 'Volksbank im Wesertal',
    bic: 'GENODEF1COP',
    id: 775,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB254',
    id: 776,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB255',
    id: 777,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H254',
    id: 778,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB264',
    id: 779,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H264',
    id: 780,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF254',
    id: 781,
  },
  {
    bankName: 'Volksbank Aerzen -alt-',
    bic: 'GENODED1AEZ',
    id: 782,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 783,
  },
  {
    bankName: 'Sparkasse Schaumburg',
    bic: 'NOLADE21SHG',
    id: 784,
  },
  {
    bankName: 'Volksbank in Schaumburg',
    bic: 'GENODEF1BCK',
    id: 785,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 786,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 787,
  },
  {
    bankName: 'Sparkasse Nienburg',
    bic: 'NOLADE21NIB',
    id: 788,
  },
  {
    bankName: 'Kreissparkasse Grafschaft Diepholz',
    bic: 'BRLADE21DHZ',
    id: 789,
  },
  {
    bankName: 'Volksbank -alt-',
    bic: 'GENODEF1STY',
    id: 790,
  },
  {
    bankName: 'Volksbank Aller-Weser -alt-',
    bic: 'GENODEF1HOY',
    id: 791,
  },
  {
    bankName: 'Volksbank Nienburg',
    bic: 'GENODEF1NIN',
    id: 792,
  },
  {
    bankName: 'Volksbank Niedersachsen-Mitte',
    bic: 'GENODEF1SUL',
    id: 793,
  },
  {
    bankName: 'Volksbank Niedersachsen-Mitte (Gf GAA)',
    bic: 'GENODEF1SUL',
    id: 794,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 795,
  },
  {
    bankName: 'Sparkasse Celle -alt-',
    bic: 'NOLADE21CEL',
    id: 796,
  },
  {
    bankName: 'Volksbank Wittingen-Klötze -alt-',
    bic: 'GENODEF1WIK',
    id: 797,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB257',
    id: 798,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H257',
    id: 799,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF257',
    id: 800,
  },
  {
    bankName: 'Volksbank Hankensbüttel-Wahrenholz -alt-',
    bic: 'GENODEF1HKB',
    id: 801,
  },
  {
    bankName: 'Volksbank Südheide - Isenhagener Land - Altmark',
    bic: 'GENODEF1HMN',
    id: 802,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 803,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 804,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 805,
  },
  {
    bankName: 'Sparkasse Uelzen Lüchow-Dannenberg',
    bic: 'NOLADE21UEL',
    id: 806,
  },
  {
    bankName: 'Sparkasse Uelzen Lüchow-Dannenberg -alt-',
    bic: 'NOLADE21DAN',
    id: 807,
  },
  {
    bankName: 'Kreissparkasse Soltau',
    bic: 'NOLADE21SOL',
    id: 808,
  },
  {
    bankName: 'Volksbank Clenze-Hitzacker -alt-',
    bic: 'GENODEF1CLZ',
    id: 809,
  },
  {
    bankName: 'Volksbank Uelzen-Salzwedel',
    bic: 'GENODEF1EUB',
    id: 810,
  },
  {
    bankName: 'VR PLUS Altmark-Wendland',
    bic: 'GENODEF1WOT',
    id: 811,
  },
  {
    bankName: 'Volksbank Lüneburger Heide -alt-',
    bic: 'GENODEF1SOL',
    id: 812,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 813,
  },
  {
    bankName: 'Stadtsparkasse Hildesheim -alt-',
    bic: 'NOLADE21HIS',
    id: 814,
  },
  {
    bankName: 'Sparkasse Hildesheim Goslar Peine',
    bic: 'NOLADE21HIK',
    id: 815,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB259',
    id: 816,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H259',
    id: 817,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB253',
    id: 818,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB261',
    id: 819,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H253',
    id: 820,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H261',
    id: 821,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF259',
    id: 822,
  },
  {
    bankName: 'Volksbank Hildesheim',
    bic: 'GENODEF1HIH',
    id: 823,
  },
  {
    bankName: 'Volksbank Hildesheimer Börde -alt-',
    bic: 'GENODEF1SLD',
    id: 824,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1260',
    id: 825,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 826,
  },
  {
    bankName: 'Sparkasse Göttingen',
    bic: 'NOLADE21GOE',
    id: 827,
  },
  {
    bankName: 'Sparkasse Duderstadt',
    bic: 'NOLADE21DUD',
    id: 828,
  },
  {
    bankName: 'Kreis- und Stadtsparkasse Münden -alt-',
    bic: 'NOLADE21HMU',
    id: 829,
  },
  {
    bankName: 'Volksbank Mitte -alt-',
    bic: 'GENODEF1DUD',
    id: 830,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODEF1ADE',
    id: 831,
  },
  {
    bankName: 'VR-Bank in Südniedersachsen',
    bic: 'GENODEF1DRA',
    id: 832,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB260',
    id: 833,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB263',
    id: 834,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H260',
    id: 835,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H263',
    id: 836,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF260',
    id: 837,
  },
  {
    bankName: 'Volksbank Göttingen -alt-',
    bic: 'GENODEF1GOE',
    id: 838,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 839,
  },
  {
    bankName: 'Kreis-Sparkasse Northeim',
    bic: 'NOLADE21NOM',
    id: 840,
  },
  {
    bankName: 'Sparkasse Einbeck',
    bic: 'NOLADE21EIN',
    id: 841,
  },
  {
    bankName: 'Volksbank Einbeck -alt-',
    bic: 'GENODEF1EIN',
    id: 842,
  },
  {
    bankName: 'Volksbank Solling',
    bic: 'GENODEF1HDG',
    id: 843,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB262',
    id: 844,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H262',
    id: 845,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF261',
    id: 846,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF262',
    id: 847,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 848,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 849,
  },
  {
    bankName: 'Stadtsparkasse Osterode -alt-',
    bic: 'NOLADE21OHA',
    id: 850,
  },
  {
    bankName: 'Sparkasse Osterode am Harz',
    bic: 'NOLADE21HZB',
    id: 851,
  },
  {
    bankName: 'Stadtsparkasse Bad Sachsa -alt-',
    bic: 'NOLADE21SAC',
    id: 852,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1265',
    id: 853,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 854,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 855,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 856,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 857,
  },
  {
    bankName: 'Sparkasse Osnabrück',
    bic: 'NOLADE22XXX',
    id: 858,
  },
  {
    bankName: 'Kreissparkasse Bersenbrück',
    bic: 'NOLADE21BEB',
    id: 859,
  },
  {
    bankName: 'Kreissparkasse Melle',
    bic: 'NOLADE21MEL',
    id: 860,
  },
  {
    bankName: 'Volksbank Bad Laer-Borgloh-Hilter-Melle',
    bic: 'GENODEF1HTR',
    id: 861,
  },
  {
    bankName: 'Volksbank Bramgau-Wittlage -alt-',
    bic: 'GENODEF1WHO',
    id: 862,
  },
  {
    bankName: 'Volksbank GMHütte-Hagen-Bissendorf',
    bic: 'GENODEF1HGM',
    id: 863,
  },
  {
    bankName: 'Volksbank Osnabrücker Nordland -alt-',
    bic: 'GENODEF1MRZ',
    id: 864,
  },
  {
    bankName: 'VR-Bank Osnabrücker Nordland',
    bic: 'GENODEF1NOP',
    id: 865,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB265',
    id: 866,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB921',
    id: 867,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB922',
    id: 868,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB923',
    id: 869,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB924',
    id: 870,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB266',
    id: 871,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB925',
    id: 872,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B265',
    id: 873,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B270',
    id: 874,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B269',
    id: 875,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B268',
    id: 876,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B271',
    id: 877,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B266',
    id: 878,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B272',
    id: 879,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF265',
    id: 880,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI10',
    id: 881,
  },
  {
    bankName: 'Vereinigte Volksbank Bramgau Osnabrück Wittlage',
    bic: 'GENODEF1OSV',
    id: 882,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 883,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 884,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 885,
  },
  {
    bankName: 'Sparkasse Emsland',
    bic: 'NOLADE21EMS',
    id: 886,
  },
  {
    bankName: 'Emsländische Volksbank',
    bic: 'GENODEF1LIG',
    id: 887,
  },
  {
    bankName: 'Volksbank Haselünne',
    bic: 'GENODEF1HLN',
    id: 888,
  },
  {
    bankName: 'Emsländische Volksbank Meppen -alt-',
    bic: 'GENODEF1MEP',
    id: 889,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODEF1LEN',
    id: 890,
  },
  {
    bankName: 'Volksbank Haren Fil d Ostfriesischen VB',
    bic: 'GENODEF1HAR',
    id: 891,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 892,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 893,
  },
  {
    bankName: 'Kreissparkasse Grafschaft Bentheim zu Nordhorn',
    bic: 'NOLADE21NOH',
    id: 894,
  },
  {
    bankName: 'Raiffeisen- und Volksbank Nordhorn -alt-',
    bic: 'GENODEF1NDH',
    id: 895,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB267',
    id: 896,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB926',
    id: 897,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB927',
    id: 898,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB928',
    id: 899,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B267',
    id: 900,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B274',
    id: 901,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B273',
    id: 902,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B275',
    id: 903,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 904,
  },
  {
    bankName: 'Sparkasse Goslar/Harz -alt-',
    bic: 'NOLADE21GSL',
    id: 905,
  },
  {
    bankName: 'Kreissparkasse Clausthal-Zellerfeld -alt-',
    bic: 'NOLADE21CLZ',
    id: 906,
  },
  {
    bankName: 'Sparkasse Salzgitter -alt-',
    bic: 'NOLADE21SZG',
    id: 907,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB268',
    id: 908,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB929',
    id: 909,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB934',
    id: 910,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H268',
    id: 911,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H280',
    id: 912,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H285',
    id: 913,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF268',
    id: 914,
  },
  {
    bankName: 'Volksbank Nordharz',
    bic: 'GENODEF1VNH',
    id: 915,
  },
  {
    bankName: 'Volksbank im Harz',
    bic: 'GENODEF1OHA',
    id: 916,
  },
  {
    bankName: 'Commerzbank Wolfsburg',
    bic: 'COBADEFFXXX',
    id: 917,
  },
  {
    bankName: 'Sparkasse Celle-Gifhorn-Wolfsburg',
    bic: 'NOLADE21GFW',
    id: 918,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB269',
    id: 919,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H269',
    id: 920,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF269',
    id: 921,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI11',
    id: 922,
  },
  {
    bankName: 'Volksbank Braunschweig Wolfsburg',
    bic: 'GENODEF1WOB',
    id: 923,
  },
  {
    bankName: 'Volkswagen Bank',
    bic: 'VOWADE2BXXX',
    id: 924,
  },
  {
    bankName: 'Audi Bank Zndl d Volkswagen Bank',
    bic: 'AUDFDE21XXX',
    id: 925,
  },
  {
    bankName: 'Skoda Bank',
    bic: 'SKODDE21XXX',
    id: 926,
  },
  {
    bankName: 'AutoEuropa Bank',
    bic: 'ECBKDE21XXX',
    id: 927,
  },
  {
    bankName: 'Seat Bank Zndl d Volkswagen Bank',
    bic: 'SEATDE21XXX',
    id: 928,
  },
  {
    bankName: 'Bankhaus C. L. Seeliger',
    bic: 'BCLSDE21XXX',
    id: 929,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 930,
  },
  {
    bankName: 'Volksbank Börßum-Hornburg',
    bic: 'GENODEF1BOH',
    id: 931,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB270',
    id: 932,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB278',
    id: 933,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB271',
    id: 934,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB930',
    id: 935,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB274',
    id: 936,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB272',
    id: 937,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB279',
    id: 938,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB273',
    id: 939,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB275',
    id: 940,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H270',
    id: 941,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H271',
    id: 942,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H274',
    id: 943,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H281',
    id: 944,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H278',
    id: 945,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H279',
    id: 946,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H272',
    id: 947,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H273',
    id: 948,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H275',
    id: 949,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP23',
    id: 950,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDE2HP23',
    id: 951,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB277',
    id: 952,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H277',
    id: 953,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB276',
    id: 954,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE2H276',
    id: 955,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF270',
    id: 956,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI12',
    id: 957,
  },
  {
    bankName: 'PSD Bank',
    bic: 'GENODEF1P02',
    id: 958,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODEF1WFV',
    id: 959,
  },
  {
    bankName: 'Bankhaus Rautenschlein',
    bic: 'GENODEF1RTS',
    id: 960,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 961,
  },
  {
    bankName: 'Volksbank Weserbergland -alt-',
    bic: 'GENODEF1HMV',
    id: 962,
  },
  {
    bankName: 'Volksbank Braunlage',
    bic: 'GENODEF1BLG',
    id: 963,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODEF1SES',
    id: 964,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1280',
    id: 965,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 966,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 967,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 968,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 969,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 970,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 971,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 972,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 973,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 974,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 975,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 976,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 977,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 978,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 979,
  },
  {
    bankName: 'Oldenburgische Landesbank (vormals W. Fortmann & Söhne)',
    bic: 'FORTDEH4XXX',
    id: 980,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 981,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 982,
  },
  {
    bankName: 'Landessparkasse zu Oldenburg',
    bic: 'SLZODE22XXX',
    id: 983,
  },
  {
    bankName: 'Raiffeisenbank Oldenburg',
    bic: 'GENODEF1OL2',
    id: 984,
  },
  {
    bankName: 'Raiffeisenbank Wesermarsch-Süd',
    bic: 'GENODEF1BRN',
    id: 985,
  },
  {
    bankName: 'VR-Bank in Südoldenburg',
    bic: 'GENODEF1CLP',
    id: 986,
  },
  {
    bankName: 'Volksbank Dammer Berge',
    bic: 'GENODEF1DAM',
    id: 987,
  },
  {
    bankName: 'Volksbank Oldenburg',
    bic: 'GENODEF1EDE',
    id: 988,
  },
  {
    bankName: 'Raiffeisenbank Rastede',
    bic: 'GENODEF1RSE',
    id: 989,
  },
  {
    bankName: 'Vereinigte Volksbank',
    bic: 'GENODEF1HUD',
    id: 990,
  },
  {
    bankName: 'Vereinigte Volksbank GAA',
    bic: 'GENODEF1HUD',
    id: 991,
  },
  {
    bankName: 'Volksbank Lohne-Mühlen',
    bic: 'GENODEF1LON',
    id: 992,
  },
  {
    bankName: 'Volksbank Bookholzberg-Lemwerder -alt-',
    bic: 'GENODEF1GBH',
    id: 993,
  },
  {
    bankName: 'Volksbank Bösel -alt-',
    bic: 'GENODEF1BSL',
    id: 994,
  },
  {
    bankName: 'Volksbank Westerstede',
    bic: 'GENODEF1WRE',
    id: 995,
  },
  {
    bankName: 'Volksbank Essen-Cappeln',
    bic: 'GENODEF1ESO',
    id: 996,
  },
  {
    bankName: 'Volksbank Bakum',
    bic: 'GENODEF1BAM',
    id: 997,
  },
  {
    bankName: 'Volksbank Vechta',
    bic: 'GENODEF1VEC',
    id: 998,
  },
  {
    bankName: 'Raiffeisen-Volksbank Varel-Nordenham',
    bic: 'GENODEF1NHE',
    id: 999,
  },
  {
    bankName: 'Volksbank Löningen',
    bic: 'GENODEF1LOG',
    id: 1000,
  },
  {
    bankName: 'VR-Bank Dinklage-Steinfeld',
    bic: 'GENODEF1DIK',
    id: 1001,
  },
  {
    bankName: 'Raiffeisenbank Scharrel',
    bic: 'GENODEF1SAN',
    id: 1002,
  },
  {
    bankName: 'Volksbank Visbek',
    bic: 'GENODEF1VIS',
    id: 1003,
  },
  {
    bankName: 'VR Bank Oldenburg Land',
    bic: 'GENODEF1WDH',
    id: 1004,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODEF1FOY',
    id: 1005,
  },
  {
    bankName: 'Volksbank Neuenkirchen-Vörden -alt-',
    bic: 'GENODEF1NEO',
    id: 1006,
  },
  {
    bankName: 'Volksbank Delmenhorst Schierbrok',
    bic: 'GENODEF1GSC',
    id: 1007,
  },
  {
    bankName: 'Volksbank Lastrup',
    bic: 'GENODEF1LAP',
    id: 1008,
  },
  {
    bankName: 'Raiffeisenbank Butjadingen-Abbehausen',
    bic: 'GENODEF1BUT',
    id: 1009,
  },
  {
    bankName: 'Raiffeisenbank Strücklingen-Idafehn',
    bic: 'GENODEF1ORF',
    id: 1010,
  },
  {
    bankName: 'VR Bank Oldenburg Land West -alt-',
    bic: 'GENODEF1HAT',
    id: 1011,
  },
  {
    bankName: 'Volksbank Emstek',
    bic: 'GENODEF1EMK',
    id: 1012,
  },
  {
    bankName: 'Raiffeisenbank Garrel -alt-',
    bic: 'GENODEF1GRR',
    id: 1013,
  },
  {
    bankName: 'VR Bank Oldenburg Land West -alt-',
    bic: 'GENODEF1VAG',
    id: 1014,
  },
  {
    bankName: 'Volksbank Obergrafschaft -alt-',
    bic: 'GENODEF1BBH',
    id: 1015,
  },
  {
    bankName: 'Hümmlinger Volksbank',
    bic: 'GENODEF1WLT',
    id: 1016,
  },
  {
    bankName: 'Volksbank Nordhümmling',
    bic: 'GENODEF1BOG',
    id: 1017,
  },
  {
    bankName: 'Raiffeisenbank Oldersum',
    bic: 'GENODEF1MLO',
    id: 1018,
  },
  {
    bankName: 'Raiffeisenbank Wiesedermeer-Wiesede-Marcardsm',
    bic: 'GENODEF1WWM',
    id: 1019,
  },
  {
    bankName: 'Raiffeisenbank Ems-Vechte',
    bic: 'GENODEF1KBL',
    id: 1020,
  },
  {
    bankName: 'Volksbank Niedergrafschaft',
    bic: 'GENODEF1HOO',
    id: 1021,
  },
  {
    bankName: 'Raiffeisenbank Lorup',
    bic: 'GENODEF1LRU',
    id: 1022,
  },
  {
    bankName: 'Grafschafter Volksbank',
    bic: 'GENODEF1NEV',
    id: 1023,
  },
  {
    bankName: 'Volksbank Emstal',
    bic: 'GENODEF1LTH',
    id: 1024,
  },
  {
    bankName: 'Volksbank Süd-Emsland',
    bic: 'GENODEF1SPL',
    id: 1025,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB280',
    id: 1026,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB281',
    id: 1027,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB280',
    id: 1028,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB281',
    id: 1029,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 1030,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 1031,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 1032,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1033,
  },
  {
    bankName: 'Sparkasse Wilhelmshaven',
    bic: 'BRLADE21WHV',
    id: 1034,
  },
  {
    bankName: 'Kreissparkasse Wittmund',
    bic: 'BRLADE21WTM',
    id: 1035,
  },
  {
    bankName: 'Volksbank Jever',
    bic: 'GENODEF1JEV',
    id: 1036,
  },
  {
    bankName: 'Raiffeisen-Volksbank Varel-Nordenham',
    bic: 'GENODEF1VAR',
    id: 1037,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB282',
    id: 1038,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB283',
    id: 1039,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB282',
    id: 1040,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB283',
    id: 1041,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF282',
    id: 1042,
  },
  {
    bankName: 'Volksbank Wilhelmshaven',
    bic: 'GENODEF1WHV',
    id: 1043,
  },
  {
    bankName: 'Volksbank Esens',
    bic: 'GENODEF1ESE',
    id: 1044,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 1045,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 1046,
  },
  {
    bankName: 'Sparkasse Aurich-Norden',
    bic: 'BRLADE21ANO',
    id: 1047,
  },
  {
    bankName: 'Raiffeisen-Volksbank Fresena',
    bic: 'GENODEF1MAR',
    id: 1048,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 1049,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 1050,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1051,
  },
  {
    bankName: 'Sparkasse Emden',
    bic: 'BRLADE21EMD',
    id: 1052,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB284',
    id: 1053,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB289',
    id: 1054,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB286',
    id: 1055,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB298',
    id: 1056,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB284',
    id: 1057,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB289',
    id: 1058,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB286',
    id: 1059,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB298',
    id: 1060,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 1061,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 1062,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1063,
  },
  {
    bankName: 'Sparkasse LeerWittmund',
    bic: 'BRLADE21LER',
    id: 1064,
  },
  {
    bankName: 'Raiffeisen-Volksbank',
    bic: 'GENODEF1UPL',
    id: 1065,
  },
  {
    bankName: 'Raiffeisenbank Flachsmeer',
    bic: 'GENODEF1WEF',
    id: 1066,
  },
  {
    bankName: 'Raiffeisenbank Moormerland',
    bic: 'GENODEF1HTL',
    id: 1067,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1MML',
    id: 1068,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB285',
    id: 1069,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB287',
    id: 1070,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB288',
    id: 1071,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB285',
    id: 1072,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB287',
    id: 1073,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB288',
    id: 1074,
  },
  {
    bankName: 'Ostfriesische Volksbank Leer',
    bic: 'GENODEF1LER',
    id: 1075,
  },
  {
    bankName: 'Volksbank Papenburg Fil d. Ostfries. VB Leer',
    bic: 'GENODEF1PAP',
    id: 1076,
  },
  {
    bankName: 'Volksbank Westrhauderfehn',
    bic: 'GENODEF1WRH',
    id: 1077,
  },
  {
    bankName: 'Bundesbank eh Bremen',
    bic: 'MARKDEF1290',
    id: 1078,
  },
  {
    bankName: 'Bankhaus Neelmeyer',
    bic: 'NEELDE22XXX',
    id: 1079,
  },
  {
    bankName: 'Greensill Bank',
    bic: 'NFHBDE21XXX',
    id: 1080,
  },
  {
    bankName: 'M.M. Warburg & Co (vormals Bankhaus Carl F. Plump & CO)',
    bic: 'PLUMDE29XXX',
    id: 1081,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 1082,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 1083,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1084,
  },
  {
    bankName: 'Norddeutsche Landesbank - Girozentrale -',
    bic: 'BRLADE22XXX',
    id: 1085,
  },
  {
    bankName: 'Norddeutsche Landesbank - Girozentrale -',
    bic: 'BRLADE22OLD',
    id: 1086,
  },
  {
    bankName: 'Sparkasse Bremen',
    bic: 'SBREDE22XXX',
    id: 1087,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBBRE',
    id: 1088,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB295',
    id: 1089,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB292',
    id: 1090,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB293',
    id: 1091,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB294',
    id: 1092,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB296',
    id: 1093,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB297',
    id: 1094,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB290',
    id: 1095,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHBXXX',
    id: 1096,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB295',
    id: 1097,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB297',
    id: 1098,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB292',
    id: 1099,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB294',
    id: 1100,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB293',
    id: 1101,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB290',
    id: 1102,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB296',
    id: 1103,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP21',
    id: 1104,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEHBP21',
    id: 1105,
  },
  {
    bankName: 'Commerzbank vormals Bremer Bank (Dresdner Bank)',
    bic: 'DRESDEFF290',
    id: 1106,
  },
  {
    bankName: 'Commerzbank vormals Bremer Bank (Dresdner Bank) ITGK',
    bic: 'DRESDEFFI13',
    id: 1107,
  },
  {
    bankName: 'PSD Bank Nord',
    bic: 'GENODEF1P03',
    id: 1108,
  },
  {
    bankName: 'Oldenburgische Landesbank AG',
    bic: 'OLBODEH2XXX',
    id: 1109,
  },
  {
    bankName: 'Kreissparkasse Syke',
    bic: 'BRLADE21SYK',
    id: 1110,
  },
  {
    bankName: 'Kreissparkasse Osterholz -alt-',
    bic: 'BRLADE21OHZ',
    id: 1111,
  },
  {
    bankName: 'Zweckverbandssparkasse Scheeßel',
    bic: 'BRLADE21SHL',
    id: 1112,
  },
  {
    bankName: 'Kreissparkasse Verden',
    bic: 'BRLADE21VER',
    id: 1113,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODEF1OHZ',
    id: 1114,
  },
  {
    bankName: 'Volksbank Schwanewede',
    bic: 'GENODEF1SWW',
    id: 1115,
  },
  {
    bankName: 'Volksbank Aller-Weser -alt-',
    bic: 'GENODEF1VER',
    id: 1116,
  },
  {
    bankName: 'Volksbank Oyten',
    bic: 'GENODEF1OYT',
    id: 1117,
  },
  {
    bankName: 'Volksbank Sottrum',
    bic: 'GENODEF1SUM',
    id: 1118,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODEF1WOP',
    id: 1119,
  },
  {
    bankName: 'Volksbank Syke',
    bic: 'GENODEF1SHR',
    id: 1120,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB291',
    id: 1121,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEHB291',
    id: 1122,
  },
  {
    bankName: 'Bremische Volksbank',
    bic: 'GENODEF1HB1',
    id: 1123,
  },
  {
    bankName: 'Volksbank Bremen-Nord',
    bic: 'GENODEF1HB2',
    id: 1124,
  },
  {
    bankName: 'Volksbank Bremen-Nord (Gf GAA)',
    bic: 'GENODEF1HB2',
    id: 1125,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1126,
  },
  {
    bankName: 'Weser-Elbe Sparkasse',
    bic: 'BRLADE21BRS',
    id: 1127,
  },
  {
    bankName: 'Kreissparkasse Wesermünde-Hadeln -alt-',
    bic: 'BRLADE21BRK',
    id: 1128,
  },
  {
    bankName: 'Volksbank Geeste-Nord',
    bic: 'GENODEF1BRV',
    id: 1129,
  },
  {
    bankName: 'Volksbank Bremerhaven-Cuxland',
    bic: 'GENODEF1BEV',
    id: 1130,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF292',
    id: 1131,
  },
  {
    bankName: 'Volksbank Bremerhaven-Wesermünde -alt-',
    bic: 'GENODEF1HBV',
    id: 1132,
  },
  {
    bankName: 'Bundesbank eh Düsseldorf',
    bic: 'MARKDEF1300',
    id: 1133,
  },
  {
    bankName: 'IKB Deutsche Industriebank',
    bic: 'IKBDDEDDXXX',
    id: 1134,
  },
  {
    bankName: 'IKB Privatkunden - IKB Deutsche Industriebank',
    bic: 'IKBDDEDDDIR',
    id: 1135,
  },
  {
    bankName: 'MUFG Bank (Europe) Germany Branch',
    bic: 'BOTKDEDXXXX',
    id: 1136,
  },
  {
    bankName: 'VIVA PAYMENT SERVICES - Zweigniederlassung Deutschland',
    bic: 'VPAYDE32XXX',
    id: 1137,
  },
  {
    bankName: 'BHF-BANK',
    bic: 'BHFBDEFF300',
    id: 1138,
  },
  {
    bankName: 'Mizuho Bank Filiale Düsseldorf',
    bic: 'MHCBDEDDXXX',
    id: 1139,
  },
  {
    bankName: 'TARGOBANK',
    bic: 'CMCIDEDDXXX',
    id: 1140,
  },
  {
    bankName: 'NRW.BANK',
    bic: 'NRWBDEDMXXX',
    id: 1141,
  },
  {
    bankName: 'S Broker Wiesbaden',
    bic: 'PULSDEDDXXX',
    id: 1142,
  },
  {
    bankName: 'Bank11direkt',
    bic: 'CUABDED1XXX',
    id: 1143,
  },
  {
    bankName: 'ETRIS Bank',
    bic: 'ETRIDE31XXX',
    id: 1144,
  },
  {
    bankName: 'HSBC Trinkaus & Burkhardt',
    bic: 'TUBDDEDDXXX',
    id: 1145,
  },
  {
    bankName: 'HSBC Trinkaus VAC',
    bic: 'TUBDDEDDXXX',
    id: 1146,
  },
  {
    bankName: 'Merck Finck A Quintet Private Bank',
    bic: 'MEFIDEMM300',
    id: 1147,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEDDXXX',
    id: 1148,
  },
  {
    bankName: 'Commerzbank, Filiale Düsseldorf 2',
    bic: 'COBADEDDXXX',
    id: 1149,
  },
  {
    bankName: 'Commerzbank GF-D48',
    bic: 'COBADEFFXXX',
    id: 1150,
  },
  {
    bankName: 'Commerzbank Gf 660',
    bic: 'COBADEFFXXX',
    id: 1151,
  },
  {
    bankName: 'Commerzbank Gf 661',
    bic: 'COBADEFFXXX',
    id: 1152,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 1153,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 1154,
  },
  {
    bankName: 'Commerzbank Gf Comp. Center STS',
    bic: 'COBADEFFSTS',
    id: 1155,
  },
  {
    bankName: 'Landesbank Hessen-Thüringen Girozentrale NL. Düsseldorf',
    bic: 'WELADEDDXXX',
    id: 1156,
  },
  {
    bankName: 'Stadtsparkasse Düsseldorf',
    bic: 'DUSSDEDDXXX',
    id: 1157,
  },
  {
    bankName: 'DZ BANK',
    bic: 'GENODEDDXXX',
    id: 1158,
  },
  {
    bankName: 'apoBank',
    bic: 'DAAEDEDDXXX',
    id: 1159,
  },
  {
    bankName: 'PSD Bank Rhein-Ruhr',
    bic: 'GENODEF1P05',
    id: 1160,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDDXXX',
    id: 1161,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD306',
    id: 1162,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD307',
    id: 1163,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD300',
    id: 1164,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD305',
    id: 1165,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD301',
    id: 1166,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD304',
    id: 1167,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD303',
    id: 1168,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD302',
    id: 1169,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBDUE',
    id: 1170,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB306',
    id: 1171,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB305',
    id: 1172,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB307',
    id: 1173,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB301',
    id: 1174,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB303',
    id: 1175,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB302',
    id: 1176,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB300',
    id: 1177,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB304',
    id: 1178,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDDP06',
    id: 1179,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP06',
    id: 1180,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF300',
    id: 1181,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 05',
    bic: 'DRESDEFFXXX',
    id: 1182,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Ztv 22',
    bic: 'DRESDEFFI28',
    id: 1183,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 38',
    bic: 'DRESDEFFXXX',
    id: 1184,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 41',
    bic: 'DRESDEFFI29',
    id: 1185,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 53',
    bic: 'DRESDEFFI30',
    id: 1186,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 55',
    bic: 'DRESDEFF309',
    id: 1187,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf ZW 57',
    bic: 'DRESDEFF316',
    id: 1188,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 61',
    bic: 'DRESDEFFI31',
    id: 1189,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 74',
    bic: 'DRESDEFFI32',
    id: 1190,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 3',
    bic: 'DRESDEFFI76',
    id: 1191,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
    bic: 'DRESDEFFI77',
    id: 1192,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
    bic: 'DRESDEFFI78',
    id: 1193,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
    bic: 'DRESDEFFI79',
    id: 1194,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 7',
    bic: 'DRESDEFFI80',
    id: 1195,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 8',
    bic: 'DRESDEFFI81',
    id: 1196,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 9',
    bic: 'DRESDEFFI82',
    id: 1197,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 10',
    bic: 'DRESDEFFI83',
    id: 1198,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 11',
    bic: 'DRESDEFFI84',
    id: 1199,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 12',
    bic: 'DRESDEFFI85',
    id: 1200,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 95',
    bic: 'DRESDEFFI33',
    id: 1201,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK I',
    bic: 'DRESDEFFI02',
    id: 1202,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK II',
    bic: 'DRESDEFFI03',
    id: 1203,
  },
  {
    bankName: 'Sumitomo Mitsui Banking Corporation',
    bic: 'SMBCDEDDXXX',
    id: 1204,
  },
  {
    bankName: 'Demir-Halk Bank (Nederland)',
    bic: 'DHBNDEDDXXX',
    id: 1205,
  },
  {
    bankName: 'GarantiBank International',
    bic: 'UGBIDEDDXXX',
    id: 1206,
  },
  {
    bankName: 'Isbank Fil Düsseldorf',
    bic: 'ISBKDEFXDUS',
    id: 1207,
  },
  {
    bankName: 'Kreissparkasse Düsseldorf',
    bic: 'WELADED1KSD',
    id: 1208,
  },
  {
    bankName: 'Volksbank Düsseldorf Neuss',
    bic: 'GENODED1DNE',
    id: 1209,
  },
  {
    bankName: 'Volksbank Düsseldorf Neuss (Gf GAA)',
    bic: 'GENODED1DNE',
    id: 1210,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM414',
    id: 1211,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM414',
    id: 1212,
  },
  {
    bankName: 'flatexDEGIRO Bank',
    bic: 'BIWBDE33303',
    id: 1213,
  },
  {
    bankName: 'Stadt-Sparkasse Haan',
    bic: 'WELADED1HAA',
    id: 1214,
  },
  {
    bankName: 'RCI Banque Niederlassung Deutschland',
    bic: 'RCIDDE3NXXX',
    id: 1215,
  },
  {
    bankName: 'RCI Banque Direkt',
    bic: 'RCIDDE3NPAY',
    id: 1216,
  },
  {
    bankName: 'KBC Bank Ndl Deutschland',
    bic: 'KREDDEDDXXX',
    id: 1217,
  },
  {
    bankName: 'Bankhaus Werhahn',
    bic: 'WERHDED1XXX',
    id: 1218,
  },
  {
    bankName: 'Bank11 für Privatkunden und Handel, Neuss',
    bic: 'WEFZDED1XXX',
    id: 1219,
  },
  {
    bankName: 'Sparkasse Neuss',
    bic: 'WELADEDNXXX',
    id: 1220,
  },
  {
    bankName: 'Stadtsparkasse Kaarst-Büttgen -alt-',
    bic: 'WELADED1KST',
    id: 1221,
  },
  {
    bankName: 'Volksbank Neuss -alt-',
    bic: 'GENODED1NSS',
    id: 1222,
  },
  {
    bankName: 'VR Bank',
    bic: 'GENODED1NLD',
    id: 1223,
  },
  {
    bankName: 'VR Bank (Gf GAA)',
    bic: 'GENODED1NLD',
    id: 1224,
  },
  {
    bankName: 'Santander Consumer Bank',
    bic: 'SCFBDE33XXX',
    id: 1225,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1226,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 1227,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 1228,
  },
  {
    bankName: 'Stadtsparkasse Mönchengladbach',
    bic: 'MGLSDE33XXX',
    id: 1229,
  },
  {
    bankName: 'Gladbacher Bank von 1922',
    bic: 'GENODED1GBM',
    id: 1230,
  },
  {
    bankName: 'Volksbank Mönchengladbach',
    bic: 'GENODED1MRB',
    id: 1231,
  },
  {
    bankName: 'Volksbank Brüggen-Nettetal',
    bic: 'GENODED1KBN',
    id: 1232,
  },
  {
    bankName: 'Volksbank Schwalmtal',
    bic: 'GENODED1NKR',
    id: 1233,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD310',
    id: 1234,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD317',
    id: 1235,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD318',
    id: 1236,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD319',
    id: 1237,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB310',
    id: 1238,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB319',
    id: 1239,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB317',
    id: 1240,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB318',
    id: 1241,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDDP05',
    id: 1242,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP05',
    id: 1243,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF310',
    id: 1244,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 61',
    bic: 'DRESDEFFI34',
    id: 1245,
  },
  {
    bankName: 'Kreissparkasse Heinsberg in Erkelenz',
    bic: 'WELADED1ERK',
    id: 1246,
  },
  {
    bankName: 'Volksbank Erkelenz',
    bic: 'GENODED1EHE',
    id: 1247,
  },
  {
    bankName: 'Raiffeisenbank Erkelenz',
    bic: 'GENODED1LOE',
    id: 1248,
  },
  {
    bankName: 'Volksbank Viersen',
    bic: 'GENODED1VSN',
    id: 1249,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD314',
    id: 1250,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD315',
    id: 1251,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD316',
    id: 1252,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB314',
    id: 1253,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB316',
    id: 1254,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB315',
    id: 1255,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1256,
  },
  {
    bankName: 'Sparkasse Krefeld',
    bic: 'SPKRDE33XXX',
    id: 1257,
  },
  {
    bankName: 'Sparkasse der Stadt Straelen -alt-',
    bic: 'WELADED1STR',
    id: 1258,
  },
  {
    bankName: 'Volksbank Krefeld',
    bic: 'GENODED1HTK',
    id: 1259,
  },
  {
    bankName: 'Volksbank an der Niers',
    bic: 'GENODED1GDL',
    id: 1260,
  },
  {
    bankName: 'Volksbank Kempen-Grefrath',
    bic: 'GENODED1KMP',
    id: 1261,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB320',
    id: 1262,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB323',
    id: 1263,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB327',
    id: 1264,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB328',
    id: 1265,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB329',
    id: 1266,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB936',
    id: 1267,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB322',
    id: 1268,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB321',
    id: 1269,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD320',
    id: 1270,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD323',
    id: 1271,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD327',
    id: 1272,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD328',
    id: 1273,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD331',
    id: 1274,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD329',
    id: 1275,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD322',
    id: 1276,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD321',
    id: 1277,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF320',
    id: 1278,
  },
  {
    bankName: 'Verbandssparkasse Goch',
    bic: 'WELADED1GOC',
    id: 1279,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1280,
  },
  {
    bankName: 'Sparkasse Rhein-Maas',
    bic: 'WELADED1KLE',
    id: 1281,
  },
  {
    bankName: 'Volksbank Kleverland',
    bic: 'GENODED1KLL',
    id: 1282,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB324',
    id: 1283,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB326',
    id: 1284,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB325',
    id: 1285,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD324',
    id: 1286,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD325',
    id: 1287,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDD326',
    id: 1288,
  },
  {
    bankName: 'akf bank',
    bic: 'AKFBDE31XXX',
    id: 1289,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM809',
    id: 1290,
  },
  {
    bankName: 'GEFA BANK',
    bic: 'GGABDE31XXX',
    id: 1291,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1292,
  },
  {
    bankName: 'Commerzbank Zw 117',
    bic: 'COBADEDHXXX',
    id: 1293,
  },
  {
    bankName: 'Stadtsparkasse Wuppertal',
    bic: 'WUPSDE33XXX',
    id: 1294,
  },
  {
    bankName: 'Credit- und Volksbank Wuppertal -alt-',
    bic: 'GENODED1CVW',
    id: 1295,
  },
  {
    bankName: 'Sparda-Bank West',
    bic: 'GENODED1SPW',
    id: 1296,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBWUP',
    id: 1297,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB331',
    id: 1298,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB332',
    id: 1299,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB333',
    id: 1300,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB334',
    id: 1301,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB330',
    id: 1302,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB335',
    id: 1303,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDWXXX',
    id: 1304,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW331',
    id: 1305,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW333',
    id: 1306,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW332',
    id: 1307,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW330',
    id: 1308,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW334',
    id: 1309,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW335',
    id: 1310,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFI86',
    id: 1311,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF332',
    id: 1312,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
    bic: 'DRESDEFFI87',
    id: 1313,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 3',
    bic: 'DRESDEFFI88',
    id: 1314,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
    bic: 'DRESDEFFI89',
    id: 1315,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
    bic: 'DRESDEFFI90',
    id: 1316,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1317,
  },
  {
    bankName: 'Sparkasse Hilden-Ratingen-Velbert',
    bic: 'WELADED1VEL',
    id: 1318,
  },
  {
    bankName: 'Sparkasse Heiligenhaus -alt-',
    bic: 'WELADED1HGH',
    id: 1319,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1320,
  },
  {
    bankName: 'Stadtsparkasse Remscheid',
    bic: 'WELADEDRXXX',
    id: 1321,
  },
  {
    bankName: 'Sparkasse Radevormwald-Hückeswagen',
    bic: 'WELADED1RVW',
    id: 1322,
  },
  {
    bankName: 'Stadtsparkasse Wermelskirchen',
    bic: 'WELADED1WMK',
    id: 1323,
  },
  {
    bankName: 'Volksbank im Bergischen Land',
    bic: 'VBRSDE33XXX',
    id: 1324,
  },
  {
    bankName: 'Volksbank im Bergischen Land',
    bic: 'VBRSDE33305',
    id: 1325,
  },
  {
    bankName: 'Volksbank im Bergischen Land',
    bic: 'VBRSDE33343',
    id: 1326,
  },
  {
    bankName: 'Volksbank im Bergischen Land',
    bic: 'VBRSDE33330',
    id: 1327,
  },
  {
    bankName: 'Volksbank im Bergischen Land',
    bic: 'VBRSDE33342',
    id: 1328,
  },
  {
    bankName: 'Volksbank im Bergischen Land',
    bic: 'VBRSDE33345',
    id: 1329,
  },
  {
    bankName: 'Volksbank im Bergischen Land',
    bic: 'VBRSDE33341',
    id: 1330,
  },
  {
    bankName: 'Volksbank im Bergischen Land',
    bic: 'VBRSDE33346',
    id: 1331,
  },
  {
    bankName: 'Volksbank im Bergischen Land',
    bic: 'VBRSDE33344',
    id: 1332,
  },
  {
    bankName: 'Volksbank im Bergischen Land',
    bic: 'VBRSDE33347',
    id: 1333,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB340',
    id: 1334,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB344',
    id: 1335,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB345',
    id: 1336,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB346',
    id: 1337,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB341',
    id: 1338,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW340',
    id: 1339,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW344',
    id: 1340,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW346',
    id: 1341,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW341',
    id: 1342,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW345',
    id: 1343,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF340',
    id: 1344,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1345,
  },
  {
    bankName: 'Stadt-Sparkasse Solingen',
    bic: 'SOLSDE33XXX',
    id: 1346,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB342',
    id: 1347,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB343',
    id: 1348,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW342',
    id: 1349,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW343',
    id: 1350,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF342',
    id: 1351,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1352,
  },
  {
    bankName: 'Commerzbank, Gf Web-K',
    bic: 'COBADEFFXXX',
    id: 1353,
  },
  {
    bankName: 'Sparkasse Duisburg',
    bic: 'DUISDE33XXX',
    id: 1354,
  },
  {
    bankName: 'Bank für Kirche und Diakonie - KD-Bank',
    bic: 'GENODED1DKD',
    id: 1355,
  },
  {
    bankName: 'Bank für Kirche und Diakonie - KD-Bank (Gf GAA RB)',
    bic: 'GENODED1DKD',
    id: 1356,
  },
  {
    bankName: 'Volksbank Rhein-Ruhr',
    bic: 'GENODED1VRR',
    id: 1357,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB350',
    id: 1358,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB351',
    id: 1359,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB352',
    id: 1360,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB354',
    id: 1361,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB356',
    id: 1362,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE350',
    id: 1363,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE351',
    id: 1364,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE352',
    id: 1365,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE354',
    id: 1366,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE356',
    id: 1367,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF350',
    id: 1368,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFI91',
    id: 1369,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
    bic: 'DRESDEFFI92',
    id: 1370,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 3',
    bic: 'DRESDEFFI93',
    id: 1371,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
    bic: 'DRESDEFFI94',
    id: 1372,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
    bic: 'DRESDEFFI95',
    id: 1373,
  },
  {
    bankName: 'Bank für Schiffahrt (BFS) Fil d Ostfr VB Leer',
    bic: 'GENODEF1BSD',
    id: 1374,
  },
  {
    bankName: 'Sparkasse Dinslaken-Voerde-Hünxe -alt-',
    bic: 'WELADED1DIN',
    id: 1375,
  },
  {
    bankName: 'Volksbank Dinslaken',
    bic: 'GENODED1DLK',
    id: 1376,
  },
  {
    bankName: 'Sparkasse am Niederrhein',
    bic: 'WELADED1MOR',
    id: 1377,
  },
  {
    bankName: 'Sparkasse Neukirchen-Vluyn -alt-',
    bic: 'WELADED1NVL',
    id: 1378,
  },
  {
    bankName: 'Sparkasse Rheinberg -alt-',
    bic: 'WELADED1RHB',
    id: 1379,
  },
  {
    bankName: 'Volksbank Niederrhein',
    bic: 'GENODED1NRH',
    id: 1380,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1381,
  },
  {
    bankName: 'Niederrheinische Sparkasse RheinLippe',
    bic: 'WELADED1WES',
    id: 1382,
  },
  {
    bankName: 'Volksbank Rhein-Lippe (GAA)',
    bic: 'GENODED1RLW',
    id: 1383,
  },
  {
    bankName: 'Volksbank Rhein-Lippe',
    bic: 'GENODED1RLW',
    id: 1384,
  },
  {
    bankName: 'Stadtsparkasse Emmerich-Rees -alt-',
    bic: 'WELADED1EMR',
    id: 1385,
  },
  {
    bankName: 'Volksbank Emmerich-Rees',
    bic: 'GENODED1EMR',
    id: 1386,
  },
  {
    bankName: 'Bundesbank eh Essen',
    bic: 'MARKDEF1360',
    id: 1387,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 1388,
  },
  {
    bankName: 'Aareal Bank',
    bic: 'AARBDE5W360',
    id: 1389,
  },
  {
    bankName: 'National-Bank Essen',
    bic: 'NBAGDE3EXXX',
    id: 1390,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM360',
    id: 1391,
  },
  {
    bankName: 'Santander Consumer Bank',
    bic: 'SCFBDE33XXX',
    id: 1392,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1393,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 1394,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 1395,
  },
  {
    bankName: 'Commerzbank, Gf Web-K',
    bic: 'COBADEFFXXX',
    id: 1396,
  },
  {
    bankName: 'Sparkasse Essen',
    bic: 'SPESDE3EXXX',
    id: 1397,
  },
  {
    bankName: 'Pax-Bank',
    bic: 'GENODED1PA2',
    id: 1398,
  },
  {
    bankName: 'Bank im Bistum Essen',
    bic: 'GENODED1BBE',
    id: 1399,
  },
  {
    bankName: 'GENO BANK ESSEN',
    bic: 'GENODEM1GBE',
    id: 1400,
  },
  {
    bankName: 'Sparda-Bank West',
    bic: 'GENODED1SPE',
    id: 1401,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBESS',
    id: 1402,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDEXXX',
    id: 1403,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDEP07',
    id: 1404,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP07',
    id: 1405,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF360',
    id: 1406,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK  2',
    bic: 'DRESDEFFI66',
    id: 1407,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI17',
    id: 1408,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1409,
  },
  {
    bankName: 'Sparkasse Mülheim an der Ruhr',
    bic: 'SPMHDE3EXXX',
    id: 1410,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB362',
    id: 1411,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE362',
    id: 1412,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF362',
    id: 1413,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1414,
  },
  {
    bankName: 'Stadtsparkasse Oberhausen',
    bic: 'WELADED1OBH',
    id: 1415,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB365',
    id: 1416,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE365',
    id: 1417,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF365',
    id: 1418,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1370',
    id: 1419,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 1420,
  },
  {
    bankName: 'BNP Paribas Niederlassung Deutschland',
    bic: 'BNPADEFFXXX',
    id: 1421,
  },
  {
    bankName: 'BNP Paribas Niederlassung Deutschland',
    bic: 'BNPADEFFXXX',
    id: 1422,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 1423,
  },
  {
    bankName: 'bunq Niederlassung Deutschland',
    bic: 'BUNQDE82XXX',
    id: 1424,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM429',
    id: 1425,
  },
  {
    bankName: 'Fondsdepot Bank',
    bic: 'AXABDE31XXX',
    id: 1426,
  },
  {
    bankName: 'TOYOTA Kreditbank',
    bic: 'TOBADE33XXX',
    id: 1427,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33XXX',
    id: 1428,
  },
  {
    bankName: 'Santander Consumer Bank MG',
    bic: 'AKBCDE31XXX',
    id: 1429,
  },
  {
    bankName: 'Ford Bank Ndl. der FCE Bank',
    bic: 'FDBADE3KXXX',
    id: 1430,
  },
  {
    bankName: 'Ford Bank',
    bic: 'FDBADE8FXXX',
    id: 1431,
  },
  {
    bankName: 'Ford Bank (GF EG)',
    bic: 'LRFSDE31XXX',
    id: 1432,
  },
  {
    bankName: 'Deutsche Oppenheim Family Office (ehem. Sal. Oppenheim)',
    bic: 'SOPPDE3KXXX',
    id: 1433,
  },
  {
    bankName: 'abcbank',
    bic: 'WWBADE3AXXX',
    id: 1434,
  },
  {
    bankName: 'Isbank Fil Köln',
    bic: 'ISBKDEFXKOL',
    id: 1435,
  },
  {
    bankName: 'Commerzbank, CC SP',
    bic: 'COBADEFFXXX',
    id: 1436,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1437,
  },
  {
    bankName: 'Commerzbank GF-K48',
    bic: 'COBADEFFXXX',
    id: 1438,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 1439,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 1440,
  },
  {
    bankName: 'Sparkasse KölnBonn',
    bic: 'COLSDE33XXX',
    id: 1441,
  },
  {
    bankName: 'Kreissparkasse Köln',
    bic: 'COKSDE33XXX',
    id: 1442,
  },
  {
    bankName: 'Pax-Bank Gf MHD',
    bic: 'GENODED1PA7',
    id: 1443,
  },
  {
    bankName: 'Pax-Bank',
    bic: 'GENODED1PAX',
    id: 1444,
  },
  {
    bankName: 'Pax-Bank GF GAA',
    bic: 'GENODED1PAX',
    id: 1445,
  },
  {
    bankName: 'Sparda-Bank West',
    bic: 'GENODED1SPK',
    id: 1446,
  },
  {
    bankName: 'PSD Bank West',
    bic: 'GENODEF1P13',
    id: 1447,
  },
  {
    bankName: 'Bensberger Bank',
    bic: 'GENODED1BGL',
    id: 1448,
  },
  {
    bankName: 'Volksbank Rhein-Erft-Köln',
    bic: 'GENODED1FHH',
    id: 1449,
  },
  {
    bankName: 'VR Bank Bergisch Gladbach-Leverkusen',
    bic: 'GENODED1PAF',
    id: 1450,
  },
  {
    bankName: 'Raiffeisenbank Fischenich-Kendenich -alt-',
    bic: 'GENODED1FKH',
    id: 1451,
  },
  {
    bankName: 'Spar- und Darlehnskasse Aegidienberg -alt-',
    bic: 'GENODED1AEG',
    id: 1452,
  },
  {
    bankName: 'Raiffeisenbank Aldenhoven',
    bic: 'GENODED1ALD',
    id: 1453,
  },
  {
    bankName: 'Volksbank Berg',
    bic: 'GENODED1RKO',
    id: 1454,
  },
  {
    bankName: 'Spar- und Darlehnskasse Brachelen -alt-',
    bic: 'GENODED1HCK',
    id: 1455,
  },
  {
    bankName: 'Volksbank Meerbusch -alt-',
    bic: 'GENODED1MBU',
    id: 1456,
  },
  {
    bankName: 'Volksbank Erft',
    bic: 'GENODED1ERE',
    id: 1457,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODED1GLK',
    id: 1458,
  },
  {
    bankName: 'Volksbank Gemünd-Kall -alt-',
    bic: 'GENODED1GKK',
    id: 1459,
  },
  {
    bankName: 'Raiffeisenbank Grevenbroich -alt-',
    bic: 'GENODED1GRB',
    id: 1460,
  },
  {
    bankName: 'Raiffeisenbank Gymnich',
    bic: 'GENODED1EGY',
    id: 1461,
  },
  {
    bankName: 'Volksbank Haaren',
    bic: 'GENODED1HAW',
    id: 1462,
  },
  {
    bankName: 'Raiffeisenbank von 1895 Zw Horrem -alt-',
    bic: 'GENODED1KHO',
    id: 1463,
  },
  {
    bankName: 'Volksbank Heimbach',
    bic: 'GENODED1HMB',
    id: 1464,
  },
  {
    bankName: 'Raiffeisenbank Selfkant  -alt-',
    bic: 'GENODED1SEG',
    id: 1465,
  },
  {
    bankName: 'Spar- und Darlehnskasse Hoengen -alt-',
    bic: 'GENODED1AHO',
    id: 1466,
  },
  {
    bankName: 'VR-Bank Rur-Wurm -alt-',
    bic: 'GENODED1IMM',
    id: 1467,
  },
  {
    bankName: 'Raiffeisenbank Junkersdorf -alt-',
    bic: 'GENODED1JUK',
    id: 1468,
  },
  {
    bankName: 'Raiffeisenbank Kaarst',
    bic: 'GENODED1KAA',
    id: 1469,
  },
  {
    bankName: 'Volksbank Heinsberg',
    bic: 'GENODED1HRB',
    id: 1470,
  },
  {
    bankName: 'Volksbank Dünnwald-Holweide',
    bic: 'GENODED1DHK',
    id: 1471,
  },
  {
    bankName: 'Volksbank Köln-Nord -alt-',
    bic: 'GENODED1KNL',
    id: 1472,
  },
  {
    bankName: 'Raiffeisenbk Erftstadt -alt-',
    bic: 'GENODED1ERF',
    id: 1473,
  },
  {
    bankName: 'VR-Bank Rhein-Sieg',
    bic: 'GENODED1RST',
    id: 1474,
  },
  {
    bankName: 'Raiffeisenbank Rhein-Berg -alt-',
    bic: 'GENODED1MNH',
    id: 1475,
  },
  {
    bankName: 'Raiffeisenbank Much-Ruppichteroth -alt-',
    bic: 'GENODED1MUC',
    id: 1476,
  },
  {
    bankName: 'Raiffeisenbank Voreifel',
    bic: 'GENODED1RBC',
    id: 1477,
  },
  {
    bankName: 'Rosbacher Raiffeisenbank',
    bic: 'GENODED1WND',
    id: 1478,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODED1SMR',
    id: 1479,
  },
  {
    bankName: 'Raiffeisenbank Sankt Augustin -alt-',
    bic: 'GENODED1SAM',
    id: 1480,
  },
  {
    bankName: 'VR-Bank Nordeifel',
    bic: 'GENODED1SLE',
    id: 1481,
  },
  {
    bankName: 'Volksbank Wachtberg -alt-',
    bic: 'GENODED1WVI',
    id: 1482,
  },
  {
    bankName: 'Raiffeisenbk Wesseling -alt-',
    bic: 'GENODED1WSL',
    id: 1483,
  },
  {
    bankName: 'Volksbank Wipperfürth-Lindlar',
    bic: 'GENODED1WPF',
    id: 1484,
  },
  {
    bankName: 'Brühler Bank',
    bic: 'GENODED1BRL',
    id: 1485,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK402',
    id: 1486,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBKOE',
    id: 1487,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB379',
    id: 1488,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB938',
    id: 1489,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB373',
    id: 1490,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB370',
    id: 1491,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB939',
    id: 1492,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB353',
    id: 1493,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB940',
    id: 1494,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB355',
    id: 1495,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB372',
    id: 1496,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB941',
    id: 1497,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB357',
    id: 1498,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB358',
    id: 1499,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB386',
    id: 1500,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB360',
    id: 1501,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB371',
    id: 1502,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDKXXX',
    id: 1503,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK372',
    id: 1504,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK370',
    id: 1505,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK373',
    id: 1506,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK351',
    id: 1507,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK354',
    id: 1508,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK352',
    id: 1509,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK355',
    id: 1510,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK356',
    id: 1511,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK360',
    id: 1512,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK386',
    id: 1513,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK371',
    id: 1514,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK379',
    id: 1515,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK357',
    id: 1516,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK353',
    id: 1517,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK358',
    id: 1518,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDKP08',
    id: 1519,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP08',
    id: 1520,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF370',
    id: 1521,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf PCC DCC-ITGK 1',
    bic: 'DRESDEFFI51',
    id: 1522,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
    bic: 'DRESDEFFI67',
    id: 1523,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
    bic: 'DRESDEFFI96',
    id: 1524,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
    bic: 'DRESDEFFI97',
    id: 1525,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 7',
    bic: 'DRESDEFFI98',
    id: 1526,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 8',
    bic: 'DRESDEFFJ01',
    id: 1527,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 9',
    bic: 'DRESDEFFJ02',
    id: 1528,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 10',
    bic: 'DRESDEFFJ03',
    id: 1529,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 11',
    bic: 'DRESDEFFJ04',
    id: 1530,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 12',
    bic: 'DRESDEFFJ05',
    id: 1531,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 13',
    bic: 'DRESDEFFJ06',
    id: 1532,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 96',
    bic: 'DRESDEFFXXX',
    id: 1533,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 97',
    bic: 'DRESDEFFXXX',
    id: 1534,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 14',
    bic: 'DRESDEFFJ07',
    id: 1535,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 99',
    bic: 'DRESDEFFI36',
    id: 1536,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK I',
    bic: 'DRESDEFFI04',
    id: 1537,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK II',
    bic: 'DRESDEFFI05',
    id: 1538,
  },
  {
    bankName: 'Kölner Bank -alt-',
    bic: 'GENODED1CGN',
    id: 1539,
  },
  {
    bankName: 'VR-Bank Rhein-Erft -alt-',
    bic: 'GENODED1BRH',
    id: 1540,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1541,
  },
  {
    bankName: 'Stadt-Sparkasse Leichlingen',
    bic: 'WELADED1LEI',
    id: 1542,
  },
  {
    bankName: 'Sparkasse Leverkusen',
    bic: 'WELADEDLLEV',
    id: 1543,
  },
  {
    bankName: 'Stadt-Sparkasse Langenfeld',
    bic: 'WELADED1LAF',
    id: 1544,
  },
  {
    bankName: 'Volksbank Rhein-Wupper -alt-',
    bic: 'GENODED1RWL',
    id: 1545,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB375',
    id: 1546,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB378',
    id: 1547,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB377',
    id: 1548,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK375',
    id: 1549,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK378',
    id: 1550,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK377',
    id: 1551,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF380',
    id: 1552,
  },
  {
    bankName: 'DSL Bank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFDSL',
    id: 1553,
  },
  {
    bankName: 'KfW Ndl Bonn',
    bic: 'DTABDED1XXX',
    id: 1554,
  },
  {
    bankName: 'KfW Ausbildungsförderung Bonn',
    bic: 'DTABDED1AUS',
    id: 1555,
  },
  {
    bankName: 'VÖB-ZVD Processing',
    bic: 'VZVDDED1XXX',
    id: 1556,
  },
  {
    bankName: 'VÖB-ZVD Processing',
    bic: 'VZVDDED1001',
    id: 1557,
  },
  {
    bankName: 'VÖB-ZVD Processing',
    bic: 'VZVDDED1002',
    id: 1558,
  },
  {
    bankName: 'VÖB-ZVD Processing',
    bic: 'VZVDDED1003',
    id: 1559,
  },
  {
    bankName: 'VÖB-ZVD Processing',
    bic: 'VZVDDED1004',
    id: 1560,
  },
  {
    bankName: 'VÖB-ZVD Processing',
    bic: 'VZVDDED1005',
    id: 1561,
  },
  {
    bankName: 'VÖB-ZVD Processing',
    bic: 'VZVDDED1006',
    id: 1562,
  },
  {
    bankName: 'VÖB-ZVD Processing',
    bic: 'VZVDDED1007',
    id: 1563,
  },
  {
    bankName: 'VÖB-ZVD Processing',
    bic: 'VZVDDED1008',
    id: 1564,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM402',
    id: 1565,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1566,
  },
  {
    bankName: 'Sparkasse Bonn -alt-',
    bic: 'COLSDE33BON',
    id: 1567,
  },
  {
    bankName: 'Stadtsparkasse Bad Honnef',
    bic: 'WELADED1HON',
    id: 1568,
  },
  {
    bankName: 'Volksbank Köln Bonn',
    bic: 'GENODED1BRS',
    id: 1569,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB380',
    id: 1570,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB944',
    id: 1571,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB943',
    id: 1572,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB946',
    id: 1573,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB942',
    id: 1574,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB945',
    id: 1575,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK380',
    id: 1576,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK385',
    id: 1577,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK389',
    id: 1578,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK388',
    id: 1579,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK384',
    id: 1580,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK387',
    id: 1581,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDKP38',
    id: 1582,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP38',
    id: 1583,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBXXX',
    id: 1584,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB383',
    id: 1585,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF380',
    id: 1586,
  },
  {
    bankName: 'VR-Bank Bonn -alt-',
    bic: 'GENODED1HBO',
    id: 1587,
  },
  {
    bankName: 'Kreissparkasse Euskirchen',
    bic: 'WELADED1EUS',
    id: 1588,
  },
  {
    bankName: 'Volksbank Euskirchen',
    bic: 'GENODED1EVB',
    id: 1589,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1590,
  },
  {
    bankName: 'Sparkasse Gummersbach',
    bic: 'WELADED1GMB',
    id: 1591,
  },
  {
    bankName: 'Sparkasse der Homburgischen Gemeinden -alt-',
    bic: 'WELADED1WIE',
    id: 1592,
  },
  {
    bankName: 'Volksbank Oberberg',
    bic: 'GENODED1WIL',
    id: 1593,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB384',
    id: 1594,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB385',
    id: 1595,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB388',
    id: 1596,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB389',
    id: 1597,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB387',
    id: 1598,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW384',
    id: 1599,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW385',
    id: 1600,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW388',
    id: 1601,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW389',
    id: 1602,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW387',
    id: 1603,
  },
  {
    bankName: 'Steyler Bank',
    bic: 'GENODED1STB',
    id: 1604,
  },
  {
    bankName: 'Kreissparkasse Siegburg',
    bic: 'WELADED1SGB',
    id: 1605,
  },
  {
    bankName: 'Sparkasse Hennef',
    bic: 'WELADED1HEN',
    id: 1606,
  },
  {
    bankName: 'Aachener Bausparkasse',
    bic: 'AABSDE31XXX',
    id: 1607,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1608,
  },
  {
    bankName: 'Sparkasse Aachen',
    bic: 'AACSDE33XXX',
    id: 1609,
  },
  {
    bankName: 'Aachener Bank',
    bic: 'GENODED1AAC',
    id: 1610,
  },
  {
    bankName: 'Heinsberger Volksbank -alt-',
    bic: 'GENODED1HNB',
    id: 1611,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK390',
    id: 1612,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK391',
    id: 1613,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK398',
    id: 1614,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK392',
    id: 1615,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK394',
    id: 1616,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK397',
    id: 1617,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK399',
    id: 1618,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK393',
    id: 1619,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK400',
    id: 1620,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK401',
    id: 1621,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB390',
    id: 1622,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB398',
    id: 1623,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB391',
    id: 1624,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB948',
    id: 1625,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB397',
    id: 1626,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB394',
    id: 1627,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB392',
    id: 1628,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB393',
    id: 1629,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB399',
    id: 1630,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB947',
    id: 1631,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDKP09',
    id: 1632,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP09',
    id: 1633,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF390',
    id: 1634,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 98',
    bic: 'DRESDEFFI37',
    id: 1635,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 99',
    bic: 'DRESDEFFI38',
    id: 1636,
  },
  {
    bankName: 'Pax-Bank',
    bic: 'GENODED1PA1',
    id: 1637,
  },
  {
    bankName: 'Volksbank Aachen Süd',
    bic: 'GENODED1AAS',
    id: 1638,
  },
  {
    bankName: 'VR-Bank',
    bic: 'GENODED1WUR',
    id: 1639,
  },
  {
    bankName: 'Raiffeisen-Bank Eschweiler',
    bic: 'GENODED1RSC',
    id: 1640,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1641,
  },
  {
    bankName: 'Sparkasse Düren',
    bic: 'SDUEDE33XXX',
    id: 1642,
  },
  {
    bankName: 'Volksbank Düren -alt-',
    bic: 'GENODED1DUE',
    id: 1643,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB395',
    id: 1644,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB396',
    id: 1645,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK395',
    id: 1646,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK396',
    id: 1647,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF395',
    id: 1648,
  },
  {
    bankName: 'NRW.BANK',
    bic: 'NRWBDEDMMST',
    id: 1649,
  },
  {
    bankName: 'Münsterländische Bank Thie & Co',
    bic: 'MLBKDEH1MUE',
    id: 1650,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1651,
  },
  {
    bankName: 'Landesbank Hessen-Thüringen Girozentrale NL. Düsseldorf',
    bic: 'WELADE3MXXX',
    id: 1652,
  },
  {
    bankName: 'Sparkasse Münsterland Ost',
    bic: 'WELADED1MST',
    id: 1653,
  },
  {
    bankName: 'LBS Westdeutsche Landesbausparkasse',
    bic: 'LBSWDE31XXX',
    id: 1654,
  },
  {
    bankName: 'DZ BANK',
    bic: 'GENODEMSXXX',
    id: 1655,
  },
  {
    bankName: 'DKM Darlehnskasse Münster',
    bic: 'GENODEM1DKM',
    id: 1656,
  },
  {
    bankName: 'DZ HYP - Münster',
    bic: 'GENODEM1WLM',
    id: 1657,
  },
  {
    bankName: 'Sparda-Bank West',
    bic: 'GENODEF1S08',
    id: 1658,
  },
  {
    bankName: 'Volksbank Greven -alt-',
    bic: 'GENODEM1GRV',
    id: 1659,
  },
  {
    bankName: 'Volksbank Lette-Darup-Rorup',
    bic: 'GENODEM1CND',
    id: 1660,
  },
  {
    bankName: 'Volksbank im Hochsauerland',
    bic: 'GENODEM1MAS',
    id: 1661,
  },
  {
    bankName: 'Volksbank Schlangen',
    bic: 'GENODEM1SLN',
    id: 1662,
  },
  {
    bankName: 'Volksbank Medebach -alt-',
    bic: 'GENODEM1MDB',
    id: 1663,
  },
  {
    bankName: 'Volksbank -alt-',
    bic: 'GENODEM1SAE',
    id: 1664,
  },
  {
    bankName: 'Volksbank Schermbeck',
    bic: 'GENODEM1SMB',
    id: 1665,
  },
  {
    bankName: 'Volksbank Thülen -alt-',
    bic: 'GENODEM1BTH',
    id: 1666,
  },
  {
    bankName: 'Volksbank Baumberge',
    bic: 'GENODEM1BAU',
    id: 1667,
  },
  {
    bankName: 'Volksbank Wulfen -alt-',
    bic: 'GENODEM1DWU',
    id: 1668,
  },
  {
    bankName: 'Volksbank Senden',
    bic: 'GENODEM1SDN',
    id: 1669,
  },
  {
    bankName: 'Volksbank Amelsbüren -alt-',
    bic: 'GENODEM1MAB',
    id: 1670,
  },
  {
    bankName: 'Volksbank Ascheberg-Herbern',
    bic: 'GENODEM1CAN',
    id: 1671,
  },
  {
    bankName: 'Volksbank Erle -alt-',
    bic: 'GENODEM1ERR',
    id: 1672,
  },
  {
    bankName: 'Volksbank Seppenrade',
    bic: 'GENODEM1LSP',
    id: 1673,
  },
  {
    bankName: 'Volksbank in der Hohen Mark',
    bic: 'GENODEM1DLR',
    id: 1674,
  },
  {
    bankName: 'Volksbank Südkirchen-Capelle-Nordkirchen',
    bic: 'GENODEM1SCN',
    id: 1675,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB400',
    id: 1676,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB949',
    id: 1677,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB950',
    id: 1678,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB951',
    id: 1679,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB952',
    id: 1680,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB404',
    id: 1681,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B400',
    id: 1682,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B442',
    id: 1683,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B443',
    id: 1684,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B441',
    id: 1685,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B404',
    id: 1686,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B440',
    id: 1687,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDE3BP10',
    id: 1688,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP10',
    id: 1689,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF400',
    id: 1690,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFI68',
    id: 1691,
  },
  {
    bankName: 'PSD Bank Westfalen-Lippe',
    bic: 'GENODEF1P15',
    id: 1692,
  },
  {
    bankName: 'Verbundsparkasse Emsdetten Ochtrup',
    bic: 'WELADED1EMS',
    id: 1693,
  },
  {
    bankName: 'Sparkasse Gronau',
    bic: 'WELADED1GRO',
    id: 1694,
  },
  {
    bankName: 'Stadtsparkasse Lengerich',
    bic: 'WELADED1LEN',
    id: 1695,
  },
  {
    bankName: 'Sparkasse Westmünsterland',
    bic: 'WELADE3WXXX',
    id: 1696,
  },
  {
    bankName: 'Stadtsparkasse Stadtlohn',
    bic: 'WELADED1STL',
    id: 1697,
  },
  {
    bankName: 'Vereinigte Volksbank Münster -alt-',
    bic: 'GENODEM1MSC',
    id: 1698,
  },
  {
    bankName: 'Volksbank Nordmünsterland -alt-',
    bic: 'GENODEM1SEE',
    id: 1699,
  },
  {
    bankName: 'Volksbank Gronau-Ahaus',
    bic: 'GENODEM1GRN',
    id: 1700,
  },
  {
    bankName: 'Volksbank Laer-Horstmar-Leer -alt-',
    bic: 'GENODEM1LAE',
    id: 1701,
  },
  {
    bankName: 'Volksbank Nottuln',
    bic: 'GENODEM1CNO',
    id: 1702,
  },
  {
    bankName: 'Volksbank Südmünsterland-Mitte',
    bic: 'GENODEM1LHN',
    id: 1703,
  },
  {
    bankName: 'Volksbank Ochtrup-Laer',
    bic: 'GENODEM1OTR',
    id: 1704,
  },
  {
    bankName: 'Volksbank Gescher',
    bic: 'GENODEM1GE1',
    id: 1705,
  },
  {
    bankName: 'Volksbank Selm-Bork',
    bic: 'GENODEM1SEM',
    id: 1706,
  },
  {
    bankName: 'Volksbank Lengerich/Lotte -alt-',
    bic: 'GENODEM1LLE',
    id: 1707,
  },
  {
    bankName: 'Volksbank Buldern -alt-',
    bic: 'GENODEM1BUL',
    id: 1708,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1709,
  },
  {
    bankName: 'Stadtsparkasse Rheine',
    bic: 'WELADED1RHN',
    id: 1710,
  },
  {
    bankName: 'Kreissparkasse Steinfurt',
    bic: 'WELADED1STF',
    id: 1711,
  },
  {
    bankName: 'Sparkasse Steinfurt -alt-',
    bic: 'WELADED1IBB',
    id: 1712,
  },
  {
    bankName: 'Volksbank Westerkappeln-Saerbeck',
    bic: 'GENODEM1WKP',
    id: 1713,
  },
  {
    bankName: 'Volksbank Münsterland Nord',
    bic: 'GENODEM1IBB',
    id: 1714,
  },
  {
    bankName: 'Volksbank Hörstel -alt-',
    bic: 'GENODEM1HRL',
    id: 1715,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB403',
    id: 1716,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB405',
    id: 1717,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB406',
    id: 1718,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB401',
    id: 1719,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB407',
    id: 1720,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB408',
    id: 1721,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB409',
    id: 1722,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B403',
    id: 1723,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B406',
    id: 1724,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B405',
    id: 1725,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B401',
    id: 1726,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B409',
    id: 1727,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B408',
    id: 1728,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B407',
    id: 1729,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1730,
  },
  {
    bankName: 'ZTB der Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1731,
  },
  {
    bankName: 'Sparkasse Hamm',
    bic: 'WELADED1HAM',
    id: 1732,
  },
  {
    bankName: 'Stadtsparkasse Werne -alt-',
    bic: 'WELADED1WRN',
    id: 1733,
  },
  {
    bankName: 'Sparkasse Bergkamen-Bönen',
    bic: 'WELADED1BGK',
    id: 1734,
  },
  {
    bankName: 'Spar- und Darlehnskasse Bockum-Hövel',
    bic: 'GENODEM1HBH',
    id: 1735,
  },
  {
    bankName: 'BAG Bankaktiengesellschaft',
    bic: 'GENODEM1BAG',
    id: 1736,
  },
  {
    bankName: 'Volksbank Bönen',
    bic: 'GENODEM1BO1',
    id: 1737,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB410',
    id: 1738,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB412',
    id: 1739,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE410',
    id: 1740,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE412',
    id: 1741,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1742,
  },
  {
    bankName: 'Sparkasse Beckum-Wadersloh',
    bic: 'WELADED1BEK',
    id: 1743,
  },
  {
    bankName: 'Volksbank Beckum -alt-',
    bic: 'GENODEM1BEK',
    id: 1744,
  },
  {
    bankName: 'Volksbank Enniger-Ostenfelde-Westkirchen',
    bic: 'GENODEM1EOW',
    id: 1745,
  },
  {
    bankName: 'Volksbank Oelde-Ennigerloh-Neubeckum -alt-',
    bic: 'GENODEM1OEN',
    id: 1746,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODEM1AHL',
    id: 1747,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF413',
    id: 1748,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1749,
  },
  {
    bankName: 'Sparkasse SoestWerl',
    bic: 'WELADED1SOS',
    id: 1750,
  },
  {
    bankName: 'Sparkasse Werl -alt-',
    bic: 'WELADED1WRL',
    id: 1751,
  },
  {
    bankName: 'Volksbank Hellweg',
    bic: 'GENODEM1SOE',
    id: 1752,
  },
  {
    bankName: 'Volksbank Wickede (Ruhr)',
    bic: 'GENODEM1WRU',
    id: 1753,
  },
  {
    bankName: 'Sparkasse Lippstadt',
    bic: 'WELADED1LIP',
    id: 1754,
  },
  {
    bankName: 'Sparkasse Hochsauerland',
    bic: 'WELADED1HSL',
    id: 1755,
  },
  {
    bankName: 'Sparkasse Erwitte-Anröchte -alt-',
    bic: 'WELADED1ERW',
    id: 1756,
  },
  {
    bankName: 'Sparkasse Geseke',
    bic: 'WELADED1GES',
    id: 1757,
  },
  {
    bankName: 'Sparkasse Warstein-Rüthen -alt-',
    bic: 'WELADED1WAR',
    id: 1758,
  },
  {
    bankName: 'Volksbank Beckum-Lippstadt',
    bic: 'GENODEM1LPS',
    id: 1759,
  },
  {
    bankName: 'Volksbank Anröchte',
    bic: 'GENODEM1ANR',
    id: 1760,
  },
  {
    bankName: 'Volksbank Benninghausen -alt-',
    bic: 'GENODEM1LBH',
    id: 1761,
  },
  {
    bankName: 'Volksbank Brilon -alt-',
    bic: 'GENODEM1BRI',
    id: 1762,
  },
  {
    bankName: 'Volksbank Störmede-Hörste',
    bic: 'GENODEM1SGE',
    id: 1763,
  },
  {
    bankName: 'Volksbank Warstein-Belecke -alt-',
    bic: 'GENODEM1WST',
    id: 1764,
  },
  {
    bankName: 'Volksbank Hörste -alt-',
    bic: 'GENODEM1HOE',
    id: 1765,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB416',
    id: 1766,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB417',
    id: 1767,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB414',
    id: 1768,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB418',
    id: 1769,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B416',
    id: 1770,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B417',
    id: 1771,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B414',
    id: 1772,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B418',
    id: 1773,
  },
  {
    bankName: 'Isbank Fil Gelsenkirchen',
    bic: 'ISBKDEFXGEL',
    id: 1774,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1775,
  },
  {
    bankName: 'Sparkasse Gelsenkirchen',
    bic: 'WELADED1GEK',
    id: 1776,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB420',
    id: 1777,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB422',
    id: 1778,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB423',
    id: 1779,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB424',
    id: 1780,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB366',
    id: 1781,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB426',
    id: 1782,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB425',
    id: 1783,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB421',
    id: 1784,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE420',
    id: 1785,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE384',
    id: 1786,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE422',
    id: 1787,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE424',
    id: 1788,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE423',
    id: 1789,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE425',
    id: 1790,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE426',
    id: 1791,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE421',
    id: 1792,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF420',
    id: 1793,
  },
  {
    bankName: 'Volksbank Ruhr Mitte',
    bic: 'GENODEM1GBU',
    id: 1794,
  },
  {
    bankName: 'Stadtsparkasse Gladbeck',
    bic: 'WELADED1GLA',
    id: 1795,
  },
  {
    bankName: 'Sparkasse Bottrop',
    bic: 'WELADED1BOT',
    id: 1796,
  },
  {
    bankName: 'Vereinte Volksbank',
    bic: 'GENODEM1KIH',
    id: 1797,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1798,
  },
  {
    bankName: 'Sparkasse Vest Recklinghausen',
    bic: 'WELADED1REK',
    id: 1799,
  },
  {
    bankName: 'Stadtsparkasse Haltern am See',
    bic: 'WELADED1HAT',
    id: 1800,
  },
  {
    bankName: 'Volksbank Marl-Recklinghausen',
    bic: 'GENODEM1MRL',
    id: 1801,
  },
  {
    bankName: 'Volksbank Marl-Recklinghausen (Gf GAA)',
    bic: 'GENODEM1MRL',
    id: 1802,
  },
  {
    bankName: 'Volksbank Haltern -alt-',
    bic: 'GENODEM1HLT',
    id: 1803,
  },
  {
    bankName: 'Volksbank Waltrop -alt-',
    bic: 'GENODEM1WLW',
    id: 1804,
  },
  {
    bankName: 'Volksbank Dorsten -alt-',
    bic: 'GENODEM1DST',
    id: 1805,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF426',
    id: 1806,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1807,
  },
  {
    bankName: 'Stadtsparkasse Bocholt',
    bic: 'WELADED1BOH',
    id: 1808,
  },
  {
    bankName: 'Volksbank Bocholt',
    bic: 'GENODEM1BOH',
    id: 1809,
  },
  {
    bankName: 'Spar- und Darlehnskasse -alt-',
    bic: 'GENODEM1RKN',
    id: 1810,
  },
  {
    bankName: 'VR-Bank Westmünsterland',
    bic: 'GENODEM1BOB',
    id: 1811,
  },
  {
    bankName: 'Volksbank Gemen',
    bic: 'GENODEM1BOG',
    id: 1812,
  },
  {
    bankName: 'Volksbank Heiden',
    bic: 'GENODEM1HEI',
    id: 1813,
  },
  {
    bankName: 'Volksbank Rhede',
    bic: 'GENODEM1RHD',
    id: 1814,
  },
  {
    bankName: 'Volksbank Raesfeld und Erle',
    bic: 'GENODEM1RAE',
    id: 1815,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB428',
    id: 1816,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB429',
    id: 1817,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B428',
    id: 1818,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B429',
    id: 1819,
  },
  {
    bankName: 'Bundesbank eh Bochum',
    bic: 'MARKDEF1430',
    id: 1820,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1821,
  },
  {
    bankName: 'Sparkasse Bochum',
    bic: 'WELADED1BOC',
    id: 1822,
  },
  {
    bankName: 'Sparkasse Hattingen',
    bic: 'WELADED1HTG',
    id: 1823,
  },
  {
    bankName: 'Volksbank Bochum Witten',
    bic: 'GENODEM1BOC',
    id: 1824,
  },
  {
    bankName: 'GLS Gemeinschaftsbank',
    bic: 'GENODEM1GLS',
    id: 1825,
  },
  {
    bankName: 'GLS Gemeinschaftsbank (GAA)',
    bic: 'GENODEM1GLS',
    id: 1826,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB430',
    id: 1827,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB433',
    id: 1828,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB432',
    id: 1829,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB434',
    id: 1830,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB431',
    id: 1831,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE430',
    id: 1832,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE433',
    id: 1833,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE434',
    id: 1834,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE432',
    id: 1835,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE431',
    id: 1836,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF430',
    id: 1837,
  },
  {
    bankName: 'Herner Sparkasse',
    bic: 'WELADED1HRN',
    id: 1838,
  },
  {
    bankName: 'Bundesbank eh Dortmund -alt-',
    bic: 'MARKDEF1440',
    id: 1839,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 1840,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM808',
    id: 1841,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM808',
    id: 1842,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1843,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 1844,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 1845,
  },
  {
    bankName: 'Commerzbank, Gf Web-K',
    bic: 'COBADEFFXXX',
    id: 1846,
  },
  {
    bankName: 'Landesbank Hessen-Thüringen Girozentrale NL. Düsseldorf',
    bic: 'WELADE3DXXX',
    id: 1847,
  },
  {
    bankName: 'Sparkasse Dortmund',
    bic: 'DORTDE33XXX',
    id: 1848,
  },
  {
    bankName: 'Volksbank Dortmund-Nordwest',
    bic: 'GENODEM1DNW',
    id: 1849,
  },
  {
    bankName: 'Bank für Kirche und Diakonie - KD-Bank Gf Sonder-BLZ',
    bic: 'GENODED1KDD',
    id: 1850,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB440',
    id: 1851,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB441',
    id: 1852,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB442',
    id: 1853,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB444',
    id: 1854,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB443',
    id: 1855,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB447',
    id: 1856,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB448',
    id: 1857,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE440',
    id: 1858,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE441',
    id: 1859,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE442',
    id: 1860,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE443',
    id: 1861,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE444',
    id: 1862,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE447',
    id: 1863,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDE448',
    id: 1864,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF440',
    id: 1865,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 55',
    bic: 'DRESDEFF446',
    id: 1866,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf ZW 57',
    bic: 'DRESDEFF447',
    id: 1867,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
    bic: 'DRESDEFFI69',
    id: 1868,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI18',
    id: 1869,
  },
  {
    bankName: 'PSD Bank Dortmund -alt-',
    bic: 'GENODEF1P04',
    id: 1870,
  },
  {
    bankName: 'Sparkasse an der Lippe',
    bic: 'WELADED1LUN',
    id: 1871,
  },
  {
    bankName: 'Stadtsparkasse Schwerte',
    bic: 'WELADED1SWT',
    id: 1872,
  },
  {
    bankName: 'Dortmunder Volksbank',
    bic: 'GENODEM1DOR',
    id: 1873,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1874,
  },
  {
    bankName: 'Sparkasse UnnaKamen',
    bic: 'WELADED1UNN',
    id: 1875,
  },
  {
    bankName: 'Sparkasse Kamen -alt-',
    bic: 'WELADED1KAM',
    id: 1876,
  },
  {
    bankName: 'Sparkasse Fröndenberg -alt-',
    bic: 'WELADED1FRN',
    id: 1877,
  },
  {
    bankName: 'Volksbank Kamen-Werne -alt-',
    bic: 'GENODEM1KWK',
    id: 1878,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1879,
  },
  {
    bankName: 'Sparkasse der Stadt Iserlohn',
    bic: 'WELADED1ISL',
    id: 1880,
  },
  {
    bankName: 'Sparkasse Märkisches Sauerland Hemer-Menden',
    bic: 'WELADED1HEM',
    id: 1881,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW445',
    id: 1882,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW446',
    id: 1883,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW447',
    id: 1884,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW444',
    id: 1885,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW449',
    id: 1886,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW443',
    id: 1887,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW448',
    id: 1888,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB445',
    id: 1889,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB446',
    id: 1890,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB954',
    id: 1891,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB955',
    id: 1892,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB956',
    id: 1893,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB449',
    id: 1894,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB953',
    id: 1895,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF445',
    id: 1896,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFI70',
    id: 1897,
  },
  {
    bankName: 'Mendener Bank',
    bic: 'GENODEM1MEN',
    id: 1898,
  },
  {
    bankName: 'Volksbank in Südwestfalen',
    bic: 'GENODEM1NRD',
    id: 1899,
  },
  {
    bankName: 'Bundesbank eh Hagen',
    bic: 'MARKDEF1450',
    id: 1900,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1901,
  },
  {
    bankName: 'Sparkasse HagenHerdecke',
    bic: 'WELADE3HXXX',
    id: 1902,
  },
  {
    bankName: 'Stadtsparkasse Herdecke -alt-',
    bic: 'WELADED1HER',
    id: 1903,
  },
  {
    bankName: 'Märkische Bank',
    bic: 'GENODEM1HGN',
    id: 1904,
  },
  {
    bankName: 'Volksbank Hohenlimburg',
    bic: 'GENODEM1HLH',
    id: 1905,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW450',
    id: 1906,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW454',
    id: 1907,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW456',
    id: 1908,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW453',
    id: 1909,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW451',
    id: 1910,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB450',
    id: 1911,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB454',
    id: 1912,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB453',
    id: 1913,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB456',
    id: 1914,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB451',
    id: 1915,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF450',
    id: 1916,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1917,
  },
  {
    bankName: 'Sparkasse Witten',
    bic: 'WELADED1WTN',
    id: 1918,
  },
  {
    bankName: 'Stadtsparkasse Wetter -alt-',
    bic: 'WELADED1WET',
    id: 1919,
  },
  {
    bankName: 'Stadtsparkasse Sprockhövel -alt-',
    bic: 'SPSHDE31XXX',
    id: 1920,
  },
  {
    bankName: 'Volksbank Witten -alt-',
    bic: 'GENODEM1WTN',
    id: 1921,
  },
  {
    bankName: 'Spar- u Kreditbank d Bundes Fr ev Gemeinden',
    bic: 'GENODEM1BFG',
    id: 1922,
  },
  {
    bankName: 'Volksbank Sprockhövel',
    bic: 'GENODEM1SPO',
    id: 1923,
  },
  {
    bankName: 'Sparkasse an Ennepe und Ruhr',
    bic: 'WELADED1GEV',
    id: 1924,
  },
  {
    bankName: 'Sparkasse Ennepetal-Breckerfeld',
    bic: 'WELADED1ENE',
    id: 1925,
  },
  {
    bankName: 'Sparkasse Schwelm-Sprockhövel',
    bic: 'WELADED1SLM',
    id: 1926,
  },
  {
    bankName: 'Volksbank Altena -alt-',
    bic: 'GENODEM1ALA',
    id: 1927,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1928,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1929,
  },
  {
    bankName: 'Sparkasse Lüdenscheid',
    bic: 'WELADED1LSD',
    id: 1930,
  },
  {
    bankName: 'Vereinigte Sparkasse im Märkischen Kreis',
    bic: 'WELADED1PLB',
    id: 1931,
  },
  {
    bankName: 'Sparkasse Kierspe-Meinerzhagen',
    bic: 'WELADED1KMZ',
    id: 1932,
  },
  {
    bankName: 'Volksbank Lüdenscheid -alt-',
    bic: 'GENODEM1LHA',
    id: 1933,
  },
  {
    bankName: 'Volksbank Kierspe',
    bic: 'GENODEM1KIE',
    id: 1934,
  },
  {
    bankName: 'Volksbank Meinerzhagen -alt-',
    bic: 'GENODEM1MOM',
    id: 1935,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1936,
  },
  {
    bankName: 'Sparkasse Siegen',
    bic: 'WELADED1SIE',
    id: 1937,
  },
  {
    bankName: 'Sparkasse Burbach-Neunkirchen',
    bic: 'WELADED1BUB',
    id: 1938,
  },
  {
    bankName: 'Stadtsparkasse Freudenberg -alt-',
    bic: 'WELADED1FRE',
    id: 1939,
  },
  {
    bankName: 'Stadtsparkasse Hilchenbach -alt-',
    bic: 'WELADED1HIL',
    id: 1940,
  },
  {
    bankName: 'Stadtsparkasse Schmallenberg -alt-',
    bic: 'WELADED1SMB',
    id: 1941,
  },
  {
    bankName: 'Sparkasse Wittgenstein',
    bic: 'WELADED1BEB',
    id: 1942,
  },
  {
    bankName: 'Volksbank Siegerland -alt-',
    bic: 'GENODEM1SNS',
    id: 1943,
  },
  {
    bankName: 'VR-Bank Freudenberg-Niederfischbach',
    bic: 'GENODEM1FRF',
    id: 1944,
  },
  {
    bankName: 'Volksbank Bigge-Lenne',
    bic: 'GENODEM1SMA',
    id: 1945,
  },
  {
    bankName: 'Volksbank Wittgenstein',
    bic: 'GENODEM1BB1',
    id: 1946,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB460',
    id: 1947,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB962',
    id: 1948,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB963',
    id: 1949,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB463',
    id: 1950,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB469',
    id: 1951,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB466',
    id: 1952,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB461',
    id: 1953,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB516',
    id: 1954,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB470',
    id: 1955,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB967',
    id: 1956,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB471',
    id: 1957,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB966',
    id: 1958,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB964',
    id: 1959,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB465',
    id: 1960,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB462',
    id: 1961,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB965',
    id: 1962,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB464',
    id: 1963,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK460',
    id: 1964,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK461',
    id: 1965,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK466',
    id: 1966,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK463',
    id: 1967,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK516',
    id: 1968,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK468',
    id: 1969,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK467',
    id: 1970,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK470',
    id: 1971,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK465',
    id: 1972,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK474',
    id: 1973,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK471',
    id: 1974,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK469',
    id: 1975,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK462',
    id: 1976,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK473',
    id: 1977,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK464',
    id: 1978,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK475',
    id: 1979,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDK472',
    id: 1980,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF460',
    id: 1981,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1982,
  },
  {
    bankName: 'Sparkasse Olpe-Drolshagen-Wenden',
    bic: 'WELADED1OPE',
    id: 1983,
  },
  {
    bankName: 'Sparkasse Finnentrop -alt-',
    bic: 'WELADED1FTR',
    id: 1984,
  },
  {
    bankName: 'Sparkasse Attendorn-Lennestadt-Kirchhundem',
    bic: 'WELADED1ALK',
    id: 1985,
  },
  {
    bankName: 'Volksbank Olpe -alt-',
    bic: 'GENODEM1OLP',
    id: 1986,
  },
  {
    bankName: 'Volksbank Grevenbrück -alt',
    bic: 'GENODEM1GLG',
    id: 1987,
  },
  {
    bankName: 'Volksbank Olpe-Wenden-Drolshagen',
    bic: 'GENODEM1WDD',
    id: 1988,
  },
  {
    bankName: 'Volksbank Bigge-Lenne -alt-',
    bic: 'GENODEM1HUL',
    id: 1989,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1990,
  },
  {
    bankName: 'Sparkasse Mitten im Sauerland',
    bic: 'WELADED1MES',
    id: 1991,
  },
  {
    bankName: 'Sparkasse Bestwig -alt-',
    bic: 'WELADED1BST',
    id: 1992,
  },
  {
    bankName: 'Volksbank Sauerland -alt-',
    bic: 'GENODEM1SRL',
    id: 1993,
  },
  {
    bankName: 'Spar- und Darlehnskasse Oeventrop -alt-',
    bic: 'GENODEM1ANO',
    id: 1994,
  },
  {
    bankName: 'Volksbank Reiste-Eslohe -alt-',
    bic: 'GENODEM1RET',
    id: 1995,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 1996,
  },
  {
    bankName: 'Sparkasse Arnsberg-Sundern',
    bic: 'WELADED1ARN',
    id: 1997,
  },
  {
    bankName: 'Volksbank Sauerland',
    bic: 'GENODEM1NEH',
    id: 1998,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW466',
    id: 1999,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW467',
    id: 2000,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDW468',
    id: 2001,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB961',
    id: 2002,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB468',
    id: 2003,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB467',
    id: 2004,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDWP03',
    id: 2005,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP03',
    id: 2006,
  },
  {
    bankName: 'Bundesbank Dortmund',
    bic: 'MARKDEF1470',
    id: 2007,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2008,
  },
  {
    bankName: 'Sparkasse Paderborn -alt-',
    bic: 'WELADED1PBN',
    id: 2009,
  },
  {
    bankName: 'Sparkasse Höxter',
    bic: 'WELADED1HXB',
    id: 2010,
  },
  {
    bankName: 'Stadtsparkasse Delbrück',
    bic: 'WELADED1DEL',
    id: 2011,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MXXX',
    id: 2012,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MALT',
    id: 2013,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MDRI',
    id: 2014,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MLIP',
    id: 2015,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MBRA',
    id: 2016,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MBUR',
    id: 2017,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MDEL',
    id: 2018,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MHOV',
    id: 2019,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MHOX',
    id: 2020,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MWAR',
    id: 2021,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MSTE',
    id: 2022,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MSAL',
    id: 2023,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MDTM',
    id: 2024,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MHBM',
    id: 2025,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MLAG',
    id: 2026,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MLEM',
    id: 2027,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MOER',
    id: 2028,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MMND',
    id: 2029,
  },
  {
    bankName: 'VerbundVolksbank OWL',
    bic: 'DGPBDE3MEPW',
    id: 2030,
  },
  {
    bankName: 'Volksbank Elsen-Wewer-Borchen',
    bic: 'GENODEM1EWB',
    id: 2031,
  },
  {
    bankName: 'Bank für Kirche und Caritas',
    bic: 'GENODEM1BKC',
    id: 2032,
  },
  {
    bankName: 'Volksbank Haaren -alt-',
    bic: 'GENODEM1WNH',
    id: 2033,
  },
  {
    bankName: 'Volksbank Brilon-Büren-Salzkotten',
    bic: 'GENODEM1BUS',
    id: 2034,
  },
  {
    bankName: 'Volksbank Westenholz',
    bic: 'GENODEM1WDE',
    id: 2035,
  },
  {
    bankName: 'Volksbank Delbrück-Hövelhof',
    bic: 'GENODEM1DLB',
    id: 2036,
  },
  {
    bankName: 'Vereinigte Volksbank',
    bic: 'GENODEM1STM',
    id: 2037,
  },
  {
    bankName: 'Volksbank Wewelsburg-Ahden -alt-',
    bic: 'GENODEM1WAH',
    id: 2038,
  },
  {
    bankName: 'Volksbank Borgentreich -alt-',
    bic: 'GENODEM1BOT',
    id: 2039,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB472',
    id: 2040,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB473',
    id: 2041,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB474',
    id: 2042,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB475',
    id: 2043,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB958',
    id: 2044,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B472',
    id: 2045,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B475',
    id: 2046,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B473',
    id: 2047,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B451',
    id: 2048,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B474',
    id: 2049,
  },
  {
    bankName: 'Volksbank Warburger Land -alt-',
    bic: 'GENODEM1WBG',
    id: 2050,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2051,
  },
  {
    bankName: 'Sparkasse Paderborn-Detmold',
    bic: 'WELADE3LXXX',
    id: 2052,
  },
  {
    bankName: 'Stadtsparkasse Blomberg -alt-',
    bic: 'WELADED1BLO',
    id: 2053,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B476',
    id: 2054,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B450',
    id: 2055,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B477',
    id: 2056,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B478',
    id: 2057,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B453',
    id: 2058,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B452',
    id: 2059,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB476',
    id: 2060,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB477',
    id: 2061,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB957',
    id: 2062,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB959',
    id: 2063,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB960',
    id: 2064,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB478',
    id: 2065,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDE3BP04',
    id: 2066,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP04',
    id: 2067,
  },
  {
    bankName: 'Volksbank Ostlippe',
    bic: 'GENODEM1OLB',
    id: 2068,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2069,
  },
  {
    bankName: 'Commerzbank Zw 80',
    bic: 'COBADEFFXXX',
    id: 2070,
  },
  {
    bankName: 'Sparkasse Gütersloh-Rietberg',
    bic: 'WELADED1GTL',
    id: 2071,
  },
  {
    bankName: 'Sparkasse Rietberg -alt-',
    bic: 'WELADED1RTG',
    id: 2072,
  },
  {
    bankName: 'Stadtsparkasse Versmold',
    bic: 'WELADED1VSM',
    id: 2073,
  },
  {
    bankName: 'Kreissparkasse Wiedenbrück',
    bic: 'WELADED1WDB',
    id: 2074,
  },
  {
    bankName: 'Volksbank Bielefeld-Gütersloh',
    bic: 'GENODEM1GTL',
    id: 2075,
  },
  {
    bankName: 'VB Rheda-Wiedenbrück Zndl d VB Bielefeld-Gütersloh',
    bic: 'VBGTDE3MXXX',
    id: 2076,
  },
  {
    bankName: 'Volksbank im Ostmünsterland -alt-',
    bic: 'GENODEM1CLL',
    id: 2077,
  },
  {
    bankName: 'Volksbank Harsewinkel -alt-',
    bic: 'GENODEM1HWI',
    id: 2078,
  },
  {
    bankName: 'Volksbank Marienfeld -alt-',
    bic: 'GENODEM1MFD',
    id: 2079,
  },
  {
    bankName: 'Volksbank Rietberg',
    bic: 'GENODEM1RNE',
    id: 2080,
  },
  {
    bankName: 'Volksbank Versmold',
    bic: 'GENODEM1VMD',
    id: 2081,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF478',
    id: 2082,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1480',
    id: 2083,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM344',
    id: 2084,
  },
  {
    bankName: 'Hauck Aufhäuser Lampe Privatbank',
    bic: 'LAMPDEDDXXX',
    id: 2085,
  },
  {
    bankName: 'Bankverein Werther Zw Ndl der VerbundVolksbank OWL',
    bic: 'DGPBDE3MBVW',
    id: 2086,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2087,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 2088,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 2089,
  },
  {
    bankName: 'Sparkasse Bielefeld',
    bic: 'SPBIDE3BXXX',
    id: 2090,
  },
  {
    bankName: 'Kreissparkasse Halle',
    bic: 'WELADED1HAW',
    id: 2091,
  },
  {
    bankName: 'Bielefelder Volksbank -alt-',
    bic: 'GENODEM1BIE',
    id: 2092,
  },
  {
    bankName: 'Volksbank Halle/Westf',
    bic: 'GENODEM1HLW',
    id: 2093,
  },
  {
    bankName: 'Spar-u Darlehnskasse Schloß Holte-Stukenbrock -alt-',
    bic: 'GENODEM1SHS',
    id: 2094,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3BXXX',
    id: 2095,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B483',
    id: 2096,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B486',
    id: 2097,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBBIE',
    id: 2098,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB483',
    id: 2099,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB486',
    id: 2100,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB413',
    id: 2101,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB480',
    id: 2102,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB484',
    id: 2103,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB485',
    id: 2104,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB487',
    id: 2105,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB489',
    id: 2106,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB492',
    id: 2107,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB481',
    id: 2108,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB488',
    id: 2109,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B480',
    id: 2110,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B484',
    id: 2111,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B489',
    id: 2112,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B487',
    id: 2113,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B413',
    id: 2114,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B481',
    id: 2115,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B492',
    id: 2116,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF480',
    id: 2117,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI19',
    id: 2118,
  },
  {
    bankName: 'Sparkasse Lemgo',
    bic: 'WELADED1LEM',
    id: 2119,
  },
  {
    bankName: 'Volksbank Bad Salzuflen',
    bic: 'GENODEM1BSU',
    id: 2120,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2121,
  },
  {
    bankName: 'Sparkasse Minden-Lübbecke',
    bic: 'WELADED1MIN',
    id: 2122,
  },
  {
    bankName: 'Stadtsparkasse Rahden',
    bic: 'WELADED1RHD',
    id: 2123,
  },
  {
    bankName: 'Sparkasse Bad Oeynhausen-Porta Westfalica',
    bic: 'WELADED1OEH',
    id: 2124,
  },
  {
    bankName: 'Stadtsparkasse Porta Westfalica -alt-',
    bic: 'WELADED1PWF',
    id: 2125,
  },
  {
    bankName: 'Volksbank Mindener Land -alt-',
    bic: 'GENODEM1MPW',
    id: 2126,
  },
  {
    bankName: 'Volksbank Minden -alt-',
    bic: 'GENODEM1MND',
    id: 2127,
  },
  {
    bankName: 'Volksbank Stemweder Berg -alt-',
    bic: 'GENODEM1STR',
    id: 2128,
  },
  {
    bankName: 'Volksbank Eisbergen -alt-',
    bic: 'GENODEM1EPW',
    id: 2129,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB490',
    id: 2130,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB491',
    id: 2131,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB493',
    id: 2132,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB494',
    id: 2133,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB495',
    id: 2134,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B490',
    id: 2135,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B491',
    id: 2136,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B494',
    id: 2137,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B493',
    id: 2138,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE3B495',
    id: 2139,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF491',
    id: 2140,
  },
  {
    bankName: 'Volksbank Lübbecker Land',
    bic: 'GENODEM1LUB',
    id: 2141,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2142,
  },
  {
    bankName: 'Volksbank Schnathorst',
    bic: 'GENODEM1SNA',
    id: 2143,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2144,
  },
  {
    bankName: 'Sparkasse Herford',
    bic: 'WLAHDE44XXX',
    id: 2145,
  },
  {
    bankName: 'Volksbank Herford-Mindener Land',
    bic: 'GENODEM1HFV',
    id: 2146,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1500',
    id: 2147,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2148,
  },
  {
    bankName: 'AKBANK',
    bic: 'AKBKDEFFXXX',
    id: 2149,
  },
  {
    bankName: 'Aareal Bank',
    bic: 'AARBDE5W500',
    id: 2150,
  },
  {
    bankName: 'ING-DiBa',
    bic: 'INGDDEFFXXX',
    id: 2151,
  },
  {
    bankName: 'Degussa Bank',
    bic: 'DEGUDEFFXXX',
    id: 2152,
  },
  {
    bankName: 'Bank of America Europe, Frankfurt Branch',
    bic: 'BOFADEFXXXX',
    id: 2153,
  },
  {
    bankName: 'Bank of America Europe, Frankfurt Branch',
    bic: 'BOFADEFXVAM',
    id: 2154,
  },
  {
    bankName: 'ALTE LEIPZIGER Bauspar',
    bic: 'ALTEDEFAXXX',
    id: 2155,
  },
  {
    bankName: 'Western Union Intl. Bank Niederlassung Deutschland',
    bic: 'WUIDDEF1XXX',
    id: 2156,
  },
  {
    bankName: 'SÜDWESTBANK - BAWAG Niederlassung Deutschland',
    bic: 'SEZDDEF1XXX',
    id: 2157,
  },
  {
    bankName: 'ODDO BHF',
    bic: 'BHFBDEFF500',
    id: 2158,
  },
  {
    bankName: 'KfW Kreditanstalt für Wiederaufbau Frankfurt',
    bic: 'KFWIDEFFXXX',
    id: 2159,
  },
  {
    bankName: 'Landwirtschaftliche Rentenbank',
    bic: 'LAREDEFFXXX',
    id: 2160,
  },
  {
    bankName: 'Credit Europe Bank Ndl. Deutschland',
    bic: 'FBHLDEFFXXX',
    id: 2161,
  },
  {
    bankName: 'Intesa Sanpaolo Frankfurt',
    bic: 'BCITDEFFXXX',
    id: 2162,
  },
  {
    bankName: 'ING Bank',
    bic: 'INGBDEFFXXX',
    id: 2163,
  },
  {
    bankName: 'FIL Fondsbank',
    bic: 'FFBKDEFFKRN',
    id: 2164,
  },
  {
    bankName: 'FIL Fondsbank',
    bic: 'FFBKDEFFTHK',
    id: 2165,
  },
  {
    bankName: 'Hyundai Capital Bank Europe',
    bic: 'HCSEDEF1XXX',
    id: 2166,
  },
  {
    bankName: 'C24 Bank',
    bic: 'DEFFDEFFXXX',
    id: 2167,
  },
  {
    bankName: 'Opel Bank, Niederlassung Deutschland',
    bic: 'GMGGDE51XXX',
    id: 2168,
  },
  {
    bankName: 'PSA Bank Deutschland',
    bic: 'PSADDEF1XXX',
    id: 2169,
  },
  {
    bankName: 'PSA Bank Deutschland',
    bic: 'BPNDDE52XXX',
    id: 2170,
  },
  {
    bankName: 'HKB Bank Frankfurt',
    bic: 'HKBBDEF1FRA',
    id: 2171,
  },
  {
    bankName: 'BNP PARIBAS Securities Services',
    bic: 'PARBDEFFXXX',
    id: 2172,
  },
  {
    bankName: 'Deutsche WertpapierService Bank',
    bic: 'DWPBDEFFXXX',
    id: 2173,
  },
  {
    bankName: 'DenizBank (Wien) Zw Frankfurt',
    bic: 'ESBKDEFFXXX',
    id: 2174,
  },
  {
    bankName: 'Triodos Bank Deutschland',
    bic: 'TRODDEF1XXX',
    id: 2175,
  },
  {
    bankName: 'Santander Consumer Bank',
    bic: 'SCFBDE33XXX',
    id: 2176,
  },
  {
    bankName: 'Agricultural Bank of China, Frankfurt Branch',
    bic: 'ABOCDEFFXXX',
    id: 2177,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2178,
  },
  {
    bankName: 'Commerzbank, Filiale Frankfurt 2',
    bic: 'COBADEFFXXX',
    id: 2179,
  },
  {
    bankName: 'Commerzbank (IPC PARIS)',
    bic: 'COBADEFFPAR',
    id: 2180,
  },
  {
    bankName: 'Commerzbank (IPC VIENNA)',
    bic: 'COBADEFFVIE',
    id: 2181,
  },
  {
    bankName: 'Commerzbank (IPC MILANO)',
    bic: 'COBADEFFMIL',
    id: 2182,
  },
  {
    bankName: 'Commerzbank (IPC AMSTERDAM)',
    bic: 'COBADEFFAMS',
    id: 2183,
  },
  {
    bankName: 'Commerzbank (IPC BRUSSEL)',
    bic: 'COBADEFFBRU',
    id: 2184,
  },
  {
    bankName: 'Commerzbank (IPC MADRID)',
    bic: 'COBADEFFMAD',
    id: 2185,
  },
  {
    bankName: 'Commerzbank Gf BRS',
    bic: 'COBADEF1BRS',
    id: 2186,
  },
  {
    bankName: 'Commerzbank, MBP',
    bic: 'COBADEFFXXX',
    id: 2187,
  },
  {
    bankName: 'Commerzbank Gf ZRK',
    bic: 'COBADEFFXXX',
    id: 2188,
  },
  {
    bankName: 'Commerzbank GF-F48',
    bic: 'COBADEFFXXX',
    id: 2189,
  },
  {
    bankName: 'Commerzbank, CC SP',
    bic: 'COBADEFFXXX',
    id: 2190,
  },
  {
    bankName: 'Commerzbank Center Dresdner Bank Frankfurt',
    bic: 'COBADEFFXXX',
    id: 2191,
  },
  {
    bankName: 'Commerzbank Service - BZ Frankfurt',
    bic: 'COBADEFFXXX',
    id: 2192,
  },
  {
    bankName: 'Commerzbank Gf 460',
    bic: 'COBADEFFXXX',
    id: 2193,
  },
  {
    bankName: 'Commerzbank Gf 461',
    bic: 'COBADEFFXXX',
    id: 2194,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 2195,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 2196,
  },
  {
    bankName: 'Commerzbank Gf ZCM',
    bic: 'COBADEFFXXX',
    id: 2197,
  },
  {
    bankName: 'Commerzbank, GF Web-K CMTS2',
    bic: 'COBADEFFXXX',
    id: 2198,
  },
  {
    bankName: 'Commerzbank, Gf Web-K',
    bic: 'COBADEFFXXX',
    id: 2199,
  },
  {
    bankName: 'Commerzbank, GF Web-K CMTS',
    bic: 'COBADEFFXXX',
    id: 2200,
  },
  {
    bankName: 'Commerzbank, Gf Web-K CMTS3',
    bic: 'COBADEFFXXX',
    id: 2201,
  },
  {
    bankName: 'Commerzbank, INT 1',
    bic: 'COBADEFFXXX',
    id: 2202,
  },
  {
    bankName: 'Commerzbank INT',
    bic: 'COBADEFFXXX',
    id: 2203,
  },
  {
    bankName: 'Commerzbank Zw 425 - keine Auslandsbanken',
    bic: 'COBADEFFXXX',
    id: 2204,
  },
  {
    bankName: 'Commerzbank Vermögensverwaltung',
    bic: 'COBADEFFXXX',
    id: 2205,
  },
  {
    bankName: 'Commerzbank Service - BZ',
    bic: 'COBADEFFXXX',
    id: 2206,
  },
  {
    bankName: 'Landesbank Hessen-Thür Girozentrale',
    bic: 'HELADEFFXXX',
    id: 2207,
  },
  {
    bankName: 'Frankfurter Sparkasse',
    bic: 'HELADEF1822',
    id: 2208,
  },
  {
    bankName: 'Frankfurter Sparkasse GF 1822direkt',
    bic: 'HELADEF1822',
    id: 2209,
  },
  {
    bankName: 'DekaBank Frankfurt',
    bic: 'DGZFDEFFXXX',
    id: 2210,
  },
  {
    bankName: 'DZ Bank',
    bic: 'GENODE55XXX',
    id: 2211,
  },
  {
    bankName: 'DZ BANK',
    bic: 'GENODEFFXXX',
    id: 2212,
  },
  {
    bankName: 'DZ BANK GF DZH',
    bic: 'GENODEF1VK1',
    id: 2213,
  },
  {
    bankName: 'DZ BANK GF ODA',
    bic: 'GENODEF1VK2',
    id: 2214,
  },
  {
    bankName: 'DZ BANK für Bausparkasse Schwäbisch Hall',
    bic: 'GENODEF1VK3',
    id: 2215,
  },
  {
    bankName: 'DZ BANK für Bausparkasse Schwäbisch Hall',
    bic: 'GENODEF1VK4',
    id: 2216,
  },
  {
    bankName: 'DZ BANK GF TSG',
    bic: 'GENODEF1VK6',
    id: 2217,
  },
  {
    bankName: 'DZ BANK GF BMT',
    bic: 'GENODEF1VK7',
    id: 2218,
  },
  {
    bankName: 'DZ BANK GF BMT',
    bic: 'GENODEF1VK8',
    id: 2219,
  },
  {
    bankName: 'DZ BANK GF VRF',
    bic: 'GENODEF1VK9',
    id: 2220,
  },
  {
    bankName: 'DZ BANK GF BSH',
    bic: 'GENODEF1V20',
    id: 2221,
  },
  {
    bankName: 'DZ BANK GF BSH',
    bic: 'GENODEF1V21',
    id: 2222,
  },
  {
    bankName: 'DZ BANK GF GRX',
    bic: 'GENODEF1V22',
    id: 2223,
  },
  {
    bankName: 'DZ BANK GF GRX',
    bic: 'GENODEF1V23',
    id: 2224,
  },
  {
    bankName: 'DZ BANK GF DEV',
    bic: 'GENODEF1V24',
    id: 2225,
  },
  {
    bankName: 'DZ BANK GF BSH',
    bic: 'GENODEF1V25',
    id: 2226,
  },
  {
    bankName: 'DZ BANK, Deutsche Zentral-Genossenschaftsbank',
    bic: 'GENODEFFBRO',
    id: 2227,
  },
  {
    bankName: 'Raiffeisenbank im Hochtaunus',
    bic: 'GENODE51OBU',
    id: 2228,
  },
  {
    bankName: 'VR Bank Alzey-Land-Schwabenheim',
    bic: 'GENODE51ABO',
    id: 2229,
  },
  {
    bankName: 'Volksbank Grebenhain',
    bic: 'GENODE51GRC',
    id: 2230,
  },
  {
    bankName: 'Volksbank Egelsbach -alt-',
    bic: 'GENODE51EGE',
    id: 2231,
  },
  {
    bankName: 'Raiffeisenkasse Erbes-Büdesheim und Umgebung',
    bic: 'GENODE51ERB',
    id: 2232,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODE51GWB',
    id: 2233,
  },
  {
    bankName: 'Hüttenberger Bank',
    bic: 'GENODE51HUT',
    id: 2234,
  },
  {
    bankName: 'Raiffeisenbank Kirtorf',
    bic: 'GENODE51KIF',
    id: 2235,
  },
  {
    bankName: 'Raiffeisenbank Bad Homburg Ndl d FrankfurterVB',
    bic: 'GENODE51BH1',
    id: 2236,
  },
  {
    bankName: 'Raiffeisen Volksbank',
    bic: 'GENODE51SWB',
    id: 2237,
  },
  {
    bankName: 'Volksbank Wißmar',
    bic: 'GENODE51WWI',
    id: 2238,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFFXXX',
    id: 2239,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF500',
    id: 2240,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF542',
    id: 2241,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF541',
    id: 2242,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF504',
    id: 2243,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF503',
    id: 2244,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF540',
    id: 2245,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFFSIP',
    id: 2246,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBFRA',
    id: 2247,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB500',
    id: 2248,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB503',
    id: 2249,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB535',
    id: 2250,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB536',
    id: 2251,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB504',
    id: 2252,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP25',
    id: 2253,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEFFS25',
    id: 2254,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBEW1',
    id: 2255,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBEW2',
    id: 2256,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBEW3',
    id: 2257,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBEW4',
    id: 2258,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBEW5',
    id: 2259,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF502',
    id: 2260,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF543',
    id: 2261,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB502',
    id: 2262,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB537',
    id: 2263,
  },
  {
    bankName: 'Deutsche Bank Europe',
    bic: 'DEUTDE5XXXX',
    id: 2264,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFFXXX',
    id: 2265,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 15',
    bic: 'DRESDEFFI39',
    id: 2266,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 25',
    bic: 'DRESDEFFXXX',
    id: 2267,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 35',
    bic: 'DRESDEFFI40',
    id: 2268,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 55',
    bic: 'DRESDEFF516',
    id: 2269,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf ZW 57',
    bic: 'DRESDEFF522',
    id: 2270,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf DrKW',
    bic: 'DRESDEFFXXX',
    id: 2271,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf DrKWSL',
    bic: 'DRESDEFFLDG',
    id: 2272,
  },
  {
    bankName: 'Commerzbank, GF Wüstenrot BSPK',
    bic: 'DRESDEFFBSP',
    id: 2273,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ESOP',
    bic: 'DRESDEFFXXX',
    id: 2274,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Bs 80',
    bic: 'DRESDEFFI41',
    id: 2275,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf AVB',
    bic: 'DRESDEFFAVB',
    id: 2276,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK 3',
    bic: 'DRESDEFFI49',
    id: 2277,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
    bic: 'DRESDEFFJ08',
    id: 2278,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
    bic: 'DRESDEFFJ09',
    id: 2279,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
    bic: 'DRESDEFFJ10',
    id: 2280,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 7',
    bic: 'DRESDEFFJ11',
    id: 2281,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Finance and Controlling',
    bic: 'DRESDEFFFCO',
    id: 2282,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 99',
    bic: 'DRESDEFFI42',
    id: 2283,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Private Banking Inland',
    bic: 'DRESDEFF500',
    id: 2284,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF502',
    id: 2285,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank in Frankfurt MBP',
    bic: 'DRESDEFFMBP',
    id: 2286,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI01',
    id: 2287,
  },
  {
    bankName: 'Sparda-Bank Hessen',
    bic: 'GENODEF1S12',
    id: 2288,
  },
  {
    bankName: 'PSD Bank Hessen-Thüringen',
    bic: 'GENODEF1P06',
    id: 2289,
  },
  {
    bankName: 'Spar- u Kreditbank ev-freikirchl Gemeinden',
    bic: 'GENODE51BH2',
    id: 2290,
  },
  {
    bankName: 'Volksbank Main-Taunus -alt-',
    bic: 'GENODE51KEL',
    id: 2291,
  },
  {
    bankName: 'Volksbank Usinger Land Ndl d Frankfurter VB',
    bic: 'GENODE51USI',
    id: 2292,
  },
  {
    bankName: 'Rüsselsheimer Volksbank',
    bic: 'GENODE51RUS',
    id: 2293,
  },
  {
    bankName: 'Rüsselsheimer Volksbank GAA',
    bic: 'GENODE51GAA',
    id: 2294,
  },
  {
    bankName: 'Volksbank Kelsterbach Ndl d Frankfurter VB',
    bic: 'GENODE51KBH',
    id: 2295,
  },
  {
    bankName: 'Industrial and Commercial Bank of China',
    bic: 'ICBKDEFFXXX',
    id: 2296,
  },
  {
    bankName: 'DVB Bank',
    bic: 'DVKBDEFFXXX',
    id: 2297,
  },
  {
    bankName: 'AKA Ausfuhrkredit GmbH',
    bic: 'AUSKDEFFXXX',
    id: 2298,
  },
  {
    bankName: 'NATIXIS Zweigniederlassung Deutschland',
    bic: 'NATXDEFFXXX',
    id: 2299,
  },
  {
    bankName: 'Standard Chartered Bank',
    bic: 'SCBLDEFXXXX',
    id: 2300,
  },
  {
    bankName: 'Frankfurter Bankgesellschaft (Deutschland)',
    bic: 'FBGADEF1XXX',
    id: 2301,
  },
  {
    bankName: 'J.P. Morgan',
    bic: 'CHASDEFXXXX',
    id: 2302,
  },
  {
    bankName: 'J.P. Morgan, Internal Reference',
    bic: 'CHASDEFXVR1',
    id: 2303,
  },
  {
    bankName: 'J.P. Morgan',
    bic: 'JPMGDEFFXXX',
    id: 2304,
  },
  {
    bankName: 'Bank of America N.A. Military Bank',
    bic: 'MNBIDEF1XXX',
    id: 2305,
  },
  {
    bankName: 'Stifel Europe Bank',
    bic: 'MAIFDEFFXXX',
    id: 2306,
  },
  {
    bankName: 'ICICI Bank UK, Germany Branch',
    bic: 'ICICDEFFXXX',
    id: 2307,
  },
  {
    bankName: 'Bethmann Bank',
    bic: 'DELBDE33XXX',
    id: 2308,
  },
  {
    bankName: 'Credit Suisse (Deutschland)',
    bic: 'CRESDE55XXX',
    id: 2309,
  },
  {
    bankName: 'Bank of Communications Frankfurt branch',
    bic: 'COMMDEFFXXX',
    id: 2310,
  },
  {
    bankName: 'AION Bank German Branch',
    bic: 'BMPBDEF2XXX',
    id: 2311,
  },
  {
    bankName: 'VakifBank International Wien Zndl Deutschland',
    bic: 'TVBADEFFXXX',
    id: 2312,
  },
  {
    bankName: 'VTB Bank (Europe) Zndl Frankfurt am Main (Direktbank)',
    bic: 'DOBADEF1XXX',
    id: 2313,
  },
  {
    bankName: 'PKO Bank Polski Niederlassung Deutschland',
    bic: 'BPKODEFFXXX',
    id: 2314,
  },
  {
    bankName: 'National Bank of Pakistan Zndl Frankfurt',
    bic: 'NBPADEFFXXX',
    id: 2315,
  },
  {
    bankName: 'Deutsche Oppenheim Family Office (ehem. Sal. Oppenheim)',
    bic: 'SOPPDEFFXXX',
    id: 2316,
  },
  {
    bankName: 'Merck Finck A Quintet Private Bank',
    bic: 'MEFIDEMM501',
    id: 2317,
  },
  {
    bankName: 'UBS Europe',
    bic: 'UBSWDEFFXXX',
    id: 2318,
  },
  {
    bankName: 'Vietnam Joint Stock Commercial Bank for Industry and Trade',
    bic: 'ICBVDEFFXXX',
    id: 2319,
  },
  {
    bankName: 'Frankfurter Volksbank',
    bic: 'FFVBDEFFXXX',
    id: 2320,
  },
  {
    bankName: 'Volksbank Höchst a.M., ZwNdl. der Frankfurter VB -alt-',
    bic: 'GENODE51FHC',
    id: 2321,
  },
  {
    bankName: 'Volksbank Griesheim -alt-',
    bic: 'GENODE51FGH',
    id: 2322,
  },
  {
    bankName: 'Rabobank International Frankfurt Branch',
    bic: 'RABODEFFTAR',
    id: 2323,
  },
  {
    bankName: 'RaboDirect',
    bic: 'RABODEFFDIR',
    id: 2324,
  },
  {
    bankName: 'Rabobank International Frankfurt Branch',
    bic: 'RABODEFFXXX',
    id: 2325,
  },
  {
    bankName: 'ProCredit Bank, Frankfurt am Main',
    bic: 'PRCBDEFFXXX',
    id: 2326,
  },
  {
    bankName: 'Citibank Europe Germany Branch',
    bic: 'CITIDEFFXXX',
    id: 2327,
  },
  {
    bankName: 'UBS Europe',
    bic: 'SMHBDEFFXXX',
    id: 2328,
  },
  {
    bankName: 'Kommunalkredit Austria, Zweigstelle Deutschland',
    bic: 'INVODEF2XXX',
    id: 2329,
  },
  {
    bankName: 'WOORI BANK EUROPE',
    bic: 'HVBKDEFFXXX',
    id: 2330,
  },
  {
    bankName: 'Lloyds Bank',
    bic: 'BOFSDEF1XXX',
    id: 2331,
  },
  {
    bankName: 'CAIXABANK Zweigniederlassung Deutschland',
    bic: 'CAIXDEFFXXX',
    id: 2332,
  },
  {
    bankName: 'Hauck Aufhäuser Lampe Privatbank',
    bic: 'HAUKDEFFXXX',
    id: 2333,
  },
  {
    bankName: 'Arab Banking Corporation Zweigniederlassung Frankfurt',
    bic: 'ABCADEFFXXX',
    id: 2334,
  },
  {
    bankName: 'Isbank',
    bic: 'ISBKDEFXXXX',
    id: 2335,
  },
  {
    bankName: 'Metzler, B. - seel Sohn & Co',
    bic: 'METZDEFFXXX',
    id: 2336,
  },
  {
    bankName: 'Ikano Bank',
    bic: 'PLFGDE5AXXX',
    id: 2337,
  },
  {
    bankName: 'Ikano Bank',
    bic: 'PLFGDE5AIKB',
    id: 2338,
  },
  {
    bankName: 'KT Bank',
    bic: 'KTAGDEFFXXX',
    id: 2339,
  },
  {
    bankName: 'Deutsche Leasing Finance',
    bic: 'DLFGDE51XXX',
    id: 2340,
  },
  {
    bankName: 'Barclays Bank Ireland Frankfurt Branch',
    bic: 'BARCDEFFXXX',
    id: 2341,
  },
  {
    bankName: 'China Construction Bank Ndl Frankfurt',
    bic: 'PCBCDEFFXXX',
    id: 2342,
  },
  {
    bankName: 'VTB Bank (Europe)',
    bic: 'OWHBDEFFXXX',
    id: 2343,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM430',
    id: 2344,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM430',
    id: 2345,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM430',
    id: 2346,
  },
  {
    bankName: 'Banco Santander Filiale Frankfurt',
    bic: 'BSCHDEFFXXX',
    id: 2347,
  },
  {
    bankName: 'Attijariwafa bank Europa ZNdl. Frankfurt',
    bic: 'BCMADEFFXXX',
    id: 2348,
  },
  {
    bankName: 'Pictet & Cie (Europe) Ndl Deutschland',
    bic: 'PICTDEFFXXX',
    id: 2349,
  },
  {
    bankName: 'ABN AMRO Bank, Frankfurt Branch',
    bic: 'FTSBDEFAXXX',
    id: 2350,
  },
  {
    bankName: 'State Bank of India',
    bic: 'SBINDEFFXXX',
    id: 2351,
  },
  {
    bankName: 'Raisin Bank',
    bic: 'MHBFDEFFXXX',
    id: 2352,
  },
  {
    bankName: 'Raisin Bank',
    bic: 'RAISDEFFXXX',
    id: 2353,
  },
  {
    bankName: 'The Bank of New York Mellon',
    bic: 'IRVTDEFXXXX',
    id: 2354,
  },
  {
    bankName: 'BANQUE CHAABI DU MAROC Agentur Frankfurt Ndl. Deutschland',
    bic: 'BCDMDEF1XXX',
    id: 2355,
  },
  {
    bankName: 'Bank Sepah-Iran',
    bic: 'SEPBDEFFXXX',
    id: 2356,
  },
  {
    bankName: 'The Bank of New York Mellon NL Frankfurt',
    bic: 'BNYMDEF1XXX',
    id: 2357,
  },
  {
    bankName: 'German branch of Unifiedpost Payments',
    bic: 'PANXDEF2XXX',
    id: 2358,
  },
  {
    bankName: 'Bundesbank Zentrale',
    bic: 'MARKDEFFXXX',
    id: 2359,
  },
  {
    bankName: 'SMBC Bank EU',
    bic: 'SMBCDEFFXXX',
    id: 2360,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM467',
    id: 2361,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM467',
    id: 2362,
  },
  {
    bankName: 'FIDOR Bank Zndl Frankfurt am Main',
    bic: 'FDDODEMMXXX',
    id: 2363,
  },
  {
    bankName: 'Cronbank',
    bic: 'GENODE51CRO',
    id: 2364,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2365,
  },
  {
    bankName: 'Städtische Sparkasse Offenbach a.M.',
    bic: 'HELADEF1OFF',
    id: 2366,
  },
  {
    bankName: 'Raiffeisenbank Offenbach/M.-Bieber',
    bic: 'GENODE51OF2',
    id: 2367,
  },
  {
    bankName: 'Vereinigte Volksbank Maingau -alt-',
    bic: 'GENODE51OBH',
    id: 2368,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF505',
    id: 2369,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF507',
    id: 2370,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF549',
    id: 2371,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF546',
    id: 2372,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF548',
    id: 2373,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF545',
    id: 2374,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF544',
    id: 2375,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF547',
    id: 2376,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF550',
    id: 2377,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB505',
    id: 2378,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB538',
    id: 2379,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB539',
    id: 2380,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB529',
    id: 2381,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB528',
    id: 2382,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB527',
    id: 2383,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB507',
    id: 2384,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB526',
    id: 2385,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB525',
    id: 2386,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF505',
    id: 2387,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFJ12',
    id: 2388,
  },
  {
    bankName: 'Offenbacher Volksbank -alt-',
    bic: 'GENODE51OF1',
    id: 2389,
  },
  {
    bankName: 'Volksbank Dreieich',
    bic: 'GENODE51DRE',
    id: 2390,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2391,
  },
  {
    bankName: 'SPARKASSE HANAU',
    bic: 'HELADEF1HAN',
    id: 2392,
  },
  {
    bankName: 'Sparkasse Langen-Seligenstadt',
    bic: 'HELADEF1SLS',
    id: 2393,
  },
  {
    bankName: 'VR Bank Main-Kinzig-Büdingen',
    bic: 'GENODEF1LSR',
    id: 2394,
  },
  {
    bankName: 'Volksbank Heldenbergen Ndl d Frankfurter VB',
    bic: 'GENODE51NIH',
    id: 2395,
  },
  {
    bankName: 'Raiffeisenbank Bruchköbel -alt-',
    bic: 'GENODEF1BKO',
    id: 2396,
  },
  {
    bankName: 'Raiffeisenbank Maintal Ndl d Frankfurter VB',
    bic: 'RBMFDEF1XXX',
    id: 2397,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1RDB',
    id: 2398,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF506',
    id: 2399,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB506',
    id: 2400,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF506',
    id: 2401,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFJ13',
    id: 2402,
  },
  {
    bankName: 'Volksbank Raiffeisenbank Hanau Ndl d Frankf VB',
    bic: 'GENODEF1HUV',
    id: 2403,
  },
  {
    bankName: 'DZB BANK',
    bic: 'DZBMDEF1XXX',
    id: 2404,
  },
  {
    bankName: 'Volksbank Seligenstadt',
    bic: 'GENODE51SEL',
    id: 2405,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2406,
  },
  {
    bankName: 'Kreissparkasse Gelnhausen',
    bic: 'HELADEF1GEL',
    id: 2407,
  },
  {
    bankName: 'Volksbank -alt-',
    bic: 'GENODE51BUE',
    id: 2408,
  },
  {
    bankName: 'Raiffeisenbank Vogelsberg -alt-',
    bic: 'GENODEF1BIR',
    id: 2409,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF524',
    id: 2410,
  },
  {
    bankName: 'VR Bank Bad Orb-Gelnhausen',
    bic: 'GENODE51GEL',
    id: 2411,
  },
  {
    bankName: 'Birsteiner Volksbank -alt-',
    bic: 'GENODE51BIV',
    id: 2412,
  },
  {
    bankName: 'VR Bank Wächtersbach/Bad Soden-Salmünster -alt',
    bic: 'GENODE51WBH',
    id: 2413,
  },
  {
    bankName: 'Deutsche Bausparkasse Badenia (ehem. DBS Bausparkasse)',
    bic: 'BBSPDE6KXXX',
    id: 2414,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM487',
    id: 2415,
  },
  {
    bankName: 'MCE Bank',
    bic: 'MKGMDE51XXX',
    id: 2416,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2417,
  },
  {
    bankName: 'Landesbank Hessen-Thür Girozentrale',
    bic: 'HELADEFF508',
    id: 2418,
  },
  {
    bankName: 'Stadt- und Kreis-Sparkasse Darmstadt',
    bic: 'HELADEF1DAS',
    id: 2419,
  },
  {
    bankName: 'Sparkasse Odenwaldkreis',
    bic: 'HELADEF1ERB',
    id: 2420,
  },
  {
    bankName: 'Kreissparkasse Groß-Gerau',
    bic: 'HELADEF1GRG',
    id: 2421,
  },
  {
    bankName: 'Sparkasse Dieburg',
    bic: 'HELADEF1DIE',
    id: 2422,
  },
  {
    bankName: 'Spar- und Darlehnskasse Zell -alt-',
    bic: 'GENODE51BKZ',
    id: 2423,
  },
  {
    bankName: 'Raiffeisenbank Nördliche Bergstraße',
    bic: 'GENODE51ABH',
    id: 2424,
  },
  {
    bankName: 'Volksbank Gräfenhausen -alt-',
    bic: 'GENODE51WGH',
    id: 2425,
  },
  {
    bankName: 'Vereinigte Volksbank Griesheim-Weiterstadt -alt-',
    bic: 'GENODE51GRI',
    id: 2426,
  },
  {
    bankName: 'Volksbank Gersprenztal-Otzberg',
    bic: 'GENODE51REI',
    id: 2427,
  },
  {
    bankName: 'Raiffeisenbank Schaafheim',
    bic: 'GENODE51SHM',
    id: 2428,
  },
  {
    bankName: 'Volksbank Mainspitze',
    bic: 'GENODE51GIN',
    id: 2429,
  },
  {
    bankName: 'Vereinigte Volksbank Raiffeisenbank',
    bic: 'GENODE51MIC',
    id: 2430,
  },
  {
    bankName: 'VB Mörfelden-Walldorf Ndl d Frankfurter VB',
    bic: 'GENODE51MWA',
    id: 2431,
  },
  {
    bankName: 'Volksbank -alt-',
    bic: 'GENODE51EPT',
    id: 2432,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF508',
    id: 2433,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF551',
    id: 2434,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF552',
    id: 2435,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB508',
    id: 2436,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB554',
    id: 2437,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB555',
    id: 2438,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP26',
    id: 2439,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEFFS26',
    id: 2440,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF508',
    id: 2441,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFJ14',
    id: 2442,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
    bic: 'DRESDEFFJ15',
    id: 2443,
  },
  {
    bankName: 'Volksbank Darmstadt - Südhessen',
    bic: 'GENODEF1VBD',
    id: 2444,
  },
  {
    bankName: 'Sparkasse Bensheim',
    bic: 'HELADEF1BEN',
    id: 2445,
  },
  {
    bankName: 'Sparkasse Starkenburg',
    bic: 'HELADEF1HEP',
    id: 2446,
  },
  {
    bankName: 'Raiffeisenbank Ried',
    bic: 'GENODE51RBU',
    id: 2447,
  },
  {
    bankName: 'Raiffeisenbank Groß-Rohrheim',
    bic: 'GENODE51GRM',
    id: 2448,
  },
  {
    bankName: 'Volksbank Weschnitztal',
    bic: 'GENODE51FHO',
    id: 2449,
  },
  {
    bankName: 'Volksbank Überwald-Gorxheimertal',
    bic: 'GENODE51ABT',
    id: 2450,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF509',
    id: 2451,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF519',
    id: 2452,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB509',
    id: 2453,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB519',
    id: 2454,
  },
  {
    bankName: 'Aareal Bank',
    bic: 'AARBDE5WXXX',
    id: 2455,
  },
  {
    bankName: 'BHF-BANK',
    bic: 'BHFBDEFF510',
    id: 2456,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM478',
    id: 2457,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2458,
  },
  {
    bankName: 'Nassauische Sparkasse',
    bic: 'NASSDE55XXX',
    id: 2459,
  },
  {
    bankName: 'S Broker Wiesbaden',
    bic: 'PULSDE5WXXX',
    id: 2460,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF510',
    id: 2461,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF512',
    id: 2462,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF514',
    id: 2463,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB510',
    id: 2464,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB512',
    id: 2465,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB514',
    id: 2466,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF510',
    id: 2467,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFJ16',
    id: 2468,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK2',
    bic: 'DRESDEFFJ17',
    id: 2469,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI20',
    id: 2470,
  },
  {
    bankName: 'Wiesbadener Volksbank',
    bic: 'WIBADE5WXXX',
    id: 2471,
  },
  {
    bankName: 'Rheingauer Volksbank',
    bic: 'GENODE51RGG',
    id: 2472,
  },
  {
    bankName: 'Rheingauer Volksbank (Gf GAA)',
    bic: 'GENODE51RGG',
    id: 2473,
  },
  {
    bankName: 'vr bank Untertaunus -alt-',
    bic: 'VRBUDE51XXX',
    id: 2474,
  },
  {
    bankName: 'vr bank Untertaunus -alt-',
    bic: 'VRBUDE51XXX',
    id: 2475,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2476,
  },
  {
    bankName: 'Kreissparkasse Limburg',
    bic: 'HELADEF1LIM',
    id: 2477,
  },
  {
    bankName: 'Kreissparkasse Weilburg',
    bic: 'HELADEF1WEI',
    id: 2478,
  },
  {
    bankName: 'Volksbank Langendernbach',
    bic: 'GENODE51LDD',
    id: 2479,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF511',
    id: 2480,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB511',
    id: 2481,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF511',
    id: 2482,
  },
  {
    bankName: 'Vereinigte Volksbank Limburg -alt-',
    bic: 'GENODE51LIM',
    id: 2483,
  },
  {
    bankName: 'Volksbank Schupbach',
    bic: 'GENODE51SBH',
    id: 2484,
  },
  {
    bankName: 'Volks- und Raiffeisenbank Weilmünster -alt-',
    bic: 'GENODE51WEM',
    id: 2485,
  },
  {
    bankName: 'BNP Paribas Niederlassung Deutschland',
    bic: 'BNPADEFFXXX',
    id: 2486,
  },
  {
    bankName: 'BNP Paribas Niederlassung Deutschland',
    bic: 'BNPADEFFXXX',
    id: 2487,
  },
  {
    bankName: 'BNP Paribas Niederlassung Deutschland',
    bic: 'BNPADEFFXXX',
    id: 2488,
  },
  {
    bankName: 'NIBC Bank Zndl Frankfurt am Main',
    bic: 'NZFMDEF1XXX',
    id: 2489,
  },
  {
    bankName: 'Societe Generale',
    bic: 'SOGEDEFFXXX',
    id: 2490,
  },
  {
    bankName: 'Societe Generale',
    bic: 'SOGEDEFFXXX',
    id: 2491,
  },
  {
    bankName: 'Societe Generale',
    bic: 'SOGEDEFFXXX',
    id: 2492,
  },
  {
    bankName: 'Societe Generale',
    bic: 'SOGEDEFFXXX',
    id: 2493,
  },
  {
    bankName: 'NATIXIS Pfandbriefbank',
    bic: 'NATXDEFPXXX',
    id: 2494,
  },
  {
    bankName: 'Skandinaviska Enskilda Banken (publ) Frankfurt Branch',
    bic: 'ESSEDEFFXXX',
    id: 2495,
  },
  {
    bankName: 'Bank Saderat Iran',
    bic: 'SIHRDEH1FFM',
    id: 2496,
  },
  {
    bankName: 'ZIRAAT BANK International',
    bic: 'TCZBDEFFXXX',
    id: 2497,
  },
  {
    bankName: 'Banco do Brasil',
    bic: 'BRASDEFFXXX',
    id: 2498,
  },
  {
    bankName: 'Morgan Stanley Bank',
    bic: 'MSFFDEFPXXX',
    id: 2499,
  },
  {
    bankName: 'Morgan Stanley Bank',
    bic: 'MSFFDEFXCND',
    id: 2500,
  },
  {
    bankName: 'Standard Chartered Bank',
    bic: 'SCBLDEFXXXX',
    id: 2501,
  },
  {
    bankName: 'Standard Chartered Bank',
    bic: 'SCBLDEFXXXX',
    id: 2502,
  },
  {
    bankName: 'Ikano Bank',
    bic: 'PLFGDE5AIKB',
    id: 2503,
  },
  {
    bankName: 'Europe ARAB Bank',
    bic: 'ARABDEFFXXX',
    id: 2504,
  },
  {
    bankName: 'Taunus-Sparkasse',
    bic: 'HELADEF1TSK',
    id: 2505,
  },
  {
    bankName: 'Bundesbank eh Gießen',
    bic: 'MARKDEF1513',
    id: 2506,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2507,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2508,
  },
  {
    bankName: 'Sparkasse Gießen',
    bic: 'SKGIDE5FXXX',
    id: 2509,
  },
  {
    bankName: 'Sparkasse Grünberg',
    bic: 'HELADEF1GRU',
    id: 2510,
  },
  {
    bankName: 'Sparkasse Laubach-Hungen',
    bic: 'HELADEF1LAU',
    id: 2511,
  },
  {
    bankName: 'Volksbank Heuchelheim',
    bic: 'GENODE51HHE',
    id: 2512,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF513',
    id: 2513,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB513',
    id: 2514,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF513',
    id: 2515,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFJ18',
    id: 2516,
  },
  {
    bankName: 'Volksbank Mittelhessen',
    bic: 'VBMHDE5FXXX',
    id: 2517,
  },
  {
    bankName: 'Bank of America',
    bic: 'BOFADEFFXXX',
    id: 2518,
  },
  {
    bankName: 'Bank of China',
    bic: 'BKCHDEFFXXX',
    id: 2519,
  },
  {
    bankName: 'Commerzbank - GF onvista',
    bic: 'BOURDEFFXXX',
    id: 2520,
  },
  {
    bankName: 'Misr Bank-Europe',
    bic: 'MIBEDEFFXXX',
    id: 2521,
  },
  {
    bankName: 'Bank Julius Bär Deutschland',
    bic: 'BAERDEF1XXX',
    id: 2522,
  },
  {
    bankName: 'Goldman Sachs',
    bic: 'GOLDDEFFXXX',
    id: 2523,
  },
  {
    bankName: 'Goldman Sachs',
    bic: 'GOLDDEFBXXX',
    id: 2524,
  },
  {
    bankName: 'Saman Bank Niederlassung Frankfurt',
    bic: 'SABCDEFFXXX',
    id: 2525,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2526,
  },
  {
    bankName: 'Sparkasse Wetzlar',
    bic: 'HELADEF1WET',
    id: 2527,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF515',
    id: 2528,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB515',
    id: 2529,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF515',
    id: 2530,
  },
  {
    bankName: 'Volksbank Brandoberndorf',
    bic: 'GENODE51WBO',
    id: 2531,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2532,
  },
  {
    bankName: 'Sparkasse Dillenburg',
    bic: 'HELADEF1DIL',
    id: 2533,
  },
  {
    bankName: 'Volksbank Dill -alt-',
    bic: 'GENODE51DIL',
    id: 2534,
  },
  {
    bankName: 'Volksbank Herborn-Eschenburg -alt-',
    bic: 'GENODE51HER',
    id: 2535,
  },
  {
    bankName: 'Sparkasse Battenberg',
    bic: 'HELADEF1BAT',
    id: 2536,
  },
  {
    bankName: 'VR Bank Lahn-Dill',
    bic: 'GENODE51BIK',
    id: 2537,
  },
  {
    bankName: 'Sparkasse Oberhessen',
    bic: 'HELADEF1FRI',
    id: 2538,
  },
  {
    bankName: 'BVB Volksbank Ndl d Frankfurter Volksbank',
    bic: 'GENODEF1BVB',
    id: 2539,
  },
  {
    bankName: 'Volksbank Butzbach',
    bic: 'GENODE51BUT',
    id: 2540,
  },
  {
    bankName: 'Landbank Horlofftal',
    bic: 'GENODE51REW',
    id: 2541,
  },
  {
    bankName: 'Volksbank Ober-Mörlen',
    bic: 'GENODE51OBM',
    id: 2542,
  },
  {
    bankName: 'Volksbank Ulrichstein',
    bic: 'GENODE51ULR',
    id: 2543,
  },
  {
    bankName: 'Spar- und Darlehnskasse Stockhausen -alt-',
    bic: 'GENODE51HSH',
    id: 2544,
  },
  {
    bankName: 'Volksbank Feldatal',
    bic: 'GENODE51FEL',
    id: 2545,
  },
  {
    bankName: 'Volksbank Lauterbach-Schlitz',
    bic: 'GENODE51LB1',
    id: 2546,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2547,
  },
  {
    bankName: 'Landeskreditkasse Kassel',
    bic: 'HELADEFF520',
    id: 2548,
  },
  {
    bankName: 'Kasseler Sparkasse',
    bic: 'HELADEF1KAS',
    id: 2549,
  },
  {
    bankName: 'Stadtsparkasse Borken (Hessen)',
    bic: 'HELADEF1BOR',
    id: 2550,
  },
  {
    bankName: 'Stadtsparkasse Felsberg -alt-',
    bic: 'HELADEF1FEL',
    id: 2551,
  },
  {
    bankName: 'Stadtsparkasse Grebenstein',
    bic: 'HELADEF1GRE',
    id: 2552,
  },
  {
    bankName: 'Kreissparkasse Schwalm-Eder',
    bic: 'HELADEF1MEG',
    id: 2553,
  },
  {
    bankName: 'Stadtsparkasse Schwalmstadt',
    bic: 'HELADEF1SWA',
    id: 2554,
  },
  {
    bankName: 'DZ BANK',
    bic: 'GENODEFF520',
    id: 2555,
  },
  {
    bankName: 'Kurhessische Landbank',
    bic: 'GENODEF1KS2',
    id: 2556,
  },
  {
    bankName: 'Evangelische Bank',
    bic: 'GENODEF1EK1',
    id: 2557,
  },
  {
    bankName: 'Evangelische Bank (Gf GAA)',
    bic: 'GENODEF1EK1',
    id: 2558,
  },
  {
    bankName: 'Raiffeisenbank Borken Nordhessen',
    bic: 'GENODEF1BOR',
    id: 2559,
  },
  {
    bankName: 'VR-Bank Chattengau -alt-',
    bic: 'GENODEF1GUB',
    id: 2560,
  },
  {
    bankName: 'VR PartnerBank Chattengau-Schwalm-Eder',
    bic: 'GENODEF1HRV',
    id: 2561,
  },
  {
    bankName: 'VR-Bank Spangenberg-Morschen',
    bic: 'GENODEF1SPB',
    id: 2562,
  },
  {
    bankName: 'Raiffeisenbank HessenNord',
    bic: 'GENODEF1WOH',
    id: 2563,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1BTA',
    id: 2564,
  },
  {
    bankName: 'Raiffeisenbank Burghaun -alt-',
    bic: 'GENODEF1BHN',
    id: 2565,
  },
  {
    bankName: 'Spar-u. Kredit-Bank',
    bic: 'GENODEF1GMD',
    id: 2566,
  },
  {
    bankName: 'Raiffeisenbank Langenschwarz -alt-',
    bic: 'GENODEF1BUR',
    id: 2567,
  },
  {
    bankName: 'Raiffeisenbank Volkmarsen',
    bic: 'GENODEF1VLM',
    id: 2568,
  },
  {
    bankName: 'Frankenberger Bank Raiffeisenbank',
    bic: 'GENODEF1FBK',
    id: 2569,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF520',
    id: 2570,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF523',
    id: 2571,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF524',
    id: 2572,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB520',
    id: 2573,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB523',
    id: 2574,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB524',
    id: 2575,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF521',
    id: 2576,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB521',
    id: 2577,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF520',
    id: 2578,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK1',
    bic: 'DRESDEFFJ19',
    id: 2579,
  },
  {
    bankName: 'Volksbank Kassel Göttingen',
    bic: 'GENODE51KS1',
    id: 2580,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2581,
  },
  {
    bankName: 'Sparkasse Werra-Meißner',
    bic: 'HELADEF1ESW',
    id: 2582,
  },
  {
    bankName: 'VR-Bank Mitte',
    bic: 'GENODEF1ESW',
    id: 2583,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF522',
    id: 2584,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB522',
    id: 2585,
  },
  {
    bankName: 'Sparkasse Waldeck-Frankenberg',
    bic: 'HELADEF1KOR',
    id: 2586,
  },
  {
    bankName: 'Waldecker Bank',
    bic: 'GENODEF1KBW',
    id: 2587,
  },
  {
    bankName: 'ReiseBank',
    bic: 'RBAGDEF1XXX',
    id: 2588,
  },
  {
    bankName: 'ReiseBank Gf2',
    bic: 'RBAGDEF1CMI',
    id: 2589,
  },
  {
    bankName: 'KEB Hana Bank (Deutschland)',
    bic: 'KOEXDEFAXXX',
    id: 2590,
  },
  {
    bankName: 'ReiseBank Gf3 vormals Cash Express',
    bic: 'RBAGDEF1CMI',
    id: 2591,
  },
  {
    bankName: 'ReiseBank Gf4 vormals Cash Express',
    bic: 'RBAGDEF1CMI',
    id: 2592,
  },
  {
    bankName: 'Credit Agricole CIB Deutschland',
    bic: 'BSUIDEFFXXX',
    id: 2593,
  },
  {
    bankName: 'SHINHAN BANK EUROPE',
    bic: 'SHBKDEFFXXX',
    id: 2594,
  },
  {
    bankName: 'Piraeus Bank Frankfurt Branch',
    bic: 'ABGRDEFFXXX',
    id: 2595,
  },
  {
    bankName: 'SECB Swiss Euro Clearing Bank',
    bic: 'SECGDEFFXXX',
    id: 2596,
  },
  {
    bankName: 'Credit Mutuel - BECM - Ndl Deutschland',
    bic: 'CMCIDEF1XXX',
    id: 2597,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2598,
  },
  {
    bankName: 'Sparkasse Fulda',
    bic: 'HELADEF1FDS',
    id: 2599,
  },
  {
    bankName: 'Kreissparkasse Schlüchtern',
    bic: 'HELADEF1SLU',
    id: 2600,
  },
  {
    bankName: 'VR Bank Fulda',
    bic: 'GENODE51FUL',
    id: 2601,
  },
  {
    bankName: 'VR-Bank NordRhön',
    bic: 'GENODEF1HUE',
    id: 2602,
  },
  {
    bankName: 'VR Bank Schlüchtern-Birstein -alt-',
    bic: 'GENODE51SLU',
    id: 2603,
  },
  {
    bankName: 'Raiffeisenbank im Fuldaer Land',
    bic: 'GENODEF1GLU',
    id: 2604,
  },
  {
    bankName: 'Raiffeisenbank Biebergrund-Petersberg',
    bic: 'GENODEF1PBG',
    id: 2605,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1FLN',
    id: 2606,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF530',
    id: 2607,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF531',
    id: 2608,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF534',
    id: 2609,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB530',
    id: 2610,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB531',
    id: 2611,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB534',
    id: 2612,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP27',
    id: 2613,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEFFS27',
    id: 2614,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF530',
    id: 2615,
  },
  {
    bankName: 'VR Bank HessenLand',
    bic: 'GENODE51ALS',
    id: 2616,
  },
  {
    bankName: 'AgrarBank',
    bic: 'GENODE51AGR',
    id: 2617,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2618,
  },
  {
    bankName: 'Sparkasse Bad Hersfeld-Rotenburg',
    bic: 'HELADEF1HER',
    id: 2619,
  },
  {
    bankName: 'Raiffeisenbank Asbach-Sorga -alt-',
    bic: 'GENODEF1HFA',
    id: 2620,
  },
  {
    bankName: 'Bankverein Bebra -alt-',
    bic: 'GENODEF1BEB',
    id: 2621,
  },
  {
    bankName: 'Raiffeisenbank Werratal-Landeck',
    bic: 'GENODEF1RAW',
    id: 2622,
  },
  {
    bankName: 'Raiffeisenbank Haunetal -alt-',
    bic: 'GENODEF1HNT',
    id: 2623,
  },
  {
    bankName: 'Raiffeisenbank Ronshausen-Marksuhl -alt-',
    bic: 'GENODEF1ROH',
    id: 2624,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF532',
    id: 2625,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB518',
    id: 2626,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF532',
    id: 2627,
  },
  {
    bankName: 'VR-Bankverein Bad Hersfeld-Rotenburg',
    bic: 'GENODE51BHE',
    id: 2628,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2629,
  },
  {
    bankName: 'Sparkasse Marburg-Biedenkopf',
    bic: 'HELADEF1MAR',
    id: 2630,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1EBG',
    id: 2631,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF533',
    id: 2632,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB533',
    id: 2633,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF533',
    id: 2634,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF568',
    id: 2635,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM482',
    id: 2636,
  },
  {
    bankName: 'Service Credit Union Overseas Headquarters',
    bic: 'SCRUDE51XXX',
    id: 2637,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2638,
  },
  {
    bankName: 'Stadtsparkasse Kaiserslautern -alt-',
    bic: 'MALADE51KLS',
    id: 2639,
  },
  {
    bankName: 'Sparkasse Kaiserslautern',
    bic: 'MALADE51KLK',
    id: 2640,
  },
  {
    bankName: 'Kreissparkasse Kusel',
    bic: 'MALADE51KUS',
    id: 2641,
  },
  {
    bankName: 'Stadtsparkasse Landstuhl -alt-',
    bic: 'MALADE51LAS',
    id: 2642,
  },
  {
    bankName: 'Sparkasse Donnersberg',
    bic: 'MALADE51ROK',
    id: 2643,
  },
  {
    bankName: 'VR-Bank Westpfalz -alt-',
    bic: 'GENODE61LAN',
    id: 2644,
  },
  {
    bankName: 'Raiffeisenbank Donnersberg -alt-',
    bic: 'GENODE61ALB',
    id: 2645,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB540',
    id: 2646,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB541',
    id: 2647,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM540',
    id: 2648,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM541',
    id: 2649,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF540',
    id: 2650,
  },
  {
    bankName: 'Volksbank Kaiserslautern',
    bic: 'GENODE61KL1',
    id: 2651,
  },
  {
    bankName: 'Volksbank Lauterecken',
    bic: 'GENODE61LEK',
    id: 2652,
  },
  {
    bankName: 'VR Bank Nordwestpfalz -alt-',
    bic: 'GENODE61OB1',
    id: 2653,
  },
  {
    bankName: 'Volksbank Glan-Münchweiler',
    bic: 'GENODE61GLM',
    id: 2654,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM485',
    id: 2655,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2656,
  },
  {
    bankName: 'Sparkasse Südwestpfalz',
    bic: 'MALADE51SWP',
    id: 2657,
  },
  {
    bankName: 'VR-Bank Südwestpfalz Pirmasens-Zweibrücken',
    bic: 'GENODE61ROA',
    id: 2658,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB542',
    id: 2659,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB543',
    id: 2660,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM542',
    id: 2661,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM543',
    id: 2662,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF542',
    id: 2663,
  },
  {
    bankName: 'VR-Bank Pirmasens -alt-',
    bic: 'GENODE61PS1',
    id: 2664,
  },
  {
    bankName: 'Raiffeisen- u Volksbank Dahn -alt-',
    bic: 'GENODE61DAH',
    id: 2665,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1545',
    id: 2666,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2667,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM483',
    id: 2668,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM483',
    id: 2669,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM483',
    id: 2670,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM483',
    id: 2671,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM483',
    id: 2672,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM483',
    id: 2673,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2674,
  },
  {
    bankName: 'Sparkasse Vorderpfalz',
    bic: 'LUHSDE6AXXX',
    id: 2675,
  },
  {
    bankName: 'Kreissparkasse Rhein-Pfalz',
    bic: 'MALADE51LUH',
    id: 2676,
  },
  {
    bankName: 'RV Bank Rhein-Haardt -alt-',
    bic: 'GENODE61LBS',
    id: 2677,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB545',
    id: 2678,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB549',
    id: 2679,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB544',
    id: 2680,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB550',
    id: 2681,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB547',
    id: 2682,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB553',
    id: 2683,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM545',
    id: 2684,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM544',
    id: 2685,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM549',
    id: 2686,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM550',
    id: 2687,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM547',
    id: 2688,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM553',
    id: 2689,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF545',
    id: 2690,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM620',
    id: 2691,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2692,
  },
  {
    bankName: 'Sparkasse Rhein-Haardt',
    bic: 'MALADE51DKH',
    id: 2693,
  },
  {
    bankName: 'Raiffeisenbank Freinsheim -alt-',
    bic: 'GENODE61FSH',
    id: 2694,
  },
  {
    bankName: 'Raiffeisenbank Friedelsheim-Rödersheim -alt-',
    bic: 'GENODE61FHR',
    id: 2695,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB546',
    id: 2696,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB552',
    id: 2697,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB548',
    id: 2698,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM546',
    id: 2699,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM552',
    id: 2700,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM548',
    id: 2701,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF546',
    id: 2702,
  },
  {
    bankName: 'VR Bank Mittelhaardt',
    bic: 'GENODE61DUW',
    id: 2703,
  },
  {
    bankName: 'Kreis- und Stadtsparkasse Speyer',
    bic: 'MALADE51SPY',
    id: 2704,
  },
  {
    bankName: 'Vereinigte VR Bank Kur- und Rheinpfalz',
    bic: 'GENODE61SPE',
    id: 2705,
  },
  {
    bankName: 'Sparkasse Südpfalz',
    bic: 'SOLADES1SUW',
    id: 2706,
  },
  {
    bankName: 'Sparkasse Germersheim-Kandel',
    bic: 'MALADE51KAD',
    id: 2707,
  },
  {
    bankName: 'Raiffeisenbank Oberhaardt-Gäu -alt-',
    bic: 'GENODE61EDH',
    id: 2708,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODE61HXH',
    id: 2709,
  },
  {
    bankName: 'VR Bank Südpfalz',
    bic: 'GENODE61SUW',
    id: 2710,
  },
  {
    bankName: 'VR Bank Südliche Weinstraße-Wasgau',
    bic: 'GENODE61BZA',
    id: 2711,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1550',
    id: 2712,
  },
  {
    bankName: 'Aareal Bank GF - BK01 -',
    bic: 'AARBDE5WDOM',
    id: 2713,
  },
  {
    bankName: 'Aareal Bank',
    bic: 'AARBDE5W550',
    id: 2714,
  },
  {
    bankName: 'Aareal Bank Clearing Wiesbaden',
    bic: 'AARBDE5WCLE',
    id: 2715,
  },
  {
    bankName: 'Investitions- und Strukturbank RP',
    bic: 'ISBRDE55XXX',
    id: 2716,
  },
  {
    bankName: 'BHF-BANK',
    bic: 'BHFBDEFF550',
    id: 2717,
  },
  {
    bankName: 'Bausparkasse Mainz',
    bic: 'BKMZDE51XXX',
    id: 2718,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM486',
    id: 2719,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33MNZ',
    id: 2720,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33MNZ',
    id: 2721,
  },
  {
    bankName: 'Aareal Bank (formerly Westdeutsche ImmobilienBank)',
    bic: 'IMMODE5MXXX',
    id: 2722,
  },
  {
    bankName: 'Süd-West-Kreditbank Finanzierung',
    bic: 'SUFGDE51XXX',
    id: 2723,
  },
  {
    bankName: 'TARGOBANK Gf FG',
    bic: 'CPLADE55XXX',
    id: 2724,
  },
  {
    bankName: 'Santander Consumer Bank',
    bic: 'SCFBDE33XXX',
    id: 2725,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2726,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 2727,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 2728,
  },
  {
    bankName: 'ZV Landesbank Baden-Württemberg',
    bic: 'SOLADEST550',
    id: 2729,
  },
  {
    bankName: 'Sparkasse Mainz -alt-',
    bic: 'MALADE51MNZ',
    id: 2730,
  },
  {
    bankName: 'VR-Bank Mainz -alt-',
    bic: 'GENODE51MZ4',
    id: 2731,
  },
  {
    bankName: 'VR-Bank Mainz -alt-',
    bic: 'GENODE51MZ2',
    id: 2732,
  },
  {
    bankName: 'Genobank Mainz',
    bic: 'GENODE51MZ6',
    id: 2733,
  },
  {
    bankName: 'Budenheimer Volksbank',
    bic: 'GENODE51BUD',
    id: 2734,
  },
  {
    bankName: 'VR-Bank Mainz -alt-',
    bic: 'GENODE51HDS',
    id: 2735,
  },
  {
    bankName: 'Volksbank Rhein-Selz -alt-',
    bic: 'GENODE51NIS',
    id: 2736,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBMAI',
    id: 2737,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB563',
    id: 2738,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB561',
    id: 2739,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB551',
    id: 2740,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5MXXX',
    id: 2741,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M552',
    id: 2742,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M550',
    id: 2743,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M551',
    id: 2744,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP29',
    id: 2745,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDE5MP29',
    id: 2746,
  },
  {
    bankName: 'Commerzbank, TF MZ 1',
    bic: 'COBADEFFXXX',
    id: 2747,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF550',
    id: 2748,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFJ20',
    id: 2749,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
    bic: 'DRESDEFFJ21',
    id: 2750,
  },
  {
    bankName: 'Commerzbank, TF MZ 2',
    bic: 'DRESDEFF555',
    id: 2751,
  },
  {
    bankName: 'Sparda-Bank Südwest',
    bic: 'GENODEF1S01',
    id: 2752,
  },
  {
    bankName: 'Volksbank Alzey-Worms',
    bic: 'GENODE61AZY',
    id: 2753,
  },
  {
    bankName: 'Clearingkonto LRP-SI',
    bic: 'MALADE51EMZ',
    id: 2754,
  },
  {
    bankName: 'Pax-Bank',
    bic: 'GENODED1PA4',
    id: 2755,
  },
  {
    bankName: 'Mainzer Volksbank',
    bic: 'MVBMDE55XXX',
    id: 2756,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2757,
  },
  {
    bankName: 'Rheinhessen Sparkasse',
    bic: 'MALADE51WOR',
    id: 2758,
  },
  {
    bankName: 'VR Bank',
    bic: 'GENODE51AHM',
    id: 2759,
  },
  {
    bankName: 'Volksbank Bechtheim -alt-',
    bic: 'GENODE51BEC',
    id: 2760,
  },
  {
    bankName: 'Volksbank Worms-Wonnegau -alt-',
    bic: 'GENODE61WO1',
    id: 2761,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM515',
    id: 2762,
  },
  {
    bankName: 'Sparkasse Rhein-Nahe',
    bic: 'MALADE51KRE',
    id: 2763,
  },
  {
    bankName: 'Kreissparkasse Rhein-Hunsrück',
    bic: 'MALADE51SIM',
    id: 2764,
  },
  {
    bankName: 'Raiffeisenbank Kastellaun',
    bic: 'GENODED1KSL',
    id: 2765,
  },
  {
    bankName: 'Volksbank Hunsrück-Nahe',
    bic: 'GENODED1KHK',
    id: 2766,
  },
  {
    bankName: 'Volksbank Rheinböllen',
    bic: 'GENODED1RBO',
    id: 2767,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB560',
    id: 2768,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M560',
    id: 2769,
  },
  {
    bankName: 'Volksbank Rhein-Nahe-Hunsrück',
    bic: 'GENODE51KRE',
    id: 2770,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2771,
  },
  {
    bankName: 'Kreissparkasse Birkenfeld',
    bic: 'BILADE55XXX',
    id: 2772,
  },
  {
    bankName: 'Raiffeisenbank Nahe',
    bic: 'GENODED1FIN',
    id: 2773,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB562',
    id: 2774,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M562',
    id: 2775,
  },
  {
    bankName: 'Volksbank-Raiffeisenbank Naheland -alt-',
    bic: 'GENODE51IDO',
    id: 2776,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1570',
    id: 2777,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM401',
    id: 2778,
  },
  {
    bankName: 'MMV Bank',
    bic: 'MKBKDE51XXX',
    id: 2779,
  },
  {
    bankName: 'Oyak Anker Bank',
    bic: 'OYAKDE5KXXX',
    id: 2780,
  },
  {
    bankName: 'Debeka Bausparkasse',
    bic: 'DEBKDE51XXX',
    id: 2781,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2782,
  },
  {
    bankName: 'Sparkasse Koblenz',
    bic: 'MALADE51KOB',
    id: 2783,
  },
  {
    bankName: 'Kreissparkasse Westerwald -alt-',
    bic: 'MALADE51BMB',
    id: 2784,
  },
  {
    bankName: 'Kreissparkasse Cochem-Zell -alt-',
    bic: 'MALADE51COC',
    id: 2785,
  },
  {
    bankName: 'DZ BANK',
    bic: 'GENODEDD570',
    id: 2786,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODE51NWA',
    id: 2787,
  },
  {
    bankName: 'Volksbank Mülheim-Kärlich -alt-',
    bic: 'GENODED1MKA',
    id: 2788,
  },
  {
    bankName: 'Raiffeisenbank Lutzerather Höhe -alt-',
    bic: 'GENODED1LUH',
    id: 2789,
  },
  {
    bankName: 'Raiffeisenbank Moselkrampen',
    bic: 'GENODED1MOK',
    id: 2790,
  },
  {
    bankName: 'Raiffeisenbank Eifeltor',
    bic: 'GENODED1KAI',
    id: 2791,
  },
  {
    bankName: 'Raiffeisenbank Neustadt',
    bic: 'GENODED1ASN',
    id: 2792,
  },
  {
    bankName: 'Raiffeisenbank Untermosel -alt-',
    bic: 'GENODED1UMO',
    id: 2793,
  },
  {
    bankName: 'Raiffeisenbank Straßenhaus -alt-',
    bic: 'GENODED1SRH',
    id: 2794,
  },
  {
    bankName: 'Raiffeisenbank Welling',
    bic: 'GENODED1WLG',
    id: 2795,
  },
  {
    bankName: 'Raiffeisenbank Irrel',
    bic: 'GENODED1IRR',
    id: 2796,
  },
  {
    bankName: 'VR-Bank Hunsrück-Mosel',
    bic: 'GENODED1MBA',
    id: 2797,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB570',
    id: 2798,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB572',
    id: 2799,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB571',
    id: 2800,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB577',
    id: 2801,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB573',
    id: 2802,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB578',
    id: 2803,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M570',
    id: 2804,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M572',
    id: 2805,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M573',
    id: 2806,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M577',
    id: 2807,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M571',
    id: 2808,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M578',
    id: 2809,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP28',
    id: 2810,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDE5MP28',
    id: 2811,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF570',
    id: 2812,
  },
  {
    bankName: 'Volksbank Koblenz Mittelrhein -alt-',
    bic: 'GENODE51KOB',
    id: 2813,
  },
  {
    bankName: 'Volksbank Koblenz Mittelrhein GAA -alt-',
    bic: 'GENODE51KOB',
    id: 2814,
  },
  {
    bankName: 'PSD Bank Koblenz',
    bic: 'GENODEF1P12',
    id: 2815,
  },
  {
    bankName: 'Volksbank Montabaur-Höhr-Grenzhausen -alt-',
    bic: 'GENODE51MON',
    id: 2816,
  },
  {
    bankName: 'Volksbank Höhr-Grenzhausen -alt-',
    bic: 'GENODE51HGR',
    id: 2817,
  },
  {
    bankName: 'Volksbank Rhein-Lahn-Limburg',
    bic: 'GENODE51DIE',
    id: 2818,
  },
  {
    bankName: 'Raiffeisenbank Unterwesterwald',
    bic: 'GENODE51ARZ',
    id: 2819,
  },
  {
    bankName: 'Sparkasse Westerwald-Sieg',
    bic: 'MALADE51AKI',
    id: 2820,
  },
  {
    bankName: 'Volksbank Gebhardshain',
    bic: 'GENODED1GBS',
    id: 2821,
  },
  {
    bankName: 'Raiffeisenbank Niederfischbach -alt-',
    bic: 'GENODED1NFB',
    id: 2822,
  },
  {
    bankName: 'Volksbank Daaden',
    bic: 'GENODE51DAA',
    id: 2823,
  },
  {
    bankName: 'Volksbank Hamm/Sieg',
    bic: 'GENODE51HAM',
    id: 2824,
  },
  {
    bankName: 'Westerwald Bank',
    bic: 'GENODE51WW1',
    id: 2825,
  },
  {
    bankName: 'Sparkasse Neuwied',
    bic: 'MALADE51NWD',
    id: 2826,
  },
  {
    bankName: 'VR Bank Rhein-Mosel',
    bic: 'GENODED1NWD',
    id: 2827,
  },
  {
    bankName: 'Raiffeisenbank Mittelrhein -alt-',
    bic: 'GENODED1MRW',
    id: 2828,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB574',
    id: 2829,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB575',
    id: 2830,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB576',
    id: 2831,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB579',
    id: 2832,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M574',
    id: 2833,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M575',
    id: 2834,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M576',
    id: 2835,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M579',
    id: 2836,
  },
  {
    bankName: 'Kreissparkasse Mayen',
    bic: 'MALADE51MYN',
    id: 2837,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODED1KEH',
    id: 2838,
  },
  {
    bankName: 'VR Bank Rhein-Mosel -alt-',
    bic: 'GENODED1MPO',
    id: 2839,
  },
  {
    bankName: 'Kreissparkasse Ahrweiler',
    bic: 'MALADE51AHR',
    id: 2840,
  },
  {
    bankName: 'Volksbank RheinAhrEifel',
    bic: 'GENODED1BNA',
    id: 2841,
  },
  {
    bankName: 'Volksbank RheinAhrEifel (Gf GAA)',
    bic: 'GENODED1BNA',
    id: 2842,
  },
  {
    bankName: 'Raiffeisenbank Grafschaft-Wachtberg',
    bic: 'GENODED1GRO',
    id: 2843,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM437',
    id: 2844,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2845,
  },
  {
    bankName: 'Sparkasse Trier',
    bic: 'TRISDE55XXX',
    id: 2846,
  },
  {
    bankName: 'Volksbank Trier',
    bic: 'GENODED1TVB',
    id: 2847,
  },
  {
    bankName: 'Pax-Bank',
    bic: 'GENODED1PA3',
    id: 2848,
  },
  {
    bankName: 'Volksbank Saarburg -alt-',
    bic: 'GENODED1SRB',
    id: 2849,
  },
  {
    bankName: 'Raiffeisenbank Mehring-Leiwen',
    bic: 'GENODED1MLW',
    id: 2850,
  },
  {
    bankName: 'Volksbank Hochwald-Saarburg -alt-',
    bic: 'GENODED1HWM',
    id: 2851,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB585',
    id: 2852,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB580',
    id: 2853,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB586',
    id: 2854,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M585',
    id: 2855,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M590',
    id: 2856,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M586',
    id: 2857,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF585',
    id: 2858,
  },
  {
    bankName: 'PSD Bank Trier Ndl der PSD Bank West',
    bic: 'GENODEF1P21',
    id: 2859,
  },
  {
    bankName: 'Kreissparkasse Bitburg-Prüm',
    bic: 'MALADE51BIT',
    id: 2860,
  },
  {
    bankName: 'Kreissparkasse Vulkaneifel',
    bic: 'MALADE51DAU',
    id: 2861,
  },
  {
    bankName: 'Volksbank Eifel',
    bic: 'GENODED1BIT',
    id: 2862,
  },
  {
    bankName: 'Raiffeisenbank Westeifel',
    bic: 'GENODED1WSC',
    id: 2863,
  },
  {
    bankName: 'Raiffeisenbank östl Südeifel -alt-',
    bic: 'GENODED1OSE',
    id: 2864,
  },
  {
    bankName: 'Raiffeisenbank Neuerburg-Land -alt-',
    bic: 'GENODED1NBL',
    id: 2865,
  },
  {
    bankName: 'Volksbank Eifel Mitte -alt-',
    bic: 'GENODED1PRU',
    id: 2866,
  },
  {
    bankName: 'Sparkasse Mittelmosel-Eifel Mosel Hunsrück',
    bic: 'MALADE51BKS',
    id: 2867,
  },
  {
    bankName: 'Vereinigte Volksbank Raiffeisenbank',
    bic: 'GENODED1WTL',
    id: 2868,
  },
  {
    bankName: 'Raiffeisenbank Zeller Land',
    bic: 'GENODED1BPU',
    id: 2869,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB587',
    id: 2870,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB589',
    id: 2871,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB588',
    id: 2872,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M587',
    id: 2873,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M589',
    id: 2874,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M588',
    id: 2875,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1590',
    id: 2876,
  },
  {
    bankName: 'ZVC FK 11 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2877,
  },
  {
    bankName: 'ZVC FK 12 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2878,
  },
  {
    bankName: 'ZVC FK 13 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2879,
  },
  {
    bankName: 'ZVC FK 14 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2880,
  },
  {
    bankName: 'ZVC FK 15 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF015',
    id: 2881,
  },
  {
    bankName: 'ZVC FK 16 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2882,
  },
  {
    bankName: 'ZVC FK 17 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF017',
    id: 2883,
  },
  {
    bankName: 'ZVC FK 18 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2884,
  },
  {
    bankName: 'ZVC FK 19 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF019',
    id: 2885,
  },
  {
    bankName: 'ZVC FK 21 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF021',
    id: 2886,
  },
  {
    bankName: 'ZVC FK 22 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF022',
    id: 2887,
  },
  {
    bankName: 'ZVC FK 23 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF023',
    id: 2888,
  },
  {
    bankName: 'ZVC FK 24 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2889,
  },
  {
    bankName: 'ZVC FK 25 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF025',
    id: 2890,
  },
  {
    bankName: 'ZVC FK 26 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2891,
  },
  {
    bankName: 'ZVC FK 27 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2892,
  },
  {
    bankName: 'ZVC FK 28 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2893,
  },
  {
    bankName: 'ZVC FK 29 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2894,
  },
  {
    bankName: 'ZVC FK 31 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2895,
  },
  {
    bankName: 'ZVC FK 32 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF032',
    id: 2896,
  },
  {
    bankName: 'ZVC FK 33 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF033',
    id: 2897,
  },
  {
    bankName: 'ZVC FK 34 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2898,
  },
  {
    bankName: 'ZVC FK 35 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2899,
  },
  {
    bankName: 'ZVC FK 36 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2900,
  },
  {
    bankName: 'ZVC FK 37 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF037',
    id: 2901,
  },
  {
    bankName: 'ZVC FK 38 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2902,
  },
  {
    bankName: 'ZVC FK 39 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF039',
    id: 2903,
  },
  {
    bankName: 'ZVC FK 40 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2904,
  },
  {
    bankName: 'ZVC FK 41 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2905,
  },
  {
    bankName: 'ZVC FK 42 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF042',
    id: 2906,
  },
  {
    bankName: 'ZVC FK 44 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF044',
    id: 2907,
  },
  {
    bankName: 'ZVC FK 45 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2908,
  },
  {
    bankName: 'ZVC FK 47 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF047',
    id: 2909,
  },
  {
    bankName: 'ZVC FK 48 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2910,
  },
  {
    bankName: 'ZVC FK 49 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF049',
    id: 2911,
  },
  {
    bankName: 'ZVC FK 51 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2912,
  },
  {
    bankName: 'ZVC FK 52 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2913,
  },
  {
    bankName: 'ZVC FK 53 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFF053',
    id: 2914,
  },
  {
    bankName: 'ZVC FK 54 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2915,
  },
  {
    bankName: 'ZVC FK 55 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2916,
  },
  {
    bankName: 'ZVC FK 56 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2917,
  },
  {
    bankName: 'ZVC FK 57 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2918,
  },
  {
    bankName: 'ZVC FK 58 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2919,
  },
  {
    bankName: 'ZVC FK 59 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2920,
  },
  {
    bankName: 'ZVC FK 61 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2921,
  },
  {
    bankName: 'ZVC FK 62 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2922,
  },
  {
    bankName: 'ZVC FK 63 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2923,
  },
  {
    bankName: 'ZVC FK 64 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2924,
  },
  {
    bankName: 'ZVC FK 65 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2925,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2926,
  },
  {
    bankName: 'ZVC FK 68 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2927,
  },
  {
    bankName: 'ZVC FK 69 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2928,
  },
  {
    bankName: 'ZVC FK 71 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2929,
  },
  {
    bankName: 'ZVC FK 72 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2930,
  },
  {
    bankName: 'ZVC FK 73 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2931,
  },
  {
    bankName: 'ZVC FK 74 Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2932,
  },
  {
    bankName: 'Saarl Investitionskreditbank',
    bic: 'SIKBDE55XXX',
    id: 2933,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM432',
    id: 2934,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM432',
    id: 2935,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM432',
    id: 2936,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM432',
    id: 2937,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM432',
    id: 2938,
  },
  {
    bankName: 'mediserv Bank',
    bic: 'MEGHDE81XXX',
    id: 2939,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2940,
  },
  {
    bankName: 'Landesbank Saar',
    bic: 'SALADE55XXX',
    id: 2941,
  },
  {
    bankName: 'Sparkasse Saarbrücken',
    bic: 'SAKSDE55XXX',
    id: 2942,
  },
  {
    bankName: 'Stadtsparkasse Völklingen -alt-',
    bic: 'SALADE51VKS',
    id: 2943,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE5M555',
    id: 2944,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB595',
    id: 2945,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB590',
    id: 2946,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF590',
    id: 2947,
  },
  {
    bankName: 'PSD Bank RheinNeckarSaar',
    bic: 'GENODEF1P19',
    id: 2948,
  },
  {
    bankName: 'Vereinigte Volksbank Saarlouis-Losheim-Sulzbach/Saar',
    bic: 'GENODE51SB2',
    id: 2949,
  },
  {
    bankName: 'Volksbank Nahe-Schaumberg -alt-',
    bic: 'GENODE51NOH',
    id: 2950,
  },
  {
    bankName: 'Bank 1 Saar',
    bic: 'SABADE5SXXX',
    id: 2951,
  },
  {
    bankName: 'Volksbank Westliche Saar plus -alt-',
    bic: 'GENODE51SLS',
    id: 2952,
  },
  {
    bankName: 'Kreissparkasse St. Wendel',
    bic: 'SALADE51WND',
    id: 2953,
  },
  {
    bankName: 'Sparkasse Neunkirchen',
    bic: 'SALADE51NKS',
    id: 2954,
  },
  {
    bankName: 'Unsere Volksbank St. Wendeler Land -alt-',
    bic: 'GENODE51WEN',
    id: 2955,
  },
  {
    bankName: 'Volks- und Raiffeisenbank Saarpfalz',
    bic: 'GENODE51BEX',
    id: 2956,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM838',
    id: 2957,
  },
  {
    bankName: 'Kreissparkasse Saarlouis',
    bic: 'KRSADE55XXX',
    id: 2958,
  },
  {
    bankName: 'Sparkasse Merzig-Wadern',
    bic: 'MERZDE55XXX',
    id: 2959,
  },
  {
    bankName: 'Volksbank Saarlouis -alt-',
    bic: 'GENODE51SLF',
    id: 2960,
  },
  {
    bankName: 'Volksbank Überherrn',
    bic: 'GENODE51UBH',
    id: 2961,
  },
  {
    bankName: 'Volksbank Dillingen -alt-',
    bic: 'GENODE51DSA',
    id: 2962,
  },
  {
    bankName: 'Volksbank Untere Saar -alt-',
    bic: 'GENODE51LOS',
    id: 2963,
  },
  {
    bankName: 'levoBank',
    bic: 'GENODE51LEB',
    id: 2964,
  },
  {
    bankName: 'Kreissparkasse Saarpfalz',
    bic: 'SALADE51HOM',
    id: 2965,
  },
  {
    bankName: 'VR Bank Saarpfalz -alt-',
    bic: 'GENODE51MBT',
    id: 2966,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1600',
    id: 2967,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 2968,
  },
  {
    bankName: 'Aareal Bank',
    bic: 'AARBDE5W600',
    id: 2969,
  },
  {
    bankName: 'Landeskreditbank Baden-Württemberg Förderbank -alt-',
    bic: 'LKBWDE6K600',
    id: 2970,
  },
  {
    bankName: 'Baden-Württembergische Bank',
    bic: 'SOLADEST601',
    id: 2971,
  },
  {
    bankName: 'M.M. Warburg & Co (vormals Schwäbische Bank)',
    bic: 'SCHWDESSXXX',
    id: 2972,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM473',
    id: 2973,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM473',
    id: 2974,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM473',
    id: 2975,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM473',
    id: 2976,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM473',
    id: 2977,
  },
  {
    bankName: 'Mercedes-Benz Bank',
    bic: 'MEBEDE6SDCB',
    id: 2978,
  },
  {
    bankName: 'Bankhaus Bauer, Essen',
    bic: 'BHBADES1XXX',
    id: 2979,
  },
  {
    bankName: 'Bankhaus Ellwanger & Geiger',
    bic: 'ELGEDES1XXX',
    id: 2980,
  },
  {
    bankName: 'CreditPlus Bank',
    bic: 'CPLUDES1XXX',
    id: 2981,
  },
  {
    bankName: 'CreditPlus Bank',
    bic: 'CPLUDES1666',
    id: 2982,
  },
  {
    bankName: 'AKTIVBANK',
    bic: 'AKBADES1XXX',
    id: 2983,
  },
  {
    bankName: 'Isbank Fil Stuttgart',
    bic: 'ISBKDEFXSTU',
    id: 2984,
  },
  {
    bankName: 'TRUMPF Financial Services',
    bic: 'TRUFDE66XXX',
    id: 2985,
  },
  {
    bankName: 'Wüstenrot Bausparkasse',
    bic: 'BSWLDE61XXX',
    id: 2986,
  },
  {
    bankName: 'IBM Deutschland Kreditbank',
    bic: 'IBKBDES1XXX',
    id: 2987,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 2988,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 2989,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 2990,
  },
  {
    bankName: 'Commerzbank, Filiale Stuttgart 2',
    bic: 'COBADEFFXXX',
    id: 2991,
  },
  {
    bankName: 'Landesbank Baden-Württemberg',
    bic: 'SOLADESTXXX',
    id: 2992,
  },
  {
    bankName: 'Landesbank Baden-Württemberg/Baden-Württembergische Bank',
    bic: 'SOLADEST600',
    id: 2993,
  },
  {
    bankName: 'Baden-Württemb.Bank/Landesbank Baden-Württemb.',
    bic: 'SOLADEST493',
    id: 2994,
  },
  {
    bankName: 'Baden-Württemb.Bank/Landesbank Baden-Württemb.',
    bic: 'SOLADEST880',
    id: 2995,
  },
  {
    bankName: 'Baden-Württemb.Bank/Landesbank Baden-Württemb.',
    bic: 'SOLADEST447',
    id: 2996,
  },
  {
    bankName: 'Baden-Württemb.Bank/Landesbank Baden-Württemb.',
    bic: 'SOLADEST829',
    id: 2997,
  },
  {
    bankName: 'Baden-Württemb.Bank/Landesbank Baden-Württemb.',
    bic: 'SOLADEST457',
    id: 2998,
  },
  {
    bankName: 'Baden-Württemb.Bank/Landesbank Baden-Württemb.',
    bic: 'SOLADEST490',
    id: 2999,
  },
  {
    bankName: 'Baden-Württemb.Bank/Landesbank Baden-Württemb.',
    bic: 'SOLADEST437',
    id: 3000,
  },
  {
    bankName: 'Baden-Württemb.Bank/Landesbank Baden-Württemb.',
    bic: 'SOLADEST864',
    id: 3001,
  },
  {
    bankName: 'Baden-Württemb.Bank/Landesbank Baden-Württemb.',
    bic: 'SOLADEST454',
    id: 3002,
  },
  {
    bankName: 'Baden-Württemb.Bank/Landesbank Baden-Württemb.',
    bic: 'SOLADEST896',
    id: 3003,
  },
  {
    bankName: 'Baden-Württemb.Bank/Landesbank Baden-Württemb.',
    bic: 'SOLADEST836',
    id: 3004,
  },
  {
    bankName: 'Baden-Württemb.Bank/Landesbank Baden-Württemb.',
    bic: 'SOLADEST484',
    id: 3005,
  },
  {
    bankName: 'Baden-Württemb.Bank/Landesbank Baden-Württemb.',
    bic: 'SOLADEST487',
    id: 3006,
  },
  {
    bankName: 'Baden-Württemb.Bank/Landesbank Baden-Württemb.',
    bic: 'SOLADEST428',
    id: 3007,
  },
  {
    bankName: 'DZ BANK',
    bic: 'GENODESGXXX',
    id: 3008,
  },
  {
    bankName: 'DZ PRIVATBANK Ndl. Stuttgart',
    bic: 'GENODESTXXX',
    id: 3009,
  },
  {
    bankName: 'Volksbank am Württemberg',
    bic: 'GENODES1UTV',
    id: 3010,
  },
  {
    bankName: 'Echterdinger Bank',
    bic: 'GENODES1ECH',
    id: 3011,
  },
  {
    bankName: 'Volksbank Strohgäu -alt-',
    bic: 'GENODES1MCH',
    id: 3012,
  },
  {
    bankName: 'Raiffeisenbank Dellmensingen -alt-',
    bic: 'GENODES1DMS',
    id: 3013,
  },
  {
    bankName: 'Raiffeisenbank Niedere Alb',
    bic: 'GENODES1RBA',
    id: 3014,
  },
  {
    bankName: 'Raiffeisenbank Bühlertal',
    bic: 'GENODES1RVG',
    id: 3015,
  },
  {
    bankName: 'Raiffeisenbank Sondelfingen',
    bic: 'GENODES1RSF',
    id: 3016,
  },
  {
    bankName: 'Raiffeisenbank Steinheim',
    bic: 'GENODES1SAA',
    id: 3017,
  },
  {
    bankName: 'Raiffeisenbank Aidlingen',
    bic: 'GENODES1AID',
    id: 3018,
  },
  {
    bankName: 'Genossenschaftsbank Weil im Schönbuch',
    bic: 'GENODES1GWS',
    id: 3019,
  },
  {
    bankName: 'Bopfinger Bank Sechta-Ries',
    bic: 'GENODES1BPF',
    id: 3020,
  },
  {
    bankName: 'Raiffeisenbank Gruibingen',
    bic: 'GENODES1RGR',
    id: 3021,
  },
  {
    bankName: 'Raiffeisenbank Bühlertal',
    bic: 'GENODES1RVG',
    id: 3022,
  },
  {
    bankName: 'Raiffeisenbank Donau-Iller -alt-',
    bic: 'GENODES1RDI',
    id: 3023,
  },
  {
    bankName: 'Raiffeisenbank Erlenmoos -alt-',
    bic: 'GENODES1ERM',
    id: 3024,
  },
  {
    bankName: 'Raiffeisenbank Bad Schussenried-Aulendorf',
    bic: 'GENODES1RBS',
    id: 3025,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODES1RIN',
    id: 3026,
  },
  {
    bankName: 'Volksbank Freiberg und Umgebung -alt-',
    bic: 'GENODES1MDH',
    id: 3027,
  },
  {
    bankName: 'Raiffeisenbank Maitis',
    bic: 'GENODES1RMA',
    id: 3028,
  },
  {
    bankName: 'Raiffeisenbank Rißtal -alt-',
    bic: 'GENODES1RRI',
    id: 3029,
  },
  {
    bankName: 'VR-Bank Alb-Blau-Donau',
    bic: 'GENODES1REH',
    id: 3030,
  },
  {
    bankName: 'Raiffeisenbank Reute-Gaisbeuren',
    bic: 'GENODES1RRG',
    id: 3031,
  },
  {
    bankName: 'VR-Bank Ehningen-Nufringen',
    bic: 'GENODES1EHN',
    id: 3032,
  },
  {
    bankName: 'Volksbank Dettenhausen',
    bic: 'GENODES1DEH',
    id: 3033,
  },
  {
    bankName: 'Dettinger Bank',
    bic: 'GENODES1DBE',
    id: 3034,
  },
  {
    bankName: 'Raiffeisenbank Kirchheim-Walheim -alt-',
    bic: 'GENODES1KIB',
    id: 3035,
  },
  {
    bankName: 'Uhlbacher Bank -alt-',
    bic: 'GENODES1UHL',
    id: 3036,
  },
  {
    bankName: 'Raiffeisenbank Mittelbiberach -alt-',
    bic: 'GENODES1MBI',
    id: 3037,
  },
  {
    bankName: 'Raiffeisenbank Oberessendorf -alt-',
    bic: 'GENODES1OED',
    id: 3038,
  },
  {
    bankName: 'Raiffeisenbank Frankenhardt-Stimpfach',
    bic: 'GENODES1RFS',
    id: 3039,
  },
  {
    bankName: 'Raiffeisenbank Vordersteinenberg -alt-',
    bic: 'GENODES1RVS',
    id: 3040,
  },
  {
    bankName: 'Raiffeisenbank Ottenbach',
    bic: 'GENODES1OTT',
    id: 3041,
  },
  {
    bankName: 'Raiffeisenbank Rottumtal -alt-',
    bic: 'GENODES1RRE',
    id: 3042,
  },
  {
    bankName: 'Winterbacher Bank',
    bic: 'GENODES1WBB',
    id: 3043,
  },
  {
    bankName: 'Raiffeisenbank Geislingen-Rosenfeld',
    bic: 'GENODES1RKH',
    id: 3044,
  },
  {
    bankName: 'Raiffeisenbank Heidenheimer Alb -alt-',
    bic: 'GENODES1DEA',
    id: 3045,
  },
  {
    bankName: 'Raiffeisenbank Oberer Wald -alt-',
    bic: 'GENODES1ROW',
    id: 3046,
  },
  {
    bankName: 'Volksbank Murgtal -alt-',
    bic: 'GENODES1VMT',
    id: 3047,
  },
  {
    bankName: 'Scharnhauser Bank',
    bic: 'GENODES1SCA',
    id: 3048,
  },
  {
    bankName: 'Volksbank Brenztal',
    bic: 'GENODES1RNS',
    id: 3049,
  },
  {
    bankName: 'Löchgauer Bank -alt-',
    bic: 'GENODES1LOC',
    id: 3050,
  },
  {
    bankName: 'Raiffeisenbank Westhausen',
    bic: 'GENODES1RWN',
    id: 3051,
  },
  {
    bankName: 'Nufringer Bank -Raiffeisen- -alt-',
    bic: 'GENODES1NUF',
    id: 3052,
  },
  {
    bankName: 'Raiffeisenbank Aichhalden-Hardt-Sulgen',
    bic: 'GENODES1HAR',
    id: 3053,
  },
  {
    bankName: 'Raiffeisenbank Vordere Alb -alt-',
    bic: 'GENODES1RVA',
    id: 3054,
  },
  {
    bankName: 'Raiffeisenbank Schrozberg-Rot am See',
    bic: 'GENODES1SBB',
    id: 3055,
  },
  {
    bankName: 'Raiffeisenbank Ingersheim -alt-',
    bic: 'GENODES1RIH',
    id: 3056,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODES1EHZ',
    id: 3057,
  },
  {
    bankName: 'Erligheimer Bank -alt-',
    bic: 'GENODES1EHB',
    id: 3058,
  },
  {
    bankName: 'Abtsgmünder Bank -Raiffeisen-',
    bic: 'GENODES1ABR',
    id: 3059,
  },
  {
    bankName: 'Raiffeisenbank Bretzfeld-Neuenstein -alt-',
    bic: 'GENODES1BRZ',
    id: 3060,
  },
  {
    bankName: 'Raiffeisenbank Wangen',
    bic: 'GENODES1RWA',
    id: 3061,
  },
  {
    bankName: 'Raiffeisenbank Schlat -alt-',
    bic: 'GENODES1SLA',
    id: 3062,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODES1MEH',
    id: 3063,
  },
  {
    bankName: 'Raiffeisenbank Gammesfeld',
    bic: 'GENODES1RGF',
    id: 3064,
  },
  {
    bankName: 'Raiffeisenbank Hohenloher Land',
    bic: 'GENODES1IBR',
    id: 3065,
  },
  {
    bankName: 'Raiffeisenbank Oberstenfeld -alt-',
    bic: 'GENODES1ROF',
    id: 3066,
  },
  {
    bankName: 'Volksbank Freiberg und Umgebung -alt-',
    bic: 'GENODES1FAN',
    id: 3067,
  },
  {
    bankName: 'Volks- und Raiffeisenbank Boll -alt-',
    bic: 'GENODES1BBO',
    id: 3068,
  },
  {
    bankName: 'Volksbank Freiberg und Umgebung -alt-',
    bic: 'GENODES1HHB',
    id: 3069,
  },
  {
    bankName: 'Raiffeisenbank Horb -alt-',
    bic: 'GENODES1RHB',
    id: 3070,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODES1RMO',
    id: 3071,
  },
  {
    bankName: 'Raiffeisenbank Urbach -alt-',
    bic: 'GENODES1URB',
    id: 3072,
  },
  {
    bankName: 'Darmsheimer Bank -alt-',
    bic: 'GENODES1DHB',
    id: 3073,
  },
  {
    bankName: 'Enztalbank -alt-',
    bic: 'GENODES1VAI',
    id: 3074,
  },
  {
    bankName: 'Federseebank -alt-',
    bic: 'GENODES1FED',
    id: 3075,
  },
  {
    bankName: 'Raiffeisenbank Oberes Gäu Ergenzingen',
    bic: 'GENODES1ROG',
    id: 3076,
  },
  {
    bankName: 'Volksbank Freiberg und Umgebung -alt-',
    bic: 'GENODES1PLE',
    id: 3077,
  },
  {
    bankName: 'VR-Bank Alb -alt-',
    bic: 'GENODES1RUW',
    id: 3078,
  },
  {
    bankName: 'Volksbank Remseck',
    bic: 'GENODES1REM',
    id: 3079,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODES1ERL',
    id: 3080,
  },
  {
    bankName: 'Volksbank Glatten-Wittendorf -alt-',
    bic: 'GENODES1VBG',
    id: 3081,
  },
  {
    bankName: 'Berkheimer Bank -alt-',
    bic: 'GENODES1BHB',
    id: 3082,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODES1BGH',
    id: 3083,
  },
  {
    bankName: 'Raiffeisenbank Tüngental',
    bic: 'GENODES1TUN',
    id: 3084,
  },
  {
    bankName: 'Raiffeisenbank Böllingertal',
    bic: 'GENODES1BOE',
    id: 3085,
  },
  {
    bankName: 'Raiffeisenbank Maselheim-Äpfingen -alt-',
    bic: 'GENODES1RMH',
    id: 3086,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBSTG',
    id: 3087,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB602',
    id: 3088,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB647',
    id: 3089,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB609',
    id: 3090,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB624',
    id: 3091,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB605',
    id: 3092,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESSXXX',
    id: 3093,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS602',
    id: 3094,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS624',
    id: 3095,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS609',
    id: 3096,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS605',
    id: 3097,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS647',
    id: 3098,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDESSP13',
    id: 3099,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP13',
    id: 3100,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF600',
    id: 3101,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 55',
    bic: 'DRESDEFF608',
    id: 3102,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf Zw 57',
    bic: 'DRESDEFF609',
    id: 3103,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK 2',
    bic: 'DRESDEFFI50',
    id: 3104,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf PCC-ITGK 3',
    bic: 'DRESDEFFI54',
    id: 3105,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DC-ITGK 4',
    bic: 'DRESDEFFI57',
    id: 3106,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DC-ITGK 5',
    bic: 'DRESDEFFI58',
    id: 3107,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI21',
    id: 3108,
  },
  {
    bankName: 'Volksbank Stuttgart',
    bic: 'VOBADESSXXX',
    id: 3109,
  },
  {
    bankName: 'Volksbank Stuttgart GAA',
    bic: 'VOBADESSXXX',
    id: 3110,
  },
  {
    bankName: 'Volksbank Zuffenhausen m Zndl Stammheimer VB',
    bic: 'GENODES1ZUF',
    id: 3111,
  },
  {
    bankName: 'SÜDWESTBANK - BAWAG Niederlassung Deutschland',
    bic: 'SWBSDESSXXX',
    id: 3112,
  },
  {
    bankName: 'Sparda-Bank Baden-Württemberg',
    bic: 'GENODEF1S02',
    id: 3113,
  },
  {
    bankName: 'PSD Bank RheinNeckarSaar',
    bic: 'GENODEF1P20',
    id: 3114,
  },
  {
    bankName: 'BHF-BANK',
    bic: 'BHFBDEFF600',
    id: 3115,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33STG',
    id: 3116,
  },
  {
    bankName: 'Santander Consumer Bank',
    bic: 'SCFBDE33XXX',
    id: 3117,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3118,
  },
  {
    bankName: 'Kreissparkasse Waiblingen',
    bic: 'SOLADES1WBN',
    id: 3119,
  },
  {
    bankName: 'Fellbacher Bank',
    bic: 'GENODES1FBB',
    id: 3120,
  },
  {
    bankName: 'VR-Bank Weinstadt -alt-',
    bic: 'GENODES1WNS',
    id: 3121,
  },
  {
    bankName: 'Raiffeisenbank Weissacher Tal -alt-',
    bic: 'GENODES1RWT',
    id: 3122,
  },
  {
    bankName: 'Korber Bank -alt-',
    bic: 'GENODES1KOR',
    id: 3123,
  },
  {
    bankName: 'Kerner Volksbank -alt-',
    bic: 'GENODES1KRN',
    id: 3124,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB606',
    id: 3125,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS606',
    id: 3126,
  },
  {
    bankName: 'Volksbank Rems -alt-',
    bic: 'GENODES1VWN',
    id: 3127,
  },
  {
    bankName: 'Volksbank Backnang',
    bic: 'GENODES1VBK',
    id: 3128,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM858',
    id: 3129,
  },
  {
    bankName: 'Commerzbank Sindelfingen',
    bic: 'COBADEFFXXX',
    id: 3130,
  },
  {
    bankName: 'Kreissparkasse Böblingen',
    bic: 'BBKRDE6BXXX',
    id: 3131,
  },
  {
    bankName: 'Raiffeisenbank Weissach -alt-',
    bic: 'GENODES1WES',
    id: 3132,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF601',
    id: 3133,
  },
  {
    bankName: 'Vereinigte Volksbanken',
    bic: 'GENODES1BBV',
    id: 3134,
  },
  {
    bankName: 'Volksbank Leonberg-Strohgäu',
    bic: 'GENODES1LEO',
    id: 3135,
  },
  {
    bankName: 'Volksbank Herrenberg-Nagold-Rottenburg',
    bic: 'GENODES1VBH',
    id: 3136,
  },
  {
    bankName: 'VR-Bank Magstadt-Weissach',
    bic: 'GENODES1MAG',
    id: 3137,
  },
  {
    bankName: 'Oldenburgische Landesbank',
    bic: 'WBAGDE61XXX',
    id: 3138,
  },
  {
    bankName: 'Oldenburgische Landesbank',
    bic: 'WBAGDEA1XXX',
    id: 3139,
  },
  {
    bankName: 'Oldenburgische Landesbank',
    bic: 'WBAGDEA1XXX',
    id: 3140,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM860',
    id: 3141,
  },
  {
    bankName: 'RSB Retail+Service Bank',
    bic: 'SABUDES1XXX',
    id: 3142,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3143,
  },
  {
    bankName: 'Kreissparkasse Ludwigsburg',
    bic: 'SOLADES1LBG',
    id: 3144,
  },
  {
    bankName: 'Volksbank Freiberg und Umgebung -alt-',
    bic: 'GENODES1EGL',
    id: 3145,
  },
  {
    bankName: 'VR-Bank Asperg-Markgröningen -alt-',
    bic: 'GENODES1AMT',
    id: 3146,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB604',
    id: 3147,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB608',
    id: 3148,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB635',
    id: 3149,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB617',
    id: 3150,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB623',
    id: 3151,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB648',
    id: 3152,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS604',
    id: 3153,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS635',
    id: 3154,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS617',
    id: 3155,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS623',
    id: 3156,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS608',
    id: 3157,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS648',
    id: 3158,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF604',
    id: 3159,
  },
  {
    bankName: 'Volksbank Ludwigsburg -alt-',
    bic: 'GENODES1LBG',
    id: 3160,
  },
  {
    bankName: 'VR-Bank Ludwigsburg',
    bic: 'GENODES1VBB',
    id: 3161,
  },
  {
    bankName: 'VR-Bank Ludwigsburg',
    bic: 'GENODES1GHB',
    id: 3162,
  },
  {
    bankName: 'Raiffeisenbank Wimsheim-Mönsheim',
    bic: 'GENODES1WIM',
    id: 3163,
  },
  {
    bankName: 'Raiffeisenbank im Kreis Calw',
    bic: 'GENODES1RCW',
    id: 3164,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB659',
    id: 3165,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS659',
    id: 3166,
  },
  {
    bankName: 'Bankhaus Gebr. Martin',
    bic: 'MARBDE6GXXX',
    id: 3167,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3168,
  },
  {
    bankName: 'Kreissparkasse Göppingen',
    bic: 'GOPSDE6GXXX',
    id: 3169,
  },
  {
    bankName: 'Kreissparkasse Göppingen',
    bic: 'GOPSDE6G612',
    id: 3170,
  },
  {
    bankName: 'Volksbank Göppingen',
    bic: 'GENODES1VGP',
    id: 3171,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB610',
    id: 3172,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB627',
    id: 3173,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB618',
    id: 3174,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS610',
    id: 3175,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS618',
    id: 3176,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS627',
    id: 3177,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF610',
    id: 3178,
  },
  {
    bankName: 'Volksbank-Raiffeisenbank Deggingen',
    bic: 'GENODES1DGG',
    id: 3179,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM859',
    id: 3180,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3181,
  },
  {
    bankName: 'Kreissparkasse Esslingen-Nürtingen',
    bic: 'ESSLDE66XXX',
    id: 3182,
  },
  {
    bankName: 'Volksbank Filder',
    bic: 'GENODES1NHB',
    id: 3183,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB611',
    id: 3184,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB619',
    id: 3185,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB612',
    id: 3186,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB626',
    id: 3187,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS611',
    id: 3188,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS612',
    id: 3189,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS619',
    id: 3190,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS626',
    id: 3191,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF611',
    id: 3192,
  },
  {
    bankName: 'Volksbank Esslingen -alt-',
    bic: 'GENODES1ESS',
    id: 3193,
  },
  {
    bankName: 'Volksbank Plochingen',
    bic: 'GENODES1VBP',
    id: 3194,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3195,
  },
  {
    bankName: 'Raiffeisenbank Teck -alt-',
    bic: 'GENODES1TEC',
    id: 3196,
  },
  {
    bankName: 'VR Bank Hohenneuffen-Teck',
    bic: 'GENODES1HON',
    id: 3197,
  },
  {
    bankName: 'Genossenschaftsbank Wolfschlugen -alt-',
    bic: 'GENODES1WLF',
    id: 3198,
  },
  {
    bankName: 'Bernhauser Bank',
    bic: 'GENODES1BBF',
    id: 3199,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF612',
    id: 3200,
  },
  {
    bankName: 'Volksbank Mittlerer Neckar',
    bic: 'GENODES1NUE',
    id: 3201,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3202,
  },
  {
    bankName: 'Raiffeisenbank Rosenstein',
    bic: 'GENODES1HEU',
    id: 3203,
  },
  {
    bankName: 'Raiffeisenbank Mutlangen',
    bic: 'GENODES1RML',
    id: 3204,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB613',
    id: 3205,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB614',
    id: 3206,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB637',
    id: 3207,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB638',
    id: 3208,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB639',
    id: 3209,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB615',
    id: 3210,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB633',
    id: 3211,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB616',
    id: 3212,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS613',
    id: 3213,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS639',
    id: 3214,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS638',
    id: 3215,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS614',
    id: 3216,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS615',
    id: 3217,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS616',
    id: 3218,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS637',
    id: 3219,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS633',
    id: 3220,
  },
  {
    bankName: 'Volksbank Schwäbisch Gmünd -alt-',
    bic: 'GENODES1VGD',
    id: 3221,
  },
  {
    bankName: 'Volksbank Welzheim',
    bic: 'GENODES1WEL',
    id: 3222,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM272',
    id: 3223,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3224,
  },
  {
    bankName: 'Kreissparkasse Ostalb',
    bic: 'OASPDE6AXXX',
    id: 3225,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF614',
    id: 3226,
  },
  {
    bankName: 'VR-Bank Ostalb',
    bic: 'GENODES1AAV',
    id: 3227,
  },
  {
    bankName: 'VR-Bank Ellwangen',
    bic: 'GENODES1ELL',
    id: 3228,
  },
  {
    bankName: 'Hoerner-Bank',
    bic: 'HOEBDE61XXX',
    id: 3229,
  },
  {
    bankName: 'FCA Bank Deutschland',
    bic: 'FBHNDE61XXX',
    id: 3230,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3231,
  },
  {
    bankName: 'Kreissparkasse Heilbronn',
    bic: 'HEISDE66XXX',
    id: 3232,
  },
  {
    bankName: 'Volksbank Sulmtal',
    bic: 'GENODES1VOS',
    id: 3233,
  },
  {
    bankName: 'Volksbank Beilstein-Ilsfeld-Abstatt',
    bic: 'GENODES1BIA',
    id: 3234,
  },
  {
    bankName: 'Volksbank Flein-Talheim',
    bic: 'GENODES1VFT',
    id: 3235,
  },
  {
    bankName: 'VBU Volksbank im Unterland',
    bic: 'GENODES1VLS',
    id: 3236,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB620',
    id: 3237,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB628',
    id: 3238,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB621',
    id: 3239,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB629',
    id: 3240,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB622',
    id: 3241,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS620',
    id: 3242,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS621',
    id: 3243,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS629',
    id: 3244,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS622',
    id: 3245,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS628',
    id: 3246,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF620',
    id: 3247,
  },
  {
    bankName: 'Volksbank Heilbronn -alt-',
    bic: 'GENODES1VHN',
    id: 3248,
  },
  {
    bankName: 'Volksbank Brackenheim-Güglingen -alt-',
    bic: 'GENODES1VBR',
    id: 3249,
  },
  {
    bankName: 'Volksbank Möckmühl',
    bic: 'GENODES1VMN',
    id: 3250,
  },
  {
    bankName: 'Volksbank Hohenlohe',
    bic: 'GENODES1VHL',
    id: 3251,
  },
  {
    bankName: 'Bausparkasse Schwäbisch Hall',
    bic: 'BSHHDE61XXX',
    id: 3252,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3253,
  },
  {
    bankName: 'Sparkasse Schwäbisch Hall-Crailsheim',
    bic: 'SOLADES1SHA',
    id: 3254,
  },
  {
    bankName: 'Sparkasse Hohenlohekreis',
    bic: 'SOLADES1KUN',
    id: 3255,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF622',
    id: 3256,
  },
  {
    bankName: 'VR Bank Heilbronn Schwäbisch Hall',
    bic: 'GENODES1SHA',
    id: 3257,
  },
  {
    bankName: 'Crailsheimer Volksbank -alt-',
    bic: 'GENODES1CRV',
    id: 3258,
  },
  {
    bankName: 'Volksbank Vorbach-Tauber -alt-',
    bic: 'GENODES1VVT',
    id: 3259,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1630',
    id: 3260,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM461',
    id: 3261,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM461',
    id: 3262,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3263,
  },
  {
    bankName: 'Sparkasse Ulm',
    bic: 'SOLADES1ULM',
    id: 3264,
  },
  {
    bankName: 'VR-Bank Langenau-Ulmer Alb',
    bic: 'GENODES1LBK',
    id: 3265,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB630',
    id: 3266,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB631',
    id: 3267,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB632',
    id: 3268,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS630',
    id: 3269,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS631',
    id: 3270,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS632',
    id: 3271,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF630',
    id: 3272,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFI59',
    id: 3273,
  },
  {
    bankName: 'Volksbank Ulm-Biberach',
    bic: 'ULMVDE66XXX',
    id: 3274,
  },
  {
    bankName: 'Donau-Iller Bank',
    bic: 'GENODES1EHI',
    id: 3275,
  },
  {
    bankName: 'Volksbank Blaubeuren -alt-',
    bic: 'GENODES1BLA',
    id: 3276,
  },
  {
    bankName: 'Volksbank Laichinger Alb',
    bic: 'GENODES1LAI',
    id: 3277,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM271',
    id: 3278,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3279,
  },
  {
    bankName: 'Kreissparkasse Heidenheim',
    bic: 'SOLADES1HDH',
    id: 3280,
  },
  {
    bankName: 'Heidenheimer Volksbank',
    bic: 'GENODES1HDH',
    id: 3281,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1640',
    id: 3282,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM374',
    id: 3283,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3284,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3285,
  },
  {
    bankName: 'Kreissparkasse Reutlingen',
    bic: 'SOLADES1REU',
    id: 3286,
  },
  {
    bankName: 'VR Bank Tübingen',
    bic: 'GENODES1STW',
    id: 3287,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB640',
    id: 3288,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB646',
    id: 3289,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB634',
    id: 3290,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB643',
    id: 3291,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB636',
    id: 3292,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB645',
    id: 3293,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB641',
    id: 3294,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS640',
    id: 3295,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS642',
    id: 3296,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS641',
    id: 3297,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS644',
    id: 3298,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS645',
    id: 3299,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS643',
    id: 3300,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS646',
    id: 3301,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDESSP14',
    id: 3302,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP14',
    id: 3303,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF640',
    id: 3304,
  },
  {
    bankName: 'Volksbank Reutlingen -alt-',
    bic: 'VBRTDE6RXXX',
    id: 3305,
  },
  {
    bankName: 'Volksbank Ermstal-Alb',
    bic: 'GENODES1MTZ',
    id: 3306,
  },
  {
    bankName: 'Volksbank Münsingen',
    bic: 'GENODES1MUN',
    id: 3307,
  },
  {
    bankName: 'Commerzbank Tübingen',
    bic: 'COBADEFFXXX',
    id: 3308,
  },
  {
    bankName: 'Kreissparkasse Tübingen',
    bic: 'SOLADES1TUB',
    id: 3309,
  },
  {
    bankName: 'Volksbank Ammerbuch',
    bic: 'GENODES1AMM',
    id: 3310,
  },
  {
    bankName: 'Raiffeisenbank Härten -alt-',
    bic: 'GENODES1RHK',
    id: 3311,
  },
  {
    bankName: 'Volksbank Mössingen -alt-',
    bic: 'GENODES1VMO',
    id: 3312,
  },
  {
    bankName: 'Volksbank Hohenzollern-Balingen',
    bic: 'GENODES1VHZ',
    id: 3313,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF641',
    id: 3314,
  },
  {
    bankName: 'Volksbank Tübingen -alt-',
    bic: 'GENODES1TUE',
    id: 3315,
  },
  {
    bankName: 'Volksbank Nagoldtal -alt-',
    bic: 'GENODES1NAG',
    id: 3316,
  },
  {
    bankName: 'Volksbank Horb -alt-',
    bic: 'GENODES1HOR',
    id: 3317,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3318,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3319,
  },
  {
    bankName: 'Kreissparkasse Rottweil',
    bic: 'SOLADES1RWL',
    id: 3320,
  },
  {
    bankName: 'Kreissparkasse Freudenstadt',
    bic: 'SOLADES1FDS',
    id: 3321,
  },
  {
    bankName: 'Volksbank Baiersbronn Murgtal -alt-',
    bic: 'GENODES1BAI',
    id: 3322,
  },
  {
    bankName: 'Murgtalbank Mitteltal - Obertal -alt-',
    bic: 'GENODES1MMO',
    id: 3323,
  },
  {
    bankName: 'Volksbank Nordschwarzwald',
    bic: 'GENODES1PGW',
    id: 3324,
  },
  {
    bankName: 'VR-Bank Dornstetten-Horb',
    bic: 'GENODES1VDS',
    id: 3325,
  },
  {
    bankName: 'Volksbank Rottweil',
    bic: 'GENODES1VRW',
    id: 3326,
  },
  {
    bankName: 'Volksbank im Kreis Freudenstadt',
    bic: 'GENODES1FDS',
    id: 3327,
  },
  {
    bankName: 'Volksbank Deisslingen',
    bic: 'GENODES1VDL',
    id: 3328,
  },
  {
    bankName: 'Volksbank Schwarzwald-Neckar -alt-',
    bic: 'GENODES1SBG',
    id: 3329,
  },
  {
    bankName: 'Volksbank Trossingen',
    bic: 'GENODES1TRO',
    id: 3330,
  },
  {
    bankName: 'Kreissparkasse Tuttlingen',
    bic: 'SOLADES1TUT',
    id: 3331,
  },
  {
    bankName: 'Raiffeisenbank Donau-Heuberg',
    bic: 'GENODES1RDH',
    id: 3332,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF643',
    id: 3333,
  },
  {
    bankName: 'Volksbank Schwarzwald-Donau-Neckar',
    bic: 'GENODES1TUT',
    id: 3334,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM588',
    id: 3335,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3336,
  },
  {
    bankName: 'Kreissparkasse Ravensburg',
    bic: 'SOLADES1RVB',
    id: 3337,
  },
  {
    bankName: 'Raiffeisenbank Aulendorf -alt-',
    bic: 'GENODES1AUL',
    id: 3338,
  },
  {
    bankName: 'VR Bank Ravensburg-Weingarten',
    bic: 'GENODES1RRV',
    id: 3339,
  },
  {
    bankName: 'Raiffeisenbank Bad Saulgau',
    bic: 'GENODES1SAG',
    id: 3340,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB650',
    id: 3341,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB651',
    id: 3342,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB652',
    id: 3343,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB654',
    id: 3344,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB649',
    id: 3345,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB657',
    id: 3346,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB658',
    id: 3347,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS650',
    id: 3348,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS651',
    id: 3349,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS658',
    id: 3350,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS657',
    id: 3351,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS654',
    id: 3352,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS652',
    id: 3353,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS649',
    id: 3354,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF650',
    id: 3355,
  },
  {
    bankName: 'Volksbank Ulm-Biberach -alt-',
    bic: 'ULMVDE66XXX',
    id: 3356,
  },
  {
    bankName: 'Volksbank Allgäu-Oberschwaben',
    bic: 'GENODES1LEU',
    id: 3357,
  },
  {
    bankName: 'Bad Waldseer Bank -alt-',
    bic: 'GENODES1BWB',
    id: 3358,
  },
  {
    bankName: 'Volksbank Weingarten -alt-',
    bic: 'GENODES1VWG',
    id: 3359,
  },
  {
    bankName: 'Volksbank Allgäu-West -alt-',
    bic: 'GENODES1WAN',
    id: 3360,
  },
  {
    bankName: 'Volksbank Altshausen',
    bic: 'GENODES1VAH',
    id: 3361,
  },
  {
    bankName: 'Volksbank Bad Saulgau',
    bic: 'GENODES1SLG',
    id: 3362,
  },
  {
    bankName: 'Internationales Bankhaus Bodensee',
    bic: 'IBBFDE81XXX',
    id: 3363,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3364,
  },
  {
    bankName: 'Genossenschaftsbank Meckenbeuren -alt-',
    bic: 'GENODES1GMB',
    id: 3365,
  },
  {
    bankName: 'Raiffeisenbank Oberteuringen-Meckenbeuren',
    bic: 'GENODES1OTE',
    id: 3366,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF651',
    id: 3367,
  },
  {
    bankName: 'Volksbank Friedrichshafen -alt-',
    bic: 'GENODES1VFN',
    id: 3368,
  },
  {
    bankName: 'Volksbank Friedrichshafen-Tettnang',
    bic: 'GENODES1TET',
    id: 3369,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3370,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3371,
  },
  {
    bankName: 'Hohenz Landesbank Kreissparkasse Sigmaringen',
    bic: 'SOLADES1SIG',
    id: 3372,
  },
  {
    bankName: 'Sparkasse Zollernalb',
    bic: 'SOLADES1BAL',
    id: 3373,
  },
  {
    bankName: 'Volksbank Heuberg -alt-',
    bic: 'GENODES1HBM',
    id: 3374,
  },
  {
    bankName: 'VR Bank Heuberg-Winterlingen',
    bic: 'GENODES1WLB',
    id: 3375,
  },
  {
    bankName: 'Onstmettinger Bank',
    bic: 'GENODES1ONS',
    id: 3376,
  },
  {
    bankName: 'Raiffeisenbank Geislingen-Rosenfeld',
    bic: 'GENODES1GEI',
    id: 3377,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB653',
    id: 3378,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB655',
    id: 3379,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB603',
    id: 3380,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS653',
    id: 3381,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS655',
    id: 3382,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESS603',
    id: 3383,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF653',
    id: 3384,
  },
  {
    bankName: 'Volksbank Albstadt',
    bic: 'GENODES1EBI',
    id: 3385,
  },
  {
    bankName: 'Volksbank Balingen -alt-',
    bic: 'GENODES1BAL',
    id: 3386,
  },
  {
    bankName: 'Volksbank Tailfingen -alt-',
    bic: 'GENODES1TAI',
    id: 3387,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3388,
  },
  {
    bankName: 'Kreissparkasse Biberach',
    bic: 'SBCRDE66XXX',
    id: 3389,
  },
  {
    bankName: 'Raiffeisenbank Biberach',
    bic: 'GENODES1WAR',
    id: 3390,
  },
  {
    bankName: 'Raiffeisenbank Illertal -alt-',
    bic: 'GENODES1ERO',
    id: 3391,
  },
  {
    bankName: 'Volksbank Raiffeisenbank Laupheim-Illertal',
    bic: 'GENODES1VBL',
    id: 3392,
  },
  {
    bankName: 'VR Bank Riedlingen-Federsee',
    bic: 'GENODES1VRR',
    id: 3393,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1660',
    id: 3394,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 3395,
  },
  {
    bankName: 'Deutsche Bausparkasse Badenia',
    bic: 'BBSPDE6KXXX',
    id: 3396,
  },
  {
    bankName: 'Landeskreditbank Baden-Württemberg Förderbank',
    bic: 'LKBWDE6KXXX',
    id: 3397,
  },
  {
    bankName: 'Baden-Württembergische Bank',
    bic: 'SOLADEST663',
    id: 3398,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM475',
    id: 3399,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM475',
    id: 3400,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33KRL',
    id: 3401,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33KRL',
    id: 3402,
  },
  {
    bankName: 'Isbank eh Filiale Mannheim',
    bic: 'ISBKDEFXXXX',
    id: 3403,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3404,
  },
  {
    bankName: 'Commerzbank/Kreditcenter Badenia',
    bic: 'COBADEFFXXX',
    id: 3405,
  },
  {
    bankName: 'Landesbank Baden-Württemberg',
    bic: 'SOLADEST660',
    id: 3406,
  },
  {
    bankName: 'Sparkasse Karlsruhe',
    bic: 'KARSDE66XXX',
    id: 3407,
  },
  {
    bankName: 'Sparkasse Ettlingen -alt-',
    bic: 'SOLADES1ETT',
    id: 3408,
  },
  {
    bankName: 'DZ BANK',
    bic: 'GENODE6KXXX',
    id: 3409,
  },
  {
    bankName: 'Spar- und Kreditbank -alt-',
    bic: 'GENODE61KA3',
    id: 3410,
  },
  {
    bankName: 'Spar- und Kreditbank',
    bic: 'GENODE61RH2',
    id: 3411,
  },
  {
    bankName: 'Volksbank Stutensee-Weingarten -alt-',
    bic: 'GENODE61WGA',
    id: 3412,
  },
  {
    bankName: 'Spar- und Kreditbank Hardt -alt-',
    bic: 'GENODE61EGG',
    id: 3413,
  },
  {
    bankName: 'Raiffeisenbank Hardt-Bruhrain',
    bic: 'GENODE61DET',
    id: 3414,
  },
  {
    bankName: 'Raiffeisenbank Elztal',
    bic: 'GENODE61ELZ',
    id: 3415,
  },
  {
    bankName: 'Spar- und Kreditbank -alt-',
    bic: 'GENODE61DAC',
    id: 3416,
  },
  {
    bankName: 'Volksbank Krautheim',
    bic: 'GENODE61KTH',
    id: 3417,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM660',
    id: 3418,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM661',
    id: 3419,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM663',
    id: 3420,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM664',
    id: 3421,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB660',
    id: 3422,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB663',
    id: 3423,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB661',
    id: 3424,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB664',
    id: 3425,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDESMP12',
    id: 3426,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP12',
    id: 3427,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF660',
    id: 3428,
  },
  {
    bankName: 'BBBank',
    bic: 'GENODE61BBB',
    id: 3429,
  },
  {
    bankName: 'PSD Bank Karlsruhe-Neustadt',
    bic: 'GENODEF1P10',
    id: 3430,
  },
  {
    bankName: 'Volksbank Ettlingen',
    bic: 'GENODE61ETT',
    id: 3431,
  },
  {
    bankName: 'Volksbank Karlsruhe Baden-Baden',
    bic: 'GENODE61KA1',
    id: 3432,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3433,
  },
  {
    bankName: 'Sparkasse Baden-Baden Gaggenau',
    bic: 'SOLADES1BAD',
    id: 3434,
  },
  {
    bankName: 'Sparkasse Bühl',
    bic: 'SOLADES1BHL',
    id: 3435,
  },
  {
    bankName: 'Spar- und Kreditbank',
    bic: 'GENODE61BHT',
    id: 3436,
  },
  {
    bankName: 'Raiffeisenbank Altschweier',
    bic: 'GENODE61ALR',
    id: 3437,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM662',
    id: 3438,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM667',
    id: 3439,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM671',
    id: 3440,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM665',
    id: 3441,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM669',
    id: 3442,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB662',
    id: 3443,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB669',
    id: 3444,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB671',
    id: 3445,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB667',
    id: 3446,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB665',
    id: 3447,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF662',
    id: 3448,
  },
  {
    bankName: 'Volksbank Baden-Baden Rastatt -alt-',
    bic: 'VBRADE6KXXX',
    id: 3449,
  },
  {
    bankName: 'Volksbank Achern -alt-',
    bic: 'GENODE61ACH',
    id: 3450,
  },
  {
    bankName: 'Volksbank Bühl',
    bic: 'GENODE61BHL',
    id: 3451,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3452,
  },
  {
    bankName: 'Sparkasse Kraichgau Bruchsal-Bretten-Sinsheim',
    bic: 'BRUSDE66XXX',
    id: 3453,
  },
  {
    bankName: 'Volksbank Bruchsal-Bretten',
    bic: 'GENODE61BTT',
    id: 3454,
  },
  {
    bankName: 'Volksbank Bruhrain-Kraich-Hardt -alt-',
    bic: 'GENODE61ORH',
    id: 3455,
  },
  {
    bankName: 'Bankhaus J. Faißt',
    bic: 'FAITDE66XXX',
    id: 3456,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3457,
  },
  {
    bankName: 'Sparkasse Offenburg/Ortenau',
    bic: 'SOLADES1OFG',
    id: 3458,
  },
  {
    bankName: 'Sparkasse Gengenbach -alt-',
    bic: 'SOLADES1GEB',
    id: 3459,
  },
  {
    bankName: 'Sparkasse Kinzigtal',
    bic: 'SOLADES1HAL',
    id: 3460,
  },
  {
    bankName: 'Sparkasse Hanauerland',
    bic: 'SOLADES1KEL',
    id: 3461,
  },
  {
    bankName: 'Sparkasse Wolfach',
    bic: 'SOLADES1WOF',
    id: 3462,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB968',
    id: 3463,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB970',
    id: 3464,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB969',
    id: 3465,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB971',
    id: 3466,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F664',
    id: 3467,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F665',
    id: 3468,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F666',
    id: 3469,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F667',
    id: 3470,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODE61OG1',
    id: 3471,
  },
  {
    bankName: 'Volksbank Bühl Fil Kehl',
    bic: 'GENODE61BHL',
    id: 3472,
  },
  {
    bankName: 'Volksbank Appenweier-Urloffen Appenweier -alt-',
    bic: 'GENODE61APP',
    id: 3473,
  },
  {
    bankName: 'Volksbank Mittlerer Schwarzwald',
    bic: 'GENODE61KZT',
    id: 3474,
  },
  {
    bankName: 'Sparkasse Rastatt-Gernsbach',
    bic: 'SOLADES1RAS',
    id: 3475,
  },
  {
    bankName: 'Raiffeisenbank Südhardt Durmersheim',
    bic: 'GENODE61DUR',
    id: 3476,
  },
  {
    bankName: 'VR-Bank in Mittelbaden',
    bic: 'GENODE61IFF',
    id: 3477,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3478,
  },
  {
    bankName: 'Sparkasse Pforzheim Calw',
    bic: 'PZHSDE66XXX',
    id: 3479,
  },
  {
    bankName: 'Raiffeisenbank Bauschlott -alt-',
    bic: 'GENODE61NBT',
    id: 3480,
  },
  {
    bankName: 'Raiffeisenbank Kieselbronn',
    bic: 'GENODE61KBR',
    id: 3481,
  },
  {
    bankName: 'VR Bank im Enzkreis -alt-',
    bic: 'GENODE61NFO',
    id: 3482,
  },
  {
    bankName: 'Raiffeisenbank Ersingen',
    bic: 'GENODE61ERS',
    id: 3483,
  },
  {
    bankName: 'Volksbank Stein Eisingen -alt-',
    bic: 'GENODE61KBS',
    id: 3484,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM666',
    id: 3485,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM677',
    id: 3486,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB666',
    id: 3487,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB677',
    id: 3488,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF666',
    id: 3489,
  },
  {
    bankName: 'Volksbank Pforzheim',
    bic: 'VBPFDE66XXX',
    id: 3490,
  },
  {
    bankName: 'VR Bank Enz plus',
    bic: 'GENODE61WIR',
    id: 3491,
  },
  {
    bankName: 'Raiffeisenbank Kraichgau -alt-',
    bic: 'GENODE61KIR',
    id: 3492,
  },
  {
    bankName: 'Raiffeisenbank Neudenau-Stein-Herbolzheim -alt-',
    bic: 'GENODE61NEU',
    id: 3493,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM489',
    id: 3494,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3495,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 3496,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 3497,
  },
  {
    bankName: 'Commerzbank, Gf Web-K',
    bic: 'COBADEFFXXX',
    id: 3498,
  },
  {
    bankName: 'Sparkasse Rhein Neckar Nord',
    bic: 'MANSDE66XXX',
    id: 3499,
  },
  {
    bankName: 'Sparkasse Hockenheim',
    bic: 'SOLADES1HOC',
    id: 3500,
  },
  {
    bankName: 'Volksbank Sandhofen',
    bic: 'GENODE61MA3',
    id: 3501,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESMXXX',
    id: 3502,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM676',
    id: 3503,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM675',
    id: 3504,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM673',
    id: 3505,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM670',
    id: 3506,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBMAN',
    id: 3507,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB676',
    id: 3508,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB673',
    id: 3509,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB675',
    id: 3510,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB670',
    id: 3511,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF670',
    id: 3512,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
    bic: 'DRESDEFFI60',
    id: 3513,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 3',
    bic: 'DRESDEFFI61',
    id: 3514,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI22',
    id: 3515,
  },
  {
    bankName: 'VR Bank Rhein-Neckar',
    bic: 'GENODE61MA2',
    id: 3516,
  },
  {
    bankName: 'Volksbank Kurpfalz',
    bic: 'GENODE61WNM',
    id: 3517,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM479',
    id: 3518,
  },
  {
    bankName: 'MLP Banking',
    bic: 'MLPBDE61XXX',
    id: 3519,
  },
  {
    bankName: 'MLP Banking Zw CS',
    bic: 'MLPBDE61001',
    id: 3520,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3521,
  },
  {
    bankName: 'Sparkasse Heidelberg',
    bic: 'SOLADES1HDB',
    id: 3522,
  },
  {
    bankName: 'Raiffeisenbank Baiertal',
    bic: 'GENODE61WIB',
    id: 3523,
  },
  {
    bankName: 'Volksbank Rot',
    bic: 'GENODE61LRO',
    id: 3524,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM672',
    id: 3525,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM674',
    id: 3526,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDESM678',
    id: 3527,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB672',
    id: 3528,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB674',
    id: 3529,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB678',
    id: 3530,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF672',
    id: 3531,
  },
  {
    bankName: 'Heidelberger Volksbank',
    bic: 'GENODE61HD1',
    id: 3532,
  },
  {
    bankName: 'Volksbank Kurpfalz -alt-',
    bic: 'GENODE61HD3',
    id: 3533,
  },
  {
    bankName: 'Volksbank Neckartal',
    bic: 'GENODE61NGD',
    id: 3534,
  },
  {
    bankName: 'Volksbank Kraichgau -alt-',
    bic: 'GENODE61SSH',
    id: 3535,
  },
  {
    bankName: 'Volksbank Kraichgau',
    bic: 'GENODE61WIE',
    id: 3536,
  },
  {
    bankName: 'Sparkasse Tauberfranken',
    bic: 'SOLADES1TBB',
    id: 3537,
  },
  {
    bankName: 'Volksbank Main-Tauber',
    bic: 'GENODE61WTH',
    id: 3538,
  },
  {
    bankName: 'Sparkasse Neckartal-Odenwald',
    bic: 'SOLADES1MOS',
    id: 3539,
  },
  {
    bankName: 'Volksbank Mosbach',
    bic: 'GENODE61MOS',
    id: 3540,
  },
  {
    bankName: 'Volksbank Franken',
    bic: 'GENODE61BUC',
    id: 3541,
  },
  {
    bankName: 'Volksbank Kirnau',
    bic: 'GENODE61RNG',
    id: 3542,
  },
  {
    bankName: 'Volksbank Limbach',
    bic: 'GENODE61LMB',
    id: 3543,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1680',
    id: 3544,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM357',
    id: 3545,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM357',
    id: 3546,
  },
  {
    bankName: 'Bankhaus E. Mayer',
    bic: 'BKMADE61XXX',
    id: 3547,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3548,
  },
  {
    bankName: 'Sparkasse Freiburg-Nördlicher Breisgau',
    bic: 'FRSPDE66XXX',
    id: 3549,
  },
  {
    bankName: 'Sparkasse Hochschwarzwald',
    bic: 'SOLADES1HSW',
    id: 3550,
  },
  {
    bankName: 'Sparkasse Bonndorf-Stühlingen',
    bic: 'SOLADES1BND',
    id: 3551,
  },
  {
    bankName: 'Sparkasse St. Blasien',
    bic: 'SOLADES1STB',
    id: 3552,
  },
  {
    bankName: 'Sparkasse Staufen-Breisach',
    bic: 'SOLADES1STF',
    id: 3553,
  },
  {
    bankName: 'Sparkasse Schönau-Todtnau -alt-',
    bic: 'SOLADES1SCH',
    id: 3554,
  },
  {
    bankName: 'Volksbank Breisgau-Markgräflerland',
    bic: 'GENODE61IHR',
    id: 3555,
  },
  {
    bankName: 'Raiffeisenbank Denzlingen-Sexau',
    bic: 'GENODE61DEN',
    id: 3556,
  },
  {
    bankName: 'Raiffeisenbank Wyhl',
    bic: 'GENODE61WYH',
    id: 3557,
  },
  {
    bankName: 'Raiffeisenbank Kaiserstuhl',
    bic: 'GENODE61VOK',
    id: 3558,
  },
  {
    bankName: 'Raiffeisenbank im Breisgau',
    bic: 'GENODE61GUN',
    id: 3559,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBFRE',
    id: 3560,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB681',
    id: 3561,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB685',
    id: 3562,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB689',
    id: 3563,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB687',
    id: 3564,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6FXXX',
    id: 3565,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F681',
    id: 3566,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F685',
    id: 3567,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F687',
    id: 3568,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F689',
    id: 3569,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDE6FP11',
    id: 3570,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP11',
    id: 3571,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF680',
    id: 3572,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw Münsterstraße',
    bic: 'DRESDEFFI44',
    id: 3573,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFI62',
    id: 3574,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
    bic: 'DRESDEFFJ22',
    id: 3575,
  },
  {
    bankName: 'Volksbank Freiburg',
    bic: 'GENODE61FR1',
    id: 3576,
  },
  {
    bankName: 'PSD Bank RheinNeckarSaar',
    bic: 'GENODEF1P07',
    id: 3577,
  },
  {
    bankName: 'Volksbank Müllheim -alt-',
    bic: 'GENODE61MHL',
    id: 3578,
  },
  {
    bankName: 'Volksbank Breisgau Nord',
    bic: 'GENODE61EMM',
    id: 3579,
  },
  {
    bankName: 'Volksbank Staufen',
    bic: 'GENODE61STF',
    id: 3580,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB682',
    id: 3581,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F682',
    id: 3582,
  },
  {
    bankName: 'Volksbank Lahr',
    bic: 'GENODE61LAH',
    id: 3583,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3584,
  },
  {
    bankName: 'Sparkasse Lörrach-Rheinfelden',
    bic: 'SKLODE66XXX',
    id: 3585,
  },
  {
    bankName: 'Sparkasse Wiesental',
    bic: 'SOLADES1SFH',
    id: 3586,
  },
  {
    bankName: 'Sparkasse Markgräflerland',
    bic: 'SOLADES1MGL',
    id: 3587,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB683',
    id: 3588,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB684',
    id: 3589,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB688',
    id: 3590,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB686',
    id: 3591,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB693',
    id: 3592,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB680',
    id: 3593,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB679',
    id: 3594,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F683',
    id: 3595,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F688',
    id: 3596,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F693',
    id: 3597,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F684',
    id: 3598,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F686',
    id: 3599,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F679',
    id: 3600,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F678',
    id: 3601,
  },
  {
    bankName: 'Volksbank Dreiländereck',
    bic: 'VOLODE66XXX',
    id: 3602,
  },
  {
    bankName: 'VR Bank -alt-',
    bic: 'GENODE61SPF',
    id: 3603,
  },
  {
    bankName: 'Sparkasse Hochrhein',
    bic: 'SKHRDE6WXXX',
    id: 3604,
  },
  {
    bankName: 'Volksbank Klettgau-Wutöschingen',
    bic: 'GENODE61WUT',
    id: 3605,
  },
  {
    bankName: 'Volksbank Rhein-Wehra',
    bic: 'GENODE61BSK',
    id: 3606,
  },
  {
    bankName: 'Volksbank Hochrhein',
    bic: 'GENODE61WT1',
    id: 3607,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM591',
    id: 3608,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3609,
  },
  {
    bankName: 'Sparkasse Bodensee',
    bic: 'SOLADES1KNZ',
    id: 3610,
  },
  {
    bankName: 'Bezirkssparkasse Reichenau',
    bic: 'SOLADES1REN',
    id: 3611,
  },
  {
    bankName: 'Sparkasse Pfullendorf-Meßkirch',
    bic: 'SOLADES1PFD',
    id: 3612,
  },
  {
    bankName: 'Sparkasse Salem-Heiligenberg',
    bic: 'SOLADES1SAL',
    id: 3613,
  },
  {
    bankName: 'Volksbank Überlingen',
    bic: 'GENODE61UBE',
    id: 3614,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB690',
    id: 3615,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB691',
    id: 3616,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F690',
    id: 3617,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F691',
    id: 3618,
  },
  {
    bankName: 'Hagnauer Volksbank',
    bic: 'GENODE61HAG',
    id: 3619,
  },
  {
    bankName: 'Volksbank Pfullendorf',
    bic: 'GENODE61PFD',
    id: 3620,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM590',
    id: 3621,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3622,
  },
  {
    bankName: 'Sparkasse Hegau-Bodensee',
    bic: 'SOLADES1SNG',
    id: 3623,
  },
  {
    bankName: 'Sparkasse Engen-Gottmadingen',
    bic: 'SOLADES1ENG',
    id: 3624,
  },
  {
    bankName: 'Sparkasse Stockach -alt-',
    bic: 'SOLADES1STO',
    id: 3625,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB692',
    id: 3626,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB696',
    id: 3627,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F692',
    id: 3628,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F696',
    id: 3629,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF692',
    id: 3630,
  },
  {
    bankName: 'Volksbank Hegau -alt-',
    bic: 'GENODE61SIN',
    id: 3631,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODE61RAD',
    id: 3632,
  },
  {
    bankName: 'Volksbank Gf GA',
    bic: 'GENODE61RAD',
    id: 3633,
  },
  {
    bankName: 'Volksbank Meßkirch Raiffeisenbank',
    bic: 'GENODE61MES',
    id: 3634,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1694',
    id: 3635,
  },
  {
    bankName: 'Commerzbank Villingen u Schwenningen',
    bic: 'COBADEFFXXX',
    id: 3636,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 3637,
  },
  {
    bankName: 'Sparkasse Schwarzwald-Baar',
    bic: 'SOLADES1VSS',
    id: 3638,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB694',
    id: 3639,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB698',
    id: 3640,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB642',
    id: 3641,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB699',
    id: 3642,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB644',
    id: 3643,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB697',
    id: 3644,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F694',
    id: 3645,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F697',
    id: 3646,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F642',
    id: 3647,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F699',
    id: 3648,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F644',
    id: 3649,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE6F698',
    id: 3650,
  },
  {
    bankName: 'Volksbank Schwarzwald Baar Hegau -alt-',
    bic: 'GENODE61VS1',
    id: 3651,
  },
  {
    bankName: 'Volksbank Triberg -alt-',
    bic: 'GENODE61TRI',
    id: 3652,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1700',
    id: 3653,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 3654,
  },
  {
    bankName: 'Aareal Bank',
    bic: 'AARBDE5W700',
    id: 3655,
  },
  {
    bankName: 'Deutsche Pfandbriefbank',
    bic: 'REBMDEMMXXX',
    id: 3656,
  },
  {
    bankName: 'Deutsche Pfandbriefbank',
    bic: 'REBMDEMM555',
    id: 3657,
  },
  {
    bankName: 'Deutsche Pfandbriefbank',
    bic: 'REBMDE7CXXX',
    id: 3658,
  },
  {
    bankName: 'Middle East Bank, Munich Branch',
    bic: 'KHMIDEMMXXX',
    id: 3659,
  },
  {
    bankName: 'Deutsche Handelsbank',
    bic: 'DEKTDE7GXXX',
    id: 3660,
  },
  {
    bankName: 'Deutsche Handelsbank',
    bic: 'DEKTDE7GXXX',
    id: 3661,
  },
  {
    bankName: 'Bank Vontobel Europe',
    bic: 'VONTDEM1XXX',
    id: 3662,
  },
  {
    bankName: 'Autobank',
    bic: 'AUZDDEM1XXX',
    id: 3663,
  },
  {
    bankName: 'BfW - Bank für Wohnungswirtschaft',
    bic: 'BFWODE71XXX',
    id: 3664,
  },
  {
    bankName: 'SIEMENS BANK',
    bic: 'SIBADEMMXXX',
    id: 3665,
  },
  {
    bankName: 'TEN31 Bank',
    bic: 'WEGBDE77XXX',
    id: 3666,
  },
  {
    bankName: 'Bankhaus von der Heydt',
    bic: 'BVDHDEMMXXX',
    id: 3667,
  },
  {
    bankName: 'InterCard',
    bic: 'ICRDDE71XXX',
    id: 3668,
  },
  {
    bankName: 'UniCredit Family Financing Bank, Ndl der UniCredit',
    bic: 'CLABDEM1XXX',
    id: 3669,
  },
  {
    bankName: 'V-Bank',
    bic: 'VBANDEMMXXX',
    id: 3670,
  },
  {
    bankName: 'Bankhaus Obotritia',
    bic: 'FLGMDE77XXX',
    id: 3671,
  },
  {
    bankName: 'Südtiroler Sparkasse Niederlassung München',
    bic: 'SUSKDEM1XXX',
    id: 3672,
  },
  {
    bankName: 'VZ Depotbank Deutschland',
    bic: 'DEPDDEMMXXX',
    id: 3673,
  },
  {
    bankName: 'IBAN FIRST, Zweigniederlassung München',
    bic: 'FXBBDEM2XXX',
    id: 3674,
  },
  {
    bankName: 'European Bank for Financial Services',
    bic: 'EBSGDEMXXXX',
    id: 3675,
  },
  {
    bankName: 'European Bank for Financial Services',
    bic: 'EFSGDEM1XXX',
    id: 3676,
  },
  {
    bankName: 'net-m privatbank 1891',
    bic: 'BVWBDE2WXXX',
    id: 3677,
  },
  {
    bankName: 'net-m privatbank 1891',
    bic: 'BVWBDE2WXXX',
    id: 3678,
  },
  {
    bankName: 'IC Cash Services',
    bic: 'CSHHDE71XXX',
    id: 3679,
  },
  {
    bankName: 'Bankhaus Herzogpark',
    bic: 'HERZDEM1XXX',
    id: 3680,
  },
  {
    bankName: 'transact Elektronische Zahlungssysteme',
    bic: 'TEZGDEB1XXX',
    id: 3681,
  },
  {
    bankName: 'transact Elektronische Zahlungssysteme',
    bic: 'TEZGDEB1001',
    id: 3682,
  },
  {
    bankName: 'transact Elektronische Zahlungssysteme',
    bic: 'TEZGDEB1002',
    id: 3683,
  },
  {
    bankName: 'transact Elektronische Zahlungssysteme',
    bic: 'TEZGDEB1003',
    id: 3684,
  },
  {
    bankName: 'PayCenter',
    bic: 'PAGMDEM1XXX',
    id: 3685,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3686,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3687,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3688,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3689,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3690,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3691,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3692,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3693,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3694,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3695,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3696,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3697,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3698,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3699,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3700,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3701,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3702,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3703,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3704,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3705,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3706,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3707,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3708,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3709,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMMXXX',
    id: 3710,
  },
  {
    bankName: 'BNP Paribas NL Deutschland',
    bic: 'WKVBDEM1XXX',
    id: 3711,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33MUE',
    id: 3712,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33MUE',
    id: 3713,
  },
  {
    bankName: 'INTESA SANPAOLO',
    bic: 'BCITDEFFMUC',
    id: 3714,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM418',
    id: 3715,
  },
  {
    bankName: 'Fidor Bank',
    bic: 'FDDODEMMXXX',
    id: 3716,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM643',
    id: 3717,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM643',
    id: 3718,
  },
  {
    bankName: 'Fürst Fugger Privatbank',
    bic: 'FUBKDE71MUC',
    id: 3719,
  },
  {
    bankName: 'Bankhaus Reuschel & Co -alt-',
    bic: 'CHDBDEHHXXX',
    id: 3720,
  },
  {
    bankName: 'Merck Finck A Quintet Private Bank',
    bic: 'MEFIDEMMXXX',
    id: 3721,
  },
  {
    bankName: 'Bankhaus Ludwig Sperrer',
    bic: 'BHLSDEM1XXX',
    id: 3722,
  },
  {
    bankName: 'St.Galler Kantonalbank Deutschland',
    bic: 'GAKDDEM1XXX',
    id: 3723,
  },
  {
    bankName: 'Baader Bank',
    bic: 'BDWBDEMMXXX',
    id: 3724,
  },
  {
    bankName: 'Oldenburgische Landesbank',
    bic: 'OLBODEH2700',
    id: 3725,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3726,
  },
  {
    bankName: 'Commerzbank, Filiale München 2',
    bic: 'COBADEFFXXX',
    id: 3727,
  },
  {
    bankName: 'Commerzbank GF-M48',
    bic: 'COBADEFFXXX',
    id: 3728,
  },
  {
    bankName: 'Commerzbank Gf 860',
    bic: 'COBADEFFXXX',
    id: 3729,
  },
  {
    bankName: 'Commerzbank Gf 861',
    bic: 'COBADEFFXXX',
    id: 3730,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 3731,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 3732,
  },
  {
    bankName: 'Commerzbank, CC SP',
    bic: 'COBADEFFXXX',
    id: 3733,
  },
  {
    bankName: 'Commerzbank Service-BZ',
    bic: 'COBADEFFXXX',
    id: 3734,
  },
  {
    bankName: 'Bayerische Landesbank',
    bic: 'BYLADEMMXXX',
    id: 3735,
  },
  {
    bankName: 'Sparkasse Freising',
    bic: 'BYLADEM1FSI',
    id: 3736,
  },
  {
    bankName: 'Sparkasse Dachau',
    bic: 'BYLADEM1DAH',
    id: 3737,
  },
  {
    bankName: 'Kreis- und Stadtsparkasse Erding-Dorfen',
    bic: 'BYLADEM1ERD',
    id: 3738,
  },
  {
    bankName: 'Sparkasse Landsberg-Dießen',
    bic: 'BYLADEM1LLD',
    id: 3739,
  },
  {
    bankName: 'Sparkasse Fürstenfeldbruck',
    bic: 'BYLADEM1FFB',
    id: 3740,
  },
  {
    bankName: 'Sparkasse Bad Tölz-Wolfratshausen',
    bic: 'BYLADEM1WOR',
    id: 3741,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMMXXX',
    id: 3742,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM705',
    id: 3743,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM713',
    id: 3744,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM701',
    id: 3745,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM708',
    id: 3746,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM703',
    id: 3747,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM709',
    id: 3748,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM706',
    id: 3749,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM702',
    id: 3750,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM715',
    id: 3751,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM704',
    id: 3752,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM700',
    id: 3753,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM711',
    id: 3754,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM707',
    id: 3755,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM716',
    id: 3756,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM714',
    id: 3757,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM712',
    id: 3758,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM710',
    id: 3759,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM717',
    id: 3760,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBMUC',
    id: 3761,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB710',
    id: 3762,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB700',
    id: 3763,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB706',
    id: 3764,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB712',
    id: 3765,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB707',
    id: 3766,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB708',
    id: 3767,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB701',
    id: 3768,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB713',
    id: 3769,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB714',
    id: 3770,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB702',
    id: 3771,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB703',
    id: 3772,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB704',
    id: 3773,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB715',
    id: 3774,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB716',
    id: 3775,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB705',
    id: 3776,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB717',
    id: 3777,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB709',
    id: 3778,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB711',
    id: 3779,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP16',
    id: 3780,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEMMP16',
    id: 3781,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF700',
    id: 3782,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 56',
    bic: 'DRESDEFF714',
    id: 3783,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf ZW 57',
    bic: 'DRESDEFF724',
    id: 3784,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf PCC DCC-ITGK 3',
    bic: 'DRESDEFFI55',
    id: 3785,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
    bic: 'DRESDEFFJ23',
    id: 3786,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
    bic: 'DRESDEFFJ24',
    id: 3787,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
    bic: 'DRESDEFFJ25',
    id: 3788,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI23',
    id: 3789,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI45',
    id: 3790,
  },
  {
    bankName: 'Hausbank München',
    bic: 'GENODEF1M04',
    id: 3791,
  },
  {
    bankName: 'Hausbank München',
    bic: 'GENODEF1MU4',
    id: 3792,
  },
  {
    bankName: 'Sparda-Bank München',
    bic: 'GENODEF1S04',
    id: 3793,
  },
  {
    bankName: 'Volksbank Raiffeisenbank Dachau',
    bic: 'GENODEF1DCA',
    id: 3794,
  },
  {
    bankName: 'Volksbank Raiffeisenbank Dachau (Gf GAA)',
    bic: 'GENODEF1DCA',
    id: 3795,
  },
  {
    bankName: 'VR-Bank Landsberg-Ammersee',
    bic: 'GENODEF1DSS',
    id: 3796,
  },
  {
    bankName: 'VR-Bank Erding -alt-',
    bic: 'GENODEF1EDV',
    id: 3797,
  },
  {
    bankName: 'Volksbank Raiffeisenbank Starnberg-Herrsching-Landsberg',
    bic: 'GENODEF1STH',
    id: 3798,
  },
  {
    bankName: 'VR-Bank Ismaning Hallbergmoos Neufahrn',
    bic: 'GENODEF1ISV',
    id: 3799,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 3800,
  },
  {
    bankName: 'Münchener Hypothekenbank',
    bic: 'MHYPDEMMXXX',
    id: 3801,
  },
  {
    bankName: 'State Street Bank International',
    bic: 'SBOSDEMXXXX',
    id: 3802,
  },
  {
    bankName: 'BNP Paribas Niederlassung Deutschland',
    bic: 'DABBDEMMXXX',
    id: 3803,
  },
  {
    bankName: 'CACEIS Bank, Germany Branch',
    bic: 'FMBKDEMMXXX',
    id: 3804,
  },
  {
    bankName: 'Airbus Bank',
    bic: 'AGBMDEMMXXX',
    id: 3805,
  },
  {
    bankName: 'Oberbank Ndl Deutschland',
    bic: 'OBKLDEMXXXX',
    id: 3806,
  },
  {
    bankName: 'Bankhaus August Lenz & Co',
    bic: 'LENZDEM1XXX',
    id: 3807,
  },
  {
    bankName: 'Bankhaus August Lenz & Co Gf GAA',
    bic: 'LENZDEM1XXX',
    id: 3808,
  },
  {
    bankName: 'MERKUR PRIVATBANK',
    bic: 'GENODEF1M06',
    id: 3809,
  },
  {
    bankName: 'Santander Consumer Bank',
    bic: 'SCFBDE33XXX',
    id: 3810,
  },
  {
    bankName: 'Stadtsparkasse München',
    bic: 'SSKMDEMMXXX',
    id: 3811,
  },
  {
    bankName: 'DZ BANK',
    bic: 'GENODEFF701',
    id: 3812,
  },
  {
    bankName: 'Volksbank Raiffeisenbank Fürstenfeldbruck',
    bic: 'GENODEF1FFB',
    id: 3813,
  },
  {
    bankName: 'VR Bank München Land',
    bic: 'GENODEF1OHC',
    id: 3814,
  },
  {
    bankName: 'Raiffeisenbank Griesstätt-Halfing',
    bic: 'GENODEF1HFG',
    id: 3815,
  },
  {
    bankName: 'Raiffeisenbank Chiemgau-Nord - Obing',
    bic: 'GENODEF1SBC',
    id: 3816,
  },
  {
    bankName: 'VR-Bank Chiemgau-Süd -alt-',
    bic: 'GENODEF1RIW',
    id: 3817,
  },
  {
    bankName: 'Raiffeisenbank Pfaffenhofen a d Glonn',
    bic: 'GENODEF1ODZ',
    id: 3818,
  },
  {
    bankName: 'Raiffeisenbank Tattenh-Großkarolinenf',
    bic: 'GENODEF1GKT',
    id: 3819,
  },
  {
    bankName: 'Raiffeisenbank Rupertiwinkel',
    bic: 'GENODEF1TEI',
    id: 3820,
  },
  {
    bankName: 'Raiffeisenbank Trostberg-Traunreut -alt-',
    bic: 'GENODEF1TRU',
    id: 3821,
  },
  {
    bankName: 'Alxing-Brucker Genossenschaftsbank',
    bic: 'GENODEF1ALX',
    id: 3822,
  },
  {
    bankName: 'Raiffeisenbank südöstl. Starnberger See -alt-',
    bic: 'GENODEF1SSB',
    id: 3823,
  },
  {
    bankName: 'Raiffeisenbank Beuerberg-Eurasburg',
    bic: 'GENODEF1EUR',
    id: 3824,
  },
  {
    bankName: 'Raiffeisenbank Nordkreis Landsberg',
    bic: 'GENODEF1ELB',
    id: 3825,
  },
  {
    bankName: 'Raiffeisenbank Erding',
    bic: 'GENODEF1EDR',
    id: 3826,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1GIL',
    id: 3827,
  },
  {
    bankName: 'Raiffeisenbank Gmund am Tegernsee',
    bic: 'GENODEF1GMU',
    id: 3828,
  },
  {
    bankName: 'Raiffeisenbank Haag-Gars-Maitenbeth',
    bic: 'GENODEF1HMA',
    id: 3829,
  },
  {
    bankName: 'Raiffeisenbank Höhenkirchen und Umgebung -alt-',
    bic: 'GENODEF1HHK',
    id: 3830,
  },
  {
    bankName: 'Raiffeisenbank Holzkirchen-Otterfing',
    bic: 'GENODEF1HZO',
    id: 3831,
  },
  {
    bankName: 'Raiffeisenbank Singoldtal',
    bic: 'GENODEF1HUA',
    id: 3832,
  },
  {
    bankName: 'Raiffeisen-Volksbank Ebersberg',
    bic: 'GENODEF1ASG',
    id: 3833,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1MTW',
    id: 3834,
  },
  {
    bankName: 'Raiffeisenbank Westkreis Fürstenfeldbruck',
    bic: 'GENODEF1MOO',
    id: 3835,
  },
  {
    bankName: 'Genossenschaftsbank München',
    bic: 'GENODEF1M07',
    id: 3836,
  },
  {
    bankName: 'Raiffeisenbank München-Nord',
    bic: 'GENODEF1M08',
    id: 3837,
  },
  {
    bankName: 'Raiffeisenbank München-Süd',
    bic: 'GENODEF1M03',
    id: 3838,
  },
  {
    bankName: 'Raiffeisenbank München-Süd Gf GA',
    bic: 'GENODEF1GAA',
    id: 3839,
  },
  {
    bankName: 'Raiffbk Neumarkt-St. Veit - Niederbergkirchen -alt-',
    bic: 'GENODEF1NSV',
    id: 3840,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1NBK',
    id: 3841,
  },
  {
    bankName: 'Raiffeisenbank Pfaffenwinkel',
    bic: 'GENODEF1PEI',
    id: 3842,
  },
  {
    bankName: 'Raiffeisenbank Raisting',
    bic: 'GENODEF1RIG',
    id: 3843,
  },
  {
    bankName: 'Bankhaus RSA',
    bic: 'GENODEF1RME',
    id: 3844,
  },
  {
    bankName: 'Raiffeisenbank Neumarkt-St. Veit - Reischach',
    bic: 'GENODEF1RWZ',
    id: 3845,
  },
  {
    bankName: 'Raiffeisenbank St. Wolfgang-Schwindkirchen -alt-',
    bic: 'GENODEF1SWO',
    id: 3846,
  },
  {
    bankName: 'Raiffeisenbank Lech-Ammersee -alt-',
    bic: 'GENODEF1THG',
    id: 3847,
  },
  {
    bankName: 'Raiffeisenbank Isar-Loisachtal',
    bic: 'GENODEF1HHS',
    id: 3848,
  },
  {
    bankName: 'Raiffeisenbank Steingaden',
    bic: 'GENODEF1SGA',
    id: 3849,
  },
  {
    bankName: 'VR-Bank Taufkirchen-Dorfen',
    bic: 'GENODEF1TAV',
    id: 3850,
  },
  {
    bankName: 'Raiffeisenbank Taufkirchen-Oberneukirchen',
    bic: 'GENODEF1TAE',
    id: 3851,
  },
  {
    bankName: 'Raiffeisenbank Tölzer Land -alt-',
    bic: 'GENODEF1DTZ',
    id: 3852,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1TRH',
    id: 3853,
  },
  {
    bankName: 'Raiffeisen-Volksbank Tüßling-Unterneukirchen',
    bic: 'GENODEF1TUS',
    id: 3854,
  },
  {
    bankName: 'Raiffeisenbank Unterschleißheim-Haimhn -alt-',
    bic: 'GENODEF1UNS',
    id: 3855,
  },
  {
    bankName: 'Raiffeisenbank im Oberland',
    bic: 'GENODEF1MIB',
    id: 3856,
  },
  {
    bankName: 'Raiffeisenbank Weil u Umgebung -alt-',
    bic: 'GENODEF1WEI',
    id: 3857,
  },
  {
    bankName: 'Raiffeisenbank Weilheim -alt-',
    bic: 'GENODEF1WM1',
    id: 3858,
  },
  {
    bankName: 'VR-Bank Erding',
    bic: 'GENODEF1ISE',
    id: 3859,
  },
  {
    bankName: 'Freisinger Bank Volksbank-Raiffeisenbank',
    bic: 'GENODEF1FSR',
    id: 3860,
  },
  {
    bankName: 'Raiffeisenbank Zorneding',
    bic: 'GENODEF1ZOR',
    id: 3861,
  },
  {
    bankName: 'Raiffeisenbank Aiglsbach -alt-',
    bic: 'GENODEF1AIG',
    id: 3862,
  },
  {
    bankName: 'Raiffeisenbank Hallertau',
    bic: 'GENODEF1RHT',
    id: 3863,
  },
  {
    bankName: 'Münchner Bank',
    bic: 'GENODEF1M01',
    id: 3864,
  },
  {
    bankName: 'Münchner Bank',
    bic: 'GENODEF1M1Y',
    id: 3865,
  },
  {
    bankName: 'LfA Förderbank Bayern',
    bic: 'LFFBDEMMXXX',
    id: 3866,
  },
  {
    bankName: 'BHF-BANK',
    bic: 'BHFBDEFF700',
    id: 3867,
  },
  {
    bankName: 'BMW Bank',
    bic: 'BMWBDEMUXXX',
    id: 3868,
  },
  {
    bankName: 'Wüstenrot Bausparkasse',
    bic: 'DRESDEFFBFC',
    id: 3869,
  },
  {
    bankName: 'Isbank Fil München',
    bic: 'ISBKDEFXMUN',
    id: 3870,
  },
  {
    bankName: 'Kreissparkasse München Starnberg Ebersberg',
    bic: 'BYLADEM1KMS',
    id: 3871,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM654',
    id: 3872,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM466',
    id: 3873,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM664',
    id: 3874,
  },
  {
    bankName: 'Kreissparkasse Garmisch-Partenkirchen',
    bic: 'BYLADEM1GAP',
    id: 3875,
  },
  {
    bankName: 'Sparkasse Oberland',
    bic: 'BYLADEM1WHM',
    id: 3876,
  },
  {
    bankName: 'Raiffeisenbank Wallgau-Krün',
    bic: 'GENODEF1WAK',
    id: 3877,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF703',
    id: 3878,
  },
  {
    bankName: 'VR-Bank Werdenfels',
    bic: 'GENODEF1GAP',
    id: 3879,
  },
  {
    bankName: 'VR-Bank Werdenfels',
    bic: 'GENODEF1GAP',
    id: 3880,
  },
  {
    bankName: 'Volksbank-Raiffeisenbank Penzberg -alt-',
    bic: 'GENODEF1PZB',
    id: 3881,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM410',
    id: 3882,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM410',
    id: 3883,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM410',
    id: 3884,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM629',
    id: 3885,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM629',
    id: 3886,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM629',
    id: 3887,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM453',
    id: 3888,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM453',
    id: 3889,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM453',
    id: 3890,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM632',
    id: 3891,
  },
  {
    bankName: 'Sparkasse Berchtesgadener Land',
    bic: 'BYLADEM1BGL',
    id: 3892,
  },
  {
    bankName: 'Kreissparkasse Altötting-Burghausen -alt-',
    bic: 'BYLADEM1AOE',
    id: 3893,
  },
  {
    bankName: 'Kreissparkasse Traunstein-Trostberg',
    bic: 'BYLADEM1TST',
    id: 3894,
  },
  {
    bankName: 'VR meine Raiffeisenbank -alt-',
    bic: 'GENODEF1AOE',
    id: 3895,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1AGE',
    id: 3896,
  },
  {
    bankName: 'Volksbank Raiffeisenbank Oberbayern Südost',
    bic: 'GENODEF1BGL',
    id: 3897,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM448',
    id: 3898,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM448',
    id: 3899,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM448',
    id: 3900,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM448',
    id: 3901,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM448',
    id: 3902,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM644',
    id: 3903,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM644',
    id: 3904,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM438',
    id: 3905,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM457',
    id: 3906,
  },
  {
    bankName: 'Commerzbank Rosenheim',
    bic: 'COBADEFFXXX',
    id: 3907,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3908,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3909,
  },
  {
    bankName: 'Sparkasse Rosenheim-Bad Aibling',
    bic: 'BYLADEM1ROS',
    id: 3910,
  },
  {
    bankName: 'Sparkasse Altötting-Mühldorf',
    bic: 'BYLADEM1MDF',
    id: 3911,
  },
  {
    bankName: 'Kreissparkasse Miesbach-Tegernsee',
    bic: 'BYLADEM1MIB',
    id: 3912,
  },
  {
    bankName: 'Kreis- und Stadtsparkasse Wasserburg',
    bic: 'BYLADEM1WSB',
    id: 3913,
  },
  {
    bankName: 'meine Volksbank Raiffeisenbank',
    bic: 'GENODEF1VRR',
    id: 3914,
  },
  {
    bankName: 'VR Bank Rosenheim-Chiemsee -alt-',
    bic: 'GENODEF1ROR',
    id: 3915,
  },
  {
    bankName: 'Volksbank-Raiffeisenbank Chiemsee -alt-',
    bic: 'GENODEF1PRV',
    id: 3916,
  },
  {
    bankName: 'Raiffeisenbank Oberaudorf',
    bic: 'GENODEF1OBD',
    id: 3917,
  },
  {
    bankName: 'Raiffeisenbank Aschau-Samerberg',
    bic: 'GENODEF1ASU',
    id: 3918,
  },
  {
    bankName: 'Raiffeisenbank Mangfalltal -alt-',
    bic: 'GENODEF1AIB',
    id: 3919,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF711',
    id: 3920,
  },
  {
    bankName: 'Volksbank Rosenheim -alt-',
    bic: 'GENODEF1ROV',
    id: 3921,
  },
  {
    bankName: 'VR-Bank Burghausen-Mühldorf -alt-',
    bic: 'GENODEF1MUL',
    id: 3922,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1720',
    id: 3923,
  },
  {
    bankName: 'Bank für Tirol und Vorarlberg Deutschland',
    bic: 'BTVADE61XXX',
    id: 3924,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM408',
    id: 3925,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM408',
    id: 3926,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM408',
    id: 3927,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM408',
    id: 3928,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM408',
    id: 3929,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM408',
    id: 3930,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM408',
    id: 3931,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM408',
    id: 3932,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM408',
    id: 3933,
  },
  {
    bankName: 'Augsburger Aktienbank',
    bic: 'AUGBDE77XXX',
    id: 3934,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM236',
    id: 3935,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM259',
    id: 3936,
  },
  {
    bankName: 'Fürst Fugger Privatbank',
    bic: 'FUBKDE71XXX',
    id: 3937,
  },
  {
    bankName: 'Bankhaus Anton Hafner',
    bic: 'ANHODE77XXX',
    id: 3938,
  },
  {
    bankName: 'Bankhaus Anton Hafner (Gf PayCenter)',
    bic: 'ANHODE7AXXX',
    id: 3939,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3940,
  },
  {
    bankName: 'Stadtsparkasse Augsburg',
    bic: 'AUGSDE77XXX',
    id: 3941,
  },
  {
    bankName: 'Kreissparkasse Augsburg',
    bic: 'BYLADEM1AUG',
    id: 3942,
  },
  {
    bankName: 'Sparkasse Aichach-Schrobenhausen',
    bic: 'BYLADEM1AIC',
    id: 3943,
  },
  {
    bankName: 'Sparkasse Günzburg-Krumbach',
    bic: 'BYLADEM1GZK',
    id: 3944,
  },
  {
    bankName: 'VR-Bank Handels- und Gewerbebank',
    bic: 'GENODEF1MTG',
    id: 3945,
  },
  {
    bankName: 'Raiffeisenbank Adelzhausen-Sielenbach -alt-',
    bic: 'GENODEF1ADZ',
    id: 3946,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1AIL',
    id: 3947,
  },
  {
    bankName: 'Raiffeisenbank Bissingen',
    bic: 'GENODEF1BSI',
    id: 3948,
  },
  {
    bankName: 'Raiffeisenbank Bobingen',
    bic: 'GENODEF1BOI',
    id: 3949,
  },
  {
    bankName: 'VR-Bank Donau-Mindel',
    bic: 'GENODEF1GZ2',
    id: 3950,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1HTF',
    id: 3951,
  },
  {
    bankName: 'Raiffeisenbank Aschberg',
    bic: 'GENODEF1HZH',
    id: 3952,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1HZR',
    id: 3953,
  },
  {
    bankName: 'Raiffeisenbank Ichenhausen',
    bic: 'GENODEF1ICH',
    id: 3954,
  },
  {
    bankName: 'Raiffeisenbank Jettingen-Scheppach -alt-',
    bic: 'GENODEF1JET',
    id: 3955,
  },
  {
    bankName: 'Raiffeisenbank Mittelschwaben',
    bic: 'GENODEF1BBT',
    id: 3956,
  },
  {
    bankName: 'Raiffeisenbank Krumbach/Schwaben -alt-',
    bic: 'GENODEF1KRR',
    id: 3957,
  },
  {
    bankName: 'Raiffeisenbank Stauden',
    bic: 'GENODEF1LST',
    id: 3958,
  },
  {
    bankName: 'Raiffeisenbank Wittelsbacher Land',
    bic: 'GENODEF1MRI',
    id: 3959,
  },
  {
    bankName: 'Raiffeisenbank Unteres Zusamtal',
    bic: 'GENODEF1BWI',
    id: 3960,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1OFF',
    id: 3961,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1RLI',
    id: 3962,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1RGB',
    id: 3963,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1SMU',
    id: 3964,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1THS',
    id: 3965,
  },
  {
    bankName: 'Raiffeisenbank Wittislingen -alt-',
    bic: 'GENODEF1WTS',
    id: 3966,
  },
  {
    bankName: 'Raiffeisenbank Augsburger Land West',
    bic: 'GENODEF1ZUS',
    id: 3967,
  },
  {
    bankName: 'Raiffeisen-Volksbank Wemding',
    bic: 'GENODEF1WDN',
    id: 3968,
  },
  {
    bankName: 'Raiffeisen-Volksbank Ries',
    bic: 'GENODEF1NOE',
    id: 3969,
  },
  {
    bankName: 'Raiffeisenbank Schwaben Mitte',
    bic: 'GENODEF1BLT',
    id: 3970,
  },
  {
    bankName: 'Raiffeisenbank Pfaffenhausen',
    bic: 'GENODEF1PFA',
    id: 3971,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM720',
    id: 3972,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM727',
    id: 3973,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM725',
    id: 3974,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM723',
    id: 3975,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM726',
    id: 3976,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM724',
    id: 3977,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB720',
    id: 3978,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB723',
    id: 3979,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB724',
    id: 3980,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB725',
    id: 3981,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB726',
    id: 3982,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB727',
    id: 3983,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP19',
    id: 3984,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEMMP19',
    id: 3985,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF720',
    id: 3986,
  },
  {
    bankName: 'VR Bank Augsburg-Ostallgäu',
    bic: 'GENODEF1AUB',
    id: 3987,
  },
  {
    bankName: 'Sparda-Bank Augsburg',
    bic: 'GENODEF1S03',
    id: 3988,
  },
  {
    bankName: 'PSD Bank München',
    bic: 'GENODEF1P14',
    id: 3989,
  },
  {
    bankName: 'Volksbank Günzburg -alt-',
    bic: 'GENODEF1GZ1',
    id: 3990,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM426',
    id: 3991,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM426',
    id: 3992,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM426',
    id: 3993,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM426',
    id: 3994,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM426',
    id: 3995,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM426',
    id: 3996,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM665',
    id: 3997,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 3998,
  },
  {
    bankName: 'Sparkasse Ingolstadt Eichstätt',
    bic: 'BYLADEM1ING',
    id: 3999,
  },
  {
    bankName: 'Sparkasse Eichstätt -alt-',
    bic: 'BYLADEM1EIS',
    id: 4000,
  },
  {
    bankName: 'Sparkasse Pfaffenhofen',
    bic: 'BYLADEM1PAF',
    id: 4001,
  },
  {
    bankName: 'Sparkasse Aichach-Schrobenhausen -alt-',
    bic: 'BYLADEM1SSH',
    id: 4002,
  },
  {
    bankName: 'Sparkasse Neuburg-Rain',
    bic: 'BYLADEM1NEB',
    id: 4003,
  },
  {
    bankName: 'Volksbank Raiffeisenbank Bayern Mitte',
    bic: 'GENODEF1INP',
    id: 4004,
  },
  {
    bankName: 'Raiffeisenbank Aresing-Hörzhausen-Schiltberg -alt-',
    bic: 'GENODEF1ARH',
    id: 4005,
  },
  {
    bankName: 'Raiffeisenbank Aresing-Gerolsbach',
    bic: 'GENODEF1GSB',
    id: 4006,
  },
  {
    bankName: 'Schrobenhausener Bank',
    bic: 'GENODEF1SBN',
    id: 4007,
  },
  {
    bankName: 'Raiffeisenbank Schrobenhausener Land',
    bic: 'GENODEF1WFN',
    id: 4008,
  },
  {
    bankName: 'Raiffeisenbank Beilngries',
    bic: 'GENODEF1BLN',
    id: 4009,
  },
  {
    bankName: 'Raiffeisenbank Ehekirchen-Oberhausen',
    bic: 'GENODEF1WDF',
    id: 4010,
  },
  {
    bankName: 'VR Bank Neuburg-Rain',
    bic: 'GENODEF1ND2',
    id: 4011,
  },
  {
    bankName: 'Raiffeisenbank Donaumooser Land -alt-',
    bic: 'GENODEF1WRI',
    id: 4012,
  },
  {
    bankName: 'Raiffeisenbank im Donautal',
    bic: 'GENODEF1GAH',
    id: 4013,
  },
  {
    bankName: 'Raiffeisenbank Riedenburg-Lobsing -alt-',
    bic: 'GENODEF1RBL',
    id: 4014,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM721',
    id: 4015,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM729',
    id: 4016,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM722',
    id: 4017,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM728',
    id: 4018,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB721',
    id: 4019,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB722',
    id: 4020,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB728',
    id: 4021,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB729',
    id: 4022,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP17',
    id: 4023,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEMMP17',
    id: 4024,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF721',
    id: 4025,
  },
  {
    bankName: 'Hallertauer Volksbank -alt-',
    bic: 'GENODEF1PFI',
    id: 4026,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM255',
    id: 4027,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM263',
    id: 4028,
  },
  {
    bankName: 'Sparkasse Nördlingen -alt-',
    bic: 'BYLADEM1NLG',
    id: 4029,
  },
  {
    bankName: 'Sparkasse Donauwörth',
    bic: 'BYLADEM1DON',
    id: 4030,
  },
  {
    bankName: 'Sparkasse Dillingen-Nördlingen',
    bic: 'BYLADEM1DLG',
    id: 4031,
  },
  {
    bankName: 'Raiffeisenbank Rain am Lech -alt-',
    bic: 'GENODEF1RLH',
    id: 4032,
  },
  {
    bankName: 'Raiffeisen-Volksbank Dillingen -alt-',
    bic: 'GENODEF1DLG',
    id: 4033,
  },
  {
    bankName: 'Raiffeisen-Volksbank Donauwörth',
    bic: 'GENODEF1DON',
    id: 4034,
  },
  {
    bankName: 'Sparkasse Neu-Ulm-Illertissen',
    bic: 'BYLADEM1NUL',
    id: 4035,
  },
  {
    bankName: 'VR-Bank Neu-Ulm',
    bic: 'GENODEF1NU1',
    id: 4036,
  },
  {
    bankName: 'Volksbank Neu-Ulm -alt-',
    bic: 'GENODEF1NUV',
    id: 4037,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM436',
    id: 4038,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM436',
    id: 4039,
  },
  {
    bankName: 'Commerzbank Memmingen',
    bic: 'COBADEFFXXX',
    id: 4040,
  },
  {
    bankName: 'Sparkasse Schwaben-Bodensee',
    bic: 'BYLADEM1MLM',
    id: 4041,
  },
  {
    bankName: 'Genossenschaftsbank Unterallgäu',
    bic: 'GENODEF1MIR',
    id: 4042,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF731',
    id: 4043,
  },
  {
    bankName: 'VR-Bank Memmingen',
    bic: 'GENODEF1MM1',
    id: 4044,
  },
  {
    bankName: 'Volksbank Ulm-Biberach -alt-',
    bic: 'ULMVDE66XXX',
    id: 4045,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM428',
    id: 4046,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM428',
    id: 4047,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM567',
    id: 4048,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM570',
    id: 4049,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM570',
    id: 4050,
  },
  {
    bankName: 'Gabler-Saliter Bankgeschäft',
    bic: 'GABLDE71XXX',
    id: 4051,
  },
  {
    bankName: 'Commerzbank Kempten Allgäu',
    bic: 'COBADEFFXXX',
    id: 4052,
  },
  {
    bankName: 'Sparkasse Allgäu',
    bic: 'BYLADEM1ALG',
    id: 4053,
  },
  {
    bankName: 'Raiffeisenbank im Allgäuer Land',
    bic: 'GENODEF1DTA',
    id: 4054,
  },
  {
    bankName: 'BodenseeBank',
    bic: 'GENODEF1LBB',
    id: 4055,
  },
  {
    bankName: 'Raiffeisenbank Westallgäu',
    bic: 'GENODEF1WWA',
    id: 4056,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODEF1LIA',
    id: 4057,
  },
  {
    bankName: 'Raiffeisenbank Aitrang-Ruderatshofen',
    bic: 'GENODEF1AIT',
    id: 4058,
  },
  {
    bankName: 'Raiffeisenbank Fuchstal-Denklingen',
    bic: 'GENODEF1FCH',
    id: 4059,
  },
  {
    bankName: 'Raiffeisenbank Bidingen',
    bic: 'GENODEF1BIN',
    id: 4060,
  },
  {
    bankName: 'Raiffeisenbank Baisweil-Eggenthal-Friesenried',
    bic: 'GENODEF1EGB',
    id: 4061,
  },
  {
    bankName: 'Raiffeisenbank Haldenwang -alt-',
    bic: 'GENODEF1HWG',
    id: 4062,
  },
  {
    bankName: 'Raiffeisenbank Kempten -alt-',
    bic: 'GENODEF1KM1',
    id: 4063,
  },
  {
    bankName: 'Raiffeisenbank Kirchweihtal',
    bic: 'GENODEF1OKI',
    id: 4064,
  },
  {
    bankName: 'Raiffeisenbank Kempten-Oberallgäu',
    bic: 'GENODEF1SFO',
    id: 4065,
  },
  {
    bankName: 'Raiffeisenbank Südliches Ostallgäu',
    bic: 'GENODEF1RHP',
    id: 4066,
  },
  {
    bankName: 'Raiffeisenbank Seeg -alt-',
    bic: 'GENODEF1SER',
    id: 4067,
  },
  {
    bankName: 'Raiffeisenbank Wald-Görisried',
    bic: 'GENODEF1WGO',
    id: 4068,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM733',
    id: 4069,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM734',
    id: 4070,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM735',
    id: 4071,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM736',
    id: 4072,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM737',
    id: 4073,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB733',
    id: 4074,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB737',
    id: 4075,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB736',
    id: 4076,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB734',
    id: 4077,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB735',
    id: 4078,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF733',
    id: 4079,
  },
  {
    bankName: 'Allgäuer Volksbank Kempten-Sonthofen',
    bic: 'GENODEF1KEV',
    id: 4080,
  },
  {
    bankName: 'Volksbank Immenstadt',
    bic: 'GENODEF1IMV',
    id: 4081,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM427',
    id: 4082,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM427',
    id: 4083,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM427',
    id: 4084,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM666',
    id: 4085,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4086,
  },
  {
    bankName: 'Kreis- und Stadtsparkasse Kaufbeuren',
    bic: 'BYLADEM1KFB',
    id: 4087,
  },
  {
    bankName: 'Kreissparkasse Schongau -alt-',
    bic: 'BYLADEM1SOG',
    id: 4088,
  },
  {
    bankName: 'VR Bank Augsburg-Ostallgäu',
    bic: 'GENODEF1KFB',
    id: 4089,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF734',
    id: 4090,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM445',
    id: 4091,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM445',
    id: 4092,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM445',
    id: 4093,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM445',
    id: 4094,
  },
  {
    bankName: 'Raiffeisenlandesbank OÖ Zndl Süddeutschland',
    bic: 'RZOODE77XXX',
    id: 4095,
  },
  {
    bankName: 'Raiffeisenlandesbank OÖ Zndl Südde - für interne Zwecke',
    bic: 'RZOODE77050',
    id: 4096,
  },
  {
    bankName: 'Commerzbank Passau',
    bic: 'COBADEFFXXX',
    id: 4097,
  },
  {
    bankName: 'Sparkasse Passau',
    bic: 'BYLADEM1PAS',
    id: 4098,
  },
  {
    bankName: 'Sparkasse Freyung-Grafenau',
    bic: 'BYLADEM1FRG',
    id: 4099,
  },
  {
    bankName: 'Raiffeisenbank Am Goldenen Steig',
    bic: 'GENODEF1RGS',
    id: 4100,
  },
  {
    bankName: 'Raiffeisenbank Unteres Inntal',
    bic: 'GENODEF1NUI',
    id: 4101,
  },
  {
    bankName: 'Raiffeisenbank Ortenburg-Kirchberg',
    bic: 'GENODEF1ORT',
    id: 4102,
  },
  {
    bankName: 'VR-Bank Rottal-Inn',
    bic: 'GENODEF1PFK',
    id: 4103,
  },
  {
    bankName: 'VR-Bank Rottal-Inn Gf GAA',
    bic: 'GENODEF1PFK',
    id: 4104,
  },
  {
    bankName: 'Volksbank - Raiffeisenbank Vilshofen',
    bic: 'GENODEF1VIR',
    id: 4105,
  },
  {
    bankName: 'Raiffeisenbank i Lkr Passau-Nord',
    bic: 'GENODEF1TIE',
    id: 4106,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1WSD',
    id: 4107,
  },
  {
    bankName: 'Raiffeisenbank Salzweg-Thyrnau -alt-',
    bic: 'GENODEF1SZT',
    id: 4108,
  },
  {
    bankName: 'Raiffeisenbank Südl. Bayerischer Wald -alt-',
    bic: 'GENODEF1HZN',
    id: 4109,
  },
  {
    bankName: 'Rottaler Raiffeisenbank',
    bic: 'GENODEF1POC',
    id: 4110,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1GRT',
    id: 4111,
  },
  {
    bankName: 'Raiffeisenbank Hohenau-Mauth -alt-',
    bic: 'GENODEF1HHU',
    id: 4112,
  },
  {
    bankName: 'Raiffeisenbank Kirchberg v. Wald',
    bic: 'GENODEF1TKI',
    id: 4113,
  },
  {
    bankName: 'Raiffeisenbank am Dreisessel',
    bic: 'GENODEF1NHD',
    id: 4114,
  },
  {
    bankName: 'VR-Bank Passau',
    bic: 'GENODEF1PA1',
    id: 4115,
  },
  {
    bankName: 'Volksbank Vilshofen -alt-',
    bic: 'GENODEF1VIV',
    id: 4116,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM415',
    id: 4117,
  },
  {
    bankName: 'TEBA Kreditbank',
    bic: 'TEKRDE71XXX',
    id: 4118,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4119,
  },
  {
    bankName: 'Sparkasse Deggendorf',
    bic: 'BYLADEM1DEG',
    id: 4120,
  },
  {
    bankName: 'Sparkasse Regen-Viechtach',
    bic: 'BYLADEM1REG',
    id: 4121,
  },
  {
    bankName: 'Raiffeisenbank Deggendorf-Plattling-Sonnenwald',
    bic: 'GENODEF1DEG',
    id: 4122,
  },
  {
    bankName: 'Raiffeisenbank Hengersberg-Schöllnach',
    bic: 'GENODEF1HBW',
    id: 4123,
  },
  {
    bankName: 'VR-Bank -alt-',
    bic: 'GENODEF1RGE',
    id: 4124,
  },
  {
    bankName: 'Raiffeisenbank Sonnenwald -alt-',
    bic: 'GENODEF1AUS',
    id: 4125,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF741',
    id: 4126,
  },
  {
    bankName: 'VR GenoBank DonauWald',
    bic: 'GENODEF1DGV',
    id: 4127,
  },
  {
    bankName: 'VR-Bank Landau-Mengkofen',
    bic: 'GENODEF1LND',
    id: 4128,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM452',
    id: 4129,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM675',
    id: 4130,
  },
  {
    bankName: 'Commerzbank Straubing',
    bic: 'COBADEFFXXX',
    id: 4131,
  },
  {
    bankName: 'Sparkasse Niederbayern-Mitte',
    bic: 'BYLADEM1SRG',
    id: 4132,
  },
  {
    bankName: 'Sparkasse im Landkreis Cham',
    bic: 'BYLADEM1CHM',
    id: 4133,
  },
  {
    bankName: 'Raiffeisenbank Straubing',
    bic: 'GENODEF1SR2',
    id: 4134,
  },
  {
    bankName: 'Raiffeisenbank Chamer Land',
    bic: 'GENODEF1CHA',
    id: 4135,
  },
  {
    bankName: 'VR-Bank Ostbayern-Mitte',
    bic: 'GENODEF1SR1',
    id: 4136,
  },
  {
    bankName: 'CB Bank',
    bic: 'CBSRDE71XXX',
    id: 4137,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM433',
    id: 4138,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM433',
    id: 4139,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM433',
    id: 4140,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM433',
    id: 4141,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4142,
  },
  {
    bankName: 'Sparkasse Landshut',
    bic: 'BYLADEM1LAH',
    id: 4143,
  },
  {
    bankName: 'Sparkasse Rottal-Inn',
    bic: 'BYLADEM1EGF',
    id: 4144,
  },
  {
    bankName: 'Stadt- und Kreissparkasse Moosburg',
    bic: 'BYLADEM1MSB',
    id: 4145,
  },
  {
    bankName: 'Raiffeisenbank Arnstorf',
    bic: 'GENODEF1ARF',
    id: 4146,
  },
  {
    bankName: 'Raiffeisenbank Altdorf-Ergolding',
    bic: 'GENODEF1ERG',
    id: 4147,
  },
  {
    bankName: 'Raiffeisenbank Pfeffenhausen-Rottenburg-Wildenberg',
    bic: 'GENODEF1PFF',
    id: 4148,
  },
  {
    bankName: 'Raiffeisenbank Geisenhausen -alt-',
    bic: 'GENODEF1GSH',
    id: 4149,
  },
  {
    bankName: 'Raiffeisenbank Hofkirchen-Bayerbach',
    bic: 'GENODEF1LWE',
    id: 4150,
  },
  {
    bankName: 'Raiffeisenbank Geiselhöring-Pfaffenberg',
    bic: 'GENODEF1GPF',
    id: 4151,
  },
  {
    bankName: 'Raiffeisenbank Parkstetten',
    bic: 'GENODEF1PST',
    id: 4152,
  },
  {
    bankName: 'Raiffeisenbank Rattiszell-Konzell',
    bic: 'GENODEF1RZK',
    id: 4153,
  },
  {
    bankName: 'Raiffeisenbank Essenbach',
    bic: 'GENODEF1ENA',
    id: 4154,
  },
  {
    bankName: 'Raiffeisenbank Buch-Eching',
    bic: 'GENODEF1EBV',
    id: 4155,
  },
  {
    bankName: 'Raiffeisenbank Mengkofen-Loiching -alt-',
    bic: 'GENODEF1MKO',
    id: 4156,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF743',
    id: 4157,
  },
  {
    bankName: 'VR-Bank Landshut',
    bic: 'GENODEF1LH1',
    id: 4158,
  },
  {
    bankName: 'Volksbank-Raiffeisenbank Dingolfing -alt-',
    bic: 'GENODEF1DGF',
    id: 4159,
  },
  {
    bankName: 'VR-Bank Rottal-Inn',
    bic: 'GENODEF1EGR',
    id: 4160,
  },
  {
    bankName: 'VR-Bank Isar-Vils',
    bic: 'GENODEF1VBV',
    id: 4161,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1750',
    id: 4162,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM447',
    id: 4163,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM447',
    id: 4164,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM447',
    id: 4165,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM447',
    id: 4166,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM447',
    id: 4167,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM447',
    id: 4168,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM447',
    id: 4169,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM804',
    id: 4170,
  },
  {
    bankName: 'Commerzbank Regensburg',
    bic: 'COBADEFFXXX',
    id: 4171,
  },
  {
    bankName: 'Sparkasse Regensburg',
    bic: 'BYLADEM1RBG',
    id: 4172,
  },
  {
    bankName: 'Sparkasse im Landkreis Schwandorf',
    bic: 'BYLADEM1SAD',
    id: 4173,
  },
  {
    bankName: 'Kreissparkasse Kelheim',
    bic: 'BYLADEM1KEH',
    id: 4174,
  },
  {
    bankName: 'Raiffeisenbank Regensburg-Wenzenbach',
    bic: 'GENODEF1R02',
    id: 4175,
  },
  {
    bankName: 'Raiffeisenbank Schwandorf-Nittenau -alt-',
    bic: 'GENODEF1SWN',
    id: 4176,
  },
  {
    bankName: 'Raiffeisenbank Regenstauf',
    bic: 'GENODEF1REF',
    id: 4177,
  },
  {
    bankName: 'Raiffeisenbank Oberpfalz Süd',
    bic: 'GENODEF1DST',
    id: 4178,
  },
  {
    bankName: 'Raiffeisenbank Kreis Kelheim',
    bic: 'GENODEF1ABS',
    id: 4179,
  },
  {
    bankName: 'Raiffeisenbank Bad Gögging -alt-',
    bic: 'GENODEF1NGG',
    id: 4180,
  },
  {
    bankName: 'Raiffeisenbank Bruck',
    bic: 'GENODEF1BUK',
    id: 4181,
  },
  {
    bankName: 'Raiffeisenbank Falkenstein-Wörth',
    bic: 'GENODEF1FKS',
    id: 4182,
  },
  {
    bankName: 'Raiffeisenbank Grafenwöhr-Kirchenthumbach -alt-',
    bic: 'GENODEF1GRW',
    id: 4183,
  },
  {
    bankName: 'Raiffeisenbank Alteglofsheim-Hagelstadt',
    bic: 'GENODEF1HGA',
    id: 4184,
  },
  {
    bankName: 'Raiffeisenbank im Oberpfälzer Jura',
    bic: 'GENODEF1HEM',
    id: 4185,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1SZV',
    id: 4186,
  },
  {
    bankName: 'Raiffeisenbank Bad Kötzting',
    bic: 'GENODEF1KTZ',
    id: 4187,
  },
  {
    bankName: 'Raiffeisenbank Parsberg-Velburg -alt-',
    bic: 'GENODEF1PAR',
    id: 4188,
  },
  {
    bankName: 'Raiffeisenbank Eschlkam-Lam-Lohberg-Neukirchen b Hl Blut',
    bic: 'GENODEF1NKN',
    id: 4189,
  },
  {
    bankName: 'VR Bank Mittlere Oberpfalz',
    bic: 'GENODEF1SWD',
    id: 4190,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM750',
    id: 4191,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM751',
    id: 4192,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM741',
    id: 4193,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM752',
    id: 4194,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB750',
    id: 4195,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB741',
    id: 4196,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB752',
    id: 4197,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB751',
    id: 4198,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF750',
    id: 4199,
  },
  {
    bankName: 'Volksbank Raiffeisenbank Regensburg-Schwandorf',
    bic: 'GENODEF1R01',
    id: 4200,
  },
  {
    bankName: 'LIGA Bank',
    bic: 'GENODEF1M05',
    id: 4201,
  },
  {
    bankName: 'Sparda-Bank Ostbayern',
    bic: 'GENODEF1S05',
    id: 4202,
  },
  {
    bankName: 'VR Bank Niederbayern-Oberpfalz',
    bic: 'GENODEF1P18',
    id: 4203,
  },
  {
    bankName: 'VR Bank Burglengenfeld -alt-',
    bic: 'GENODEF1BLF',
    id: 4204,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM405',
    id: 4205,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM405',
    id: 4206,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4207,
  },
  {
    bankName: 'Sparkasse Amberg-Sulzbach',
    bic: 'BYLADEM1ABG',
    id: 4208,
  },
  {
    bankName: 'Raiffeisenbank Sulzbach-Rosenberg',
    bic: 'GENODEF1SZH',
    id: 4209,
  },
  {
    bankName: 'Volksbank-Raiffeisenbank Amberg',
    bic: 'GENODEF1AMV',
    id: 4210,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM454',
    id: 4211,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4212,
  },
  {
    bankName: 'Sparkasse Oberpfalz Nord',
    bic: 'BYLADEM1WEN',
    id: 4213,
  },
  {
    bankName: 'Vereinigte Sparkassen Eschenbach i d Opf',
    bic: 'BYLADEM1ESB',
    id: 4214,
  },
  {
    bankName: 'Raiffeisenbank Weiden -alt-',
    bic: 'GENODEF1WEO',
    id: 4215,
  },
  {
    bankName: 'Raiffeisenbank Floß',
    bic: 'GENODEF1FLS',
    id: 4216,
  },
  {
    bankName: 'Raiffeisenbank Neustadt-Vohenstrauß',
    bic: 'GENODEF1NEW',
    id: 4217,
  },
  {
    bankName: 'Volksbank Raiffeisenbank Nordoberpfalz',
    bic: 'GENODEF1WEV',
    id: 4218,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1760',
    id: 4219,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 4220,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM460',
    id: 4221,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM460',
    id: 4222,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM460',
    id: 4223,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM460',
    id: 4224,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM460',
    id: 4225,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM460',
    id: 4226,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank Prepaid Card',
    bic: 'HYVEDEMMCAR',
    id: 4227,
  },
  {
    bankName: 'norisbank',
    bic: 'NORSDE71XXX',
    id: 4228,
  },
  {
    bankName: 'BNP Paribas Niederlassung Deutschland',
    bic: 'CSDBDE71XXX',
    id: 4229,
  },
  {
    bankName: 'Isbank Fil Nürnberg',
    bic: 'ISBKDEFXNUR',
    id: 4230,
  },
  {
    bankName: 'flatexDEGIRO Bank',
    bic: 'BIWBDE33760',
    id: 4231,
  },
  {
    bankName: 'TeamBank Nürnberg',
    bic: 'TEAMDE71XXX',
    id: 4232,
  },
  {
    bankName: 'TeamBank Nürnberg GF Austria',
    bic: 'TEAMDE71TAT',
    id: 4233,
  },
  {
    bankName: 'UmweltBank',
    bic: 'UMWEDE7NXXX',
    id: 4234,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 4235,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4236,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 4237,
  },
  {
    bankName: 'Commerzbank, Filiale Nürnberg 2',
    bic: 'COBADEFFXXX',
    id: 4238,
  },
  {
    bankName: 'Bayerische Landesbank',
    bic: 'BYLADEMMXXX',
    id: 4239,
  },
  {
    bankName: 'Sparkasse Nürnberg',
    bic: 'SSKNDE77XXX',
    id: 4240,
  },
  {
    bankName: 'Sparkasse Neumarkt i d OPf-Parsberg',
    bic: 'BYLADEM1NMA',
    id: 4241,
  },
  {
    bankName: 'DZ BANK',
    bic: 'GENODEFF760',
    id: 4242,
  },
  {
    bankName: 'Volksbank Raiffeisenbank -alt-',
    bic: 'GENODEF1N02',
    id: 4243,
  },
  {
    bankName: 'Volksbank Raiffeisenbank Nürnberg (Gf GAA)',
    bic: 'GENODEF1N02',
    id: 4244,
  },
  {
    bankName: 'Raiffeisen Spar+Kreditbank Lauf a d Pegnitz',
    bic: 'GENODEF1LAU',
    id: 4245,
  },
  {
    bankName: 'Raiffeisenbank im Nürnberger Land',
    bic: 'GENODEF1HSB',
    id: 4246,
  },
  {
    bankName: 'Raiffeisenbank Auerbach-Freihung',
    bic: 'GENODEF1AUO',
    id: 4247,
  },
  {
    bankName: 'Raiffeisenbank Bad Windsheim',
    bic: 'GENODEF1WDS',
    id: 4248,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1BEH',
    id: 4249,
  },
  {
    bankName: 'Raiffeisenbank Uehlfeld-Dachsbach',
    bic: 'GENODEF1DSB',
    id: 4250,
  },
  {
    bankName: 'Raiffeisenbank Dietenhofen -alt-',
    bic: 'GENODEF1DIH',
    id: 4251,
  },
  {
    bankName: 'Raiffeisenbank Dietersheim und Umgebung',
    bic: 'GENODEF1DIM',
    id: 4252,
  },
  {
    bankName: 'Raiffeisenbank Altdorf-Feucht -alt-',
    bic: 'GENODEF1FEC',
    id: 4253,
  },
  {
    bankName: 'VR-Bank Feuchtwangen-Limes -alt-',
    bic: 'GENODEF1FEW',
    id: 4254,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1FRD',
    id: 4255,
  },
  {
    bankName: 'Raiffeisen - meine Bank',
    bic: 'GENODEF1FYS',
    id: 4256,
  },
  {
    bankName: 'Raiffeisenbank Greding - Thalmässing',
    bic: 'GENODEF1GDG',
    id: 4257,
  },
  {
    bankName: 'Raiffeisenbank Weißenburg-Gunzenhausen',
    bic: 'GENODEF1GU1',
    id: 4258,
  },
  {
    bankName: 'Raiffeisenbank Hirschau',
    bic: 'GENODEF1HSC',
    id: 4259,
  },
  {
    bankName: 'Raiffeisenbank Knoblauchsland Nürnberg-Buch',
    bic: 'GENODEF1N08',
    id: 4260,
  },
  {
    bankName: 'Raiffeisenbank Neumarkt',
    bic: 'GENODEF1NM1',
    id: 4261,
  },
  {
    bankName: 'VR Bank Metropolregion Nürnberg',
    bic: 'GENODEF1NEA',
    id: 4262,
  },
  {
    bankName: 'Raiffeisenbank Oberferrieden-Burgthann',
    bic: 'GENODEF1BTO',
    id: 4263,
  },
  {
    bankName: 'Raiffeisenbank Plankstetten',
    bic: 'GENODEF1BPL',
    id: 4264,
  },
  {
    bankName: 'Raiffeisenbank Großhabersdorf-Roßtal -alt-',
    bic: 'GENODEF1RSS',
    id: 4265,
  },
  {
    bankName: 'Raiffeisenbank Seebachgrund',
    bic: 'GENODEF1HSE',
    id: 4266,
  },
  {
    bankName: 'Raiffeisenbank Unteres Vilstal',
    bic: 'GENODEF1SDM',
    id: 4267,
  },
  {
    bankName: 'Raiffeisenbank Ursensollen-Ammerthal-Hohenburg -alt-',
    bic: 'GENODEF1URS',
    id: 4268,
  },
  {
    bankName: 'Raiffeisenbank Heilsbronn-Windsbach',
    bic: 'GENODEF1WBA',
    id: 4269,
  },
  {
    bankName: 'Raiffeisenbank Bibertgrund',
    bic: 'GENODEF1ZIR',
    id: 4270,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM760',
    id: 4271,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM773',
    id: 4272,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM762',
    id: 4273,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM763',
    id: 4274,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM783',
    id: 4275,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM764',
    id: 4276,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM769',
    id: 4277,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM774',
    id: 4278,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM768',
    id: 4279,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM761',
    id: 4280,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM765',
    id: 4281,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM766',
    id: 4282,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM767',
    id: 4283,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM772',
    id: 4284,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM771',
    id: 4285,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB760',
    id: 4286,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB765',
    id: 4287,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB761',
    id: 4288,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB762',
    id: 4289,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB773',
    id: 4290,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB783',
    id: 4291,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB763',
    id: 4292,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB768',
    id: 4293,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB764',
    id: 4294,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB774',
    id: 4295,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB772',
    id: 4296,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB767',
    id: 4297,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB771',
    id: 4298,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB766',
    id: 4299,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB769',
    id: 4300,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP15',
    id: 4301,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEMMP15',
    id: 4302,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF760',
    id: 4303,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 53',
    bic: 'DRESDEFFAGI',
    id: 4304,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 55',
    bic: 'DRESDEFFI25',
    id: 4305,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFJ26',
    id: 4306,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
    bic: 'DRESDEFFJ27',
    id: 4307,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI24',
    id: 4308,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI46',
    id: 4309,
  },
  {
    bankName: 'Evenord-Bank',
    bic: 'GENODEF1N03',
    id: 4310,
  },
  {
    bankName: 'Sparda-Bank Nürnberg',
    bic: 'GENODEF1S06',
    id: 4311,
  },
  {
    bankName: 'PSD Bank',
    bic: 'GENODEF1P17',
    id: 4312,
  },
  {
    bankName: 'CVW-Privatbank',
    bic: 'GENODEF1WHD',
    id: 4313,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM419',
    id: 4314,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM419',
    id: 4315,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM419',
    id: 4316,
  },
  {
    bankName: 'BSQ Bauspar',
    bic: 'QUBADE71XXX',
    id: 4317,
  },
  {
    bankName: 'Commerzbank Fürth Bayern',
    bic: 'COBADEFFXXX',
    id: 4318,
  },
  {
    bankName: 'Sparkasse Fürth',
    bic: 'BYLADEM1SFU',
    id: 4319,
  },
  {
    bankName: 'Sparkasse im Landkreis Neustadt a.d. Aisch - Bad Windsheim',
    bic: 'BYLADEM1NEA',
    id: 4320,
  },
  {
    bankName: 'Raiffeisen-Volksbank Fürth -alt-',
    bic: 'GENODEF1FUE',
    id: 4321,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM417',
    id: 4322,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM417',
    id: 4323,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM417',
    id: 4324,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM417',
    id: 4325,
  },
  {
    bankName: 'Commerzbank Erlangen',
    bic: 'COBADEFFXXX',
    id: 4326,
  },
  {
    bankName: 'Stadt- u. Kreissparkasse Erlangen Höchstadt Herzogenaurach',
    bic: 'BYLADEM1ERH',
    id: 4327,
  },
  {
    bankName: 'Sparkasse Forchheim',
    bic: 'BYLADEM1FOR',
    id: 4328,
  },
  {
    bankName: 'Kreissparkasse Höchstadt',
    bic: 'BYLADEM1HOS',
    id: 4329,
  },
  {
    bankName: 'VR-Bank Erlangen-Höchstadt-Herzogenaurach -alt-',
    bic: 'GENODEF1ER1',
    id: 4330,
  },
  {
    bankName: 'VR Bank Bamberg-Forchheim Volks- Raiffeisenbank',
    bic: 'GENODEF1FOH',
    id: 4331,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM065',
    id: 4332,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM065',
    id: 4333,
  },
  {
    bankName: 'Sparkasse Mittelfranken-Süd',
    bic: 'BYLADEM1SRS',
    id: 4334,
  },
  {
    bankName: 'Raiffeisenbank Roth-Schwabach',
    bic: 'GENODEF1SWR',
    id: 4335,
  },
  {
    bankName: 'Raiffeisenbank am Rothsee -alt-',
    bic: 'GENODEF1HPN',
    id: 4336,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM406',
    id: 4337,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM406',
    id: 4338,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM406',
    id: 4339,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM406',
    id: 4340,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM406',
    id: 4341,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM406',
    id: 4342,
  },
  {
    bankName: 'Sparkasse Ansbach',
    bic: 'BYLADEM1ANS',
    id: 4343,
  },
  {
    bankName: 'Kreis- und Stadtsparkasse Dinkelsbühl -alt-',
    bic: 'BYLADEM1DKB',
    id: 4344,
  },
  {
    bankName: 'Vereinigte Sparkassen Gunzenhausen',
    bic: 'BYLADEM1GUN',
    id: 4345,
  },
  {
    bankName: 'Stadt- und Kreissparkasse Rothenburg -alt-',
    bic: 'BYLADEM1ROT',
    id: 4346,
  },
  {
    bankName: 'VR-Bank Mittelfranken West',
    bic: 'GENODEF1ANS',
    id: 4347,
  },
  {
    bankName: 'VR-Bank Feuchtwangen-Dinkelsbühl',
    bic: 'GENODEF1DKV',
    id: 4348,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM411',
    id: 4349,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM411',
    id: 4350,
  },
  {
    bankName: 'Commerzbank Bamberg',
    bic: 'COBADEFFXXX',
    id: 4351,
  },
  {
    bankName: 'Sparkasse Bamberg',
    bic: 'BYLADEM1SKB',
    id: 4352,
  },
  {
    bankName: 'VR Bank Bamberg Raiffeisen-Volksbank -alt-',
    bic: 'GENODEF1BA2',
    id: 4353,
  },
  {
    bankName: 'Raiffeisenbank Obermain Nord',
    bic: 'GENODEF1ALK',
    id: 4354,
  },
  {
    bankName: 'Raiffeisen-Volksbank -alt-',
    bic: 'GENODEF1EBR',
    id: 4355,
  },
  {
    bankName: 'Raiffeisenbank Burgebrach-Stegaurach',
    bic: 'GENODEF1BGB',
    id: 4356,
  },
  {
    bankName: 'Raiffeisen-Volksbank Bad Staffelstein',
    bic: 'GENODEF1SFF',
    id: 4357,
  },
  {
    bankName: 'Raiffeisenbank Küps-Mitwitz-Stockheim',
    bic: 'GENODEF1KC2',
    id: 4358,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1HIS',
    id: 4359,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1HOB',
    id: 4360,
  },
  {
    bankName: 'Raiffeisenbank Ebrachgrund',
    bic: 'GENODEF1SFD',
    id: 4361,
  },
  {
    bankName: 'Vereinigte Raiffeisenbanken -alt-',
    bic: 'GENODEF1GBF',
    id: 4362,
  },
  {
    bankName: 'Raiffeisenbank Thurnauer Land',
    bic: 'GENODEF1THA',
    id: 4363,
  },
  {
    bankName: 'Raiffeisenbank Emtmannsberg -alt-',
    bic: 'GENODEF1ETB',
    id: 4364,
  },
  {
    bankName: 'Raiffeisenbank Oberpfalz NordWest',
    bic: 'GENODEF1KEM',
    id: 4365,
  },
  {
    bankName: 'Raiffeisenbank am Kulm',
    bic: 'GENODEF1SPK',
    id: 4366,
  },
  {
    bankName: 'Raiffeisenbank Berg-Bad Steben -alt-',
    bic: 'GENODEF1BGO',
    id: 4367,
  },
  {
    bankName: 'Raiffeisenbank Oberland',
    bic: 'GENODEF1MGA',
    id: 4368,
  },
  {
    bankName: 'Raiffeisenbank Hochfranken West',
    bic: 'GENODEF1SZF',
    id: 4369,
  },
  {
    bankName: 'Raiffeisenbank Wüstenselbitz',
    bic: 'GENODEF1WSZ',
    id: 4370,
  },
  {
    bankName: 'Raiffeisenbank Sparneck-Stammbach-Zell -alt-',
    bic: 'GENODEF1ZSP',
    id: 4371,
  },
  {
    bankName: 'VR-Bank Lichtenfels-Ebern',
    bic: 'GENODEF1LIF',
    id: 4372,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM289',
    id: 4373,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM289',
    id: 4374,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4375,
  },
  {
    bankName: 'Sparkasse Kulmbach-Kronach',
    bic: 'BYLADEM1KUB',
    id: 4376,
  },
  {
    bankName: 'VR Bank Oberfranken Mitte',
    bic: 'GENODEF1KU1',
    id: 4377,
  },
  {
    bankName: 'Bundesbank eh Bayreuth',
    bic: 'MARKDEF1773',
    id: 4378,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM412',
    id: 4379,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM412',
    id: 4380,
  },
  {
    bankName: 'Fondsdepot Bank',
    bic: 'FODBDE77XXX',
    id: 4381,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4382,
  },
  {
    bankName: 'Sparkasse Bayreuth',
    bic: 'BYLADEM1SBT',
    id: 4383,
  },
  {
    bankName: 'Raiffeisen-Volksbank Kronach-Ludwigsstadt -alt-',
    bic: 'GENODEF1KC1',
    id: 4384,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1GFS',
    id: 4385,
  },
  {
    bankName: 'Raiffeisenbank Fränkische Schweiz',
    bic: 'GENODEF1HWA',
    id: 4386,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM424',
    id: 4387,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM424',
    id: 4388,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM424',
    id: 4389,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM424',
    id: 4390,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM424',
    id: 4391,
  },
  {
    bankName: 'Commerzbank Hof Saale',
    bic: 'COBADEFFXXX',
    id: 4392,
  },
  {
    bankName: 'Sparkasse Hochfranken',
    bic: 'BYLADEM1HOF',
    id: 4393,
  },
  {
    bankName: 'Sparkasse Hochfranken -alt-',
    bic: 'BYLADEM1FIG',
    id: 4394,
  },
  {
    bankName: 'VR Bank Bayreuth-Hof',
    bic: 'GENODEF1HO1',
    id: 4395,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4396,
  },
  {
    bankName: 'VR-Bank Fichtelgebirge-Frankenwald',
    bic: 'GENODEF1MAK',
    id: 4397,
  },
  {
    bankName: 'Raiffeisenbank im Stiftland -alt-',
    bic: 'GENODEF1WSS',
    id: 4398,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM480',
    id: 4399,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM480',
    id: 4400,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4401,
  },
  {
    bankName: 'Sparkasse Coburg-Lichtenfels',
    bic: 'BYLADEM1COB',
    id: 4402,
  },
  {
    bankName: 'VR-Bank Coburg',
    bic: 'GENODEF1COS',
    id: 4403,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1790',
    id: 4404,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM455',
    id: 4405,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM455',
    id: 4406,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM455',
    id: 4407,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM455',
    id: 4408,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM455',
    id: 4409,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM455',
    id: 4410,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM455',
    id: 4411,
  },
  {
    bankName: 'Fürstlich Castellsche Bank Credit-Casse',
    bic: 'FUCEDE77XXX',
    id: 4412,
  },
  {
    bankName: 'MERKUR PRIVATBANK',
    bic: 'BSHADE71XXX',
    id: 4413,
  },
  {
    bankName: 'Commerzbank Würzburg',
    bic: 'COBADEFFXXX',
    id: 4414,
  },
  {
    bankName: 'Sparkasse Mainfranken Würzburg',
    bic: 'BYLADEM1SWU',
    id: 4415,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1HAB',
    id: 4416,
  },
  {
    bankName: 'Raiffeisenbank Estenfeld-Bergtheim',
    bic: 'GENODEF1EFD',
    id: 4417,
  },
  {
    bankName: 'Raiffeisenbank Höchberg',
    bic: 'GENODEF1HBG',
    id: 4418,
  },
  {
    bankName: 'VR-Bank Bad Kissingen',
    bic: 'GENODEF1BRK',
    id: 4419,
  },
  {
    bankName: 'Raiffeisenbank Mainschleife - Steigerwald',
    bic: 'GENODEF1WED',
    id: 4420,
  },
  {
    bankName: 'VR-Bank Schweinfurt -alt-',
    bic: 'GENODEF1ATE',
    id: 4421,
  },
  {
    bankName: 'Raiffeisenbank Bütthard-Gaukönigshofen',
    bic: 'GENODEF1BHD',
    id: 4422,
  },
  {
    bankName: 'Raiffeisenbank Main-Spessart',
    bic: 'GENODEF1GEM',
    id: 4423,
  },
  {
    bankName: 'VR-Bank Main-Rhön',
    bic: 'GENODEF1MLV',
    id: 4424,
  },
  {
    bankName: 'Raiffeisenbank',
    bic: 'GENODEF1NDL',
    id: 4425,
  },
  {
    bankName: 'Raiffeisenbank im Grabfeld',
    bic: 'GENODEF1SLZ',
    id: 4426,
  },
  {
    bankName: 'Raiffeisenbank Maßbach',
    bic: 'GENODEF1RNM',
    id: 4427,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM790',
    id: 4428,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM791',
    id: 4429,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEMM792',
    id: 4430,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB790',
    id: 4431,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB792',
    id: 4432,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB791',
    id: 4433,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF790',
    id: 4434,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFJ28',
    id: 4435,
  },
  {
    bankName: 'Volksbank Raiffeisenbank Würzburg',
    bic: 'GENODEF1WU1',
    id: 4436,
  },
  {
    bankName: 'Raiffeisenbank Fränkisches Weinland -alt-',
    bic: 'GENODEF1ERN',
    id: 4437,
  },
  {
    bankName: 'Raiffeisenbank Kitzinger Land -alt-',
    bic: 'GENODEF1OBR',
    id: 4438,
  },
  {
    bankName: 'VR Bank Kitzingen',
    bic: 'GENODEF1KT1',
    id: 4439,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM451',
    id: 4440,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM451',
    id: 4441,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM451',
    id: 4442,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM451',
    id: 4443,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM451',
    id: 4444,
  },
  {
    bankName: 'Bankhaus Max Flessa',
    bic: 'FLESDEMMXXX',
    id: 4445,
  },
  {
    bankName: 'Commerzbank Schweinfurt',
    bic: 'COBADEFFXXX',
    id: 4446,
  },
  {
    bankName: 'Städtische Sparkasse Schweinfurt -alt-',
    bic: 'BYLADEM1SSW',
    id: 4447,
  },
  {
    bankName: 'Sparkasse Schweinfurt-Haßberge',
    bic: 'BYLADEM1KSW',
    id: 4448,
  },
  {
    bankName: 'Sparkasse Bad Kissingen',
    bic: 'BYLADEM1KIS',
    id: 4449,
  },
  {
    bankName: 'Sparkasse Ostunterfranken -alt-',
    bic: 'BYLADEM1HAS',
    id: 4450,
  },
  {
    bankName: 'Sparkasse Bad Neustadt a d Saale',
    bic: 'BYLADEM1NES',
    id: 4451,
  },
  {
    bankName: 'VR-Bank Gerolzhofen',
    bic: 'GENODEF1GZH',
    id: 4452,
  },
  {
    bankName: 'Volksbank Raiffeisenbank Rhön-Grabfeld -alt-',
    bic: 'GENODEF1NDT',
    id: 4453,
  },
  {
    bankName: 'Raiffeisen-Volksbank Haßberge',
    bic: 'GENODEF1HAS',
    id: 4454,
  },
  {
    bankName: 'Raiffeisenbank Frankenwinheim und Umgebung -alt-',
    bic: 'GENODEF1FWH',
    id: 4455,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF793',
    id: 4456,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM407',
    id: 4457,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM407',
    id: 4458,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM407',
    id: 4459,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM407',
    id: 4460,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4461,
  },
  {
    bankName: 'Sparkasse Aschaffenburg Alzenau',
    bic: 'BYLADEM1ASA',
    id: 4462,
  },
  {
    bankName: 'Raiffeisenbank Bachgau -alt-',
    bic: 'GENODEF1BAG',
    id: 4463,
  },
  {
    bankName: 'Raiffeisen-Volksbank Aschaffenburg',
    bic: 'GENODEF1AB1',
    id: 4464,
  },
  {
    bankName: 'Raiffeisenbank Waldaschaff-Heigenbrücken',
    bic: 'GENODEF1WAA',
    id: 4465,
  },
  {
    bankName: 'VR-Bank -alt-',
    bic: 'GENODEF1ALZ',
    id: 4466,
  },
  {
    bankName: 'Raiffeisenbank Haibach-Obernau',
    bic: 'GENODEF1HAC',
    id: 4467,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB795',
    id: 4468,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB796',
    id: 4469,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF795',
    id: 4470,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEFF796',
    id: 4471,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP18',
    id: 4472,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEFFS18',
    id: 4473,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF795',
    id: 4474,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI47',
    id: 4475,
  },
  {
    bankName: 'Volksbank Aschaffenburg -alt-',
    bic: 'GENODEF1AB2',
    id: 4476,
  },
  {
    bankName: 'Sparkasse Miltenberg-Obernburg',
    bic: 'BYLADEM1MIL',
    id: 4477,
  },
  {
    bankName: 'Raiffeisenbank Elsavatal',
    bic: 'GENODEF1EAU',
    id: 4478,
  },
  {
    bankName: 'Raiffeisenbank Großostheim-Obernburg -alt-',
    bic: 'GENODEF1OBE',
    id: 4479,
  },
  {
    bankName: 'Raiffeisenbank Eichenbühl und Umgebung',
    bic: 'GENODEF1ENB',
    id: 4480,
  },
  {
    bankName: 'Raiffeisen-Volksbank Miltenberg -alt-',
    bic: 'GENODEF1MIL',
    id: 4481,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM440',
    id: 4482,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM440',
    id: 4483,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM440',
    id: 4484,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM462',
    id: 4485,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM462',
    id: 4486,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4487,
  },
  {
    bankName: 'Kreissparkasse Merseburg-Querfurt -alt-',
    bic: 'NOLADE21MQU',
    id: 4488,
  },
  {
    bankName: 'Sparkasse Burgenlandkreis',
    bic: 'NOLADE21BLK',
    id: 4489,
  },
  {
    bankName: 'Stadtsparkasse Dessau',
    bic: 'NOLADE21DES',
    id: 4490,
  },
  {
    bankName: 'Kreissparkasse Köthen -alt-',
    bic: 'NOLADE21KOT',
    id: 4491,
  },
  {
    bankName: 'Kreissparkasse Anhalt-Bitterfeld',
    bic: 'NOLADE21BTF',
    id: 4492,
  },
  {
    bankName: 'Saalesparkasse',
    bic: 'NOLADE21HAL',
    id: 4493,
  },
  {
    bankName: 'Kreissparkasse Weißenfels -alt-',
    bic: 'NOLADE21WSF',
    id: 4494,
  },
  {
    bankName: 'Sparkasse Mansfeld-Südharz',
    bic: 'NOLADE21EIL',
    id: 4495,
  },
  {
    bankName: 'Salzlandsparkasse',
    bic: 'NOLADE21SES',
    id: 4496,
  },
  {
    bankName: 'Volksbank Elsterland -alt-',
    bic: 'GENODEF1JE1',
    id: 4497,
  },
  {
    bankName: 'Harzer Volksbank',
    bic: 'GENODEF1QLB',
    id: 4498,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODEF1SGH',
    id: 4499,
  },
  {
    bankName: 'Volksbank Wittenberg',
    bic: 'GENODEF1WB1',
    id: 4500,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODEF1KOE',
    id: 4501,
  },
  {
    bankName: 'Volks- und Raiffeisenbank Saale-Unstrut',
    bic: 'GENODEF1NMB',
    id: 4502,
  },
  {
    bankName: 'VR-Bank Zeitz -alt-',
    bic: 'GENODEF1ZTZ',
    id: 4503,
  },
  {
    bankName: 'Volks- und Raiffeisenbank Eisleben -alt-',
    bic: 'GENODEF1EIL',
    id: 4504,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF800',
    id: 4505,
  },
  {
    bankName: 'Volksbank Dessau-Anhalt',
    bic: 'GENODEF1DS1',
    id: 4506,
  },
  {
    bankName: 'Volksbank Halle, Saale',
    bic: 'GENODEF1HAL',
    id: 4507,
  },
  {
    bankName: 'Sparkasse Wittenberg',
    bic: 'NOLADE21WBL',
    id: 4508,
  },
  {
    bankName: 'Kreissparkasse Anhalt-Zerbst -alt-',
    bic: 'NOLADE21ZER',
    id: 4509,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1810',
    id: 4510,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33MAG',
    id: 4511,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4512,
  },
  {
    bankName: 'Kreissparkasse Stendal',
    bic: 'NOLADE21SDL',
    id: 4513,
  },
  {
    bankName: 'Harzsparkasse',
    bic: 'NOLADE21HRZ',
    id: 4514,
  },
  {
    bankName: 'Sparkasse MagdeBurg',
    bic: 'NOLADE21MDG',
    id: 4515,
  },
  {
    bankName: 'Sparkasse Jerichower Land',
    bic: 'NOLADE21JEL',
    id: 4516,
  },
  {
    bankName: 'Kreissparkasse Börde',
    bic: 'NOLADE21HDL',
    id: 4517,
  },
  {
    bankName: 'Sparkasse Altmark West',
    bic: 'NOLADE21SAW',
    id: 4518,
  },
  {
    bankName: 'Raiffeisenbank Kalbe-Bismark',
    bic: 'GENODEF1KAB',
    id: 4519,
  },
  {
    bankName: 'Volksbank Jerichower Land',
    bic: 'GENODEF1BRG',
    id: 4520,
  },
  {
    bankName: 'Bank für Kirche und Diakonie - KD-Bank Gf Sonder-BLZ',
    bic: 'GENODED1KDM',
    id: 4521,
  },
  {
    bankName: 'Volksbank Börde-Bernburg',
    bic: 'GENODEF1WZL',
    id: 4522,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8MXXX',
    id: 4523,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8M814',
    id: 4524,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8M812',
    id: 4525,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8M816',
    id: 4526,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8M820',
    id: 4527,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8M818',
    id: 4528,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8M821',
    id: 4529,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8M822',
    id: 4530,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8M823',
    id: 4531,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8M815',
    id: 4532,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8M817',
    id: 4533,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8M819',
    id: 4534,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8M811',
    id: 4535,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8M825',
    id: 4536,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBMAG',
    id: 4537,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB815',
    id: 4538,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB814',
    id: 4539,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB811',
    id: 4540,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB812',
    id: 4541,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB816',
    id: 4542,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB817',
    id: 4543,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB818',
    id: 4544,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB819',
    id: 4545,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB801',
    id: 4546,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB802',
    id: 4547,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB803',
    id: 4548,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB804',
    id: 4549,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB806',
    id: 4550,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF810',
    id: 4551,
  },
  {
    bankName: 'Volksbank',
    bic: 'GENODEF1GA1',
    id: 4552,
  },
  {
    bankName: 'Volksbank Stendal',
    bic: 'GENODEF1SDL',
    id: 4553,
  },
  {
    bankName: 'Volksbank Magdeburg',
    bic: 'GENODEF1MD1',
    id: 4554,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1820',
    id: 4555,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM498',
    id: 4556,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM098',
    id: 4557,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM824',
    id: 4558,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4559,
  },
  {
    bankName: 'Commerzbank, Gf Web-K',
    bic: 'COBADEFFXXX',
    id: 4560,
  },
  {
    bankName: 'Landesbank Hessen-Thür Girozentrale Erfurt',
    bic: 'HELADEFF820',
    id: 4561,
  },
  {
    bankName: 'Sparkasse Mittelthüringen',
    bic: 'HELADEF1WEM',
    id: 4562,
  },
  {
    bankName: 'Kreissparkasse Gotha',
    bic: 'HELADEF1GTH',
    id: 4563,
  },
  {
    bankName: 'Kreissparkasse Nordhausen',
    bic: 'HELADEF1NOR',
    id: 4564,
  },
  {
    bankName: 'Kyffhäusersparkasse',
    bic: 'HELADEF1KYF',
    id: 4565,
  },
  {
    bankName: 'Sparkasse Unstrut-Hainich',
    bic: 'HELADEF1MUE',
    id: 4566,
  },
  {
    bankName: 'Kreissparkasse Eichsfeld',
    bic: 'HELADEF1EIC',
    id: 4567,
  },
  {
    bankName: 'Pax-Bank',
    bic: 'GENODED1PA5',
    id: 4568,
  },
  {
    bankName: 'VR Bank Westthüringen',
    bic: 'GENODEF1MU2',
    id: 4569,
  },
  {
    bankName: 'VR-Bank Eisenach-Ronshausen',
    bic: 'GENODEF1ESA',
    id: 4570,
  },
  {
    bankName: 'Raiffeisenbank Gotha',
    bic: 'GENODEF1GTH',
    id: 4571,
  },
  {
    bankName: 'VR Bank Weimar',
    bic: 'GENODEF1WE1',
    id: 4572,
  },
  {
    bankName: 'Erfurter Bank -alt-',
    bic: 'ERFBDE8EXXX',
    id: 4573,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8EXXX',
    id: 4574,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E828',
    id: 4575,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E829',
    id: 4576,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E842',
    id: 4577,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E843',
    id: 4578,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E832',
    id: 4579,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E830',
    id: 4580,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E827',
    id: 4581,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E833',
    id: 4582,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E844',
    id: 4583,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E831',
    id: 4584,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E845',
    id: 4585,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E825',
    id: 4586,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E824',
    id: 4587,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E835',
    id: 4588,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E847',
    id: 4589,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E848',
    id: 4590,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E849',
    id: 4591,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E822',
    id: 4592,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E850',
    id: 4593,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E823',
    id: 4594,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E840',
    id: 4595,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E820',
    id: 4596,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E836',
    id: 4597,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E841',
    id: 4598,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E826',
    id: 4599,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E851',
    id: 4600,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8E846',
    id: 4601,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBERF',
    id: 4602,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB828',
    id: 4603,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB841',
    id: 4604,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB829',
    id: 4605,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB842',
    id: 4606,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB843',
    id: 4607,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB832',
    id: 4608,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB830',
    id: 4609,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB827',
    id: 4610,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB833',
    id: 4611,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB844',
    id: 4612,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB831',
    id: 4613,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB826',
    id: 4614,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB845',
    id: 4615,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB825',
    id: 4616,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB824',
    id: 4617,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB846',
    id: 4618,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB835',
    id: 4619,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB847',
    id: 4620,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB848',
    id: 4621,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB849',
    id: 4622,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB823',
    id: 4623,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB822',
    id: 4624,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB850',
    id: 4625,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB840',
    id: 4626,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB851',
    id: 4627,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB820',
    id: 4628,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB821',
    id: 4629,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB836',
    id: 4630,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP20',
    id: 4631,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDE8EP20',
    id: 4632,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF827',
    id: 4633,
  },
  {
    bankName: 'Volksbank Heiligenstadt -alt-',
    bic: 'GENODEF1HIG',
    id: 4634,
  },
  {
    bankName: 'Nordthüringer Volksbank',
    bic: 'GENODEF1NDS',
    id: 4635,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM468',
    id: 4636,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM468',
    id: 4637,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM463',
    id: 4638,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM484',
    id: 4639,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4640,
  },
  {
    bankName: 'Sparkasse Gera-Greiz',
    bic: 'HELADEF1GER',
    id: 4641,
  },
  {
    bankName: 'Sparkasse Altenburger Land',
    bic: 'HELADEF1ALT',
    id: 4642,
  },
  {
    bankName: 'Kreissparkasse Saalfeld-Rudolstadt',
    bic: 'HELADEF1SAR',
    id: 4643,
  },
  {
    bankName: 'Kreissparkasse Saale-Orla',
    bic: 'HELADEF1SOK',
    id: 4644,
  },
  {
    bankName: 'Sparkasse Jena-Saale-Holzland',
    bic: 'HELADEF1JEN',
    id: 4645,
  },
  {
    bankName: 'Raiffeisen-Volksbank Hermsdorfer Kreuz',
    bic: 'GENODEF1HMF',
    id: 4646,
  },
  {
    bankName: 'Geraer Bank -alt-',
    bic: 'GENODEF1GEV',
    id: 4647,
  },
  {
    bankName: 'VR-Bank Altenburger Land / Deutsche Skatbank',
    bic: 'GENODEF1SLR',
    id: 4648,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF830',
    id: 4649,
  },
  {
    bankName: 'Raiffeisen-Volksbank Saale-Orla -alt-',
    bic: 'GENODEF1PN1',
    id: 4650,
  },
  {
    bankName: 'Volksbank Gera-Jena-Rudolstadt',
    bic: 'GENODEF1RUJ',
    id: 4651,
  },
  {
    bankName: 'Volksbank Eisenberg',
    bic: 'GENODEF1ESN',
    id: 4652,
  },
  {
    bankName: 'EthikBank, Zndl der Volksbank Eisenberg',
    bic: 'GENODEF1ETK',
    id: 4653,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM458',
    id: 4654,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4655,
  },
  {
    bankName: 'Rhön-Rennsteig-Sparkasse',
    bic: 'HELADEF1RRS',
    id: 4656,
  },
  {
    bankName: 'Sparkasse Arnstadt-Ilmenau',
    bic: 'HELADEF1ILK',
    id: 4657,
  },
  {
    bankName: 'Kreissparkasse Hildburghausen',
    bic: 'HELADEF1HIL',
    id: 4658,
  },
  {
    bankName: 'Sparkasse Sonneberg',
    bic: 'HELADEF1SON',
    id: 4659,
  },
  {
    bankName: 'Wartburg-Sparkasse',
    bic: 'HELADEF1WAK',
    id: 4660,
  },
  {
    bankName: 'Genobank Rhön-Grabfeld',
    bic: 'GENODEF1MLF',
    id: 4661,
  },
  {
    bankName: 'Raiffeisenbank Schleusingen -alt-',
    bic: 'GENODEF1SSG',
    id: 4662,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF843',
    id: 4663,
  },
  {
    bankName: 'VR-Bank Bad Salzungen Schmalkalden',
    bic: 'GENODEF1SAL',
    id: 4664,
  },
  {
    bankName: 'VR-Bank Bad Salzungen Schmalkalden GAA',
    bic: 'GENODEF1SAL',
    id: 4665,
  },
  {
    bankName: 'Volksbank Thüringen Mitte',
    bic: 'GENODEF1SHL',
    id: 4666,
  },
  {
    bankName: 'Bundesbank eh Dresden',
    bic: 'MARKDEF1850',
    id: 4667,
  },
  {
    bankName: 'Sächsische Aufbaubank -Förderbank-',
    bic: 'SABDDE81XXX',
    id: 4668,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM496',
    id: 4669,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM496',
    id: 4670,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33DRE',
    id: 4671,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4672,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 4673,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 4674,
  },
  {
    bankName: 'Sparkasse Oberlausitz-Niederschlesien',
    bic: 'WELADED1GRL',
    id: 4675,
  },
  {
    bankName: 'Ostsächsische Sparkasse Dresden',
    bic: 'OSDDDE81XXX',
    id: 4676,
  },
  {
    bankName: 'Ostsächsische Sparkasse Dresden',
    bic: 'OSDDDE81SEB',
    id: 4677,
  },
  {
    bankName: 'Ostsächsische Sparkasse Dresden',
    bic: 'OSDDDE81FTL',
    id: 4678,
  },
  {
    bankName: 'Ostsächsische Sparkasse Dresden',
    bic: 'OSDDDE81HDN',
    id: 4679,
  },
  {
    bankName: 'Ostsächsische Sparkasse Dresden',
    bic: 'OSDDDE81HOY',
    id: 4680,
  },
  {
    bankName: 'Ostsächsische Sparkasse Dresden',
    bic: 'OSDDDE81KMZ',
    id: 4681,
  },
  {
    bankName: 'Ostsächsische Sparkasse Dresden',
    bic: 'OSDDDE81RBG',
    id: 4682,
  },
  {
    bankName: 'Ostsächsische Sparkasse Dresden',
    bic: 'OSDDDE81PIR',
    id: 4683,
  },
  {
    bankName: 'Sparkasse Meißen',
    bic: 'SOLADES1MEI',
    id: 4684,
  },
  {
    bankName: 'Volksbank Pirna',
    bic: 'GENODEF1PR2',
    id: 4685,
  },
  {
    bankName: 'Raiffeisenbank Neustadt, Sachs -alt-',
    bic: 'GENODEF1SEB',
    id: 4686,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF850',
    id: 4687,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFJ29',
    id: 4688,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
    bic: 'DRESDEFFJ30',
    id: 4689,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF857',
    id: 4690,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI15',
    id: 4691,
  },
  {
    bankName: 'Volksbank Dresden-Bautzen',
    bic: 'GENODEF1DRS',
    id: 4692,
  },
  {
    bankName: 'Volksbank Riesa',
    bic: 'GENODEF1RIE',
    id: 4693,
  },
  {
    bankName: 'Volksbank Raiffeisenbank Meißen Großenhain',
    bic: 'GENODEF1MEI',
    id: 4694,
  },
  {
    bankName: 'Kreissparkasse Bautzen',
    bic: 'SOLADES1BAT',
    id: 4695,
  },
  {
    bankName: 'Volksbank Bautzen -alt-',
    bic: 'GENODEF1BZV',
    id: 4696,
  },
  {
    bankName: 'Volksbank Löbau-Zittau',
    bic: 'GENODEF1NGS',
    id: 4697,
  },
  {
    bankName: 'Volksbank Raiffeisenbank Niederschlesien',
    bic: 'GENODEF1GR1',
    id: 4698,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1860',
    id: 4699,
  },
  {
    bankName: 'Postbank Ndl der Deutsche Bank',
    bic: 'PBNKDEFFXXX',
    id: 4700,
  },
  {
    bankName: 'Aareal Bank',
    bic: 'AARBDE5W860',
    id: 4701,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM495',
    id: 4702,
  },
  {
    bankName: 'Bank für Sozialwirtschaft',
    bic: 'BFSWDE33LPZ',
    id: 4703,
  },
  {
    bankName: 'Santander Consumer Bank',
    bic: 'SCFBDE33XXX',
    id: 4704,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4705,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 4706,
  },
  {
    bankName: 'Commerzbank CC',
    bic: 'COBADEFFXXX',
    id: 4707,
  },
  {
    bankName: 'ZV Landesbank Baden-Württemberg',
    bic: 'SOLADEST861',
    id: 4708,
  },
  {
    bankName: 'Sparkasse Muldental',
    bic: 'SOLADES1GRM',
    id: 4709,
  },
  {
    bankName: 'Kreissparkasse Torgau-Oschatz -alt-',
    bic: 'WELADED1TGU',
    id: 4710,
  },
  {
    bankName: 'Sparkasse Delitzsch-Eilenburg -alt-',
    bic: 'SOLADES1DES',
    id: 4711,
  },
  {
    bankName: 'Kreissparkasse Döbeln',
    bic: 'SOLADES1DLN',
    id: 4712,
  },
  {
    bankName: 'Stadt- und Kreissparkasse Leipzig',
    bic: 'WELADE8LXXX',
    id: 4713,
  },
  {
    bankName: 'VR Bank Leipziger Land',
    bic: 'GENODEF1BOA',
    id: 4714,
  },
  {
    bankName: 'VR-Bank Mittelsachsen',
    bic: 'GENODEF1DL1',
    id: 4715,
  },
  {
    bankName: 'Raiffeisenbank Grimma',
    bic: 'GENODEF1GMR',
    id: 4716,
  },
  {
    bankName: 'Raiffeisenbank -alt-',
    bic: 'GENODEF1TGB',
    id: 4717,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8LXXX',
    id: 4718,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L861',
    id: 4719,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L862',
    id: 4720,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L863',
    id: 4721,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L864',
    id: 4722,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L865',
    id: 4723,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L867',
    id: 4724,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L871',
    id: 4725,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L860',
    id: 4726,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L873',
    id: 4727,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L875',
    id: 4728,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L877',
    id: 4729,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L880',
    id: 4730,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L883',
    id: 4731,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L884',
    id: 4732,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L885',
    id: 4733,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L887',
    id: 4734,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L888',
    id: 4735,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L866',
    id: 4736,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L868',
    id: 4737,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L869',
    id: 4738,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L872',
    id: 4739,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L870',
    id: 4740,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L874',
    id: 4741,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L878',
    id: 4742,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L879',
    id: 4743,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L881',
    id: 4744,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8L882',
    id: 4745,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBLEG',
    id: 4746,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB861',
    id: 4747,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB862',
    id: 4748,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB863',
    id: 4749,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB864',
    id: 4750,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB865',
    id: 4751,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB866',
    id: 4752,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB867',
    id: 4753,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB868',
    id: 4754,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB869',
    id: 4755,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB974',
    id: 4756,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB860',
    id: 4757,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB975',
    id: 4758,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB976',
    id: 4759,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB973',
    id: 4760,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB988',
    id: 4761,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB977',
    id: 4762,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB978',
    id: 4763,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB980',
    id: 4764,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB981',
    id: 4765,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB982',
    id: 4766,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB983',
    id: 4767,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB984',
    id: 4768,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB985',
    id: 4769,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB986',
    id: 4770,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB987',
    id: 4771,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB990',
    id: 4772,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB991',
    id: 4773,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDE8LP37',
    id: 4774,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP37',
    id: 4775,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF860',
    id: 4776,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Zw 55',
    bic: 'DRESDEFF862',
    id: 4777,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank Gf ZW 57',
    bic: 'DRESDEFF867',
    id: 4778,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
    bic: 'DRESDEFFJ31',
    id: 4779,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
    bic: 'DRESDEFFJ32',
    id: 4780,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank ITGK',
    bic: 'DRESDEFFI16',
    id: 4781,
  },
  {
    bankName: 'Volks- und Raiffeisenbank Muldental',
    bic: 'GENODEF1GMV',
    id: 4782,
  },
  {
    bankName: 'Volksbank Delitzsch',
    bic: 'GENODEF1DZ1',
    id: 4783,
  },
  {
    bankName: 'Leipziger Volksbank',
    bic: 'GENODEF1LVB',
    id: 4784,
  },
  {
    bankName: 'Bundesbank',
    bic: 'MARKDEF1870',
    id: 4785,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM497',
    id: 4786,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM481',
    id: 4787,
  },
  {
    bankName: 'UniCredit Bank - HypoVereinsbank',
    bic: 'HYVEDEMM441',
    id: 4788,
  },
  {
    bankName: 'Commerzbank',
    bic: 'COBADEFFXXX',
    id: 4789,
  },
  {
    bankName: 'Sparkasse Chemnitz',
    bic: 'CHEKDE81XXX',
    id: 4790,
  },
  {
    bankName: 'Sparkasse Chemnitz',
    bic: 'CHEKDE81MRN',
    id: 4791,
  },
  {
    bankName: 'Sparkasse Chemnitz',
    bic: 'CHEKDE81LTS',
    id: 4792,
  },
  {
    bankName: 'Sparkasse Chemnitz',
    bic: 'CHEKDE81LIM',
    id: 4793,
  },
  {
    bankName: 'Sparkasse Chemnitz',
    bic: 'CHEKDE81GLA',
    id: 4794,
  },
  {
    bankName: 'Sparkasse Chemnitz',
    bic: 'CHEKDE81HOT',
    id: 4795,
  },
  {
    bankName: 'Sparkasse Mittelsachsen -alt-',
    bic: 'WELADED1MTW',
    id: 4796,
  },
  {
    bankName: 'Sparkasse Mittelsachsen',
    bic: 'WELADED1FGX',
    id: 4797,
  },
  {
    bankName: 'Erzgebirgssparkasse',
    bic: 'WELADED1STB',
    id: 4798,
  },
  {
    bankName: 'Sparkasse Zwickau',
    bic: 'WELADED1ZWI',
    id: 4799,
  },
  {
    bankName: 'Sparkasse Vogtland',
    bic: 'WELADED1PLX',
    id: 4800,
  },
  {
    bankName: 'Volksbank Mittleres Erzgebirge',
    bic: 'GENODEF1MBG',
    id: 4801,
  },
  {
    bankName: 'Vereinigte Raiffeisenbank Burgstädt',
    bic: 'GENODEF1BST',
    id: 4802,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8CXXX',
    id: 4803,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C871',
    id: 4804,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C874',
    id: 4805,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C877',
    id: 4806,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C870',
    id: 4807,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C879',
    id: 4808,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C880',
    id: 4809,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C881',
    id: 4810,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C882',
    id: 4811,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C883',
    id: 4812,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C885',
    id: 4813,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C886',
    id: 4814,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C887',
    id: 4815,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C888',
    id: 4816,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C890',
    id: 4817,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C892',
    id: 4818,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C894',
    id: 4819,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C895',
    id: 4820,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C897',
    id: 4821,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C898',
    id: 4822,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C899',
    id: 4823,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C901',
    id: 4824,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C902',
    id: 4825,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C903',
    id: 4826,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C906',
    id: 4827,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C907',
    id: 4828,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C909',
    id: 4829,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C910',
    id: 4830,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C872',
    id: 4831,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C873',
    id: 4832,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C875',
    id: 4833,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C876',
    id: 4834,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C884',
    id: 4835,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C889',
    id: 4836,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C891',
    id: 4837,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C893',
    id: 4838,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C896',
    id: 4839,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C900',
    id: 4840,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C905',
    id: 4841,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C908',
    id: 4842,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDE8C878',
    id: 4843,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDBCHE',
    id: 4844,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB871',
    id: 4845,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB872',
    id: 4846,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB873',
    id: 4847,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB874',
    id: 4848,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB875',
    id: 4849,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB876',
    id: 4850,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB877',
    id: 4851,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB870',
    id: 4852,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB878',
    id: 4853,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB879',
    id: 4854,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB880',
    id: 4855,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB881',
    id: 4856,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB882',
    id: 4857,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB883',
    id: 4858,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB884',
    id: 4859,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB885',
    id: 4860,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB886',
    id: 4861,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB887',
    id: 4862,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB888',
    id: 4863,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB897',
    id: 4864,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB889',
    id: 4865,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB890',
    id: 4866,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB891',
    id: 4867,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB892',
    id: 4868,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB893',
    id: 4869,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB894',
    id: 4870,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB895',
    id: 4871,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB896',
    id: 4872,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB898',
    id: 4873,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB899',
    id: 4874,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB900',
    id: 4875,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB901',
    id: 4876,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB902',
    id: 4877,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB903',
    id: 4878,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB905',
    id: 4879,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB906',
    id: 4880,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB907',
    id: 4881,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB908',
    id: 4882,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB909',
    id: 4883,
  },
  {
    bankName: 'Deutsche Bank',
    bic: 'DEUTDEDB910',
    id: 4884,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDE8CP36',
    id: 4885,
  },
  {
    bankName: 'Postbank/DSL Ndl der Deutsche Bank',
    bic: 'DEUTDEDBP36',
    id: 4886,
  },
  {
    bankName: 'Commerzbank vormals Dresdner Bank',
    bic: 'DRESDEFF870',
    id: 4887,
  },
  {
    bankName: 'Volksbank Vogtland-Saale-Orla',
    bic: 'GENODEF1PL1',
    id: 4888,
  },
  {
    bankName: 'Volksbank Vogtland GAA',
    bic: 'GENODEF1EXT',
    id: 4889,
  },
  {
    bankName: 'Volksbank Zwickau',
    bic: 'GENODEF1Z01',
    id: 4890,
  },
  {
    bankName: 'Volksbank-Raiffeisenbank Glauchau',
    bic: 'GENODEF1GC1',
    id: 4891,
  },
  {
    bankName: 'Volksbank Mittweida',
    bic: 'GENODEF1MIW',
    id: 4892,
  },
  {
    bankName: 'Volksbank Chemnitz',
    bic: 'GENODEF1CH1',
    id: 4893,
  },
];

export { bankNameKeyLookup };
