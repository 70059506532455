import React, { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { usePrevious } from 'util/stateUtils';
import { useSnackbarContext } from '../../common/context/snackbarContext';
import useErrorHandler from './useErrorHandler';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  snackbarContainer: {
    zIndex: 9999,
  },
  content: {
    marginLeft: '0.5rem',
  },
  count: {
    fontSize: '0.8rem',
    opacity: 0.7,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  error: {
    backgroundColor: '#ca0036',
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
}));

const ContextNotification = () => {
  useErrorHandler();
  const { t } = useTranslation();
  const { readMessage, message, messages } = useSnackbarContext();
  const prevMessage = usePrevious(message);
  const prevMessagesLength = usePrevious(messages.length) || 0;

  const type = message ? message.type : 'info';

  const Icon = variantIcon[type];
  const classes = useStyles();

  const timer: any = useRef();

  useEffect(() => {
    if (messages.length > 0 && prevMessagesLength === 0) {
      // first message
      timer.current = setTimeout(() => {
        readMessage();
      }, 6000);
    } else if (messages.length < prevMessagesLength && messages.length > 0) {
      // next message
      clearTimeout(timer.current);

      timer.current = setTimeout(() => {
        readMessage();
      }, 6000);
    } else if (
      messages.length === prevMessagesLength &&
      messages.length !== 0 &&
      (message as any).text !== (prevMessage as any).text
    ) {
      // number of messages is the same but message has been changed
      clearTimeout(timer.current);

      timer.current = setTimeout(() => {
        readMessage();
      }, 6000);
    } else if (prevMessagesLength && messages.length === 0) {
      clearTimeout(timer.current);
    }
  }, [message, messages.length, prevMessage, prevMessagesLength, readMessage]);

  const handleClose = (_: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    clearTimeout(timer.current);
    readMessage();
  };

  const renderMessage = () => {
    if (message) {
      if (typeof message.text === 'string') {
        return t(message.text);
      }

      return message.text;
    }
  };

  return (
    <Snackbar
      className={classes.snackbarContainer}
      open={!!message}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      role="alertdialog"
      aria-live="assertive"
    >
      <SnackbarContent
        classes={{ root: classes[type] }}
        message={
          <div className={classes.root}>
            <Icon fontSize="small" />
            <div className={classes.content} data-testid="snackbarMessage">
              {renderMessage()}
              {messages.length > 1 && (
                <>
                  <br />
                  <span className={classes.count}>
                    {t('snackbar.messages_in_queue', { count: messages.length - 1 })}
                  </span>
                </>
              )}
            </div>
          </div>
        }
        action={
          <Button onClick={handleClose} size="small" color="inherit" data-testid="snackbarClose">
            {t('snackbar.close')}
          </Button>
        }
      />
    </Snackbar>
  );
};

export default memo(ContextNotification);
