import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Link } from '@material-ui/core';
import FormCheckbox from '../../components/form/FormCheckbox';
import { validators } from '../formValidation';

const formatConsentText = (consent: any) => {
  let formattedText = consent.format === 'Bold' ? <b>{consent.text}</b> : consent.text;

  if (consent.link) {
    const arrayOfStrings = consent.text.split('%link%');
    formattedText = (
      <Typography>
        {consent.format === 'Bold' ? <b>{arrayOfStrings[0]}</b> : arrayOfStrings[0]}
        <Link href={consent.link} target="_blank" rel="noopener noreferrer">
          {consent.format === 'Bold' ? <b>{consent.label}</b> : consent.label}
        </Link>
        {consent.format === 'Bold' ? <b>{arrayOfStrings[1]}</b> : arrayOfStrings[1]}
      </Typography>
    );
  } else if (consent.mail) {
    const arrayOfStrings = consent.text.split('%mail%');
    const emailId = `mailto:${consent.mail}`;

    formattedText = (
      <Typography>
        {consent.format === 'Bold' ? <b>{arrayOfStrings[0]}</b> : arrayOfStrings[0]}
        <a href={emailId} style={{ textDecoration: 'underline' }}>
          {consent.format === 'Bold' ? <b>{consent.mail}</b> : consent.mail}
        </a>
        {consent.format === 'Bold' ? <b>{arrayOfStrings[1]}</b> : arrayOfStrings[1]}
      </Typography>
    );
  }

  return formattedText;
};

export default function ConsentSection({ id, title, data, consentCheckboxText }: any) {
  const { t } = useTranslation();

  const sortedConsents = data.sort((a: any, b: any) => {
    return a.order - b.order;
  });

  return (
    <Grid container item className="formSubSection" data-testid={id}>
      <Typography variant="h3">{title}</Typography>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <ul>
          {sortedConsents.map((consent: any, index: number) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Typography key={index}>
                <li>{formatConsentText(consent)}</li>
                <br />
              </Typography>
            );
          })}
        </ul>
        <FormCheckbox
          name={id}
          label={consentCheckboxText || t('ikk.Consent.declarationCheckbox')}
          boldLabel
          validate={validators.ikkForm.notARobot}
          data-testid={id}
          required
          errorMsg={t('ikk.Consent.checkboxError')}
        />
      </Grid>
    </Grid>
  );
}
