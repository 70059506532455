import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-final-form';
import { validators } from '../../formValidation';

import { IBAN_PATTERN, HOUSE_NUMBER_PATTERN } from '../../constants/index';
import FormTextField from '../../components/form/FormTextField';
import FormSwitch from '../../components/form/FormSwitch';
import FormNumberInput from '../../components/form/FormNumberInput';
import FormAutocomplete from '../../components/form/FormAutocomplete';
import { bankNameKeyLookup } from './BankNameKey';

export default function BankDetails({ productType }: any) {
  const { t } = useTranslation();
  const [diffAccountOwner, setDiffAccountOwner] = useState<Boolean>(false);

  const [bankNameSelectedOption, setBankNameSelectedOption] = useState({});
  const [bicSelectedOption, setBicSelectedOption] = useState({});

  const form = useForm();

  const handleBankNameChange = (e: any) => {
    if (e) {
      const { bic, id, title } = e;
      // BIC have title set to bic attribute and bankName should be as a property
      const bicSelection = { title: bic, id, firstLetter: bic[0].toUpperCase(), bankName: title };
      setBicSelectedOption(bicSelection);
      setBankNameSelectedOption(e);
      form.change('applicant.bic', bicSelection);
    }
  };

  const handleBicChange = (e: any) => {
    if (e) {
      const { bankName, id, title } = e;
      // bank names have title set to bankName attribute and BIC should be as a property
      const bankNameSelection = { title: bankName, id, firstLetter: bankName[0].toUpperCase(), bic: title };
      setBankNameSelectedOption(bankNameSelection);
      setBicSelectedOption(e);
      form.change('applicant.bankName', bankNameSelection);
    }
  };

  const bankNameOptions = bankNameKeyLookup.map(({ bankName, ...rest }: any) => ({ title: bankName, ...rest }));
  const bicOptions = bankNameKeyLookup.map(({ bic, ...rest }: any) => ({ title: bic, ...rest }));

  const categoryLookup: { [key: string]: string } = t('ikk.FindProduct.categories.categoryLookup', {
    returnObjects: true,
  });

  return (
    <Grid container item className="formSubSection" data-testid="bankDetails">
      <Typography variant="h3" className="subheader">
        {t('ikk.ApplicantSelection.BankDetails.title')}
      </Typography>
      <Grid item xs={12} md={6}>
        <FormAutocomplete
          name="applicant.bankName"
          className="formItem"
          label={t('ikk.ApplicantSelection.BankDetails.bankName')}
          data={bankNameOptions}
          required
          data-testid="bankName"
          preselectedValue={bankNameSelectedOption}
          onChange={handleBankNameChange}
          errorMsg={t('validation.emptyField')}
        />
      </Grid>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.iban"
            className="formItem"
            label={t('ikk.ApplicantSelection.BankDetails.iban')}
            pattern={IBAN_PATTERN}
            patternErrMsg={t('validation.invalidIbanFormat')}
            required
            data-testid="iban"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            name="applicant.bic"
            className="formItem"
            label={t('ikk.ApplicantSelection.BankDetails.bic')}
            data={bicOptions}
            required
            data-testid="bic"
            preselectedValue={bicSelectedOption}
            onChange={handleBicChange}
            errorMsg={t('validation.emptyField')}
          />
        </Grid>
      </Grid>
      {categoryLookup[productType] === categoryLookup.credit && (
        <>
          <Grid container item xs={12} className="formSwitchSection">
            <FormSwitch
              name="applicant.sepa"
              label={t('ikk.ApplicantSelection.BankDetails.sepa')}
              data-testid="sepa"
              validate={validators.applicant.sepaAccept}
              errorMsg={t('validation.sepaConsent')}
            />
          </Grid>
          <Grid container item xs={12} className="formSwitchSection">
            <FormSwitch
              name="applicant.diffAccountOwner"
              label={t('ikk.ApplicantSelection.BankDetails.diffAccountOwner')}
              data-testid="diffAccountOwner"
              checked={diffAccountOwner}
              onChange={() => setDiffAccountOwner(!diffAccountOwner)}
            />
          </Grid>
        </>
      )}
      {!diffAccountOwner ? (
        <></>
      ) : (
        <Grid container item className="formSubSection" data-testid="accountOwnerDetails">
          <Grid container item xs={12} className="formGroup">
            <Grid item xs={12} md={6}>
              <FormTextField
                name="applicant.accountOwner"
                className="formItem"
                label={t('ikk.ApplicantSelection.BankDetails.accountOwner')}
                required
                data-testid="accountOwner"
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="center" className="formGroup">
            <Grid item xs={12} md={6}>
              <FormTextField
                name="applicant.accountOwnerStreet"
                className="formItem"
                label={t('ikk.common.Address.street')}
                required
                data-testid="accountOwnerStreet"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                name="applicant.accountOwnerHouseNumber"
                className="formItem"
                label={t('ikk.common.Address.houseNumber')}
                required
                pattern={HOUSE_NUMBER_PATTERN}
                patternErrMsg="validation.invalidHouseNumber"
                data-testid="accountOwnerHouseNumber"
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="center" className="formGroup">
            <Grid item xs={12} md={6}>
              <FormNumberInput
                name="applicant.accountOwnerPLZ"
                className="formItem"
                label={t('ikk.common.Address.postcode')}
                decimalScale={2}
                integerScale={13}
                required
                data-testid="accountOwnerPLZ"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                name="applicant.accountOwnerTown"
                className="formItem"
                label={t('ikk.common.Address.location')}
                required
                data-testid="accountOwnerTown"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
