import React, { useState, useCallback, useEffect } from 'react';
import { Field, useForm } from 'react-final-form';
import { Grid } from '@material-ui/core';

import DatepickerField from 'components/fields/DatepickerField';
import ToolTipIcon from 'components/fields/ToolTipIcon';
import { useTranslation } from 'react-i18next';
import { getErrorProps } from '../../helpers/formFieldHelpers';

const FormDatePicker = ({ name, tooltip, validate, ...props }: any) => {
  const [error, setError] = useState(false);
  const form = useForm();
  const { t } = useTranslation();

  const validateHandler = useCallback(
    (value, allValues, meta) => {
      if (error) return true;

      if (validate) return validate(value, allValues, meta);

      return false;
    },
    [error, validate],
  );

  useEffect(() => {
    if (error) {
      form.mutators.setFieldError(name, error);
    } else {
      let err = false;
      if (validate) {
        const field = form.getFieldState(name);

        if (field) {
          err = validateHandler(field.value, form.getState().values, field);
        }
      }

      form.mutators.setFieldError(name, err);
    }
  }, [form, error, name, validateHandler, validate]);

  const onError = (err: boolean) => {
    setError(err);
  };

  return (
    <Grid container>
      <Grid item xs={10}>
        <Field
          {...props}
          validate={validateHandler}
          subscription={{ value: true, modified: true, error: true, submitFailed: true }}
          name={name}
          render={({ input, meta }) => {
            return (
              <DatepickerField
                {...props}
                {...input}
                {...getErrorProps(t, meta.error, meta.modified, meta.submitFailed)}
                name={name}
                value={input.value || null}
                variant="outlined"
                onError={onError}
                withInput
              />
            );
          }}
        />
      </Grid>
      {tooltip && (
        <Grid container item xs={1} className="tooltip">
          <ToolTipIcon tip={tooltip} />
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(FormDatePicker);
