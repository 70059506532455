import React from 'react';
import { useField, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import InfoBox from '../../components/common/InfoBox';
import FormAreaTextField from '../../components/form/FormAreaTextField';

const useStyles = makeStyles(() => ({
  textarea: {
    marginBottom: '20px',
    width: '100%',
  },
  infoText: {
    padding: '0 0 20px 0',
    width: '100%',
  },
}));

export default function ProjectDetails() {
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useForm();

  const infoLookup: [{ id: string; title: string; description: string[]; text: string }] = t(
    'oneApp.Notes.commentSectionInfoLookup',
    {
      returnObjects: true,
    },
  );

  const programField = useField('program.program');
  const filteredInfo = infoLookup.find(({ id }) => id === programField.input.value);
  const { productCategory } = form.getState().values.product;

  return (
    <Grid container item className="formSection" data-testid="notes">
      <Typography variant="h3" className="header">
        {t('ikk.ProjectDescription.projectDetails.title')}
      </Typography>
      <Grid container direction="column">
        <Typography variant="body1" id="infoTextHint" className={classes.infoText}>
          {t(`ikk.ProjectDescription.projectDetails.${productCategory}InfoText`)}
        </Typography>
        {filteredInfo && (
          <InfoBox
            content={filteredInfo.text}
            key={filteredInfo.id}
            title={filteredInfo.title}
            description={filteredInfo.description}
          />
        )}
        <FormAreaTextField
          name="project.projectDescription"
          subscription={{ value: true }}
          minRows={8}
          maxLength={210}
          className={`textarea ${classes.textarea}`}
          data-testid="infoText"
        />
      </Grid>
    </Grid>
  );
}
