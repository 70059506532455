import isNotEmpty from 'util/isNotEmpty';

type Validators = {
  [key: string]: {
    [key: string]: (value: any, values: any) => boolean | string;
  };
};

export const validators: Validators = {
  applicant: {
    applicantType: (value: string) => !isNotEmpty(value),
  },
  projectSpecific: {
    restoration: () => {
      return true;
    },
  },
  consent: {
    dsgvoConfirmation: (isChecked: boolean) => !isChecked,
  },
  oneAppForm: {
    notARobot: (isChecked: boolean) => !isChecked,
  },
  requestInformation: {
    // honeypot field if filled in field is invalid
    requestDetails: (value: string) => isNotEmpty(value),
  },
};
