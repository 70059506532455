import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, scroller } from 'react-scroll';

import { Grid, Typography, IconButton } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import menuItems from '../../helpers/menuItems';

const useStyles = makeStyles((theme) => ({
  menuList: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      overflowX: 'scroll',
      scrollBehavior: 'smooth',
    },
  },
  menuItemContainer: {
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flex: '1',
      justifyContent: 'space-around',
    },
  },

  menuItemLink: {
    padding: '6px 16px',
    fontSize: '15px',
    minHeight: '48px',
    fontFamily: 'Arial',
    fontWeight: 'normal',
    lineHeight: '2.6',
    whiteSpace: 'nowrap',
    color: '#1b618c',
    opacity: 0.5,
    '&.active': {
      opacity: 1,
      textShadow: '0 0 0.01px black',
      transition: '0.5s',
      fontSize: '17px',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.08)',
      },
    },
    '&:hover': {
      cursor: 'pointer',
      background: 'rgba(0, 0, 0, 0.04)',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '6px',
      lineHeight: '2.1',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      flex: 'none',
      alignItem: 'center',
    },
  },

  container: {
    position: 'fixed',
    right: '0',
    zIndex: 100,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('md')]: {
      height: '60px',
      left: '0px',
      bottom: '0',
      backgroundColor: '#FFF',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      left: '0px',
    },
  },
  vertical: {
    [theme.breakpoints.up('md')]: {
      height: '1em',
      marginLeft: '85%',
      borderLeft: '1px solid lightgrey',
    },
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: '10px',
      height: '40px',
      marginLeft: '0px',
    },
  },
  leftArrow: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex',
      width: '30px',
      backgroundColor: '#FFFF',
      zIndex: 1,
      outline: 'none !important',
    },
  },
  rightArrow: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex',
      width: '30px',
      backgroundColor: '#FFFF',
      zIndex: 1,
      outline: 'none !important',
    },
  },
}));

type ContentProgressBarProps = {
  invalidSections?: string[];
};

const ContentProgressBar = React.memo(({ invalidSections }: ContentProgressBarProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [urlHash, setUrlHash] = useState('');

  const scrollToCenter = useCallback(
    (id: string) => {
      setUrlHash(`#${id}`);
      const container = document.querySelector('[role="menu"]') as HTMLElement;
      const activeItem = container.querySelector(`#${id}`) as HTMLElement;
      container.scrollLeft = activeItem?.offsetLeft + activeItem?.offsetWidth / 2 - container?.offsetWidth / 2;
    },
    [setUrlHash],
  );

  const handleSetActive = useCallback(
    (to) => {
      scrollToCenter(to);
    },
    [scrollToCenter],
  );

  const scrollToSection = useCallback((id: string) => {
    scroller.scrollTo(id, {
      smooth: true,
    });
  }, []);

  const nextMenuItem = useCallback(() => {
    const currentItemId = urlHash ? urlHash.slice(1) : menuItems[0].id;
    const currentItemIndex = menuItems.findIndex((item) => item.id === currentItemId);
    if (currentItemIndex < menuItems.length - 1) {
      const nexItem = menuItems[currentItemIndex + 1];
      scrollToSection(nexItem.id);
    }
  }, [urlHash, scrollToSection]);

  const prevMenuItem = useCallback(() => {
    const currentItemId = urlHash ? urlHash.slice(1) : menuItems[0].id;
    const currentItemIndex = menuItems.findIndex((item) => item.id === currentItemId);
    if (currentItemIndex > 0) {
      const prevItem = menuItems[currentItemIndex - 1];
      scrollToSection(prevItem.id);
    }
  }, [urlHash, scrollToSection]);

  return (
    <Grid item xs={12} className={classes.container} data-testid="contentProgressBar" id="contentProgressBar">
      <IconButton className={classes.leftArrow} color="primary" onClick={prevMenuItem}>
        <KeyboardArrowLeft color="primary" />
      </IconButton>
      <ol className={classes.menuList} role="menu">
        {menuItems.map((item, index) => (
          <li key={item.id} className={classes.menuItemContainer}>
            <Link
              className={classNames(classes.menuItemLink, {
                error: invalidSections?.includes(item.id),
              })}
              id={`${item.id}`}
              to={item.id}
              selected={urlHash === `#${item.id}` || (!urlHash && item.isDefault)}
              spy
              smooth
              hashSpy
              offset={-70}
              onSetActive={handleSetActive}
              onClick={() => scrollToCenter(item.id)}
              data-testid={`${item.id}${index}`}
              aria-current={urlHash === `#${item.id}` || (!urlHash && item.isDefault) ? 'step' : undefined}
            >
              <Typography variant="inherit">{t(item.label)}</Typography>
            </Link>
            {index !== menuItems.length - 1 && <div className={classes.vertical} />}
          </li>
        ))}
      </ol>
      <IconButton className={classes.rightArrow} color="primary" onClick={nextMenuItem}>
        <KeyboardArrowRight color="primary" />
      </IconButton>
    </Grid>
  );
});

export default ContentProgressBar;
