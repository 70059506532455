import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import { validators } from '../formValidation';

import FormNumberInput from '../../components/form/FormNumberInput';
import FormDatePicker from '../../components/form/FormDatePicker';

export default function CameralisticAccounting() {
  const { t } = useTranslation();

  return (
    <Grid container item className="subSubSection" data-testid="cameralisticAccounting">
      <Grid container>
        <FormNumberInput
          name="requestInformation.householdDebt"
          className="formItem"
          required
          thousandSeparators
          decimalScale={0}
          integerScale={9}
          decimalSeparator={false}
          label={t('ikk.ApplicantSelection.CommunityAssociationFinancials.CameralisticAccounting.householdDebt')}
          tooltip={t('ikk.ApplicantSelection.CommunityAssociationFinancials.CameralisticAccounting.householdDebtTip')}
          data-testid="householdDebt"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormDatePicker
          name="requestInformation.timeOfAccounting"
          className="formItem"
          label={t('ikk.ApplicantSelection.Population.timeOfPopulation')}
          data-testid="timeOfAccounting"
          validate={validators.applicant.dateInPast}
          errorMsg={t('validation.invalidAccountingDate')}
          required
        />
      </Grid>
    </Grid>
  );
}
