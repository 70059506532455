import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function FinancialData() {
  const { t } = useTranslation();

  return (
    <Grid container item className="subSectionMargin" data-testid="FinancialData">
      <Typography variant="h3" className="subheader">
        {t('ikk.ApplicantSelection.FinancialData.title')}
      </Typography>
    </Grid>
  );
}
