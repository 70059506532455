import { TFunction } from 'i18next';

const getErrorProps = (t: TFunction, err: string | boolean, modified?: boolean, submitFailed?: boolean) => {
  let error: boolean | string = false;
  let helperText;

  if (modified || submitFailed) {
    error = err;
    if (err && typeof err === 'string') {
      helperText = t(err);
    }
  }

  return { error, helperText };
};

export { getErrorProps };
