import React, { createContext, useContext, useState } from 'react';

import { Components } from '../../../types/models';

interface IHistory {
  dates: any[];
  addresses: any[];
}

interface Props {
  request?: Components.Schemas.CreditRequestIKK;
  setRequest: React.Dispatch<React.SetStateAction<Components.Schemas.CreditRequestIKK | undefined>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  inputHistory: IHistory;
  setInputHistory: React.Dispatch<React.SetStateAction<IHistory>>;
  addInputHistory: Function;
  getInputHistory: Function;
}

const defaults: Props = {
  setRequest: () => null,
  loading: false,
  setLoading: () => {},
  inputHistory: { dates: [], addresses: [] },
  setInputHistory: () => {},
  addInputHistory: () => {},
  getInputHistory: () => {},
};

const Context = createContext(defaults);

export const useIKKContext = () => useContext(Context);

interface ProviderProps {
  children: React.ReactNode;
}

const Provider = ({ children }: ProviderProps) => {
  const [loading, setLoading] = useState<boolean>(defaults.loading);
  const [request, setRequest] = useState<Components.Schemas.CreditRequestIKK | undefined>(defaults.request);
  const [inputHistory, setInputHistory] = useState<any>(defaults.inputHistory);

  const addInputHistory = (type: string, item: any) => {
    const { dates, addresses } = inputHistory;
    switch (type) {
      case 'date':
        if (dates.indexOf(item) === -1 && `${item}`.match(/^\d{4}-\d{2}-\d{2}$/)) {
          dates.push(item);
          setInputHistory({ dates, addresses });
        }
        break;
      case 'address':
        if (addresses.indexOf(item) === -1) {
          addresses.push(item);
          setInputHistory({ dates, addresses });
        }
        break;
      default:
        break;
    }
  };

  const getInputHistory = (type: string) => {
    switch (type) {
      case 'date':
        return inputHistory.dates;
      case 'address':
        return inputHistory.addresses;
      default:
        break;
    }
  };

  const contextValue = {
    loading,
    setLoading,
    request,
    setRequest,
    inputHistory,
    setInputHistory,
    addInputHistory,
    getInputHistory,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const IKKContextProvider = Provider;
