import React, { useCallback } from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';

import ToolTipIcon from 'components/fields/ToolTipIcon';
import { isEmpty } from 'util/formUtils';

import { getErrorProps } from '../../helpers/formFieldHelpers';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  decimalSeparator?: boolean | string;
  thousandSeparators?: boolean;
  decimalScale?: number;
  integerScale: number;
}

function NumberFormatCustom({
  inputRef,
  onChange,
  thousandSeparators,
  decimalScale,
  decimalSeparator = ',',
  integerScale,
  name,
  ...other
}: NumberFormatCustomProps) {
  return (
    <NumberFormat
      {...other}
      name={name}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalSeparator={decimalSeparator || false}
      thousandSeparator={thousandSeparators ? '.' : ''}
      isNumericString
      decimalScale={decimalScale}
      isAllowed={(values) => {
        const { value: v } = values;
        const separateInteger = v.split('.')[0];
        return separateInteger.length <= integerScale;
      }}
    />
  );
}

const FormNumberInput = ({
  name,
  validate,
  tooltip,
  thousandSeparators,
  decimalScale,
  integerScale,
  onChange,
  required,
  zeroOnBlur,
  decimalSeparator,
  'data-testid': dataTestId,
  ...props
}: any) => {
  const { t } = useTranslation();

  const validateHandler = useCallback(
    (value, allValues, meta) => {
      let hasError;

      if (required) {
        hasError = isEmpty(value);
        if (hasError) return hasError;
      }

      if (validate) return validate(value, allValues, meta);
    },
    [required, validate],
  );

  return (
    <Grid container>
      <Field
        name={name}
        validate={validateHandler}
        subscription={{ value: true, modified: true, error: true }}
        render={({ input, meta }) => {
          const onChangeHandler = (e: React.ChangeEvent<{ name: string; value: string }>) => {
            if (onChange) onChange(e);
            input.onChange({ ...e, target: { ...e.target, value: e.target.value !== '' ? +e.target.value : '' } });
          };

          return (
            <Grid container item xs={12}>
              <Grid container item xs={10}>
                <TextField
                  {...props}
                  {...input}
                  {...getErrorProps(t, meta.error, meta.modified, meta.submitFailed)}
                  required={required}
                  variant="outlined"
                  data-testid={dataTestId}
                  inputProps={{
                    thousandSeparators,
                    decimalSeparator,
                    decimalScale,
                    integerScale,
                    id: dataTestId,
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                  }}
                  onBlur={(e) => {
                    if (zeroOnBlur && !e.target.value && e.target.value !== '0') {
                      input.onChange({ ...e, target: { ...e.target, value: 0 } });
                    }
                    input.onBlur(e);
                  }}
                  onChange={onChangeHandler}
                  InputLabelProps={{
                    htmlFor: dataTestId,
                  }}
                />
              </Grid>
              {tooltip && (
                <Grid item xs={1} className="tooltip">
                  <ToolTipIcon tip={tooltip} />
                </Grid>
              )}
            </Grid>
          );
        }}
      />
    </Grid>
  );
};

export default React.memo(FormNumberInput);
