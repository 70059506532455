export default [
  { id: 20, title: 'Einzelfirma Inland / Selbstständiger / Freiberufler' },
  { id: 21, title: 'Gesellschaft bürgerlichen Rechts' },
  { id: 22, title: 'Offene Handelsgesellschaft (OHG)' },
  { id: 23, title: 'Kommanditgesellschaft (KG)' },
  { id: 24, title: 'GmbH und Co. KG' },
  { id: 25, title: 'Gesellschaft m. beschränkter Haftung (GmbH)' },
  { id: 26, title: 'Eingetragene Genossenschaft (eG)' },
  { id: 27, title: 'Kommanditgesellschaft auf Aktien (KGaA)' },
  { id: 28, title: 'Aktiengesellschaft (AG)' },
  { id: 30, title: 'Partenreederei' },
  { id: 31, title: 'Eingetragener Verein' },
  { id: 32, title: 'Partnerschaftsgesellschaft' },
  { id: 33, title: 'GmbH und Co. KGaA' },
  { id: 34, title: 'AG und Co. OHG' },
  { id: 35, title: 'AG und Co. KG' },
  { id: 50, title: 'Einzelfirma Ausland / Selbständiger / Freiberufler' },
  { id: 51, title: 'Personengesellschaft Ausland' },
  { id: 52, title: 'Kapitalgesellschaft Ausland' },
  { id: 80, title: 'Stiftung' },
  { id: 81, title: 'Versicherungsverein auf Gegenseitigkeit (VVaG)' },
  { id: 82, title: 'GmbH und Co. OHG' },
  { id: 83, title: 'Limited & Co. KG' },
  { id: 84, title: 'eG und CO. KG' },
  { id: 85, title: 'Europäische Aktiengesellschaft (SE)' },
  { id: 86, title: 'eingetragener Kaufmann (e.K.)' },
  { id: 87, title: 'Unternehmergesellschaft (haftungsbeschränkt)' },
  { id: 88, title: 'UG & Co. KG' },
  { id: 89, title: 'Sarl & Co. KG' },
  { id: 90, title: 'SE & Co. KG' },
  { id: 93, title: 'Stiftung & Co. KG' },
  { id: 94, title: 'B.V. & Co. KG' },
  { id: 95, title: 'SE & Co. KGaA' },
  { id: 96, title: 'AG & Co. KGaA' },
  { id: 97, title: 'Partnerschaftsges. mit beschr. Berufshaftung' },
  { id: 106, title: 'Aktiengesellschaft nach ausländischem Recht' },
  { id: 107, title: 'AG und Co. KG nach ausld. Recht' },
];
