import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import FormNumberInput from '../../components/form/FormNumberInput';

const useStyles = makeStyles(() => ({
  alignGrids: {
    margin: '5px 5px 5px 5px',
  },
}));

type EmployeeCountCalculatorProps = {
  setIsTouched: () => void;
};

export default function EmployeeCountCalculator({ setIsTouched }: EmployeeCountCalculatorProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container item className="formSubSection" data-testid="employeesCalculator">
      <Typography variant="body1" className="subheader">
        {t('oneApp.CompanySize.calculatorSubhead')}
      </Typography>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6} className={classes.alignGrids}>
          <FormNumberInput
            className="formItem"
            name="applicant.companyEmployeesOverThirty"
            data-testid="companyEmployeesOverThirty"
            thousandSeparators
            decimalScale={0}
            integerScale={9}
            zeroOnBlur
            decimalSeparator={false}
            onChange={setIsTouched}
            label={t('oneApp.CompanySize.thirtyPlus')}
            tooltip={t('oneApp.CompanySize.tooltip.thirtyPlus')}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.alignGrids}>
          <FormNumberInput
            className="formItem"
            name="applicant.companyEmployeesUptoThirty"
            data-testid="companyEmployeesUptoThirty"
            thousandSeparators
            decimalScale={0}
            integerScale={9}
            zeroOnBlur
            onChange={setIsTouched}
            decimalSeparator={false}
            label={t('oneApp.CompanySize.twentyPlus')}
            tooltip={t('oneApp.CompanySize.tooltip.twentyPlus')}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.alignGrids}>
          <FormNumberInput
            className="formItem"
            name="applicant.companyEmployeesUptoTwenty"
            data-testid="companyEmployeesUptoTwenty"
            thousandSeparators
            decimalScale={0}
            integerScale={9}
            zeroOnBlur
            onChange={setIsTouched}
            decimalSeparator={false}
            label={t('oneApp.CompanySize.twenty')}
            tooltip={t('oneApp.CompanySize.tooltip.twenty')}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.alignGrids}>
          <FormNumberInput
            className="formItem"
            name="applicant.companyEmployees450Jobber"
            data-testid="companyEmployees450Jobber"
            thousandSeparators
            decimalScale={0}
            integerScale={9}
            zeroOnBlur
            onChange={setIsTouched}
            decimalSeparator={false}
            label={t('oneApp.CompanySize.miniJobber')}
            tooltip={t('oneApp.CompanySize.tooltip.miniJobber')}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.alignGrids}>
          <FormNumberInput
            className="formItem"
            name="applicant.companyEmployeesParentalLeave"
            data-testid="companyEmployeesParentalLeave"
            thousandSeparators
            decimalScale={0}
            integerScale={9}
            zeroOnBlur
            onChange={setIsTouched}
            decimalSeparator={false}
            label={t('oneApp.CompanySize.parentalLeave')}
            tooltip={t('oneApp.CompanySize.tooltip.parentalLeave')}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.alignGrids}>
          <FormNumberInput
            className="formItem"
            name="applicant.companyEmployeesTrainee"
            data-testid="companyEmployeesTrainee"
            thousandSeparators
            decimalScale={0}
            integerScale={9}
            zeroOnBlur
            onChange={setIsTouched}
            decimalSeparator={false}
            label={t('oneApp.CompanySize.trainees')}
            tooltip={t('oneApp.CompanySize.tooltip.trainees')}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
