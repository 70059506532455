import React from 'react';

import { KeyboardDatePicker, DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/de';

function jsDateToLocalISO8601DateString(date: any) {
  return [String(date.year()), String(101 + date.month()).substring(1), String(100 + date.date()).substring(1)].join(
    '-',
  );
}

function dateStringToLocalDate(s: any) {
  if (!s) return null;
  return moment(s, 'yyyy-MM-dd').toDate();
}

interface Props {
  maxDate?: string;
  minDate?: string;
  value: Date | null;
  onChange: any;
  label: string;
  invalidDateMessage?: string;
  maxDateMessage?: string;
  minDateMessage?: string;
  required?: boolean;
  error?: boolean;
  'data-testid'?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  className?: string;
  onError?: any;
  name?: string;
  withInput?: boolean;
  helperText?: string | React.ReactNode;
  onFocus?: any;
  onBlur?: any;
}

moment.locale('de');

export default function Datepicker({
  maxDate,
  minDate,
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  value = dateStringToLocalDate(value),
  onChange,
  label,
  required,
  invalidDateMessage = 'Ungültiges Datumsformat',
  maxDateMessage = `Das angegebene Datum sollte das Maximaldatum, den ${
    maxDate ? moment(maxDate).format('DD.MM.YYYY') : '01.01.2100'
  }, nicht überschreiten`,
  minDateMessage = `Das angegebene Datum sollte das Minimaldatum, den ${
    minDate ? moment(minDate).format('DD.MM.YYYY') : '01.01.1900'
  }, nicht unterschreiten`,
  error,
  'data-testid': dataTestId,
  variant = 'outlined',
  className,
  onError,
  name,
  withInput,
  helperText,
  onFocus,
  onBlur,
  ...props
}: Props) {
  const Component = withInput ? KeyboardDatePicker : DatePicker;
  const KeyboardButtonProps = {
    'aria-label': 'Datum auswählen',
    title: 'Datum auswählen',
  };
  const inputProps = {
    'aria-labelledby': `${dataTestId}-label`,
    ...props,
  };

  const handleChange = React.useCallback(
    (date) => {
      onChange({ target: { value: date ? jsDateToLocalISO8601DateString(date) : null } });
    },
    [onChange],
  );

  return (
    <Component
      autoOk
      id={dataTestId}
      inputProps={inputProps}
      className={className}
      variant="inline"
      inputVariant={variant}
      label={label}
      format="DD.MM.YYYY"
      value={value}
      name={name}
      allowKeyboardControl={false}
      maxDate={maxDate}
      onChange={handleChange}
      onError={onError}
      invalidDateMessage={invalidDateMessage}
      maxDateMessage={maxDateMessage}
      minDateMessage={minDateMessage}
      required={required}
      error={error}
      data-testid={dataTestId}
      KeyboardButtonProps={KeyboardButtonProps}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}
