/* eslint-disable no-plusplus */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormNumberInput from '../../components/form/FormNumberInput';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  hidden: {
    display: 'none',
  },
  inline: {
    display: 'inline-block',
  },
  investWrapperTable: {
    width: '100%',
    '& th': {
      textAlign: 'left',
      verticalAlign: 'middle',
      padding: '1.2em 1.5em 1.2em .5em',
    },
    '& td': {
      padding: '.3em',
      width: '45%',
      verticalAlign: 'middle',
    },
  },
}));

export default function InvestmentPlan({ selectedProduct, financingElementId, validate }: any) {
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useForm();

  const investitionList: { id: string; title: string }[] =
    selectedProduct !== 432
      ? t('ikk.InvestPlan.investitionList', {
          returnObjects: true,
        })
      : t('ikk.InvestPlan.investitionList432', {
          returnObjects: true,
        });

  const handleSumDiff = () => {
    const sum1 = parseFloat(form.getFieldState('investPlan.sum')?.value || '0');
    const sum2 = parseFloat(form.getFieldState(`${financingElementId}.sum`)?.value || '0');
    if (sum1 === sum2) {
      // Displaying/hiding icons this way so that we bypass state change - it's faster and state is not needed here
      document.getElementById(`${financingElementId}.sum.passed`)?.classList.add('visible');
      document.getElementById(`${financingElementId}.sum.error`)?.classList.remove('visible');
    } else {
      document.getElementById(`${financingElementId}.sum.passed`)?.classList.remove('visible');
      document.getElementById(`${financingElementId}.sum.error`)?.classList.add('visible');
    }
  };

  const handleInvestSumChange = function (value: any, id: string | null) {
    let sum: number = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < investitionList.length; i++) {
      // eslint-disable-next-line eqeqeq
      if (investitionList[i].id === id) {
        sum += parseFloat(value || 0);
      } else {
        sum += parseFloat(form.getFieldState(`investPlan.amount.${investitionList[i].id}`)?.value || '0');
      }
    }
    form.change('investPlan.sum', sum);
    handleSumDiff();
  };

  return (
    <Grid container className="formSection">
      <Typography variant="h2" className={`${classes.fullWidth} header`}>
        {selectedProduct !== 432 ? t('ikk.InvestPlan.title') : t('ikk.InvestPlan.title432')}
      </Typography>
      <Typography variant="h3" className={`${classes.fullWidth} header`}>
        {selectedProduct !== 432 ? t('ikk.InvestPlan.investitionPlan') : t('ikk.InvestPlan.costPlan')}
      </Typography>
      <Grid item xs={12} md={12}>
        <table className={classes.investWrapperTable}>
          <thead>
            <tr>
              <th>{t('ikk.InvestPlan.costField')}</th>
              <th>{t('ikk.InvestPlan.investAmount')}</th>
            </tr>
          </thead>
          <tbody>
            {Array.from(Array(investitionList.length).keys()).map((index: number) => (
              <tr key={index}>
                <td>{investitionList[index].title}</td>
                <td>
                  <FormNumberInput
                    name={`investPlan.amount.${investitionList[index].id}`}
                    label={t('ikk.InvestPlan.investAmount')}
                    thousandSeparators
                    decimalScale={2}
                    integerScale={13}
                    isInline
                    validate={validate}
                    data-testid={`investitionPlanAmt.${investitionList[index].id}`}
                    onChange={(evt: any) => {
                      handleInvestSumChange(evt.target.value, investitionList[index].id);
                    }}
                  />
                  {t('ikk.common.eur')}
                </td>
              </tr>
            ))}
            <tr>
              <th>{t('ikk.InvestPlan.investSum')}</th>
              <td>
                <FormNumberInput
                  name="investPlan.sum"
                  label={t('ikk.InvestPlan.sum')}
                  thousandSeparators
                  decimalScale={2}
                  integerScale={13}
                  data-testid="investitionPlanSum"
                  isInline
                  disabled
                />
                {t('ikk.common.eur')}
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
}
