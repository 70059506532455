/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import { formatAutocompleteOptions } from 'util/formatUtils';

interface DataPoint {
  id: number;
  title: string;
}

interface Props {
  inputAutoComplete?: string;
  label: string;
  data: DataPoint[];
  onChange?: any;
  value?: any;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  helperText?: string;
  'data-testid'?: string;
}

const useStyles = makeStyles(() => ({
  inputClearIcon: {
    '& input': {
      '&::-ms-clear': {
        width: 0,
        height: 0,
      },
    },
    '& button': {
      transform: 'rotate(0deg)',
    },
  },
}));

export default function Grouped({
  inputAutoComplete,
  label,
  data,
  onChange,
  value,
  disabled = false,
  required,
  error,
  variant = 'outlined',
  'data-testid': dataTestId,
  helperText,
  ...props
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const options = formatAutocompleteOptions(data);

  return (
    <Autocomplete
      {...props}
      openText="Search"
      fullWidth
      id={dataTestId}
      options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.title || ''}
      getOptionSelected={(option, val) => option.id === val.id}
      onChange={onChange}
      value={value}
      disabled={disabled}
      disablePortal
      popupIcon={<SearchIcon />}
      noOptionsText={t('autocompleteField.noOptions')}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, autoComplete: inputAutoComplete }}
          className={classes.inputClearIcon}
          error={error}
          helperText={helperText}
          label={label}
          variant={variant}
          required={required}
        />
      )}
      data-testid={dataTestId}
    />
  );
}
