import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import { validators } from '../formValidation';

import FormNumberInput from '../../components/form/FormNumberInput';
import FormDatePicker from '../../components/form/FormDatePicker';

export default function DoubleAccounting() {
  const { t } = useTranslation();

  return (
    <Grid container item className="subSubSection" data-testid="doubleAccounting">
      <Grid item xs={12} md={6}>
        <FormNumberInput
          name="requestInformation.totalAssets"
          className="formItem"
          thousandSeparators
          decimalScale={0}
          integerScale={9}
          decimalSeparator={false}
          label={t('ikk.ApplicantSelection.CommunityAssociationFinancials.DoubleAccounting.totalAssets')}
          data-testid="totalAssets"
        />
      </Grid>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormNumberInput
            name="requestInformation.equityCapital"
            className="formItem"
            thousandSeparators
            decimalScale={0}
            integerScale={9}
            decimalSeparator={false}
            label={t('ikk.ApplicantSelection.CommunityAssociationFinancials.DoubleAccounting.equityCapital')}
            data-testid="equityCapital"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormNumberInput
            name="requestInformation.annualSales"
            className="formItem"
            thousandSeparators
            decimalScale={0}
            integerScale={9}
            decimalSeparator={false}
            label={t('ikk.ApplicantSelection.CommunityAssociationFinancials.DoubleAccounting.annualSales')}
            data-testid="annualSales"
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormNumberInput
            name="requestInformation.netIncome"
            className="formItem"
            thousandSeparators
            decimalScale={0}
            integerScale={9}
            decimalSeparator={false}
            label={t('ikk.ApplicantSelection.CommunityAssociationFinancials.DoubleAccounting.netIncome')}
            data-testid="netIncome"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormNumberInput
            name="requestInformation.totalDebt"
            className="formItem"
            thousandSeparators
            decimalScale={0}
            integerScale={9}
            decimalSeparator={false}
            label={t('ikk.ApplicantSelection.CommunityAssociationFinancials.DoubleAccounting.totalDebt')}
            data-testid="totalDebt"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormDatePicker
          name="requestInformation.timeOfAccounting"
          className="formItem"
          label={t('ikk.ApplicantSelection.Population.timeOfPopulation')}
          data-testid="timeOfAccounting"
          validate={validators.applicant.dateInPast}
          errorMsg={t('validation.invalidAccountingDate')}
          required
        />
      </Grid>
    </Grid>
  );
}
