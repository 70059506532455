const OneAppStyles = (theme: any) => ({
  '@global': {
    '.errorMessage': {
      color: '#ca0036 !important',
      marginTop: '5px',
    },
    '.formSection': {
      minHeight: '30vh',
      padding: '1.5rem',

      [theme.breakpoints.down('md')]: {
        padding: '0',
        '& > *': {
          width: '100%',
        },
      },
      '& > :not(.header):not(.subheader):not(.noAutoPadding)': {
        padding: '0.75rem 0',
      },
    },
    '.formSubSection': {
      minHeight: '10vh',
      padding: '1.5rem 0',
      marginTop: '25px',

      [theme.breakpoints.down('md')]: {
        padding: '0',
        '& > *': {
          width: '100%',
        },
      },

      '& > :not(.header):not(.subheader)': {
        padding: '0.75rem 0',
      },
    },
    '.formAttachmentSection': {
      minHeight: '10vh',
      padding: '1.5rem 0',

      [theme.breakpoints.down('md')]: {
        padding: '0',
        '& > *': {
          width: '100%',
        },
      },
      '& h4, & p': {
        width: '100%',
      },
      '& > :not(.header):not(.subheader)': {
        padding: '0.75rem 0',
      },
      '& .row': {
        padding: '1rem',
      },
      '& .link': {
        '&:before': {
          fontFamily: 'kfw-icons',
          verticalAlign: 'baseline',
          content: '"\\e931"',
          paddingRight: '10px',
        },
      },
    },
    '.collapsibleFormSubSection': {
      padding: '0rem',

      [theme.breakpoints.down('md')]: {
        padding: '0',
        '& > *': {
          width: '100%',
        },
      },

      '& > :not(.header):not(.subheader):not(.formSwitchSection)': {
        padding: '0.75rem 0',
      },
    },
    '.modalFormSection': {
      [theme.breakpoints.down('md')]: {
        padding: '0',
        '& > *': {
          width: '100%',
        },
      },
      '& > :not(.header):not(.subheader):not(.makeStyles-infocenterRecommendation-61)': {
        padding: '0.75rem 0',
      },
    },
    '.header': {
      paddingTop: '50px',
      paddingBottom: '20px',

      [theme.breakpoints.down('md')]: {
        '&.MuiTypography-h1': {
          fontSize: '30px',
        },
        paddingBottom: '20px',
      },
    },
    '.subheader': {
      width: '100%',
      paddingBottom: '20px',
    },
    '.formItem': {
      width: '100%',
    },
    '.formGroup': {
      [theme.breakpoints.down('sm')]: {
        '& > div:not(:first-child)': {
          marginTop: '1.5rem',
        },
      },
    },
    '.textContainer': {
      width: '100%',
    },
    '.tooltip': {
      height: '55px',
      '& button': {
        position: 'relative',
        top: '-5px',
      },
    },
  },
});

export default OneAppStyles;
