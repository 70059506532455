import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FormSwitch from '../../components/form/FormSwitch';

export default function NonProfit() {
  const { t } = useTranslation();

  return (
    <Grid container item className="formSubSection" data-testid="nonProfit">
      <Typography variant="h3" className="subheader">
        {t('oneApp.NonProfit.title')}
      </Typography>

      <Grid container item xs={12}>
        <FormSwitch
          name="applicant.partOfCompany"
          label={t('oneApp.NonProfit.partOfCompanyLabel')}
          data-testid="partOfCompany-switch"
        />
      </Grid>

      <Grid container item xs={12}>
        <FormSwitch
          name="applicant.participationOfBankStatus"
          label={t('oneApp.NonProfit.participationOfBankStatusLabel')}
          tooltip={t('oneApp.NonProfit.participationOfBankStatusTooltip')}
          data-testid="participationOfBankStatus-switch"
        />
      </Grid>

      <Grid container item xs={12}>
        <FormSwitch
          name="applicant.nonProfit"
          label={t('oneApp.NonProfit.nonProfitLabel')}
          data-testid="nonProfit-switch"
        />
      </Grid>

      <Grid container item xs={12}>
        <FormSwitch
          name="applicant.activeInNonProfit"
          label={t('oneApp.NonProfit.activeInNonProfitLabel')}
          data-testid="activeInNonProfit-switch"
        />
      </Grid>

      <Grid container item xs={12}>
        <FormSwitch
          name="applicant.citBObligatory"
          label={t('oneApp.NonProfit.citBObligatoryLabel')}
          data-testid="citBObligatory-switch"
        />
      </Grid>

      <Grid container item xs={12}>
        <FormSwitch
          name="applicant.CITFreed"
          label={t('oneApp.NonProfit.CITFreedLabel')}
          data-testid="CITFreed-switch"
        />
      </Grid>
    </Grid>
  );
}
