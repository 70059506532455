import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';

import { Field } from 'react-final-form';
import FormCheckbox from '../components/form/FormCheckbox';
import { validators } from './formValidation';

const useStyles = makeStyles(() => ({
  subtitle: {
    padding: '20px 0',
  },
  bold: {
    '& > .MuiFormControlLabel-label > .MuiTypography-body1': {
      fontWeight: 'bold !important',
    },
  },
}));

export default function Consent() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container item className="formSection" data-testid="consent">
      <Typography variant="h2" className="header">
        {t('oneApp.Consent.title')}
      </Typography>

      <Typography variant="h3" className="subheader">
        {t('oneApp.Consent.dataProtectionTitle')}
      </Typography>
      <Typography variant="body1">{t('oneApp.Consent.dataProtectionInfoText')}</Typography>
      <Typography variant="body1">
        {t('oneApp.Consent.dataProtectionInfoText2_part1')}
        <Field
          name="program.program"
          subscription={{ value: true }}
          render={() => {
            const dataProtectionPrivacyLink = t('oneApp.Consent.dataProtectionInfoText2_link');
            return (
              <Link href={dataProtectionPrivacyLink} target="_blank">
                {t('oneApp.Consent.dataProtectionInfoText2_linkText')}
              </Link>
            );
          }}
        />

        {t('oneApp.Consent.dataProtectionInfoText2_part2')}
      </Typography>
      <Typography variant="body1">{t('oneApp.Consent.dataProtectionInfoText3')}</Typography>

      <FormCheckbox
        name="consent.dsgvoConfirmation"
        className={classes.bold}
        label={t('oneApp.Consent.dataProtectionConfirmLabel')}
        validate={validators.consent.dsgvoConfirmation}
        errorMsg={t('oneApp.Consent.dataProtectionConfirmError')}
        data-testid="dsgvoConfirmation-checkbox"
      />

      <Typography variant="h3" className={classnames('subheader', classes.subtitle)}>
        {t('oneApp.Consent.dataUsageTitle')}
      </Typography>
      <Typography variant="body1">{t('oneApp.Consent.dataUsageInfoText')}</Typography>
      <Typography variant="body1">{t('oneApp.Consent.dataUsageInfoText2')}</Typography>
      <Typography variant="body1">
        {t('oneApp.Consent.dataUsageInfoText3')}
        <Link href={`mailto:${t('oneApp.Consent.dataUsageInfoText3_email')}`}>
          {t('oneApp.Consent.dataUsageInfoText3_emailText')}
        </Link>
        {t('oneApp.Consent.dataUsageInfoText3_point')}
      </Typography>
      <Typography variant="body1">{t('oneApp.Consent.dataUsageInfoText4')}</Typography>

      <FormCheckbox
        name="consent.dataUsageConfirmation"
        label={t('oneApp.Consent.dataUsageConfirmLabel')}
        data-testid="dataUsageConfirmation-checkbox"
      />
    </Grid>
  );
}
