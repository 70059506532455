import React, { useState, useCallback, useEffect } from 'react';
import { Field, useForm } from 'react-final-form';
import { Grid, Typography } from '@material-ui/core';
import ToolTipIcon from 'components/fields/ToolTipIcon';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'util/formUtils';
import DatepickerField from '../fields/DatepickerField';
import { getErrorProps } from '../../helpers/formFieldHelpers';
import { useIKKContext } from '../../context/IKKContext';
import History from '../history/History';

const useStyles = makeStyles(() =>
  createStyles({
    errorMessage: {
      color: '#ca0036 !important',
      marginTop: '5px',
    },
    historyParent: {
      position: 'relative',
    },
  }),
);

const FormDatePicker = ({ name, tooltip, validate, errorMsg, required, ...props }: any) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const form = useForm();
  const { t } = useTranslation();
  const [displayError, setDisplayError] = useState(false);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [historyValue, setHistoryValue] = useState(false);
  const { addInputHistory } = useIKKContext();

  const validateHandler = useCallback(
    (value, allValues, meta) => {
      if (error) return true;

      let hasError;
      if (required) {
        hasError = isEmpty(value);
        if (hasError) return true;
      }

      if (validate) {
        setDisplayError(validate(value, allValues, meta));
        return displayError;
      }

      return false;
    },
    [error, validate, displayError, required],
  );

  useEffect(() => {
    if (error) {
      form.mutators.setFieldError(name, error);
    } else {
      let err = false;
      if (validate) {
        const field = form.getFieldState(name);

        if (field) {
          err = validateHandler(field.value, form.getState().values, field);
        }
      }

      form.mutators.setFieldError(name, err);
    }
  }, [form, error, name, validateHandler, validate]);

  const onError = (err: boolean) => {
    setError(err);
  };

  const handleChangeHistory = (value: any) => {
    form.change(name, value);
    addInputHistory('date', value);
    setHistoryValue(value);
  };

  const handleChange = (evt: any) => {
    handleChangeHistory(evt.target.value);
  };

  return (
    <Grid container className={classes.historyParent}>
      <Grid item xs={10}>
        <Field
          {...props}
          validate={validateHandler}
          subscription={{ value: true, modified: true, error: true, submitFailed: true }}
          name={name}
          required={required}
          render={({ input, meta }) => {
            return (
              <DatepickerField
                {...props}
                {...input}
                {...getErrorProps(t, meta.error, meta.modified, meta.submitFailed)}
                name={name}
                required={required}
                value={historyValue || input.value || null}
                variant="outlined"
                onError={onError}
                onFocus={(evt: any) => {
                  setHistoryVisible(true);
                  props.onFocus && props.onFocus(evt);
                }}
                onBlur={(evt: any) => {
                  // onBlur happens before History.onClick, so delaying this to not hide history before clicking it.
                  setTimeout(() => setHistoryVisible(false), 100);
                  props.onBlur && props.onBlur(evt);
                }}
                onChange={(evt: any) => {
                  handleChange(evt);
                  input.onChange(evt);
                }}
                withInput
              />
            );
          }}
        />
      </Grid>
      {tooltip && (
        <Grid container item xs={1} className="tooltip">
          <ToolTipIcon tip={tooltip} />
        </Grid>
      )}
      <History
        type="date"
        visible={historyVisible}
        onSelect={(evt: any, item: any) => {
          setHistoryValue(item);
          handleChangeHistory(item);
        }}
        itemAssembler={(item: any, index: any) => {
          const y = `${item}`.substring(0, 4);
          const m = `${item}`.substring(5, 7);
          const d = `${item}`.substring(8, 10);
          return [d, m, y].join('.');
        }}
      />
      {errorMsg && displayError && (
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.errorMessage}>
            {errorMsg}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(FormDatePicker);
