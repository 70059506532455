import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import FormDatePicker from '../../components/form/FormDatePicker';
import { validators } from '../../formValidation';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 'bold',
  },
}));

export default function InformationOnImplementationDuration({ selectedPurposes }: any) {
  const { t } = useTranslation();
  const classes = useStyles();
  const selectedPurposesIds = selectedPurposes.map((p: any) => p.id);

  // Check if personal cost and material cost exist for remedtiation manager
  const remediationManagerPurpose = selectedPurposesIds.includes(1) && selectedPurposesIds.includes(2);

  const stabilizationRemediationManagerPurpose = selectedPurposesIds.includes(3) && selectedPurposesIds.includes(4);

  const purposeFilter = (elem: any) => {
    if (elem.id === 1 && remediationManagerPurpose) {
    } else if (elem.id === 3 && stabilizationRemediationManagerPurpose) {
    } else {
      return elem;
    }
  };

  return (
    <Grid container item className="formSubSection" data-testid="implementationDuration">
      <Typography variant="h3">{t('ikk.ImplementationDuration.title')}</Typography>
      <Typography variant="body1">{t('ikk.ImplementationDuration.infoText1')}</Typography>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={6} md={6} />
        <Grid item xs={3} md={3}>
          <Typography variant="body1" className={classes.bold}>
            {t('ikk.ImplementationDuration.begin')}
          </Typography>
        </Grid>
        <Grid item xs={3} md={3}>
          <Typography variant="body1" className={classes.bold}>
            {t('ikk.ImplementationDuration.endDate')}
          </Typography>
        </Grid>
      </Grid>
      {selectedPurposes?.filter(purposeFilter).map((p: any) => (
        <Grid container item xs={12} justifyContent="center" className="formGroup">
          <Grid item xs={6} md={6}>
            <Typography variant="body1" className={classes.bold}>
              {t(`ikk.ImplementationDuration.purpose${p.id}`)}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            {/* TODO - the dates here are put onto project.projectPurpose, 
              but all the other projectPurpose dates are put directly into own 
              root property "projectPurpose" */}
            <FormDatePicker
              required
              label=""
              className="formItem"
              data-testid={`begin-date${p.id}`}
              name={`project.projectPurpose.dates.beginDate${p.id}`}
              validate={validators.implementationDuration.beginDate}
              errorMsg={t('ikk.ImplementationDuration.beginDateError')}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <FormDatePicker
              required
              label=""
              className="formItem"
              data-testid={`end-date${p.id}`}
              name={`project.projectPurpose.dates.endDate${p.id}`}
              validate={validators.implementationDuration.endDate}
              errorMsg={t('ikk.ImplementationDuration.endDateError')}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
