import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { Components as YggsComponents } from 'yggs-api/openapi/models';

import { makeStyles } from '@material-ui/core/styles';

interface Props {
  className?: string;
  title?: string;
  content: React.ReactNode;
  product: YggsComponents.Schemas.Product;
  productTypeKey: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  productNumber: {
    fontSize: '21px',
    position: 'relative',
    padding: '10px 0 0 13px',
    wordBreak: 'break-all',
  },
  productKredit: {
    fontSize: '10px !important',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    padding: '5px 8px',
  },
  productNumberContainer: {
    position: 'relative',
    minWidth: '160px',
    minHeight: '120px',
    backgroundColor: '#60800f',
    '&:after': {
      content: "''",
      position: 'absolute',
      width: '0',
      height: '0',
      left: '160px',
      top: '17px',
      borderStyle: 'solid',
      borderWidth: '10px 0 10px 10px',
      borderColor: `transparent transparent transparent #60800f`,
    },
  },
  productColorBlue: {
    background: theme.palette.primary.dark,
    '&:after': {
      borderColor: `transparent transparent transparent ${theme.palette.primary.dark}`,
    },
  },
  productDetails: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f9f6f4',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
}));

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
export default function SelectionCard({ className = '', title, content = null, product, productTypeKey }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const categoryKeyLookup: { [key: string]: string } = t('ikk.FindProduct.categories.categoryKeyLookup', {
    returnObjects: true,
  });

  return (
    <div className={`${classes.root} ${className}`}>
      <div
        className={`${classes.productNumberContainer} ${
          categoryKeyLookup[productTypeKey] === categoryKeyLookup.grant ? classes.productColorBlue : ''
        }`}
      >
        <Typography variant="body1" className={classes.productNumber} style={{ color: '#fff' }}>
          {product.productId}
        </Typography>
        <Typography variant="body1" className={classes.productKredit} style={{ color: '#fff' }}>
          {product.productType}
        </Typography>
      </div>
      <div className={classes.productDetails}>
        <CardContent className={classes.content}>
          {/* !!!! DON'T REMOVE component="div" !!!! */}
          <Typography component="div" variant="body2" align="left" className={classes.content}>
            {content}
          </Typography>
        </CardContent>
      </div>
    </div>
  );
}
