import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';

interface Props {
  title?: string;
  category?: any;
  description?: string;
  link?: string;
  formNumber?: string;
}

const useStyles = makeStyles((theme) => ({
  arrowIcon: {
    fontSize: '12px',
    marginRight: '0.5%',
  },
  link: {
    position: 'relative',
    padding: '20px 10px 0 0',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export default function Attachment({ title, category, description, link, formNumber }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container item className="formSubSection" data-testid={title}>
      <Typography variant="h3" className="subheader">
        {title}
      </Typography>
      <Typography variant="body1">
        {t('ikk.Attachments.categoryLabel')}
        {category}
      </Typography>
      <Grid item xs={12} md={12}>
        <Typography variant="body1">{description}</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Link target="_blank" href={link} className={classes.link}>
          <span className={classnames(classes.arrowIcon, 'icArrowsRight')} />
          {t('ikk.Attachments.linkLabel')}
        </Link>
      </Grid>
      {formNumber && formNumber !== 'null' && (
        <Typography variant="body1">
          {t('ikk.Attachments.formNumberLabel')}
          {formNumber}
        </Typography>
      )}
    </Grid>
  );
}
