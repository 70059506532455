import React from 'react';
import classnames from 'classnames';
import { Field } from 'react-final-form';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { Grid } from '@material-ui/core';

const FormCheckbox = ({
  label,
  className,
  containerClassname,
  validate,
  'data-testid': dataTestId,
  errorMsg,
  ...props
}: any) => {
  return (
    <Field
      {...props}
      validate={validate}
      type="checkbox"
      subscription={{ value: true, modified: true, error: true, submitFailed: true }}
      render={({ input, meta }) => {
        return (
          <Grid container className={containerClassname}>
            <FormControlLabel
              className={classnames(className, { error: meta.error && (meta.modified || meta.submitFailed) })}
              control={
                <Checkbox
                  {...props}
                  {...input}
                  data-testid={dataTestId}
                  inputProps={{
                    'aria-labelledby': dataTestId,
                  }}
                />
              }
              label={
                <Typography variant="body1" id={dataTestId}>
                  {label}
                </Typography>
              }
            />
            {errorMsg && meta.submitFailed && meta.error && (
              <Grid container>
                <Typography variant="body1" className="errorMessage">
                  {errorMsg}
                </Typography>
              </Grid>
            )}
          </Grid>
        );
      }}
    />
  );
};

export default React.memo(FormCheckbox);
