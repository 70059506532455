import { MAPP_DL } from '../constants/index';

const { VALUES } = MAPP_DL;

export function updateMappDLDetailsPage(valuesToUpdate: any) {
  return {
    ep6: VALUES.NOT_APPLICABLE,
    ep7: VALUES.NOT_APPLICABLE,
    ep8: VALUES.NOT_APPLICABLE,
    ep9: VALUES.NOT_APPLICABLE,
    ...valuesToUpdate,
    contentId: VALUES.CONTENT_ID_FORM,
  };
}

export function updateMappDLSummaryPage(valuesToUpdate: any) {
  return {
    ...valuesToUpdate,
    contentId: VALUES.CONTENT_ID_SUMMARY,
  };
}

export const dispatchMappDLEvent = (eventName: string) => {
  if (window.mappDL) {
    document.dispatchEvent(new CustomEvent(eventName));
  }
};

export const getViewportSize = () => {
  const screenWidth = window.window.innerWidth;

  switch (true) {
    case screenWidth >= 0 && screenWidth < 600:
      return 'xs';
    case screenWidth >= 600 && screenWidth < 840:
      return 'sm';
    case screenWidth >= 840 && screenWidth < 960:
      return 'md';
    case screenWidth >= 960 && screenWidth < 1280:
      return 'lg';
    default:
      return 'xl';
  }
};

export const getScreenOrientation = () => {
  return (window.screen.orientation || {}).type;
};
