import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm, useField } from 'react-final-form';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { POST_CODE_PATTERN } from 'common/constants/regexp';
import { MAPP_DL } from 'common/constants';
import FormAutocomplete from 'pages/oneApp/components/form/FormAutocomplete';
import FormTextField from '../../components/form/FormTextField';
import FormSwitch from '../../components/form/FormSwitch';
import countryList from '../../../../types/Land';
import { dispatchMappDLEvent } from '../../../../components/MappDL';

const INPUT_PROPS = {
  addAddressStreet: {
    maxLength: 60,
    formNoValidate: true,
  },
  addAddressNumber: {
    maxLength: 9,
    formNoValidate: true,
  },
  addAddressLocation: {
    maxLength: 35,
  },
  addAddressPostcode: {
    maxLength: 5,
  },
  addAddressProvince: {
    maxLength: 35,
  },
};

const { EVENTS } = MAPP_DL;

export default function AdditionalAddress() {
  const { t } = useTranslation();
  const form = useForm();
  const additionalAddressField = useField('project.additionalAddressProvided');
  const additionalAddressFieldValue = additionalAddressField.input.value;

  useEffect(() => {
    dispatchMappDLEvent(EVENTS.MAPP_INIT_FORM_UPDATE);
  }, [additionalAddressFieldValue]);

  const handleAddAddressProvidedChange = (value: boolean) => {
    if (!value) {
      form.batch(() => {
        form.change('project.addAddressStreet', undefined);
        form.change('project.addAddressNumber', undefined);
        form.change('project.addAddressPostcode', undefined);
        form.change('project.addAddressLocation', undefined);
        form.change('project.addAddressCountry', undefined);
      });
    }
  };

  return (
    <Grid container item xs={12} className="collapsibleFormSubSection" data-testid="additionalAddress">
      <Grid container item xs={12} className="formSwitchSection">
        <FormSwitch
          name="project.additionalAddressProvided"
          label={t('oneApp.ProjectDetails.additionalAddressProvided')}
          data-testid="additionalAddressProvided-switch"
          onChange={handleAddAddressProvidedChange}
        />
      </Grid>

      <Field name="project.additionalAddressProvided" subscription={{ value: true }}>
        {({ input }) => {
          if (input.value) {
            return (
              <Grid container item xs={12} className="collapsibleFormSubSection">
                <Typography variant="h3">{t('oneApp.ProjectDetails.addressTitle')}</Typography>
                <Grid container item xs={12} justifyContent="center" className="formGroup">
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name="project.addAddressStreet"
                      className="formItem"
                      label={t('oneApp.ProjectDetails.addAddressStreet')}
                      maxLength={INPUT_PROPS.addAddressStreet.maxLength}
                      data-testid="addAddressStreet"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name="project.addAddressNumber"
                      className="formItem"
                      label={t('oneApp.ProjectDetails.addAddressNumber')}
                      maxLength={INPUT_PROPS.addAddressNumber.maxLength}
                      data-testid="addAddressNumber"
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="formGroup">
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name="project.addAddressPostcode"
                      className="formItem"
                      label={t('oneApp.ProjectDetails.addAddressPostcode')}
                      pattern={POST_CODE_PATTERN}
                      patternErrMsg="validation.invalidPostCodeFormat"
                      maxLength={INPUT_PROPS.addAddressPostcode.maxLength}
                      data-testid="addAddressPostcode"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name="project.addAddressLocation"
                      className="formItem"
                      label={t('oneApp.ProjectDetails.addAddressLocation')}
                      maxLength={INPUT_PROPS.addAddressLocation.maxLength}
                      data-testid="addAddressLocation"
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" className="formGroup">
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name="project.addAddressProvince"
                      className="formItem"
                      label={t('oneApp.ProjectDetails.addAddressProvince')}
                      maxLength={INPUT_PROPS.addAddressProvince.maxLength}
                      data-testid="addAddressProvince"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormAutocomplete
                      name="project.addAddressCountry"
                      className="formItem"
                      data={countryList}
                      label={t('oneApp.ProjectDetails.addAddressCountry')}
                      data-testid="addAddressCountry-select"
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          }
        }}
      </Field>
    </Grid>
  );
}
