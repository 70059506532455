import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useField } from 'react-final-form';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// @ts-ignore
import { OnChange } from 'react-final-form-listeners';
import FormRadio from 'pages/ikk/components/form/FormRadio';
import { Components } from 'types/models';
import { CircularProgress } from '@material-ui/core';
import ProductCard from './ProductCard';

const useStyles = makeStyles(() => ({
  productCard: {
    opacity: '0.7',
    maxWidth: '1024px',
  },
  isSelectedProgram: {
    opacity: '1',
  },
  switchContainer: {
    marginBottom: '20px',
    display: 'flex',
    '& > .MuiFormControlLabel-label': {
      display: 'flex',
      width: '100%',
    },
  },
  selectSubtitle: {
    width: '100%',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  linkStyle: {
    fontSize: '14px',
    textDecoration: 'underline',
    color: '#1b618c !important',
    textAlign: 'left',
  },
  productDialog: {
    '& .MuiPaper-root': {
      maxWidth: '1250px',
      minHeight: '95%',
    },
  },
  fullWidth: {
    width: '100%',
  },
}));

export default function ProductList({
  productType,
  onSelectedProductChange,
  urlParams,
  recommendedProducts,
  isLoading,
}: any) {
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useForm();

  const [selectedProduct, setSelectedProduct] = useState<any>(urlParams?.programId || 0);

  const productField = useField('product.product');
  const loanTerm = useField('program.duration');
  const freeYears = useField('program.freeYears');
  const fixedInterestRatePeriod = useField('program.fixedInterestPeriod');

  const handleSelectedProductChange = (value: number) => {
    setSelectedProduct(value);
    onSelectedProductChange(value);
    // initialize initial values for sliders
    if (loanTerm && !loanTerm.input.value) form.change('product.duration', 1);
    if (freeYears && !freeYears.input.value) form.change('product.freeYears', 1);
    if (fixedInterestRatePeriod && !fixedInterestRatePeriod.input?.value) form.change('product.fixedInterestPeriod', 1);
  };

  const programIDFromUrl = urlParams?.programId;

  useEffect(() => {
    setSelectedProduct(programIDFromUrl * 1);
    onSelectedProductChange(programIDFromUrl * 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programIDFromUrl]);

  return (
    <Grid container item className="formSection" data-testid="program" id="product-list">
      <Typography variant="h2" className="header">
        {t('ikk.FindProduct.productList.title')}
      </Typography>
      <Typography variant="body1" className={classes.fullWidth}>
        {t('ikk.FindProduct.productList.subtitle')}
      </Typography>
      <Grid container item xs={12} className="formGroup">
        {isLoading && <CircularProgress />}
        <Grid container direction="column" item justifyContent="center" data-testid="productList">
          {recommendedProducts &&
          recommendedProducts.products &&
          recommendedProducts.products.data &&
          recommendedProducts.products.data.length > 0 ? (
            <>
              <Grid container item>
                <OnChange name="product.id">
                  {(value: any) => {
                    handleSelectedProductChange(value * 1);
                  }}
                </OnChange>
                {recommendedProducts.products.data.map((product: Components.Schemas.ProductFoea) => (
                  <Grid item xs={12} sm={12} key={product.productId}>
                    <FormRadio
                      className={classes.switchContainer}
                      name="product.id"
                      value={product.productId}
                      defaultValue={selectedProduct}
                      label={
                        <ProductCard
                          product={product}
                          productTypeKey={productType}
                          className={classnames(classes.productCard, {
                            // eslint-disable-next-line eqeqeq
                            [classes.isSelectedProgram]: productField.input.value == product.productId,
                          })}
                        />
                      }
                      data-testid={`product${product.productId}-radio`}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <Typography variant="body1" className="textContainer">
              {t('ikk.FindProduct.productList.selectProduct')}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
