import React from 'react';
import classnames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

interface SectionHeaderProps {
  icon?: any;
  title: string;
  className?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    iconContainer: {
      position: 'relative',
      top: '-2px',
      paddingRight: '10px',
    },
    noWrap: {
      flexWrap: 'nowrap',
    },
  }),
);

const SectionHeader = ({ icon, title, className }: SectionHeaderProps) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      alignContent="center"
      justifyContent="flex-start"
      xs={12}
      className={classnames(classes.noWrap, className)}
    >
      {icon && (
        <Typography variant="h3" className={classes.iconContainer}>
          {icon}
        </Typography>
      )}
      <Typography variant="h3">{title}</Typography>
    </Grid>
  );
};

export default SectionHeader;
