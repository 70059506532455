import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { PERSON_TYPE } from 'common/constants';

import {
  EnumAttachmentOneAppProgramValues,
  EnumDetailedOneAppProgramValues,
  EnumGbzaOneAppProgramValues,
  EnumAttachmentOneAppSubsidyValues,
  EnumAttachmentOneAppCompanySizeValues,
  EnumAttachmentOneAppConsentRequestValues,
  EnumSpecialProgramUBR,
} from 'types/AdditonalDoumentsEnum';
import AdditionalAddress from './ProjectDetails/AdditionalAddress';
import AdditionalProjectInformation from './ProjectDetails/AdditionalProjectInformation';
import AdditionalFinancialInformation from './ProjectDetails/AdditionalFinancialInformation';
import FormDatePicker from '../components/form/FormDatePicker';
import CumulativeDeclarationOfAid from './ProjectDetails/CumulativeDeclarationOfAid';
import KMUDeclaration from './ProjectDetails/KMUDeclaration';
import ConsentToRequest from './ProjectDetails/ConsentToRequest';
import GbzaInformation from './ProjectDetails/GbzaInformation';
import SpecialProgramUBR from './ProjectDetails/SpecialProgramUBR';

export default function ProjectDetails() {
  const { t } = useTranslation();

  return (
    <Grid container item className="formSection" data-testid="projectDetails">
      <Typography variant="h2" className="header">
        {t('oneApp.ProjectDetails.title')}
      </Typography>
      <Grid container item xs={12} className="formGroup">
        <Grid item xs={12} md={6}>
          <FormDatePicker
            name="project.investmentDate"
            className="formItem"
            label={t('oneApp.ProjectDetails.investmentDate')}
            tooltip={t('oneApp.ProjectDetails.investmentDateTooltip')}
            data-testid="investmentDate-date"
          />
        </Grid>
      </Grid>
      <AdditionalAddress />
      <AdditionalProjectInformation />
      <Field name="applicant.applicantType" subscription={{ value: true }}>
        {({ input }) => {
          if (input.value === PERSON_TYPE.COMPANIES) {
            return <AdditionalFinancialInformation />;
          }
        }}
      </Field>

      <Field name="program.program" subscription={{ value: true }}>
        {({ input }) => (
          <>
            {EnumDetailedOneAppProgramValues.some((r) => input.value.includes(r)) && (
              <Grid container item className="formSection">
                <Typography variant="h2" className="header">
                  {t('oneApp.ProjectDetails.detailedInformation')}
                </Typography>
                {EnumGbzaOneAppProgramValues.some((r) => input.value.includes(r)) && <GbzaInformation />}
                {EnumAttachmentOneAppProgramValues.some((r) => input.value.includes(r)) && (
                  <Grid container item>
                    <Typography variant="h3" className="header">
                      {t('oneApp.ProjectDetails.additionalInformation')}
                    </Typography>
                    <Typography variant="body1" className="row">
                      {t('oneApp.ProjectDetails.additionalInformationText1')}
                      <br />
                      {t('oneApp.ProjectDetails.additionalInformationText2')}
                    </Typography>
                    {EnumAttachmentOneAppSubsidyValues.some((r) => input.value.includes(r)) && (
                      <CumulativeDeclarationOfAid />
                    )}
                    {EnumAttachmentOneAppCompanySizeValues.some((r) => input.value.includes(r)) && <KMUDeclaration />}
                    {EnumAttachmentOneAppConsentRequestValues.some((r) => input.value.includes(r)) && (
                      <ConsentToRequest />
                    )}
                  </Grid>
                )}
                {EnumSpecialProgramUBR.some((r) => input.value.includes(r)) && <SpecialProgramUBR />}
              </Grid>
            )}
          </>
        )}
      </Field>
    </Grid>
  );
}
