import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useField, useForm } from 'react-final-form';
import EmployeeCountCalculator from '../../OneAppForm/Applicant/EmployeeCountCalculator';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
}

function CalculateEmployeesModal({ open, onConfirm, onCancel }: Props) {
  const { t } = useTranslation();
  const form = useForm();

  const companyEmployeesOverThirty = useField('applicant.companyEmployeesOverThirty').input.value;
  const companyEmployeesUptoThirty = useField('applicant.companyEmployeesUptoThirty').input.value;
  const companyEmployeesUptoTwenty = useField('applicant.companyEmployeesUptoTwenty').input.value;
  const companyEmployees450Jobber = useField('applicant.companyEmployees450Jobber').input.value;
  const companyEmployeesParentalLeave = useField('applicant.companyEmployeesParentalLeave').input.value;
  const companyEmployeesTrainee = useField('applicant.companyEmployeesTrainee').input.value;
  const programField = useField('program.program');
  const [isValueChanged, setIsValueChanged] = useState<boolean>(false);

  const [defaultValues] = useState({
    companyEmployeesOverThirty,
    companyEmployeesUptoThirty,
    companyEmployeesUptoTwenty,
    companyEmployees450Jobber,
    companyEmployeesParentalLeave,
    companyEmployeesTrainee,
  });

  const setIsTouched = () => {
    if (!isValueChanged) setIsValueChanged((isValueChanged) => !isValueChanged);
  };

  const handleTakeOverCalculatedEmployees = () => {
    let calculatedTotalNumberOfEmployees = 0;

    const hasProgram78 = programField.input.value && programField.input.value === '78';
    const traineeMultiplyFactor = hasProgram78 ? 1 : 0;

    calculatedTotalNumberOfEmployees =
      companyEmployeesOverThirty * 1 +
      companyEmployeesUptoThirty * 0.75 +
      companyEmployeesUptoTwenty * 0.5 +
      companyEmployees450Jobber * 0.3 +
      companyEmployeesParentalLeave * 1 +
      companyEmployeesTrainee * traineeMultiplyFactor;

    calculatedTotalNumberOfEmployees = Math.round(calculatedTotalNumberOfEmployees * 100) / 100;

    form.change('applicant.companyNumberOfEmployees', calculatedTotalNumberOfEmployees);
    onConfirm();
  };

  const handleCancel = () => {
    if (isValueChanged) {
      form.batch(() => {
        form.change('applicant.companyEmployeesOverThirty', defaultValues.companyEmployeesOverThirty);
        form.change('applicant.companyEmployeesUptoThirty', defaultValues.companyEmployeesUptoThirty);
        form.change('applicant.companyEmployeesUptoTwenty', defaultValues.companyEmployeesUptoTwenty);
        form.change('applicant.companyEmployees450Jobber', defaultValues.companyEmployees450Jobber);
        form.change('applicant.companyEmployeesParentalLeave', defaultValues.companyEmployeesParentalLeave);
        form.change('applicant.companyEmployeesTrainee', defaultValues.companyEmployeesTrainee);
      });
    }
    onCancel();
  };

  return (
    <ConfirmationModal
      id="cal-employees-modal"
      content={<EmployeeCountCalculator setIsTouched={setIsTouched} />}
      open={open}
      header={t('oneApp.CompanySize.modalTitle')}
      confirmButton={t('oneApp.CompanySize.takeOverEmployeeNumber')}
      onConfirm={handleTakeOverCalculatedEmployees}
      onCancel={handleCancel}
      cancelButton={t('oneApp.CompanySize.cancelModal')}
    />
  );
}

export default CalculateEmployeesModal;
