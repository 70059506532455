import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import FormTextField from 'pages/oneApp/components/form/FormTextField';
import FormAutocomplete from 'pages/oneApp/components/form/FormAutocomplete';
import originList from 'types/Staatsangehoerigkeit';
import FormSelect from 'pages/oneApp/components/form/FormSelect';
import FormDatePicker from 'pages/oneApp/components/form/FormDatePicker';

const useStyles = makeStyles(() => ({
  subtitle: {
    marginTop: '30px',
  },
}));

export default function PersonalIdentification() {
  const { t } = useTranslation();
  const classes = useStyles();

  const idTypeLookup: { [key: string]: string } = t('oneApp.PersonalIdentification.idTypeLookup', {
    returnObjects: true,
  });

  return (
    <Grid container item className="formSubSection" data-testid="personalIdentification">
      <Typography variant="h3" className="subheader">
        {t('oneApp.PersonalIdentification.title')}
      </Typography>

      <Typography variant="body1">{t('oneApp.PersonalIdentification.infoText')}</Typography>

      <Typography variant="h3" className={classnames('subheader', classes.subtitle)}>
        {t('oneApp.PersonalIdentification.subtitle')}
      </Typography>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormDatePicker
            name="applicant.birthDate"
            autoComplete="bday"
            className="formItem"
            label={t('oneApp.PersonalIdentification.birthDate')}
            data-testid="birthDate-date"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.placeOfBirth"
            className="formItem"
            label={t('oneApp.PersonalIdentification.placeOfBirth')}
            data-testid="placeOfBirth"
            maxLength={35}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.birthName"
            autoComplete="family-name"
            className="formItem"
            label={t('oneApp.PersonalIdentification.birthName')}
            data-testid="birthName"
            maxLength={35}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            name="applicant.nationality"
            className="formItem"
            label={t('oneApp.PersonalIdentification.nationality')}
            data={originList}
            data-testid="nationality-autocomplete"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormSelect
            name="applicant.idType"
            className="formItem"
            label={t('oneApp.PersonalIdentification.idType')}
            options={idTypeLookup}
            labelWidth={190}
            data-testid="idType-select"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.idNumber"
            className="formItem"
            label={t('oneApp.PersonalIdentification.idNumber')}
            maxLength={35}
            data-testid="idNumber"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.idIssuePlace"
            className="formItem"
            label={t('oneApp.PersonalIdentification.idIssuePlace')}
            tooltip={t('oneApp.PersonalIdentification.idIssuePlace_tooltip')}
            maxLength={35}
            data-testid="idIssuePlace"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormDatePicker
            name="applicant.idIssueDate"
            className="formItem"
            label={t('oneApp.PersonalIdentification.idIssueDate')}
            data-testid="idIssueDate-date"
            tooltip={t('oneApp.PersonalIdentification.idIssueDate_tooltip')}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.idIssuedBy"
            className="formItem"
            label={t('oneApp.PersonalIdentification.idIssuedBy')}
            tooltip={t('oneApp.PersonalIdentification.idIssuedBy_tooltip')}
            maxLength={35}
            data-testid="idIssuedBy"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormDatePicker
            name="applicant.idExpirationDate"
            className="formItem"
            label={t('oneApp.PersonalIdentification.idExpirationDate')}
            data-testid="idExpirationDate-date"
            tooltip={t('oneApp.PersonalIdentification.idExpirationDate_tooltip')}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
