import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormDatePicker from '../../components/form/FormDatePicker';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  inline: {
    display: 'inline-block',
    padding: '0 !important',
    marginTop: '-.5em',
  },
  infoText: {
    padding: '0 0 20px 0',
    width: '100%',
  },
}));

export default function ProjectBegin({ selectedProduct }: any) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container item className="formSubSection">
      <Typography variant="h3" className={`${classes.fullWidth} subheader`}>
        {t('ikk.ProjectDescription.AddressForm.title')}
      </Typography>
      <Grid item container direction="column" xs={12} md={6}>
        <Typography variant="body1" className={`${classes.infoText}`}>
          {t('ikk.ProjectDescription.AddressForm.infoText1')}
        </Typography>

        <FormDatePicker
          name="project.investitionDate"
          className="formItem"
          label={t('ikk.ProjectDescription.AddressForm.investitionStartDate')}
          tooltip={
            selectedProduct === 264
              ? t('ikk.ProjectDescription.AddressForm.infoText264_tooltip')
              : t('ikk.ProjectDescription.AddressForm.infoText1_tooltip')
          }
          data-testid="investitionStartDate"
          required
        />
      </Grid>
    </Grid>
  );
}
