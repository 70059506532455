import isNotEmpty from 'util/isNotEmpty';

type Validators = {
  [key: string]: {
    [key: string]: (value: any, values: any, meta: any) => boolean | string;
  };
};

export const validators: Validators = {
  applicant: {
    applicantType: (value: string) => !isNotEmpty(value),
    accountingType: (value: string) => !isNotEmpty(value),
    sepaAccept: (value: string) => !isNotEmpty(value),
  },
  additions: {},
  oneAppForm: {
    notARobot: (isChecked: boolean) => !isChecked,
  },
  requestInformation: {
    // honeypot field if filled in field is invalid
    requestDetails: (value: string) => isNotEmpty(value),
  },
  implementationDuration: {
    beginDate: (value: string) => new Date(value) < new Date(),
    endDate: (value: string, values: any, meta: any) => {
      const bDateField = `beginDate${meta.name.charAt(meta.name.length - 1)}`;
      let bDate;
      if (values?.project?.projectPurpose?.dates) {
        bDate = values?.project?.projectPurpose.dates[bDateField];
      }
      if (value && !bDate) {
        return true;
      }
      return new Date(value) <= new Date(bDate);
    },
  },
  chargingStations: {
    chargingPoint: (value: number) => {
      return value < 1;
    },
    totalChargingPoints: (value: number) => value < 9,
  },
  common: {
    notEmpty: (value: string) => !isNotEmpty(value),
  },
};
