import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useField, useForm } from 'react-final-form';
import classnames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// @ts-ignore
import { OnChange } from 'react-final-form-listeners';

import { Components } from 'types/models';

import FormRadio from 'pages/oneApp/components/form/FormRadio';
import ConfirmationModal from 'pages/oneApp/components/modals/ConfirmationModal';
import ToolTipIcon from 'components/fields/ToolTipIcon';
import { sortByKey } from 'util/data.utils';
import { ORDER } from 'common/constants';
import { fundingPurposes, usagePurposes, programs, UsagePurpose, Program as ProgramType } from './programLogic';
import ProgramCard from './ProgramCard';
import FormAutocomplete from '../../components/form/FormAutocomplete';

const useStyles = makeStyles(() => ({
  programCard: {
    opacity: '0.7',
  },
  isSelectedProgram: {
    opacity: '1',
  },
  switchContainer: {
    marginBottom: '20px',
  },
  selectSubtitle: {
    width: '100%',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  linkStyle: {
    fontSize: '14px',
    textDecoration: 'underline',
    color: '#1b618c !important',
    textAlign: 'left',
  },
  productHeader: {
    marginBottom: '20px',
  },
  selectionInfo: {
    marginTop: '12px',
  },
  paddingZero: {
    padding: '0 !important',
  },
}));

export default function ProductSelectionModalContent() {
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useForm();

  const fundingPurposeField = useField('program.modalFundingPurpose');
  const usagePurposeField = useField('program.modalUsagePurpose');
  const programField = useField('program.modalProgram');

  const [openFundingPurposeModal, setOpenFundingPurposeModal] = useState<boolean>(false);
  const [newFundingPurpose, setNewFundingPurpose] = useState<Components.Schemas.StrictKeyValueField>(
    fundingPurposeField.input.value,
  );

  const selectedFundingPurpose = fundingPurposeField.input.value;
  const selectedUsagePurpose = usagePurposeField.input.value;
  let usgPurposeOptions: UsagePurpose[] = [];
  let recPrograms: ProgramType[] = [];

  const [recommendedPrograms, setRecommendedPrograms] = useState<ProgramType[]>(() => {
    if (selectedFundingPurpose !== null && selectedFundingPurpose.id !== undefined) {
      const optionIds = fundingPurposes[selectedFundingPurpose.id]?.usagePurposeIds as number[];
      const filteredUsagePurposes = optionIds
        ? optionIds.map((id: number) => usagePurposes[id]).filter((option: UsagePurpose) => option && !option.isHidden)
        : [];
      usgPurposeOptions = filteredUsagePurposes;

      if (selectedUsagePurpose !== null && selectedUsagePurpose.id !== undefined) {
        const fundingProgramOptionIds = fundingPurposes[selectedFundingPurpose.id]?.programIds;
        const usageProgramOptionIds = usagePurposes[selectedUsagePurpose.id]?.programIds;

        recPrograms = usageProgramOptionIds
          ? usageProgramOptionIds
              .map((id: string) => programs[id])
              .filter((option: ProgramType) => option)
              .filter((program) => fundingProgramOptionIds.includes(program.id))
          : [];
      } else {
        const usageProgramOptionIds = fundingPurposes[selectedFundingPurpose.id]?.programIds;
        recPrograms = usageProgramOptionIds
          ? usageProgramOptionIds.map((id: string) => programs[id]).filter((option: ProgramType) => option)
          : [];
      }
    }
    return recPrograms;
  });
  const [usagePurposeOptions, setUsagePurposeOptions] = useState<UsagePurpose[]>(usgPurposeOptions);

  const setSortedRecommendedPrograms = useCallback((programs) => {
    setRecommendedPrograms(sortByKey(programs, 'id', ORDER.ASC));
  }, []);

  const fundingPurposesOptions = useMemo(
    () => Object.values(fundingPurposes).filter((purpose) => !purpose.isHidden),
    [],
  );

  const handleFundingPurposeChange = useCallback(
    (selectedFundingPurpose: Components.Schemas.StrictKeyValueField) => {
      form.batch(() => {
        form.change('program.modalFundingPurpose', selectedFundingPurpose);
        form.change('program.modalUsagePurpose', null);
      });
      if (selectedFundingPurpose === null || selectedFundingPurpose.id === undefined) {
        programField.input.onChange(null);
        setRecommendedPrograms([]);
        setUsagePurposeOptions([]);
      } else {
        const optionIds = fundingPurposes[selectedFundingPurpose.id]?.usagePurposeIds as number[];
        const filteredUsagePurposes = optionIds
          ? optionIds
              .map((id: number) => usagePurposes[id])
              .filter((option: UsagePurpose) => option && !option.isHidden)
          : [];
        setUsagePurposeOptions(sortByKey(filteredUsagePurposes, 'title'));

        const programOptionIds = fundingPurposes[selectedFundingPurpose.id]?.programIds;
        const filteredPrograms: ProgramType[] = programOptionIds
          ? programOptionIds.map((id: string) => programs[id]).filter((option: ProgramType) => option)
          : [];

        setSortedRecommendedPrograms(filteredPrograms);
        const selectedProgram = programOptionIds.includes(programField.input.value) ? programField.input.value : '';
        form.batch(() => {
          form.change('program.modalProgram', selectedProgram);
          form.change('program.modalUsagePurpose', null);
        });
      }
    },
    [programField.input, setSortedRecommendedPrograms, form],
  );

  const handleUsagePurposeChange = (selectedUsagePurpose: Components.Schemas.StrictKeyValueField) => {
    if (selectedUsagePurpose === null || selectedUsagePurpose.id === undefined) {
      const programOptionIds = fundingPurposes[fundingPurposeField.input.value.id]?.programIds;
      const filteredPrograms: ProgramType[] = programOptionIds
        ? programOptionIds.map((id: string) => programs[id]).filter((option: ProgramType) => option)
        : [];
      setSortedRecommendedPrograms([...filteredPrograms]);
    } else {
      const optionIds = usagePurposes[selectedUsagePurpose.id]?.programIds;
      const filteredUsagePurposePrograms: ProgramType[] = optionIds
        ? optionIds
            .map((id: string) => programs[id])
            .filter((option: ProgramType) => option)
            .filter((program: ProgramType) =>
              programs[program.id].fundingPurposeIds.includes(selectedFundingPurpose.id),
            )
        : [];
      setSortedRecommendedPrograms(filteredUsagePurposePrograms);
      programField.input.onChange(optionIds.includes(programField.input.value) ? programField.input.value : undefined);
    }
  };

  const programSelectHandler = (selectedProgram: Components.Schemas.EnumProgram) => {
    if (selectedProgram) {
      const optionIds = programs[selectedProgram]?.usagePurposeIds as number[];

      const uniqueOptionIds = optionIds.reduce(
        (unique: number[], item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
      );

      const filteredUsagePurposes = uniqueOptionIds
        .map((id: number) => usagePurposes[id])
        .filter((option: UsagePurpose) => option && !option.isHidden);

      setUsagePurposeOptions(sortByKey(filteredUsagePurposes, 'title'));
    }
  };

  const handleFundingPurposeModalOpen = useCallback(
    (selectedFundingPurpose: Components.Schemas.StrictKeyValueField) => {
      const program = programField.input.value;
      if (!program) {
        handleFundingPurposeChange(selectedFundingPurpose);
      } else {
        setNewFundingPurpose(selectedFundingPurpose);
        setOpenFundingPurposeModal(true);
      }
    },
    [handleFundingPurposeChange, programField.input.value],
  );

  const cancelFundingPurposeModal = useCallback(() => {
    setOpenFundingPurposeModal(false);
  }, []);

  const confirmFundingPurposeModal = useCallback(
    (newFundingPurpose) => {
      handleFundingPurposeChange(newFundingPurpose);
      programField.input.onChange(null);
      setOpenFundingPurposeModal(false);
    },
    [handleFundingPurposeChange, programField.input],
  );

  return (
    <Grid container item className="modalFormSection" data-testid="program">
      <Typography variant="body1" className="textContainer">
        <b>
          {t('oneApp.ProgramModal.info')}
          {': '}
        </b>
        {t('oneApp.ProgramModal.infoText')}{' '}
      </Typography>

      <Grid container item xs={12} justifyContent="flex-start" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            name="program.modalFundingPurpose"
            className="formItem"
            label={t('oneApp.Program.fundingPurpose')}
            data={fundingPurposesOptions}
            onChange={handleFundingPurposeModalOpen}
            data-testid="modal-fundingPurpose-autocomplete"
            preventDefaultOnChange
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            name="program.modalUsagePurpose"
            className="formItem"
            disabled={!fundingPurposeField.input.value}
            label={t('oneApp.Program.usagePurpose')}
            onChange={handleUsagePurposeChange}
            data={usagePurposeOptions}
            data-testid="modal-usagePurpose-autocomplete"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} className="formGroup">
        <Grid container direction="column" item justifyContent="center" data-testid="modal-programRecommendation">
          {recommendedPrograms.length > 0 ? (
            <>
              <Typography variant="h3" className={classes.productHeader}>
                {t('oneApp.ProgramRecommendation.title')}
              </Typography>

              <Grid container item>
                <OnChange name="program.modalProgram">
                  {(value: any) => {
                    programSelectHandler(value);
                  }}
                </OnChange>
                {recommendedPrograms.map((program: ProgramType) => (
                  <Grid item xs={12} sm={12} key={program.id}>
                    <FormRadio
                      className={classes.switchContainer}
                      name="program.modalProgram"
                      value={program.id}
                      label={
                        <ProgramCard
                          program={program}
                          className={classnames(classes.programCard, {
                            [classes.isSelectedProgram]: programField.input.value === program.id,
                          })}
                        />
                      }
                      data-testid={`modal-program${program.id}-radio`}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <Typography variant="body1" className="textContainer">
              {t('oneApp.ProgramRecommendation.selectProduct')}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={12} md={6} className={classes.paddingZero}>
        <Grid item xs={10}>
          <Typography variant="h6" className={classes.selectionInfo}>
            {t('oneApp.ProgramModal.productSelectionInfo')}
          </Typography>
        </Grid>
        <Grid container item xs={1} className="tooltip">
          <ToolTipIcon
            tip="oneApp.ProgramModal.productSelectionInfo"
            tipList={[
              'oneApp.ProgramModal.productSelectionInfoTooltipItem1',
              'oneApp.ProgramModal.productSelectionInfoTooltipItem2',
              'oneApp.ProgramModal.productSelectionInfoTooltipItem3',
            ]}
            tipEnd="oneApp.ProgramModal.productSelectionInfoTooltipEnd"
            wideBox
          />
        </Grid>
      </Grid>
      <ConfirmationModal
        id="product-modal-funding-purpose-one-app-modal"
        open={openFundingPurposeModal}
        header=""
        content="Die Änderung des Förderzweckes setzt Ihre bereits getroffene Produktauswahl zurück. Möchten Sie fortfahren?"
        onConfirm={() => confirmFundingPurposeModal(newFundingPurpose)}
        onCancel={() => cancelFundingPurposeModal()}
      />
    </Grid>
  );
}
