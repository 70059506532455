import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FormTextField from '../../components/form/FormTextField';

const INPUT_PROPS = {
  company: {
    maxLength: 35,
  },
  companyContinuation: {
    maxLength: 35,
  },
};

export default function Company() {
  const { t } = useTranslation();

  return (
    <Grid container item className="collapsibleFormSubSection" data-testid="company">
      <Typography variant="h3" className="subheader">
        {t('oneApp.Company.title')}
      </Typography>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.company"
            autoComplete="organization"
            className="formItem"
            label={t('oneApp.Company.company')}
            maxLength={INPUT_PROPS.company.maxLength}
            required
            data-testid="company1"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.companyContinuation"
            className="formItem"
            label={t('oneApp.Company.companyContinuation')}
            maxLength={INPUT_PROPS.companyContinuation.maxLength}
            data-testid="companyContinuation"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
