import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { Grid, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useHistory } from 'react-router-dom';
import { ROOT } from 'common/constants/routes';

import { LoadingButtonContainer } from '../components/LoadingButton';
import { redirectToProfileDelete, titleChange } from '../common/helpers/helper';

const useStyles = makeStyles(() =>
  createStyles({
    confirmText: {
      marginTop: '2rem',
      marginBottom: '2rem',
      textAlign: 'center',
      fontWeight: 'bold',
    },
  }),
);

const DeleteProfile = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    titleChange(t('deleteProfile.windowTitle'));
  });

  const handleOverviewClick = () => {
    history.push(ROOT);
  };

  const handleDeleteAllRequestsClick = async () => {
    try {
      setLoading(true);
      setLoading(false);
      redirectToProfileDelete();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom>
              {t('deleteProfile.title')}
            </Typography>
          </Grid>
          <Grid item xs={12} spacing={2}>
            <Typography variant="body1">{t('deleteProfile.description')}</Typography>
          </Grid>
          <Grid item xs={12} spacing={2} className={classes.confirmText}>
            <Typography variant="body1">{t('deleteProfile.description_confirm')}</Typography>
          </Grid>
          <Grid container item xs={6} justifyContent="center">
            <Button
              variant="contained"
              color="secondary"
              data-testid="overview"
              onClick={handleOverviewClick}
              startIcon={<span className="icArrowsLeft" />}
            >
              {t('deleteProfile.buttons.overview')}
            </Button>
          </Grid>
          <Grid container item xs={6} justifyContent="center">
            <LoadingButtonContainer
              color="primary"
              data-testid="deleteProfile"
              loading={loading}
              text={t('deleteProfile.buttons.delete')}
              onClick={handleDeleteAllRequestsClick}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DeleteProfile;
