import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

import ToolTipIcon from 'components/fields/ToolTipIcon';
import { useField } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

import FormRadio from './FormRadio';

const useStyles = makeStyles(() => ({
  alignTooltip: {
    flexWrap: 'nowrap',
  },
  displayContent: { display: 'contents' },
}));

const FormRadioGroup = ({ options, label, name, tooltip, tipList, tipEnd, validate, errorMsg }: any) => {
  const field = useField(name);
  const classes = useStyles();

  return (
    <Grid container>
      <Grid container item xs={tooltip && 12} alignItems="center" className={classes.alignTooltip}>
        <Typography variant="body1" className={classes.displayContent}>
          {label}
          {tooltip && <ToolTipIcon tip={tooltip} tipList={tipList} tipEnd={tipEnd} />}
        </Typography>
      </Grid>

      <Grid container direction="column">
        {Object.keys(options).map((o: string) => (
          <FormRadio key={o} name={name} label={options[o]} value={o} validate={validate} data-testid={o} />
        ))}
      </Grid>
      {errorMsg && field.meta.submitFailed && !field.meta.valid && (
        <Typography variant="body1" className="errorMessage">
          {errorMsg}
        </Typography>
      )}
    </Grid>
  );
};

export default React.memo(FormRadioGroup);
