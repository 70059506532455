import React, { useCallback } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';

import IconButton from '@material-ui/core/IconButton';
import { withStyles, makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      margin: theme.spacing(1),
      height: '48px',
      width: '48px',
      '&:hover': {
        height: '48px',
        width: '48px',
        '& svg': {
          color: '#60800f',
        },
      },
      '& svg': {
        color: '#1b618c',
      },
    },
    list: {
      listStyleType: 'disc',
      marginLeft: '20px',
    },
  }),
);

interface Props {
  boxed?: boolean;
  tip: string;
  tipDetails?: string;
  tipList?: Array<string>;
  tipTitle?: any;
  customClass?: string;
  tipEnd?: string;
  wideBox?: boolean;
}

export default function ToolTipIcon({ tip, tipDetails, tipList, tipTitle, tipEnd, wideBox }: Props) {
  const [clicked, setClicked] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClickAway = () => {
    setClicked(false);
  };

  const handleClick = () => {
    setClicked(true);
  };

  const handleClose = useCallback(() => {
    if (!clicked) {
      setOpen(false);
    }
  }, [clicked]);

  const handleOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    handleClose();
  }, [handleClose]);

  const component = tipList ? 'div' : 'p';

  const HtmlTooltip = withStyles(() => ({
    tooltip: {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
      backgroundColor: '#f6f3f0',
      margin: '8px',
      padding: '8px',
      maxWidth: wideBox ? '650px' : '450px',
    },
  }))(Tooltip);

  const title = (
    <Typography component={component}>
      {t(tip) as string}
      {tipDetails ? (
        <div>
          <br />
          {t(tipDetails) as string}
        </div>
      ) : null}
      {tipList && (
        <ul className={classes.list}>
          {tipList.map((item: string) => (
            <li key={item}>
              <Typography>{t(item)}</Typography>
            </li>
          ))}
        </ul>
      )}
      {tipEnd && (t(tipEnd) as string)}
    </Typography>
  );

  const tooltip = (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <HtmlTooltip
          title={tipTitle || title}
          interactive
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          enterTouchDelay={0}
          PopperProps={{
            disablePortal: true,
            popperOptions: {
              positionFixed: true,
              modifiers: {
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'window', // where "window" is the boundary
                },
              },
            },
          }}
        >
          <IconButton aria-label="information" className={classes.icon} onClick={handleClick}>
            <InfoIcon color="primary" />
          </IconButton>
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  );

  return tooltip;
}
