import GeneralRequestInformation from '../IKKForm/GeneralInformation/GeneralRequestInformation';
// eslint-disable-next-line import/no-cycle
import FindProduct from '../IKKForm/Product/FindProduct';
import FinancingDetails from '../IKKForm/FinancingDetails/FinancingDetails';
import ApplicantSelection from '../IKKForm/ApplicantDetails/ApplicantSelection';
import Attachments from '../IKKForm/Attachments/Attachments';
import ProjectDescription from '../IKKForm/ProjectDescription/ProjectDescription';
import InvestPlan from '../IKKForm/InvestmentPlan/InvestPlan';
import ProductSpecificInputs from '../IKKForm/ProductSpecificInput/ProductSpecificInputs';
import Consent from '../IKKForm/Consent/Consent';
import SubmitRequest from '../IKKForm/SubmitRequest/SubmitRequest';

interface Props {
  urlParams?: { [key: string]: string | null };
  next: string | false;
  productType?: string | false;
  onProductTypeChange?: Function;
  onSelectedProductChange?: Function;
  selectedProduct?: string | false;
  applicantType?: string | false;
  onApplicantTypeChange?: Function;
  creditAmountMainField?: string;
  creditAmountShadowField?: string;
  financingCreditAmountShadowField?: string;
  productDetails?: Object;
  loading?: boolean;
}

export interface ContentMenuItem {
  id: string;
  label: string;
  Component: (props: Props) => JSX.Element | null;
  isDefault?: boolean;
  isVisibleNoCreditProductSelected: boolean;
  isVisibleNoProductSelected: boolean;
}

export const menuItemFinancingDetails: ContentMenuItem = {
  id: 'financingDetails',
  label: 'ikk.contentProgressBar.financingDetails',
  Component: FinancingDetails,
  isVisibleNoCreditProductSelected: true,
  isVisibleNoProductSelected: false,
};

export const menuItemApplicantSelection: ContentMenuItem = {
  id: 'applicantSelection',
  label: 'ikk.contentProgressBar.applicantSelection',
  Component: ApplicantSelection,
  isVisibleNoCreditProductSelected: true,
  isVisibleNoProductSelected: false,
};

export const menuItemProjectDescription: ContentMenuItem = {
  id: 'projectDescription',
  label: 'ikk.contentProgressBar.projectDescription',
  Component: ProjectDescription,
  isVisibleNoCreditProductSelected: true,
  isVisibleNoProductSelected: false,
};

export const menuItemInvestitionPlan: ContentMenuItem = {
  id: 'investitionPlan',
  label: 'ikk.contentProgressBar.investitionPlan',
  Component: InvestPlan,
  isVisibleNoCreditProductSelected: true,
  isVisibleNoProductSelected: false,
};

export const menuItemProductSpecificInput: ContentMenuItem = {
  id: 'productSpecificInput',
  label: 'ikk.contentProgressBar.productSpecificInput',
  Component: ProductSpecificInputs,
  isVisibleNoCreditProductSelected: true,
  isVisibleNoProductSelected: false,
};

export const menuItemAttachments: ContentMenuItem = {
  id: 'attachments',
  label: 'ikk.contentProgressBar.attachments',
  Component: Attachments,
  isVisibleNoCreditProductSelected: true,
  isVisibleNoProductSelected: false,
};
export const menuItemConsent: ContentMenuItem = {
  id: 'consent',
  label: 'ikk.contentProgressBar.consent',
  Component: Consent,
  isVisibleNoCreditProductSelected: true,
  isVisibleNoProductSelected: false,
};

export const menuItemSubmitRequest: ContentMenuItem = {
  id: 'submitRequest',
  label: 'ikk.contentProgressBar.submitRequest',
  Component: SubmitRequest,
  isVisibleNoCreditProductSelected: true,
  isVisibleNoProductSelected: true,
};

export const menuItems: ContentMenuItem[] = [
  {
    id: 'generalRequestInformation',
    label: 'ikk.contentProgressBar.generalRequestInformation',
    Component: GeneralRequestInformation,
    isDefault: true,
    isVisibleNoCreditProductSelected: true,
    isVisibleNoProductSelected: true,
  },
  {
    id: 'findProduct',
    label: 'ikk.contentProgressBar.findProduct',
    Component: FindProduct,
    isVisibleNoCreditProductSelected: true,
    isVisibleNoProductSelected: true,
  },
  menuItemFinancingDetails,
  menuItemApplicantSelection,
  menuItemProjectDescription,
  menuItemInvestitionPlan,
  menuItemProductSpecificInput,
  menuItemAttachments,
  menuItemConsent,
  menuItemSubmitRequest,
];

export default {
  menuItems,
  menuItemFinancingDetails,
  menuItemApplicantSelection,
  menuItemProjectDescription,
  menuItemInvestitionPlan,
  menuItemProductSpecificInput,
  menuItemAttachments,
  menuItemConsent,
  menuItemSubmitRequest,
};
