import React from 'react';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';

import { makeStyles } from '@material-ui/core/styles';

interface Props {
  className?: string;
  title?: string;
  content: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  programNumber: {
    fontSize: '21px',
    position: 'relative',
    padding: '10px 0 0 13px',
    wordBreak: 'break-all',
  },
  programKredit: {
    fontSize: '10px',
    textTransform: 'uppercase',
    padding: '5px 13px',
    fontWeight: 'bold',
  },
  programNumberContainer: {
    position: 'relative',
    minWidth: '140px',
    minHeight: '120px',
    backgroundColor: '#60800f',
    '&:after': {
      content: "''",
      position: 'absolute',
      width: '0',
      height: '0',
      left: '140px',
      top: '17px',
      borderStyle: 'solid',
      borderWidth: '10px 0 10px 10px',
      borderColor: `transparent transparent transparent #60800f`,
    },
  },
  programDetails: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f6f3f0',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
}));

export default function SelectionCard({ className = '', title, content = null }: Props) {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className}`}>
      <div className={classes.programNumberContainer}>
        <Typography variant="body1" className={classes.programNumber} style={{ color: '#fff' }}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.programKredit} style={{ color: '#fff' }}>
          kredit
        </Typography>
      </div>
      <div className={classes.programDetails}>
        <CardContent className={classes.content}>
          <Typography component="div" variant="body2" align="left" className={classes.content}>
            {content}
          </Typography>
        </CardContent>
      </div>
    </div>
  );
}
