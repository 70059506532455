import React from 'react';
import { Field, useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { makeStyles } from '@material-ui/core/styles';
import InfoBox from '../components/common/InfoBox';

const useStyles = makeStyles(() => ({
  textarea: {
    marginBottom: '20px',
    width: 'inherit',
  },
}));

export default function Notes() {
  const { t } = useTranslation();
  const classes = useStyles();

  const infoLookup: [{ id: string; title: string; description: string[]; text: string }] = t(
    'oneApp.Notes.commentSectionInfoLookup',
    {
      returnObjects: true,
    },
  );

  const programField = useField('program.program');
  const filteredInfo = infoLookup.find(({ id }) => id === programField.input.value);

  return (
    <Grid container item className="formSection" data-testid="notes">
      <Typography variant="h2" className="header">
        {t('oneApp.Notes.title')}
      </Typography>
      {filteredInfo && (
        <InfoBox
          content={filteredInfo.text}
          key={filteredInfo.id}
          title={filteredInfo.title}
          description={filteredInfo.description}
        />
      )}

      <Grid container direction="column">
        <Field
          name="notes"
          subscription={{ value: true }}
          render={({ input }) => (
            <TextareaAutosize
              {...input}
              minRows={8}
              maxLength={3000}
              className={`textarea ${classes.textarea}`}
              data-testid="infoText"
              aria-labelledby="infoTextHint"
            />
          )}
        />
        <Typography variant="caption" id="infoTextHint">
          {t('oneApp.Notes.infoText')}
        </Typography>
      </Grid>
    </Grid>
  );
}
