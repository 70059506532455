import React from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    padding: '1em',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '760px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '760px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1024px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1280px',
    },
  },
  link: {
    textDecoration: 'none',
    fontSize: '13px',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  linkContainer: {
    display: 'flex',
    flex: '1',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  copyRight: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  spacing: {
    padding: '8px 0',
    marginRight: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      marginRight: '0.1rem',
    },
  },
}));

export default function Footer() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <footer>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item container xs={12} className={classes.container} justifyContent="center" alignItems="center">
          <Grid>
            <Typography className={classnames(classes.copyRight, classes.spacing)} variant="body2">
              {t('footer.copyright', { year: new Date().getFullYear() })}
            </Typography>
          </Grid>
          <Grid>
            <ul className={classes.linkContainer}>
              <li className={classes.spacing}>
                <Link
                  href="https://www.kfw.de/KfW-Konzern/Barrierefreiheit.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                  variant="body2"
                  data-testid="footerAccessibility"
                >
                  {t('footer.accessibility')}
                </Link>
              </li>
              <li className={classes.spacing}>
                <Link
                  href="https://www.kfw.de/KfW-Konzern/Datenschutz.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                  variant="body2"
                  data-testid="footerDataProtection"
                >
                  {t('footer.dsgvo')}
                </Link>
              </li>
              <li className={classes.spacing}>
                <Link
                  href="https://www.kfw.de/inlandsfoerderung/Steuerdisclaimer.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                  variant="body2"
                  data-testid="footerLegalNotice"
                >
                  {t('footer.legal')}
                </Link>
              </li>
              <li className={classes.spacing}>
                <Link
                  href="https://www.kfw.de/KfW-Konzern/Impressum.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                  variant="body2"
                  data-testid="footerImprint"
                >
                  {t('footer.impressum')}
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}
