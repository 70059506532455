import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { validators } from '../../formValidation';
import FormRadioGroup from '../../components/form/FormRadioGroup';
import ApplicantInformation from './ApplicantInformation';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: '45px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '38px',
      paddingBottom: '20px !important',
    },
  },
  list: {
    marginLeft: '2rem',
    listStyleType: 'disc',
  },
  requestIdentifierWrapper: {
    width: '100%',
  },
  requestIdentifier: {
    width: '100%',
  },
  inputInfoText: {
    padding: '20px 0 10px 0',
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
}));

export default function ApplicantSelection({ applicantType, onApplicantTypeChange, productDetails, productType }: any) {
  const { t } = useTranslation();
  const classes = useStyles();

  const applicantLookup: { [key: string]: string } = t('ikk.ApplicantSelection.categories.applicantLookup', {
    returnObjects: true,
  });

  return (
    <Grid container className="formSection">
      <Typography variant="h2" className="header">
        {t('ikk.ApplicantSelection.title')}
      </Typography>
      <Grid container className={classes.requestIdentifierWrapper}>
        <FormRadioGroup
          name="applicant.requestApplicant"
          options={applicantLookup}
          label={t('ikk.ApplicantSelection.categories.label')}
          radioTooltip={t('ikk.ApplicantSelection.categories.tooltip')}
          validate={validators.applicant.applicantType}
          errorMsg={t('validation.emptyField')}
          onChange={onApplicantTypeChange}
          defaultValue={applicantType}
        />
      </Grid>
      <ApplicantInformation productType={productType} productDetails={productDetails} />
    </Grid>
  );
}
