import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import { makeStyles, Grid, Typography, Link, Button, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ToolTipIcon from 'components/fields/ToolTipIcon';
import FormTextField from '../../components/form/FormTextField';
import FormNumberInput from '../../components/form/FormNumberInput';
import { POST_CODE_PATTERN } from '../../constants/index';
import { validators } from '../../formValidation';

const useStyles = makeStyles(() => ({
  link: {
    position: 'relative',
    padding: '20px 10px 0 0',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  inline: {
    display: 'inline-block',
    padding: '0 !important',
    marginTop: '-.5em',
  },
  underline: {
    textDecorationLine: 'underline',
  },
  bold: {
    fontWeight: 'bold',
  },
  fullWidth: {
    width: '100%',
  },
  marginTop: {
    marginTop: '1%',
  },
}));

const INPUT_PROPS = {
  street: {
    maxLength: 60,
    formNoValidate: true,
  },
  houseNumber: {
    maxLength: 9,
    formNoValidate: true,
  },
  location: {
    maxLength: 35,
  },
  postcode: {
    maxLength: 5,
  },
};

export default function ChargingStation() {
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useForm();

  const [numberOfEntries, setNumberOfEntries] = React.useState<number[]>([1]);

  // eslint-disable-next-line array-callback-return
  numberOfEntries.map((entry, index) => {
    if (!form.getFieldState(`productSpecific.chargingStation.numberOfChargingPoints${index}`)?.value)
      form.change(`productSpecific.chargingStation.numberOfChargingPoints${index}`, 0);
  });

  const addNewChargingStationsHandler = () => {
    if (numberOfEntries.length < 11) {
      const newStations: number[] = [...numberOfEntries];
      newStations.push(1);
      setNumberOfEntries(newStations);
    }
  };

  const deleteChargingStationsHandler = (index: number) => {
    const newStations: number[] = [...numberOfEntries];
    newStations.splice(index, 1);
    form.change(`productSpecific.chargingStation.numberOfChargingPoints${index}`, undefined);
    form.change(`productSpecific.chargingStation.plz${index}`, undefined);
    form.change(`productSpecific.chargingStation.city${index}`, undefined);
    form.change(`productSpecific.chargingStation.houseNumber${index}`, undefined);
    form.change(`productSpecific.chargingStation.street${index}`, undefined);
    let sum: number = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < numberOfEntries.length; i++) {
      sum += parseInt(
        form.getFieldState(`productSpecific.chargingStation.numberOfChargingPoints${i}`)?.value || '0',
        10,
      );
    }
    form.change('productSpecific.chargingStation.totalChargingPoints', sum);
    setNumberOfEntries(newStations);
  };

  const handleSumChange = (value: string, index: number) => {
    let sum: number = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < numberOfEntries.length; i++) {
      if (i === index) {
        sum += parseInt(value, 10) || 0;
      } else {
        sum += parseInt(
          form.getFieldState(`productSpecific.chargingStation.numberOfChargingPoints${i}`)?.value || '0',
          10,
        );
      }
    }
    form.change('productSpecific.chargingStation.totalChargingPoints', sum);
  };

  return (
    <Grid container item className="formSubSection" data-testid="chargingStation">
      <Typography variant="h3">{t('ikk.ChargingStation.title')}</Typography>
      <Typography variant="body1">{t('ikk.ChargingStation.infoText1')}</Typography>
      <Grid container item xs={12} lg={4}>
        <Link
          target="_blank"
          href="https://www.kfw.de/inlandsfoerderung/%C3%96ffentliche-Einrichtungen/Kommunen/F%C3%B6rderprodukte/Liste-der-f%C3%B6rderf%C3%A4higen-Ladestationen/"
          className={classes.link}
        >
          <u>{t('ikk.ChargingStation.listChargingStation')}</u>
        </Link>
      </Grid>

      <Typography variant="body1">
        {t('ikk.ChargingStation.infoText2_1')}
        <u>{t('ikk.ChargingStation.infoText2_2')}</u>
        {t('ikk.ChargingStation.infoText2_3')}
        <div className={classes.inline}>
          <ToolTipIcon tip="ikk.ProjectDescription.AddressForm.infoText1_tooltip" />
        </div>
      </Typography>

      <Grid className={classes.inline}>
        <Typography variant="body1">
          <b>{t('ikk.ChargingStation.infoText3_1')}</b>
          {t('ikk.ChargingStation.infoText3_2')}
        </Typography>
      </Grid>

      <Typography variant="body1">{t('ikk.ChargingStation.infoText3_3')}</Typography>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={2}>
          <Typography variant="body1" className={classes.bold}>
            {t('ikk.ChargingStation.chargingStation')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="body1" className={classes.bold}>
            {t('ikk.ChargingStation.chargingPoint')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="body1" className={classes.bold}>
            {t('ikk.ChargingStation.address')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="center" className="formGroup" />
      {numberOfEntries.map((entry, index) => {
        return (
          <>
            <Grid container item xs={12} justifyContent="center" className="formGroup">
              <Grid item xs={12} md={2}>
                <Typography variant="body1" className={classes.bold}>
                  {index + 1}.
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormNumberInput
                  className="formItem"
                  required
                  thousandSeparators
                  isInline
                  decimalScale={0}
                  integerScale={13}
                  onChange={(evt: any) => {
                    handleSumChange(evt.target.value, index);
                  }}
                  data-testid="numberOfChargingPoints"
                  label={t('ikk.ChargingStation.count')}
                  validate={validators.chargingStations.chargingPoint}
                  errorMsg={t('ikk.ChargingStation.chargingPointError')}
                  name={`productSpecific.chargingStation.numberOfChargingPoints${index}`}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container item xs={12} justifyContent="center" className="formGroup">
                  <Grid item xs={12} md={4}>
                    <FormTextField
                      name={`productSpecific.chargingStation.plz${index}`}
                      label={t('ikk.common.Address.postcode')}
                      className="formItem"
                      required
                      pattern={POST_CODE_PATTERN}
                      maxLength={INPUT_PROPS.postcode.maxLength}
                      data-testid="chargingStationPLZ"
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <FormTextField
                      name={`productSpecific.chargingStation.city${index}`}
                      label={t('ikk.common.Address.location')}
                      className="formItem"
                      required
                      maxLength={INPUT_PROPS.location.maxLength}
                      data-testid="chargingStationTown"
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center" className={`formGroup ${classes.marginTop}`}>
                  <Grid item xs={12} md={4}>
                    <FormTextField
                      name={`productSpecific.chargingStation.houseNumber${index}`}
                      className="formItem"
                      label={t('ikk.common.Address.houseNumber')}
                      maxLength={INPUT_PROPS.houseNumber.maxLength}
                      data-testid="chargingStationHouseNumber"
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <FormTextField
                      name={`productSpecific.chargingStation.street${index}`}
                      autoComplete="chargingStation-street-address"
                      className="formItem"
                      label={t('ikk.common.Address.street')}
                      maxLength={INPUT_PROPS.street.maxLength}
                      data-testid="chargingStationStreet"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {index > 0 && (
              <Grid item xs={12} md={4}>
                <IconButton aria-label="delete" onClick={() => deleteChargingStationsHandler(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
          </>
        );
      })}

      <Grid container item>
        <Grid item xs={12} md={4}>
          <Button variant="text" onClick={addNewChargingStationsHandler}>
            <Typography variant="body1">
              <u>{t('ikk.ChargingStation.addChargingStations')}</u>
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={12} md={2}>
          <Typography variant="body1">
            <b>{t('ikk.ChargingStation.totalNumberOfChargingPoints')}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <FormNumberInput
            isInline
            disabled
            thousandSeparators
            label=""
            decimalScale={2}
            integerScale={13}
            data-testid="totalNumberOfChargingPoints"
            errorMsg={t('ikk.ChargingStation.totalChargingPointsError')}
            validate={validators.chargingStations.totalChargingPoints}
            name="productSpecific.chargingStation.totalChargingPoints"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
