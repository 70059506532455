import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Link } from '@material-ui/core';
import { LoadingButtonContainer } from 'components/LoadingButton';

export default function ConsentToRequest() {
  const { t } = useTranslation();

  return (
    <Grid container item className="formAttachmentSection">
      <Typography variant="h4">{t('oneApp.ProjectDetails.consentToRequestHeading')}</Typography>

      <Typography variant="body1" className="row">
        {t('oneApp.ProjectDetails.consentToRequestText')}
      </Typography>

      <Link href={t('oneApp.ProjectDetails.consentToRequestLink')} target="_blank">
        <LoadingButtonContainer
          color="primary"
          type="button"
          text={t('oneApp.ProjectDetails.consentToRequestLinkText')}
          classes="link"
          data-testid="consentToRequestLink"
        />
      </Link>
    </Grid>
  );
}
