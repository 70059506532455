import React, { useCallback, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';

import { ROOT, ONE_APP } from 'common/constants/routes';
import { generateOneAppPdf } from 'common/api/apiClient';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Box from '@material-ui/core/Box';
import { LOCAL_STORAGE_KEYS, MAPP_DL } from 'common/constants';
import {
  EnumAttachmentOneAppCompanySizeValues,
  EnumAttachmentOneAppConsentRequestValues,
  EnumAttachmentOneAppSubsidyValues,
  EnumDetailedOneAppProgramValues,
  EnumGbzaOneAppProgramValues,
  EnumSpecialProgramUBR,
} from 'types/AdditonalDoumentsEnum';
import { OneAppForPDF } from 'types/OneAppModels';
import { LoadingButtonContainer } from '../../components/LoadingButton';
import { updateMappDLSummaryPage, getViewportSize, dispatchMappDLEvent } from '../../components/MappDL';
import { programs } from './OneAppForm/Program/programLogic';

import { useOneAppContext } from './context/OneAppContext';

const useStyles = makeStyles((theme) =>
  createStyles({
    summaryContainer: {
      maxWidth: '1175px',
      padding: '3rem',
      margin: 'auto',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-end',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '50px 0 0 0',
      },
    },
    title: {
      marginBottom: '20px',
    },
    container: {
      padding: '1rem',
    },
    downloadSection: {
      marginTop: '1rem',
    },
    button: {
      margin: '1rem 0 1rem 0',
      minWidth: '145px',
    },
    list: {
      listStyleType: 'decimal',
      marginLeft: '1rem',
      '& li': {
        color: '#5a6166',
        fontWeight: 'bold',
        '&:not(:last-child)': {
          paddingBottom: '8px',
        },
      },
    },
    listBox: {
      border: 'none',
    },
    displaytogether: {
      display: 'flex',
      marginTop: '2%',
    },
    reducePadding: {
      padding: '-1%',
    },
    spacing: {
      marginRight: '0.5rem',
    },
    smallerlinespacing: {
      paddingBottom: '0.5rem',
    },
    linespacing: {
      paddingBottom: '1rem',
    },
    biggerLinespacing: {
      paddingBottom: '1.5rem',
    },
    listItemTitle: {
      fontWeight: 'bold',
    },
  }),
);

const { EVENTS } = MAPP_DL;

export default function Summary() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { request, setRequest, loading, setLoading } = useOneAppContext();

  const processedRequest: OneAppForPDF | undefined = useMemo(() => {
    if (request) {
      const programId = request?.program?.program;

      if (programId) {
        return {
          ...request,
          program: {
            ...request.program,
            programTitle: programs[programId]?.title,
            hasGbzaInfo: EnumGbzaOneAppProgramValues.includes(programId),
            hasAccumulationInfo: EnumAttachmentOneAppSubsidyValues.includes(programId),
            isDetailedProgram: EnumDetailedOneAppProgramValues.includes(programId),
            hasCompanySizeInfo: EnumAttachmentOneAppCompanySizeValues.includes(programId),
            hasConsentInfo: EnumAttachmentOneAppConsentRequestValues.includes(programId),
            hasSanctionsDocument: EnumSpecialProgramUBR.includes(programId),
          },
        };
      }

      return request;
    }
  }, [request]);

  useEffect(() => {
    if (request && request.requestId) {
      const viewportSize = getViewportSize();

      const mappDLSummaryPage = updateMappDLSummaryPage({
        pp5: `Viewport ${viewportSize}`,
        ep11: request.requestId,
      });

      window.mappDL = mappDLSummaryPage;
      dispatchMappDLEvent(EVENTS.MAPP_DL_READY);
    }

    // eslint-disable-next-line no-underscore-dangle
    if (!request || !request.id || !request.requestId) history.push(ONE_APP);
  }, [history, request]);

  useEffect(() => {
    return () => {
      setRequest(undefined);
    };
  }, [setRequest]);

  const nextStepCounter = ['1', '2', '3', '4', '5'];

  const handlePdfDownload = useCallback(async () => {
    try {
      setLoading(true);
      if (processedRequest) {
        const response = await generateOneAppPdf(processedRequest);
        let { fileName } = response;

        if (!fileName) {
          fileName = `KfW_Vorantrag_${processedRequest.requestId}.pdf`;
        }

        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, fileName);
      }
    } finally {
      setLoading(false);
    }
  }, [processedRequest, setLoading]);

  const redirectOneAppToAA = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.IMPORT_ONE_APP_REQUEST, 'true');
    history.push(ROOT);
  };

  return (
    <Grid container item xs={12} md={10} className={classes.summaryContainer}>
      <Grid container item xs={12} className={classes.container} role="heading">
        <Grid container item xs={12}>
          <Typography variant="h1" className={classes.title}>
            {t('oneApp.summaryScreen.title')}
          </Typography>
          <Typography variant="body1">{t('oneApp.summaryScreen.subtitle')}</Typography>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <LoadingButtonContainer
            color="primary"
            size="large"
            loading={loading}
            classes={classes.button}
            startIcon={<DescriptionRoundedIcon />}
            onClick={handlePdfDownload}
            data-testid="pdf_download"
            text={t('oneApp.summaryScreen.pdf_download')}
          />
        </Grid>
        <Grid item xs={12} sm={6} container justifyContent="center" direction="column">
          <Typography variant="body1">{t('oneApp.summaryScreen.code')}</Typography>
          <Typography variant="h5" data-testid="Vorantrags_code">
            {request?.requestId}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.container}>
        <Typography variant="h3">
          {t('oneApp.summaryScreen.nextsteps_title')} <br /> {t('oneApp.summaryScreen.nextsteps_subtitle')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.container}>
        <ol className={classes.list}>
          {nextStepCounter.map((step: string) => (
            <li key={step}>
              <Typography variant="body1" className={classes.listItemTitle}>
                {t(`oneApp.summaryScreen.nextsteps_header_${step}`)}
              </Typography>
              <Typography variant="body1">
                {t(`oneApp.summaryScreen.nextsteps_details_${step}`)}
                {step === '1' && (
                  <Link
                    href="https://www.kfw.de/inlandsfoerderung/Unternehmen/Online-Beratungsanfrage/index.html"
                    target="_blank"
                  >
                    {t(`oneApp.summaryScreen.nextsteps_link_${step}`)}
                  </Link>
                )}
              </Typography>
            </li>
          ))}
        </ol>
      </Grid>

      <Grid item xs={12} className={classes.displaytogether}>
        <Typography variant="h2">{t('oneApp.summaryScreen.save_pre_application_heading')}</Typography>
      </Grid>

      <Grid item xs={12} className={classes.displaytogether}>
        <Box bgcolor="#EDF3F7" color="info.contrastText" p={2}>
          <Grid item xs={12} className={classes.container}>
            <Typography variant="body1" className={classes.biggerLinespacing}>
              {t('oneApp.summaryScreen.redirect_to_save')}
            </Typography>
            <Typography variant="body1" className={classes.smallerlinespacing}>
              <CheckOutlinedIcon fontSize="small" className={classes.spacing} />
              {t('oneApp.summaryScreen.overview_financing_project')}
            </Typography>
            <Typography variant="body1" className={classes.linespacing}>
              <CheckOutlinedIcon fontSize="small" className={classes.spacing} />
              {t('oneApp.summaryScreen.coming_soon')}
            </Typography>

            <LoadingButtonContainer
              color="primary"
              size="large"
              classes={classes.button}
              startIcon={<ArrowForwardIosIcon fontSize="small" />}
              onClick={redirectOneAppToAA}
              data-testid="redirect-to-aa"
              text={t('oneApp.summaryScreen.save_pre_application')}
            />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
