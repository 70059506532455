import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useIKKContext } from '../../context/IKKContext';

interface Props {
  type: string;
  headerElement?: React.ReactNode;
  footerElement?: React.ReactNode;
  visible?: boolean;
  onSelect: Function;
  itemAssembler?: Function;
}

const useStyles = makeStyles((theme) => ({
  historyWrapper: {
    position: 'absolute',
    top: '52px',
    left: 0,
    zIndex: 1300,
    minWidth: '100px',
    width: '83.333333%',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    fontSize: '15px',
    lineHeight: '1.47',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    '& li button': {
      wordBreak: 'break-word',
      cursor: 'pointer',
      padding: '6px 16px 6px 24px',
      width: '100%',
      textAlign: 'left',
    },
    '& li:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

// We can't use MUI Autocomplete with our custom components, so this is a custom new component.
/**
type: "date" or "address"
headerElement: will be added in the beginning of this popup
footerElement: will be added in the end of this popup
visible: sets visibility
onSelect: onSelect(evt, item) handler
itemAssembler: function(item, index) which will assemble item elements from history data
*/
const History = ({ type, headerElement, footerElement, visible, onSelect, itemAssembler }: Props) => {
  const classes = useStyles();
  const { getInputHistory } = useIKKContext();

  if (!visible) {
    return <></>;
  }

  const items: Array<any> = getInputHistory(type);

  return (
    <div className={classes.historyWrapper}>
      {headerElement}
      <ul>
        {items.map((item, index) => (
          <li key={item}>
            <button
              type="button"
              onClick={(evt) => {
                onSelect(evt, item);
              }}
            >
              {itemAssembler ? itemAssembler(item, index) : item}
            </button>
          </li>
        ))}
      </ul>
      {footerElement}
    </div>
  );
};

export default React.memo(History);
