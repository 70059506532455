import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm, useField } from 'react-final-form';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { MAPP_DL } from 'common/constants';
import naceList from 'types/Branche_NACE_2008';
import { dispatchMappDLEvent } from 'components/MappDL';
import FormSwitch from '../../components/form/FormSwitch';
import FormAutocomplete from '../../components/form/FormAutocomplete';

const { EVENTS } = MAPP_DL;

export default function AdditonalProjectInformation() {
  const { t } = useTranslation();
  const [specificNaceList, setSpecificNaceList] = useState(naceList);
  const [specificNaceDisabled, setSpecificNaceDisabled] = useState(true);
  const form = useForm();
  const projectNaceIsDifferent = useField('project.projectNaceIsDifferent');
  const projectNaceIsDifferentValue = projectNaceIsDifferent.input.value;

  useEffect(() => {
    dispatchMappDLEvent(EVENTS.MAPP_INIT_FORM_UPDATE);
  }, [projectNaceIsDifferentValue]);

  const handleProjectNaceIsDifferentChange = (value: boolean) => {
    if (!value) {
      form.batch(() => {
        form.change('project.projectNace', null);
        form.change('project.industrySector', null);
      });
    }
  };

  const handleNaceChange = useCallback(
    (value: { id: number; title: string }) => {
      if (value !== null) {
        setSpecificNaceList(naceList.filter((entry) => entry.id > value.id && entry.id < value.id + 1000));
        setSpecificNaceDisabled(false);
        form.change('project.projectNace', null);
      } else {
        setSpecificNaceDisabled(true);
        form.change('project.projectNace', null);
      }
    },
    [form],
  );

  return (
    <Grid container className="collapsibleFormSubSection" data-testid="additonalProjectInformation">
      <Grid container item xs={12} className="formSwitchSection">
        <FormSwitch
          name="project.projectNaceIsDifferent"
          label={t('oneApp.ProjectDetails.projectNaceIsDifferent')}
          tooltip={t('oneApp.ProjectDetails.projectNaceIsDifferentTooltip')}
          onChange={handleProjectNaceIsDifferentChange}
        />
      </Grid>
      <Field name="project.projectNaceIsDifferent" subscription={{ value: true }}>
        {({ input }) => {
          if (input.value) {
            return (
              <Grid container item className="collapsibleFormSubSection">
                <Typography variant="h3">{t('oneApp.ProjectDetails.additionalInformationTitle')}</Typography>
                <Grid container item xs={12} className="formGroup">
                  <Grid item xs={12} md={6}>
                    <FormAutocomplete
                      onChange={handleNaceChange}
                      name="project.industrySector"
                      label={t('oneApp.ProjectDetails.industrySector')}
                      data={naceList.filter((e) => e.id % 1000 === 0)}
                      data-testid="industrySector-autocomplete"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormAutocomplete
                      name="project.projectNace"
                      label={t('oneApp.ProjectDetails.projectNace')}
                      data={specificNaceList}
                      disabled={specificNaceDisabled}
                      data-testid="projectNace-autocomplete"
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          }
        }}
      </Field>
    </Grid>
  );
}
