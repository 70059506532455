import React, { useState } from 'react';
import classnames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import warningLogoSvg from '../../../../assets/img/kfw-exclamation-mark.svg';

interface Props {
  id: string;
  className?: string;
  accordionSummaryContent: React.ReactNode;
  accordionDetailContent?: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  warning: {
    padding: '6px 16px',
    color: 'rgb(102, 60, 0)',
    backgroundColor: 'rgb(255, 244, 229)',
    border: 'none',
    boxShadow: 'none',
    '&.MuiAccordion-root:before': {
      backgroundColor: 'transparent',
    },

    '.content': {
      backgroundColor: 'white',
    },

    '& .MuiAccordionSummary-expandIcon': {
      transform: 'none',
    },

    '& img': {
      height: '2.4rem',
      width: 'auto',
      marginRight: '16px',
    },
  },
}));

export default function WarningAccordionBox({ id, className, accordionSummaryContent, accordionDetailContent }: Props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const onChange = () => setExpanded((expanded) => !expanded);

  return (
    <Accordion id={id} className={classnames(classes.warning, className)} expanded={expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={expanded ? <IndeterminateCheckBoxOutlinedIcon /> : <AddBoxOutlinedIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
      >
        <Grid container item wrap="nowrap" alignItems="flex-start" className="content">
          <img src={warningLogoSvg} alt="Warning-Icon" />
          <Typography>{accordionSummaryContent}</Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{accordionDetailContent}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
