import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useLoginContext } from 'common/context/loginContext';
import SectionHeader from './SectionHeader';

interface ExpandibleInfoPanelProps {
  defaultExpanded?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '1rem',
    },
    panel: {
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
    },
    header: {
      padding: '0',
    },
    row: {
      display: 'inherit',
      marginTop: '20px',
      width: '100%',
    },
    list: {
      listStyleType: 'decimal',
      marginLeft: '1rem',
      '& li': {
        color: '#5a6166',
        fontWeight: 'bold',
        '&:not(:last-child)': {
          paddingBottom: '8px',
        },
      },
    },
  }),
);

export const ExpandibleInfoPanel = (props: ExpandibleInfoPanelProps) => {
  const classes = useStyles();
  const { defaultExpanded } = props;
  const { t } = useTranslation();
  const { user } = useLoginContext();
  const hasName = user?.name && user?.name !== 'unknown';
  const nextStepCounter = ['1', '2', '3', '4', '5'];

  return (
    <Accordion className={classes.panel} defaultExpanded={defaultExpanded}>
      <AccordionSummary
        className={classes.header}
        expandIcon={
          <Typography variant="h3">
            <span className="icArrowsDownExpandIcon" />
          </Typography>
        }
        aria-controls="panel-content"
        id="panel-header"
        data-testid="informationTitle"
      >
        {hasName ? (
          <SectionHeader
            icon={<AssignmentIcon />}
            title={t('creditRequestList.informationTitle_name', { name: user?.name })}
          />
        ) : (
          <SectionHeader icon={<AssignmentIcon />} title={t('creditRequestList.informationTitle')} />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12} className={classes.container}>
            <Typography variant="h3">{t('summaryScreen.nextsteps_title')}</Typography>
            <Typography variant="h3">{t('summaryScreen.nextsteps_subtitle')}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <ul className={classes.list}>
              {nextStepCounter &&
                nextStepCounter.map((step: string) => (
                  <li key={step}>
                    <Typography variant="h5">{t(`summaryScreen.nextsteps_header_${step}`)}</Typography>
                    <Typography>{t(`summaryScreen.nextsteps_details_${step}`)}</Typography>
                  </li>
                ))}
            </ul>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
