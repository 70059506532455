import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { validators } from '../formValidation';

import FormNumberInput from '../../components/form/FormNumberInput';
import FormDatePicker from '../../components/form/FormDatePicker';

export default function Population() {
  const { t } = useTranslation();

  return (
    <Grid container item>
      <Typography variant="body1">{t('ikk.ApplicantSelection.CommunityAssociationFinancials.subtitle2')}</Typography>
      <Grid container item xs={12} justifyContent="center" className="subSubSection">
        <Grid item xs={12} md={6}>
          <FormNumberInput
            name="requestInformation.population"
            className="formItem"
            required
            thousandSeparators
            decimalScale={0}
            integerScale={9}
            decimalSeparator={false}
            label={t('ikk.ApplicantSelection.Population.population')}
            data-testid="population"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormDatePicker
            name="requestInformation.timeOfPopulation"
            className="formItem"
            label={t('ikk.ApplicantSelection.Population.timeOfPopulation')}
            data-testid="timeOfPopulation"
            validate={validators.applicant.dateInPast}
            errorMsg={t('validation.invalidPopulationDate')}
            required
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
