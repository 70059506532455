/* eslint-disable no-plusplus */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormNumberInput from '../../components/form/FormNumberInput';
import FormTextField from '../../components/form/FormTextField';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  hidden: {
    display: 'none',
  },
  inline: {
    display: 'inline-block',
  },
  investWrapperTable: {
    width: '100%',
    '& th': {
      textAlign: 'left',
      verticalAlign: 'middle',
      padding: '1.2em 1.5em 1.2em .5em',
    },
    '& td': {
      padding: '.3em',
      width: '45%',
      verticalAlign: 'middle',
    },
  },
  infoIcon: {
    fontFamily: 'kfw-icons',
    fontWeight: 'bold',
    fontSize: '80%',
    borderRadius: '50%',
    width: '1.5em !important',
    height: '1.5em !important',
    padding: '0 0.25em',
    marginLeft: '-2em',
    marginRight: '.5em',
    color: '#ffffff',
    display: 'inline-block',
    '&.error': {
      backgroundColor: '#ca0036',
    },
    '&.passed': {
      backgroundColor: '#60800f',
    },
  },
  error: {
    color: '#ca0036',
  },
  passed: {
    color: '#60800f',
  },
  InvestPlan_infoIconWrp: {
    display: 'none',
    position: 'relative',
    '&.visible': {
      display: 'inline-block',
      '&:hover .InvestPlan_tooltip': {
        display: 'block',
      },
    },
    '& .InvestPlan_tooltip': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
      backgroundColor: '#f6f3f0',
      margin: '8px',
      padding: '8px',
      maxWidth: '450px',
      minWidth: '200px',
      display: 'none',
      position: 'absolute',
      zIndex: '255',
      right: '0',
    },
  },
}));

export default function FinancingPlan({
  productType,
  selectedProduct,
  financingCreditAmountShadowField,
  financingElementId,
  creditAmountMainField,
  validate,
}: any) {
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useForm();

  const categoryKeyLookup: { [key: string]: string } = t('ikk.FindProduct.categories.categoryKeyLookup', {
    returnObjects: true,
  });

  const defaultValue = form.getFieldState(creditAmountMainField)?.value || '';

  const financingList: { id: string; title: string }[] =
    selectedProduct !== 432
      ? t('ikk.InvestPlan.financingList', {
          returnObjects: true,
        })
      : t('ikk.InvestPlan.financingList432', {
          returnObjects: true,
        });

  const handleSumDiff = () => {
    const sum1 = parseFloat(form.getFieldState('investPlan.sum')?.value || '0');
    const sum2 = parseFloat(form.getFieldState(`${financingElementId}.sum`)?.value || '0');
    if (sum1 === sum2) {
      // Displaying/hiding icons this way so that we bypass state change - it's faster and state is not needed here
      document.getElementById(`${financingElementId}.sum.passed`)?.classList.add('visible');
      document.getElementById(`${financingElementId}.sum.error`)?.classList.remove('visible');
    } else {
      document.getElementById(`${financingElementId}.sum.passed`)?.classList.remove('visible');
      document.getElementById(`${financingElementId}.sum.error`)?.classList.add('visible');
    }
  };

  const handleFinancingSumChange = function (value: any, id: string | null) {
    let sum: number = 0;
    for (let i = 0; i < financingList.length; i++) {
      // eslint-disable-next-line eqeqeq
      if (financingList[i].id === id) {
        sum += parseFloat(value || 0);
      } else {
        sum += parseFloat(form.getFieldState(`financingPlan.amount.${financingList[i].id}`)?.value || '0');
      }
    }
    if (categoryKeyLookup[productType] === categoryKeyLookup.credit) {
      sum += parseFloat(form.getFieldState(financingCreditAmountShadowField)?.value || '0');
    }
    form.change(`${financingElementId}.sum`, sum);
    handleSumDiff();
  };

  const showSectionOtherSources = () => {
    return (
      selectedProduct === 432 && form.getFieldState('financingPlan.amount.sonstige_oeffentliche_mittel')?.value > 0
    );
  };

  return (
    <Grid container className="formSection">
      <Typography variant="h3" className={`${classes.fullWidth} header`}>
        {t('ikk.InvestPlan.financingPlan')}
      </Typography>
      <Grid item xs={12} md={12}>
        <table className={classes.investWrapperTable}>
          <thead>
            <tr>
              <th>{t('ikk.InvestPlan.financeType')}</th>
              <th>{t('ikk.InvestPlan.financingAmount')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>{t('ikk.InvestPlan.applicantCredit')}</th>
              <td>
                <FormNumberInput
                  name={financingCreditAmountShadowField}
                  label={t('ikk.InvestPlan.creditAmount')}
                  thousandSeparators
                  decimalScale={2}
                  integerScale={13}
                  data-testid="financingPlanCredit"
                  isInline
                  disabled
                  defaultValue={defaultValue}
                  onChange={(evt: any) => {
                    handleFinancingSumChange(null, null);
                  }}
                />
                {t('ikk.common.eur')}
              </td>
            </tr>
            {Array.from(Array(financingList.length).keys()).map((index: number) => (
              <tr key={index}>
                <td>{financingList[index].title}</td>
                <td>
                  <FormNumberInput
                    name={`financingPlan.amount.${financingList[index].id}`}
                    label={t('ikk.InvestPlan.financingAmount')}
                    thousandSeparators
                    decimalScale={2}
                    integerScale={13}
                    isInline
                    validate={validate}
                    data-testid={`financingPlanAmt.${financingList[index].id}`}
                    onChange={(evt: any) => {
                      handleFinancingSumChange(evt.target.value, financingList[index].id);
                    }}
                  />
                  {t('ikk.common.eur')}
                </td>
              </tr>
            ))}
            <tr>
              <th>{t('ikk.InvestPlan.financingSum')}</th>
              <td>
                {/* Tooltip is done this old-school way here because our version of MUI SvgIcon doesn't support success icon. */}
                <div
                  id={`${financingElementId}.sum.passed`}
                  className={`${classes.InvestPlan_infoIconWrp} ${classes.passed}`}
                >
                  <div className={`${classes.infoIcon} passed`}>&#59658;</div>
                  <div className="InvestPlan_tooltip">{t('ikk.InvestPlan.sumsEqual')}</div>
                </div>
                <div
                  id={`${financingElementId}.sum.error`}
                  className={`${classes.InvestPlan_infoIconWrp} ${classes.error}`}
                >
                  <div className={`${classes.infoIcon} error`}>&#59693;</div>
                  <div className="InvestPlan_tooltip">{t('ikk.InvestPlan.sumsDifferentErr')}</div>
                </div>
                <FormNumberInput
                  name={`${financingElementId}.sum`}
                  label={t('ikk.InvestPlan.sum')}
                  thousandSeparators
                  decimalScale={2}
                  integerScale={13}
                  data-testid="financingPlanSum"
                  isInline
                  disabled
                />
                {t('ikk.common.eur')}
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
      {showSectionOtherSources() && (
        <Grid container>
          <Typography variant="h3" className={`${classes.fullWidth} header`}>
            {t('ikk.InvestPlan.otherSourcesHeading')}
          </Typography>
          <Grid container item xs={12} className="formGroup">
            <Grid item xs={12} md={8}>
              <FormTextField
                name="financingPlan.nameOtherSource"
                className="formItem"
                label={t('ikk.InvestPlan.nameOtherSource')}
                data-testid="nameOtherSource"
                maxLength={500}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
