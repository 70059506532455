import React from 'react';
import classnames from 'classnames';
import { Field } from 'react-final-form';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const FormCheckbox = ({
  label,
  className,
  containerClassname,
  validate,
  'data-testid': dataTestId,
  errorMsg,
  ...props
}: any) => {
  const StyledIcon = styled('span')(() => ({
    borderRadius: '2px',
    width: '2.8rem',
    height: '2.8rem',
    boxShadow: '0 0 0 1px #5a6166',

    'input:hover ~ &': {
      boxShadow: '0 0 0 2px #00446e',
    },
    'input:focus-visible ~ &': {
      boxShadow: '0 0 0 1px #5a6166',
      outline: '2px dashed #00446e',
      outlineOffset: '4px',
      // outline: ""
    },
    '&:error': {
      boxShadow: '0 0 0 2px #ca0036',
    },
  }));

  const StyledCheckedIcon = styled(StyledIcon)({
    backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23005A8C' fill-rule='evenodd' d='M20.854 5.852l-.706-.706a.5.5 0 0 0-.706 0L9.509 15.385l-4.95-5.256a.5.5 0 0 0-.707 0l-.706.706a.5.5 0 0 0 0 .706l6.01 6.313a.501.501 0 0 0 .706 0L20.854 6.56a.5.5 0 0 0 0-.708' style='fill: %23005a8c;'/%3e%3c/svg%3e ");`,
    backgroundSize: '2.4rem 2.4rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '2.8rem',
    width: '2.8rem',
    boxShadow: '0 0 0 1px #5a6166',
  });

  return (
    <Field
      {...props}
      validate={validate}
      type="checkbox"
      subscription={{ value: true, modified: true, error: true, submitFailed: true }}
      render={({ input, meta }) => {
        return (
          <Grid container className={containerClassname}>
            <FormControlLabel
              className={classnames(className, { error: meta.error && (meta.modified || meta.submitFailed) })}
              control={
                <Checkbox
                  {...props}
                  {...input}
                  data-testid={dataTestId}
                  inputProps={{
                    'aria-labelledby': dataTestId,
                  }}
                  checkedIcon={<StyledCheckedIcon />}
                  icon={<StyledIcon />}
                />
              }
              label={<Typography id={dataTestId}>{props.boldLabel ? <b>{label}</b> : label}</Typography>}
            />
            {errorMsg && meta.submitFailed && meta.error && (
              <Grid container>
                <Typography variant="body1" className="errorMessage">
                  {errorMsg}
                </Typography>
              </Grid>
            )}
          </Grid>
        );
      }}
    />
  );
};

export default React.memo(FormCheckbox);
