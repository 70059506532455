import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

import WarningBox from 'pages/oneApp/components/common/WarningBox';
import Fields from 'pages/oneApp/components/form/Fields';
import FormNumberInput from '../../components/form/FormNumberInput';
import FormDatePicker from '../../components/form/FormDatePicker';
import { getCompanySize, CompanySize as CompanySizeEnum, isCreditAmountValid } from './businessLogic/company';

import CalculateEmployeesModal from '../../components/modal/CalculateEmployeesModal';

const useStyles = makeStyles(() => ({
  alignParallel: {
    display: 'inline-flex',
  },
  click: {
    color: '#1b618c !important',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  list: {
    marginLeft: '2rem',
    listStyleType: 'disc',
  },
  creditAmountWarning: {
    margin: '10px 0 20px 0',
  },
}));
export default function CompanySize() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [numberOfEmployees, setNumberOfEmployees] = useState(NaN);
  const [annualSales, setAnnualSales] = useState(NaN);
  const [totalAssets, setTotalAssets] = useState(NaN);
  const [classification, setClassification] = useState<undefined | string>(undefined);
  const [openCalculateEmployeesModal, setOpenCalculateEmployeesModal] = useState<boolean>(false);

  const companySizeStrings = {
    [CompanySizeEnum.KLU]: t('oneApp.CompanySize.microEnterprise'),
    [CompanySizeEnum.KU]: t('oneApp.CompanySize.smallCompany'),
    [CompanySizeEnum.SME]: t('oneApp.CompanySize.mediumSizedCompany'),
    [CompanySizeEnum.GU]: t('oneApp.CompanySize.largeCompany'),
  } as { [key: number]: string };

  const creditAmountDetailArrWarning: string[] = t('oneApp.CompanySize.infoBox.creditAmountDetailArrWarning', {
    returnObjects: true,
  });

  useEffect(() => {
    if (numberOfEmployees > 0 && annualSales > 0 && totalAssets > 0) {
      const companySize = getCompanySize(numberOfEmployees, annualSales, totalAssets);
      setClassification(companySizeStrings[companySize]);
    } else {
      setClassification(undefined);
    }
  }, [numberOfEmployees, annualSales, totalAssets, companySizeStrings]);

  const handleChange = {
    numberOfEmployees: useCallback((e) => {
      setNumberOfEmployees(e.target.value);
    }, []),
    annualSales: useCallback((e) => {
      setAnnualSales(e.target.value);
    }, []),
    totalAssets: useCallback((e) => {
      setTotalAssets(e.target.value);
    }, []),
  };

  const toggleEmployeeModal = () => {
    setOpenCalculateEmployeesModal((openCalculateEmployeesModal) => !openCalculateEmployeesModal);
  };

  /**
   * @todo Check <Trans> component on security check. Should be fine, we don't use users data (database).
   */
  return (
    <Grid container item className="formSubSection" data-testid="nonProfit">
      <Typography variant="h3" className="subheader">
        {t('oneApp.CompanySize.title')}
      </Typography>
      <Typography variant="body1">
        <Trans i18nKey="oneApp.CompanySize.description">
          Die folgenden Angaben sind für die Größeneinstufung Ihres Unternehmens notwendig. Sie bilden die Basis, um Ihr
          Unternehmen einer der vier möglichen Größenklassen (Kleinstunternehmen, Kleines Unternehmen, Mittleres
          Unternehmen oder Großes Unternehmen) zuzuordnen. Die Einstufung kann u.a. auch Einfluss auf Ihre
          Zinskonditionen haben. Weiterführende Informationen finden Sie im
          <Link href="https://www.kfw.de/Download-Center/Förderprogramme-(Inlandsförderung)/PDF-Dokumente/6000000196-KMU-Definition.pdf">
            Merkblatt KMU-Definition
          </Link>
          .
        </Trans>
      </Typography>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <div className={classes.alignParallel}>
            <FormNumberInput
              className="formItem"
              name="applicant.companyNumberOfEmployees"
              data-testid="companyNumberOfEmployees"
              thousandSeparators
              integerScale={9}
              label={t('oneApp.CompanySize.numberOfEmployeesLabel')}
              tooltip={t('oneApp.CompanySize.numberOfEmployeesTooltip')}
              onChange={handleChange.numberOfEmployees}
            />

            <Button onClick={toggleEmployeeModal} className={classes.click}>
              {t('oneApp.CompanySize.calculateNumberOfEmployees')}
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormNumberInput
            className="formItem"
            name="applicant.companyAnnualSales"
            data-testid="companyAnnualSales"
            thousandSeparators
            integerScale={13}
            decimalScale={2}
            label={t('oneApp.CompanySize.annualSalesLabel')}
            tooltip={t('oneApp.CompanySize.annualSalesTooltip')}
            onChange={handleChange.annualSales}
          />
        </Grid>
      </Grid>
      <Fields
        names={['applicant.companyNumberOfEmployees', 'program.program', 'program.creditAmount']}
        subscription={{ value: true }}
      >
        {(fieldsState: any) => {
          const employeesValue = fieldsState['applicant.companyNumberOfEmployees']?.input?.value;
          const programValue = fieldsState['program.program']?.input?.value;
          const creditAmountValue = fieldsState['program.creditAmount']?.input?.value;

          if (!isCreditAmountValid(employeesValue, programValue, creditAmountValue)) {
            return (
              <WarningBox
                id="creditAmountWarning"
                className={classes.creditAmountWarning}
                accordionSummaryContent={t('oneApp.CompanySize.infoBox.creditAmountSummaryWarning')}
                accordionDetailContent={
                  <ul className={classes.list}>
                    {creditAmountDetailArrWarning.map((detail: string) => (
                      <li>{detail}</li>
                    ))}
                  </ul>
                }
              />
            );
          }

          return null;
        }}
      </Fields>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormNumberInput
            className="formItem"
            name="applicant.companyTotalAssets"
            data-testid="companyTotalAssets"
            thousandSeparators
            integerScale={13}
            decimalScale={2}
            label={t('oneApp.CompanySize.totalAssetsLabel')}
            tooltip={t('oneApp.CompanySize.totalAssetsTooltip')}
            onChange={handleChange.totalAssets}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormDatePicker
            className="formItem"
            name="applicant.companyInfoValidDate"
            data-testid="companyValidDate-date"
            label={t('oneApp.CompanySize.InfoValidDateLabel')}
            tooltip={t('oneApp.CompanySize.InfoValidDateTooltip')}
          />
        </Grid>
      </Grid>
      {classification && (
        <>
          <Typography variant="body1">
            <Trans i18nKey="oneApp.CompanySize.companyIsClassified">
              Gemäß Ihren Angaben, wird Ihr Unternehmen als: <strong>{{ classification }}</strong> eingestuft.
            </Trans>
          </Typography>
          <Typography variant="body1">
            <Trans i18nKey="oneApp.CompanySize.companyIsClassifiedDescription">
              Sofern diese Angaben zu Ihrem Unternehmen Schwankungen unterliegen, die zu wechselnden Größeneinstufungen
              (Details finden
              <Link href="https://www.kfw.de/Download-Center/Förderprogramme-(Inlandsförderung)/PDF-Dokumente/6000000196-KMU-Definition.pdf">
                Sie hier
              </Link>
              ) führen, sollten Sie sich von Ihren Bank- oder Sparkassenberater gezielt auf diesen Umstand hinweisen.
            </Trans>
          </Typography>
        </>
      )}
      {openCalculateEmployeesModal && (
        <CalculateEmployeesModal
          open={openCalculateEmployeesModal}
          onConfirm={toggleEmployeeModal}
          onCancel={toggleEmployeeModal}
        />
      )}
    </Grid>
  );
}
