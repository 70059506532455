import React from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmationModal from '../ConfirmationModal';

interface Props {
  requestIdentifier?: string;
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
}

const DeleteOneAppCreditRequest = ({ requestIdentifier, open, onConfirm, onCancel }: Props) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      id="delete-one-app-request-modal"
      content={t('creditRequestList.modals.deleteOneAppRequest.content', {
        requestIdentifier,
      })}
      open={open}
      header={t('creditRequestList.modals.deleteOneAppRequest.header')}
      confirmButton={t('creditRequestList.modals.deleteOneAppRequest.confirm')}
      onConfirm={onConfirm}
      onCancel={onCancel}
      cancelButton={t('creditRequestList.modals.deleteOneAppRequest.cancel')}
    />
  );
};

export default DeleteOneAppCreditRequest;
