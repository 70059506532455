import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FormTextField from '../../components/form/FormTextField';
import FormSelect from '../../components/form/FormSelect';

const INPUT_PROPS = {
  firstName: {
    maxLength: 35,
  },
  lastName: {
    maxLength: 35,
  },
  company: {
    maxLength: 35,
  },
  legal: {
    maxLength: 35,
  },
};

export default function SingleEntrepreneur() {
  const { t } = useTranslation();

  const adressingLookup: { [key: string]: string } = t('oneApp.SingleEntrepreneur.adressingLookup', {
    returnObjects: true,
  });

  const academicTitleLookup: { [key: string]: string } = t('oneApp.SingleEntrepreneur.academicTitleLookup', {
    returnObjects: true,
  });

  return (
    <Grid container item className="collapsibleFormSubSection" data-testid="singleEntrepreneur">
      <Typography variant="h3" className="subheader">
        {t('oneApp.SingleEntrepreneur.title')}
      </Typography>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormSelect
            name="applicant.addressing"
            autoComplete="honorific-prefix"
            className="formItem"
            label={t('oneApp.SingleEntrepreneur.adressing')}
            options={adressingLookup}
            labelWidth={60}
            data-testid="adressing-select"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormSelect
            name="applicant.academicTitle"
            autoComplete="honorific-sufix"
            className="formItem"
            label={t('oneApp.SingleEntrepreneur.academicTitle')}
            options={academicTitleLookup}
            labelWidth={140}
            data-testid="academicTitle-select"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.firstName"
            autoComplete="given-name"
            className="formItem"
            required
            label={t('oneApp.SingleEntrepreneur.firstName')}
            maxLength={INPUT_PROPS.firstName.maxLength}
            data-testid="firstName"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.lastName"
            autoComplete="family-name"
            className="formItem"
            required
            label={t('oneApp.SingleEntrepreneur.lastName')}
            maxLength={INPUT_PROPS.lastName.maxLength}
            data-testid="lastName"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.company"
            autoComplete="organization"
            className="formItem"
            label={t('oneApp.SingleEntrepreneur.company')}
            maxLength={INPUT_PROPS.company.maxLength}
            data-testid="company"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
