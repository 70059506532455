import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { POST_CODE_PATTERN, HOUSE_NUMBER_PATTERN, ADDRESS_PROPS } from '../../constants/index';
import FormTextField from '../../components/form/FormTextField';

export default function Address() {
  const { t } = useTranslation();

  return (
    <Grid container item className="formSubSection" data-testid="address">
      <Typography variant="h3" className="subheader">
        {t('ikk.ApplicantSelection.Address.title')}
      </Typography>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.street"
            autoComplete="street-address"
            className="formItem"
            required
            label={t('ikk.common.Address.street')}
            maxLength={ADDRESS_PROPS.street.maxLength}
            data-testid="street"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.houseNumber"
            className="formItem"
            required
            label={t('ikk.common.Address.houseNumber')}
            pattern={HOUSE_NUMBER_PATTERN}
            patternErrMsg="validation.invalidHouseNumber"
            data-testid="houseNumber"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.postcode"
            autoComplete="postal-code"
            className="formItem"
            required
            label={t('ikk.common.Address.postcode')}
            pattern={POST_CODE_PATTERN}
            patternErrMsg="validation.invalidPostCodeFormat"
            maxLength={ADDRESS_PROPS.postcode.maxLength}
            data-testid="postCode"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.location"
            autoComplete="address-level2"
            className="formItem"
            required
            label={t('ikk.common.Address.location')}
            maxLength={ADDRESS_PROPS.location.maxLength}
            data-testid="location"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
