import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

interface LoadingButtonProps {
  color: 'primary' | 'secondary';
  text: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  'data-testid'?: string;
  endIcon?: JSX.Element;
  startIcon?: JSX.Element;
  classes?: string;
  size?: 'large' | 'medium' | 'small';
  type?: 'button' | 'submit' | 'reset';
}

export function LoadingButtonContainer({
  classes,
  startIcon,
  endIcon,
  color,
  text,
  disabled = false,
  loading,
  size,
  onClick,
  type,
  'data-testid': dataTestId,
}: LoadingButtonProps) {
  return (
    <Button
      type={type}
      disabled={loading || disabled}
      color={color}
      variant="contained"
      className={classes}
      onClick={onClick}
      size={size}
      data-testid={dataTestId}
      endIcon={!loading && endIcon}
      startIcon={!loading && startIcon}
    >
      {loading ? <CircularProgress disableShrink size={20} /> : text}
    </Button>
  );
}
