import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { useField } from 'react-final-form';
import InformationOnImplementationDuration from './InformationOnImplementationDuration';
import InformationDistrictConcept from './InformationDistrictConcept';
import RemediationManagement from './RemediationManagement';
import ChargingStation from './ChargingStation';

export default function ProductSpecificInputs({ productDetails }: any) {
  const selectedPurposes: any = [];
  // Unable to use productDetails.fundingPurposes.length here because of a React bug.
  for (let i = 0; i < 14; i += 1) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const amount = useField(`projectPurpose.amounts.amtRequest${i}`)?.input.value;
    if (amount) selectedPurposes.push({ id: i, title: productDetails.fundingPurposes[i].intendedUse });
  }

  const { t } = useTranslation();
  const [displayedExistingConcepts, setDisplayedExistingConcepts] = useState<any>(false);
  const [displayImplementationDuration, setDisplayImplementationDuration] = useState<any>(false);
  const [displayedRenovationConcept, setDisplayedRenovationConcept] = useState<any>(false);

  const remediationManagementPurposes = [1, 3];
  const shouldDisplayRemediationManagement = () => {
    return (
      // eslint-disable-next-line eqeqeq
      remediationManagementPurposes.some((i: any) => selectedPurposes.some((p: any) => p.id == i)) &&
      displayedRenovationConcept
    );
  };
  const [displayChargingStation, setDisplayChargingStation] = useState<any>(false);

  useEffect(() => {
    (async () => {
      if (productDetails) {
        try {
          const i = productDetails;
          if (i && i.ikkDisplayedComponents) {
            setDisplayedExistingConcepts(i?.ikkDisplayedComponents?.includes('productSpecific_existingConcepts'));
            setDisplayChargingStation(i?.ikkDisplayedComponents?.includes('productSpecific_chargingStations'));
            setDisplayImplementationDuration(i?.ikkDisplayedComponents?.includes('productSpecific_implementationTime'));
            setDisplayedRenovationConcept(i?.ikkDisplayedComponents?.includes('productSpecific_renovationConcept'));
          }
        } catch (err) {
          return null;
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails]);

  if (!productDetails) {
    return <></>;
  }

  return (
    <Grid>
      {(displayedExistingConcepts || displayChargingStation || displayImplementationDuration) && (
        <Grid container className="formSection">
          <Typography variant="h2" className="header">
            {t('ikk.ProductSpecificData.infoText1')}
          </Typography>
          {displayImplementationDuration && selectedPurposes.length > 0 && (
            <InformationOnImplementationDuration selectedPurposes={selectedPurposes} />
          )}
          {displayChargingStation && <ChargingStation />}
          {displayedExistingConcepts && <InformationDistrictConcept />}
          {shouldDisplayRemediationManagement() && <RemediationManagement />}
        </Grid>
      )}
    </Grid>
  );
}
