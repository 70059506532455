import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ATTACHMENT_TYPE } from '../../constants/index';
import Attachment from '../../components/common/Attachment';

export default function Attachments({ productDetails }: any) {
  const { t } = useTranslation();

  if (!productDetails || !productDetails.ikkAttachments) {
    return <></>;
  }

  return (
    <Grid container className="formSection">
      <Typography variant="h2" className="header">
        {t('ikk.Attachments.title')}
      </Typography>
      <Typography variant="h2" className="header" />
      <Typography variant="body1">{t('ikk.Attachments.infoText1')}</Typography>
      {productDetails.ikkAttachments.map((item: any, index: number) => (
        <Attachment
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          title={item.title}
          category={ATTACHMENT_TYPE[item.category as keyof typeof ATTACHMENT_TYPE]}
          description={item.description}
          link={item.link}
          formNumber={item.formNumber}
        />
      ))}
    </Grid>
  );
}
