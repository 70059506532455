import React, { useCallback } from 'react';
import { Field } from 'react-final-form';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ToolTipIcon from 'components/fields/ToolTipIcon';
import { isEmpty } from 'util/formUtils';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
}));

const FormSelect = ({
  name,
  value,
  options,
  label,
  labelWidth,
  tooltip,
  validate,
  required,
  onChange,
  preventDefaultOnChange,
  ...props
}: any) => {
  const classes = useStyles();

  const validateHandler = useCallback(
    (value, allValues, meta) => {
      let hasError;

      if (required) {
        hasError = isEmpty(value);
        if (hasError) return hasError;
      }

      if (validate) return validate(value, allValues, meta);
    },
    [required, validate],
  );

  return (
    <Field
      {...props}
      validate={validateHandler}
      name={name}
      subscription={{ value: true, modified: true, error: true, submitFailed: true }}
      render={({ input, meta }) => {
        const error = meta.error && (meta.modified || meta.submitFailed);
        const onChangeHandler = (e: React.ChangeEvent<{}>, value: React.ReactElement) => {
          if (onChange) onChange(value.props.value);
          const id = value.props.value;
          if (!preventDefaultOnChange) input.onChange({ ...e, target: { ...e.target, id } });
        };
        return (
          <Grid container>
            <Grid container item xs={10}>
              <FormControl variant="outlined" className={classes.formControl} error={error}>
                <InputLabel id={`${name}-label`} required={required}>
                  {label}
                </InputLabel>
                <Select
                  {...props}
                  {...input}
                  error={error}
                  onChange={onChangeHandler}
                  labelWidth={labelWidth}
                  id={`${name}-label`}
                  labelId={`${name}-label`}
                  MenuProps={{ disablePortal: true }}
                >
                  {Object.keys(options).map((o) => {
                    return (
                      <MenuItem key={o} value={o}>
                        {options[o]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {tooltip && (
              <Grid item xs={1} className="tooltip">
                <ToolTipIcon tip={tooltip} />
              </Grid>
            )}
          </Grid>
        );
      }}
    />
  );
};

export default React.memo(FormSelect);
