import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import FormSwitch from '../../components/form/FormSwitch';
import FormTextField from '../../components/form/FormTextField';
import { POST_CODE_PATTERN, HOUSE_NUMBER_PATTERN, ADDRESS_PROPS } from '../../constants/index';

export default function ThirdPartyFunding({ productType }: any) {
  const { t } = useTranslation();
  const [thirdPartyFunding, setThirdPartyFunding] = useState<Boolean>(false);

  return (
    <Grid container item className="formSubSection">
      <Grid container item xs={12} className="formSwitchSection">
        <FormSwitch
          name="projectPurpose.thirdPartyFunding"
          label={t('ikk.ProjectDescription.ProjectPurpose.thirdPartyFunding')}
          data-testid="thirdPartyFunding-switch"
          onChange={() => setThirdPartyFunding(!thirdPartyFunding)}
        />
      </Grid>
      {(!thirdPartyFunding || productType === 'credit') ? (
        <></>
      ) : (
        <Grid container>
          <Grid container item xs={12} justifyContent="center" className="formGroup">
            <Grid item xs={12} md={6}>
              <FormTextField
                name="projectPurpose.thirdPartyFundingBeneficiary"
                className="formItem"
                required
                label={t('ikk.ProjectDescription.ProjectPurpose.thirdPartyFundingBeneficiary')}
                data-testid="thirdPartyFunding-textField"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                name="projectPurpose.legalForm"
                className="formItem"
                required
                label={t('ikk.ProjectDescription.ProjectPurpose.legalForm')}
                data-testid="legalForm"
              />
            </Grid>
          </Grid>
          
          <Grid container item xs={12} justifyContent="center" className="formGroup">
            <Grid item xs={12} md={6}>
              <FormTextField
                name="projectPurpose.street"
                autoComplete="street-address"
                className="formItem"
                required
                label={t('ikk.common.Address.street')}
                maxLength={ADDRESS_PROPS.street.maxLength}
                data-testid="street"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                name="projectPurpose.houseNumber"
                className="formItem"
                required
                label={t('ikk.common.Address.houseNumber')}
                pattern={HOUSE_NUMBER_PATTERN}
                patternErrMsg="validation.invalidHouseNumber"
                data-testid="houseNumber"
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} justifyContent="center" className="formGroup">
            <Grid item xs={12} md={6}>
              <FormTextField
                name="projectPurpose.postcode"
                autoComplete="postal-code"
                className="formItem"
                required
                label={t('ikk.common.Address.postcode')}
                pattern={POST_CODE_PATTERN}
                patternErrMsg="validation.invalidPostCodeFormat"
                maxLength={ADDRESS_PROPS.postcode.maxLength}
                data-testid="postCode"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                name="projectPurpose.location"
                autoComplete="address-level2"
                className="formItem"
                required
                label={t('ikk.common.Address.location')}
                maxLength={ADDRESS_PROPS.location.maxLength}
                data-testid="location"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
