import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AxiosResponse } from 'axios';
import { useSnackbarContext } from '../../common/context/snackbarContext';
import { client } from '../../common/api/apiClient';

interface ResponseError extends Error {
  response: AxiosResponse;
}
const getErrorText = ({ e, t }: { e: ResponseError; t: any }) => {
  // we might use more error codes in the future
  if (!e.response) return t('snackbar.errors.unspecific');
  if (e.response.data.statusCode) {
    return t([
      `snackbar.errors.${e.response.data.statusCode}`,
      `snackbar.errors.${e.response.status}`,
      'snackbar.errors.unspecific',
    ]);
  }
  return t([`snackbar.errors.${e.response.status}`, 'snackbar.errors.unspecific']);
};

const useErrorHandler = () => {
  const { addError } = useSnackbarContext();
  const { t } = useTranslation();

  const handleInterceptorInit = useCallback(() => {
    client.interceptors.response.use(
      (response) => {
        return response;
      },
      (e) => {
        const finalText = getErrorText({ e, t });
        if (e.response?.data.message) {
          e.response.data.message = finalText;
        }
        addError(finalText);
        return Promise.reject(e);
      },
    );
  }, [addError, t]);

  useEffect(() => {
    handleInterceptorInit();
  }, [handleInterceptorInit]);
};

export default useErrorHandler;
