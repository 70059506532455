import React from 'react';
import { LoginContextProvider } from './loginContext';
import { SnackbarStateProvider } from './snackbarContext';

const GlobalContextProvider = ({ children }) => {
  return (
    <LoginContextProvider>
      <SnackbarStateProvider>{children}</SnackbarStateProvider>
    </LoginContextProvider>
  );
};

export default GlobalContextProvider;
