export default [
  { id: 1000, title: 'Landwirtschaft, Jagd u. verbundene Tätigkeiten' },
  { id: 1100, title: 'Anbau einjähriger Pflanzen' },
  { id: 1110, title: 'Anb.v.Getreide (o.Reis),Hülsenfrüc. u.Ölsaaten' },
  { id: 1120, title: 'Anbau von Reis' },
  { id: 1130, title: 'Anb.v.Gemüse u.Melonen u.Wurzeln u.Knollen' },
  { id: 1131, title: 'Anbau von Gemüse und Melonen' },
  { id: 1132, title: 'Anb.v.Kartoffeln u.sonstigen Wurzeln u.Knollen' },
  { id: 1140, title: 'Anbau von Zuckerrohr' },
  { id: 1150, title: 'Anbau von Tabak' },
  { id: 1160, title: 'Anbau von Faserpflanzen' },
  { id: 1190, title: 'Anbau von sonstigen einjährigen Pflanzen' },
  { id: 1191, title: 'Anbau von Zierpflanzen zum Schnitt' },
  { id: 1192, title: 'Erzeugung von Blumensamen' },
  { id: 1199, title: 'Anb.v.sonstigen einjährigen Pflanzen a. n. g.' },
  { id: 1200, title: 'Anbau mehrjähriger Pflanzen' },
  { id: 1210, title: 'Anbau von Wein- und Tafeltrauben' },
  { id: 1220, title: 'Anb.v.tropischen u. subtropischen Früchten' },
  { id: 1230, title: 'Anbau von Zitrusfrüchten' },
  { id: 1240, title: 'Anbau von Kern- und Steinobst' },
  { id: 1250, title: 'Anbau von sonstigem Obst und Nüssen' },
  { id: 1251, title: 'Anbau von Erdbeeren' },
  { id: 1259, title: 'Anb.v.sonstigem Obst (ohne Erdbeeren) u.Nüssen' },
  { id: 1260, title: 'Anbau von ölhaltigen Früchten' },
  { id: 1270, title: 'Anb.v.Pflanzen zur Herst.v.Getränken' },
  { id: 1280, title: 'Anb.v.Gewürzpflanzen u. aroma.narkot.pharma.' },
  { id: 1290, title: 'Anbau sonstiger mehrjähriger Pflanzen' },
  { id: 1300, title: 'Betr.v.Baumschulen u.Anb.v.Pfl.zu Vermehrungs.' },
  { id: 1301, title: 'Anb.v.Zimmerpflanzen, Beet- u. Balkonpflanzen' },
  { id: 1302, title: 'Betrieb von Baumschulen' },
  { id: 1400, title: 'Tierhaltung' },
  { id: 1410, title: 'Haltung von Milchkühen' },
  { id: 1420, title: 'Haltung von anderen Rindern' },
  { id: 1430, title: 'Haltung von Pferden und Eseln' },
  { id: 1440, title: 'Haltung von Kamelen' },
  { id: 1450, title: 'Haltung von Schafen und Ziegen' },
  { id: 1460, title: 'Haltung von Schweinen' },
  { id: 1470, title: 'Haltung von Geflügel' },
  { id: 1471, title: 'Haltung von Legehennen zur Konsumeiererzeugung' },
  { id: 1472, title: 'Betrieb von Brütereien' },
  { id: 1479, title: 'Sonstige Haltung von Nutzgeflügel' },
  { id: 1490, title: 'Sonstige Tierhaltung' },
  { id: 1500, title: 'Gemischte Landwirtschaft' },
  { id: 1600, title: 'Erbr.v.landwirtschaftlichen Dienstl.' },
  { id: 1610, title: 'Erbr.v.landwirt. Dienstl.f. den Pflanzenbau' },
  { id: 1620, title: 'Erbr.v.landwirt. Dienstl.f. die Tierhaltung' },
  { id: 1630, title: 'Nach der Ernte anfallende Tätigk.i.d.pfla.Ezg.' },
  { id: 1640, title: 'Saatgutaufbereitung' },
  { id: 1700, title: 'Jagd,Fallenstellerei u.dam.verbu.Tätigkeiten' },
  { id: 2000, title: 'Forstwirtschaft und Holzeinschlag' },
  { id: 2100, title: 'Forstwirtschaft' },
  { id: 2200, title: 'Holzeinschlag' },
  { id: 2300, title: 'Sammeln v.wild wachsenden Produkten (o.Holz)' },
  { id: 2400, title: 'Erbr.v.Dienstl.f. Forstwirtschaft u.Holzeinsc.' },
  { id: 3000, title: 'Fischerei und Aquakultur' },
  { id: 3100, title: 'Fischerei' },
  { id: 3110, title: 'Meeresfischerei' },
  { id: 3120, title: 'Süßwasserfischerei' },
  { id: 3200, title: 'Aquakultur' },
  { id: 3210, title: 'Meeresaquakultur' },
  { id: 3220, title: 'Süßwasseraquakultur' },
  { id: 5000, title: 'Kohlenbergbau' },
  { id: 5100, title: 'Steinkohlenbergbau' },
  { id: 5200, title: 'Braunkohlenbergbau' },
  { id: 6000, title: 'Gewinnung von Erdöl und Erdgas' },
  { id: 6100, title: 'Gewinnung von Erdöl' },
  { id: 6200, title: 'Gewinnung von Erdgas' },
  { id: 7000, title: 'Erzbergbau' },
  { id: 7100, title: 'Eisenerzbergbau' },
  { id: 7200, title: 'NE-Metallerzbergbau' },
  { id: 7210, title: 'Bergbau auf Uran- und Thoriumerze' },
  { id: 7290, title: 'Sonstiger NE-Metallerzbergbau' },
  { id: 8000, title: 'Gewinnung v.Steinen u.Erden, sonstiger Bergbau' },
  { id: 8100, title: 'Gewin.v.Natursteinen,Kies,Sand,Ton u.Kaolin' },
  { id: 8110, title: 'Gewin.v.Naturwerkst.,Kalk,Gips,Kreide,Schiefer' },
  { id: 8120, title: 'Gewinnung von Kies, Sand, Ton und Kaolin' },
  { id: 8900, title: 'Sonst. Bergbau;Gewin. v.Steinen u.Erden a.n.g.' },
  { id: 8910, title: 'Bergbau auf chemische und Düngemittelminerale' },
  { id: 8920, title: 'Torfgewinnung' },
  { id: 8930, title: 'Gewinnung von Salz' },
  { id: 8990, title: 'Gewinnung von Steinen und Erden a. n. g.' },
  { id: 9000, title: 'Erbr.v.Dienstl.f.Bergbau,Gewi. v.Steinen,Erden' },
  { id: 9100, title: 'Erbr.v.Dienstl.f. die Gewinn. v.Erdöl u.Erdgas' },
  { id: 9900, title: 'Erbr.v.Dienstl.sonst. Bergbau, Steinen u.Erden' },
  { id: 10000, title: 'Herstellung von Nahrungs- und Futtermitteln' },
  { id: 10100, title: 'Schlachten und Fleischverarbeitung' },
  { id: 10110, title: 'Schlachten (ohne Schlachten von Geflügel)' },
  { id: 10120, title: 'Schlachten von Geflügel' },
  { id: 10130, title: 'Fleischverarbeitung' },
  { id: 10200, title: 'Fischverarbeitung' },
  { id: 10300, title: 'Obst- und Gemüseverarbeitung' },
  { id: 10310, title: 'Kartoffelverarbeitung' },
  { id: 10320, title: 'Herstellung von Frucht- und Gemüsesäften' },
  { id: 10390, title: 'Sonstige Verarbeitung von Obst und Gemüse' },
  { id: 10400, title: 'Herst.v.pflanzlichen u.tierisch. Ölen u.Fetten' },
  { id: 10410, title: 'Herst.v.Ölen u.Fetten (o.Marg. u.ä.Nahrungsf.)' },
  { id: 10420, title: 'Herstellung von Margarine u. ä. Nahrungsfetten' },
  { id: 10500, title: 'Milchverarbeitung' },
  { id: 10510, title: 'Milchverarbeitung (ohne Herst.v.Speiseeis)' },
  { id: 10520, title: 'Herstellung von Speiseeis' },
  { id: 10600, title: 'Mahl-u.Schälmühlen,H.v.Stärke u.Stärkeerzeug.' },
  { id: 10610, title: 'Mahl- und Schälmühlen' },
  { id: 10620, title: 'Herstellung von Stärke und Stärkeerzeugnissen' },
  { id: 10700, title: 'Herstellung von Back- und Teigwaren' },
  { id: 10710, title: 'Herst.v.Backwaren (ohne Dauerbackwaren)' },
  { id: 10720, title: 'Herstellung von Dauerbackwaren' },
  { id: 10730, title: 'Herstellung von Teigwaren' },
  { id: 10800, title: 'Herstellung von sonstigen Nahrungsmitteln' },
  { id: 10810, title: 'Herstellung von Zucker' },
  { id: 10820, title: 'Herstellung von Süßwaren (o. Dauerbackwaren)' },
  { id: 10830, title: 'Verarb. v.Kaffee u.Tee,Herst.v.Kaffee-Ersatz' },
  { id: 10840, title: 'Herstellung von Würzmitteln und Soßen' },
  { id: 10850, title: 'Herstellung von Fertiggerichten' },
  { id: 10860, title: 'H.v.homogenisierten u.diätetischen Nahrungsm.' },
  { id: 10890, title: 'Herst.v.sonstigen Nahrungsmitteln a. n. g.' },
  { id: 10900, title: 'Herstellung von Futtermitteln' },
  { id: 10910, title: 'Herstellung von Futtermitteln für Nutztiere' },
  { id: 10920, title: 'Herst.v.Futtermitteln für sonstige Tiere' },
  { id: 11000, title: 'Getränkeherstellung' },
  { id: 11010, title: 'Herstellung von Spirituosen' },
  { id: 11020, title: 'Herstellung von Traubenwein' },
  { id: 11030, title: 'Herst.v.Apfelwein u. anderen Fruchtweinen' },
  { id: 11040, title: 'H.v.Wermutwein u.sonst. aromatisierten Weinen' },
  { id: 11050, title: 'Herstellung von Bier' },
  { id: 11060, title: 'Herstellung von Malz' },
  { id: 11070, title: 'H.v.Erfrischungsgetränken;Gewi.natür.Mineralw.' },
  { id: 12000, title: 'Tabakverarbeitung' },
  { id: 13000, title: 'Herstellung von Textilien' },
  { id: 13100, title: 'Spinnstoffaufbereitung und Spinnerei' },
  { id: 13200, title: 'Weberei' },
  { id: 13300, title: 'Veredlung von Textilien und Bekleidung' },
  { id: 13900, title: 'Herstellung von sonstigen Textilwaren' },
  { id: 13910, title: 'Herst.v.gewirktem u. gestricktem Stoff' },
  { id: 13920, title: 'H.v.konfektionierten Textilw. (o.Bekleidung)' },
  { id: 13930, title: 'Herstellung von Teppichen' },
  { id: 13940, title: 'Herstellung von Seilerwaren' },
  { id: 13950, title: 'H.v.Vliesstoff u.Erzeug. daraus (o.Bekleidung)' },
  { id: 13960, title: 'Herstellung von technischen Textilien' },
  { id: 13990, title: 'Herstellung von sonstigen Textilwaren a. n. g.' },
  { id: 14000, title: 'Herstellung von Bekleidung' },
  { id: 14100, title: 'Herst.v.Bekleidung (ohne Pelzbekleidung)' },
  { id: 14110, title: 'Herstellung von Lederbekleidung' },
  { id: 14120, title: 'Herstellung von Arbeits- und Berufsbekleidung' },
  { id: 14130, title: 'Herstellung von sonstiger Oberbekleidung' },
  { id: 14131, title: 'H.v.gewebter Oberbekleidung f. Herren u.Knaben' },
  { id: 14132, title: 'H.v.gewebter Oberbekleidung f. Damen u.Mädchen' },
  { id: 14133, title: 'Herst.v.gewirkter u.gestrickter Oberbekleidung' },
  { id: 14140, title: 'Herstellung von Wäsche' },
  { id: 14141, title: 'Herst.v.gewebter Wäsche (ohne Miederwaren)' },
  { id: 14142, title: 'H.v.gewirkter u.gestri.Wäsche (o.Miederwaren)' },
  { id: 14143, title: 'Herstellung von Miederwaren' },
  { id: 14190, title: 'H.v.sonstiger Bekleidung u.Bek.-zubehör a.n.g.' },
  { id: 14200, title: 'Herstellung von Pelzwaren' },
  { id: 14300, title: 'H.v.Bekleidung aus gewirktem u.gestrick. Stoff' },
  { id: 14310, title: 'Herstellung von Strumpfwaren' },
  { id: 14390, title: 'H.v.sonst.Bekleidung a.gewirk.u.gestrick.Stoff' },
  { id: 15000, title: 'Herstellung von Leder, Lederwaren und Schuhen' },
  { id: 15100, title: 'H.v.Leder u.Lederwaren (o.Herst.v.Lederbekl.)' },
  { id: 15110, title: 'H.v.Leder u.L.-faserst.;Zuri.u.Färben v.Fellen' },
  { id: 15120, title: 'Lederverarbeitung (o.Herst.v.Lederbekleidung)' },
  { id: 15200, title: 'Herstellung von Schuhen' },
  { id: 16000, title: 'Herst.v.Holz-,Flecht-,Korb- u.Korkwaren (o.Mö)' },
  { id: 16100, title: 'Säge-, Hobel- und Holzimprägnierwerke' },
  { id: 16200, title: 'H.v.sonst. Holz-,Kork-,Flecht-u.Korb.(o.Möbel)' },
  { id: 16210, title: 'H.v.Furnier-,Sperrh.-,Holzfaser-u.Holzspanpla.' },
  { id: 16220, title: 'Herstellung von Parketttafeln' },
  { id: 16230, title: 'H.v.s. Konstrukt.,Ausb.,Fertigteilbaut. a.Holz' },
  { id: 16240, title: 'H.v.Verpack.,Behältern u.Ladungsträgern a.Holz' },
  { id: 16290, title: 'H.v.Holz-,Kork-,Flecht-u.Korbwaren(o.Mö)a.n.g.' },
  { id: 17000, title: 'Herstellung von Papier, Pappe und Waren daraus' },
  { id: 17100, title: 'Herst.v.Holz-u.Zellstoff,Papier,Karton u.Pappe' },
  { id: 17110, title: 'Herstellung von Holz- und Zellstoff' },
  { id: 17120, title: 'Herstellung von Papier, Karton und Pappe' },
  { id: 17200, title: 'Herst.v.Waren aus Papier, Karton u. Pappe' },
  { id: 17210, title: 'H.v.Wellp.u.Verpackungsm.a.Papier,Karton,Pappe' },
  { id: 17220, title: 'H.v.Haush.-,Hygienearti.a.Zellst.,Papier,Pappe' },
  { id: 17230, title: 'H.v.Schreibw.,Bürobedarf a.Papier,Karton,Pappe' },
  { id: 17240, title: 'Herstellung von Tapeten' },
  { id: 17290, title: 'Herst.v.sonst. Waren aus Papier,Karton u.Pappe' },
  { id: 18000, title: 'Herst.v.Druckerzeug.;Verv.v.be.Ton,Bild,Daten.' },
  { id: 18100, title: 'Herstellung von Druckerzeugnissen' },
  { id: 18110, title: 'Drucken von Zeitungen' },
  { id: 18120, title: 'Drucken a. n. g.' },
  { id: 18130, title: 'Druck- und Medienvorstufe' },
  { id: 18140, title: 'Binden v.Druckerzeug.u.dam.verbu.ene Dienstl.' },
  { id: 18200, title: 'Vervielfältigung v.be.Ton-,Bild-u.Datenträgern' },
  { id: 19000, title: 'Kokerei und Mineralölverarbeitung' },
  { id: 19100, title: 'Kokerei' },
  { id: 19200, title: 'Mineralölverarbeitung' },
  { id: 20000, title: 'Herstellung von chemischen Erzeugnissen' },
  { id: 20100, title: 'H.v.che.Grund,Düng,Stickst,Kunstst.u.sy.Kaut.' },
  { id: 20110, title: 'Herstellung von Industriegasen' },
  { id: 20120, title: 'Herstellung von Farbstoffen und Pigmenten' },
  { id: 20130, title: 'H.v.sonst.anorganischen G.stoffen u.Chemikalie' },
  { id: 20140, title: 'H.v.sonst.organischen Gr.stoffen u.Chemikalien' },
  { id: 20150, title: 'Herst.v.Düngemitteln u. Stickstoffverbindungen' },
  { id: 20160, title: 'Herstellung von Kunststoffen in Primärformen' },
  { id: 20170, title: 'Herst.v.synthetischem Kautschuk i.Primärformen' },
  { id: 20200, title: 'H.v.Schädlingsbe.-,Pflanzens.-,Desinfektionsm.' },
  { id: 20300, title: 'Herst.v.Anstrichmitteln, Druckfarben u. Kitten' },
  { id: 20400, title: 'H.v.Seifen,Wasch.,Reinigung.,Körperpfl.,Duftst' },
  { id: 20410, title: 'H.v.Seifen,Wasch-,Reinigungs-u.Poliermitteln' },
  { id: 20420, title: 'Herst.v.Körperpflegemitteln u. Duftstoffen' },
  { id: 20500, title: 'Herst.v.sonstigen chemischen Erzeugnissen' },
  { id: 20510, title: 'Herstellung von pyrotechnischen Erzeugnissen' },
  { id: 20520, title: 'Herstellung von Klebstoffen' },
  { id: 20530, title: 'Herstellung von etherischen Ölen' },
  { id: 20590, title: 'Herst.v.sonst. chemischen Erzeugnissen a.n.g.' },
  { id: 20600, title: 'Herstellung von Chemiefasern' },
  { id: 21000, title: 'Herstellung von pharmazeutischen Erzeugnissen' },
  { id: 21100, title: 'Herstellung von pharmazeutischen Grundstoffen' },
  { id: 21200, title: 'H.v.pharma.Spezial.,sonst.pharma. Erzeugnissen' },
  { id: 22000, title: 'Herstellung von Gummi- und Kunststoffwaren' },
  { id: 22100, title: 'Herstellung von Gummiwaren' },
  { id: 22110, title: 'Herstellung und Runderneuerung von Bereifungen' },
  { id: 22190, title: 'Herstellung von sonstigen Gummiwaren' },
  { id: 22200, title: 'Herstellung von Kunststoffwaren' },
  { id: 22210, title: 'H.v.Platten,Folien,Schläuchen,Profil a.Kunstst' },
  { id: 22220, title: 'Herst.v.Verpackungsmitteln aus Kunststoffen' },
  { id: 22230, title: 'Herst.v.Baubedarfsartikeln aus Kunststoffen' },
  { id: 22290, title: 'Herstellung von sonstigen Kunststoffwaren' },
  { id: 23000, title: 'Herst.v.Glas,-waren,Keramik,Verarb.v.Stein,Erd' },
  { id: 23100, title: 'Herstellung von Glas und Glaswaren' },
  { id: 23110, title: 'Herstellung von Flachglas' },
  { id: 23120, title: 'Veredlung und Bearbeitung von Flachglas' },
  { id: 23130, title: 'Herstellung von Hohlglas' },
  { id: 23140, title: 'Herstellung von Glasfasern und Waren daraus' },
  { id: 23190, title: 'H.,Vered.u.Bearb.v.sonst.Glas,tech.Glaswaren' },
  { id: 23200, title: 'H.v.feuerfesten keramischen Werkstoffen u.Ware' },
  { id: 23300, title: 'Herstellung von keramischen Baumaterialien' },
  { id: 23310, title: 'H.v.keramischen Wand-u.Bodenfliesen u.-platten' },
  { id: 23320, title: 'Herst.v.Ziegeln u. sonstiger Baukeramik' },
  { id: 23400, title: 'H.v.sonst.Porzellan-u.keramischen Erzeugnissen' },
  { id: 23410, title: 'H.v.keramis. Haushaltswaren u.Ziergegenständen' },
  { id: 23420, title: 'Herstellung von Sanitärkeramik' },
  { id: 23430, title: 'Herst.v.Isolatoren u.Isolierteilen aus Keramik' },
  { id: 23440, title: 'H.v.keramischen Erzeug.f.sonst.tech.Zwecke' },
  { id: 23490, title: 'Herst.v.sonstigen keramischen Erzeugnissen' },
  { id: 23500, title: 'Herst.v.Zement, Kalk u. gebranntem Gips' },
  { id: 23510, title: 'Herstellung von Zement' },
  { id: 23520, title: 'Herstellung von Kalk und gebranntem Gips' },
  { id: 23600, title: 'Herst.v.Erzeugnissen aus Beton, Zement u. Gips' },
  { id: 23610, title: 'H.v.Erzeug.a.Beton,Zement u.Kalksandst.f.Bau' },
  { id: 23620, title: 'Herstellung von Gipserzeugnissen für den Bau' },
  { id: 23630, title: 'Herstellung von Frischbeton (Transportbeton)' },
  { id: 23640, title: 'Herst.v.Mörtel u. anderem Beton (Trockenbeton)' },
  { id: 23650, title: 'Herstellung von Faserzementwaren' },
  { id: 23690, title: 'H.v.sonst. Erzeug.a.Beton,Zement u.Gips a.n.g.' },
  { id: 23700, title: 'Be-u.Verarb.v.Naturwerk-u.Natursteinen a.n.g.' },
  { id: 23900, title: 'H.v.Schleif.a.Unterl.,sonst.Ezg.a.nichtmetal.M' },
  { id: 23910, title: 'H.v.Schleifkörpern,Schleifmitteln a. Unterlage' },
  { id: 23990, title: 'Herst.v.s.Erzeug.a.nichtmetall.Mineral. a.n.g.' },
  { id: 24000, title: 'Metallerzeugung und -bearbeitung' },
  { id: 24100, title: 'Erzeugung v.Roheisen, Stahl u.Ferrolegierungen' },
  { id: 24200, title: 'H.v.Rohren,-verschluss-,-verb.stücken a. Stahl' },
  { id: 24201, title: 'Herst.v.Stahlrohren (o.Präzisionsstahlrohre)' },
  { id: 24202, title: 'Herstellung von Präzisionsstahlrohren' },
  { id: 24203, title: 'H.v.Rohren,-verschluss-,-verb.stücken a. Stahl' },
  { id: 24300, title: 'Sonstige erste Bearbeitung von Eisen und Stahl' },
  { id: 24310, title: 'Herstellung von Blankstahl' },
  { id: 24320, title: 'H.v.Kaltband mit Breite von weniger als 600 mm' },
  { id: 24330, title: 'Herstellung von Kaltprofilen' },
  { id: 24340, title: 'Herstellung von kaltgezogenem Draht' },
  { id: 24400, title: 'Erzeugung u. erste Bearbeitung v. NE-Metallen' },
  { id: 24410, title: 'Erzeugung u. erste Bearbeitung v. Edelmetallen' },
  { id: 24420, title: 'Erzeugung und erste Bearbeitung von Aluminium' },
  { id: 24430, title: 'Erzeugung u.erste Bearbeitung v.Blei,Zink,Zinn' },
  { id: 24440, title: 'Erzeugung und erste Bearbeitung von Kupfer' },
  { id: 24450, title: 'Erzeugung u.erste Bearbeitung v.so.NE-Metallen' },
  { id: 24460, title: 'Aufbereitung von Kernbrennstoffen' },
  { id: 24500, title: 'Gießereien' },
  { id: 24510, title: 'Eisengießereien' },
  { id: 24520, title: 'Stahlgießereien' },
  { id: 24530, title: 'Leichtmetallgießereien' },
  { id: 24540, title: 'Buntmetallgießereien' },
  { id: 25000, title: 'Herstellung von Metallerzeugnissen' },
  { id: 25100, title: 'Stahl- und Leichtmetallbau' },
  { id: 25110, title: 'Herstellung von Metallkonstruktionen' },
  { id: 25120, title: 'Herstellung von Ausbauelementen aus Metall' },
  { id: 25200, title: 'H.v.Met.tanks,-behä.;H.v.Heizkör.,-kess.Zentr.' },
  { id: 25210, title: 'H.v.Heizkörpern u.-kesseln f. Zentralheizungen' },
  { id: 25290, title: 'H.v.Sammelbehältern,Tanks u.ä.Behältern Metall' },
  { id: 25300, title: 'Herst.v.Dampfkesseln (o.Zentralheizungskessel)' },
  { id: 25400, title: 'Herstellung von Waffen und Munition' },
  { id: 25500, title: 'H.v.Schmiede-,Press-,Zieh-,Stanzteilen,pulverb' },
  { id: 25501, title: 'Herstellung von Freiformschmiedestücken' },
  { id: 25502, title: 'Herstellung von Gesenkschmiedeteilen' },
  { id: 25503, title: 'Herstellung von Kaltfließpressteilen' },
  { id: 25504, title: 'Herstellung von Press-, Zieh- und Stanzteilen' },
  { id: 25505, title: 'Herst.v.pulvermetallurgischen Erzeugnissen' },
  { id: 25600, title: 'Oberflächenvered.,Wärmebehand.;Mechanik a.n.g.' },
  { id: 25610, title: 'Oberflächenveredlung und Wärmebehandlung' },
  { id: 25620, title: 'Mechanik a. n. g.' },
  { id: 25700, title: 'H.v.Schneidw.,Werkz.,Schlös.,Beschl.a.une.Met.' },
  { id: 25710, title: 'H.v.Schneidwaren,Bestecken a. unedlen Metallen' },
  { id: 25720, title: 'H.v.Schlössern,Beschlägen aus unedlen Metallen' },
  { id: 25730, title: 'Herstellung von Werkzeugen' },
  { id: 25731, title: 'Herstellung von Handwerkzeugen' },
  { id: 25732, title: 'H.v.Sägen,Masch.werkzeugen f.d.Holzbearbeitung' },
  { id: 25733, title: 'H.v.auswechselb.Werkz.f.d.Metallbearbe. a.n.g.' },
  { id: 25734, title: 'Herstellung von Geräten für die Landwirtschaft' },
  { id: 25735, title: 'Herstellung von sonstigen Werkzeugen' },
  { id: 25900, title: 'Herstellung von sonstigen Metallwaren' },
  { id: 25910, title: 'H.v.Fässern,Trommeln,Dosen,Eimern,Behäl.a.Met.' },
  { id: 25920, title: 'H.v.Verpack.,Verschlüs.a.Eisen,Stahl,NE-Metall' },
  { id: 25930, title: 'Herstellung von Drahtwaren, Ketten und Federn' },
  { id: 25940, title: 'Herstellung von Schrauben und Nieten' },
  { id: 25990, title: 'Herstellung von sonstigen Metallwaren a. n.ág.' },
  { id: 25991, title: 'H.v.nicht elektri.Haushaltsartikeln aus Metall' },
  { id: 25992, title: 'Herst.v.Panzerschränken u. Tresoranlagen' },
  { id: 25993, title: 'Herstellung von Metallwaren a. n. g.' },
  { id: 26000, title: 'Herst.v.DV-Geräten,elektr.u.optischen Erzeugn.' },
  { id: 26100, title: 'H.v.elektronischen Bauelementen,Leiterplatten' },
  { id: 26110, title: 'Herstellung von elektronischen Bauelementen' },
  { id: 26111, title: 'Herstellung von Solarzellen und Solarmodulen' },
  { id: 26119, title: 'Herst.v.sonstigen elektronischen Bauelementen' },
  { id: 26120, title: 'Herstellung von bestückten Leiterplatten' },
  { id: 26200, title: 'H.v.Datenverarbeitungsgerä.,peripheren Geräten' },
  { id: 26300, title: 'H.v.Geräten,Einric.d.Telekommunikationstechnik' },
  { id: 26400, title: 'Herst.v.Geräten der Unterhaltungselektronik' },
  { id: 26500, title: 'H.v.Mess-,Kontroll-,Naviga.-,Instru.;H.v.Uhren' },
  { id: 26510, title: 'H.v.Mess-,Kontroll-,Naviga.-,Instru.,Vorricht.' },
  { id: 26511, title: 'H.v.elektr.Mess-,Kontroll-,Naviga.-,Instrumen.' },
  { id: 26512, title: 'H.v.nicht elektri.Mess-,Kontr.-,Navi.,Instrum.' },
  { id: 26513, title: 'Herstellung von Prüfmaschinen' },
  { id: 26520, title: 'Herstellung von Uhren' },
  { id: 26600, title: 'H.v.Bestrahlungs-u.Elektr.ther.-,elektromediz.' },
  { id: 26700, title: 'H.v.optischen u.fotograf. Instrumenten,Geräten' },
  { id: 26800, title: 'Herst.v.magnetischen u. optischen Datenträgern' },
  { id: 27000, title: 'Herstellung von elektrischen Ausrüstungen' },
  { id: 27100, title: 'H.v.E-Motoren,Genera.,Transforma.,Elektrizität' },
  { id: 27110, title: 'H.v.Elektromotoren,Generatoren,Transformatoren' },
  { id: 27120, title: 'H.v.Elektrizitätsverteilungs-,-schalteinricht.' },
  { id: 27200, title: 'Herstellung von Batterien und Akkumulatoren' },
  { id: 27300, title: 'H.v.Kabeln,elektrischem Installationsmaterial' },
  { id: 27310, title: 'Herstellung von Glasfaserkabeln' },
  { id: 27320, title: 'H.v.sonst.elektroni.,elektrisch.Drähten,Kabeln' },
  { id: 27330, title: 'Herst.v.elektrischem Installationsmaterial' },
  { id: 27400, title: 'Herst.v.elektrischen Lampen u. Leuchten' },
  { id: 27500, title: 'Herstellung von Haushaltsgeräten' },
  { id: 27510, title: 'Herstellung von elektrischen Haushaltsgeräten' },
  { id: 27520, title: 'Herst.v.nicht elektrischen Haushaltsgeräten' },
  { id: 27900, title: 'H.v.sonst.elektris.Ausrüstungen,Geräten a.n.g.' },
  { id: 28000, title: 'Maschinenbau' },
  { id: 28100, title: 'Herst.v.nicht wirtschaftszweigspez. Masch.' },
  { id: 28110, title: 'H.v.Verbrennungsm.,Turbinen(o.Mot.f.Luft-,Stra' },
  { id: 28120, title: 'H.v.hydraulischen,pneumatischen Kompo.,Systeme' },
  { id: 28130, title: 'Herst.v.Pumpen u. Kompressoren a. n. g.' },
  { id: 28140, title: 'Herstellung von Armaturen a. n. g.' },
  { id: 28150, title: 'H.v.Lagern,Getrieben,Zahnrädern,Antriebselemen' },
  { id: 28200, title: 'Herst.v.sons.nicht wirtschaftszweigspe. Masch.' },
  { id: 28210, title: 'Herstellung von Öfen und Brennern' },
  { id: 28211, title: 'Herstellung von Solarwärmekollektoren' },
  { id: 28219, title: 'Herstellung von sonstigen Öfen und Brennern' },
  { id: 28220, title: 'Herstellung von Hebezeugen und Fördermitteln' },
  { id: 28230, title: 'H.v.Büromasch.(oh.EDV-Geräte,periphere Geräte)' },
  { id: 28240, title: 'H.v.handgeführten Werkzeugen m.Motorantrieb' },
  { id: 28250, title: 'H.v.kälte-u.lufttechn.Erzeug.,nicht f.d.Haush.' },
  { id: 28290, title: 'H.v.sonst.nicht wirt.zweigspezif. Masch.a.n.g.' },
  { id: 28300, title: 'Herst.v.land- u. forstwirtschaftlichen Masch.' },
  { id: 28400, title: 'Herstellung von Werkzeugmaschinen' },
  { id: 28410, title: 'Herst.v.WerkzeugMasch.f. die Metallbearbeitung' },
  { id: 28490, title: 'Herstellung von sonstigen Werkzeugmaschinen' },
  { id: 28491, title: 'H.v.Werkzeugmasch.z.Bearb.v.Steinen,Beton,sons' },
  { id: 28492, title: 'H.v.Werkzeugmasch.z.Bearb.v.sonst.harten Stoff' },
  { id: 28493, title: 'H.v.Masch.spannze.u.sonst.Zubehör f.Werkzeugma' },
  { id: 28499, title: 'Herst.v.sonstigen Werkzeugmaschinen a. n. g.' },
  { id: 28900, title: 'H.v.Masch.f.sonst. bestimmte Wirtschaftszweige' },
  { id: 28910, title: 'H.v.Masch.f.d.Metallerzeug.,Walzwerk.,Gießmasc' },
  { id: 28920, title: 'Herst.v.Bergwerks-, Bau- u. Baustoffmaschinen' },
  { id: 28921, title: 'Herstellung von Bergwerksmaschinen' },
  { id: 28922, title: 'Herstellung von Bau- und Baustoffmaschinen' },
  { id: 28930, title: 'H.v.Masch.f.d.Nahrungs-,Genussm.erzeugung,Taba' },
  { id: 28940, title: 'H.v.Masch.f.d.Textil-,Bekleidungsherst,Lederve' },
  { id: 28950, title: 'H.v.Masch.f. die Papiererzeugung,-verarbeitung' },
  { id: 28960, title: 'H.v.Masch.f.d.Verarb.v.Kunststoffen,Kautschuk' },
  { id: 28990, title: 'H.v.Masch.f.sonst. bestimmte Wirtschaftszweige' },
  { id: 29000, title: 'Herstellung v. Kraftwagen und Kraftwagenteilen' },
  { id: 29100, title: 'Herst.v.Kraftwagen u. Kraftwagenmotoren' },
  { id: 29101, title: 'H.v.PKW u.Personenkraftwagenmotoren' },
  { id: 29102, title: 'Herst.v.Nutzkraftwagen u.Nutzkraftwagenmotoren' },
  { id: 29200, title: 'Herst.v.Karosserien, Aufbauten u. Anhängern' },
  { id: 29300, title: 'Herst.v.Teilen u. Zubehör für Kraftwagen' },
  { id: 29310, title: 'H.v.elektrischer,elektronischer Ausrüst.f. KFZ' },
  { id: 29320, title: 'H.v.sonst.Teilen,sonst. Zubehör für Kraftwagen' },
  { id: 30000, title: 'Sonstiger Fahrzeugbau' },
  { id: 30100, title: 'Schiff- und Bootsbau' },
  { id: 30110, title: 'Schiffbau (ohne Boots- und Yachtbau)' },
  { id: 30120, title: 'Boots- und Yachtbau' },
  { id: 30200, title: 'Schienenfahrzeugbau' },
  { id: 30201, title: 'Herst.v.Lokomotiven u.ande. Schienenfahrzeugen' },
  { id: 30202, title: 'Herstellung von Eisenbahninfrastruktur' },
  { id: 30300, title: 'Luft- und Raumfahrzeugbau' },
  { id: 30400, title: 'Herstellung von militärischen Kampffahrzeugen' },
  { id: 30900, title: 'Herstellung von Fahrzeugen a.n.g.' },
  { id: 30910, title: 'Herstellung von Krafträdern' },
  { id: 30920, title: 'Herst.v.Fahrrädern u. v.Behindertenfahrzeugen' },
  { id: 30990, title: 'Herstellung von sonstigen Fahrzeugen a.n.g.' },
  { id: 31000, title: 'Herstellung von Möbeln' },
  { id: 31010, title: 'Herstellung von Büro- und Ladenmöbeln' },
  { id: 31011, title: 'Herstellung von Büromöbeln' },
  { id: 31012, title: 'Herst.v.Ladenmöbeln u. sonstigen Objektmöbeln' },
  { id: 31020, title: 'Herstellung von Küchenmöbeln' },
  { id: 31030, title: 'Herstellung von Matratzen' },
  { id: 31090, title: 'Herstellung von sonstigen Möbeln' },
  { id: 31091, title: 'Herstellung von Polstermöbeln' },
  { id: 31099, title: 'Herstellung von sonstigen Möbeln a. n. g.' },
  { id: 32000, title: 'Herstellung von sonstigen Waren' },
  { id: 32100, title: 'Herst.v.Münzen,Schmuck u.ähnlich. Erzeugnissen' },
  { id: 32110, title: 'Herstellung von Münzen' },
  { id: 32120, title: 'H.v.Schmuck,Gold-u.Silberschmiedew.(o.Fantasie' },
  { id: 32130, title: 'Herstellung von Fantasieschmuck' },
  { id: 32200, title: 'Herstellung von Musikinstrumenten' },
  { id: 32300, title: 'Herstellung von Sportgeräten' },
  { id: 32400, title: 'Herstellung von Spielwaren' },
  { id: 32500, title: 'H.v.mediz.,zahnmedizi. Apparaten u.Materialien' },
  { id: 32501, title: 'H.v.medizintechnischen Apparaten u.Materialien' },
  { id: 32502, title: 'Herstellung von orthopädischen Erzeugnissen' },
  { id: 32503, title: 'Zahntechnische Laboratorien' },
  { id: 32900, title: 'Herstellung von Erzeugnissen a. n. g.' },
  { id: 32910, title: 'Herstellung von Besen und Bürsten' },
  { id: 32990, title: 'Herst.v.sonstigen Erzeugnissen a.n.g.' },
  { id: 33000, title: 'Reparatur,Installa.v.Maschinen u.Ausrüstungen' },
  { id: 33100, title: 'Repa. v.Metallerzeugnissen,Masch.,Ausrüstungen' },
  { id: 33110, title: 'Reparatur von Metallerzeugnissen' },
  { id: 33120, title: 'Reparatur von Maschinen' },
  { id: 33130, title: 'Reparatur v.elektronischen u.optischen Geräten' },
  { id: 33140, title: 'Reparatur von elektrischen Ausrüstungen' },
  { id: 33150, title: 'Repa. u.Instandhaltung Schiffen,Booten,Yachten' },
  { id: 33160, title: 'Repa. u.Instandhaltung v.Luft-u.Raumfahrzeugen' },
  { id: 33170, title: 'Reparatur u.Instandhaltung v.Fahrzeugen a.n.g.' },
  { id: 33190, title: 'Reparatur von sonstigen Ausrüstungen' },
  { id: 33200, title: 'Installation v. Masch. u. Ausrüstungen a.n.g.' },
  { id: 35000, title: 'Energieversorgung' },
  { id: 35100, title: 'Elektrizitätsversorgung' },
  { id: 35110, title: 'Elektrizitätserzeugung' },
  { id: 35111, title: 'Elektrizitätserzeugung ohne Verteilung' },
  { id: 35112, title: 'Erlektrizitätserze.m.Fremdbezug zur Verteilung' },
  { id: 35113, title: 'Erlektrizitätserze.m.Fremdbezug zur Verteilung' },
  { id: 35120, title: 'Elektrizitätsübertragung' },
  { id: 35130, title: 'Elektrizitätsverteilung' },
  { id: 35140, title: 'Elektrizitätshandel' },
  { id: 35200, title: 'Gasversorgung' },
  { id: 35210, title: 'Gaserzeugung' },
  { id: 35211, title: 'Gaserzeugung ohne Verteilung' },
  { id: 35212, title: 'Gaserzeugung mit Fremdbezug zur Verteilung' },
  { id: 35213, title: 'Gaserzeugung ohne Fremdbezug zur Verteilung' },
  { id: 35220, title: 'Gasverteilung durch Rohrleitungen' },
  { id: 35230, title: 'Gashandel durch Rohrleitungen' },
  { id: 35300, title: 'Wärme- und Kälteversorgung' },
  { id: 36000, title: 'Wasserversorgung' },
  { id: 36001, title: 'Wassergewinnung mit Fremdbezug zur Verteilung' },
  { id: 36002, title: 'Wassergewinnung ohne Fremdbezug zur Verteilung' },
  { id: 36003, title: 'Wasserverteilung ohne Gewinnung' },
  { id: 37000, title: 'Abwasserentsorgung' },
  { id: 37001, title: 'Betrieb der Sammelkanalisation' },
  { id: 37002, title: 'Betrieb von Kläranlagen' },
  { id: 38000, title: 'Sammlung,Behandlung u.Beseitigung v.Abfällen' },
  { id: 38100, title: 'Sammlung von Abfällen' },
  { id: 38110, title: 'Sammlung nicht gefährlicher Abfälle' },
  { id: 38120, title: 'Sammlung gefährlicher Abfälle' },
  { id: 38200, title: 'Abfallbehandlung und -beseitigung' },
  { id: 38210, title: 'Behandlung,Beseitigung nicht gefährl. Abfälle' },
  { id: 38220, title: 'Behandlung u. Beseitigung gefährlicher Abfälle' },
  { id: 38300, title: 'Rückgewinnung' },
  { id: 38310, title: 'Zerlegen v.Schiffs-,Fahrzeugwracks u.and.Altw.' },
  { id: 38320, title: 'Rückgewinnung sortierter Werkstoffe' },
  { id: 39000, title: 'Beseitigung v.Umweltverschmutz.,sons. Entsorg.' },
  { id: 41000, title: 'Hochbau' },
  { id: 41100, title: 'Erschließung von Grundstücken; Bauträger' },
  { id: 41101, title: 'Erschließung von unbebauten Grundstücken' },
  { id: 41102, title: 'Bauträger für Nichtwohngebäude' },
  { id: 41103, title: 'Bauträger für Wohngebäude' },
  { id: 41200, title: 'Bau von Gebäuden' },
  { id: 41201, title: 'Bau von Gebäuden (ohne Fertigteilbau)' },
  { id: 41202, title: 'Errichtung von Fertigteilbauten' },
  { id: 42000, title: 'Tiefbau' },
  { id: 42100, title: 'Bau von Straßen und Bahnverkehrsstrecken' },
  { id: 42110, title: 'Bau von Straßen' },
  { id: 42120, title: 'Bau von Bahnverkehrsstrecken' },
  { id: 42130, title: 'Brücken- und Tunnelbau' },
  { id: 42200, title: 'Leitungstiefbau und Kläranlagenbau' },
  { id: 42210, title: 'Rohrleitungstiefbau,Brunnenbau,Kläranlagenbau' },
  { id: 42220, title: 'Kabelnetzleitungstiefbau' },
  { id: 42900, title: 'Sonstiger Tiefbau' },
  { id: 42910, title: 'Wasserbau' },
  { id: 42990, title: 'Sonstiger Tiefbau a.n.g.' },
  { id: 43000, title: 'Vorber.Baustellenarb.,Bauinstallation' },
  { id: 43100, title: 'Abbrucharbeiten u.vorbereitende Baustellenarb.' },
  { id: 43110, title: 'Abbrucharbeiten' },
  { id: 43120, title: 'Vorbereitende Baustellenarbeiten' },
  { id: 43130, title: 'Test- und Suchbohrung' },
  { id: 43200, title: 'Bauinstallation' },
  { id: 43210, title: 'Elektroinstallation' },
  { id: 43220, title: 'Gas-,Wasser-,Heizungs-,Lüftungs-,Klimainstall.' },
  { id: 43290, title: 'Sonstige Bauinstallation' },
  { id: 43291, title: 'Dämmung gegen Kälte,Wärme,Schall,Erschütterung' },
  { id: 43299, title: 'Sonstige Bauinstallation a. n. g.' },
  { id: 43300, title: 'Sonstiger Ausbau' },
  { id: 43310, title: 'Anbringen v.Stuckaturen,Gipserei u.Verputzerei' },
  { id: 43320, title: 'Bautischlerei und -schlosserei' },
  { id: 43330, title: 'Fußboden-,Fliesen-,Plattenlegerei,Tapeziererei' },
  { id: 43340, title: 'Malerei und Glaserei' },
  { id: 43341, title: 'Maler- und Lackierergewerbe' },
  { id: 43342, title: 'Glasergewerbe' },
  { id: 43390, title: 'Sonstiger Ausbau a. n. g.' },
  { id: 43900, title: 'Sonstige spezialisierte Bautätigkeiten' },
  { id: 43910, title: 'Dachdeckerei und Zimmerei' },
  { id: 43911, title: 'Dachdeckerei und Bauspenglerei' },
  { id: 43912, title: 'Zimmerei und Ingenieurholzbau' },
  { id: 43990, title: 'Sonstige spezialisierte Bautätigkeiten a.n.g.' },
  { id: 43991, title: 'Gerüstbau' },
  { id: 43992, title: 'Schornstein-, Feuerungs- und Industrieofenbau' },
  { id: 43999, title: 'Baugewerbe a. n. g.' },
  { id: 45000, title: 'Handel m. KFZ;Instandhaltung,Reparatur v. KFZ' },
  { id: 45100, title: 'Handel mit Kraftwagen' },
  { id: 45110, title: 'Handel m.KFZ m.einem GesamtGwg.v.3,5t o.wenig.' },
  { id: 45190, title: 'Handel m.KFZ m.einem GesamtGwg.v.mehr als 3,5t' },
  { id: 45200, title: 'Instandhaltung und Reparatur von Kraftwagen' },
  { id: 45201, title: 'Lackieren von Kraftwagen' },
  { id: 45202, title: 'Autowaschanlagen' },
  { id: 45203, title: 'Repa.v.KFZ m.GesamtGwg.v.3,5t o.weniger(o.La.)' },
  { id: 45204, title: 'Repa.v.KFZ m.GesamtGwg.v.mehr als 3,5t (o.La.)' },
  { id: 45300, title: 'Handel mit Kraftwagenteilen und -zubehör' },
  { id: 45310, title: 'Großhandel mit Kraftwagenteilen und -zubehör' },
  { id: 45320, title: 'Einzelhandel mit Kraftwagenteilen und -zubehör' },
  { id: 45400, title: 'Handel,Repa. Krafträdern,-radteilen u.-zubehör' },
  { id: 46000, title: 'Großhandel (ohne Handel mit Kraftfahrzeugen)' },
  { id: 46100, title: 'Handelsvermittlung' },
  { id: 46110, title: 'Ha.verm.v.landw.Gru.stoffen,leb.Tieren,textile' },
  { id: 46120, title: 'Ha.verm.v.Brennstoffen,Erzen,Metal.,techni.Che' },
  { id: 46130, title: 'Handelsverm.v.Holz,Baustoffen,Anstrichmitteln' },
  { id: 46131, title: 'Handelsverm.v.Rohholz,Holzhalbw.,Bauelem. Holz' },
  { id: 46132, title: 'Handelsverm. v. Baustoffen u. Anstrichmitteln' },
  { id: 46140, title: 'Ha.verm.v.Masch.,tech.Bedarf,Wasser-,Luftfahrz' },
  { id: 46141, title: 'Ha.verm.v.Masch.(o.landw.,Büro),techn.Bedarf' },
  { id: 46142, title: 'Handelsverm. v. Wasser- u. Luftfahrzeugen' },
  { id: 46143, title: 'Handelsverm.v.Geräten d.Unterhaltungselektron.' },
  { id: 46144, title: 'Handelsvermittlung von Werkzeugen' },
  { id: 46145, title: 'Handelsverm.v.Büromasch.,EDV-Geräten,Software' },
  { id: 46146, title: 'Handelsverm.v.landwirtschaftl. Masch.u.Geräten' },
  { id: 46147, title: 'Ha.verm.v.Inst.bedarf Gas,Wasser,Heizung,Klim.' },
  { id: 46149, title: 'Ha.verm.v.elektrote.,elektroni. Erzeug.a.n.g.' },
  { id: 46150, title: 'Ha.verm.v.Möbeln,Einrich.-,Hausha.gegenständen' },
  { id: 46151, title: 'Ha.verm.v.Möbeln,Einricht.gegens.,Antiquitäten' },
  { id: 46152, title: 'Ha.verm.v.keram.Erzeug.,Glas-,Holzwaren a.n.g.' },
  { id: 46153, title: 'Handelsverm. v. elektrischen Haushaltsgeräten' },
  { id: 46154, title: 'Ha.verm.v.Eisen-,Metall-,Kunststoffwar. a.n.g.' },
  { id: 46155, title: 'Handelsverm. v. Putz- u. Reinigungsmitteln' },
  { id: 46160, title: 'Ha.verm.v.Textilien,Bekleidung,Schuhen,Lederwa' },
  { id: 46161, title: 'Handelsverm.v.Meterware f. Bekleidung u.Wäsche' },
  { id: 46162, title: 'Handelsverm.v.Heim-,Haustextilien,Bodenbelägen' },
  { id: 46163, title: 'Handelsvermittlung von Bekleidung' },
  { id: 46164, title: 'Handelsvermittlung von Bekleidungszubehör' },
  { id: 46165, title: 'Handelsverm.v.Schuhen,Lederwaren u.Reisegepäck' },
  { id: 46170, title: 'Ha.verm.v.Nahrungsmitteln,Getränken,Tabakwaren' },
  { id: 46171, title: 'Handelsvermittlung von Zucker und Süßwaren' },
  { id: 46172, title: 'Handelsverm. v. Wein, Sekt u. Spirituosen' },
  { id: 46179, title: 'Ha.verm.v.sonst.Nahrungsmit.,Getränken u.Tabak' },
  { id: 46180, title: 'Handelsvermittlung von sonstigen Waren' },
  { id: 46181, title: 'Ha.verm.v.feinmech.,Foto-,optisc. Erzeugnissen' },
  { id: 46182, title: 'Handelsverm.v.Uhren, Edelmetallwaren u.Schmuck' },
  { id: 46183, title: 'Handelsverm.v.Spielwaren u.Musikinstrumenten' },
  { id: 46184, title: 'Handelsverm.v.pharma.,mediz.,orthopä.Artikeln' },
  { id: 46185, title: 'Ha.verm.v.kosmeti.Erzeugnissen,Körperpflegemit' },
  { id: 46186, title: 'Ha.verm.v.Schreib.,Büro.,Geschenk.,Werbe.,Verp' },
  { id: 46187, title: 'Ha.verm.v.Büchern,Zeitschr.,Zeitungen,Musikali' },
  { id: 46189, title: 'Handelsvermittlung v. sonstigen Waren a. n. g.' },
  { id: 46190, title: 'Handelsverm.v.Waren o.ausgeprägten Schwerpunkt' },
  { id: 46200, title: 'Gr.ha.m.landwi.Grundstoffen u. lebenden Tieren' },
  { id: 46210, title: 'Gr.ha.m.Getreide,Rohtabak,Saatgut,Futtermittel' },
  { id: 46220, title: 'Großhandel mit Blumen und Pflanzen' },
  { id: 46230, title: 'Großhandel mit lebenden Tieren' },
  { id: 46240, title: 'Großhandel mit Häuten, Fellen und Leder' },
  { id: 46300, title: 'Gr.ha.m.Nahrungs-,Genussmitteln,Getränken,Taba' },
  { id: 46310, title: 'Großhandel mit Obst, Gemüse und Kartoffeln' },
  { id: 46320, title: 'Großhandel mit Fleisch und Fleischwaren' },
  { id: 46330, title: 'Gr.ha.m.Milch,-erzeug.,Eiern,Speiseöl,Nahr.fet' },
  { id: 46340, title: 'Großhandel mit Getränken' },
  { id: 46350, title: 'Großhandel mit Tabakwaren' },
  { id: 46360, title: 'Großhandel mit Zucker, Süßwaren und Backwaren' },
  { id: 46370, title: 'Großhandel mit Kaffee, Tee, Kakao und Gewürzen' },
  { id: 46380, title: 'Gr.ha. m.sonstigen Nahrungs- u. Genussmitteln' },
  { id: 46381, title: 'Großhandel mit Fisch und Fischerzeugnissen' },
  { id: 46382, title: 'Großhandel mit Mehl und Getreideprodukten' },
  { id: 46389, title: 'Gr.ha. m.Nahrungs- u. Genussmitteln a.n.g.' },
  { id: 46390, title: 'Gr.ha.m.Nahrungs-,Genussmit.,Getränken,Tabakwa' },
  { id: 46391, title: 'Gr.ha.m.tiefgefr.Nahrungsmitteln,o.ausge.Schwe' },
  { id: 46399, title: 'Gr.ha.m.so.Nahrungs-,Genussmit.,Getränken,Taba' },
  { id: 46400, title: 'Großhandel mit Gebrauchs- und Verbrauchsgütern' },
  { id: 46410, title: 'Großhandel mit Textilien' },
  { id: 46420, title: 'Großhandel mit Bekleidung und Schuhen' },
  { id: 46421, title: 'Großhandel mit Bekleidung' },
  { id: 46422, title: 'Großhandel mit Schuhen' },
  { id: 46430, title: 'Gr.ha.m.Foto-,opti.Erzeug.,elektri.Haush.,Unte' },
  { id: 46431, title: 'Großhandel mit Foto- u. optischen Erzeugnissen' },
  { id: 46432, title: 'Großhandel mit elektrischen Haushaltsgeräten' },
  { id: 46433, title: 'Gr.ha. m.Geräten der Unterhaltungselektronik' },
  { id: 46440, title: 'Gr.ha.m.kerami.Erzeug.,Glaswaren,Reinigungsmit' },
  { id: 46441, title: 'Gr.ha. m.keramischen Erzeugnissen u. Glaswaren' },
  { id: 46442, title: 'Gr.ha. m.Wasch-, Putz- u. Reinigungsmitteln' },
  { id: 46450, title: 'Gr.ha.m.kosmetischen Erzeugnissen,Körperpflege' },
  { id: 46460, title: 'Gr.ha.m.pharmaz.,mediz.,orthopädischen Erzeug.' },
  { id: 46461, title: 'Großhandel mit pharmazeutischen Erzeugnissen' },
  { id: 46462, title: 'Gr.ha.m.mediz.,orthopä.Artikeln,Dental-u.Labor' },
  { id: 46470, title: 'Gr.ha. m.Möbeln, Teppichen, Lampen u. Leuchten' },
  { id: 46480, title: 'Großhandel mit Uhren und Schmuck' },
  { id: 46490, title: 'Gr.ha.m.sonstigen Gebrauchs-u.Verbrauchsgütern' },
  { id: 46491, title: 'Großhandel mit Spielwaren u. Musikinstrumenten' },
  { id: 46492, title: 'Gr.ha.m.Fahrrad,-teilen,-zubehör,Sport-,Campin' },
  { id: 46493, title: 'Gr.ha.m.Leder,Reisegepäck,Geschenk-,Werbeartik' },
  { id: 46494, title: 'Gr.ha.m.Schreibw.,Bürobedarf,Büchern,Zeitungen' },
  { id: 46495, title: 'Gr.ha.m.nicht elektr.Haush.,-waren,so.Gebr,Ver' },
  { id: 46500, title: 'Gr.ha.m.Ger.d.Informations-,Kommunikationstec.' },
  { id: 46510, title: 'Gr.ha.m.EDV-Geräten,peripheren Gerär.,Software' },
  { id: 46520, title: 'Gr.ha.m.elekt.Bauteilen,Telekommunikationsger.' },
  { id: 46600, title: 'Gr.ha.m.sonst. Masch.,Ausrüstungen u.Zubehör' },
  { id: 46610, title: 'Gr.ha.m.landwirtschaftlichen Masch.u.Geräten' },
  { id: 46620, title: 'Großhandel mit Werkzeugmaschinen' },
  { id: 46630, title: 'Gr.ha. m.Bergwerks-, Bau- u. BaustoffMasch.' },
  { id: 46640, title: 'Gr.ha. m.Textil-, Näh- u. StrickMasch.' },
  { id: 46650, title: 'Großhandel mit Büromöbeln' },
  { id: 46660, title: 'Gr.ha.m.sonstigen BüroMasch.u.-einrichtungen' },
  { id: 46690, title: 'Gr.ha. m.sonstigen Masch. u. Ausrüstungen' },
  { id: 46691, title: 'Gr.ha. m.Flurförderzeugen u. Fahrzeugen a.n.g.' },
  { id: 46692, title: 'Großhandel mit sonstigen Maschinen' },
  { id: 46693, title: 'Gr.ha.m.sonst.Ausrüstungen,Zubeh.f.Masch.,tech' },
  { id: 46700, title: 'Sonstiger Großhandel' },
  { id: 46710, title: 'Gr.ha.m.festen Brennstoffen,Mineralölerzeugnis' },
  { id: 46711, title: 'Großhandel mit festen Brennstoffen' },
  { id: 46712, title: 'Großhandel mit Mineralölerzeugnissen' },
  { id: 46720, title: 'Gr.ha. m.Erzen, Metallen u. Metallhalbzeug' },
  { id: 46721, title: 'Gr.ha.m.Eisenerzen,Eisen,Stahl,Eisen-,Stahlhal' },
  { id: 46722, title: 'Gr.ha.m.NE-Erzen,NE-Metallen,NE-Metallhalbzeug' },
  { id: 46730, title: 'Gr.ha.m.Holz,Bausto.,Anstrichmit.,Sanitärkeram' },
  { id: 46731, title: 'Gr.ha.m.Holz,Bausto.,Anstrichmit.,Sanitärkeram' },
  { id: 46732, title: 'Großhandel mit Roh- und Schnittholz' },
  { id: 46733, title: 'Gr.ha.m.sons.Holzhalbwaren,Bauelementen a.Holz' },
  { id: 46734, title: 'Gr.ha.m.Baustoffen,Bauelementen a.mineral.Stof' },
  { id: 46735, title: 'Großhandel mit Flachglas' },
  { id: 46736, title: 'Großhandel mit Anstrichmitteln' },
  { id: 46737, title: 'Großhandel mit Sanitärkeramik' },
  { id: 46738, title: 'Gr.ha.m.Tapeten u.Bodenbelägen (ohne Teppiche)' },
  { id: 46740, title: 'Gr.ha.m.Metall-,Kunststoffw.f.Bauzwecke,Instal' },
  { id: 46741, title: 'Großhandel mit Werkzeugen und Kleineisenwaren' },
  { id: 46742, title: 'Gr.ha.m.Installationsbed. f.Gas,Wasser,Heizung' },
  { id: 46743, title: 'Gr.ha.m.Metall-u.Kunststoffwaren f. Bauzwecke' },
  { id: 46750, title: 'Großhandel mit chemischen Erzeugnissen' },
  { id: 46760, title: 'Großhandel mit sonstigen Halbwaren' },
  { id: 46770, title: 'Großhandel mit Altmaterialien und Reststoffen' },
  { id: 46900, title: 'Großhandel ohne ausgeprägten Schwerpunkt' },
  { id: 46901, title: 'Gr.ha.m.Rohstoffen,Halb-,Fertigware,o.ausg.Sch' },
  { id: 46902, title: 'Gr.ha.m.Rohstoffen,Halbwaren,o.ausgepr.Schwerp' },
  { id: 46903, title: 'Gr.ha.m.Fertigwaren,o.ausgeprägten Schwerpunkt' },
  { id: 47000, title: 'Einzelhandel (ohne Handel mit Kraftfahrzeugen)' },
  { id: 47100, title: 'Einz.ha.m.Waren verschied.Art(im Verkaufsraum)' },
  { id: 47110, title: 'Einz.ha.m.Waren vers.Art,Nahr.,Genuss,Getr,Tab' },
  { id: 47111, title: 'Einz.ha.m.Nahrung,Genussm,Getränken,Tabakwaren' },
  { id: 47112, title: 'Sonst.Einz.ha.m.Waren vers.Art,Nahrung,Genussm' },
  { id: 47190, title: 'Sonstiger Einz.ha. m.Waren verschiedener Art' },
  { id: 47191, title: 'Einz.ha.m.Waren verschi.Art (o.Nahrungsmittel)' },
  { id: 47192, title: 'Einz.ha.m.Waren verschi.Art,Nicht-Nahrungsmitt' },
  { id: 47200, title: 'Einz.ha.m.Nahrung,Genuss,Getränken,Tabakw.(iV)' },
  { id: 47210, title: 'Einzelhandel mit Obst, Gemüse und Kartoffeln' },
  { id: 47220, title: 'Einzelhandel mit Fleisch und Fleischwaren' },
  { id: 47230, title: 'Einz.ha.m.Fisch,Meeresfrüchten u.Fischerzeugn.' },
  { id: 47240, title: 'Einzelhandel mit Back- und Süßwaren' },
  { id: 47250, title: 'Einzelhandel mit Getränken' },
  { id: 47260, title: 'Einzelhandel mit Tabakwaren' },
  { id: 47290, title: 'Sonstiger Einz.ha.m.Nahrungs- u.Genussmitteln' },
  { id: 47300, title: 'Einz.ha. m.Motorenkraftstoffen (Tankstellen)' },
  { id: 47301, title: 'Einz.ha.i.fre.Namen m.Motorenkraftst.(Agenturt' },
  { id: 47302, title: 'Einz.ha.i.eig.Namen m.Motorenkraftst.(Freie Ta' },
  { id: 47400, title: 'Einz.ha.m.Ger.d.Inform.-,Kommunikationste.(iV)' },
  { id: 47410, title: 'Einz.ha.m.EDV-Geräten,peripheren Ge.,Software' },
  { id: 47420, title: 'Einzelhandel mit Telekommunikationsgeräten' },
  { id: 47430, title: 'Einz.ha. m.Geräten der Unterhaltungselektronik' },
  { id: 47500, title: 'Einz.ha.m.so.Haush.geräten,Texti.,Heimwerker' },
  { id: 47510, title: 'Einzelhandel mit Textilien' },
  { id: 47520, title: 'Einz.ha.m.Metallw.,Anstrichmit.,Bau-u.Heimwerk' },
  { id: 47521, title: 'Einz.ha. m.Metall- u. Kunststoffwaren a.n.g.' },
  { id: 47523, title: 'Einz.ha.m.Anstrichmitteln,Bau-,Heimwerkerbedar' },
  { id: 47530, title: 'Einz.ha.m.Vorhängen,Tepp.,Fußbodenbel.,Tapeten' },
  { id: 47540, title: 'Einz.ha. m.elektrischen Haushaltsgeräten' },
  { id: 47590, title: 'Einz.ha.m.Möbeln,Einrichtungsgegen.,so.Hausrat' },
  { id: 47591, title: 'Einzelhandel mit Wohnmöbeln' },
  { id: 47592, title: 'Einz.ha.m.keramischen Erzeugnissen u.Glaswaren' },
  { id: 47593, title: 'Einz.ha. m.Musikinstrumenten u. Musikalien' },
  { id: 47599, title: 'Einzelhandel mit Haushaltsgegenständen a.n.g.' },
  { id: 47600, title: 'Einz.ha.m.Verlagsprod.,Sportausrüs.,Spielwaren' },
  { id: 47610, title: 'Einzelhandel mit Büchern' },
  { id: 47620, title: 'Einz.ha.m.Zeitschrif.,Zeitungen,Schreibw.,Büro' },
  { id: 47621, title: 'Einzelhandel mit Zeitschriften und Zeitungen' },
  { id: 47622, title: 'Einz.ha.m.Schreib-,Papierw.,Schul-u.Büroartike' },
  { id: 47630, title: 'Einz.ha. m.bespielten Ton- u. Bildträgern' },
  { id: 47640, title: 'Einz.ha.m.Fahrrädern, Sport- u.Campingartikeln' },
  { id: 47641, title: 'Einz.ha.m.Fahrrädern, Fahrradteilen u.-zubehör' },
  { id: 47642, title: 'Einz.ha.m.Sport-,Campingartikeln (o.Campingmöb' },
  { id: 47650, title: 'Einzelhandel mit Spielwaren' },
  { id: 47700, title: 'Einz.ha.m.sonstigen Gütern (in Verkaufsräumen)' },
  { id: 47710, title: 'Einzelhandel mit Bekleidung' },
  { id: 47720, title: 'Einzelhandel mit Schuhen und Lederwaren' },
  { id: 47721, title: 'Einzelhandel mit Schuhen' },
  { id: 47722, title: 'Einzelhandel mit Lederwaren und Reisegepäck' },
  { id: 47730, title: 'Apotheken' },
  { id: 47740, title: 'Einz.ha.m.medizinischen,orthopädischen Artikel' },
  { id: 47750, title: 'Einz.ha.m.kosmet.Erzeugnissen,Körperpflegemit.' },
  { id: 47760, title: 'Einz.ha.m.Pflanzen,Samen,Dünger,zoo.Bedarf,Tie' },
  { id: 47761, title: 'Einz.ha.m.Blumen,Pflanzen,Sämereien,Düngemitt.' },
  { id: 47762, title: 'Einz.ha.m.zoologischem Bedarf, lebenden Tieren' },
  { id: 47770, title: 'Einzelhandel mit Uhren und Schmuck' },
  { id: 47780, title: 'Sonst.Einz.ha.in Verkaufsräumen(o.Antiq,Gebra.' },
  { id: 47781, title: 'Augenoptiker' },
  { id: 47782, title: 'Einz.ha.m.Foto-u.optischen Erzeug.(o.Augenopt.' },
  { id: 47783, title: 'Einz.ha.m.Kunst,-gewerb.Erzeugn.,Marken,Münzen' },
  { id: 47789, title: 'Sonstiger Einz.ha. a.n.g. (in Verkaufsräumen)' },
  { id: 47790, title: 'Einz.ha. m.Antiquitäten u. Gebrauchtwaren' },
  { id: 47791, title: 'Einz.ha. m.Antiquitäten u. antiken Teppichen' },
  { id: 47792, title: 'Antiquariate' },
  { id: 47799, title: 'Einzelhandel mit sonstigen Gebrauchtwaren' },
  { id: 47800, title: 'Einzelhandel an Verkaufsständen u. auf Märkten' },
  { id: 47810, title: 'Einz.ha.m.Nahrung,Genuss,Getr.,Tab.Verkaufsstä' },
  { id: 47820, title: 'Einz.ha.m.Texti.,Bekleid.,Schuhen Verkaufsstän' },
  { id: 47890, title: 'Einz.ha.m.sonst.Gütern a.Verkaufsständen,Märkt' },
  { id: 47900, title: 'Einz.ha.,an Verkaufsständen oder auf Märkten' },
  { id: 47910, title: 'Versand- und Internet-Einzelhandel' },
  { id: 47911, title: 'Versand-,Internet-Einz.ha.Texti.,Bekl.,Schuhen' },
  { id: 47919, title: 'Sonstiger Versand- und Internet-Einzelhandel' },
  { id: 47990, title: 'Sonst.Einz.ha.,an Verkaufsständen o.a. Märkten' },
  { id: 47991, title: 'Einzelhandel vom Lager mit Brennstoffen' },
  { id: 47999, title: 'Sonst.Einz.ha.a.n.g.(nicht in Verkaufsräumen)' },
  { id: 49000, title: 'Landverkehr und Transport in Rohrfernleitungen' },
  { id: 49100, title: 'Personenbeförderung im Eisenbahnfernverkehr' },
  { id: 49200, title: 'Güterbeförderung im Eisenbahnverkehr' },
  { id: 49300, title: 'Sonstige Personenbeförderung im Landverkehr' },
  { id: 49310, title: 'Personenbeför.im Nahverkehr zu Lande (o.Taxis)' },
  { id: 49320, title: 'Betrieb von Taxis' },
  { id: 49390, title: 'Sonstige Personenbeför. im Landverkehr a.n.g.' },
  { id: 49391, title: 'Personenbeför. im Omnibus-Linienfernverkehr' },
  { id: 49392, title: 'Personenbeför. im Omnibus-Gelegenheitsverkehr' },
  { id: 49399, title: 'Personenbeförderung im Landverkehr a. n. g.' },
  { id: 49400, title: 'Güterbeför. im Straßenverkehr,Umzugstransporte' },
  { id: 49410, title: 'Güterbeförderung im Straßenverkehr' },
  { id: 49420, title: 'Umzugstransporte' },
  { id: 49500, title: 'Transport in Rohrfernleitungen' },
  { id: 50000, title: 'Schifffahrt' },
  { id: 50100, title: 'Personenbeför. in der See-u.Küstenschifffahrt' },
  { id: 50200, title: 'Güterbeför. in der See-u.Küstenschifffahrt' },
  { id: 50300, title: 'Personenbeförderung in der Binnenschifffahrt' },
  { id: 50400, title: 'Güterbeförderung in der Binnenschifffahrt' },
  { id: 51000, title: 'Luftfahrt' },
  { id: 51100, title: 'Personenbeförderung in der Luftfahrt' },
  { id: 51200, title: 'Güterbeför. in der Luftfahrt u.Raumtransport' },
  { id: 51210, title: 'Güterbeförderung in der Luftfahrt' },
  { id: 51220, title: 'Raumtransport' },
  { id: 52000, title: 'Lagerei,Erbring.v.s.Dienstleist. f.d.Verkehr' },
  { id: 52100, title: 'Lagerei' },
  { id: 52200, title: 'Erbr.v.sonstigen Dienstl. für den Verkehr' },
  { id: 52210, title: 'Erbr.v.sonstigen Dienstl. für den Landverkehr' },
  { id: 52211, title: 'Betrieb von Parkhäusern und Parkplätzen' },
  { id: 52212, title: 'Betrieb von Verkehrswegen für Straßenfahrzeuge' },
  { id: 52213, title: 'Betrieb v. Verkehrswegen für Schienenfahrzeuge' },
  { id: 52214, title: 'Betr.v.Bahnhöfen f.d.Personenverk.inkl.Omnibus' },
  { id: 52215, title: 'Betr.v.Güterabfertig.f.Schienen-u.Straßenfahrz' },
  { id: 52219, title: 'Erbr.v.sonst.Dienstl.f. den Landverkehr a.n.g.' },
  { id: 52220, title: 'Erbr.v.sonstigen Dienstl. für die Schifffahrt' },
  { id: 52221, title: 'Betrieb von Wasserstraßen' },
  { id: 52222, title: 'Betrieb von Häfen' },
  { id: 52223, title: 'Lotsinnen und Lotsen in der Schifffahrt' },
  { id: 52229, title: 'Erbr.v.sonst.Dienstl.f. die Schifffahrt a.n.g.' },
  { id: 52230, title: 'Erbr.v.sonstigen Dienstl. für die Luftfahrt' },
  { id: 52231, title: 'Betr.v.Flughäfen,Landeplätzen f. Luftfahrzeuge' },
  { id: 52239, title: 'Erbr.v.sonst. Dienstl.f. die Luftfahrt a.n.g.' },
  { id: 52240, title: 'Frachtumschlag' },
  { id: 52290, title: 'Erbr.v.sonstigen Dienstl.f. den Verkehr a.n.g.' },
  { id: 52291, title: 'Spedition' },
  { id: 52292, title: 'Schiffsmaklerbüros und -agenturen' },
  { id: 52299, title: 'Erbr.v.Dienstl. für den Verkehr a.n.g.' },
  { id: 53000, title: 'Post-, Kurier- und Expressdienste' },
  { id: 53100, title: 'Postdienste v.Universaldienstleistungsanbieter' },
  { id: 53200, title: 'Sonstige Post-, Kurier- und Expressdienste' },
  { id: 55000, title: 'Beherbergung' },
  { id: 55100, title: 'Hotels, Gasthöfe und Pensionen' },
  { id: 55101, title: 'Hotels (ohne Hotels garnis)' },
  { id: 55102, title: 'Hotels garnis' },
  { id: 55103, title: 'Gasthöfe' },
  { id: 55104, title: 'Pensionen' },
  { id: 55200, title: 'Ferienunterkünfte u.ähnl. Beherbergungsstätten' },
  { id: 55201, title: 'Erholungs- und Ferienheime' },
  { id: 55202, title: 'Ferienzentren' },
  { id: 55203, title: 'Ferienhäuser und Ferienwohnungen' },
  { id: 55204, title: 'Jugendherbergen und Hütten' },
  { id: 55300, title: 'Campingplätze' },
  { id: 55900, title: 'Sonstige Beherbergungsstätten' },
  { id: 55901, title: 'Privatquartiere' },
  { id: 55909, title: 'Sonstige Beherbergungsstätten a. n. g.' },
  { id: 56000, title: 'Gastronomie' },
  { id: 56100, title: 'Restaurants,Gaststätten,Imbiss,Cafés,Eissalons' },
  { id: 56101, title: 'Restaurants mit herkömmlicher Bedienung' },
  { id: 56102, title: 'Restaurants mit Selbstbedienung' },
  { id: 56103, title: 'Imbissstuben u.Ä.' },
  { id: 56104, title: 'Cafés' },
  { id: 56105, title: 'Eissalons' },
  { id: 56200, title: 'Caterer u.Erbr.sonstiger VerpflegungsDienstl.' },
  { id: 56210, title: 'Event-Caterer' },
  { id: 56290, title: 'Erbr. sonstiger VerpflegungsDienstl.' },
  { id: 56300, title: 'Ausschank von Getränken' },
  { id: 56301, title: 'Schankwirtschaften' },
  { id: 56302, title: 'Diskotheken und Tanzlokale' },
  { id: 56303, title: 'Bars' },
  { id: 56304, title: 'Vergnügungslokale' },
  { id: 56309, title: 'Sonstige getränkegeprägte Gastronomie' },
  { id: 58000, title: 'Verlagswesen' },
  { id: 58100, title: 'Verlegen v.Büchern,Zeitschri.;sonst.Verlagswes' },
  { id: 58110, title: 'Verlegen von Büchern' },
  { id: 58120, title: 'Verlegen von Adressbüchern und Verzeichnissen' },
  { id: 58130, title: 'Verlegen von Zeitungen' },
  { id: 58140, title: 'Verlegen von Zeitschriften' },
  { id: 58190, title: 'Sonstiges Verlagswesen (ohne Software)' },
  { id: 58200, title: 'Verlegen von Software' },
  { id: 58210, title: 'Verlegen von Computerspielen' },
  { id: 58290, title: 'Verlegen von sonstiger Software' },
  { id: 59000, title: 'Herst.Verleih,Vertrieb Filme,Ferns;Kinos;Tonst' },
  { id: 59100, title: 'H.v.Filmen,Fernsehprogra.,Verleih,Vertrieb;Kin' },
  { id: 59110, title: 'Herst.v.Filmen,Videofilmen u.Fernsehprogrammen' },
  { id: 59120, title: 'Nachbearbeitung und sonstige Filmtechnik' },
  { id: 59130, title: 'Filmverleih und -vertrieb (ohne Videotheken)' },
  { id: 59140, title: 'Kinos' },
  { id: 59200, title: 'Tonstudios;H.v.Hörfunkbeitr.;Verl.v.bes.Tonträ' },
  { id: 59201, title: 'Tonstudios u. Herst.v.Hörfunkbeiträgen' },
  { id: 59202, title: 'Verlegen von bespielten Tonträgern' },
  { id: 59203, title: 'Verlegen von Musikalien' },
  { id: 60000, title: 'Rundfunkveranstalter' },
  { id: 60100, title: 'Hörfunkveranstalter' },
  { id: 60200, title: 'Fernsehveranstalter' },
  { id: 61000, title: 'Telekommunikation' },
  { id: 61100, title: 'Leitungsgebundene Telekommunikation' },
  { id: 61200, title: 'Drahtlose Telekommunikation' },
  { id: 61300, title: 'Satellitentelekommunikation' },
  { id: 61900, title: 'Sonstige Telekommunikation' },
  { id: 61901, title: 'Internetserviceprovider' },
  { id: 61909, title: 'Sonstige Telekommunikation a. n. g.' },
  { id: 62000, title: 'Erbr.v.Dienstl. der Informationstechnologie' },
  { id: 62010, title: 'Programmierungstätigkeiten' },
  { id: 62011, title: 'Entwick.,Programmierung v.Internetpräsentation' },
  { id: 62019, title: 'Sonstige Softwareentwicklung' },
  { id: 62020, title: 'Erbr.v.Beratungsleist. Informationstechnologie' },
  { id: 62030, title: 'Betr.v.EDV-Einrichtungen f. Dritte' },
  { id: 62090, title: 'Erbr.v.sonst.Dienstl.d.Informationstechnologie' },
  { id: 63000, title: 'Informationsdienstleistungen' },
  { id: 63100, title: 'DV,Hosting u.dam. verbu.Tätigkeiten;Webportale' },
  { id: 63110, title: 'Datenverarbeitung,Hosting u.dam.verbu.Tätigkei' },
  { id: 63120, title: 'Webportale' },
  { id: 63900, title: 'Erbr.v.sonstigen InformationsDienstl.' },
  { id: 63910, title: 'Korrespondenz- und Nachrichtenbüros' },
  { id: 63990, title: 'Erbr.v.sonstigen InformationsDienstl. a.n.g.' },
  { id: 64000, title: 'Erbringung von Finanzdienstleistungen' },
  { id: 64100, title: 'Zentralbanken und Kreditinstitute' },
  { id: 64110, title: 'Zentralbanken' },
  { id: 64190, title: 'Kreditinstitute (ohne Spezialkreditinstitute)' },
  { id: 64191, title: 'Kreditbanken inkl.Zweigst.ausländischer Banken' },
  { id: 64192, title: 'Kreditinstitute des Sparkassensektors' },
  { id: 64193, title: 'Kreditinstitute des Genossenschaftssektors' },
  { id: 64194, title: 'Realkreditinstitute' },
  { id: 64195, title: 'Kreditinstitute mit Sonderaufgaben' },
  { id: 64196, title: 'Bausparkassen' },
  { id: 64197, title: 'Geldmarktfonds' },
  { id: 64200, title: 'Beteiligungsgesellschaften' },
  { id: 64300, title: 'Treuhand-,sonst.Fonds,ähnl.Finanzinstitutionen' },
  { id: 64301, title: 'Geschlossene Investmentvermögen (o.Geldmarktf)' },
  { id: 64302, title: 'Verbriefungszweckgesellschaften - intern' },
  { id: 64900, title: 'Sonstige Finanzierungsinstitutionen' },
  { id: 64910, title: 'Institutionen für Finanzierungsleasing' },
  { id: 64920, title: 'Spezialkreditinstitute' },
  { id: 64921, title: 'Spezialkreditinstitute (o.Pfandkreditgesch.)' },
  { id: 64922, title: 'Leihhäuser' },
  { id: 64990, title: 'Erbr.v.sonstigen FinanzDienstl. a.n.g.' },
  { id: 64991, title: 'Investmentaktiengesellschaften,Fonds(o.Geldmar' },
  { id: 64992, title: 'Verbriefungszweckgesellschaften' },
  { id: 64993, title: 'Finanzhandelsinstitute im Sinne §25f Abs.1 KW' },
  { id: 64994, title: 'Kapitalbeteiligungsgesellschaften' },
  { id: 64999, title: 'Sonstige Finanzierungsinstitutionen a. n. g.' },
  { id: 65000, title: 'Versicherungen,Rückvers.Pensionskassen (o.Soz)' },
  { id: 65100, title: 'Versicherungen, Rückv. (o. Soz.)' },
  { id: 65110, title: 'Lebensversicherungen' },
  { id: 65120, title: 'Nichtlebensversicherungen' },
  { id: 65121, title: 'Krankenversicherungen' },
  { id: 65122, title: 'Schaden- und Unfallversicherungen' },
  { id: 65200, title: 'Rückversicherungen' },
  { id: 65300, title: 'Pensionskassen und Pensionsfonds' },
  { id: 65400, title: 'Mgt.Holdingges.m.aktivemVersicherungsgesch.' },
  { id: 66000, title: 'Mit Finanz-u.Versicherungsdienstl.verbun.Tätig' },
  { id: 66100, title: 'Mit Finanzdienstl. verbundene Tätigkeiten' },
  { id: 66110, title: 'Effekten- und Warenbörsen' },
  { id: 66120, title: 'Effekten- und Warenhandel' },
  { id: 66190, title: 'Sonstige m.FinanzDienstl.verbu.ene Tätigkeiten' },
  { id: 66200, title: 'm.Versicherungsdie.,Pensionska.verb.Tätigkeite' },
  { id: 66210, title: 'Risiko- und Schadensbewertung' },
  { id: 66220, title: 'Tätigkeit v.Versicherungsmaklerinnen,-maklern' },
  { id: 66290, title: 'So.m.Versicherungsd.,Pensionska.verbu.Tätigkei' },
  { id: 66300, title: 'Fondsmanagement' },
  { id: 66400, title: 'Mgt.Holdingges.m.AnteilAnBankVersicherFin' },
  { id: 68000, title: 'Grundstücks- und Wohnungswesen' },
  { id: 68100, title: 'Kauf,Verkauf v.eigenen Grundst.,Gebäuden,Wohnu' },
  { id: 68101, title: 'Kauf,Verkauf v.eig.Wohngrund.,Wohngebäu.,Wohnu' },
  { id: 68102, title: 'Kauf,Verkauf v.eig.Gewerbegrundst.Nichtwohngeb' },
  { id: 68200, title: 'Vermie.,Verpachtung v.eig.o.geleast.Gru.,Gebä.' },
  { id: 68201, title: 'Vermie.,Verpachtung v.eig.o.geleast.Wohnungen' },
  { id: 68202, title: 'Vermie.,Verpachtung v.eig.o.geleast.Gewerbeg.' },
  { id: 68300, title: 'Vermit.,Verwalt.v.Gru.,Gebäuden,Wohn.f. Dritte' },
  { id: 68310, title: 'Vermit.v.Grund.,Gebäuden u.Wohnungen f. Dritte' },
  { id: 68311, title: 'Vermit.v.Wohngr.,Wohngebäu.,Wohnungen f.Dritte' },
  { id: 68312, title: 'Vermit.v.Gewerbegr.,Nichtwohngebäuden f.Dritte' },
  { id: 68320, title: 'Verwa.v.Grundstüc.,Gebäuden,Wohnungen f.Dritte' },
  { id: 68321, title: 'Verwa.v.Wohng.,Wohngebäuden,Wohnungen f.Dritte' },
  { id: 68322, title: 'Verwa.v.Gewerbegru.,Nichtwohngebäuden f.Dritte' },
  { id: 69000, title: 'Rechts- und Steuerberatung, Wirtschaftsprüfung' },
  { id: 69100, title: 'Rechtsberatung' },
  { id: 69101, title: 'Rechtsanwaltskanzleien mit Notariat' },
  { id: 69102, title: 'Rechtsanwaltskanzleien ohne Notariat' },
  { id: 69103, title: 'Notariate' },
  { id: 69104, title: 'Patentanwaltskanzleien' },
  { id: 69109, title: 'Erbr. sonstiger juristischer Dienstl. a.n.g.' },
  { id: 69200, title: 'Wirtschaftsprüfung,Steuerberatung;Buchführung' },
  { id: 69201, title: 'Praxen v.Wirtschaftsprüfer,Wirtschaftsp.gesell' },
  { id: 69202, title: 'Praxen v.vereidigten Buchprüfer,Buchp.gesellsc' },
  { id: 69203, title: 'Praxen v.Steuerbevollmächtigten,-berater,-gese' },
  { id: 69204, title: 'Buchführung (ohne Datenverarbeitungsdienste)' },
  { id: 70000, title: 'Verw.,Führung v.Unternehmen,Betrieben;U.Beratu' },
  { id: 70100, title: 'Verwaltung u.Führung v.Unternehmen u.Betr.en' },
  { id: 70101, title: 'Managementtätigkeiten v. Holdinggesellschaften' },
  { id: 70109, title: 'Sonst. Verwaltung,Führung v.Unternehmen,Betr.' },
  { id: 70200, title: 'Public-Relations- und Unternehmensberatung' },
  { id: 70210, title: 'Public-Relations-Beratung' },
  { id: 70220, title: 'Unternehmensberatung' },
  { id: 71000, title: 'Archite.u.Ingenieur;techn.,phys.,chem.Unters.' },
  { id: 71100, title: 'Architektur- und Ingenieurbüros' },
  { id: 71110, title: 'Architekturbüros' },
  { id: 71111, title: 'Architekturbüros für Hochbau' },
  { id: 71112, title: 'Büros für Innenarchitektur' },
  { id: 71113, title: 'Architekturbüro f.Orts-,Regional-,Landesplanun' },
  { id: 71114, title: 'Architekturbüro f.Garten-,Landschaftsgestaltun' },
  { id: 71120, title: 'Ingenieurbüros' },
  { id: 71121, title: 'Ingenieurbüros für bautechnische Gesamtplanung' },
  { id: 71122, title: 'Ingenieurbüros f.tech.Fachplanung,Ingenieurdes' },
  { id: 71123, title: 'Vermessungsbüros' },
  { id: 71129, title: 'Sonstige Ingenieurbüros' },
  { id: 71200, title: 'Technische,physikalische u.chemi. Untersuchung' },
  { id: 72000, title: 'Forschung und Entwicklung' },
  { id: 72100, title: 'F.u.E Natur-,Ingenieur-,Agrarwiss.u.Medizin' },
  { id: 72110, title: 'Forschung u.Entwicklung Bereich Biotechnologie' },
  { id: 72190, title: 'Sonst.F.u.E.Natur-,Ingenieur-,Agrarwi.,Medizin' },
  { id: 72200, title: 'F.u.E. Recht,Wirts,Sozial,Sprache,Kultur,Kunst' },
  { id: 73000, title: 'Werbung und Marktforschung' },
  { id: 73100, title: 'Werbung' },
  { id: 73110, title: 'Werbeagenturen' },
  { id: 73120, title: 'Vermarktung,Vermittlung v.Werbezeiten,-flächen' },
  { id: 73200, title: 'Markt- und Meinungsforschung' },
  { id: 74000, title: 'Sonst.freiberufl.,wissenschaftl.,techn.Tätigk.' },
  { id: 74100, title: 'Ateliers f. Textil-,Schmuck-,Grafik-u.ä.Design' },
  { id: 74101, title: 'Industrie-, Produkt- und Mode-Design' },
  { id: 74102, title: 'Grafik- und Kommunikationsdesign' },
  { id: 74103, title: 'Interior Design und Raumgestaltung' },
  { id: 74200, title: 'Fotografie und Fotolabors' },
  { id: 74201, title: 'Fotografie' },
  { id: 74202, title: 'Fotolabors' },
  { id: 74300, title: 'Übersetzen und Dolmetschen' },
  { id: 74301, title: 'Übersetzen' },
  { id: 74302, title: 'Dolmetschen' },
  { id: 74900, title: 'Sonst.freiberufl.,wissenschaft.,techn.Tätigkei' },
  { id: 75000, title: 'Veterinärwesen' },
  { id: 75001, title: 'Tierarztpraxen' },
  { id: 75009, title: 'Sonstiges Veterinärwesen' },
  { id: 77000, title: 'Vermietung von beweglichen Sachen' },
  { id: 77100, title: 'Vermietung von Kraftwagen' },
  { id: 77110, title: 'Vermie.v.KFZ m.Gesamtgewicht v.3,5 t o.weniger' },
  { id: 77120, title: 'Vermie.v.KFZ m.Gesamtgewicht v.mehr als 3,5 t' },
  { id: 77200, title: 'Vermietung von Gebrauchsgütern' },
  { id: 77210, title: 'Vermietung von Sport- und Freizeitgeräten' },
  { id: 77220, title: 'Videotheken' },
  { id: 77290, title: 'Vermietung von sonstigen Gebrauchsgütern' },
  { id: 77300, title: 'Vermie.v.Masch.,Geräten,sonst.bewegl. Sachen' },
  { id: 77310, title: 'Vermie.v.landwirtschaftlichen Masch.u.Geräten' },
  { id: 77320, title: 'Vermietung von Baumaschinen und -geräten' },
  { id: 77330, title: 'Vermie.v.Büromasch.,EDV-geräten u.-einrichtung' },
  { id: 77340, title: 'Vermietung von Wasserfahrzeugen' },
  { id: 77350, title: 'Vermietung von Luftfahrzeugen' },
  { id: 77390, title: 'Vermie.v.sonst.Masch.,Geräten,beweglich.Sachen' },
  { id: 77400, title: 'Leasing v.nichtfinanz.immater.Vermögensgegenst' },
  { id: 78000, title: 'Vermittlung und Überlassung von Arbeitskräften' },
  { id: 78100, title: 'Vermittlung von Arbeitskräften' },
  { id: 78200, title: 'Befristete Überlassung von Arbeitskräften' },
  { id: 78300, title: 'Sonstige Überlassung von Arbeitskräften' },
  { id: 79000, title: 'Reisebüros,Reiseverans.,Erbr.sonst.Reservierun' },
  { id: 79100, title: 'Reisebüros und Reiseveranstalter' },
  { id: 79110, title: 'Reisebüros' },
  { id: 79120, title: 'Reiseveranstalter' },
  { id: 79900, title: 'Erbr. sonstiger Reservierungsdienstleist.' },
  { id: 80000, title: 'Wach- und Sicherheitsdienste sowie Detekteien' },
  { id: 80100, title: 'Private Wach- und Sicherheitsdienste' },
  { id: 80200, title: 'Sicherheitsdienste mit Überwachungs-u.Alarmsys' },
  { id: 80300, title: 'Detekteien' },
  { id: 81000, title: 'Gebäudebetreuung; Garten- und Landschaftsbau' },
  { id: 81100, title: 'Hausmeisterdienste' },
  { id: 81200, title: 'Reinigung v.Gebäuden,Straßen u.Verkehrsmitteln' },
  { id: 81210, title: 'Allgemeine Gebäudereinigung' },
  { id: 81220, title: 'Spez. Reinigung v.Gebäuden,Reinigung v.Masch.' },
  { id: 81221, title: 'Schornsteinreinigung' },
  { id: 81229, title: 'Sonst. spezielle Reinigung v.Gebäuden u.Masch.' },
  { id: 81290, title: 'Reinigung a.n.g.' },
  { id: 81291, title: 'Reinigung von Verkehrsmitteln' },
  { id: 81292, title: 'Desinfektion und Schädlingsbekämpfung' },
  { id: 81299, title: 'Sonstige Reinigung a. n. g.' },
  { id: 81300, title: 'Garten-u.Lands.bau,Erbr.v.sonst.gärtner.Diens.' },
  { id: 81301, title: 'Garten- und Landschaftsbau' },
  { id: 81309, title: 'Erbr.v.sonstigen gärtnerischen Dienstl.' },
  { id: 82000, title: 'Erbr.v.wirtschaftl.Dienstl.f.Untern.,Privatper' },
  { id: 82100, title: 'Sekretariats- und Schreibdienste, Copy-Shops' },
  { id: 82110, title: 'Allgemeine Sekretariats- und Schreibdienste' },
  { id: 82190, title: 'Copy-Shops;Dokumentenvor.;sonst.Sekretariatsd.' },
  { id: 82200, title: 'Call Center' },
  { id: 82300, title: 'Messe-, Ausstellungs- und Kongressveranstalter' },
  { id: 82900, title: 'Erbr.sonst.wirts.Dienstl.f.Unterneh.,Privatpe.' },
  { id: 82910, title: 'Inkassobüros und Auskunfteien' },
  { id: 82911, title: 'Inkassobüros' },
  { id: 82912, title: 'Auskunfteien' },
  { id: 82920, title: 'Abfüllen und Verpacken' },
  { id: 82990, title: 'Erbr.sonst.wirts.Dienstl.f.Unterneh.,Privatpe.' },
  { id: 82991, title: 'Versteigerungsgewerbe' },
  { id: 82999, title: 'Erbr.ander.wirts.Dienstl.f.Unterneh.,Privatpe.' },
  { id: 84000, title: 'Öffentl.Verwaltung,Verteidigung;Sozialversich.' },
  { id: 84100, title: 'Öffentliche Verwaltung' },
  { id: 84110, title: 'Allgemeine öffentliche Verwaltung' },
  { id: 84120, title: 'Öffentl.Verwaltung Gesundh.,Bildung,Kultur,Soz' },
  { id: 84130, title: 'Wirtschaftsförderung, -ordnung und -aufsicht' },
  { id: 84200, title: 'Auswärt.Angelegenh.,Verteid.,Rechtspfl.,Sicher' },
  { id: 84210, title: 'Auswärtige Angelegenheiten' },
  { id: 84220, title: 'Verteidigung' },
  { id: 84230, title: 'Rechtspflege' },
  { id: 84240, title: 'Öffentliche Sicherheit und Ordnung' },
  { id: 84250, title: 'Feuerwehren' },
  { id: 84300, title: 'Sozialversicherung' },
  { id: 85000, title: 'Erziehung und Unterricht' },
  { id: 85100, title: 'Kindergärten und Vorschulen' },
  { id: 85101, title: 'Kindergärten' },
  { id: 85102, title: 'Vorklassen, Schulkindergärten' },
  { id: 85200, title: 'Grundschulen' },
  { id: 85300, title: 'Weiterführende Schulen' },
  { id: 85310, title: 'Allgemein bildende weiterführende Schulen' },
  { id: 85311, title: 'Allge.bildende weiterfü.Schulen Sekundarbe. I' },
  { id: 85312, title: 'Allge.bildende weiterfü.Schulen Sekundarbe. II' },
  { id: 85320, title: 'Berufsbildende weiterführende Schulen' },
  { id: 85400, title: 'Tertiärer,post-seku.ärer,ni.tertiärer Unterric' },
  { id: 85410, title: 'Post-sekundärer, nicht tertiärer Unterricht' },
  { id: 85420, title: 'Tertiärer Unterricht' },
  { id: 85421, title: 'Universitäten' },
  { id: 85422, title: 'Allgemeine Fachhochschulen' },
  { id: 85423, title: 'Verwaltungsfachhochschulen' },
  { id: 85424, title: 'Berufsakademien,Fachaka.,Schul.d.Gesu.heitswes' },
  { id: 85500, title: 'Sonstiger Unterricht' },
  { id: 85510, title: 'Sport- und Freizeitunterricht' },
  { id: 85520, title: 'Kulturunterricht' },
  { id: 85530, title: 'Fahr- und Flugschulen' },
  { id: 85590, title: 'Sonstiger Unterricht a.n.g.' },
  { id: 85591, title: 'Allgemeine und politische Erwachsenenbildung' },
  { id: 85592, title: 'Berufliche Erwachsenenbildung' },
  { id: 85599, title: 'Unterricht a. n. g.' },
  { id: 85600, title: 'Erbr.v.Dienstl. für den Unterricht' },
  { id: 86000, title: 'Gesundheitswesen' },
  { id: 86100, title: 'Krankenhäuser' },
  { id: 86101, title: 'Krankenhäuser(o.Hochschul-,Vors.-,Reha.-klin.)' },
  { id: 86102, title: 'Hochschulkliniken' },
  { id: 86103, title: 'Vorsorge- und Rehabilitationskliniken' },
  { id: 86200, title: 'Arzt- und Zahnarztpraxen' },
  { id: 86210, title: 'Arztpraxen für Allgemeinmedizin' },
  { id: 86220, title: 'Facharztpraxen' },
  { id: 86230, title: 'Zahnarztpraxen' },
  { id: 86900, title: 'Gesundheitswesen a. n. g.' },
  { id: 86901, title: 'Praxen von psychologischen Therapeut(in)en' },
  { id: 86902, title: 'medizinische sowie verwandte Berufen' },
  { id: 86903, title: 'Heilpraktikerpraxen' },
  { id: 86909, title: 'Sonst.selbstständige Tätigk.im Gesu.heitswesen' },
  { id: 87000, title: 'Heime (ohne Erholungs- und Ferienheime)' },
  { id: 87100, title: 'Pflegeheime' },
  { id: 87200, title: 'Stati.Einr.zur psychoso.Betreu.,Suchtbekä.u.Ä.' },
  { id: 87300, title: 'Altenheime; Alten- und Behindertenwohnheime' },
  { id: 87900, title: 'Sonstige Heime (ohne Erholungs- u.Ferienheime)' },
  { id: 88000, title: 'Sozialwesen (ohne Heime)' },
  { id: 88100, title: 'Soziale Betreuung älte. Menschen u.Behinderter' },
  { id: 88101, title: 'Ambulante soziale Dienste' },
  { id: 88102, title: 'Sons.soz. Betreuung ält.Menschen u.Behinderter' },
  { id: 88900, title: 'Sonstiges Sozialwesen (ohne Heime)' },
  { id: 88910, title: 'Tagesbetreuung von Kindern' },
  { id: 88990, title: 'Sonstiges Sozialwesen a.n.g.' },
  { id: 90000, title: 'Kreative,künstlerische u.unterhaltende Tätigk.' },
  { id: 90010, title: 'Darstellende Kunst' },
  { id: 90011, title: 'Theaterensembles' },
  { id: 90012, title: 'Ballettgruppen, Orchester, Kapellen und Chöre' },
  { id: 90013, title: 'Selbstständige Artist/innen,Zirkusgruppen' },
  { id: 90014, title: 'Selbstständige Künstler/-innen u.sonst.Kunst' },
  { id: 90020, title: 'Erbr.v.Dienstl. für die darstellende Kunst' },
  { id: 90030, title: 'Künstlerisches u.schriftstellerisches Schaffen' },
  { id: 90031, title: 'Selbstständige Komponisten-,Musikbearbeiter/-in' },
  { id: 90032, title: 'Selbstständige Schriftsteller/-innen' },
  { id: 90033, title: 'Selbstständige bildende Künstler/-innen' },
  { id: 90034, title: 'Selbstständige Restaurator/innen' },
  { id: 90035, title: 'Selbstständige Journalist-,Pressefotograf/in' },
  { id: 90040, title: 'Betr.v.Kultur- u. Unterhaltungseinrichtungen' },
  { id: 90041, title: 'Theater- und Konzertveranstalter' },
  { id: 90042, title: 'Opern-,Schauspielhä.,Konzerth.u.ähnl.Einricht.' },
  { id: 90043, title: 'Varietés und Kleinkunstbühnen' },
  { id: 91000, title: 'Bibliot.,Archive,Museen,botani. u.zoolo.Gärten' },
  { id: 91010, title: 'Bibliotheken und Archive' },
  { id: 91020, title: 'Museen' },
  { id: 91030, title: 'Betr.v.histor.Stätten,Gebäuden u.ähnl. Attr.' },
  { id: 91040, title: 'Botanische u.zoologische Gärten u. Naturparks' },
  { id: 92000, title: 'Spiel-, Wett- und Lotteriewesen' },
  { id: 92001, title: 'Spielhallen und Betrieb von Spielautomaten' },
  { id: 92002, title: 'Spielbanken und Spielklubs' },
  { id: 92003, title: 'Wett-, Toto- und Lotteriewesen' },
  { id: 93000, title: 'Erbr.v.Dienstl.d.Sports,Unterhaltung,Erholung' },
  { id: 93100, title: 'Erbringung von Dienstleistungen des Sports' },
  { id: 93110, title: 'Betrieb von Sportanlagen' },
  { id: 93120, title: 'Sportvereine' },
  { id: 93130, title: 'Fitnesszentren' },
  { id: 93190, title: 'Erbr.v.sonstigen Dienstl. des Sports' },
  { id: 93200, title: 'Erbr.v.sonst. Dienstl. Unterhaltung u.Erholung' },
  { id: 93210, title: 'Vergnügungs- und Themenparks' },
  { id: 93290, title: 'Erbr.v.Dienstl.Unterhaltung u.Erholung a.n.g.' },
  { id: 94000, title: 'Interessenvertr.,kirchl.u.son.religiöse Verein' },
  { id: 94100, title: 'Wirtschafts-u.Arbeitgeberverb.,Berufsorganisa.' },
  { id: 94110, title: 'Wirtschafts- und Arbeitgeberverbände' },
  { id: 94120, title: 'Berufsorganisationen' },
  { id: 94200, title: 'Arbeitnehmervereinigungen' },
  { id: 94900, title: 'Kirchliche,politi., sonst.Vereinigungen a.n.g.' },
  { id: 94910, title: 'Kirchliche u. sonstige religiöse Vereinigungen' },
  { id: 94920, title: 'Politische Parteien und Vereinigungen' },
  { id: 94990, title: 'Sonst.Interessenvertr. u.Vereinigungen a.n.g.' },
  { id: 94991, title: 'Organisation d.Bildung,Wissenschaft u.Forsch.' },
  { id: 94992, title: 'Organisationen der Kultur' },
  { id: 94993, title: 'Verbraucherorganisationen' },
  { id: 94994, title: 'Jugendorganisationen' },
  { id: 94999, title: 'Interessenvertretungen u. Vereinigungen a.n.g.' },
  { id: 95000, title: 'Rep.v.DV-Geräten und Gebrauchsgütern' },
  { id: 95100, title: 'Repa.v.Datenverarbeitungs-u.Telek.geräten' },
  { id: 95110, title: 'Repa.v.Datenverarbeitungs-u.peripheren Geräten' },
  { id: 95120, title: 'Reparatur von Telekommunikationsgeräten' },
  { id: 95200, title: 'Reparatur von Gebrauchsgütern' },
  { id: 95210, title: 'Repa.v.Geräten d.Unterhaltungselektronik' },
  { id: 95220, title: 'Repa.v.elektr.Haushaltsgeräten u.Gartengeräten' },
  { id: 95230, title: 'Reparatur von Schuhen und Lederwaren' },
  { id: 95240, title: 'Reparatur v.Möbeln u.Einrichtungsgegenständen' },
  { id: 95250, title: 'Reparatur von Uhren und Schmuck' },
  { id: 95290, title: 'Reparatur von sonstigen Gebrauchsgütern' },
  { id: 96000, title: 'Erbr.v.sonst.überwiegend persönlichen Dienstl.' },
  { id: 96010, title: 'Wäscherei und chemische Reinigung' },
  { id: 96020, title: 'Frisör- und Kosmetiksalons' },
  { id: 96021, title: 'Frisörsalons' },
  { id: 96022, title: 'Kosmetiksalons' },
  { id: 96030, title: 'Bestattungswesen' },
  { id: 96031, title: 'Bestattungsinstitute' },
  { id: 96032, title: 'Friedhöfe und Krematorien' },
  { id: 96040, title: 'Saunas, Solarien, Bäder u.áÄ.' },
  { id: 96090, title: 'Erbr.v.sonstigen Dienstl. a.n.g.' },
  { id: 97000, title: 'Private Haushalte' },
  { id: 98000, title: 'Herst.v.Waren,Erbr.v.Dienstl.d.priv.Haushalte' },
  { id: 98100, title: 'Herst.v.Waren d.priv.Haushalte f.d.Eigenbed.' },
  { id: 98200, title: 'Erbr.v.Dienstl.d.priv.Haushalte f.d.Eigenbed.' },
  { id: 99000, title: 'Exterritoriale Organisationen u.Körperschaften' },
  { id: 99999, title: 'Unbekannt - Gebrauch beschränkt auf Retail' },
];
