export const formatAutocompleteOptions = (options: { title: string }[]) =>
  options.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

export const formatProgramId = (programId: string) => {
  return programId
    .split('/')
    .map((id) => id.padStart(3, '0'))
    .join('/');
};

export const formatShortLink = (programId: string) => {
  return programId.split('/')[0];
};
