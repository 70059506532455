/**
 * __Context__
 * By providing a context you can differ translations. Eg. useful to provide gender specific translations.
 * @template `{
 *   "friend": "A friend",
 *   "friend_male": "A boyfriend",
 *   "friend_female": "A girlfriend",
 * }`
 * @example
 *  i18next.t('friend'); // -> "A friend"
 *  i18next.t('friend', { context: 'male' }); // -> "A boyfriend"
 *  i18next.t('friend', { context: 'female' }); // -> "A girlfriend"
 */

/**
 * __Name convention__
 * for label and tooltip: `${inputName}Label|Tooltip`
 * @template {} you can use snippet `i-locales:label&tooltip`
 * @template `{
 *   inputNameLabel: '',
 *   inputNameTooltip: ''
 * }`
 * @example
 * <FormNumberInput
 *   name="applicant.companyTotalAssets"
 *   label={t('oneApp.CompanySize.totalAssetsLabel')}
 *   tooltip={t('oneApp.CompanySize.totalAssetsTooltip')}
 * />
 */

import { Components } from '../../types/models';

const ageLookup: { [key in Components.Schemas.EnumCompanyAgeNone]: string } = {
  '<Jan19': 'Gründung zwischen 02.01.2019 und heute',
  '<3y': 'Älter als 02.01.2019 und maximal 3 Jahre',
  '3-5y': '3 bis 5 Jahre alt',
  '>5y': 'Älter als 5 Jahre',
  none: ' Bitte wählen Sie',
};

const employeeLookup: { [key in Components.Schemas.EnumEmployeesNone]: string } = {
  '<11': '10 oder weniger Mitarbeiter',
  '11-50': 'Mehr als 10 und bis 50 Mitarbeiter',
  '>50': 'Mehr als 50 Mitarbeiter',
  none: ' Bitte wählen Sie',
};

const companySizeLookup: { [key in Components.Schemas.EnumCompanySizeNone]: string } = {
  Großunternehmen: 'Großes Unternehmen',
  KMU: 'Kleines oder mittleres Unternehmen',
  none: ' Bitte wählen Sie',
};

const adressingLookup: { [key in Components.Schemas.EnumAddressingNone]: string } = {
  Frau: 'Frau',
  Herr: 'Herr',
  Divers: 'Divers',
  none: ' Bitte wählen Sie',
};

const salutationLookup: { [key in Components.Schemas.EnumAddressing]: string } = {
  Frau: 'Frau',
  Herr: 'Herr',
  OhneAngaben: 'Ohne Angaben',
};

// todo fix type once generated
const academicTitleLookup: { [key: string]: string } = {
  Dr: 'Dr.',
  Prof: 'Prof.',
  ProfDr: 'Prof. Dr.',
  none: ' Bitte wählen Sie',
};

// todo fix type once generated
const countryLookup: { [key: string]: string } = {
  none: ' Bitte wählen Sie',
  DE: 'Deutschland',
};

const naceLookup: { [key in Components.Schemas.EnumNaceNone]: string } = {
  Baugewerbe: 'Baugewerbe',
  'Chemische Industrie': 'Chemische Industrie',
  Fahrzeugbau: 'Fahrzeugbau',
  Gastgewerbe: 'Gastgewerbe',
  Handel: 'Handel',
  Maschinenbau: 'Maschinenbau',
  'Metallerzeugung und -bearbeitung': 'Metallerzeugung und -bearbeitung',
  'Papier-, Verlags- und Druckgewerbe': 'Papier-, Verlags- und Druckgewerbe',
  'Textil- und Bekleidungsgewerbe': 'Textil- und Bekleidungsgewerbe',
  Tourismus: 'Tourismus',
  'Verarbeitendes Gewerbe': 'Verarbeitendes Gewerbe',
  'Verkehr und Nachrichtenübermittlung': 'Verkehr und Nachrichtenübermittlung',
  Sonstige: 'Sonstige',
  none: ' Bitte wählen Sie',
};

const purposeLookup: { [key in Components.Schemas.EnumPurposeNone]: string } = {
  Betriebsmittel: 'Laufende Kosten (Betriebsmittel, z.B. Miete, Personalkosten, Warenlager)',
  Investitionen: 'Anschaffungen (Investitionen, z.B. Maschinen, Büroausstattung, Software)',
  'Betriebsmittel und Investitionen': 'Laufende Kosten und Anschaffungen',
  none: ' Bitte wählen Sie',
};

const durationLookup: { [key in Components.Schemas.EnumDurationNone]: string } = {
  '2/2/2':
    'Laufzeit bis zu 2 Jahre. Sie zahlen Ihren Kredit nicht regelmäßig zurück, sondern in einer Summe am Laufzeitende. Der Zinssatz ist fest.',
  '6/2/6':
    'Laufzeit bis zu 6 Jahre. Im 2. Jahren zahlen Sie nur Zinsen und keine Tilgung – das erhöht Ihren finanziellen Spielraum. Danach zahlen Sie den Kredit in regelmäßigen Raten zurück. Der Zinssatz ist fest.',
  '10/2/10':
    'Laufzeit 10 Jahre. Auf Wunsch zahlen Sie in den ersten beiden Jahren nur Zinsen, aber noch keine Tilgung - das erhöht Ihren finanziellen Spielraum. Danach zahlen Sie den Kredit in regelmäßigen Raten zurück. Der Zinssatz ist fest.',
  none: ' Bitte wählen Sie',
};

const liquidityNeedsLookup: { [key in Components.Schemas.EnumCompanySizeNone]: string } = {
  KMU: 'Liquiditätsbedarf 18 Monate (EUR)',
  Großunternehmen: 'Liquiditätsbedarf 12 Monate (EUR)',
  none: 'Liquiditätsbedarf 12 Monate (EUR)',
};

const programLookup: { [key in Components.Schemas.EnumProgram]: string[] } = {
  '37': [
    'accumulationDeclaration',
    'specialAdditionalInformation',
    'accumulationDeclarationFinal',
    'consentCreditRequest',
  ],
  '47': [
    'specialAdditionalInformation',
    'accumulationDeclaration',
    'accumulationDeclarationFinal',
    'KMUDeclaration',
    'consentCreditRequest',
  ],
  '73': [
    'specialAdditionalInformation',
    'accumulationDeclaration',
    'accumulationDeclarationFinal',
    'consentCreditRequest',
  ],
  '74': [
    'specialAdditionalInformation',
    'accumulationDeclaration',
    'accumulationDeclarationFinal',
    'KMUDeclaration',
    'consentCreditRequest',
  ],
  '78(300k)': [
    'additionalInformation',
    'accumulationDeclaration',
    'employeesNumberProof',
    'annualTurnoverProof',
    'profitProof',
  ],
  '78(500k)': [
    'additionalInformation',
    'accumulationDeclaration',
    'employeesNumberProof',
    'annualTurnoverProof',
    'profitProof',
  ],
  '78(800k)': [
    'additionalInformation',
    'accumulationDeclaration',
    'employeesNumberProof',
    'annualTurnoverProof',
    'profitProof',
  ],
};

const applicantTypeLookup: { [key: string]: string } = {
  singleEntrepreneur: 'Einzelunternehmer/Freiberufler',
  companies: 'Unternehmen',
};

const infoLookup: [{ id: string; text: string }] = [
  {
    id: '270',
    text: 'Hinweis: In dem von Ihnen ausgewählten Kreditprogramm sind private Antragsteller grundsätzlich zugelassen. Sofern dies auf Sie zutrifft, müssen Sie sich dennoch mit der Unternehmensform "Einzelunternehmer" erfassen. Nicht zutreffende Angaben (z.B. den Unternehmensnamen) können Sie einfach ungefüllt lassen bzw. ignorieren.',
  },
];

const categoryLookup: { [key: string]: string } = {
  credit: 'Kreditprodukt',
  grant: 'Zuschussprodukt',
};

const categoryKeyLookup: { [key: string]: string } = {
  credit: 'Kredit',
  grant: 'Zuschuss',
};

const applicantLookup: { [key: string]: string } = {
  localAuthority: 'Kommunale Gebietskörperschaften (inkl. deren rechtlich unselbst­ständigen Eigenbetriebe)',
  communityAssoc: 'Gemeindeverband inklusive kommunaler Zweckverband',
};

const accountingLookup: { [key: string]: string } = {
  cameralistic: 'kameralistische Rechnungslegung',
  double: 'doppische Rechnungslegung',
};

const yesOrNoLookup: { [key: string]: string } = {
  yes: 'Ja',
  no: 'Nein',
};

const investitionList: { id: string; title: string }[] = [
  { id: 'grunderwerb', title: 'Grunderwerb' },
  { id: 'bauliche_massnahmen', title: 'Bauliche Maßnahmen' },
  { id: 'maschinen_einrichtungen', title: 'Maschinen/Einrichtungen' },
  { id: 'sonstiges', title: 'Sonstiges' },
];

const investitionList432: { id: string; title: string }[] = [
  { id: 'kosten_konzepterstellung', title: 'Kosten Konzepterstellung' },
  { id: 'kosten_sanierungsmanagement', title: 'Kosten Sanierungsmanagement' },
];

const financingList: { id: string; title: string }[] = [
  { id: 'eigenmittel', title: 'Eigenmittel' },
  { id: 'oeffentliche_mittel', title: 'Öffentliche Mittel' },
  { id: 'sonstige_kredite_bankkredite', title: 'Sonstige Kredite/Bankkredite' },
];

const financingList432: { id: string; title: string }[] = [
  { id: 'beantragter_zuschuss_konzept', title: 'Beantragter Zuschuss Konzept' },
  { id: 'beantragter_zuschuss_sanierungsmanagement', title: 'Beantragter Zuschuss Sanierungsmanagement' },
  { id: 'sonstige_oeffentliche_mittel', title: 'Sonstige Öffentliche Mittel' },
  { id: 'eigenanteil', title: 'Eigenanteil' },
];

const registrationTypeLookup: { [key: string]: string } = {
  none: ' Bitte wählen Sie',
  commercialRegisterA: 'Handelsregister Abteilung A',
  commercialRegisterB: 'Handelsregister Abteilung B',
  cooperativeRegister: 'Genossenschaftsregister',
  taxNumber: 'Steuernummer',
  clubRegistration: 'Vereinsregister',
  partnershipRegister: 'Partnerschaftsregister',
};

const certificationTypeLookup: { [key: string]: string } = {
  none: ' Bitte wählen Sie',
  statute: 'Satzung',
  partnershipAgreement: 'Gesellschaftervertrag',
  licenseDocument: 'Konzessionsurkunde',
  legalOpinion: 'Legal Opinion',
  notaryContract: 'Notarieller Vetrag',
  other: 'Sonstiger',
};

const idTypeLookup: { [key: string]: string } = {
  none: ' Bitte wählen Sie',
  idCard: 'Personalausweis',
  passport: 'Reisepass',
  foreignId: 'Ausländisches Ausweisdokument',
};

const additionsLookup: { [key: string]: string } = {
  mobility: 'Mobilität',
  adaptationToClimateChange: 'Anpassung an den Klimawandel',
  digitalization: 'Digitalisierung',
};

const remediationsLookup: { [key: string]: string } = {
  official: 'Sachkundiger Beamter',
  redevelopmentAgency: 'Sanierungsträger',
  planningCommunity: 'Planungsgemeinschaft',
};

const commentSectionInfoLookup: [
  { id: string; text: string },
  { id: string; text: string },
  { id: string; text: string },
  { id: string; text: string },
  { id: string; title: string; description: string[] },
] = [
  {
    id: '375',
    text: 'Ihr Förderkredit kann nicht für Umschuldungen oder Ablösungen von anderen Krediten genutzt werden.',
  },
  {
    id: '376',
    text: 'Ihr Förderkredit kann nicht für Umschuldungen oder Ablösungen von anderen Krediten genutzt werden.',
  },
  {
    id: '365',
    text: 'Ihr Förderkredit kann nicht für Umschuldungen oder Ablösungen von anderen Krediten genutzt werden.',
  },
  {
    id: '366',
    text: 'Ihr Förderkredit kann nicht für Umschuldungen oder Ablösungen von anderen Krediten genutzt werden.',
  },
  {
    id: '79/89',
    title: 'Wichtige Voraussetzungen für die Erlangung der Förderung',
    description: [
      'Ihr Unternehmen war zum 31.12.2019 nicht in Schwierigkeiten.',
      'Ihr Förderkredit kann nicht für Umschuldungen oder Ablösungen von anderen Krediten genutzt werden.',
      'Ihr Unternehmen möchte ab dem Datum der Kreditantragstellung bis zum Ende der Kreditlaufzeit keine Gewinne oder Dividende ausschütten (Ausschlüsse s. Programm Merkblatt).',
      'Gegen Ihr Unternehmen bzw. damit verbundene Personen, Unternehmen, Organisationen oder Einrichtungen wurden keine EU Sanktionen verhängt (nähere Details sind dem Programm Merkblatt zu entnehmen).',
    ],
  },
];

export default {
  welcome: 'Welcome',
  htmlTitles: {
    oneAppPageTitle: 'Vorbereitung Ihres Kreditantrages | KfW-Förderassistent',
    oneAppSummaryPageTitle: 'Zusammenfassung Ihrer Angaben | KfW-Förderassistent',
    requestDetailPage: 'Anzeige Vorantrag | KfW-Förderassistent',
    creditRequests: 'Übersicht Ihrer Finanzierungsvorhaben | KfW-Förderassistent',
    newCreditRequestPage: 'Erstellen Neuer Corona-Vorantrag | KfW-Förderassistent',
    defaultPageTitle: 'Vorbereitung Kreditantrag | KfW-Corona-Hilfe',
  },
  creditRequests: {
    CreditRequestStepper: ['Erste Angaben', 'Finanzierung', 'Unternehmen', 'Unterlagen', 'Bestätigung'],
    GeneralInformation: {
      title: 'Bitte prüfen Sie die Angaben aus Ihrem Vorantrag Stufe 1. Bei Bedarf können Sie einzelne Angaben ändern.',
      ProgramSelection: {
        title: 'Kreditauswahl',
        age: 'Alter/Gründungsdatum',
        ageLookup,
        age_hint_1:
          'Wenn Ihr Unternehmen erst nach dem 1. Januar 2019 am Markt aktiv geworden ist, können Sie einen Kredit beantragen. Voraussetzung: Ihre Bank oder Sparkasse trägt das volle Risiko. Eine Alternative kann der  ',
        age_hint_2: 'sein. Informationen dazu finden Sie auf der verlinkten Seite.',
        age_hint_link: 'ERP-Gründerkredit - Startgeld',
        size: 'Größe Ihres Unternehmens',
        companySizeLookup,
        size_hint:
          'Kleine und mittlere Unternehmen haben i.d.R. bis zu 250 Mitarbeiter und bis zu 50 Mio. Euro Umsatz.',
        employees: 'Anzahl der Mitarbeiter',
        employeeLookup,
        action_selected: 'Ausgewählt',
        action_choose: 'Auswählen',
        programLiability: 'KfW übernimmt {0}% des Kreditausfallrisikos',
        programAdditionalInfo: 'Weitere Informationen zum Kredit',
      },
      PersonalInformation: {
        company_title: 'Erste Angaben zum Unternehmen',
        adressing: 'Anrede',
        adressingLookup,
        firstName: 'Vorname',
        lastName: 'Nachname',
      },
      CompanyInformation: {
        company: 'Firma',
        legal: 'Rechtsform',
        nace: 'Branche',
        naceLookup,
      },
      LocationInformation: {
        street: 'Straße',
        houseNumber: 'Hausnummer',
        postcode: 'PLZ',
        location: 'Ort',
      },
      ContactInformation: {
        email: 'E-Mail',
        phone: 'Telefon',
      },
      BankInformation: {
        bankinformationTitle: 'Erste Angaben zur Bank oder Sparkasse',
        bankName: 'Name der Bank oder Sparkasse',
        bankBIC: 'BIC der Bank oder Sparkasse',
      },
      CreditAmount: {
        annualSales2019: 'Jahresumsatz',
        payrolCosts2019: 'Lohnkosten',
        liquidityNeeds: 'Liquiditätsbedarf',
        creditAmount: 'Ihr gewünschter Kreditbetrag',
        creditOverMax: 'Ihr gewünschter Kreditbetrag ist höher als der maximal mögliche',
      },
      CreditAmountStandard: {
        creditCalculation_title: 'Erste Angaben zum Kreditwunsch',
        creditCalculation_warning: 'Bitte machen Sie mindestens eine der nächsten 3 Angaben',
        annualSales2019: 'Jahresumsatz 2019 (EUR)',
        payrolCosts2019: 'Lohnkosten 2019 (EUR)',
        liquidity_helper:
          'Zum Liquiditätsbedarf zählen alle anfallenden Kosten, z.B. Anschaffungen, Miete, Leasing und laufende Kredite, Personalkosten, Strom und Heizung, Kosten für Werbung und Beratung.',
        liquidityNeedsLookup,
        creditAmount: 'Ihr gewünschter Kreditbetrag (EUR)',
        credit_helper:
          'Gut zu wissen:  Wenn die Krise länger anhält, können Sie später weitere Anträge stellen. Und wenn die Krise doch schneller vorbei ist, können Sie auf nicht abgerufene Teilbeträge Ihres Kredits verzichten - ohne Kosten und Gebühren.',
        maxCreditAmount_calculated: 'Ihr maximal möglicher Kreditbetrag {{amount}} EUR',
        amount_above_25M:
          'Bitte beachten Sie: Der maximale Kreditbetrag bei Krediten über 25 Mio. Euro ist auf 50% der Gesamtverschuldung begrenzt.',
        max_credit_amount_standard:
          'Hinweis: Ihr gewünschter Kreditbetrag ist höher als der maximal mögliche. Der Maximalbetrag liegt bei 25 % des Jahresumsatzes oder den doppelten Personalkosten oder dem aktuellen Liquiditätsbedarf. Bitte passen Sie den Kreditbetrag entsprechend an. Wenn Sie mehr Geld brauchen, sprechen Sie bitte mit Ihrer Bank oder Sparkasse.',
      },
      CreditAmountFast: {
        creditCalculation_title: 'Erste Angaben zum Kreditwunsch',
        annualSales2019: 'Jahresumsatz 2019 (EUR)',
        credit_helper:
          'Gut zu wissen:  Wenn die Krise länger anhält, können Sie später weitere Anträge stellen. Und wenn die Krise doch schneller vorbei ist, können Sie auf nicht abgerufene Teilbeträge Ihres Kredits verzichten - ohne Kosten und Gebühren.',
        creditAmount: 'Ihr gewünschter Kreditbetrag (EUR)',
        maxCreditAmount_calculated: 'Ihr maximal möglicher Kreditbetrag {{amount}} EUR',
        max_credit_amount_schnell:
          'Hinweis: Ihr gewünschter Kreditbetrag ist höher als der maximal mögliche. Der Maximalbetrag liegt bei 25 % des Jahresumsatzes oder {{amount}} EUR. Bitte passen Sie den Kreditbetrag entsprechend an. Wenn Sie mehr Geld brauchen, sprechen Sie bitte mit Ihrer Bank oder Sparkasse.',
      },
      PurposeDuration: {
        purposeTitle: 'Bitte wählen Sie einen Verwendungszweck aus:',
        purpose: 'Verwendungszweck',
        purposeLookup,
        durationTitle: 'Bitte wählen Sie eine Kredit-Laufzeit aus:',
        duration: 'Kreditlaufzeit',
        durationLookup,
      },
    },
    FinancialDetails: {
      title: 'Bitte machen Sie hier genaue Angaben zu Ihrem Kredit und wie Sie das Geld verwenden möchten.',
      RequestIdentifier: {
        requestIdentifier_title: 'Bezeichnung',
        requestIdentifier_subtitle:
          'Bitte vergeben Sie einen Namen für Ihre Finanzierung, zum Beispiel „Corona-Hilfe für laufende Kosten“ (max. 150 Zeichen)',
        requestIdentifier: 'Name Ihrer Finanzierung',
        tooltip: {
          requestIdentifier:
            'Die Gesamtheit aller folgenden Angaben in diesem Formular wird als Finanzierung bezeichnet. An dieser Stelle vergeben Sie bitte einen aussagekräftigen Namen für Ihre Finanzierung mit maximal 150 Zeichen. Folgende Sonderzeichen sind erlaubt: - _ / & ? % ; : , = ! # @ ^ . + \\ $ * ( ) [ ] " < > \' ~',
        },
      },
      PartialAmount: {
        partial_amount_title: 'Investitionsplan',
        partial_amount_heading:
          'Die Summe Ihres geplanten KfW-Kredits beträgt {{amount}} EUR. Wie teilt sich diese Summe auf?',
        operatingFunds: 'Laufende Kosten (EUR)',
        investingFunds: 'Anschaffungen (EUR)',
        partialAmount_slider_1: '{{value}} Jahr',
        partialAmount_slider_multiple: '{{value}} Jahre',
        fundTypesNotWithinBounds:
          'Bitte füllen Sie beide Eingabefelder aus oder wählen Sie nur einen Verwendungszweck im vorherigen Schritt aus.',
        defaultChoices:
          'Bei den folgenden Konditionen haben Sie Wahlmöglichkeiten. Wenn Sie hier unsicher sind, übernehmen Sie einfach die Voreinstellungen und sprechen mit Ihrer Bankberaterin oder Ihrem Bankberater darüber.',
        creditduration: 'Kreditlaufzeit',
        freeRepaymentYears: 'Tilgungsfreie Zeit',
        fixedInterestYears: 'Zinsbindung',
        guaranteeRatio: 'Risikoübernahme',
        additionalFundingSources: 'Weitere finanzielle Mittel angeben?',
        guranteeRatio_marks: [
          {
            value: 0,
            label: '0%',
          },
          {
            value: 0.2,
            label: '20%',
          },
          {
            value: 0.4,
            label: '40%',
          },
          {
            value: 0.6,
            label: '60%',
          },
          {
            value: 0.8,
            label: '80%',
          },
          {
            value: 1,
            label: '100%',
          },
        ],
        tooltip: {
          operatingfunds: 'Laufende Kosten (Betriebsmittel, z.B. Miete, Personalkosten, Warenlager)',
          investingfunds: 'Anschaffungen (Investitionen, z.B. Maschinen, Büroausstattung, Software)',
          creditduration: 'Die Kreditlaufzeit ist die Zeit, innerhalb derer ein Kredit komplett zurückgezahlt wird',
          freeRepaymentYears:
            'Auf Wunsch zahlen Sie in dieser Zeit nur Zinsen, aber noch keine Tilgung – das erhöht Ihren finanziellen Spielraum ',
          fixedInterestYears: 'Zeitraum, in dem Ihr Zinssatz fest ist',
          guaranteeRatio:
            'Die KfW übernimmt diesen Anteil des Risikos von Ihrer Bank. Anzeige nur zur Info, keine Anpassung möglich.',
          additionalFinancialSources: 'Welche Mittel stehen Ihnen zur Verfügung, um die Coronakrise zu überbrücken?',
        },
      },
      PlannedProject: {
        planned_project_heading: 'Vorhaben',
        planned_project_subtitle:
          'Bitte passen Sie diese Angaben nur an, wenn es um Termine in der Vergangenheit geht. Die Abfrage hat rein förderrechtliche Gründe.',
        projectStart: 'Geplanter Vorhabensbeginn',
        projectProgress: 'Stand der Vorhabensdurchführung',
        firstFinancialDiscussion: 'Datum des ersten Finanzierungsgesprächs',
        projectNace: 'Branche Vorhaben',
        additionalAddressProvided: 'Setzen Sie den Kredit an einem anderen Ort als dem Unternehmensstandort ein?',
        addAddressStreet: 'Straße',
        addAddressNumber: 'Hausnummer',
        addAddressPostcode: 'PLZ',
        addAddressLocation: 'Ort',
        projectProgress_marks: [
          {
            value: 0,
            label: '0%',
          },
          {
            value: 0.25,
            label: '25%',
          },
          {
            value: 0.5,
            label: '50%',
          },
          {
            value: 0.75,
            label: '75%',
          },
          {
            value: 1,
            label: '100%',
          },
        ],
        tooltip: {
          projectStart: 'Ab wann möchten Sie diesen Kredit verwenden?',
          projectProgress: 'Wenn Sie den Kredit für künftige Ausgaben nutzen, wählen Sie bitte 0 % aus',
          firstFinancialDiscussion: 'Ändern Sie das Datum nur, falls schon ein Finanzierungsgespräch stattgefunden hat',
        },
      },
      AdditionalFinancialSources: {
        additionalFinancialSources_title: 'Weitere finanzielle Mittel (EUR)',
        additionalFinancialSources_warning:
          'Bitte machen Sie mindestens eine der nächsten 6 Angaben oder wählen Sie "Nein" im vorherigen Element aus.',
        equityCapital: 'Eigene Mittel (EUR)',
        bankLoans: 'Bankkredite (EUR)',
        publicFunds_title: 'Öffentliche Mittel',
        publicFundsJoinTask: 'Zuschuss aus Gemeinschaftsaufgabe (EUR)',
        publicFundsGrant: 'Investitionszulage (EUR)',
        publicFundsLoan: 'Kredite (EUR)',
        additionalCapital: 'Sonstige Mittel (EUR)',
        totalAdditionalFinancialSources: 'Finanzierungssumme gesamt (EUR) ',
      },
    },
    Applicant: {
      description: 'Bitte machen Sie hier weitere Angaben zu Ihrem Unternehmen bzw. zu Ihrer Person.',
      EmployeeDetails: {
        title: 'Mitarbeiter',
        description:
          'Bitte zählen Sie Ihre Arbeitskräfte, die zum 31.12.2019 einen Arbeitsvertrag hatten – einschließlich der Unternehmer/-in sowie Mitarbeitenden in Elternzeit. Leih- und Fremdarbeitende zählen nicht. Die  Umrechnung in Vollzeitkräfte wird automatisch durchgeführt.',
        thirtyPlus: 'Anzahl über 30 Std/Woche',
        twentyPlus: 'Anzahl bis 30 Std/Woche',
        twenty: 'Anzahl bis 20 Std/Woche',
        miniJobber: 'Anzahl auf 450 Euro Basis',
        trainees: 'Anzahl Auszubildende',
        Fte: 'Auf Vollzeitkräfte umgerechnet',
        FteStartInpu: 'wird berechnet',
        FteError:
          'Mit dem KfW-Schnellkredit werden Unternehmen und Selbstständige mit mehr als 10 Mitarbeitern gefördert.',
        tooltip: {
          thirtyPlus: 'Angabe der Mitarbeiteranzahl mit mehr als 30 Stunden Wochenarbeitszeit',
          twentyPlus: 'Angabe der Mitarbeiteranzahl mit bis zu 30 Stunden Wochenarbeitszeit',
          twenty: 'Angabe der Mitarbeiteranzahl mit bis zu 20 Stunden Wochenarbeitszeit',
          miniJobber: 'Angabe der Mitarbeiteranzahl mit einer Beschäftigung als 450-EUR-Minijob',
          trainees: 'Angabe der Mitarbeiteranzahl, die sich in der Berufsausbildung befinden',
          derivedFTE: 'Anzahl der Mitarbeiter',
          derivedFTEDetails:
            'Bitte zählen Sie Ihre Arbeitskräfte, die zum 31.12.2019 einen Arbeitsvertrag hatten – einschließlich der Unternehmer/-in und Mitarbeitenden in Elternzeit. Leih- und Fremdarbeitende zählen nicht. Teilzeitkräfte zählen wie folgt:',
          derivedFTEList1: 'bis 20 Stunden: 0,5',
          derivedFTEList2: 'bis 30 Stunden: 0,75',
          derivedFTEList3: 'über 30 Stunden und Auszubildende: 1',
          derivedFTEList4: '450-Euro-Jobber: 0,3',
        },
      },
      CompanyData: {
        title: 'Angaben zum Unternehmen',
        companyNace: 'Branche Unternehmen',
        foundingDate: 'Gründungsdatum',
        businesStartingDate: 'Aufnahme der Geschäftstätigkeit',
        customerIDAtBank: 'Kundennummer der Hausbank',
        founding: 'Unternehmen in Gründung?',
        inputTax: 'Vorsteuerabzugsberechtig?',
        origin: 'Sitz in welchem Land',
        companySplit: 'Liegt eine Betriebsaufspaltung zugrunde?',
        companySplitLable: 'Betriebsaufspaltung',
        tooltip: {
          businesStartingDate: 'Datum Ihres ersten Umsatzes',
          founding:
            'Befindet sich Ihr Unternehmen noch im Gründungsprozess? Beispiel: Vorgesellschaftsstatus, GmbH in Gründung',
          inputTax: 'Vorsteuerabzugsberechtig?',
          companySplit: {
            companySplit: 'Angabe, ob dem Vorhaben eine Betriebsaufspaltung zugrunde liegt.',
            companySplitDetails: 'Eine Echte Betriebsaufspaltung liegt vor, wenn:',
            companySplit1: 'mehrheitliche Inhaber-bzw. Gesellschafteridentität (mehr als 50 %) besteht und/oder',
            companySplit2:
              'die Gesellschafter beide Einheiten familiär (als Ehepartner bzw. Lebenspartner bzw. im Verwandtschaftsverhältnis 1. Grades) verbunden sind und/oder',
            companySplit3:
              'der Mehrheitsgesellschafter der Besitzgesellschaft - entweder selbst oder sein Ehepartner bzw. Lebenspartner bzw. ein Verwandter 1. Grades - mindestens 10% Anteile an der Betriebsgesellschaft hält und in dieser auch Geschäftsführer ist.',
          },
        },
      },
      SingleEntrepreneur: {
        title: 'Weitere Angaben für Einzelunternehmer',
        birthName: 'Geburtsname',
        birthDate: 'Geburtsdatum',
        placeOfBirth: 'Geburtsort',
        nationality: 'Staatsangehörigkeit',
        maritalStatus: 'Familienstand',
        tooltip: {
          birthName: 'Angabe des Geburtsnamens, sofern relevant',
        },
      },
      AddInfoPersonalCapitalCompanies: {
        title: 'Weitere Angaben zum Unternehmen',
        companyType: 'Es liegt eine {0}gesellschaft vor?',
        companySplit: 'Liegt eine Betriebsaufspaltung zugrunde?',
        partOfCompany: 'Konzernzugehörigkeit?',
        nonProfit: 'Gemeinnützig tätig?',
        entrepreneurial: 'Unternehmerisch tätig?',
        citBObligatory: 'Antragsteller Körperschaftssteuerpflichtig?',
        CITFreed: 'Unternehmen von Körperschaftssteuer befreit?',
        participationOfBankStatus: 'Beteiligung einer Finanzinstitution >25%?',
        tooltip: {
          partOfCompany:
            'Das Unternehmen gehört einem Konzern an, wenn es unter der einheitlichen Leitung eines herrschenden Unternehmens steht.',
          nonProfit:
            'Dies trifft in der Regel auf Unternehmen in der Rechtsform von Kapitalgesellschaften, Genossenschaften und eingetragenen Vereinen (e.V.) zu, deren Tätigkeit unmittelbar und ausschließlich gemeinnützige Zwecke umfassen.',
          entrepreneurial:
            'Dies ist der Fall, wenn die Stiftung ein Unternehmen betreibt, also selbst unternehmerisch tätig ist, z.B. in der Rechtsform Stiftung & Co. KG',
          citBObligatory:
            'Als Körperschaftsteuer wird die Steuer auf das Einkommen von juristischen Personen bezeichnet. Dazu zählen beispielsweise Kapitalgesellschaften wie die GmbH oder AG',
          participationOfBankStatus:
            'Angabe, ob eine Finanzinstitution (Kreditinstitut, Versicherung oder sonstige Finanzinstitution) an Ihrem Unternehmen beteiligt ist und ob die Beteiligung höher als 25 % ist',
        },
      },
      AccountInformation: {
        title: 'Kontoinformation',
        description: 'Auf welches Konto soll der Kredit ausgezahlt werden?',
        ibanPaymentAccount: 'Auszahlungskonto IBAN',
        bicPaymentAccount: 'Auszahlungskonto BIC',
        bankNamePaymentAccount: 'Auszahlungskonto Name der Bank',
        paymentAccountIsDepositAccount: 'Auszahlungskonto entspricht dem späteren Einzugskonto?',
        ibanDepositAccount: 'Einzugskonto IBAN',
        bicDepositAccount: 'Einzugskonto BIC',
        bankNameDepositAccount: 'Einzugskonto Name der Bank',
      },
      Legitimation: {
        title: 'Legitimation',
        useIDCard: 'Ausweis zur Legitimation nutzen?',
        idType: 'Ausweistyp',
        idNumber: 'Ausweisnummer',
        validTo: 'Gültig bis',
        issuingAuthority: 'Ausstellende Behörde',
        idPlaceOfIssue: 'Ausstellungsort',
        idDateOfIssue: 'Ausstellungsdatum',
        valueAddedTaxID: 'Umsatzsteuer-Identifikationsnummer (USt-IdNr.)',
        useRegistryEntry: 'Registereintrag zur Legitimation nutzen?',
        registryType: 'Registerart',
        registryNumber: 'Registernummer',
        placeOfRegistry: 'Ort des Registergerichts',
        countryOfRegistry: 'Land des Registergerichts',
        useBusinessLicence: 'Gewerbeschein zur Legitimation nutzen?',
        businessLicenceDateOfIssue: 'Gewerbeschein Ausstellungsdatum',
        businessLicencePlaceOfIssue: 'Gewerbeschein Ausstellungsort',
        useCertificate: 'Urkunde zur Legitimation nutzen?',
        certificateType: 'Art der Urkunde',
        otherProofOfIdentityType: 'Art des sonstigen Legitimationsausweises',
        certificateNumber: 'Urkundennummer',
        DateOfCertificate: 'Datum der Urkunde',
        nameOfCertificateNotary: 'Name des Notars',
        certificateNotaryOffice: 'Sitz des Notars',
      },
      SmallMediumBusinesses: {
        title: 'KMU-Erklärung',
        annualTurnover: 'Jahresumsatz 2019 (EUR)',
        totalAssets: 'Bilanzsumme 2019 (EUR)',
        dateAnnualAccounts: 'Datum letzter Jahresabschluss',
        fulfillsSMBDefinition: 'Unternehmen entspricht der KMU-Definition*',
        employeeNumber: 'Anzahl Mitarbeiter',
        tooltip: {
          dateAnnualAccounts:
            'Bitte erfassen Sie hier das Datum Ihres letzten Jahresabschlusses. Bei einem neu gegründeten Unternehmen, für das noch kein vollständiger Abschluss vorliegen kann, geben Sie bitte das Datum Ihrer Planzahlen an (dies sollte maximal im übernächsten Jahr liegen).',
          annualTurnover:
            'Erfassen Sie bitte die Umsatzerlöse, die Ihr Unternehmen während des angegebenen Zeitraums erzielt hat. Die Mehrwertsteuer und andere indirekte Steuern sollten nicht in den Umsatz einfließen.',
          totalAssets:
            'Bitte erfassen Sie hier die Bilanzsumme Ihres letzten Jahresabschlusses. Die Bilanzsumme ist der Schlusswert der Aktiva- und Passiva-Seite der Bilanz. Die Jahresbilanzsumme bezieht sich auf die Hauptvermögenswerte Ihres Unternehmens.',
          fulfillsSMBDefinition:
            'Kleine und mittlere Unternehmen haben i.d.R. bis zu 250 Mitarbeiter und bis zu 50 Mio. Euro Umsatz',
        },
      },
    },
    DocumentManagement: {
      titleTemp: 'Unterlagen für Ihr Bankgespräch',
      titleMainTemp:
        'Ihre Bank benötigt die folgenden Unterlagen, um Ihren Kreditantrag zu erstellen. Stellen Sie Ihrer Bankberaterin oder Ihrem Bankberater bitte diese Unterlagen zur Verfügung.',
      descriptionTemp: ' ',
      programLookup,
      docTypes: {
        additionalInformation: 'Formular Ergänzende Angaben Sondermaßnahme Corona-Hilfe ',
        accumulationDeclaration: 'Formular Kumulierungserklärung Kleinbeihilfen',
        employeesNumberProof: 'Nachweis Anzahl der Mitarbeiter',
        annualTurnoverProof: 'Nachweis Jahresumsatz',
        profitProof: 'Nachweis Gewinnerzielung',
        specialAdditionalInformation: 'Ergänzende Angaben Sondermaßnahme Corona-Hilfe',
        accumulationDeclarationFinal: 'Kumulierungserklärung des Endkreditnehmers / Beteiligungsnehmers',
        KMUDeclaration: 'KMU-Erklärung',
        consentCreditRequest: 'Einwilligung für Auskunfteianfragen',
      },
      tooltip: {
        additionalInformation: {
          bodyBeforeLink: 'Bitte laden Sie dazu das',
          link_1:
            'https://www.kfw.de/PDF/Download-Center/Förderprogramme-(Inlandsförderung)/PDF-Dokumente/6000004524_F_078_ergaenzende_angaben_schnellkredit.pdf',
          bodyLink_1: 'Formular Ergänzende Angaben zum Antrag',
          bodyAfterLink: 'herunter.',
          steps: [
            'Seiten 1 und 2: Lesen Sie die Informationen sorgfältig durch und unterschreiben Sie auf Seite 2.',
            'Seiten 3 bis 6: Diese Informationen sind vor allem für Ihre Bank oder Sparkasse gedacht, die diese Seiten ausfüllt.',
          ],
          afterSteps: ' ',
          afterStepsOriginal: 'Laden Sie das auf Seite 2 unterschriebene Formular hier hoch:',
        },
        accumulationDeclaration: {
          bodyBeforeLink: 'Bitte laden Sie dazu das',
          link_1:
            'https://www.kfw.de/PDF/Download-Center/Förderprogramme-(Inlandsförderung)/PDF-Dokumente/6000004526_F_078_Kumulierungserklärung_Kleinbeihilfen.pdf',
          bodyLink_1: 'Formular Kumulierungserklärung Kleinbeihilfen',
          bodyAfterLink:
            'herunter. Lesen Sie die Informationen sorgfältig durch, füllen Sie das Formular aus und unterschreiben Sie auf Seite 2.',
          bodyAfterLinkOriginal:
            'herunter. Lesen Sie die Informationen sorgfältig durch, füllen Sie das Formular aus und unterschreiben Sie auf Seite 2. Laden Sie das unterschriebene Formular hier hoch:',
        },
        employeesNumberProof: {
          bodyBeforeLink:
            'Bitte stellen Sie einen Nachweis für Ihre angegebene Mitarbeiterzahl zum 31.12.2019 zur Verfügung. Sie können aus folgenden Nachweisen wählen:',
          bodyBeforeLinkOriginal:
            'Bitte laden Sie hier einen Nachweis der angegebenen Mitarbeiterzahl zum 31.12.2019. Sie können aus folgenden Nachweisen wählen:',
          steps: [
            'Jahresabschluss oder',
            'Lohn- und Gehaltsunterlagen oder',
            'Angaben der gesetzlichen Unfallversicherung oder',
            'Bestätigung des Steuerberaters',
          ],
        },
        annualTurnoverProof: {
          bodyBeforeLink:
            'Bitte stellen Sie einen Nachweis für Ihren Jahresumsatz 2019 zur Verfügung. Sie können aus folgenden Nachweisen wählen:',
          bodyBeforeLinkOriginal:
            'Bitte laden Sie hier einen Nachweis des angegebenen Jahresumsatzes 2019. Sie können aus folgenden Nachweisen wählen:',
          steps: [
            'Jahresabschluss oder',
            'Einnahme-Überschussrechnung oder',
            'Betriebswirtschaftliche Auswertung (BWA) per 12/2019 oder',
            'Betriebswirtschaftliche Auswertung per 06/2019 (falls noch keine BWA 12/2019 vorliegt)',
          ],
        },
        profitProof: {
          bodyBeforeLink:
            'Bitte stellen Sie einen Nachweis zur Verfügung, dass Sie im Geschäftsjahr 2019 einen Gewinn erzielt haben – oder in der Summe der letzten 3 Geschäftsjahre (2017–2019). Besteht Ihr Unternehmen nicht so lange, gilt der Zeitraum Ihres Bestehens. Sie können aus folgenden Nachweisen wählen:',
          bodyBeforeLinkOriginal:
            'Bitte laden Sie hier einen Nachweis dafür hoch, dass Sie im Geschäftsjahr 2019 einen Gewinn erzielt haben – oder in der Summe der letzten 3 Geschäftsjahre (2017–2019). Besteht Ihr Unternehmen nicht so lange, gilt der Zeitraum Ihres Bestehens. Sie können aus folgenden Nachweisen wählen:',
          steps: [
            'Gewinn- und Verlustrechnung oder',
            'Einnahmenüberschussrechnung oder',
            'Betriebswirtschaftliche Auswertung',
          ],
        },
        specialAdditionalInformation: {
          link_1:
            'https://www.kfw.de/PDF/Download-Center/F%C3%B6rderprogramme-(Inlandsf%C3%B6rderung)/PDF-Dokumente/6000004517_F_Erg%C3%A4nzende_Angaben_Coronahilfen.pdf',
          bodyLink_1: 'Dieses Formular ist vor allem für Ihre Bank oder Sparkasse gedacht, die diese Seiten ausfüllt.',
        },
        accumulationDeclarationFinal: {
          link_1:
            'https://www.kfw.de/Download-Center/F%C3%B6rderprogramme-(Inlandsf%C3%B6rderung)/PDF-Dokumente/6000000067-Kumulierungserkl%C3%A4rung-des-EKN.pdf',
          bodyLink_1: 'Bestätigung zur Einhaltung der Beihilfeobergrenze',
        },
        KMUDeclaration: {
          bodyBeforeLink: 'Als Unternehmen ohne Verflechtungen mit anderen Unternehmen nutzen Sie bitte das',
          link_1:
            'https://www.kfw.de/Download-Center/F%C3%B6rderprogramme-(Inlandsf%C3%B6rderung)/PDF-Dokumente/6000000095-Vereinfachte-Selbsterkl%C3%A4rung-KMU.pdf',
          bodyLink_1: 'Formular "Vereinfachte Selbsterklärung KMU"',
          bodyBetweenLinks: '. Als Unternehmen mit Verflechtungen zu anderen Unternehmen nutzen Sie bitte das',
          link_2:
            'https://www.kfw.de/Download-Center/F%C3%B6rderprogramme-(Inlandsf%C3%B6rderung)/PDF-Dokumente/6000000196-KMU-Definition.pdf',
          bodyLink_2: ' Formular Selbsterklärung KMU',
          bodyAfterLink:
            '. Lesen Sie die Informationen sorgfältig durch, füllen Sie das Formular aus und unterschreiben Sie es.',
          bodyAfterLinkOriginal:
            '. Lesen Sie die Informationen sorgfältig durch, füllen Sie das Formular aus und unterschreiben Sie es. Bitte laden Sie das unterschriebene Formular hier hoch.',
        },
        consentCreditRequest: {
          bodyBeforeLink: 'Bitte laden Sie das',
          link_1:
            'https://www.kfw.de/PDF/Download-Center/Förderprogramme-(Inlandsförderung)/PDF-Dokumente/6000000106_F_Einwilligungserklaerung.pdf',
          bodyLink_1: 'Formular Einwilligung für Auskunfteianfrag',
          bodyAfterLink:
            'herunter. Lesen Sie die Informationen sorgfältig durch, füllen Sie das Formular aus und unterschreiben Sie es.',
          bodyAfterLinkOriginal:
            'herunter. Lesen Sie die Informationen sorgfältig durch, füllen Sie das Formular aus und unterschreiben Sie es. Bitte laden Sie das unterschriebene Formular hier hoch:',
        },
      },
    },
    ReviewData: {
      title_line1: 'Vielen Dank!',
      title_line2: 'Sie haben alle Angaben gemacht und sind damit richtig gut vorbereitet.',
      data_explanation_subtext:
        'Zum Schluss erhalten Sie eine Zusammenfassung Ihrer Angaben als PDF, die Sie an Ihre Bankberaterin oder Ihren Bankberater weiterleiten können. Wenn Sie nachträglich noch einzelne Angaben anpassen wollen, besprechen Sie das einfach mit Ihrer Bankberaterin oder Ihrem Bankberater.',
      data_protection_header_part1: 'Ich nehme die ',
      data_protection_header_linktext: 'Datenschutzhinweise',
      data_protection_header_part2:
        ' zur Kenntnis und verstehe, dass der KfW-Förderassistent nur eine unverbindliche Vorbereitung eines späteren Antrags bei Bank oder Sparkasse durch mich darstellt.*',
      data_protection_link:
        'https://www.kfw.de/PDF/Download-Center/F%C3%B6rderprogramme-(Inlandsf%C3%B6rderung)/PDF-Dokumente/Datenschutz/6000004294_D_037_047_058_067_73-76.pdf',
      data_protection_details_part1:
        'Ja, ich habe verstanden, dass die Nutzung dieser Webseite unverbindlich ist. Dieses Formular ist kein Antrag an die KfW oder an eine Bank oder Sparkasse. Eine Prüfung meiner Eingaben seitens der KfW erfolgt nicht. Die mir zur Verfügung gestellte Zusammenfassung meiner Eingaben stellt keine Entscheidung der KfW über das Vorliegen der Fördervoraussetzungen dar. Sie stellt kein Angebot und auch keine sonstige verbindliche Zusage der KfW dar, sondern soll mir lediglich helfen, den von mir bei der Bank oder Sparkasse zu stellenden Antrag vorzubereiten.',
      data_protection_details_part2:
        'Ja, ich nehme zur Kenntnis, dass meine personenbezogenen Daten in diesem Rahmen verarbeitet werden und zur Weiterverwendung im Rahmen einer Antragsstellung durch meine Bank an diese übermittelt werden können. Eine Übermittlung meiner Daten findet erst bei Übergabe des KfW-internen Dokumenten-Codes an meine Bank durch mich selbst statt. Alles Weitere ist in den ',
      data_protection_details_linktext: 'produktspezifischen Datenschutzhinweisen',
      data_protection_details_part3: ' geregelt.',
      data_usage_header: 'Ich willige in die Verwendung der Daten durch die KfW ein.',
      data_usage_details_1:
        'Ich willige hiermit ein, dass die KfW oder ein von ihr beauftragter Dritter mich postalisch oder per E-Mail kontaktieren und meine im Formular eingegebenen Daten verarbeiten darf. Die Verarbeitung meiner Daten für die Kontaktaufnahme erfolgt ausschließlich zu dem Zweck, dieses Formular und seine Einsatzmöglichkeiten mit Hilfe meiner Erfahrungen und Einschätzungen verbessern zu können. Meine Einwilligung erfolgt auf freiwilliger Basis und ich kann sie jederzeit mit Wirkung für die Zukunft widerrufen. Ab Zugang der Widerrufserklärung dürfen meine Daten nicht mehr von der KfW für den vorgenannten Zweck weiterverarbeitet werden. Sie sind in diesem Fall unverzüglich zu löschen. Durch den Widerruf meiner Einwilligung wird die Rechtmäßigkeit der bis dahin erfolgten Verarbeitung nicht berührt.',
      data_usage_details_2:
        'Meine Widerrufserklärung kann ich jederzeit formfrei an die KfW richten: Per Post an KfW Bankengruppe, z.Hd. Widerspruchsstelle, Ludwig-Erhard-Platz 1-3 in 53179 Bonn oder per Mail an info@kfw.de. Ihr Feedback ist für uns sehr wichtig, damit wir unsere Aufgaben zur Unterstützung der Wirtschaft noch besser erfüllen können. Eine Auswertung der erhobenen Daten durch die KfW erfolgt ausschließlich in anonymisierter Form.',
      ReviewDataIsValid: {
        dsgvoConfirmation: 'Datenschutzhinweise',
      },
    },
  },
  creditRequestEdit: {
    buttons: {
      proceed: 'Änderungen übernehmen',
      discardChanges: 'Änderungen verwerfen',
      next: 'Weiter',
      previous: 'Zurück',
      save: 'Speichern',
      cancel: 'Abbrechen',
      submit: 'Zur Zusammenfassung',
      toOverview: 'Übersicht',
      optionYes: 'ja',
      optionNo: 'nein',
      accept: 'Angaben übernehmen',
    },
    modals: {
      changesWithDependency: {
        title: 'Auswirkung der Änderung bestimmter Felder',
        description:
          'Durch Ihre Änderung bestimmter Felder auf der aktuellen Seite, sind auch Felder auf den nachfolgenden Seiten der Anwendung betroffen. Davon betroffene Felder werden systemseitig auf einen gültigen Standard-Wert zurückgesetzt. Nicht davon betroffene Felder bleiben von dieser Änderung unberührt. Sie müssen die folgenden Schritte erneut durchlaufen. Bitte prüfen Sie Ihre Angaben und passen Sie diese bei Bedarf an.',
      },
    },
  },
  creditRequestList: {
    buttons: {
      newRequest: 'Vorantrag erstellen',
    },
    title: 'KfW-Förderassistent',
    informationTitle: 'Schritt für Schritt zu Ihrem Kreditantrag:',
    informationTitle_name: 'Herzlich willkommen {{name}}, so gehen Sie besser vorbereitet ins Bankgespräch',
    tableTitle_0: 'Finanzierungsvorhaben anlegen:',
    tableTitle_1: 'Ihr Finanzierungsvorhaben:',
    tableTitle_2: 'Ihre Finanzierungsvorhaben:',
    noCreditRequest:
      'Sie haben noch keine Voranträge. Klicken Sie auf "Vorantrag erstellen" um einen neuen Vorantrag anzulegen.',
    table: {
      name: 'Name',
      status: 'Status',
      amount: 'Kreditbetrag',
      progress: 'Fortschritt',
      lastModification: 'Date of last modification',
      complete: 'Angaben vervollständigen',
    },
    defaultName: 'Neues Finanzierungsvorhaben',
    planDetailsTitle: 'Finanzierungsvorhaben hinzufügen:',
    planDetailsContent:
      'Hier können Sie Ihre bestehende Eingaben übernehmen. Geben Sie dafür den KfW Dokumenten-Code von Ihrer Bestätigung und Ihre Postleitzahl ein. Sie haben noch keinen Code? Erzeugen Sie Ihren persönlichen Code zuerst unter corona.kfw.de',
    planDetailsDocCodeField: 'KfW Dokumenten-Code',
    planDetailsPostCode: 'Postleitzahl',
    modals: {
      deleteOneAppRequest: {
        header: 'Bitte bestätigen Sie die Löschung Ihres Vorantrages',
        content: 'Möchten Sie Ihren Vorantrag "{{requestIdentifier}}" unwiderruflich löschen?',
        cancel: 'Abbrechen',
        confirm: 'Löschen',
      },
    },
  },
  summaryScreen: {
    title: 'Vielen Dank für die Übermittlung Ihrer Daten',
    subtitle: 'Hier können Sie Ihre zusammengefassten Angaben als PDF-Dokument speichern:',
    pdf_download: 'Download als PDF',
    code: 'Vorantrags-Code Stufe 2: ',
    oneApp_code: 'Ihr Vorantrags-Code: ',
    nextsteps_title: 'So geht es weiter:',
    nextsteps_subtitle: 'Stellen Sie Ihren Antrag bei einer Bank oder Sparkasse Ihrer Wahl',
    nextsteps_header_1: 'Nehmen Sie Kontakt zu einer Bank oder Sparkasse auf',
    nextsteps_details_1:
      'Das kann Ihre bestehende Bank sein, aber auch jede andere Geschäftsbank, Sparkasse oder Genossenschaftsbank.',
    nextsteps_header_2:
      'Leiten Sie die Zusammenfassung Ihrer Angaben und die Unterlagen weiter an Ihre Bankberaterin oder Ihren Bankberater ',
    nextsteps_details_2:
      'Dafür können Sie Ihre Angaben und Unterlagen z.B. als PDF per E-Mail versenden oder Ausdrucke zum Bankgespräch mitnehmen.',
    nextsteps_header_3: 'Beantragen Sie Ihren Förderkredit',
    nextsteps_details_3: 'Ihre Bankberaterin oder Ihr Bankberater erstellt den Antrag für Sie.',
    nextsteps_header_4: 'Ihre Bank oder Sparkasse prüft Ihren Antrag ',
    nextsteps_details_4:
      'Ihre Bankberaterin oder Ihr Bankberater prüft Ihre Angaben. Ist alles in Ordnung, wird der Antrag direkt an die KfW weitergeleitet.',
    nextsteps_header_5: 'Schließen Sie den Kreditvertrag ab und verwenden Sie das Geld',
    nextsteps_details_5:
      'Jetzt können Sie den Kreditvertrag unterzeichnen und Sie bekommen das Geld schon bald auf Ihr Konto ausgezahlt.',
  },
  footer: {
    accessibility: 'Barrierefreiheit',
    dsgvo: 'Datenschutz',
    legal: 'Rechtliche Hinweise',
    impressum: 'Impressum',
    copyright: '{{year}} KfW, Frankfurt am Main',
  },
  snackbar: {
    close: 'Schließen',
    messages_in_queue: '{{count}} mehr Nachrichten',
    errors: {
      400: 'Validierungsfehler beim Versenden der Anfrage. Bitte korrigieren Sie die Fehler.',
      403: 'Ihre Anfrage enthält unerlaubte Zeichenfolgen und kann nicht verarbeitet werden. Bitte korrigieren Sie die Fehler.',
      unspecific: 'Fehler beim Versenden der Anfrage. Bitte versuchen Sie es später noch einmal.',
      // PRE_CREDIT_INVALID_PROGRAM
      E00000001:
        'Das ausgewählte Programm des Vorantrags wird aktuell nicht im KfW-Förderassistenten unterstützt (ERP-Gründerkredit – Universell 075/076)',
      // PRE_CREDIT_ALREADY_EXISTING_REQUEST
      E00000002: 'Es wurde bereits ein Vorantrag importiert, aktuell können keine weiteren Anträge importiert werden',
      // PRE_CREDIT_REQUEST_NOT_FOUND
      E00000003:
        'Der Vorantrag des KfW-Förderassistenten Stufe 1 wurde nicht gefunden. Bitte korrigieren Sie die Fehler.',
      // PRE_CREDIT_FAILED_TO_CONNECT
      E00000004: 'Es konnte keine Verbindung zum KfW-Förderassistenten Stufe 1 hergestellt werden',
      // UPDATE_CREDIT_DRAFT_MAX_DATE_FOUNDING_DATE
      E00000005: 'Das gewählte Datum "Gründungsdatum" muss in der Vergangenheit liegen.',
      // UPDATE_CREDIT_DRAFT_MAX_DATE_BUSINESS_STARTING_DATE
      E00000006: 'Das gewählte Datum "Aufnahme der Geschäftstätigkeit" muss in der Vergangenheit liegen.',
      // UPDATE_CREDIT_DRAFT_PREVIOUS_STEP_NOT_FILLED
      E00000007:
        'Sie können nicht zur gewählten Seite gelangen, weil die dazwischen liegenden Seiten noch nicht ausgefüllt oder invalidiert worden sind. Bitte füllen Sie zunächst die aktuelle und die folgenden Seiten aus.',

      // UPDATE_CREDIT_DRAFT_INVALID_IBAN_PAYMENT_ACCOUNT
      E00000009: 'Ungültige Auszahlungskonto - IBAN',
      // UPDATE_CREDIT_DRAFT_INVALID_IBAN_DEPOSIT_ACCOUNT
      E00000010: 'Ungültige Einzahlungskonto - IBAN.',
      // PRE_CREDIT_MAXIMUM_IMPORT_ATTEMPTS_EXCEEDED
      E00000011:
        'Sie haben die maximale Anzahl von Versuchen erreicht. Aus Sicherheitsgründen können Sie keine weiteren Anfragen mehr senden.',
      // PRE_CREDIT_REQUEST_EMAILS_DO_NOT_MATCH
      E00000012:
        'Der Vorantrag des KfW-Förderassistenten Stufe 1 wurde nicht gefunden. Bitte korrigieren Sie die Fehler.',
    },
  },
  validation: {
    maximumCharacters: 'Sie haben die maximale Zeichenanzahl erreicht.',
    invalidPurposeCombination:
      'Es sind nur Kosten zu entweder Sanierungsmanager, Verstetigung oder Konzept förderfähig.',
    invalidSumOfPlans:
      'Der beantragte Zuschussbetrag im übersteigt die angegebenen Kosten. Diese Kombination ist nicht förderfähig.',
    onePurposeAllowed: 'Das beantragte Produkt ist nur mit einem Verwendungszweck förderfähig.',
    onePurposeAllowed2:
      'Sie können mit dem Gebäude nur eine Effizienzgebäudeklasse erreichen. Die derzeitige Konstellation ist nicht förderfähig.',
    onlyAllowedCharacters: 'Bitte benutzen Sie nur erlaubte Zeichen.',
    emptyField: 'Bitte tätigen Sie eine Eingabe.',
    invalidField_text: 'Feld "{{field}}" ist ungültig.',
    dateMustBeInPast: 'Das gewählte Datum "{{field}}" muss in der Vergangenheit liegen.',
    invalidField_default: 'Ein oder mehrere Felder ungültig.',
    multipleFieldsInvalid_text: 'Mehrere Felder sind ungültig:',
    invalidCheckbox_text: 'Feld "{{field}}" muss bestätigt werden',
    multipleCheckboxesInvalid_text: 'Mehrere Felder müssen bestätigt werden:',
    invalidEmailFormat: 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
    invalidPhoneFormat: 'Bitte geben Sie eine gültige Telefonnummer ein.',
    invalidIbanFormat: 'Bitte geben Sie eine gültige IBAN ein.',
    invalidBicFormat: 'Bitte geben Sie eine gültigen BIC ein.',
    invalidPostCodeFormat: 'Bitte geben Sie eine gültige PLZ ein.',
    invalidHouseNumber: 'Die maximale Länge der Hausnummer ist erreicht.',
    invalidAddress: 'Bitte geben Sie die Investitionsadresse bitte möglichst vollständig an.',
    invalidPopulationDate:
      'Das derzeitige Datum liegt in der Zukunft. Der Stichtag für den Stand der Einwohner muss in der Vergagenheit liegen oder tagesaktuell sein.',
    invalidAccountingDate:
      'Das derzeitige Datum liegt in der Zukunft. Der Stichtag für die Rechnungslegung muss in der Vergangeheit liegen oder tagesaktuell sein.',
  },
  modals: {
    confirmationModal: {
      confirm: 'OK',
      cancel: 'Abbrechen',
    },
  },
  common: {
    onlyAllowedCharacters: 'Bitte benutzen Sie nur erlaubte Zeichen.',
  },
  autocompleteField: {
    noOptions: 'Keine Auswahlmöglichkeit',
  },
  menu: {
    profile: 'Kontoinformationen',
    termsOfUse: 'Nutzungsbedingungen',
    logout: 'Abmelden',
    deleteAccount: 'Konto löschen',
  },
  deleteProfile: {
    windowTitle: 'Konto löschen | KfW-Förderassistent',
    title: 'KfW-Förderassistent-Konto löschen',
    description:
      'Diese Funktionalität wird Ihr Konto und alle verknüpften Daten im KfW-Förderassistenten löschen. Im ersten Schritt werden automatisiert alle Finanzierungsanfragen aus unserer Datenbank unwiderruflich gelöscht. Danach werden Sie zu einer Seite weitergeleitet, auf der Sie sich noch einmal einloggen und danach die Löschung Ihres Accounts bestätigen müssen.',
    description_confirm: 'Bitte bestätigen Sie, wenn Sie fortfahren wollen.',
    buttons: {
      overview: 'Zurück',
      delete: 'Daten & Konto löschen',
    },
  },
  summaryScreenOneApp: {
    title: 'Ihr Vorantrag zum Finanzierungsvorhaben "{{projectName}}"',
    subtitle:
      'Mit Ihren Angaben sind Sie nun bestens auf das Beratungsgespräch mit Ihrem Finanzierungspartner vorbereitet. Speichern Sie jetzt Ihre zusammengefassten Angaben als PDF-Dokument:',
    code: 'Vorantrags_code',
    pdf_download: 'PDF speichern',
    nextsteps_title: 'So geht es weiter:',
    nextsteps_subtitle:
      'Stellen Sie Ihren Antrag bei Ihrem Finanzierungspartner – also bei einer Bank oder Sparkasse Ihrer Wahl',
    nextsteps_header_1: 'Nehmen Sie Kontakt zu einer Bank oder Sparkasse auf',
    nextsteps_details_1:
      'Das kann Ihre bestehende Bank sein, aber auch jede andere Geschäftsbank, Sparkasse oder Genossenschaftsbank. Suchen Sie noch eine Bank oder Sparkasse als Finanzierungspartner? ',
    nextsteps_link_1: 'Finanzierungspartner suchen',
    nextsteps_header_2:
      'Leiten Sie die Zusammenfassung Ihrer Angaben und die Unterlagen weiter an Ihre Bankberaterin oder Ihren Bankberater ',
    nextsteps_details_2:
      'Dafür können Sie Ihre Angaben und Unterlagen z.B. als PDF per E-Mail versenden oder Ausdrucke zum Bankgespräch mitnehmen.',
    nextsteps_header_3: 'Beantragen Sie Ihren Förderkredit',
    nextsteps_details_3: 'Ihre Bankberaterin oder Ihr Bankberater erstellt den Antrag für Sie.',
    nextsteps_header_4: 'Ihre Bank oder Sparkasse prüft Ihren Antrag ',
    nextsteps_details_4:
      'Ihre Bankberaterin oder Ihr Bankberater prüft Ihre Angaben. Ist alles in Ordnung, wird der Antrag direkt an die KfW weitergeleitet.',
    nextsteps_header_5: 'Schließen Sie den Kreditvertrag ab und verwenden Sie das Geld',
    nextsteps_details_5:
      'Jetzt können Sie den Kreditvertrag unterzeichnen und Sie bekommen das Geld schon bald auf Ihr Konto ausgezahlt.',
    financialPartner_header: 'Suchen Sie noch eine Bank oder Sparkasse als Finanzierungspartner?',
    financialPartner_link: 'Finanzierungspartner finden und Termin anfragen',
    toOverview: 'Übersicht',
    delete: 'Löschen',
  },
  oneApp: {
    title: 'KfW-Förderassistent',
    infoText1:
      'Ihren KfW-Kredit beantragen Sie nicht direkt bei der KfW, sondern bei einer Bank oder Sparkasse. Damit Sie gut auf das wichtige Bankgespräch vorbereitet sind, können Sie jetzt den KfW-Förderassistenten nutzen.',
    infoText2:
      'Machen Sie Ihre Angaben so vollständig wie möglich, insbesondere zu Ihrem Vorhaben, Ihrer Person bzw. Ihrem Unternehmen.',
    infoText3:
      'Bitte beachten Sie, dass die Wahl des KfW-Förderproduktes an dieser Stelle vorläufig ist. Bei der Wahl des richtigen Förderprodukts für die Antragstellung unterstützt Sie Ihre Bank oder Sparkasse, wenn Sie dort Ihren Antrag stellen.',
    infoText4:
      'Am Ende erhalten Sie eine Zusammenfassung Ihrer Angaben als PDF-Dokument, das Sie zu Ihrem Beratungsgespräch mitnehmen können.',
    infoRequired: '* Pflichtfeld',
    endText:
      "Vielen Dank, das war's schon! Wenn Sie jetzt auf \u201EAntrag generieren\u201C klicken, erhalten Sie eine Zusammenfassung Ihrer Angaben als PDF. Damit können Sie dann gut vorbereitet zu Ihrer Bank oder Sparkasse gehen.",
    notARobot: 'Ich bin kein Roboter. *',
    notARobotError: 'Bitte bestätigen Sie.',
    buttons: {
      generatePdf: 'Vorantragsdokument erzeugen',
      newRequest: 'Neuer Antrag',
      submit: 'Antrag generieren',
    },
    contentProgressBar: {
      program: 'Produktratgeber',
      generalRequestInformation: 'Erste Angaben',
      applicant: 'Antragsteller/-in',
      notes: 'Anmerkungen',
      consent: 'Einverständnis',
      projectDetails: 'Vorhaben',
    },
    validation: {
      invalidForm: 'Ein oder mehrere Felder sind ungültig.',
      somethingWrong: 'Etwas ist schiefgelaufen. Bitte versuchen Sie es später erneut.',
    },
    Program: {
      title: 'Ihr KfW-Förderprodukt finden',
      infoText: 'Bitte wählen Sie zunächst ein geeignetes Produkt aus.',
      fundingPurpose: 'Förderzweck',
      usagePurpose: 'Verwendungszwecke',
      creditAmount: 'Ihr gewünschter Kreditbetrag',
      creditAmountTooltip:
        'Beachten Sie bitte, dass diese Angabe mit Ihrer Berechtigung zum Vorsteuerabzug zusammenhängt. Sofern Sie hierzu berechtigt sind, geben Sie bitte einen Nettobetrag an, andernfalls einen Bruttobetrag. Die maximale Höhe des Kreditbetrages kann von verschiedenen Einflussgrößen abhängen. Z.B. u.a. von Ihrem gewählten Kreditprogramm, ihrem Jahresumsatz, Ihren Lohnkosten. Für eine Genaue Bestimmung beachten Sie bitte die Detailangaben zu Ihrem gewünschten KfW-Kreditprogramm. Im Zweifel berät Sie Ihre Bank oder Sparkasse.',
      usagePurposeTooltip:
        'Bitte wählen Sie einen zu Ihrem Vorhaben passenden Verwendungszweck aus. Keinen passenden Verwendungszweck gefunden? Bitte vergewissern Sie sich, dass Sie das richtige Programm im Bereich "Ihr KfW-Förderprodukt finden" ausgewählt haben.',
    },
    ProgramModal: {
      title: 'Finden Sie ein passendes Förderprodukt',
      info: 'Hinweis',
      infoText:
        'Für die Auswahl eines KfW-Förderproduktes, das bestmöglich für Ihre Ziele geeignet ist, hilft Ihnen im Zweifel gerne ein Finanzierungspartner (z.B. eine Bank oder Sparkasse). In diesem Vorantrag hier, ist Ihre Auswahl zunächst eine erste Indikation. Im Beratungsgesprächen mit Ihrem Finanzierungspartner kann sich diese Vorauswahl ändern, ggf. sind andere Förderprodukte besser geeignet. So ist es z.B. möglich auch mehrere KfW-Förderprodukte miteinander zu kombinieren - lassen Sie sich hierzu von Ihrem Finanzierungspartner beraten.',
      productSelectionInfo: 'Wichtige Hinweise zur Nutzung der Produktauswahl',
      productSelectionInfoTooltipStart: 'Gehen Sie wie folgt vor um ein passendes KfW-Förderprodukt auszuwählen:',
      productSelectionInfoTooltipItem1:
        'Wählen Sie bitte zunächst über den Förderzweck ein Hauptziel Ihrer gewünschten Förderung aus. Anschließend sehen Sie nur noch eine Liste von Förderprodukten, die für diesen Förderzweck vorgesehen sind.',
      productSelectionInfoTooltipItem2:
        'Anschließend können Sie direkt ein Förderprodukt anhand der Kurzbeschreibungen der einzelnen Programme auswählen.',
      productSelectionInfoTooltipItem3:
        'Alternativ können Sie über die Liste der Verwendungszwecke auch genauer beschreiben, welchem Hauptzweck die Förderung dienen soll. Wenn sich Ihre Förderungen mehreren Zwecken zuordnen lässt, beschränken Sie sich bitte zunächst auf den wichtigsten Zweck.',
      productSelectionInfoTooltipEnd:
        'Die Liste der Verwendungszwecke enthält jeweils nur Einträge, die zum ausgewählten Förderzweck und dem ggf. bereits ausgewählten Förderprodukt passen. Wenn Sie keinen passenden Verwendungszweck finden, können Sie einen anderen ggf. besser passenden Förderzweck oder ein besser passendes Förderprodukt wählen. Im Zweifel können Sie den Verwendungszweck auch in einem Beratungsgespräch gemeinsam mit Ihrem Finanzierungspartner festlegen.\n\nIn der Kurzbeschreibung der einzelnen Kreditprogramme finden Sie jeweils auch einen Link zu dem entsprechenden Programmmerkblatt, das detaillierte Informationen zu den möglichen Verwendungszwecken und Fördervoraussetzungen.',
      confirm: 'Übernehmen',
      cancel: 'Abbrechen',
    },
    ProgramRecommendation: {
      title: 'Ihre infrage kommenden KfW-Förderprodukte',
      selectProduct: 'Bitte wählen Sie zunächst ein Förderprodukt aus.',
      noProductFound: 'Kein passendes Förderprodukt gefunden?',
      changeProduct: 'Förderprodukt auswählen oder ändern',
    },
    ProgramCard: {
      toDetails: 'Zu den Details',
    },
    GeneralRequestInformation: {
      title: 'Bereiten Sie Ihren Kreditantrag für Ihre Bank oder Sparkasse vor',
      infoText: 'Wählen Sie einen Namen für Ihr Vorhaben – maximale Länge 255 Zeichen.',
      requestIdentifier: 'Name Ihres Vorhabens',
      requestDetails: 'Details zu Ihrem Vorhaben',
      requestIdentifierInfo: '(notwendig zum Speichern und Wiederauffinden Ihres Vorhabens)',
    },
    Applicant: {
      title: 'Antragsteller/-in – wichtig',
      applicantTypeLabel: 'Wählen Sie bitte zunächst Ihre Unternehmensform aus.',
      applicantTypeTooltip:
        'Hinweis: Je nach Personentyp unterscheiden sich Ihre für die KfW notwendigen Angaben. Die Unterscheidung orientiert sich an der Rechtsform Ihres Unternehmens. Sofern Ihr Unternehmen aufgrund eines Gründungsprozesses noch keine offizielle Rechtsform hat, können Sie als Personentyp „natürliche Person“ auswählen. Sofern Sie alleiniger Inhaber Ihres  Unternehmens sind (z.B. mit der Rechtsform „Eingetragener Kaufmann (e.K.)) wählen Sie bitte  den Personentyp „Einzelunternehmen“. In allen anderen Fällen wählen Sie bitte den Personentyp „Unternehmen“',
      applicantTypeLookup,
      infoLookup,
    },
    Notes: {
      title: 'Anmerkungen',
      infoText:
        'Hier können Sie weitere Informationen für Ihre Bank oder Sparkasse hinterlegen. Bitte verwenden Sie max. 3.000 Zeichen.',
      commentSectionInfoLookup,
    },
    Consent: {
      title: 'Einverständniserklärungen',
      dataProtectionTitle: 'Datenschutzgrundsätze',
      dataProtectionInfoText:
        'Ja, ich habe verstanden, dass die Nutzung dieser Webseite unverbindlich ist. Dieses Formular ist kein Antrag an die KfW oder an eine Bank oder Sparkasse. Eine Prüfung meiner Eingaben seitens der KfW erfolgt nicht. Die mir zur Verfügung gestellte Zusammenfassung meiner Eingaben bzw. deren Speichern bei der KfW stellt keine Entscheidung der KfW über das Vorliegen der Fördervoraussetzungen dar. Sie stellt kein Angebot und auch keine sonstige verbindliche Zusage der KfW dar, sondern soll mir lediglich helfen, den von mir bei der Bank oder Sparkasse zu stellenden Antrag vorzubereiten.',
      dataProtectionInfoText2_part1:
        'Ja, ich nehme zur Kenntnis, dass meine personenbezogenen Daten in diesem Rahmen verarbeitet werden und zur Weiterverwendung im Rahmen einer Antragsstellung durch meine Bank an diese übermittelt werden können. Eine Übermittlung meiner Daten findet erst auf meinen ausdrücklichen Wunsch hin statt. Alles Weitere ist in den ',
      dataProtectionInfoText2_link: 'https://www.kfw.de/KfW-Konzern/Datenschutz.html',
      dataProtectionInfoText2_linkText: 'Datenschutzgrundsätzen der KfW',
      dataProtectionInfoText2_part2: ' geregelt.',
      dataProtectionInfoText3:
        'Die KfW speichert meine Daten sowie das daraus generierte PDF für 6 Monate ab dem Zeitpunkt der Erzeugung. Nach Ablauf dieser Frist werden diese und das erzeugte PDF automatisch gelöscht.',
      dataProtectionConfirmLabel: 'Hiermit erkläre ich mein Einverständnis mit den vorgenannten Erklärungen *',
      dataProtectionConfirmError: 'Bitte erklären Sie Ihr Einverständnis.',
      dataUsageTitle: 'Verwendung meiner Daten durch die KfW',
      dataUsageInfoText:
        'Ich willige hiermit ein, dass die KfW oder ein von ihr beauftragter Dritter mich postalisch oder per E-Mail kontaktieren und meine im Formular eingegebenen Daten verarbeiten darf. Die Verarbeitung meiner Daten für die Kontaktaufnahme erfolgt ausschließlich zu dem Zweck, dieses Formular und seine Einsatzmöglichkeiten mit Hilfe meiner Erfahrungen und Einschätzungen verbessern zu können.',
      dataUsageInfoText2:
        'Meine Einwilligung erfolgt auf freiwilliger Basis und ich kann sie jederzeit mit Wirkung für die Zukunft widerrufen. Ab Zugang der Widerrufserklärung dürfen meine Daten nicht mehr von der KfW für den vorgenannten Zweck weiterverarbeitet werden. Sie sind in diesem Fall unverzüglich zu löschen. Durch den Widerruf meiner Einwilligung wird die Rechtmäßigkeit der bis dahin erfolgten Verarbeitung nicht berührt.',
      dataUsageInfoText3:
        'Meine Widerrufserklärung kann ich jederzeit formfrei an die KfW richten: Per Post an KfW Bankengruppe, z.Hd. Widerspruchsstelle, Ludwig-Erhard-Platz 1-3 in 53179 Bonn oder per E-Mail an ',
      dataUsageInfoText3_email: 'info@kfw.de',
      dataUsageInfoText3_emailText: 'info@kfw.de',
      dataUsageInfoText3_point: '.',
      dataUsageInfoText4:
        'Ihr Feedback ist für uns sehr wichtig, damit wir unsere Aufgaben zur Unterstützung der Wirtschaft noch besser erfüllen können. Eine Auswertung der erhobenen Daten durch die KfW erfolgt ausschließlich in anonymisierter Form.',
      dataUsageConfirmLabel: 'Hiermit erkläre ich mein Einverständnis mit der vorgenannten Verwendung meiner Daten',
    },
    SingleEntrepreneur: {
      title: 'Name',
      firstName: 'Vorname',
      lastName: 'Nachname',
      nationality: 'Staatsangehörigkeit',
      maritalStatus: 'Familienstand',
      company: 'Firmenname',
      legal: 'Rechtsform',
      adressing: 'Anrede',
      adressingLookup,
      academicTitle: 'Akademischer Titel',
      academicTitleLookup,
      industrySector: 'Branche Wirtschaftsbereich',
      projectNace: 'Genaue Bezeichnung der Branche',
      foundingDate: 'Gründungsdatum',
      businessStartingDate: 'Aufnahme der Geschäftstätigkeit (sofern abweichend)',
      isStartUpLabel: 'Befindet sich Ihr Unternehmen noch in der Gründungsphase?',
      isEligibleToDeductTaxLabel: 'Ist Ihr Unternehmen vorsteuerabzugsberechtigt?',
    },
    PersonalIdentification: {
      title: 'Weitere Angaben zur Identifizierung Ihrer Person bzw. Ihres Unternehmens – optional',
      infoText:
        'Bitte geben Sie die folgenden Informationen nur an, sofern diese für Ihr Unternehmen zutreffend sind. Dies kann z.B. von der Rechtsform Ihres Unternehmens abhängen. Je mehr Details Sie hier vorab zusammenstellen können, umso mehr helfen Sie Ihrem Finanzierungspartner im Antragsprozess bei der KfW. Die Angaben sind optional, wenn Sie Zweifel haben, können diese auch später über Ihren Finanzierungspartner (Banken oder Sparkassen) nachgetragen werden.',
      subtitle: 'Ausweisdaten',
      birthDate: 'Geburtsdatum',
      placeOfBirth: 'Geburtsort',
      birthName: 'Geburtsname',
      nationality: 'Staatsangehörigkeit',
      idType: 'Art des Ausweisdokuments',
      idNumber: 'Ausweisnummer',
      idIssuePlace: 'Ausstellungsort',
      idIssuePlace_tooltip: 'Ort an dem der Ausweis ausgestellt wurde',
      idIssueDate: 'Austellungsdatum',
      idIssueDate_tooltip: 'Austellungsdatum Ihres Ausweisdokuments',
      idIssuedBy: 'Ausstellende Behörde',
      idIssuedBy_tooltip: 'Ausstellende Behörde Ihres Ausweisdokuments',
      idExpirationDate: 'Gültig bis',
      idExpirationDate_tooltip: 'Ende der Gültigkeit Ihres Ausweisdokuments',
      idTypeLookup,
    },
    CompanyInformation: {
      titleCompany: 'Geschäftsdaten',
      titleSingleEntrepreneur: 'Geschäftsdaten',
      legal: 'Rechtsform',
      industrySector: 'Branche Wirtschaftsbereich',
      projectNace: 'Genaue Bezeichnung der Branche',
      foundingDate: 'Gründungsdatum',
      businessStartingDate: 'Aufnahme der Geschäftstätigkeit',
      businessStartingDateTooltip:
        'Hinweis: Wenn Sie Ihr Unternehmen zu einem bestimmten Zeitpunkt offiziell gegründet (z.B. durch Eintragung im Handelsregister) haben, anschließend jedoch nicht sofort die Geschäftstätigkeit aufnehmen, so beziehen sich die Fristen für das Unternehmensalter auf das Datum der Aufnahme der Geschäftstätigkeit. D.h. die Konditionen können ggf. günstiger werden.',
      isStartUpLabel: 'Befindet sich Ihr Unternehmen noch in der Gründungsphase?',
      isStartUpTooltip:
        'Hinweis: Hier sollten Sie nur dann Ja angeben, wenn die endgültige Rechtform Ihres Unternehmens noch nicht offiziell abgesichert also z.B. noch nicht in dem für die Rechtsform vorgesehenen Register eingetragen ist.',
      isEligibleToDeductTaxLabel: 'Ist Ihr Unternehmen vorsteuerabzugsberechtigt?',
      isEligibleToDeductTaxTooltip:
        'Hinweis: Bitte wählen Sie Ja, wenn Sie berechtigt sind die Mehrwertsteuer Ihrer Ausgaben mit Ihrer zu entrichtenden Umsatzsteuer zu verrechnen.',
    },
    Address: {
      title: 'Adresse',
      street: 'Straße',
      houseNumber: 'Hausnummer',
      postcode: 'PLZ',
      location: 'Ort',
      country: 'Land',
      countryLookup,
    },
    Company: {
      title: 'Firmenbezeichnung',
      company: 'Firmenname',
      companyContinuation: 'Firmenname (Fortsetzung)',
    },
    Registration: {
      registeredTitle: 'Sofern Ihr Unternehmen offiziell registriert ist',
      registrationNumber: 'Registernummer',
      registrationType: 'Art des Registers',
      registrationPlaceDE: 'Ort des Registergerichts',
      registrationPlaceDE_tooltip: 'Ort des Registergerichts in Deutschland',
      registrationPlaceDETooltip:
        'Hinweis: Der Ort des Registergerichts in Deutschland ist nur für bestimmte Registerarten notwendig bzw. möglich. Die Angabe ist hier optional und muss ggf. durch Ihren Finanzierungspartner (Banken oder Sparkassen) nachgetragen werden.',
      registrationCountryTitle: 'Sofern Ihr Unternehmen im Ausland registriert ist',
      registrationCountry: 'Land des Registergerichts',
      registrationCountry_tooltip: 'Land des Registergerichts im Ausland',
      registrationPlace: 'Ort des Registergerichts',
      registrationPlace_tooltip: 'Ort des Registergerichts im Ausland',
      registrationTypeLookup,
      countryLookup,
    },
    Certification: {
      certificationTitle:
        'Wenn die Gründung Ihres Unternehmens offiziell beurkundet ist, bitten wir Sie um folgende Angaben',
      certificationNumber: 'Urkundennummer',
      certificationType: 'Art der Urkunde',
      certificationDesc: 'Beschreibung sonstiger (nicht gelisteter) Urkunde',
      certificationDate: 'Erstellungsdatum der Urkunde',
      certificationNotaryName: 'Name der beurkundenden Notarin bzw. des Notars',
      certificationNotaryOffice: 'Name des Sitzes der Notarin bzw. des Notars',
      certificationTypeLookup,
    },
    License: {
      registredTradeLicenseTitle: 'Sofern Ihr Unternehmen über einen Gewerbeschein registriert ist',
      licenseIssuedDate: 'Ausstellungsdatum',
      licenseIssuedDate_tooltip: 'Ausstellungsdatum des Gewerbescheins',
      licenseIssuedPlace: 'Ausstellungsort',
      licenseIssuedPlace_tooltip: 'Ausstellungsort des Gewerbescheins',
    },
    CompanyIdentification: {
      title: 'Weiterführende Angaben zur Identifizierung Ihres Unternehmens – optional',
      subtitle: 'Unternehmensidentifikation',
      infoText:
        'Bitte geben Sie die folgenden Informationen nur an, sofern diese für Ihr Unternehmen zutreffend sind. Dies kann z.B. von der Rechtsform Ihres Unternehmens abhängen. Je mehr Details Sie hier vorab zusammenstellen können, umso mehr helfen Sie Ihrem Finanzierungspartner im Antragsprozess bei der KfW. Die Angaben sind optional, wenn Sie Zweifel haben, können diese auch später über Ihren Finanzierungspartner (Banken oder Sparkassen) nachgetragen werden.',
      taxId: 'Umsatzsteuer ID',
      taxId_tooltip: 'Umsatzsteuer ID Ihres Unternehmens',
    },
    NonProfit: {
      title: 'Weitere Details zum Unternehmen',
      partOfCompanyLabel: 'Gehört Ihr Unternehmen zu einem Konzern?',
      participationOfBankStatusLabel: 'Ist ein Finanzinstitut zu mehr als 25% an Ihrem Unternehmen beteiligt?',
      participationOfBankStatusTooltip:
        'Hinweis: Eine (unvollständige) Liste von Beispielen für Finanzinstitute: Banken, Sparkassen, Fintechs, Leasinggesellschaften, Versicherungen. Auch hier können Sie in Zweifelsfällen Ihren Bank- oder Sparkassenberater um Unterstützung zur Beantwortung dieser Frage bitten.',
      nonProfitLabel: 'Ist Ihr Unternehmen gemeinnützig tätig?',
      activeInNonProfitLabel: 'Ist Ihr Unternehmen unternehmerisch/gewinnorientiert tätig?',
      citBObligatoryLabel: 'Ist Ihr Unternehmen Körperschaftsteuerpflichtig?',
      CITFreedLabel: 'Ist Ihr Unternehmen von der Körperschaftsteuer freigestellt?',
    },
    CompanySize: {
      title: 'Unternehmensgröße',
      description:
        'Die folgenden Angaben sind für die Größeneinstufung Ihres Unternehmens notwendig. Sie bilden die Basis, um Ihr Unternehmen einer der vier möglichen Größenklassen (Kleinstunternehmen, Kleines Unternehmen, Mittleres Unternehmen oder Großes Unternehmen) zuzuordnen. Die Einstufung kann u.a. auch Einfluss auf Ihre Zinskonditionen haben. Weiterführende Informationen finden Sie im <1>Merkblatt KMU-Definition</1>.',
      numberOfEmployeesLabel: 'Anzahl Mitarbeiter',
      numberOfEmployeesTooltip:
        'Die Mitarbeiterzahl entspricht der während eines Jahres beschäftigten Vollzeitarbeitnehmer. Nicht Vollzeitkräfte wie Teilzeit- und Saisonbeschäftigte werden anteilig berücksichtigt (entsprechend ihrer auf ein ganzes Jahr gerechneten Quote). Auszubildende werden nicht berücksichtigt.\n\nBitte beachten Sie, dass für den Corona Schnellkredit 078 gesonderte Regeln gelten: Hier werden Auszubildende als Mitarbeiter angerechnet.',
      annualSalesLabel: 'Jahresumsatz',
      annualSalesTooltip:
        'Jahresumsatz Ihres Unternehmens (alternativ können Sie auch die Bilanzsumme wählen, nur eine Angabe ist notwendig)',
      totalAssetsLabel: 'Bilanzsumme',
      totalAssetsTooltip:
        'Bilanzsumme Ihres Unternehmen (alternativ zum Jahresumsatz, nur eine der beiden Angaben ist für die Eingruppierung notwendig)',
      InfoValidDateLabel: 'Datum Unternehmensgröße',
      InfoValidDateTooltip: 'Stichtag, zu dem die vorgenannten Angaben galten',
      companyIsClassified: 'Gemäß Ihren Angaben, wird Ihr Unternehmen als: <1>{{classification}}</1> eingestuft.',
      companyIsClassifiedDescription:
        'Sofern diese Angaben zu Ihrem Unternehmen Schwankungen unterliegen, die zu wechselnden Größeneinstufungen (Details finden <1>Sie hier</1>) führen, sollten Sie sich von Ihren Bank- oder Sparkassenberater gezielt auf diesen Umstand hinweisen.',
      microEnterprise: 'Kleinstunternehmen',
      smallCompany: 'Kleines Unternehmen',
      mediumSizedCompany: 'Mittleres Unternehmen',
      largeCompany: 'Großes Unternehmen',
      infoBox: {
        creditAmountSummaryWarning:
          'Es gelten folgende Obergrenzen des Kreditbetrags in Abhängigkeit von der Anzahl der Mitarbeiter:\nAbhängig von der Anzahl der Mitarbeiter gibt es Obergrenzen für den gewünschten Kreditbetrag. Bitte beachten Sie, dass Sie momentan oberhalb dieser Grenze liegen.\n\nEs gelten die folgenden Obergrenzen für Kreditbeträge in Abhängigkeit von der Anzahl der Mitarbeiter: ',
        creditAmountDetailArrWarning: [
          'maximal 675.000 Euro pro Unternehmensgruppe (im Sinne verbundener Unternehmen) mit bis einschließlich 10 Beschäftigten des antragstellenden Unternehmens',
          'maximal 1.125.000 Euro pro Unternehmensgruppe (im Sinne verbundener Unternehmen) mit mehr als 10 und bis einschließlich 50 Beschäftigten des antragstellenden Unternehmens',
          'maximal 1.800.000 Euro pro Unternehmensgruppe (im Sinne verbundener Unternehmen) mit mehr als 50 Beschäftigten des antragstellenden Unternehmens',
        ],
      },
      modalTitle: 'Berechnung der Mitarbeiter*innen',
      takeOverEmployeeNumber: 'Anzahl übernehmen',
      cancelModal: 'Abbrechen',
      calculatorSubhead:
        'Bitte geben Sie die Anzahl Ihrer Mitarbeiter*innen im jeweiligen Arbeitsverhältnis an. Leih- und Fremdarbeitende zählen nicht. Die Umrechnung in Vollzeitkräfte erfolgt automatisch.',
      thirtyPlus: 'Anzahl über 30 Std/Woche',
      twentyPlus: 'Anzahl bis 30 Std/Woche',
      twenty: 'Anzahl bis 20 Std/Woche',
      miniJobber: 'Anzahl auf 450 Euro Basis',
      parentalLeave: 'Anzahl Elternzeit',
      trainees: 'Anzahl Auszubildende',
      calculateNumberOfEmployees: 'Anzahl der Mitarbeiter berechnen',
      tooltip: {
        thirtyPlus: 'Angabe der Mitarbeiteranzahl mit mehr als 30 Stunden Wochenarbeitszeit',
        twentyPlus: 'Angabe der Mitarbeiteranzahl mit bis zu 30 Stunden Wochenarbeitszeit',
        twenty: 'Angabe der Mitarbeiteranzahl mit bis zu 20 Stunden Wochenarbeitszeit',
        miniJobber: 'Angabe der Mitarbeiteranzahl mit einer Beschäftigung als 450-EUR-Minijob',
        parentalLeave: 'Angabe der Mitarbeiteranzahl, welche sich in Elternzeit befinden.',
        trainees: 'Angabe der Mitarbeiteranzahl, die sich in der Berufsausbildung befinden',
      },
    },
    ProjectDetails: {
      title: 'Ihr Vorhaben – wichtig',
      addressTitle: 'Verwendungsort der Fördermittel',
      countryLookup,
      addAddressStreet: 'Straße',
      addAddressNumber: 'Hausnummer',
      addAddressPostcode: 'PLZ',
      addAddressLocation: 'Ort',
      addAddressCountry: 'Land',
      addAddressProvince: 'Bundesstaat/Provinz',
      additionalAddressProvided:
        'Möchten Sie die Fördermittel an einem anderen Ort als an Ihrer Firmenadresse verwenden?',
      additionalInformationTitle: 'Branche',
      projectNaceIsDifferent:
        'Wenn Ihr Unternehmen in verschiedenen Branchen tätig ist, geben Sie bitte an, in welcher Branche Sie die Fördermittel verwenden wollen.',
      projectNaceIsDifferentTooltip:
        'Die Förderung durch die KfW unterliegt bestimmten branchenbezogenen Einschränkungen, für die jedoch auch Ausnahmen gelten können. Diese Ausnahmen können durch eine Trennung der Branche differenziert werden, auf die sich Ihre Investition, bzw. der Einsatz der Fördermittel bezieht, von der Branche in dem Ihr Unternehmen primär tätig ist.',
      industrySector: 'Branche Wirtschaftsbereich',
      projectNace: 'Genaue Bezeichnung der Branche',
      pursposeInfoText1:
        'Bitte geben Sie hier genauer an, wie Sie die zu beantragenden Fördermittel einsetzen werden. Bitte beachten Sie auch die Ausschlüsse für die Förderung durch die KfW. Weitere Details finden Sie ',
      pursposeInfoText_linkText: 'hier,',
      pursposeInfoText_link: 'https://www.kfw.de/PDF/Download-Center/Konzernthemen/Nachhaltigkeit/Ausschlussliste.pdf',
      pursposeInfoText2:
        ' sowie auch die Detailbeschreibungen zu Ihrem ausgewählten Förderprodukt (im Produktratgeber).',
      creditAmount: 'Gewünschter Gesamtkreditbetrag',
      creditAmountTooltip:
        'Sie sehen hier Ihren weiter oben angegeben gewünschten Kreditbetrag. Wenn Sie diesen Betrag noch korrigieren möchten, geht dies nur weiter oben bei Ihren Angaben zum KfW-Förderprodukt. Beachten Sie bitte auch, dass die Angaben mit Ihrer Berechtigung zum Vorsteuerabzug zusammenhängt. Sofern Sie hierzu berechtigt sind, geben Sie bitte einen Nettobetrag an, andernfalls einen Bruttobetrag. Die maximale Höhe des Kreditbetrages kann von verschiedenen Einflussgrößen abhängen. Z.B. u.a. von Ihrem gewählten Kreditprogramm, Ihrem Jahresumsatz, Ihren Lohnkosten.  Für eine genaue Bestimmung beachten Sie bitte die Detailangaben zu Ihrem gewünschten KfW-Kreditprogramm. Im Zweifel berät Sie Ihre Bank oder Sparkasse.',
      operatingFunds: 'Davon Anteil für Betriebsmittel',
      operatingFundsTooltip:
        'Anteil des gewünschten Kreditbetrags für Betriebsmittel (EUR): Als Betriebsmittel gelten Aufwendungen für laufende Kosten, z.B. für Personal, Miete oder Leasing. Es handelt sich hier um eine optionale Angabe, die Sie auch nachträglich vornehmen können. Im Zweifel hilft Ihnen Ihr Steuerberater bzw. Ihr Bank- Sparkassenberater bei der Aufteilung. Bitte beachten Sie auch die Ausschlüsse der Förderung von: Umschuldung oder Ablösung bereits bestehender Kredit, sowie die Finanzierung bereits abgeschlossener Vorhaben oder deren Nachfinanzierung, Anschlussfinanzierung oder die Prolongation vorhandener Kredite. Unter bestimmten Bedingungen ist auch die Förderung bereits begonnener Vorhaben möglich, bitte fragen Sie hierzu Ihren Bank- oder Sparkassenberater.',
      investingFunds: 'Davon Anteil für Investitionen',
      investingFundsTooltip:
        'Anteil des gewünschten Kreditbetrags für Investitionen (die im Anlagevermögen aktivierungsfähig sind) (EUR): Als Investitionen (die im Anlagevermögen aktivierungsfähig sind) gelten Anschaffungen für Ihr Unternehmen, wie z.B. Maschinen oder Ausstattung. Es handelt sich hier um eine optionale Angabe, die Sie auch nachträglich vornehmen können. Im Zweifel hilft Ihnen Ihr Steuerberater bzw. Ihr Bank- Sparkassenberater bei der Aufteilung. Bitte beachten Sie auch die Ausschlüsse der Förderung von: Umschuldung oder Ablösung bereits bestehender Kredit, sowie die Finanzierung bereits abgeschlossener Vorhaben oder deren Nachfinanzierung, Anschlussfinanzierung oder die Prolongation vorhandener Kredite. Unter bestimmten Bedingungen ist auch die Förderung bereits begonnener Vorhaben möglich, bitte fragen Sie hierzu Ihren Bank- oder Sparkassenberater.',
      materialFunds: 'Davon Anteil für Material- und Warenlager',
      materialFundsTooltip:
        'Anteil des gewünschten Kreditbetrags für Material- und Warenlager (die im Umlaufvermögen aktivierungsfähig sind) (EUR): Als Kosten für Warenlager (die im Umlaufvermögen aktivierungsfähig sind) werden Ihre Ausgaben z.B. für Roh- Hilfs- und Betriebsstoffe, unfertige oder fertige Erzeugnisse oder Handelswaren bezeichnet. Im Zweifel hilft Ihnen Ihr Steuerberater bzw. Ihr Bank- Sparkassenberater bei der Aufteilung. Bitte beachten Sie auch die Ausschlüsse der Förderung von: Umschuldung oder Ablösung bereits bestehender Kredit, sowie die Finanzierung bereits abgeschlossener Vorhaben oder deren Nachfinanzierung, Anschlussfinanzierung oder die Prolongation vorhandener Kredite. Unter bestimmten Bedingungen ist auch die Förderung bereits begonnener Vorhaben möglich, bitte fragen Sie hierzu Ihren Bank- oder Sparkassenberater.',
      investmentDate: 'Datum für geplante Investition',
      investmentDateTooltip:
        'Datum, zu dem die Investition in Ihrem Unternehmen erfolgen soll bzw. die Betriebsmittel in Ihrem Unternehmen eingesetzt werden sollen  oder der Einsatz für Material-/Warenlagen erfolgen soll: Üblicherweise ist eine nachträglich Förderung von Investitionen nicht möglich. Unter bestimmten Bedingungen ist jedoch auch die Förderung bereits begonnener Vorhaben möglich, bitte fragen Sie hierzu Ihren Bank- oder Sparkassenberater.',
      additionalFinancialInformationTitle: 'Detailangaben zur Finanzierung Ihres Vorhabens',
      detailedInformation: 'Details zum Vorhaben',
      gbzaHeading: 'Technische Zusatzangaben - gewerbliche Bestätigung zum Antrag (gBzA) - optional',
      detailText1:
        'Für die Förderung sind zusätzliche detaillierte technische Angaben zu Ihrem Investitionsvorhaben notwendig, die auf einer sogenannten "gewerblichen Bestätigung zum Antrag" (gBzA) zusammengefasst sind. Sie können diese gBzA über eine zusätzliche Internetanwendung der KfW ausfüllen, die Sie über den folgenden Link starten können. Wenn Sie die gBzA fertig ausgefüllt haben, können Sie ein PDF erzeugen, das Sie ausdrucken und zu Ihrem Finanzierungspartner mitnehmen sollten. Sie können sich hier zunächst einen Überblick über den Inhalt der gBzA verschaffen. Es lohnt sich, die gBzA erst dann auszufüllen, wenn Sie alle benötigten Angaben vollständig machen können. Dies kann auch im weiteren Verlauf des Antragsprozesses mithilfe Ihres Finanzierungspartners bei der KfW geschehen und ist an dieser Stelle hier nicht zwingend notwendig.',
      detailText2: 'Hinweis:',
      detailText3:
        'Abhängig von dem von Ihnen ausgewählten Förderprodukt, kann es sich bei den Details auf der gBzA um technische Daten handeln, für die Sie zusätzliche Hilfe von einem Experten benötigen. Nähere Informationen zur Suche eines solchen Experten finden Sie auf der Produktseite Ihres ausgewählten Förderprogramms (siehe oben im Bereich „Ihr KfW-Förderprodukt finden“).',
      gbzaLink: 'https://public.kfw.de/GEW/masks/wizard.xhtml',
      gbzaLinkText: 'gBzA ausfüllen',
      additionalInformation: 'Zusätzliche Anlagen - optional',
      additionalInformationText1:
        'Im Folgenden finden Sie eine Zusammenstellung von weiteren Dokumenten bzw. Formularen, die für die Antragstellung bei der KfW notwendig sein können. Ob die einzelnen Formulare tatsächlich notwendig sind, erfahren Sie aus den Beschreibungen im Formular oder im Merkblatt Ihres gewünschten Förderproduktes. Im Zweifel berät Sie jedoch auch Ihr Finanzierungspartner.',
      additionalInformationText2:
        'Sofern Sie die Angaben auf den einzelnen Formularen eigenständig vorab eintragen können, vereinfachen Sie den Antragsprozess durch Ihren Finanzierungspartner, in diesem Sinn helfen auch unvollständige Angaben.',
      cumulativeDeclarationHeading: 'Anlage "Kumulierungserklärung Beihilfen"',
      cumulativeDeclarationText:
        'Ihr ausgewähltes Förderprodukt enthält eine staatliche Subvention, für die jedoch bestimmte maximale Obergrenzen gelten. Bitte Bestätigen Sie - abhängig von der Größe Ihres Unternehmens - mit einem der beiden Formulare die Einhaltung dieser Obergrenze.',
      cumulativeDeclarationLinkText1: 'Kumulierungserklärung Kleinbeihilfen',
      cumulativeDeclarationLinkText2: 'Bestätigung zur Einhaltung der Beihilfeobergrenze',
      cumulativeDeclarationLink1:
        'https://www.kfw.de/PDF/Download-Center/F%C3%B6rderprogramme-(Inlandsf%C3%B6rderung)/PDF-Dokumente/6000004526_F_078_Kumulierungserkl%C3%A4rung_Kleinbeihilfen.pdf',
      cumulativeDeclarationLink2:
        'https://www.kfw.de/Download-Center/F%C3%B6rderprogramme-(Inlandsf%C3%B6rderung)/PDF-Dokumente/6000000067-Kumulierungserkl%C3%A4rung-des-EKN.pdf',
      kmuDeclarationHeading: 'Anlage "KMU-Erklärung"',
      kmuDeclarationText1:
        'Sofern Ihr Unternehmen als "Kleinstunternehmen" , "Kleines Unternehmen" oder "Mittleres Unternehmen" eingestuft ist. Ist eine entsprechende Bestätigung notwendig.',
      kmuDeclarationText2:
        'Wenn Ihr Unternehmen keine Verflechtungen mit anderen Unternehmen hat, können Sie dieses Formular nutzen:',
      kmuDeclarationText3: 'andernfalls benutzen Sie bitte dieses Formular:',
      kmuDeclarationLinkText1: 'Vereinfachte Selbsterklärung KMU',
      kmuDeclarationLinkText2: 'Selbsterklärung KMU',
      kmuDeclarationLink1:
        'https://www.kfw.de/Download-Center/F%C3%B6rderprogramme-(Inlandsf%C3%B6rderung)/PDF-Dokumente/6000000095-Vereinfachte-Selbsterkl%C3%A4rung-KMU.pdf',
      kmuDeclarationLink2:
        'https://www.kfw.de/Download-Center/F%C3%B6rderprogramme-(Inlandsf%C3%B6rderung)/PDF-Dokumente/6000000196-KMU-Definition.pdf',
      consentToRequestHeading: 'Einwilligung für Auskunfteianfrage',
      consentToRequestText:
        'Für die Bearbeitung Ihres Förderantrags benötigt die KfW die folgende Einwilligungserklärung für Auskunfteianfragen.',
      consentToRequestLinkText: 'Einwilligung für Auskunfteianfrage',
      consentToRequestLink:
        'https://www.kfw.de/PDF/Download-Center/Förderprogramme-(Inlandsförderung)/PDF-Dokumente/6000000106_F_Einwilligungserklaerung.pdf',
      specialProgramUBRHeading: 'Anlage "Ergänzende Angaben KfW-Sonderprogramm UBR 2022"',
      specialProgramUBRsubtitle:
        'Dieses Formular wird durch Ihren Finanzierungspartner gemeinsam mit Ihnen ausgefühlt. Sie können sich vorab über den Inhalt und die notwendigen Angaben informieren.',
      specialProgramUBRLinkText1: 'Ergänzende Angaben KfW-Sonderprogramm UBR 2022',
      specialProgramUBRLink1: 'https://www.kfw.de/079-ergänzende-angaben',
      specialProgramUBRSubtitle2: 'Beachten Sie bitte auch die Bestimmungen zu Subventionserheblichen Tatsachen',
      specialProgramUBRLinkText2: 'Datenliste subventionserhebliche Tatsachen KfW-Sonderprogramm UBR 2022',
      specialProgramUBRLink2: 'https://www.kfw.de/079-datenliste',
    },
    AdditionalRequestInformation: {
      title: 'Details zu Ihrem Förderwunsch',
      infoText:
        'Die folgenden Angaben helfen Ihrer Bank oder Sparkasse dabei, Ihren Finanzierungsbedarf besser zu verstehen. Das erleichtert es in der Folge, den Antrag bei der KfW zu stellen.',
      duration: 'Kreditlaufzeit (Jahre)',
      durationTooltip:
        'Hinweis: Unter Laufzeit versteht die KfW den geplanten Zeitraum, innerhalb dem Sie Ihren Förderkredit zurückzahlen können. Unter Tilgungsfreie Jahre versteht die KfW den Zeitraum, ab dem Sie nach Zusage durch die KfW mit der Rückzahlung beginnen können. Die Auswahlmöglichkeiten sind nicht in jedem Fall für alle Förderprodukte identisch, d.h. wenn Sie das Förderprodukt wechseln, kann es sein, dass Ihre zuvor gewählte Kombination mit dem neuen Produkt nicht möglich ist. Ebenso können sich bestimmte Kombinationen ausschließen (z.B. ist eine Kombination von 5 Jahren Laufzeit und 4 Tilgungsfreie Jahre nicht möglich).',
      freeYears: 'Tilgungsfreie Zeit (Jahre)',
      freeYearsTooltip:
        'Während der tilgungs­freien Anlauf­zeit zahlen Sie nur Zinsen, aber keine Tilgung. Dadurch ist Ihre monatliche Belastung zunächst kleiner. Aber: Je länger die tilgungs­freie Anlauf­zeit ist, umso größere ist Ihre spätere monatliche Belastung und Ihre Rest­schuld zum Ende der Zinsbindung',
      fixedInterestPeriod: 'Dauer Zinsbindung (Jahre)',
    },
    ContactDetails: {
      title: 'Kontakt',
      subtitle: 'Hierüber geben Sie Ihrem Finanzierungspartner die Möglichkeit, Kontakt zu Ihnen aufzunehmen.',
      email: 'E-Mail',
      phone: 'Telefon',
    },
    summaryScreen: {
      title: 'Vielen Dank für Ihre Angaben!',
      subtitle:
        'Mit Ihren Daten sind Sie nun bestens auf das Beratungsgespräch mit Ihrem Finanzierungspartner vorbereitet. Speichern Sie jetzt Ihre zusammengefassten Angaben als PDF-Dokument:',
      code: 'Vorantrags-Code:',
      pdf_download: 'PDF speichern',
      nextsteps_title: 'So geht es weiter:',
      nextsteps_subtitle:
        'Stellen Sie Ihren Antrag bei Ihrem Finanzierungspartner – also bei einer Bank oder Sparkasse Ihrer Wahl',
      nextsteps_header_1: 'Nehmen Sie Kontakt zu einer Bank oder Sparkasse auf',
      nextsteps_details_1:
        'Das kann Ihre bestehende Bank sein, aber auch jede andere Geschäftsbank, Sparkasse oder Genossenschaftsbank. Suchen Sie noch eine Bank oder Sparkasse als Finanzierungspartner? ',
      nextsteps_link_1: 'Finanzierungspartner suchen',
      nextsteps_header_2:
        'Leiten Sie die Zusammenfassung Ihrer Angaben und die Unterlagen weiter an Ihre Bankberaterin oder Ihren Bankberater ',
      nextsteps_details_2:
        'Dafür können Sie Ihre Angaben und Unterlagen z.B. als PDF per E-Mail versenden oder Ausdrucke zum Bankgespräch mitnehmen.',
      nextsteps_header_3: 'Beantragen Sie Ihren Förderkredit',
      nextsteps_details_3: 'Ihre Bankberaterin oder Ihr Bankberater erstellt den Antrag für Sie.',
      nextsteps_header_4: 'Ihre Bank oder Sparkasse prüft Ihren Antrag ',
      nextsteps_details_4:
        'Ihre Bankberaterin oder Ihr Bankberater prüft Ihre Angaben. Ist alles in Ordnung, wird der Antrag direkt an die KfW weitergeleitet.',
      nextsteps_header_5: 'Schließen Sie den Kreditvertrag ab und verwenden Sie das Geld',
      nextsteps_details_5:
        'Jetzt können Sie den Kreditvertrag unterzeichnen und Sie bekommen das Geld schon bald auf Ihr Konto ausgezahlt.',
      financialPartner_header: 'Suchen Sie noch eine Bank oder Sparkasse als Finanzierungspartner?',
      financialPartner_link: 'Finanzierungspartner finden und Termin anfragen',
      save_pre_application: 'Vorantrag speichern',
      save_pre_application_heading: 'Speichern Sie Ihren Vorantrag',
      coming_soon: 'Bald: Weiterleitung Ihrer Voranträge an Finanzierungspartner',
      overview_financing_project: 'Übersicht und Verwaltung Ihrer Finanzierungsvorhaben',
      redirect_to_save:
        'Mit dem kostenlosen KfW-Förderassistent-Konto können Sie Ihren Vorantrag langfristig speichern und jederzeit wieder aufrufen.',
    },
    webtrekk: {
      errors: {
        requestInformation: {
          requestIdentifier: 'Name Ihres Vorhabens fehlt',
        },
        applicant: {
          applicantType: 'Unternehmensform fehlt',
          firstName: 'Name:Vorname fehlt',
          lastName: 'Name:Nachname fehlt',
          street: 'Adresse:Straße fehlt',
          houseNumber: 'Adresse:Hausnummer fehlt',
          postcode: 'Adresse:PLZ fehlt',
          location: 'Adresse:Ort fehlt',
          company: 'Firmenbezeichnung:Firmenname fehlt',
        },
        consent: {
          dsgvoConfirmation: 'Datenschutzhinweise fehlt',
        },
        notARobot: 'Ich bin kein Roboter fehlt',
      },
    },
  },
  ikk: {
    title: 'KfW-Antrag für Kommunen',
    common: {
      Address: {
        street: 'Straße',
        houseNumber: 'Hausnummer',
        postcode: 'PLZ',
        location: 'Ort',
        country: 'Land',
        countryLookup,
      },
      eur: 'EUR',
      formSwitchYes: 'Ja',
      formSwitchNo: 'Nein',
    },
    notARobot: 'Ich bin kein Roboter. *',
    notARobotError: 'Bitte bestätigen Sie.',
    buttons: {
      generatePdf: 'Vorantragsdokument erzeugen',
      newRequest: 'Neuer Antrag',
      submit: 'Antrag generieren',
    },
    GeneralRequestInformation: {
      requestIdentifier: 'Name Ihres Vorhabens',
      requestDetails: 'Details zu Ihrem Vorhaben',
      requestIdentifierInfo: '(notwendig zum Speichern und Wiederauffinden Ihres Vorhabens)',
    },
    CommunalRequest: {
      title: 'Bereiten Sie Ihren kommunalen Kredit-/Zuschussantrag vor',
      infoText1: '',
      infoText2:
        'Machen Sie Ihre Angaben so vollständig wie möglich, insbesondere zu Ihrem Vorhaben und Ihrer Kommune.',
      infoText3:
        'Bitte wählen Sie zunächst ein passendes Förderprodukt. Ihren Antrag können Sie hier für Kredit- oder Zuschussprodukte ausfüllen. Im Zweifel berät Sie Ihr KfW-Kundenbetreuer oder das KfW-Infocenter.',
      infoText4:
        'Die Antragsfelder beinhalten auch Pflichtfelder. Diese sind im Folgenden mit dem * Symbol gekennzeichnet.',
      infoText5:
        'Am Ende erhalten Sie Ihren Antrag als PDF-Dokument, das Sie direkt für die Antragstellung bei der KfW verwenden können. Beachten Sie bitte, dass auf dem gedruckten Antrag die Unterschrift durch die vertretungsberechtigten Person(en) notwendig ist.',
      infoText6: 'Wählen Sie einen Namen für Ihr Vorhaben – maximale Länge 255 Zeichen.',
    },
    FindProduct: {
      title: 'Ihr KfW-Förderprodukt finden',
      infoText1: 'Wählen Sie bitte zunächst aus, ob Sie ein',
      categories: {
        label: 'Wählen Sie bitte zunächst aus, ob Sie ein',
        tooltip: '',
        categoryLookup,
        categoryKeyLookup,
      },
      infoText2: 'bei der KfW beantragen möchten.',
      infoText3: 'Bitte wählen Sie nun das gewünschte Produkt aus',
      productList: {
        title: 'Ihre infrage kommenden KfW-Förderprodukte',
        subtitle: 'Bitte wählen Sie Ihr Produkt *',
        selectProduct: 'Bitte wählen Sie zunächst ein Förderprodukt aus.',
      },
    },
    ApplicantSelection: {
      title: 'Antragsteller/-in',
      applicantName: 'Name des/der Antragsteller/-in',
      categories: {
        label: 'Bitte wählen Sie zunächst die öffentliche Einrichtung, für die ein Antrag gestellt werden soll:',
        tooltip: 'Unter kommunalen Gebietskörperschaften versteht man die Städte, Gemeinden und Landkreise.',
        applicantLookup,
      },
      Address: {
        title: 'Adresse',
      },
      ContactDetails: {
        title: 'Kontakt des/der Sachbearbeiter/-in',
        subtitle: 'Hierüber geben Sie der KfW die Möglichkeit, Kontakt zu Ihnen aufzunehmen.',
        adressing: 'Anrede',
        salutationLookup,
        name: 'Name',
        phone: 'Telefon (mit Vorwahl)',
        email: 'E-Mail',
      },
      RegistrationData: {
        title: 'Weitere Angaben zur Identifikation',
        subtitle:
          'Bitte geben Sie die folgenden Informationen an, je mehr Details Sie hier vorab zusammenstellen können, umso einfacher gestaltet sich der Antragsprozess bei der KfW.',
        vatIdentificationNumber: 'Umsatzsteuer-Identifikationsnummer',
        taxNumber: 'Steuernummer',
        regionlKeyOfStates: 'Regionalschlüssel gem. Statist. Bundesamt',
      },
      BankDetails: {
        title: 'Bankverbindung des/der Antragstellers/-in',
        bankName: 'Name der Bank',
        iban: 'IBAN',
        bic: 'BIC',
        sepa: 'Möchten Sie am SEPA-Lastschriftverfahren teilnehmen? *',
        diffAccountOwner: 'Antragsteller/-in und Kontoinhaber/-in weichen voneinander ab.',
        accountOwner: 'Kontoinhaber/-in',
        directDebitConsent: 'SEPA Lastschriftverfahren',
      },
      FinancialData: {
        title: 'Finanzwirtschaftliche Daten',
      },
      CommunityAssociationFinancials: {
        subtitle1:
          'Als Gemeindeverbände (z.B. Kommunale Zweckverbände) sind im Sinne der Programmmerkblätter weitere Angaben erforderlich.',
        subtitle2:
          'Die KfW behält sich die Anforderung weiterer Angaben/Unterlagen im Rahmen der Antragsbearbeitung vor.',
        accountingLabel: 'Bitte geben Sie zunächst die Art der Rechnungslegung des Gemeindeverbands an:',
        accountingLookup,
        CameralisticAccounting: {
          householdDebt: 'Haushaltsschulden',
          householdDebtTip: 'Bitte geben Sie die Haushaltsschulden ohne Kassenkredite an.',
        },
        DoubleAccounting: {
          totalAssets: 'Bilanzsumme',
          equityCapital: 'Eigenkapital',
          annualSales: 'Jahresumsatz',
          netIncome: 'Jahresüberschuss/-fehlbetrag',
          totalDebt: 'Gesamtverschuldung',
        },
      },
      Population: {
        population: 'Einwohnerzahl',
        timeOfPopulation: 'Stand per',
      },
      Foreclosure: {
        budgetEmergency: 'Liegt eine Haushaltsnotlage/Haushaltssicherungslage vor?',
        yesOrNoLookup,
        foreclosureMeasures:
          'Sind dem/der Antragsteller/-in gegen Ihn beabsichtigte, beantragte, zugelassene oder eingeleitete Zwangsvollstreckungsmaßnahmen bekannt?',
      },
    },
    ProductCard: {
      toDetails: 'Zu den Details',
      noDetailsFound: 'Kein Eintrag gefunden',
    },
    FinancingDetails: {
      title: 'Details zu Ihrem Finanzierungswunsch',
      infoText1:
        'Bitte beachten Sie bei den folgenden Angaben, dass Sie nicht unabhängig voneinander sind. Nicht alle eingebbaren Kombinationen sind in jedem Fall möglich.',
      creditAmount: 'Kreditbetrag (EUR)',
      grantAmount: 'Zuschussbetrag (EUR)',
      creditAmountInfo: '',
      grantAmountTooltip:
        'Der Zuschuss beträgt 75 % der förderfähigen Kosten entsprechend den Komponenten A (Erstellung von integrierten Konzepten) und B (Sanierungsmanagement). Der maximale Zuschussbetrag für das Sanierungsmanagement beträgt bei einem Förderzeitraum von in der Regel maximal 3 Jahren insgesamt bis zu 210.000 Euro je Quartier. Bei einer Verlängerung kann der Höchstbetrag um bis zu 140.000 Euro auf insgesamt bis zu 350.000 Euro für maximal 5 Jahre aufgestockt werden. Zuschüsse unter 5.000 Euro werden nicht ausgezahlt.',
      creditAmountTooltip:
        'Die maximale Höhe des Kreditbetrages kann von verschiedenen Einflussgrößen abhängen. Z.B. u.a. von Ihrem gewählten Kreditprogramm. Für eine genaue Bestimmung beachten Sie bitte die Detailangaben zu Ihrem gewünschten KfW-Kreditprogramm. Im Zweifel berät Sie gerne auch die KfW.',
      productValidationNotification: 'Hinweismeldung',
      productValidationResponseEmpty:
        'Die von Ihnen gewählte Kombination ist leider nicht beantragbar. Bitte variieren Sie Ihre Auswahl und prüfen Sie die Gültigkeit erneut.',
      productValidationChannelDirektSuccess: 'Die gewählte Kombination ist beantragbar.',
      productValidationInvalid:
        'Die von Ihnen gewählte Kombination ist leider nicht beantragbar. Bitte variieren Sie Ihre Auswahl und prüfen Sie die Gültigkeit erneut.',
    },
    AdditionalRequestInformation: {
      title: 'Details zu Ihrem Förderwunsch',
      duration: 'Kreditlaufzeit (Jahre)',
      durationTooltip:
        'Hinweis: Unter Laufzeit versteht die KfW den geplanten Zeitraum, innerhalb dem Sie Ihren Förderkredit zurückzahlen können. Unter Tilgungsfreie Jahre versteht die KfW den Zeitraum, ab dem Sie nach Zusage durch die KfW mit der Rückzahlung beginnen können. Die Auswahlmöglichkeiten sind nicht in jedem Fall für alle Förderprodukte identisch, d.h. wenn Sie das Förderprodukt wechseln, kann es sein, dass Ihre zuvor gewählte Kombination mit dem neuen Produkt nicht möglich ist. Ebenso können sich bestimmte Kombinationen ausschließen (z.B. ist eine Kombination von 5 Jahren Laufzeit und 4 Tilgungsfreie Jahre nicht möglich).',
      freeYears: 'Tilgungs­freie Anlauf­zeit (Jahre)',
      freeYearsTooltip:
        'Während der tilgungs­freien Anlauf­zeit zahlen Sie nur Zinsen, aber keine Tilgung. Dadurch ist Ihre monatliche Belastung zunächst kleiner. Aber: Je länger die tilgungs­freie Anlauf­zeit ist, umso größere ist Ihre spätere monatliche Belastung und Ihre Rest­schuld zum Ende der Zinsbindung.',
      fixedInterestPeriod: 'Zinsbindung (Jahre)',
      fixedInterestPeriodTooltip:
        'Zeit, in der sich der Zins nicht ver­ändert. Wenn Sie Ihren Kredit zum Ablauf der Zins­bindung noch nicht ab­bezahlt haben, erhalten Sie recht­zeitig ein Angebot für eine Anschlussfinanzierung.',
    },
    ProjectDescription: {
      title: 'Vorhabensbeschreibung',
      AddressForm: {
        title: 'Daten zum Investitionsvorhaben',
        infoText1: 'Beginn des Investitionsvorhaben bzw. des zu finanzierenden Bauabschnitt',
        infoText1_tooltip:
          'Beginn des Investitionsvorhabens bzw. des zu finanzierenden Bauabschnitts (= baulicher Beginn bzw. Lieferungs- u. Leistungsvertrag)',
        infoText264_tooltip:
          'Als Vorhabensbeginn gilt der Abschluss eines der Ausführung zuzurechnenden Lieferungs- und Leistungsvertrages.',
        infoText2: 'Investitionsadresse',
        investitionStartDate: 'Datum für geplante Investition',
      },
      projectDetails: {
        title: 'Beschreibung des Vorhabens',
        creditInfoText: 'Kurzbeschreibung des Investitionsvorhabens bzw. Bauabschnitts',
        grantInfoText: 'Kurzbeschreibung des Projekts bzw. Vorhabens',
        projectDescriptionTooltip: 'Tooltip Vorhabensbeschreibung',
      },
      SustainabilityDirective: {
        title: 'Nachhaltigkeitsrichtlinie',
        infoText1:
          'Im Zuge der Nachhaltigkeitsrichtlinie prüft die KfW den Umgang mit Umwelt-, Sozial- und Klima-Aspekten, dies gilt für:',
        bullet1:
          'Vorhaben, die gemäß Gesetz über die Umweltverträglichkeit (UVPG) einer Pflicht zur Durchführung einer Umweltverträglichkeitsprüfung unterliegen oder',
        bullet2: 'dem Neubau von Krankenhäuser, Schwimmhallen, Schwimmbädern oder',
        bullet3:
          'Größeren Vorhaben im Bereich ÖPNV (Neubau U-oder S-Bahn) sowie zum Ausbau von Wasserstraßen (Neubau oder Vorhaben verbunden mit mit Änderungen des natürlichen Flussverlaufes).',
        option1:
          'Bitte geben Sie an, ob mit den KfW-Mitteln mindestens eines der oben genannten Kriterien in Ihrem Vorhaben finanziert wird: *',
        option2:
          'Alle für die Prüfung der Umweltverträglichkeit des (mit-)finanzierten Vorhabens erforderlichen Genehmigungen liegen vor? *',
        option3:
          'Da die erforderlichen Genehmigungen nicht vorliegen, bitten wir um eine Begründung (z.B. sind noch in Bearbeitung): *',
        yesOrNoLookup,
      },
      ProjectPurpose: {
        title: 'Verwendungszwecke für den beantragten Förderkredit/-zuschuss',
        infoText1:
          'Bitte ordnen Sie den Verwendungszwecken einen Betrag zu, die Sie in Ihrem Vorhaben umsetzen möchten.',
        purpose: 'Verwendungszweck',
        amount: 'Betrag',
        showAdditionalPurposes: 'weitere Verwendungszwecke einblenden',
        sum: 'Summe',
        sum_infoText1: 'Summe Beträge',
        sumsEqual: 'Vielen Dank für Ihre Angaben.',
        sumsDifferentErr:
          'Bitte passen Sie Ihre Angaben hinsichtlich des beantragten Kredit-/Zuschussbetrags oder der Beträge zu den Verwendungszwecken an, sodass deren Summe dem Kredit-/Zuschussbetrag entspricht.',
        creditAmount: 'Beantragter Betrag',
        creditAmount_infoText1: 'Beantragter Kredit-/Zuschussbetrag',
        thirdPartyFunding: 'Es ist eine Weiterleitung der KfW-Mittel (als Kredit oder Zuschuss) an Dritte vorgesehen.',
        thirdPartyFundingBeneficiary: 'Begünstigter',
        legalForm: 'Rechtsform',
      },
    },
    InvestPlan: {
      title: 'Gesamt Investitions- und Finanzierungsplan',
      investitionPlan: 'Investitionsplan',
      title432: 'Gesamt Kosten- und Finanzierungsplan',
      costPlan: 'Kostenplan',
      financingPlan: 'Finanzierungsplan',
      financing: 'Finanzierung',
      costField: 'Kostenfeld',
      financeType: 'Finanzierungsart',
      investAmount: 'Betrag',
      financingAmount: 'Betrag',
      investition: 'Investition',
      investSum: 'Summe',
      financingSum: 'Summe Finanzierungsplan',
      applicantCredit: 'Beantragter Kredit-/Zuschussbetrag',
      creditAmount: 'Beantragter Betrag',
      sum: 'Summe',
      sumsEqual: 'Vielen Dank für Ihre Angaben.',
      sumsDifferentErr:
        'Derzeit weichen die Summen des Investitionsplans und Finanzierungsplans ab. Bitte korrigieren Sie Ihre Angaben.',
      investitionList,
      financingList,
      investitionList432,
      financingList432,
      otherSourcesHeading: 'Angabe des Mittelgebers',
      nameOtherSource: 'Name des Mittelgebers',
    },
    ProductSpecificData: {
      title: 'Produktspezifische Angaben',
      infoText1: 'Weitere Details zu Ihrem Investitionsvorhaben',
    },
    ImplementationDuration: {
      title: 'Angaben zur Umsetzungsdauer',
      infoText1:
        'Bitte geben Sie zu den einzelnen Verwendungszwecken den Zeitpunkt an, zu dem die Umsetzung beginnen und zu dem Sie - gemäß Planung - enden soll.',
      purpose0: 'Erstellung eines integrierten Konzeptes',
      purpose1: 'Sanierungsmanager',
      purpose2: 'Sanierungsmanager',
      purpose3: 'Verstetigung Sanierungsmanager',
      purpose4: 'Verstetigung Sanierungsmanager',
      begin: 'Beginn',
      endDate: 'geplante Fertigstellung',
      beginDateError: 'Das Datum des Beginns der Umsetzung muss in der Zukunft liegen.',
      endDateError: 'Das geplante Datum für den Abschluss muss nach dem Beginn liegen.',
    },
    ChargingStation: {
      title: 'Angaben zu Ladestationen',
      infoText1:
        'Gefördert wird die Errichtung und Inbetriebnahme von nicht öffentlich-zugänglichen Ladestation/-en zum Aufladen von kommunal genutzten elektrisch betriebenen Fahrzeugen und/oder von elektrisch betriebenen Fahrzeugen der Beschäftigten, jeweils eingesetzt für nicht-wirtschaftliche Tätigkeiten im Sinne des EU-Beihilfenrechts. Förderfähig sind Ladestationen, die hier aufgeführt sind:',
      listChargingStation: 'Liste förderfähiger Ladestationen',
      infoText2_1:
        'Eine Ladestation kann aus einem oder mehreren Ladepunkten bestehen. Bitte geben Sie für alle geplanten Station die jeweilige Anzahl der Ladepunkte und deren Adresse an. Bitte beachten Sie, dass eine Zusage nur möglich ist, wenn insgesamt ',
      infoText2_2: 'mindestens',
      infoText2_3: ' 10 Ladepunkte errichtet werden. ',
      infoText3_1: 'Fördervoraussetzung:',
      infoText3_2:
        ' Der für den Ladevorgang erforderliche Strom muss zu 100% aus erneuerbaren Energieen stammen. Er kann über einen entsprechenden Stromliefervertrag und/oder aus Eigenerzeugung vor Ort (z.B. Strom aus Photovoltaik-Anlagen) bezogen werden.',
      infoText3_3: 'Mit Ihrem Antrag bestätigen Sie, dass die oben genannte Fördervoraussetzung vorliegt.',
      chargingStation: 'Ladestation',
      chargingPoint: 'Ladepunkte',
      address: 'Adresse',
      count: 'Anzahl',
      addChargingStations: 'weiteren Ladestation hinzufügen',
      totalNumberOfChargingPoints: 'Gesamtanzahl Ladepunkt',
      chargingPointError: 'Es muss mindestens einen Ladepunkt pro Ladestation geben.',
      totalChargingPointsError: 'Sie müssen insgesamt mindestens 10 Ladepunkte errichten.',
    },
    InformationDistrictConcept: {
      declarationsTitle: 'Angaben zum Quartier',
      title: 'Angaben zur Ergänzung eines bereits bezuschussten Quartierskonzeptes',
      infoText1:
        'Sofern Sie bereits einen Zuschuss zur energetischen Sanierung erhalten haben und das zugrundeliegende Konzept noch um fehlende Aspekte ergänzen möchten, bitten wir um weitere Details.',
      categories: {
        label: 'Die Ergänzung betrifft',
      },
      additionsLookup,
      grantNo: 'KfW-Nummer des zugrundeliegenden Zuschusses',
      grantNoHint: 'KfW-Zuschussnummer',
      grantNoError:
        'Bitte geben Sie die KfW-Zuschussnummer des für das ursprüngliche Quatierskonzept bereits beantragten Zuschusses an.',
      qualification: 'Das betreute Quartier ist förderfähig im Sinne der Definition des Programmmerkblatts. *',
      qualificationError:
        'Bitte bestätigen Sie, dass das betreute Quartier förderfähig im Sinne der Definition des Programmmerkblatts ist.',
      districtName: 'Name des Quartiers',
    },
    RemediationManagement: {
      title: 'Angaben zum Sanierungsmanagement',
      infoText1:
        'Bitte geben Sie an, in welche der folgenden Kategorien die Qualifikation der am Sanierungsmanagement beteiligten Personen fällt.',
      categories: {
        label: 'Die Ergänzung betrifft',
      },
      error: 'Bitte wahlen Sie mindestens eine Quailifikation aus.',
      remediationsLookup,
      qualification: 'Die Qualifikation i.S. der Definition des Programmmerkblatts liegt vor.',
      qualificationError: 'Bitte bestätigen Sie, dass die Qualifikation im Sinne des Programmmerkblatts vorliegt.',
    },
    Attachments: {
      title: 'Zusätzliche Anlagen',
      infoText1:
        'Zu Ihrem Antrag gehören weitere Anlagen, die Sie jedoch nicht in jedem Fall auch alle Ihrem KfW-Antrag beilegen. Die Kategorie sowie die jeweiligen Erläuterungen zur Anlage sollen Ihnen helfen zu entscheiden, unter welchen Bedingungen die betreffende Anlage tatsächlich für die Antragstellung bei der KfW erforderlich ist. Sollten Sie Zweifel über die Notwendigkeit einer Anlage haben, steht Ihnen Ihr KfW-Ansprechpartner bzw. das KfW-Infocenter gerne telefonisch zur Verfügung.',
      categoryLabel: 'Kategorie der Anlage: ',
      linkLabel: 'Externer Link zur Anlage',
      formNumberLabel: 'KfW-Formularnummer: ',
    },
    Consent: {
      title: 'Einverständnis',
      dataProtection: 'Datenschutz',
      useOfData: 'Verwendung unserer Daten durch die KfW',
      applicantDeclaration: 'Erklärungen zum Antrag',
      declarationCheckbox: 'Hiermit erklären wir unser Einverständnis mit den vorgenannten Erklärungen *',
      declarationDataProtectionCheckbox:
        'Hiermit bestätigen wir, dass wir die Datenschutzhinweise der KfW zur Kenntnis genommen haben *',
      checkboxError: '▲ Bitte stimmen Sie den Bedingungen zu',
    },
    IKSummary: {
      title: 'Vielen Dank für Ihre Angaben!',
      subtitle:
        'Mit Ihren Daten haben Sie nun bestens Ihren KfW-Antrag vorbereitet. Speichern Sie jetzt Ihre zusammengefassten Angaben als PDF-Dokument, unterschreiben Sie diesen und übersenden Sie uns den Antrag.',
      pdf_download: 'PDF speichern',
      code: 'Vorantrags-Code: ',
      nextsteps_title: 'So geht es weiter:',
      nextsteps_subtitle: '',
      credit: {
        nextsteps_header_1: 'Kredit beantragen',
        nextsteps_details_1:
          'Übersenden Sie Ihren Antrag inkl. aller Unterlagen. Am einfachsten geht’s per E-Mail: Senden Sie den Antrag an ',
        nextsteps_details_1_email: 'kommune@kfw.de',
        nextsteps_details_1_last: '. Wir prüfen Ihre Unter­lagen und machen Ihnen ein Kredit­angebot.',
        nextsteps_header_2: 'Kreditangebot annehmen',
        nextsteps_details_2: 'Reichen Sie dazu die Annahmeerklärung ein.',
        nextsteps_header_3: 'Fördermittel abrufen und einsetzen',
        nextsteps_details_3:
          'Sie erhalten eine schriftliche Bestätigung. Danach können Sie Ihre Förder­mittel abrufen. Die Unterlagen für Ihren Abruf senden Sie per E-Mail an ',
        nextsteps_details_3_email: 'auszahlungen-kommunen@kfw.de',
        nextsteps_details_3_last:
          '. In der Regel stellen wir die Mittel innerhalb eines Bank­arbeits­tages für Sie bereit.',
      },
      grant: {
        nextsteps_header_1: 'Zuschuss beantragen',
        nextsteps_details_1: 'Übersenden Sie Ihren Antrag. Am einfachsten geht’s per E-Mail: Senden Sie den Antrag an ',
        nextsteps_details_1_email: 'kommune@kfw.de',
        nextsteps_header_2: 'Ihr Antrag wird geprüft',
        nextsteps_details_2: 'Die KfW prüft Ihre Unter­lagen und stellt den Zuschuss bereit.',
        nextsteps_header_3: 'Verwendung nachweisen',
        nextsteps_details_3:
          'Nach Abschluss Ihres Vorhabens reichen Sie den entsprechenden Verwendungsnachweis bei der KfW ein.',
      },
    },
    contentProgressBar: {
      generalRequestInformation: 'Erste Angaben',
      findProduct: 'Förderprodukt finden',
      financingDetails: 'Finanzierungswunsch',
      applicantSelection: 'Antragsteller/-in',
      projectDescription: 'Vorhabensbeschreibung',
      investitionPlan: 'Investitions-/Finanzierungsplan',
      productSpecificInput: 'Produktspezifische Angaben',
      attachments: 'Anlagen',
      consent: 'Einverständnis',
      submitRequest: 'Antrag generieren',
    },
  },
};
