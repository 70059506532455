import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useField } from 'react-final-form';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import legalList from 'types/Rechtsform';
import naceList from 'types/Branche_NACE_2008';

import { PERSON_TYPE } from 'common/constants';
import FormAutocomplete from '../../components/form/FormAutocomplete';
import FormDatePicker from '../../components/form/FormDatePicker';
import FormSwitch from '../../components/form/FormSwitch';

export default function CompanyInformation() {
  const { t } = useTranslation();
  const [specificNaceList, setSpecificNaceList] = useState(naceList);
  const [specificNaceDisabled, setSpecificNaceDisabled] = useState(true);
  const specificNace = useField('applicant.projectNace');

  const handleNaceChange = useCallback(
    (value: { id: number; title: string }) => {
      if (value !== null) {
        setSpecificNaceList(naceList.filter((entry) => entry.id > value.id && entry.id < value.id + 1000));
        setSpecificNaceDisabled(false);
        specificNace.input.onChange(null);
      } else {
        setSpecificNaceDisabled(true);
        specificNace.input.onChange(null);
      }
    },
    [specificNace.input],
  );

  return (
    <Grid container item className="formSubSection" data-testid="companyInformation">
      <Field name="applicant.applicantType" subscription={{ value: true }}>
        {({ input }) => (
          <Typography variant="h3" className="subheader">
            {input.value === PERSON_TYPE.COMPANIES
              ? t('oneApp.CompanyInformation.titleCompany')
              : t('oneApp.CompanyInformation.titleSingleEntrepreneur')}
          </Typography>
        )}
      </Field>

      <Grid container item xs={12}>
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            name="applicant.legal"
            className="formItem"
            data={legalList}
            label={t('oneApp.CompanyInformation.legal')}
            data-testid="legal-autocomplete"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} className="formGroup">
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            name="applicant.industrySector"
            onChange={handleNaceChange}
            className="formItem"
            label={t('oneApp.CompanyInformation.industrySector')}
            data={naceList.filter((e) => e.id % 1000 === 0)}
            data-testid="industrySector-autocomplete"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            name="applicant.projectNace"
            className="formItem"
            label={t('oneApp.CompanyInformation.projectNace')}
            data={specificNaceList}
            disabled={specificNaceDisabled}
            data-testid="projectNace-autocomplete"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} className="formGroup">
        <Grid item xs={12} md={6}>
          <FormDatePicker
            name="applicant.foundingDate"
            className="formItem"
            label={t('oneApp.CompanyInformation.foundingDate')}
            data-testid="foundingDate-date"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormDatePicker
            name="applicant.businessStartingDate"
            className="formItem"
            label={t('oneApp.CompanyInformation.businessStartingDate')}
            tooltip={t('oneApp.CompanyInformation.businessStartingDateTooltip')}
            data-testid="businessStartingDate-date"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} md={12}>
        <FormSwitch
          name="applicant.isStartUp"
          label={t('oneApp.CompanyInformation.isStartUpLabel')}
          tooltip={t('oneApp.CompanyInformation.isStartUpTooltip')}
          data-testid="isStartUp-switch"
        />
      </Grid>

      <Grid container item xs={12} md={12}>
        <FormSwitch
          name="applicant.isEligibleToDeductTax"
          label={t('oneApp.CompanyInformation.isEligibleToDeductTaxLabel')}
          tooltip={t('oneApp.CompanyInformation.isEligibleToDeductTaxTooltip')}
          data-testid="isEligibleToDeductTax-switch"
        />
      </Grid>
    </Grid>
  );
}
