import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import FormTextField from '../components/form/FormTextField';
import { validators } from './formValidation';

const useStyles = makeStyles((theme) => ({
  infoText: {
    paddingBottom: '20px',
  },
  requestIdentifierWrapper: {
    width: '100%',
  },
  requestIdentifier: {
    width: '100%',
  },
  requestDetailsWrapper: {
    marginBottom: '-100px',
    visibility: 'hidden',
  },
  inputInfoText: {
    padding: '20px 0 10px 0',
    width: '100%',
  },
  pageTitle: {
    fontSize: '45px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '38px',
      paddingBottom: '20px !important',
    },
  },
  list: {
    marginLeft: '2rem',
    listStyleType: 'disc',
  },
  infoRightDesktop: {
    width: '100%',
    textAlign: 'right',
    padding: '0 !important',
    height: '0',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  infoRightMobile: {
    width: '100%',
    textAlign: 'right',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));

const INPUT_PROPS = {
  requestIdentifier: {
    maxLength: 255,
  },
};

export default function GeneralRequestInformation() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container item className="formSection" data-testid="generalRequestInformation">
      <Typography variant="h1" className={classes.pageTitle} role="heading">
        {t('oneApp.title')}
      </Typography>
      <Typography variant="body1" className={classes.infoRightDesktop}>
        {t('oneApp.infoRequired')}
      </Typography>
      <Typography variant="h2" className="header">
        {t('oneApp.GeneralRequestInformation.title')}
      </Typography>

      <Typography variant="body1">{t('oneApp.infoText1')}</Typography>
      <ul className={classes.list}>
        <li>
          <Typography>{t('oneApp.infoText2')}</Typography>
        </li>
        <li>
          <Typography>{t('oneApp.infoText3')}</Typography>
        </li>
        <li>
          <Typography>{t('oneApp.infoText4')}</Typography>
        </li>
      </ul>

      <Typography variant="body1" className={classes.infoRightMobile}>
        {t('oneApp.infoRequired')}
      </Typography>

      <Typography variant="body1" className={classes.infoText}>
        {t('oneApp.GeneralRequestInformation.infoText')}
      </Typography>
      <Grid container className={classes.requestIdentifierWrapper}>
        <Grid item xs={12} md={6}>
          <FormTextField
            className={classes.requestIdentifier}
            name="requestInformation.requestIdentifier"
            label={t('oneApp.GeneralRequestInformation.requestIdentifier')}
            required
            maxLength={INPUT_PROPS.requestIdentifier.maxLength}
            data-testid="requestIdentifier"
          />
        </Grid>
        <Typography variant="caption" className={classes.inputInfoText} id="requestIdentifier-hintText">
          {t('oneApp.GeneralRequestInformation.requestIdentifierInfo')}
        </Typography>
      </Grid>

      {/* honeypot field */}
      <Grid container className={classes.requestDetailsWrapper}>
        <Grid item xs={12} md={6}>
          <FormTextField
            skipDefaultValidation
            name="requestInformation.requestDetails"
            label={t('oneApp.GeneralRequestInformation.requestDetails')}
            required
            validate={validators.requestInformation.requestDetails}
            data-testid="requestDetails"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
