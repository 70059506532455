import GeneralRequestInformation from '../OneAppForm/GeneralRequestInformation';
import Applicant from '../OneAppForm/Applicant/Applicant';
import Notes from '../OneAppForm/Notes';
import Consent from '../OneAppForm/Consent';
import ProjectDetails from '../OneAppForm/ProjectDetails';
import Program from '../OneAppForm/Program/Program';

interface Props {
  next: string | false;
}

export interface ContentMenuItem {
  id: string;
  label: string;
  Component: (props: Props) => JSX.Element | null;
  isDefault?: boolean;
}

const menuItems: ContentMenuItem[] = [
  {
    id: 'generalRequestInformation',
    label: 'oneApp.contentProgressBar.generalRequestInformation',
    Component: GeneralRequestInformation,
    isDefault: true,
  },
  {
    id: 'program',
    label: 'oneApp.contentProgressBar.program',
    Component: Program,
  },
  {
    id: 'applicant',
    label: 'oneApp.contentProgressBar.applicant',
    Component: Applicant,
  },
  {
    id: 'projectDetails',
    label: 'oneApp.contentProgressBar.projectDetails',
    Component: ProjectDetails,
  },
  {
    id: 'notes',
    label: 'oneApp.contentProgressBar.notes',
    Component: Notes,
  },
  {
    id: 'consent',
    label: 'oneApp.contentProgressBar.consent',
    Component: Consent,
  },
];

export default menuItems;
