import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import FormAutocomplete from 'pages/oneApp/components/form/FormAutocomplete';
import FormTextField from '../../components/form/FormTextField';
import FormSelect from '../../components/form/FormSelect';
import countryList from '../../../../types/Land_des_Registergerichts';
import placeList from '../../../../types/Ort_des_Registergerichts';

const useStyles = makeStyles(() =>
  createStyles({
    registrationCountry: {
      marginTop: '20px',
    },
  }),
);

export default function Registration() {
  const { t } = useTranslation();
  const classes = useStyles();

  const registrationTypeLookup: { [key: string]: string } = t('oneApp.Registration.registrationTypeLookup', {
    returnObjects: true,
  });

  return (
    <Grid container item className="formSubSection" data-testid="registration">
      <Typography variant="h3" className="subheader">
        {t('oneApp.Registration.registeredTitle')}
      </Typography>

      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.registrationNumber"
            className="formItem"
            label={t('oneApp.Registration.registrationNumber')}
            maxLength={35}
            data-testid="registrationNumber"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSelect
            name="applicant.registrationType"
            className="formItem"
            label={t('oneApp.Registration.registrationType')}
            options={registrationTypeLookup}
            labelWidth={130}
            data-testid="registrationType-select"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} className="formGroup">
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            name="applicant.registrationPlaceDE"
            className="formItem"
            data={placeList}
            label={t('oneApp.Registration.registrationPlaceDE')}
            tooltip={t('oneApp.Registration.registrationPlaceDETooltip')}
            data-testid="registrationPlaceDE-autocomplete"
          />
        </Grid>
      </Grid>

      <Grid container item className={classes.registrationCountry}>
        <Typography variant="h3" className="subheader">
          {t('oneApp.Registration.registrationCountryTitle')}
        </Typography>

        <Grid container item xs={12} justifyContent="center" className="formGroup">
          <Grid item xs={12} md={6}>
            <FormAutocomplete
              name="applicant.registrationCountry"
              className="formItem"
              data={countryList}
              label={t('oneApp.Registration.registrationCountry')}
              tooltip={t('oneApp.Registration.registrationCountry_tooltip')}
              data-testid="registrationCountry-autocomplete"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              name="applicant.registrationPlace"
              className="formItem"
              label={t('oneApp.Registration.registrationPlace')}
              tooltip={t('oneApp.Registration.registrationPlace_tooltip')}
              maxLength={35}
              data-testid="registrationPlace"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
