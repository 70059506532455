import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import PersonIcon from '@material-ui/icons/Person';
import ExitToApp from '@material-ui/icons/ExitToApp';
import DeleteForever from '@material-ui/icons/DeleteForever';
import DescriptionIcon from '@material-ui/icons/Description';
import MenuItem from '@material-ui/core/MenuItem';

import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { DELETE_ACCOUNT } from 'common/constants/routes';
import Logo from '../Logo';
import { logoutByRedirect, redirectToTermsOfUse, redirectToProfileEdit } from '../../common/helpers/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 2000,
    backgroundColor: '#FFFFFF',
    color: theme.palette.primary.dark,
    '& .MuiToolbar-root': {
      [theme.breakpoints.up('md')]: {
        height: '78px',
        minHeight: '78px',
      },
      height: '58px',
      minHeight: '58px',
    },
  },
  title: {
    flexGrow: 1,
  },
  icon: {
    '& .MuiSvgIcon-root': {
      color: '#1b618c',
    },
  },
}));

export default function NavigationBar() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & Element) | null>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteAccount = () => {
    handleMenuClose();
    history.push(DELETE_ACCOUNT);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.icon}
    >
      <MenuItem onClick={redirectToProfileEdit} data-testid="profileEdit">
        <ListItemIcon>
          <PersonIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('menu.profile')}</ListItemText>
      </MenuItem>

      <MenuItem onClick={redirectToTermsOfUse} data-testid="termsOfUse">
        <ListItemIcon>
          <DescriptionIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('menu.termsOfUse')}</ListItemText>
      </MenuItem>

      <MenuItem onClick={handleDeleteAccount} data-testid="accountDelete">
        <ListItemIcon>
          <DeleteForever fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('menu.deleteAccount')}</ListItemText>
      </MenuItem>

      <MenuItem onClick={logoutByRedirect} data-testid="logout">
        <ListItemIcon>
          <ExitToApp fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('menu.logout')}</ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="static" className={classes.root}>
        <Toolbar className="full-width-container">
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Logo />
            </Grid>
            <Grid item className={classes.icon}>
              <IconButton
                edge="end"
                aria-label={t('common.profile')}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                // color="inherit"
                data-testid="profileMenu"
              >
                <PersonIcon />
              </IconButton>
              {/* <Button color="inherit" onClick={logoutByRedirect} aria-label={t('common.logout')} data-testid="logout">
                <PowerSettingsNewIcon />
              </Button> */}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </>
  );
}
