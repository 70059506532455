import isNotEmpty from 'util/isNotEmpty';

type Validators = {
  [key: string]: {
    [key: string]: (value: any, values: any) => boolean | string;
  };
};

export const validators: Validators = {
  applicant: {
    applicantType: (value: string) => !isNotEmpty(value),
    accountingType: (value: string) => !isNotEmpty(value),
    dateInPast: (value: string) => new Date(value) > new Date(),
  },
  foreClosure: {
    budgetEmergency: (value: string) => !isNotEmpty(value),
  },
  // consent: {
  //   dsgvoConfirmation: (isChecked: boolean) => !isChecked,
  // },
  ikkForm: {
    notARobot: (isChecked: boolean) => !isChecked,
  },
  requestInformation: {
    // honeypot field if filled in field is invalid
    requestDetails: (value: string) => isNotEmpty(value),
  },
  productSpecific: {
    remediationManagement: (value: any) => {
      let isValid = false;
      isValid = value?.length > 0;
      return !isValid;
    },
    districtConcept: (value: any, data: any) => {
      if (data?.informationDistrictConcept?.additions?.length > 0 && !isNotEmpty(value)) {
        return true;
      }
      return false;
    },
    districtConceptQualification: (isChecked: boolean, data: any) => {
      if (data?.informationDistrictConcept?.additions?.length > 0) {
        return !isChecked;
      }
      return false;
    },
  },
};
