import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  main: {
    background: '#fff',
    minHeight: '100vh',
    height: 'auto',
    paddingBottom: '100px',
  },
  contentContainer: {
    width: 'auto',
    padding: '70px 20px 20px 20px',
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      marginLeft: '200px',
    },
  },
  logoContainer: {
    position: 'fixed',
    paddingTop: '30px',
    paddingLeft: '50px',
    top: '0px',
    height: '120px',
    left: '0px',
    zIndex: 1299,
    [theme.breakpoints.down('sm')]: {
      height: '70px',
      paddingTop: '10px',
      background: '#FFF',
    },
    [theme.breakpoints.up('md')]: {
      width: '190px',
    },
  },
}));

export default useStyles;
