import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Link } from '@material-ui/core';
import { LoadingButtonContainer } from 'components/LoadingButton';

export default function KMUDeclaration() {
  const { t } = useTranslation();

  return (
    <Grid container item className="formAttachmentSection">
      <Typography variant="h4">{t('oneApp.ProjectDetails.kmuDeclarationHeading')}</Typography>

      <Typography variant="body1" className="row">
        {t('oneApp.ProjectDetails.kmuDeclarationText1')}
        <br />
        {t('oneApp.ProjectDetails.kmuDeclarationText2')}
      </Typography>

      <Link href={t('oneApp.ProjectDetails.kmuDeclarationLink1')} target="_blank">
        <LoadingButtonContainer
          color="primary"
          type="button"
          text={t('oneApp.ProjectDetails.kmuDeclarationLinkText1')}
          classes="link"
          data-testid="kmuDeclarationLink1"
        />
      </Link>
      <Typography variant="body1">{t('oneApp.ProjectDetails.kmuDeclarationText3')}</Typography>
      <Link href={t('oneApp.ProjectDetails.kmuDeclarationLink2')} target="_blank">
        <LoadingButtonContainer
          color="primary"
          type="button"
          text={t('oneApp.ProjectDetails.kmuDeclarationLinkText2')}
          classes="link"
          data-testid="kmuDeclarationLink2"
        />
      </Link>
    </Grid>
  );
}
