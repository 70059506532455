import React, { createContext, useContext, useState, useCallback } from 'react';

import { LOCAL_STORAGE_KEYS } from 'common/constants';
import { getOneAppRegisteredCreditRequests, importOneAppRegisteredCreditRequest } from '../common/api/apiClient';

import { Components } from '../types/models';

interface Props {
  oneAppCreditRequests: Components.Schemas.CreditRequestOneAppRegistered[];
  loadOneAppCreditRequests: () => Promise<void>;
  setOneAppCreditRequests: React.Dispatch<React.SetStateAction<Components.Schemas.CreditRequestOneAppRegistered[]>>;
}

const defaults: Props = {
  oneAppCreditRequests: [],
  loadOneAppCreditRequests: () => Promise.resolve(),
  setOneAppCreditRequests: () => {},
};

const Context = createContext(defaults);

export const useCreditRequestsContext = () => useContext(Context);

interface ProviderProps {
  children: React.ReactNode;
}

export const CreditRequestsProvider = ({ children }: ProviderProps) => {
  const [oneAppCreditRequests, setOneAppCreditRequests] = useState<Components.Schemas.CreditRequestOneAppRegistered[]>(
    defaults.oneAppCreditRequests,
  );

  const loadOneAppCreditRequests = useCallback(async () => {
    let requests: Components.Schemas.CreditRequestOneAppRegistered[] = [];
    try {
      if (localStorage.getItem(LOCAL_STORAGE_KEYS.IMPORT_ONE_APP_REQUEST) === 'true') {
        await importOneAppRegisteredCreditRequest();
        localStorage.removeItem(LOCAL_STORAGE_KEYS.IMPORT_ONE_APP_REQUEST);
      }
      requests = await getOneAppRegisteredCreditRequests();
    } finally {
      setOneAppCreditRequests(requests);
    }
  }, []);

  const contextValue = {
    oneAppCreditRequests,
    loadOneAppCreditRequests,
    setOneAppCreditRequests,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
