import moment from 'moment';
import { TEXT_FIELD_REG_EXP } from 'common/constants/regexp';
import { getIn } from 'final-form';
import menuItems from 'pages/oneApp/helpers/menuItems';
import isNotEmpty from './isNotEmpty';

export interface Errors {
  [key: string]: boolean;
}

export interface ValidatorsSection {
  [key: string]: (values: any) => any;
}

export interface Validators {
  [key: string]: ValidatorsSection;
}

export interface ValidationResult {
  isValid: boolean;
  errors: any;
}

export interface ValidationSectionResult {
  isValid: boolean;
  validations: {
    [key: string]: ValidationResult;
  };
}

type FocusableInput = { name: string; focus: () => void };

/*
|-----------------------------------------------------------------------------------------------------
| HELPER FUNCTION FOR FORM SECTION VALIDATION
|-----------------------------------------------------------------------------------------------------
*/

/**
 * Validate form section
 * @param validators validators for given section
 * @param values values needed for validators
 * @param setErrors function that sets validation result into context
 * @returns {ValidationResult} isValid - returns if whole form section is valid
 */
export const validateFormSection = (
  validators: ValidatorsSection,
  values: any,
  setErrors: React.Dispatch<React.SetStateAction<any>>,
): ValidationResult => {
  const errors: Errors = {};

  // validate each key
  Object.keys(validators).forEach((field) => {
    errors[field] = validators[field](values);
  });

  setErrors(errors);

  // isValid
  return { isValid: !Object.keys(errors).some((error) => errors[error]), errors };
};

/*
|-----------------------------------------------------------------------------------------------------
| OTHER 
|-----------------------------------------------------------------------------------------------------
*/

export const isEmpty = (value: any, forbidZero = false) => {
  const isEmpty = value === null || value === undefined || value === false;

  if (!isEmpty && typeof value === 'string') {
    const trimmedValue = value.trim();
    return trimmedValue === '' || trimmedValue === 'none';
  }

  if (!isEmpty && Array.isArray(value)) {
    return value.length === 0;
  }

  if (!isEmpty && typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  if (!isEmpty && forbidZero) {
    return value === 0;
  }

  return isEmpty;
};

export const hasAllowedCharsOnly = (value: string) => TEXT_FIELD_REG_EXP.test(value);

export const isEmptyOrHasInvalidCharacters = (value: string | undefined) => {
  let error: string | boolean = !isNotEmpty(value);

  if (!error && !hasAllowedCharsOnly(value as string)) {
    error = 'validation.onlyAllowedCharacters';
  }

  return error;
};

export const isEmptyOrHasInvalidCharactersOrFormat = (
  value: string | undefined,
  regExp: RegExp,
  formatErrMsg: string,
) => {
  const error = isEmptyOrHasInvalidCharacters(value);

  if (!error && !regExp.test(value as string)) {
    return formatErrMsg;
  }

  return error;
};

const isFocusableInput = (input: any) => !!(input && typeof input.focus === 'function');

export const getAllInputs = () => {
  if (!document) {
    return [];
  }

  return Array.prototype.slice.call(document.forms).reduce(
    (accumulator, form) =>
      accumulator.concat(
        Array.prototype.slice.call(form).filter((input: any) => {
          const isFocusable = isFocusableInput(input);

          return isFocusable;
        }),
      ),
    [],
  );
};

/*
  Returns all form fields with failed validation
*/
const getInvalidInputs = (inputs: FocusableInput[], errors: any) => {
  return inputs.filter((input: FocusableInput) => input.name && getIn(errors, input.name));
};

/*
  Returns first form field and sections with failed validation
*/
// todo fix type once data structure is clear
export const getInvalidFields = (errors: any, inputs?: any) => {
  const allInputs = inputs || getAllInputs();
  const invalidFields = getInvalidInputs(allInputs, errors);
  const sections = menuItems.map((item) => document.getElementById(item.id)).filter((section) => section);

  return {
    firstError: invalidFields[0],
    invalidFields,
    invalidSections: (sections as HTMLElement[])
      .filter((section) => invalidFields.some((input: any) => section?.contains(input)))
      .map((section: HTMLElement) => section.id),
  };
};

/*
  Returns labels of all invalid form fields
*/
export const getInvalidFieldsError = (invalidFields: any, t: any) => {
  return invalidFields
    .map((invalidField: any) => t(`oneApp.webtrekk.errors.${invalidField.name}`))
    .reduce((prev: string[], curr: string) => {
      if (prev.indexOf(curr) < 0) {
        prev.push(curr);
      }
      return prev;
    }, []);
};

export const trimSpaces = (obj: any) => {
  // make deep copy of the obj so we are not modifying original one
  const object = JSON.parse(JSON.stringify(obj));
  Object.keys(object).forEach((key: string) => {
    if (typeof object[key] === 'object' && !Array.isArray(object[key]) && object[key] !== null) {
      object[key] = trimSpaces(object[key]);
    } else if (typeof obj[key] === 'string') {
      object[key] = object[key].trim();
    }
  });

  return object;
};

export const removeEmpty = (obj: any) => {
  // make deep copy of the obj so we are not modifying original one
  const object = JSON.parse(JSON.stringify(obj));
  Object.keys(object).forEach((key: string) => {
    if (typeof object[key] === 'object' && !Array.isArray(object[key]) && object[key] !== null) {
      object[key] = removeEmpty(object[key]);
    } else if (isEmpty(obj[key])) {
      delete object[key];
    }
  });

  return object;
};

export const isValidDate = ({ date, maxDate, minDate }: any) => {
  const newDate = moment(date);
  return (
    newDate.isValid() &&
    newDate.isSameOrBefore(maxDate ? moment(maxDate) : moment('2100-01-01'), 'days') &&
    newDate.isSameOrAfter(minDate ? moment(minDate) : moment('1900-01-01'), 'days')
  );
};
