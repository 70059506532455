export default (value) => {
  let isNotEmpty = value !== null && value !== undefined && value !== false && value !== 0;

  if (isNotEmpty && typeof value === 'string') {
    const trimmedValue = value.trim();
    isNotEmpty = trimmedValue !== '' && trimmedValue !== 'none';
  }

  if (isNotEmpty && Array.isArray(value)) {
    isNotEmpty = value.length > 0;
  }

  if (isNotEmpty && typeof value === 'object') {
    isNotEmpty = Object.keys(value).length > 0;
  }

  return isNotEmpty;
};
