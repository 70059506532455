import React, { useEffect, useCallback } from 'react';
import { generateIKKPdf } from 'common/api/apiClient';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { useHistory } from 'react-router-dom';
import { IKK_APP } from 'common/constants/routes';
import { MAPP_DL } from 'common/constants';
import { LoadingButtonContainer } from '../../components/LoadingButton';
import { useIKKContext } from './context/IKKContext';
import { updateMappDLSummaryPage, getViewportSize, dispatchMappDLEvent } from '../../components/MappDL';

const useStyles = makeStyles((theme) =>
  createStyles({
    summaryContainer: {
      maxWidth: '1175px',
      padding: '3rem',
      margin: 'auto',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-end',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '50px 0 0 0',
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: '130px',
      },
    },
    title: {
      marginBottom: '20px',
    },
    container: {
      padding: '1rem',
    },
    downloadSection: {
      marginTop: '1rem',
    },
    button: {
      margin: '1rem 0 1rem 0',
      minWidth: '145px',
    },
    list: {
      listStyleType: 'decimal',
      marginLeft: '1rem',
      '& li': {
        color: '#5a6166',
        fontWeight: 'bold',
        '&:not(:last-child)': {
          paddingBottom: '8px',
        },
      },
    },
    listBox: {
      border: 'none',
    },
    displaytogether: {
      display: 'flex',
      marginTop: '2%',
    },
    reducePadding: {
      padding: '-1%',
    },
    spacing: {
      marginRight: '0.5rem',
    },
    smallerlinespacing: {
      paddingBottom: '0.5rem',
    },
    linespacing: {
      paddingBottom: '1rem',
    },
    biggerLinespacing: {
      paddingBottom: '1.5rem',
    },
    listItemTitle: {
      fontWeight: 'bold',
    },
  }),
);

const { EVENTS } = MAPP_DL;

export default function IkSummary() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { request, loading, setLoading } = useIKKContext();
  const history = useHistory();

  useEffect(() => {
    if (request && request.requestId) {
      const viewportSize = getViewportSize();

      const mappDLSummaryPage = updateMappDLSummaryPage({
        pp5: `Viewport ${viewportSize}`,
        ep11: request.requestId,
      });

      window.mappDL = mappDLSummaryPage;
      dispatchMappDLEvent(EVENTS.MAPP_DL_READY);
    }

    // eslint-disable-next-line no-underscore-dangle
    if (!request || !request.requestId) history.push(IKK_APP);
  }, [history, request]);

  const handlePdfDownload = useCallback(async () => {
    try {
      setLoading(true);
      if (request?.product) {
        const response = await generateIKKPdf(request);
        let { fileName } = response;
        if (!fileName) {
          fileName = `KfW_Antrag_${request.product.id}_${request.requestId}.pdf`;
        }
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, fileName);
      }
    } finally {
      setLoading(false);
    }
  }, [request, setLoading]);

  const isCreditProgram = request?.product?.productCategory === 'credit';

  return (
    <Grid container item xs={12} md={10} className={classes.summaryContainer}>
      <Grid container item xs={12} className={classes.container} role="heading">
        <Grid container item xs={12}>
          <Typography variant="h1" className={classes.title}>
            {t('ikk.IKSummary.title')}
          </Typography>
          <Typography variant="body1">{t('ikk.IKSummary.subtitle')}</Typography>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <LoadingButtonContainer
            color="primary"
            size="large"
            loading={loading}
            classes={classes.button}
            startIcon={<DescriptionRoundedIcon />}
            onClick={handlePdfDownload}
            data-testid="pdf_download"
            text={t('ikk.IKSummary.pdf_download')}
          />
        </Grid>
        <Grid item xs={12} sm={6} container justifyContent="center" direction="column">
          <Typography variant="body1">{t('ikk.IKSummary.code')}</Typography>
          <Typography variant="h5" data-testid="Vorantrags_code">
            {request?.requestId}
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.container}>
          <Typography variant="h3">
            {t('ikk.IKSummary.nextsteps_title')} <br /> {t('ikk.IKSummary.nextsteps_subtitle')}
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.container}>
          <ol className={classes.list}>
            <li>
              <Typography variant="body1" className={classes.listItemTitle}>
                {isCreditProgram
                  ? t(`ikk.IKSummary.credit.nextsteps_header_1`)
                  : t(`ikk.IKSummary.grant.nextsteps_header_1`)}
              </Typography>
              <Typography variant="body1">
                {isCreditProgram
                  ? t(`ikk.IKSummary.credit.nextsteps_details_1`)
                  : t(`ikk.IKSummary.grant.nextsteps_details_1`)}
                {isCreditProgram ? (
                  <a
                    href={`mailto:${t(`ikk.IKSummary.credit.nextsteps_details_1_email`)}`}
                    style={{ textDecoration: 'underline' }}
                  >
                    {t(`ikk.IKSummary.credit.nextsteps_details_1_email`)}
                  </a>
                ) : (
                  <a
                    href={`mailto:${t(`ikk.IKSummary.grant.nextsteps_details_1_email`)}`}
                    style={{ textDecoration: 'underline' }}
                  >
                    {t(`ikk.IKSummary.grant.nextsteps_details_1_email`)}
                  </a>
                )}
                {isCreditProgram ? t('ikk.IKSummary.credit.nextsteps_details_1_last') : ''}
              </Typography>
            </li>
            <li>
              <Typography variant="body1" className={classes.listItemTitle}>
                {isCreditProgram
                  ? t(`ikk.IKSummary.credit.nextsteps_header_2`)
                  : t(`ikk.IKSummary.grant.nextsteps_header_2`)}
              </Typography>
              <Typography variant="body1">
                {isCreditProgram
                  ? t(`ikk.IKSummary.credit.nextsteps_details_2`)
                  : t(`ikk.IKSummary.grant.nextsteps_details_2`)}
              </Typography>
            </li>
            <li>
              <Typography variant="body1" className={classes.listItemTitle}>
                {isCreditProgram
                  ? t(`ikk.IKSummary.credit.nextsteps_header_3`)
                  : t(`ikk.IKSummary.grant.nextsteps_header_3`)}
              </Typography>
              <Typography variant="body1">
                {isCreditProgram
                  ? t(`ikk.IKSummary.credit.nextsteps_details_3`)
                  : t(`ikk.IKSummary.grant.nextsteps_details_3`)}
                {isCreditProgram ? (
                  <a
                    href={`mailto:${t(`ikk.IKSummary.credit.nextsteps_details_3_email`)}`}
                    style={{ textDecoration: 'underline' }}
                  >
                    {t(`ikk.IKSummary.credit.nextsteps_details_3_email`)}
                  </a>
                ) : (
                  ''
                )}
                {isCreditProgram ? t('ikk.IKSummary.credit.nextsteps_details_3_last') : ''}
              </Typography>
            </li>
          </ol>
        </Grid>
      </Grid>
    </Grid>
  );
}
