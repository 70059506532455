import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import kfwLogoPng from '../assets/img/kfw_logo.png';
import kfwLogoSvg from '../assets/img/kfw_logo_1280-2x.svg';
import kfwLogoSvgSmall from '../assets/img/kfw_logo_320-2x.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    // required to move the logo for smaller sizes to center
    logoBar: {
      [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
      },
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      },
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    logo: {
      '& img': {
        [theme.breakpoints.up('lg')]: {
          height: '70px',
        },
        [theme.breakpoints.down('md')]: {
          height: '64px',
        },
        [theme.breakpoints.down('sm')]: {
          height: '39px',
        },
        width: 'auto',
      },
    },
  }),
);

export default function Logo() {
  const classes = useStyles();

  return (
    <div className={classes.logoBar}>
      <a href="https://www.kfw.de" data-testid="headerLogo">
        {/* copied from the main KFW page */}
        <picture className={classes.logo}>
          <source media="(min-width: 960px)" srcSet={kfwLogoSvg} type="image/svg+xml" />
          <source srcSet={kfwLogoSvgSmall} type="image/svg+xml" />
          <img src={kfwLogoPng} data-pfsrc={kfwLogoPng} alt="KfW-Logo - Zur KfW-Startseite" />
        </picture>
      </a>
    </div>
  );
}
