import React, { useEffect } from 'react';
import { Field, useField } from 'react-final-form';

import { Grid } from '@material-ui/core';

import { PERSON_TYPE, MAPP_DL } from 'common/constants';
import SingleEntrepreneur from './Generalnformation/SingleEnterpreneur';
import Company from './Generalnformation/Company';
import NonProfit from './Generalnformation/NonProfit';
import CompanySize from './Generalnformation/CompanySize';
import Address from './Generalnformation/Address';
import CompanyIdentification from './Generalnformation/CompanyIdentification';
import Registration from './Generalnformation/Registration';
import Certification from './Generalnformation/Certification';
import License from './Generalnformation/License';
import PersonalIdentification from './Generalnformation/Personaldentification';
import CompanyInformation from './Generalnformation/CompanyInformation';
import ContactDetails from './Generalnformation/ContactDetails';
import { dispatchMappDLEvent } from '../../../components/MappDL';

const generalInformationItems = {
  [PERSON_TYPE.COMPANIES]: [
    {
      id: 'company',
      Component: Company,
    },
    {
      id: 'companyInformation',
      Component: CompanyInformation,
    },
    {
      id: 'address',
      Component: Address,
    },
    {
      id: 'contactDetails',
      Component: ContactDetails,
    },
    {
      id: 'companyIdentification',
      Component: CompanyIdentification,
      props: {
        applicantType: PERSON_TYPE.COMPANIES,
      },
    },
    {
      id: 'registration',
      Component: Registration,
    },
    {
      id: 'certification',
      Component: Certification,
    },
    {
      id: 'license',
      Component: License,
    },
    {
      id: 'nonProfit',
      Component: NonProfit,
    },
    {
      id: 'companySize',
      Component: CompanySize,
    },
  ],
  [PERSON_TYPE.SINGLE_ENTERPRENEURS]: [
    {
      id: 'singleEntrepreneur',
      Component: SingleEntrepreneur,
    },
    {
      id: 'companyInformation',
      Component: CompanyInformation,
    },
    {
      id: 'address',
      Component: Address,
    },
    {
      id: 'contactDetails',
      Component: ContactDetails,
    },
    {
      id: 'personalIdentification',
      Component: PersonalIdentification,
    },
    {
      id: 'companyIdentification',
      Component: CompanyIdentification,
      props: {
        applicantType: PERSON_TYPE.SINGLE_ENTERPRENEURS,
      },
    },
    {
      id: 'registration',
      Component: Registration,
    },
    {
      id: 'license',
      Component: License,
    },
    {
      id: 'nonProfit',
      Component: NonProfit,
    },
    {
      id: 'companySize',
      Component: CompanySize,
    },
  ],
};

const { EVENTS } = MAPP_DL;

export default function GeneralInformation() {
  const applicantTypeField = useField('applicant.applicantType');
  const applicantTypeFieldValue = applicantTypeField.input.value;

  useEffect(() => {
    dispatchMappDLEvent(EVENTS.MAPP_INIT_FORM_UPDATE);
  }, [applicantTypeFieldValue]);

  return (
    <Field name="applicant.applicantType" subscription={{ value: true }}>
      {({ input }) => {
        if (!input.value) {
          return null;
        }
        return (
          <Grid container>
            {generalInformationItems[input.value].map(({ id, Component, props = {} }) => (
              <Grid item xs={12} key={id}>
                <Component {...props} />
              </Grid>
            ))}
          </Grid>
        );
      }}
    </Field>
  );
}
