import React from 'react';
import classnames from 'classnames';
import { Field } from 'react-final-form';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ToolTipIcon from 'components/fields/ToolTipIcon';

const useStyles = makeStyles((theme) => ({
  label: {
    [theme.breakpoints.down('xs')]: {
      wordBreak: 'break-all',
    },
  },
  alignTooltip: {
    flexWrap: 'nowrap',
  },
}));

const MultiColoredRadio = withStyles({
  root: {
    '& svg:nth-child(2)': {
      color: '#00446e',
    },
  },
})((props) => <Radio color="default" {...props} />);


const FormRadio = ({
  tooltip,
  label,
  value,
  className,
  validate,
  'data-testid': dataTestId,
  defaultValue,
  ...props
}: any) => {
  const classes = useStyles();

  return (
    <Field
      {...props}
      validate={validate}
      type="radio"
      subscription={{ value: true, modified: true, error: true, submitFailed: true }}
      render={({ input, meta }) => {
        return (
          <Grid container alignItems="center">
            <Grid container item xs={tooltip && 12} className={classes.alignTooltip}>
              <FormControlLabel
                value={value}
                className={classnames(className, { error: meta.error && (meta.modified || meta.submitFailed) })}
                control={
                  <MultiColoredRadio
                    {...props}
                    {...input}
                    data-testid={dataTestId}
                    // eslint-disable-next-line eqeqeq
                    checked={defaultValue == value} // Ignore warning here, == is correct. We are comparing string to number
                    inputProps={{
                      'aria-labelledby': dataTestId,
                    }}
                  />
                }
                label={
                  typeof label === 'string' ? (
                    <Typography variant="body1" id={dataTestId} className={classes.label}>
                      {label}
                    </Typography>
                  ) : (
                    label
                  )
                }
              />
              {tooltip && <ToolTipIcon tip={tooltip} />}
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default React.memo(FormRadio);
