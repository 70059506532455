import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { formatProgramId, formatShortLink } from 'util/formatUtils';
import { Components } from 'types/models';
import SelectionCard from '../../components/common/SelectionCard';

const useStyles = makeStyles(() => ({
  card: {
    minWidth: '530px',
  },
  subtitle: {
    marginBottom: '10px',
  },
  link: {
    position: 'relative',
    padding: '20px 10px 0 0',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  arrowIcon: {
    fontSize: '12px',
  },
  list: {
    marginLeft: '20px',

    '& li': {
      position: 'relative',

      '&:before': {
        content: '"•"',
        verticalAlign: 'middle',
        paddingRight: '5px',
        position: 'absolute',
        left: '-10px',
      },
    },
  },
  content: {
    padding: '0 20px',
  },
}));

type ProductCardProps = {
  product: Components.Schemas.ProductFoea;
  productTypeKey: string;
  className?: string;
};

export default function ProductCard({ product, productTypeKey, className }: ProductCardProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const content = (
    <Grid container item className={classes.content}>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.subtitle}>
          {product.productName || t('ikk.ProductCard.noDetailsFound')}
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          {product.tileHeader || t('ikk.ProductCard.noDetailsFound')}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={8}>
        {!product.tileDescription ? (
          t('ikk.ProductCard.noDetailsFound')
        ) : (
          <ul className={classes.list}>
            {product.tileDescription.map((desc: string, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={`${product.productId}_${index}`}>
                <Typography variant="body1">{desc}</Typography>
              </li>
            ))}
          </ul>
        )}
      </Grid>
      <Grid container item xs={12} lg={4} alignItems="flex-end" justifyContent="flex-end">
        <Link
          target="_blank"
          href={product.productPage || `https://www.kfw.de/${formatShortLink(`${product.productId}`)}`}
          className={classes.link}
        >
          <span className={classnames(classes.arrowIcon, 'icArrowsRight')} />
          {t('ikk.ProductCard.toDetails')}
        </Link>
      </Grid>
    </Grid>
  );

  return (
    <SelectionCard
      title={formatProgramId(`${product.productId}`)}
      className={className}
      content={content}
      product={product}
      productTypeKey={productTypeKey}
    />
  );
}
