import React from 'react';
import { FieldSubscription } from 'final-form';
import { Field } from 'react-final-form';

type FieldsProps = {
  names: string[];
  subscription?: FieldSubscription;
  children?: React.ReactNode;
  fieldsState?: object;
  originalRender?: any;
};

/**
 * Get field state from multiple fields at once using recursion
 * @param names e.g. ["firstName", "lastName", "employed", "notes"]
 * @param subscription
 * @param fieldState
 * @param children
 * @param originalRender
 */
const Fields = ({
  names,
  subscription,
  fieldsState = {},
  children,
  originalRender,
}: FieldsProps): React.ReactElement => {
  if (!names.length) {
    return (originalRender || children)(fieldsState);
  }
  const [name, ...rest] = names;
  return (
    <Field name={name} subscription={subscription}>
      {(fieldState) => (
        <Fields
          names={rest}
          subscription={subscription}
          originalRender={originalRender || children}
          fieldsState={{ ...fieldsState, [name]: fieldState }}
        />
      )}
    </Field>
  );
};

export default Fields;
