/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import FormNumberInput from '../../components/form/FormNumberInput';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  hidden: {
    display: 'none',
  },
  inline: {
    display: 'inline-block',
  },
  purposeWrapperTable: {
    width: '100%',
  },
  purposeWrapperHeading: {
    textAlign: 'left',
    padding: '.5em',
  },
  purposeWrapper: {
    padding: '.3em',
    width: '65%',
  },
  infoIcon: {
    fontFamily: 'kfw-icons',
    fontWeight: 'bold',
    borderRadius: '50%',
    width: '1.5em !important',
    height: '1.5em !important',
    padding: '0 0.25em',
    marginLeft: '-2em',
    marginRight: '.5em',
    display: 'inline-block',
  },
  error: {
    color: '#ca0036',
  },
  passed: {
    color: '#60800f',
  },
  projectPurpose_infoIconWrp: {
    position: 'relative',
    display: 'inline-block',
    '&:hover .projectPurpose_tooltip': {
      display: 'block',
    },
    '& .projectPurpose_tooltip': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
      backgroundColor: '#f6f3f0',
      margin: '8px',
      padding: '8px',
      maxWidth: '450px',
      minWidth: '200px',
      display: 'none',
      position: 'absolute',
      zIndex: '255',
      right: '0',
    },
  },
}));
interface PurposeItem {
  data: any;
}

interface Purpose {
  intendedUse: string;
}

export default function ProjectPurpose({ creditAmountMainField, creditAmountShadowField, productDetails }: any) {
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useForm();
  const AMOUNT_REQUEST_FIELD_PREFIX = 'projectPurpose.amounts.amtRequest';

  const purposesGroups = [[], [0], [1], [2], [3], [4], [1, 2], [2, 1], [3, 4], [4, 3]];
  const onePurposeProducts = ['264', '271', '272'];

  const purposes: PurposeItem[] = productDetails.fundingPurposes.map((purpose: Purpose) => {
    return {
      data: purpose,
    };
  });

  const defaultValue = form.getFieldState(creditAmountMainField)?.value || '';

  const [showIcon, setShowIcon] = useState<Boolean>(false);
  const [showError, setShowError] = useState<Boolean>(false);

  const handleSumChange = (value: string | null, index: number | null) => {
    let sum: number = 0;
    purposes.forEach((_item, itemIndex) => {
      if (itemIndex === index) {
        if (value) {
          sum += parseFloat(value) || 0;
        }
      } else {
        sum += parseFloat(form.getFieldState(AMOUNT_REQUEST_FIELD_PREFIX + itemIndex)?.value || '0');
      }
    });
    form.change('projectPurpose.sum', sum);
    const sum1 = parseFloat(form.getFieldState('projectPurpose.sum')?.value || '0');
    const sum2 = parseFloat(form.getFieldState(creditAmountMainField)?.value || '0');
    setShowIcon(true);
    if (sum1 === sum2) {
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  const validateHandler = (value: string, allValues: any, meta: any) => {
    const productId = form.getState().values.product.id;

    if (productId && productId === '432') {
      // Only allow certain combination of purposes
      if (
        allValues.projectPurpose &&
        meta.name.includes('projectPurpose.amounts.amtRequest') &&
        allValues.projectPurpose.amounts
      ) {
        const keys = Object.keys(allValues.projectPurpose.amounts);
        const filledPurposes = keys.filter((key) => key.includes('amtRequest'));
        const metaLastName = meta.name.split('.').pop();
        const filledPurposesIndices = filledPurposes.map((key) => parseInt(key.slice(-1), 10));
        const includes = JSON.stringify(purposesGroups).includes(JSON.stringify(filledPurposesIndices));
        if (!includes && filledPurposes.includes(metaLastName)) {
          return 'validation.invalidPurposeCombination';
        }
      }
    }

    if (productId && onePurposeProducts.includes(productId)) {
      // Only allow one purpose to select
      if (allValues.projectPurpose && allValues.projectPurpose.amounts) {
        const keys = Object.keys(allValues.projectPurpose.amounts);
        const filledPurposes = keys.filter((key) => key.includes('amtRequest'));
        const metaLastName = meta.name.split('.').pop();
        if (filledPurposes.length >= 2 && filledPurposes.includes(metaLastName)) {
          return `validation.onePurposeAllowed${productId === '264' ? '2' : ''}`;
        }
      }
    }
  };

  return (
    <Grid container className="formSection">
      <Typography variant="h3" className={`${classes.fullWidth} header`}>
        {t('ikk.ProjectDescription.ProjectPurpose.title')}
      </Typography>
      <Typography variant="body1">{t('ikk.ProjectDescription.ProjectPurpose.infoText1')}</Typography>
      <Grid item xs={12} md={8}>
        <table data-testid="purpose-select" className={classes.purposeWrapperTable}>
          <thead>
            <tr>
              <th className={classes.purposeWrapperHeading}>{t('ikk.ProjectDescription.ProjectPurpose.purpose')}</th>
              <th className={classes.purposeWrapperHeading}>{t('ikk.ProjectDescription.ProjectPurpose.amount')}</th>
            </tr>
          </thead>
          <tbody>
            {purposes.map((p: any, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={index}>
                <td className={classes.purposeWrapper}>
                  <Typography>{p.data.intendedUse}</Typography>
                </td>
                <td className={classes.purposeWrapper}>
                  <FormNumberInput
                    name={AMOUNT_REQUEST_FIELD_PREFIX + index}
                    label={t('ikk.ProjectDescription.ProjectPurpose.amount')}
                    thousandSeparators
                    decimalScale={2}
                    integerScale={13}
                    isInline
                    validate={validateHandler}
                    data-testid={`projectPurposeAmt${index}`}
                    onChange={(evt: any) => {
                      handleSumChange(evt.target.value, index);
                    }}
                  />
                  {t('ikk.common.eur')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
      <Grid item xs={12} md={8}>
        <table className={classes.purposeWrapperTable}>
          <tbody>
            <tr>
              <td className={classes.purposeWrapper} />
              <td className={classes.purposeWrapper}>
                {!showIcon ? (
                  <div />
                ) : showError ? (
                  <div
                    id="projectPurpose.sum.error"
                    className={`${classes.projectPurpose_infoIconWrp} ${classes.error}`}
                  >
                    <ErrorIcon className={`${classes.infoIcon} error`} fontSize="large" />
                    <div className="projectPurpose_tooltip">
                      {t('ikk.ProjectDescription.ProjectPurpose.sumsDifferentErr')}
                    </div>
                  </div>
                ) : (
                  <div
                    id="projectPurpose.sum.passed"
                    className={`${classes.projectPurpose_infoIconWrp} ${classes.passed}`}
                  >
                    <CheckCircleIcon className={`${classes.infoIcon} passed`} fontSize="large" />
                    <div className="projectPurpose_tooltip">{t('ikk.ProjectDescription.ProjectPurpose.sumsEqual')}</div>
                  </div>
                )}
                <FormNumberInput
                  name="projectPurpose.sum"
                  label={t('ikk.ProjectDescription.ProjectPurpose.sum')}
                  thousandSeparators
                  decimalScale={2}
                  integerScale={13}
                  data-testid="projectPurposeSum"
                  isInline
                  disabled
                />
                {t('ikk.common.eur')}
                <Typography variant="body1">{t('ikk.ProjectDescription.ProjectPurpose.sum_infoText1')}</Typography>
              </td>
            </tr>
            <tr>
              <td className={classes.purposeWrapper} />
              <td className={classes.purposeWrapper}>
                <FormNumberInput
                  name={creditAmountShadowField}
                  label={t('ikk.ProjectDescription.ProjectPurpose.creditAmount')}
                  thousandSeparators
                  decimalScale={2}
                  integerScale={13}
                  data-testid="projectPurposeCreditAmountShdw"
                  disabled
                  isInline
                  defaultValue={defaultValue}
                  onChange={(evt: any) => {
                    handleSumChange(null, null);
                  }}
                />
                {t('ikk.common.eur')}
                <Typography variant="body1">
                  {t('ikk.ProjectDescription.ProjectPurpose.creditAmount_infoText1')}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
}
