import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { PHONE_PATTERN, EMAIL_PATTERN } from '../../constants/index';
import FormTextField from '../../components/form/FormTextField';
import FormSelect from '../../components/form/FormSelect';

const INPUT_PROPS = {
  email: {
    maxLength: 50,
  },
  phone: {
    maxLength: 20,
  },
  name: {
    maxLength: 35,
  },
};

export default function ContactDetails() {
  const { t } = useTranslation();

  const salutationLookup: { [key: string]: string } = t('ikk.ApplicantSelection.ContactDetails.salutationLookup', {
    returnObjects: true,
  });

  return (
    <Grid container item className="formSubSection" data-testid="contactDetails">
      <Typography variant="h3" className="subheader">
        {t('ikk.ApplicantSelection.ContactDetails.title')}
      </Typography>
      <Typography variant="body1">{t('ikk.ApplicantSelection.ContactDetails.subtitle')}</Typography>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormSelect
            name="applicant.addressing"
            autoComplete="honorific-prefix"
            className="formItem"
            label={t('ikk.ApplicantSelection.ContactDetails.adressing')}
            options={salutationLookup}
            labelWidth={60}
            data-testid="adressing-select"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.name"
            autoComplete="given-name"
            className="formItem"
            required
            label={t('ikk.ApplicantSelection.ContactDetails.name')}
            maxLength={INPUT_PROPS.name.maxLength}
            data-testid="firstName"
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.phone"
            autoComplete="tel"
            type="tel"
            className="formItem"
            label={t('ikk.ApplicantSelection.ContactDetails.phone')}
            pattern={PHONE_PATTERN}
            patternErrMsg="validation.invalidPhoneFormat"
            maxLength={INPUT_PROPS.phone.maxLength}
            data-testid="phone"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.email"
            autoComplete="email"
            type="email"
            className="formItem"
            label={t('ikk.ApplicantSelection.ContactDetails.email')}
            pattern={EMAIL_PATTERN}
            patternErrMsg="validation.invalidEmailFormat"
            maxLength={INPUT_PROPS.email.maxLength}
            data-testid="email"
            required
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
