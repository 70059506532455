import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { saveAs } from 'file-saver';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import {
  EnumAttachmentOneAppCompanySizeValues,
  EnumAttachmentOneAppConsentRequestValues,
  EnumAttachmentOneAppSubsidyValues,
  EnumDetailedOneAppProgramValues,
  EnumGbzaOneAppProgramValues,
  EnumSpecialProgramUBR,
} from 'types/AdditonalDoumentsEnum';
import { OneAppForPDF } from 'types/OneAppModels';
import {
  generateOneAppPdf,
  getOneAppRegisteredCreditRequestByID,
  deleteOneAppRegisteredCreditRequestByID,
} from '../../../common/api/apiClient';
import { ROOT } from '../../../common/constants/routes';

import { LoadingButtonContainer } from '../../../components/LoadingButton';
import DeleteOneAppCreditRequest from '../../../components/modals/creditRequests/DeleteOneAppCreditRequest';

import { Components } from '../../../types/models';
import { programs } from '../../oneApp/OneAppForm/Program/programLogic';

const useStyles = makeStyles((theme) =>
  createStyles({
    summaryContainer: {
      maxWidth: '1175px',
      padding: '3rem',
      margin: 'auto',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-end',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '50px 0 0 0',
      },
    },
    title: {
      marginBottom: '20px',
      minWidth: '150px',
    },
    container: {
      padding: '1rem',
    },
    button: {
      margin: '1rem 0 1rem 0',
      minWidth: '145px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: '1rem 0 1rem 0',
      },
    },
    list: {
      listStyleType: 'decimal',
      marginLeft: '1rem',
      '& li': {
        color: '#5a6166',
        fontWeight: 'bold',
        '&:not(:last-child)': {
          paddingBottom: '8px',
        },
      },
    },
  }),
);

export default function SummaryOneApp({ match }: any) {
  const [openDeleteOneAppConfirmModal, setOpenDeleteOneAppConfirmModal] = useState<boolean>(false);
  const [creditRequestOneApp, setCreditRequestOneApp] = useState<Components.Schemas.CreditRequestOneApp>();
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const hasProjectName =
    creditRequestOneApp?.requestInformation.requestIdentifier &&
    creditRequestOneApp?.requestInformation.requestIdentifier !== 'unknown';
  const nextStepCounter = ['1', '2', '3', '4', '5'];

  const processedRequest: OneAppForPDF | undefined = useMemo(() => {
    if (creditRequestOneApp) {
      const programId = creditRequestOneApp?.program?.program;

      if (programId) {
        return {
          ...creditRequestOneApp,
          program: {
            ...creditRequestOneApp.program,
            programTitle: programs[programId]?.title,
            hasGbzaInfo: EnumGbzaOneAppProgramValues.includes(programId),
            hasAccumulationInfo: EnumAttachmentOneAppSubsidyValues.includes(programId),
            isDetailedProgram: EnumDetailedOneAppProgramValues.includes(programId),
            hasCompanySizeInfo: EnumAttachmentOneAppCompanySizeValues.includes(programId),
            hasConsentInfo: EnumAttachmentOneAppConsentRequestValues.includes(programId),
            hasSanctionsDocument: EnumSpecialProgramUBR.includes(programId),
          },
        };
      }

      return creditRequestOneApp;
    }
  }, [creditRequestOneApp]);

  const loadCreditRequestOneApp = useCallback(async () => {
    try {
      const { id } = match.params;
      const requestOneApp = await getOneAppRegisteredCreditRequestByID(id);
      if (requestOneApp) {
        setCreditRequestOneApp(requestOneApp);
      }
    } catch (err) {
      if (err.response?.status === 404 || err.response?.status === 500) {
        history.push(ROOT);
      }
    }
  }, [match.params, setCreditRequestOneApp, history]);

  useEffect(() => {
    loadCreditRequestOneApp();
  }, [loadCreditRequestOneApp]);

  const handlePdfDownload = useCallback(async () => {
    try {
      setLoading(true);
      if (processedRequest) {
        const response = await generateOneAppPdf(processedRequest);
        let { fileName } = response;

        if (!fileName) {
          fileName = `KfW_Vorantrag_${processedRequest.requestId}.pdf`;
        }

        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, fileName);
      }
    } finally {
      setLoading(false);
    }
  }, [processedRequest, setLoading]);

  const toggleDeleteOneAppRequest = useCallback(() => {
    setOpenDeleteOneAppConfirmModal((openDeleteOneAppConfirmModal) => !openDeleteOneAppConfirmModal);
  }, []);

  const redirectToRoot = useCallback(() => {
    history.push(ROOT);
  }, [history]);

  const handleConfirmDeleteOneAppRequest = useCallback(async () => {
    await deleteOneAppRegisteredCreditRequestByID(creditRequestOneApp?.id as string);
    redirectToRoot();
  }, [creditRequestOneApp, redirectToRoot]);

  const projectName = useMemo(() => creditRequestOneApp?.requestInformation?.requestIdentifier, [creditRequestOneApp]);

  return (
    <>
      <Grid container item xs={12} md={10} className={classes.summaryContainer}>
        <Grid container item xs={12} className={classes.container}>
          <Grid container item xs={12} role="heading">
            {hasProjectName ? (
              <Typography variant="h1" className={classes.title}>
                {t('summaryScreenOneApp.title', {
                  projectName,
                })}
              </Typography>
            ) : (
              <Typography variant="h1" className={classes.title}>
                {t('summaryScreenOneApp.title', { projectName: '' })}
              </Typography>
            )}
            <Typography>{t('summaryScreenOneApp.subtitle')}</Typography>
          </Grid>

          <Grid container item xs={12} sm={6}>
            <LoadingButtonContainer
              color="primary"
              size="large"
              loading={loading}
              classes={classes.button}
              startIcon={<DescriptionRoundedIcon />}
              onClick={handlePdfDownload}
              data-testid="pdf_download"
              text={t('summaryScreenOneApp.pdf_download')}
            />
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent="center" direction="column">
            <Typography variant="body1">{t('summaryScreenOneApp.code')}</Typography>
            <Typography variant="h5" data-testid="Vorantrags_code">
              {creditRequestOneApp?.requestId}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.container}>
          <Typography variant="h3">{t('summaryScreenOneApp.nextsteps_title')}</Typography>
          <Typography variant="h3">{t('summaryScreenOneApp.nextsteps_subtitle')}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.container}>
          <ul className={classes.list}>
            {nextStepCounter.map((step: string) => (
              <li key={step}>
                <Typography variant="h5">{t(`summaryScreenOneApp.nextsteps_header_${step}`)}</Typography>
                <Typography>
                  {t(`summaryScreenOneApp.nextsteps_details_${step}`)}
                  {step === '1' && (
                    <Link
                      href="https://www.kfw.de/inlandsfoerderung/Unternehmen/Online-Beratungsanfrage/index.html"
                      target="_blank"
                    >
                      {t(`summaryScreenOneApp.nextsteps_link_${step}`)}
                    </Link>
                  )}
                </Typography>
              </li>
            ))}
          </ul>
        </Grid>
        <Grid container item xs={12} className={classes.container} justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <LoadingButtonContainer
              color="secondary"
              text={t('summaryScreenOneApp.toOverview')}
              onClick={redirectToRoot}
              classes={classes.button}
            />
          </Grid>
          <Grid item container xs={12} sm={6} justifyContent="flex-end">
            <LoadingButtonContainer
              color="primary"
              text={t('summaryScreenOneApp.delete')}
              classes={classes.button}
              onClick={toggleDeleteOneAppRequest}
            />
          </Grid>
        </Grid>
      </Grid>

      <DeleteOneAppCreditRequest
        open={openDeleteOneAppConfirmModal}
        requestIdentifier={projectName}
        onConfirm={handleConfirmDeleteOneAppRequest}
        onCancel={toggleDeleteOneAppRequest}
      />
    </>
  );
}
