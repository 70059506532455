import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Link } from '@material-ui/core';
import { LoadingButtonContainer } from 'components/LoadingButton';

export default function CumulativeDeclarationOfAid() {
  const { t } = useTranslation();

  return (
    <Grid container item className="formAttachmentSection">
      <Typography variant="h4">{t('oneApp.ProjectDetails.cumulativeDeclarationHeading')}</Typography>

      <Typography variant="body1" className="row">
        {t('oneApp.ProjectDetails.cumulativeDeclarationText')}
      </Typography>

      <Grid container item>
        <Link href={t('oneApp.ProjectDetails.cumulativeDeclarationLink1')} target="_blank">
          <LoadingButtonContainer
            color="primary"
            type="button"
            text={t('oneApp.ProjectDetails.cumulativeDeclarationLinkText1')}
            classes="link"
            data-testid="cumulativeDeclarationLink1"
          />
        </Link>
      </Grid>
      <Link href={t('oneApp.ProjectDetails.cumulativeDeclarationLink2')} target="_blank">
        <LoadingButtonContainer
          color="primary"
          type="button"
          text={t('oneApp.ProjectDetails.cumulativeDeclarationLinkText2')}
          classes="link"
          data-testid="cumulativeDeclarationLink2"
        />
      </Link>
    </Grid>
  );
}
