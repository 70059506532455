export default [
  { id: 101, title: 'Schleswig-Holstein' },
  { id: 102, title: 'Hamburg' },
  { id: 103, title: 'Niedersachsen' },
  { id: 104, title: 'Bremen' },
  { id: 105, title: 'Nordrhein-Westfalen' },
  { id: 106, title: 'Hessen' },
  { id: 107, title: 'Rheinland-Pfalz' },
  { id: 108, title: 'Baden-Württemberg' },
  { id: 109, title: 'Bayern' },
  { id: 110, title: 'Saarland' },
  { id: 111, title: 'Berlin' },
  { id: 112, title: 'Brandenburg' },
  { id: 113, title: 'Mecklenburg-Vorpommern' },
  { id: 114, title: 'Sachsen' },
  { id: 115, title: 'Sachsen-Anhalt' },
  { id: 116, title: 'Thüringen' },
  { id: 190, title: 'Deutschland' },
  { id: 201, title: 'Albanien' },
  { id: 202, title: 'Andorra' },
  { id: 203, title: 'Belgien' },
  { id: 204, title: 'Bulgarien' },
  { id: 205, title: 'Dänemark' },
  { id: 206, title: 'Färöer' },
  { id: 207, title: 'Finnland' },
  { id: 208, title: 'Frankreich' },
  { id: 209, title: 'Gibraltar' },
  { id: 210, title: 'Griechenland' },
  { id: 211, title: 'Großbritannien' },
  { id: 212, title: 'Irland Republik' },
  { id: 213, title: 'Island' },
  { id: 214, title: 'Italien' },
  { id: 215, title: 'ehem. Jugoslawien' },
  { id: 216, title: 'Luxemburg' },
  { id: 217, title: 'Niederlande' },
  { id: 218, title: 'Norwegen' },
  { id: 219, title: 'Österreich' },
  { id: 220, title: 'Polen' },
  { id: 221, title: 'Portugal' },
  { id: 222, title: 'Rumänien' },
  { id: 223, title: 'Schweden' },
  { id: 224, title: 'Schweiz' },
  { id: 225, title: 'ehem. Sowjetunion' },
  { id: 226, title: 'Spanien' },
  { id: 227, title: 'ehem. Tschechoslowakei' },
  { id: 228, title: 'Türkei' },
  { id: 229, title: 'Ungarn' },
  { id: 230, title: 'Vatikanstadt' },
  { id: 231, title: 'Zypern' },
  { id: 232, title: 'Liechtenstein' },
  { id: 233, title: 'Monaco' },
  { id: 234, title: 'San Marino' },
  { id: 235, title: 'Nordmazedonien (vormals Mazedonien)' },
  { id: 240, title: 'Malta' },
  { id: 241, title: 'Estland' },
  { id: 242, title: 'Lettland' },
  { id: 243, title: 'Litauen' },
  { id: 244, title: 'Moldau' },
  { id: 245, title: 'Russische Föderation' },
  { id: 246, title: 'Ukraine' },
  { id: 247, title: 'Weissrussland/Belarus' },
  { id: 248, title: 'Kroatien' },
  { id: 249, title: 'Slowenien' },
  { id: 250, title: 'Tschechische Republik' },
  { id: 251, title: 'Slowakische Republik' },
  { id: 252, title: 'Bosnien-Herzegowina' },
  { id: 256, title: 'Guernsey' },
  { id: 257, title: 'Jersey' },
  { id: 258, title: 'Isle of Man' },
  { id: 264, title: 'Montenegro' },
  { id: 265, title: 'Serbien' },
  { id: 267, title: 'Kosovo' },
  { id: 289, title: 'Zypern (Nord)' },
  { id: 301, title: 'Ägypten' },
  { id: 302, title: 'Algerien' },
  { id: 304, title: 'Libyen' },
  { id: 305, title: 'Marokko' },
  { id: 306, title: 'Sudan' },
  { id: 307, title: 'Tunesien' },
  { id: 321, title: 'Benin' },
  { id: 322, title: 'Cote d`Ivoire' },
  { id: 323, title: 'Gambia' },
  { id: 324, title: 'Ghana' },
  { id: 325, title: 'Guinea' },
  { id: 326, title: 'Cabo Verde' },
  { id: 327, title: 'Liberia' },
  { id: 328, title: 'Mali' },
  { id: 329, title: 'Mauretanien' },
  { id: 330, title: 'Niger' },
  { id: 331, title: 'Nigeria' },
  { id: 332, title: 'Burkina Faso' },
  { id: 333, title: 'Guinea-Bissau' },
  { id: 334, title: 'Senegal' },
  { id: 335, title: 'Sierra Leone' },
  { id: 336, title: 'Togo' },
  { id: 337, title: 'Tschad' },
  { id: 340, title: 'Südsudan' },
  { id: 341, title: 'Republik Somaliland' },
  { id: 351, title: 'Äquatorialguinea' },
  { id: 352, title: 'Äthiopien' },
  { id: 353, title: 'Angola' },
  { id: 354, title: 'Botsuana' },
  { id: 355, title: 'Brit.Territ.im ind.Ozean' },
  { id: 356, title: 'Burundi' },
  { id: 357, title: 'Dschibuti' },
  { id: 358, title: 'Gabun' },
  { id: 359, title: 'Kamerun' },
  { id: 360, title: 'Kenia' },
  { id: 361, title: 'Komoren' },
  { id: 362, title: 'Republik Kongo' },
  { id: 363, title: 'Lesotho' },
  { id: 364, title: 'Madagaskar' },
  { id: 365, title: 'Malawi' },
  { id: 366, title: 'Mauritius' },
  { id: 367, title: 'Mosambik' },
  { id: 368, title: 'Reunion' },
  { id: 369, title: 'Simbabwe' },
  { id: 370, title: 'Ruanda' },
  { id: 371, title: 'Sambia' },
  { id: 372, title: 'St. Helena' },
  { id: 373, title: 'Sao Tome und Principe' },
  { id: 374, title: 'Seychellen' },
  { id: 375, title: 'Somalia' },
  { id: 376, title: 'Eswatini (vormals Swasiland)' },
  { id: 377, title: 'Südafrika' },
  { id: 378, title: 'Tansania' },
  { id: 379, title: 'Uganda' },
  { id: 380, title: 'Demokratische Republik Kongo' },
  { id: 381, title: 'Zentralafrikanische Republik' },
  { id: 382, title: 'Namibia' },
  { id: 383, title: 'Eritrea' },
  { id: 401, title: 'Kanada' },
  { id: 402, title: 'St. Pierre und Miquelon' },
  { id: 403, title: 'USA' },
  { id: 404, title: 'Grönland' },
  { id: 405, title: 'Curacao' },
  { id: 406, title: 'Bonaire, Saba und St. Eustatius' },
  { id: 407, title: 'Sint Maarten' },
  { id: 408, title: 'Aruba' },
  { id: 421, title: 'Jungferninseln, amerikanisch' },
  { id: 422, title: 'Antigua, Barbuda' },
  { id: 423, title: 'Bahamas' },
  { id: 424, title: 'Barbados' },
  { id: 425, title: 'Bermuda' },
  { id: 426, title: 'Belize' },
  { id: 428, title: 'Costa Rica' },
  { id: 429, title: 'Dominica' },
  { id: 430, title: 'Dominikanische Republik' },
  { id: 431, title: 'El Salvador' },
  { id: 432, title: 'Guadeloupe' },
  { id: 433, title: 'Guatemala' },
  { id: 434, title: 'Haiti' },
  { id: 435, title: 'Honduras' },
  { id: 436, title: 'Jamaika' },
  { id: 437, title: 'Kuba' },
  { id: 438, title: 'Martinique' },
  { id: 439, title: 'Mexico' },
  { id: 440, title: 'Nicaragua' },
  { id: 441, title: 'Panama' },
  { id: 442, title: 'ehem. Panama (Kanal-Zone)' },
  { id: 443, title: 'Trinidad und Tobago' },
  { id: 444, title: 'Anguilla' },
  { id: 445, title: 'Jungferninseln, britisch' },
  { id: 446, title: 'Grenada' },
  { id: 447, title: 'ehem. Niederländische Antillen' },
  { id: 448, title: 'Kaimaninseln' },
  { id: 451, title: 'St.Kitts und Nevis' },
  { id: 454, title: 'St. Lucia' },
  { id: 457, title: 'St. Vincent und die Grenadinen' },
  { id: 461, title: 'Argentinien' },
  { id: 462, title: 'Bolivien' },
  { id: 463, title: 'Brasilien' },
  { id: 464, title: 'Chile' },
  { id: 465, title: 'Ecuador' },
  { id: 466, title: 'Falklandinseln' },
  { id: 467, title: 'Guayana, französisch' },
  { id: 468, title: 'Guyana' },
  { id: 469, title: 'Kolumbien' },
  { id: 470, title: 'Paraguay' },
  { id: 471, title: 'Peru' },
  { id: 472, title: 'Suriname' },
  { id: 473, title: 'Uruguay' },
  { id: 474, title: 'Venezuela' },
  { id: 475, title: 'Turks- und Caicosinseln' },
  { id: 501, title: 'Afghanistan' },
  { id: 503, title: 'Bahrain' },
  { id: 504, title: 'Irak' },
  { id: 505, title: 'Iran' },
  { id: 506, title: 'Israel' },
  { id: 507, title: 'Jemen' },
  { id: 509, title: 'Jordanien' },
  { id: 510, title: 'Katar' },
  { id: 511, title: 'Kuwait' },
  { id: 512, title: 'Libanon' },
  { id: 513, title: 'Oman' },
  { id: 514, title: 'Saudi-Arabien' },
  { id: 515, title: 'Syrien' },
  { id: 519, title: 'Palästinensisch verwaltete Gebiete' },
  { id: 529, title: 'Guam' },
  { id: 531, title: 'Bhutan' },
  { id: 532, title: 'Myanmar' },
  { id: 533, title: 'Brunei Darussalam' },
  { id: 534, title: 'Sri Lanka' },
  { id: 535, title: 'Taiwan' },
  { id: 536, title: 'China' },
  { id: 537, title: 'Hongkong' },
  { id: 538, title: 'Indien' },
  { id: 539, title: 'Indonesien' },
  { id: 540, title: 'Japan' },
  { id: 541, title: 'Kambodscha' },
  { id: 542, title: 'Laos' },
  { id: 543, title: 'Macau' },
  { id: 544, title: 'Malaysia' },
  { id: 545, title: 'Malediven' },
  { id: 546, title: 'Mongolei' },
  { id: 547, title: 'Nepal' },
  { id: 548, title: 'Korea, Demokratische Volksrepublik' },
  { id: 550, title: 'Pakistan' },
  { id: 551, title: 'Philippinen' },
  { id: 554, title: 'Singapur' },
  { id: 555, title: 'Korea, Republik' },
  { id: 556, title: 'Vietnam' },
  { id: 557, title: 'Thailand' },
  { id: 558, title: 'Bangladesch' },
  { id: 559, title: 'Armenien' },
  { id: 560, title: 'Aserbaidschan' },
  { id: 561, title: 'Georgien' },
  { id: 562, title: 'Kasachstan' },
  { id: 563, title: 'Kirgisistan' },
  { id: 564, title: 'Tadschikistan' },
  { id: 565, title: 'Turkmenistan' },
  { id: 566, title: 'Usbekistan' },
  { id: 569, title: 'Timor-Leste' },
  { id: 597, title: 'Vereinigte Arabische Emirate' },
  { id: 601, title: 'Amerikanisch Ozeanien' },
  { id: 602, title: 'Australien' },
  { id: 603, title: 'Kiribati' },
  { id: 604, title: 'Cookinseln' },
  { id: 605, title: 'Fidschi' },
  { id: 606, title: 'Polynesien, französisch' },
  { id: 607, title: 'Nauru' },
  { id: 608, title: 'Neukaledonien' },
  { id: 609, title: 'Neuseeland' },
  { id: 610, title: 'Tokelau-Inseln' },
  { id: 611, title: 'Papua-Neuguinea' },
  { id: 612, title: 'Tonga' },
  { id: 613, title: 'Wallis und Fortuna' },
  { id: 614, title: 'Samoa' },
  { id: 615, title: 'Vanuatu' },
  { id: 616, title: 'Marshall-Inseln' },
  { id: 617, title: 'Niue-Inseln' },
  { id: 618, title: 'Mikronesien' },
  { id: 619, title: 'Palau' },
  { id: 625, title: 'Salomonen' },
  { id: 630, title: 'Tuvalu' },
  { id: 702, title: 'Franz.-Südgebiete' },
];
