import React, { useEffect } from 'react';
import { Field, useField } from 'react-final-form';

import { Grid } from '@material-ui/core';

import { PERSON_TYPE, MAPP_DL } from '../../constants/index';
import Address from './Address';
import BankDetails from './BankDetails';
import CommunityAssociationFinancials from './CommunityAssociationFinancials';
import Foreclosure from './Foreclosure';
import FinancialData from './FinancialData';
import Population from './Population';
import RegistrationData from './RegistrationData';
import ContactDetails from './ContactDetails';
import ApplicantName from './ApplicantName';

import { dispatchMappDLEvent } from '../../components/MappDL';

const applicantInformationItems = {
  [PERSON_TYPE.LOCAL_AUTHORITY]: [
    {
      id: 'applicantName',
      Component: ApplicantName,
    },
    {
      id: 'address',
      Component: Address,
    },
    {
      id: 'contactDetails',
      Component: ContactDetails,
    },
    {
      id: 'registrationData',
      Component: RegistrationData,
    },
    {
      id: 'bankDetails',
      Component: BankDetails,
    },
    {
      id: 'financialData',
      Component: FinancialData,
    },
    {
      id: 'population',
      Component: Population,
    },
    {
      id: 'foreclosure',
      Component: Foreclosure,
    },
  ],
  [PERSON_TYPE.COMMUNITY_ASSOCIATION]: [
    {
      id: 'applicantName',
      Component: ApplicantName,
    },
    {
      id: 'address',
      Component: Address,
    },
    {
      id: 'contactDetails',
      Component: ContactDetails,
    },
    {
      id: 'registrationData',
      Component: RegistrationData,
    },
    {
      id: 'bankDetails',
      Component: BankDetails,
    },
    {
      id: 'financialData',
      Component: FinancialData,
    },
    {
      id: 'communityAssociationFinancials',
      Component: CommunityAssociationFinancials,
    },
    {
      id: 'foreclosure',
      Component: Foreclosure,
    },
  ],
};

const { EVENTS } = MAPP_DL;

export default function ApplicantInformation({ productDetails, productType }: any) {
  const applicantTypeField = useField('applicant.requestApplicant');
  const applicantTypeFieldValue = applicantTypeField.input.value;

  const [possibleSections, setPossibleSections] = React.useState<String[]>();

  useEffect(() => {
    dispatchMappDLEvent(EVENTS.MAPP_INIT_FORM_UPDATE);
  }, [applicantTypeFieldValue]);

  useEffect(() => {
    (async () => {
      if (productDetails) {
        try {
          const i = productDetails;
          const listOfSections = [];
          if (i && i.ikkDisplayedComponents) {
            if (i?.ikkDisplayedComponents?.includes('applicantSelection_name')) {
              listOfSections.push('applicantName');
            }
            if (i?.ikkDisplayedComponents?.includes('applicantSelection_address')) {
              listOfSections.push('address');
            }
            if (i?.ikkDisplayedComponents?.includes('applicantSelection_contactDetails')) {
              listOfSections.push('contactDetails');
              listOfSections.push('registrationData');
            }
            if (i?.ikkDisplayedComponents?.includes('applicantSelection_bankDetails')) {
              listOfSections.push('bankDetails');
            }
            if (i?.ikkDisplayedComponents?.includes('applicantSelection_financialData')) {
              listOfSections.push('financialData');
            }
            if (i?.ikkDisplayedComponents?.includes('applicantSelection_municipalDetails')) {
              listOfSections.push('communityAssociationFinancials');
              listOfSections.push('population');
            }
            if (i?.ikkDisplayedComponents?.includes('applicantSelection_foreclosure')) {
              listOfSections.push('foreclosure');
            }
            setPossibleSections(listOfSections);
          }
        } catch (err) {
          return null;
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails, applicantTypeFieldValue]);

  return (
    <Field name="applicant.requestApplicant" subscription={{ value: true }}>
      {({ input }) => {
        if (!input.value) {
          return null;
        }
        return (
          <Grid container>
            {applicantInformationItems[input.value].map(({ id, Component }) => {
              if (possibleSections?.includes(id))
                return (
                  <Grid item xs={12} key={id}>
                    <Component productType={productType} productDetails={productDetails} />
                  </Grid>
                );
              return <></>;
            })}
          </Grid>
        );
      }}
    </Field>
  );
}
