import React, { useCallback } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import { FormHelperText, Grid } from '@material-ui/core';

import { isEmpty, hasAllowedCharsOnly } from 'util/formUtils';
import ToolTipIcon from 'components/fields/ToolTipIcon';

import { makeStyles } from '@material-ui/core/styles';
import { getErrorProps } from '../../helpers/formFieldHelpers';

const useStyles = makeStyles(() => ({
  helperText: {
    fontSize: '1.2rem',
  },
  helperTextError: {
    fontSize: '1.2rem',
    color: '#ca0036',
  },
}));

const FormAreaTextField = ({
  name,
  validate,
  minRows,
  maxLength,
  className,
  required,
  tooltip,
  pattern,
  patternErrMsg,
  'data-testid': dataTestId,
  skipDefaultValidation,
  ...props
}: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const validateHandler = useCallback(
    (value, allValues, meta) => {
      let hasError;

      if (!skipDefaultValidation) {
        if (required) {
          hasError = isEmpty(value);
          if (hasError) return 'validation.emptyField';
        }

        if (value && value.length > 0) {
          if (!hasAllowedCharsOnly(value)) {
            hasError = 'validation.onlyAllowedCharacters';
            if (hasError) return hasError;
          }

          if (pattern) {
            hasError = !new RegExp(pattern).test(value);
            if (hasError) return patternErrMsg || true;
          }
        }
      }

      if (validate) return validate(value, allValues, meta);

      return false;
    },
    [pattern, patternErrMsg, required, validate, skipDefaultValidation],
  );

  return (
    <Field
      name={name}
      validate={validateHandler}
      subscription={{ value: true, modified: true, error: true, submitFailed: true }}
      render={({ input, meta }) => (
        <Grid container>
          <Grid container item xs={11}>
            <TextField
              {...props}
              {...input}
              required={required}
              {...getErrorProps(t, meta.error, meta.modified, meta.submitFailed)}
              data-testid={dataTestId}
              multiline
              fullWidth
              variant="outlined"
              rows={minRows}
              maxLength={maxLength}
              className={className}
            />
          </Grid>
          {tooltip && (
            <Grid item xs={1} className="tooltip">
              <ToolTipIcon tip={tooltip} />
            </Grid>
          )}
          <Grid container item xs={11}>
            <FormHelperText className={classes.helperText}>{`${input.value.length}/${maxLength}`}</FormHelperText>
          </Grid>
          {input.value.length === maxLength && <br /> && (
            <Grid container item xs={11}>
              <FormHelperText className={classes.helperTextError}>{t('validation.maximumCharacters')}</FormHelperText>
            </Grid>
          )}
        </Grid>
      )}
    />
  );
};

export default React.memo(FormAreaTextField);
