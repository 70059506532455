import React from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, Grid, makeStyles } from '@material-ui/core';

import ToolTipIcon from 'components/fields/ToolTipIcon';
import { useField } from 'react-final-form';
import FormGroup from '@material-ui/core/FormGroup';
import FormCheckbox from './FormCheckbox';

const FormCheckboxGroup = ({
  options,
  label,
  name,
  tooltip,
  tipList,
  tipEnd,
  validate,
  errorMsg,
  onChange,
  defaultValue,
  required,
}: any) => {
  const field = useField(name);

  const useStyles = makeStyles(() =>
    createStyles({
      errorMessage: {
        color: '#ca0036',
        marginTop: '5px',
      },
    }),
  );
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item container lg={7} alignItems="center">
        <Grid item xs={10}>
          <Typography variant="body1">{label}</Typography>
        </Grid>
        {tooltip && (
          <Grid container item xs={1} className="tooltip">
            <ToolTipIcon tip={tooltip} tipList={tipList} tipEnd={tipEnd} />
          </Grid>
        )}
      </Grid>

      <Grid container direction="column">
        <FormGroup onChange={onChange}>
          {Object.keys(options).map((o: string) => (
            <FormCheckbox
              key={o}
              name={name}
              label={options[o]}
              value={o}
              validate={validate}
              data-testid={o}
              defaultValue={defaultValue}
              required={required}
            />
          ))}
        </FormGroup>
      </Grid>
      {errorMsg && field.meta.submitFailed && !field.meta.valid && (
        <Typography variant="body1" className={classes.errorMessage}>
          {errorMsg}
        </Typography>
      )}
    </Grid>
  );
};

export default React.memo(FormCheckboxGroup);
