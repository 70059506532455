/**
 * Mappings for the additional documents
 * IMPORTANT: when new type of document is added you most probably need to add it also in PDF and in Summary.tsx add flag if the new documented type should be printed for given program or not (cca line 115)
 */
// Add program in this enum to display the section for Additional documents: note just the Title is displayed.
export const EnumDetailedOneAppProgramValues = [
  '58',
  '67',
  '148',
  '202',
  '240/241',
  '270',
  '292',
  '293',
  '295',
  '360/361/364',
  '263',
  '365',
  '366',
  '376',
  '380',
  '79/89',
];

// Add G-BzA related program here
export const EnumGbzaOneAppProgramValues = ['202', '240/241', '270', '292', '293', '295', '360/361/364', '380', '263'];

// Add program to this enum to show subtitles on the additional documents section
export const EnumAttachmentOneAppProgramValues = [
  '58',
  '67',
  '148',
  '202',
  '240/241',
  '292',
  '293',
  '295',
  '360/361/364',
  '365',
  '366',
  '376',
  '380',
  '79/89',
];

// Add program here for the document Anlage "Kumulierungserklärung Beihilfen"
export const EnumAttachmentOneAppSubsidyValues = ['293', '380', '148', '202', '79/89'];

// Add program here for the document Anlage "KMU-Erklärung"
export const EnumAttachmentOneAppCompanySizeValues = ['58', '67', '240/241', '292', '293', '295', '365', '366', '380'];

// Add program here for Einwilligung für Auskunfteianfrage
export const EnumAttachmentOneAppConsentRequestValues = ['58', '67', '360/361/364', '365', '366', '376', '79/89'];

// Add program here for document Anlage "Ergänzende Angaben KfW-Sonderprogramm UBR 2022"
export const EnumSpecialProgramUBR = ['79/89'];
