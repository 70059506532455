import 'react-app-polyfill/ie11';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormSpy } from 'react-final-form';
import { scroller } from 'react-scroll';
import classnames from 'classnames';
import { useHistory, useLocation } from 'react-router';
import qs from 'qs';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { LoadingButtonContainer } from 'components/LoadingButton';

import { useSnackbarContext } from 'common/context/snackbarContext';
import { getSession, submitOneAppCreditRequest } from 'common/api/apiClient';
import { ONE_APP_SUMMARY } from 'common/constants/routes';
import { MAPP_DL } from 'common/constants';

import { getInvalidFields, getAllInputs, trimSpaces, removeEmpty, getInvalidFieldsError } from 'util/formUtils';
import ContentProgressBar from '../components/layout/ContentProgressBar';
import { useOneAppContext } from '../context/OneAppContext';
import menuItems from '../helpers/menuItems';
import setFieldError from '../mutators/setFieldError';
import { getProgramById, getFundingPurposeById } from './Program/programLogic';
import FormCheckbox from '../components/form/FormCheckbox';
import { validators } from './formValidation';
import { updateMappDLDetailsPage, dispatchMappDLEvent, getViewportSize } from '../../../components/MappDL';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      alignItems: 'center',
    },
  },
  progressBarContainer: {
    zIndex: 100,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '120px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '120px',
    },
    [theme.breakpoints.down('md')]: {
      order: 1,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '170px',
    },
  },
  formContentContainer: {
    maxWidth: '1175px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: '200px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
    },
  },
  submitButton: {
    marginTop: '40px',
    minWidth: '152px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '30px',
      float: 'right',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  section: {
    width: '100%',
    padding: '50px 0',
    [theme.breakpoints.up('sm')]: {
      '& > p': {
        width: '100%',
      },
    },
  },
  lastSection: {
    width: '100%',
    padding: '50px 0 0 0',
    [theme.breakpoints.up('sm')]: {
      '& > p': {
        width: '100%',
      },
    },
  },
  '@keyframes scrollAnimation': {
    '100%': { opacity: '0.1' },
    '75%': { opacity: '0.2' },
    '60%': { opacity: '0.4' },
    '25%': { opacity: '0.6' },
    '0%': { opacity: '0.8' },
  },
  scrollContainer: {
    position: 'absolute',
    top: '80vh',
    right: '15vw',
    display: 'block',
    opacity: '1',
    [theme.breakpoints.down('md')]: {
      right: '10vw',
    },
    [theme.breakpoints.down('sm')]: {
      right: '8vw',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  scrollIndicator: {
    position: 'absolute',
  },
  scrollArrow: {
    opacity: '0',
    position: 'absolute',
    border: 'solid #1b618c',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    padding: '5px',
    transform: 'rotate(45deg)',
    animation: '$scrollAnimation 1500ms linear',
    animationIterationCount: '5',
    '&:nth-child(2)': {
      top: '10px',
      animationDelay: '125ms',
    },
    '&:last-child': {
      top: '20px',
      animationDelay: '250ms',
    },
    [theme.breakpoints.up('md')]: {
      padding: '8px',
    },
  },
  scrollDisappear: {
    opacity: '1',
    transition: 'opacity 1s',
  },
  thankYouNote: {
    marginBottom: '30px',
    fontSize: '18px',
    '&.MuiTypography-body1': {
      color: '#4e6e61',
    },
  },
  noRobot: {
    justifyContent: 'flex-end',
    '& > div': {
      justifyContent: 'flex-end',
      paddingRight: '1.5rem',
    },
  },
}));

const { EVENTS, VALUES } = MAPP_DL;

const OneAppForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { addError } = useSnackbarContext();
  const { request, setRequest, loading, setLoading } = useOneAppContext();
  const history = useHistory();
  const { search } = useLocation();
  const { programId, fundingPurpose } = qs.parse(search, { ignoreQueryPrefix: true });

  // get programs based on the url parameter
  const urlProgram: any = useMemo(() => {
    if (programId) {
      let program;

      if (typeof programId === 'string') {
        program = programId;
      } else if (Array.isArray(programId)) {
        const firstIndex = 0;
        program = programId[firstIndex];
      }

      // filter out query params that are not string, get related program and it's id and filter out all undefined
      return getProgramById(program as string);
    }
  }, [programId]);

  // get fundingPurpose based on the url parameter
  const urlFundingPurpose: any = useMemo(() => {
    if (fundingPurpose) {
      let purpose;

      if (typeof fundingPurpose === 'string') {
        purpose = fundingPurpose;
      } else if (Array.isArray(fundingPurpose)) {
        const firstIndex = 0;
        purpose = fundingPurpose[firstIndex];
      }

      // filter out query params that are not string, get related funding purpose
      return getFundingPurposeById(purpose as string);
    }
  }, [fundingPurpose]);

  const initialValues = {
    program: {
      duration: 1,
      freeYears: 1,
      fixedInterestPeriod: 1,
      program: urlProgram && urlProgram.id,
    },
    applicant: {
      addressing: 'none',
      academicTitle: 'none',
      country: { id: 190, title: 'Deutschland', firstLetter: 'D' },
      registrationType: 'none',
      certificationType: 'none',
      idType: 'none',
      companyEmployeesOverThirty: 0,
      companyEmployeesUptoThirty: 0,
      companyEmployeesUptoTwenty: 0,
      companyEmployees450Jobber: 0,
      companyEmployeesParentalLeave: 0,
      companyEmployeesTrainee: 0,
    },
  };

  const [invalidSections /* , setInvalidSections */] = useState<string[]>([]);
  const [firstError, setFirstError] = useState<string>('');
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [session, setSession] = useState<string>('');
  const [mappDLErrors, setMappDLErrors] = useState<string>('');

  useEffect(() => {
    async function getCurrentSession() {
      const session = await getSession();
      setSession(session.session);
    }
    getCurrentSession();
  }, []);

  useEffect(() => {
    window.mappDL = window.mappDL || {};

    const viewportSize = getViewportSize();
    const valuesToUpdate: { [key: string]: string } = { pp5: `Viewport ${viewportSize}` };

    if (urlProgram) {
      valuesToUpdate.ep7 = urlProgram.title;
      valuesToUpdate.ep9 = urlProgram.title;
    }

    if (urlFundingPurpose) {
      valuesToUpdate.ep6 = urlFundingPurpose.title;
      valuesToUpdate.ep8 = urlFundingPurpose.title;
    }

    const mappDLDetailsPage = updateMappDLDetailsPage(valuesToUpdate);
    window.mappDL = mappDLDetailsPage;
    dispatchMappDLEvent(EVENTS.MAPP_DL_READY);
  }, [urlProgram, urlFundingPurpose]);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (request && request.id && request.requestId) {
      history.push(ONE_APP_SUMMARY);
      window.scrollTo(0, 0);
    }
  }, [history, request]);

  const allInputs = getAllInputs();

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (firstError && submitted) {
      scroller.scrollTo(firstError, {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: -120,
      });
      setSubmitted(false);
    }
  }, [firstError, submitted]);

  useEffect(() => {
    if (mappDLErrors.length) {
      window.mappDL.ep10 = mappDLErrors;

      dispatchMappDLEvent(EVENTS.MAPP_INIT_FORM_ERROR);
    }
  }, [mappDLErrors]);

  const handleErrorChange = (data: any) => {
    const { errors, submitFailed, dirtyFieldsSinceLastSubmit } = data;
    if (submitFailed) {
      const { firstError /* invalidSections */, invalidFields } = getInvalidFields(errors, allInputs);

      // do not scroll to honeypot field
      if (firstError?.name === 'requestInformation.requestDetails') {
        setFirstError('');
        return;
      }

      if (Object.keys(dirtyFieldsSinceLastSubmit).length === 0) {
        setFirstError(firstError ? firstError.name : '');
      }

      if (Object.keys(dirtyFieldsSinceLastSubmit).length === 0 && submitted && firstError) {
        const invalidFieldsError = getInvalidFieldsError(invalidFields, t).join(';');
        setMappDLErrors(invalidFieldsError);
      }
      // setInvalidSections(invalidSections);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);

      const programData = JSON.parse(JSON.stringify({ ...data.program, program: data.program.program }));
      delete programData?.fundingPurpose?.usagePurposeIds;
      delete programData?.usagePurpose?.programIds;

      const request = {
        ...data,
        program: programData,
        session,
      };

      const ids = await submitOneAppCreditRequest(trimSpaces(removeEmpty(request)));
      setRequest({ ...request, id: ids.id, requestId: ids.requestId });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      mutators={{ setFieldError }}
      subscription={{ invalid: true, values: true }}
      onSubmit={onSubmit}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, invalid, values }) => {
        const handleOnSubmit = (e: React.SyntheticEvent) => {
          handleSubmit(e);

          if ((values as any)?.requestInformation?.requestDetails) {
            addError(t('oneApp.validation.somethingWrong'));
            setSubmitted(true);
            return;
          }

          if (invalid) {
            addError(t('oneApp.validation.invalidForm'));
            setSubmitted(true);
          }

          if (!invalid) {
            const customEcommerceParameter: { [key: number]: string } = {};
            const { program } = values as any;

            if (program) {
              const isFundingUpdated =
                program?.fundingPurpose === null || program?.fundingPurpose?.title !== urlFundingPurpose?.title;
              const isProgramUpdated = program?.program !== urlProgram?.id;

              if (isFundingUpdated) {
                customEcommerceParameter[8] = program.fundingPurpose?.title || VALUES.NOT_APPLICABLE;
              }

              if (isProgramUpdated) {
                const newProgram = getProgramById(program.program);
                customEcommerceParameter[9] = newProgram?.title || VALUES.NOT_APPLICABLE;
              }
            }

            window.wts.push([
              'send',
              'click',
              {
                linkId: 'webtrekk_ignore',
                customEcommerceParameter,
              },
            ]);

            window.wts.push(['formTrackSubmit']);
            window.wts.push(['send', 'form']);
          }
        };

        return (
          <Grid container item className={classes.formContainer} xs={12} justifyContent="center">
            <Grid container item xs={12} md={10} sm={10} alignItems="flex-end">
              <form onSubmit={handleOnSubmit} noValidate className={classes.formContentContainer}>
                {menuItems.map(({ id, Component }, index) => (
                  <Grid
                    container
                    item
                    key={id}
                    className={index + 1 === menuItems.length ? classes.lastSection : classes.section}
                    id={id}
                  >
                    <Component next={index + 1 < menuItems.length && menuItems[index + 1].id} />
                  </Grid>
                ))}
                <Typography variant="body1" align="center" className={classes.thankYouNote}>
                  {t('oneApp.endText')}
                </Typography>
                <FormCheckbox
                  name="notARobot"
                  label={t('oneApp.notARobot')}
                  validate={validators.oneAppForm.notARobot}
                  errorMsg={t('oneApp.notARobotError')}
                  data-testid="notARobot-checkbox"
                  containerClassname={classes.noRobot}
                  required
                />
                <Grid container item xs={12} justifyContent="flex-end">
                  <LoadingButtonContainer
                    color="primary"
                    type="submit"
                    loading={loading}
                    text={t('oneApp.buttons.submit')}
                    classes={classes.submitButton}
                    data-testid="submit"
                  />
                </Grid>
                <div className={classnames(classes.scrollContainer, hasScrolled && classes.scrollDisappear)}>
                  <div className={classes.scrollIndicator}>
                    <div className={classes.scrollArrow} />
                    <div className={classes.scrollArrow} />
                    <div className={classes.scrollArrow} />
                  </div>
                </div>
              </form>
            </Grid>
            <Grid container item xs={12} md={2} className={classes.progressBarContainer} role="navigation">
              <FormSpy
                subscription={{
                  errors: true,
                  submitFailed: true,
                  dirtyFieldsSinceLastSubmit: true,
                }}
                onChange={handleErrorChange}
              />
              <ContentProgressBar invalidSections={invalidSections} />
            </Grid>
          </Grid>
        );
      }}
    </Form>
  );
};

export default React.memo(OneAppForm);
