import React, { createContext, useContext, useState, useCallback } from 'react';

interface Message {
  type: 'info' | 'success' | 'warning' | 'error';
  text: string | JSX.Element;
  messageType?: 'updateRequestDraftValidation';
}

interface Props {
  messages: Message[];
  message?: Message;
  addInfo: (text: string | JSX.Element, messageType?: string) => void;
  addSuccess: (text: string | JSX.Element, messageType?: string) => void;
  addWarning: (text: string | JSX.Element, messageType?: string) => void;
  addError: (text: string | JSX.Element, messageType?: string) => void;
  clearMessagesByType: (messageType: string) => void;
  getMessage: () => Message | undefined;
  readMessage: () => void;
}

const defaults: Props = {
  messages: new Array<Message>(),
  addInfo: () => {},
  addSuccess: () => {},
  addWarning: () => {},
  addError: () => {},
  clearMessagesByType: () => {},
  getMessage: () => undefined,
  readMessage: () => {},
};

export const StateContext = createContext(defaults);
/*
  USAGE
  import { useSnackbarContext } from '../common/context/snackbarContext';

  const { addSuccess, addError } = useSnackbarContext();

  // create error message
  addError('Failed to load data');

*/
const useSnackbarState = () => {
  const [messages, setMessages] = useState<Array<Message>>([]);

  const clearMessagesByType = useCallback((type: string) => {
    setMessages((oldMessages) => oldMessages.filter((m) => m.messageType !== type));
  }, []);

  const addMessage = useCallback(
    (type, text, messageType) => {
      const newMessage = {
        type,
        messageType,
        text,
      };

      if (newMessage.messageType === 'updateRequestDraftValidation') {
        setMessages((oldMessages) => {
          const newMessages = oldMessages.filter((m) => m.messageType !== 'updateRequestDraftValidation');
          return [...newMessages, newMessage];
        });
      } else {
        setMessages((oldMessages) => [...oldMessages, newMessage]);
      }
    },
    [setMessages],
  );

  const addInfo = useCallback(
    (text, messageType) => {
      addMessage('info', text, messageType);
    },
    [addMessage],
  );

  const addSuccess = useCallback(
    (text, messageType) => {
      addMessage('success', text, messageType);
    },
    [addMessage],
  );

  const addWarning = useCallback(
    (text, messageType) => {
      addMessage('warning', text, messageType);
    },
    [addMessage],
  );

  const addError = useCallback(
    (text, messageType) => {
      addMessage('error', text, messageType);
    },
    [addMessage],
  );

  const getMessage = () => {
    return messages[0];
  };

  const message = messages[0];

  const readMessage = useCallback(() => {
    setMessages((oldMessages) => {
      const newMessages = [...oldMessages];
      if (newMessages.length) newMessages.splice(0, 1);
      return newMessages;
    });
  }, []);

  return {
    addInfo,
    addSuccess,
    addWarning,
    addError,
    clearMessagesByType,
    getMessage,
    readMessage,
    message,
    messages,
  };
};

/*
  This only exports function that creates the context
  Its made in a way that every form has its own context
 */

interface ProviderProps {
  children: React.ReactNode;
}

export const SnackbarStateProvider = ({ children }: ProviderProps) => {
  const initialState = useSnackbarState();

  return <StateContext.Provider value={initialState}>{children}</StateContext.Provider>;
};

export const useSnackbarContext = () => useContext(StateContext);
