export const NONE = 'none';
export const BETRIEBSMITTEL_INVESTITIONEN = 'Betriebsmittel und Investitionen';
export const MAX_CREDIT = 100000000;
export const DATE_FORMAT = 'YYYY-MM-DD';

export const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const PROGRAM_CREDIT_TRESHOLD = {
  DEFAULT: 1000000000,
  PROGRAM_78: 800000000,
};

export const PERSONENGESELLSCHAFT = [
  'Gesellschaft bürgerlichen Rechts',
  'Offene Handelsgesellschaft (OHG)',
  'Kommanditgesellschaft (KG)',
  'GmbH und Co. KG',
  'Eingetragene Genossenschaft (eG)',
  'Partenreederei',
  'Eingetragener Verein',
  'Partnerschaftsgesellschaft',
  'AG und Co. OHG',
  'AG und Co. KG',
  'Personengesellschaft Ausland',
  'Stiftung',
  'Versicherungsverein auf Gegenseitigkeit (VVaG)',
  'GmbH und Co. OHG',
  'Limited & Co. KG',
  'eG und CO. KG',
  'UG & Co. KG',
  'Sarl & Co. KG',
  'SE & Co. KG',
  'Stiftung & Co. KG',
  'B.V. & Co. KG',
  'Partnerschaftsges. mit beschr. Berufshaftung',
  'AG und Co. KG nach ausld. Recht',
];
export const KAPITALGESELLSCHAFT = [
  'Gesellschaft m. beschränkter Haftung (GmbH)',
  'Kommanditgesellschaft auf Aktien (KGaA)',
  'Aktiengesellschaft (AG)',
  'GmbH und Co. KGaA',
  'Kapitalgesellschaft Ausland',
  'Europäische Aktiengesellschaft (SE)',
  'Unternehmergesellschaft (haftungsbeschränkt)',
  'SE & Co. KGaA',
  'AG & Co. KGaA',
  'Aktiengesellschaft nach ausländischem Recht',
];
export const EINZELUNTERNEHMER = [
  'Einzelfirma Inland / Selbstständiger / Freiberufler',
  'Einzelfirma Ausland / Selbständiger / Freiberufler',
  'eingetragener Kaufmann (e.K.)',
];
export const STIFTUNG = 'Stiftung';
export const PROGRAM47 = '47';
export const PROGRAM74 = '74';
export const CHECKBOX_FIELDS = [
  'Verwendungszweck',
  'Kreditlaufzeit',
  'Unternehmen entspricht der KMU-Definition*',
  'Datenschutzhinweise',
];

export const PERSON_TYPE = {
  COMPANIES: 'companies',
  SINGLE_ENTERPRENEURS: 'singleEntrepreneur',
};

export const LOCAL_STORAGE_KEYS = {
  IMPORT_ONE_APP_REQUEST: 'importOneAppCreditRequest',
};

export const MAPP_DL = {
  EVENTS: {
    MAPP_DL_READY: 'mappDLReady',
    MAPP_INIT_FORM_UPDATE: 'mappintFormUpdate',
    MAPP_INIT_FORM_ERROR: 'mappintFormError',
  },
  VALUES: {
    NOT_APPLICABLE: 'na',
    CONTENT_ID_FORM: 'foerderassistent|v3.0|vorantrag|vorantrag_start',
    CONTENT_ID_SUMMARY: 'foerderassistent|v3.0|vorantrag|vorantrag_versendet',
    CG_4: 'Inland',
    CG_5: 'IF-Subdomains',
    CG_6: 'Foerderassistent',
  },
};

export default {
  NONE,
  BETRIEBSMITTEL_INVESTITIONEN,
  EINZELUNTERNEHMER,
  MAX_CREDIT,
  DATE_FORMAT,
  KAPITALGESELLSCHAFT,
  PERSONENGESELLSCHAFT,
  PROGRAM47,
  PROGRAM74,
  STIFTUNG,
  CHECKBOX_FIELDS,
  LOCAL_STORAGE_KEYS,
  MAPP_DL,
};
