import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { PERSON_TYPE } from 'common/constants';
import FormTextField from '../../components/form/FormTextField';

type Props = {
  applicantType?: string;
};

const useStyles = makeStyles(() => ({
  subtitle: {
    marginTop: '30px',
  },
}));

export default function CompanyIdentification({ applicantType }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container item className="formSubSection" data-testid="companyIdentification">
      {applicantType === PERSON_TYPE.COMPANIES && (
        <Grid container item xs={12}>
          <Typography variant="h3" className="subheader">
            {t('oneApp.CompanyIdentification.title')}
          </Typography>

          <Typography variant="body1">{t('oneApp.CompanyIdentification.infoText')}</Typography>
        </Grid>
      )}
      {applicantType === PERSON_TYPE.COMPANIES && (
        <Typography variant="h3" className={classnames('subheader', classes.subtitle)}>
          {t('oneApp.CompanyIdentification.subtitle')}
        </Typography>
      )}
      {applicantType === PERSON_TYPE.SINGLE_ENTERPRENEURS && (
        <Typography variant="h3" className="subheader">
          {t('oneApp.CompanyIdentification.subtitle')}
        </Typography>
      )}
      <Grid container item xs={12}>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="applicant.taxId"
            className="formItem"
            label={t('oneApp.CompanyIdentification.taxId')}
            tooltip={t('oneApp.CompanyIdentification.taxId_tooltip')}
            maxLength={14}
            data-testid="taxId"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
