import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import { LoadingButtonContainer } from 'components/LoadingButton';
import classnames from 'classnames';
import FormCheckbox from '../../components/form/FormCheckbox';
import { validators } from '../formValidation';

const useStyles = makeStyles((theme) => ({
  noRobot: {
    justifyContent: 'flex-end',
    '& > div': {
      justifyContent: 'flex-end',
      paddingRight: '1.5rem',
    },
  },
  submitButton: {
    marginTop: '40px',
    minWidth: '152px',
    height: '39px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '30px',
      float: 'right',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  arrowIcon: {
    fontSize: '12px',
  },
}));

export default function SubmitRequest({ loading }: any) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [disabled, setDisabled] = useState<boolean>(true);

  const handleClick = (evt: any) => {
    setDisabled(!evt.target?.checked);
  }

  return (
    <Grid container className="formSection">
      <FormCheckbox
        name="notARobot"
        label={t('ikk.notARobot')}
        validate={validators.ikkForm.notARobot}
        errorMsg={t('ikk.notARobotError')}
        data-testid="notARobot-checkbox"
        containerClassname={classes.noRobot}
        onClick={handleClick}
        required
      />
      <Grid container item xs={12} justifyContent="flex-end">
        <LoadingButtonContainer
          color="primary"
          type="submit"
          loading={loading}
          text={t('ikk.buttons.submit')}
          classes={classes.submitButton}
          data-testid="submitIK"
          disabled={disabled}
          startIcon={<span className={classnames(classes.arrowIcon, 'icArrowsRight')} />}
        />
      </Grid>
    </Grid>
  );
}
