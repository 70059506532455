import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { useField } from 'react-final-form';
import { validators } from '../formValidation';
import FormRadioGroup from '../../components/form/FormRadioGroup';

import GeneralInformation from '../GeneralInformation';
import InfoBox from '../../components/common/InfoBox';

export default function Applicant() {
  const { t } = useTranslation();

  const applicantTypeLookup: { [key: string]: string } = t('oneApp.Applicant.applicantTypeLookup', {
    returnObjects: true,
  });

  const infoLookup: [{ id: string; text: string }] = t('oneApp.Applicant.infoLookup', {
    returnObjects: true,
  });

  const programField = useField('program.program');
  const filteredInfo = infoLookup.find(({ id }) => id === programField.input.value);

  return (
    <Grid container item className="formSection" data-testid="applicant">
      <Typography variant="h2" className="header">
        {t('oneApp.Applicant.title')}
      </Typography>
      {filteredInfo && (
        <InfoBox content={filteredInfo.text} key={filteredInfo.id} data-testid="infocenterRecommendation" />
      )}
      <FormRadioGroup
        name="applicant.applicantType"
        options={applicantTypeLookup}
        label={t('oneApp.Applicant.applicantTypeLabel')}
        tooltip={t('oneApp.Applicant.applicantTypeTooltip')}
        validate={validators.applicant.applicantType}
        errorMsg={t('validation.emptyField')}
      />
      <GeneralInformation />
    </Grid>
  );
}
