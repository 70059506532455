import React from 'react';
import classnames from 'classnames';
import { Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  label: {
    [theme.breakpoints.down('xs')]: {
      wordBreak: 'break-all',
    },
  },
}));

const FormRadio = ({ label, className, validate, 'data-testid': dataTestId, ...props }: any) => {
  const classes = useStyles();

  return (
    <Field
      {...props}
      validate={validate}
      type="radio"
      subscription={{ value: true, modified: true, error: true, submitFailed: true }}
      render={({ input, meta }) => {
        return (
          <FormControlLabel
            className={classnames(className, { error: meta.error && (meta.modified || meta.submitFailed) })}
            control={
              <Radio
                {...props}
                {...input}
                data-testid={dataTestId}
                inputProps={{
                  'aria-labelledby': dataTestId,
                }}
              />
            }
            label={
              typeof label === 'string' ? (
                <Typography variant="body1" id={dataTestId} className={classes.label}>
                  {label}
                </Typography>
              ) : (
                label
              )
            }
          />
        );
      }}
    />
  );
};

export default React.memo(FormRadio);
