import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useField, useForm } from 'react-final-form';
import classnames from 'classnames';
import qs from 'qs';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// @ts-ignore
import { OnChange } from 'react-final-form-listeners';

import { Components } from 'types/models';
import ConfirmationModal from 'pages/oneApp/components/modals/ConfirmationModal';
import { Button } from '@material-ui/core';
import FormRadio from 'pages/oneApp/components/form/FormRadio';

import { ORDER, MAPP_DL } from 'common/constants';
import { useLocation } from 'react-router';
import { formatAutocompleteOptions } from 'util/formatUtils';
import { sortByKey } from 'util/data.utils';
import FormAutocomplete from '../../components/form/FormAutocomplete';
import AdditionalRequestInformation from './AdditionalRequestInformation';
import ProgramCard from './ProgramCard';
import { dispatchMappDLEvent } from '../../../../components/MappDL';

import {
  fundingPurposes,
  usagePurposes,
  programs,
  UsagePurpose,
  Program as ProgramType,
  getProgramById,
  getFundingPurposeById,
  getProgramsByFundingPurpose,
} from './programLogic';
import ProductSelectionModalContent from './ProductSelectionModalContent';

const useStyles = makeStyles(() => ({
  programCard: {
    opacity: '0.7',
    maxWidth: '1024px',
  },
  isSelectedProgram: {
    opacity: '1',
  },
  switchContainer: {
    marginBottom: '20px',
    display: 'flex',
    '& > .MuiFormControlLabel-label': {
      display: 'flex',
      width: '100%',
    },
  },
  selectSubtitle: {
    width: '100%',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  linkStyle: {
    fontSize: '14px',
    textDecoration: 'underline',
    color: '#1b618c !important',
    textAlign: 'left',
  },
  productDialog: {
    '& .MuiPaper-root': {
      maxWidth: '1250px',
      minHeight: '95%',
    },
  },
}));

const { EVENTS } = MAPP_DL;

export default function Program() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { search } = useLocation();
  const form = useForm();

  const fundingPurposeField = useField('program.fundingPurpose');
  const usagePurposeField = useField('program.usagePurpose');
  const programField = useField('program.program');

  const modalFundingPurposeField = useField('program.modalFundingPurpose');
  const modalUsagePurposeField = useField('program.modalUsagePurpose');
  const modalProgramField = useField('program.modalProgram');

  // TODO unify approach with funding purpose, temporary workaround so we can set program in init values and correct dataPrivacy link is displayed
  const [preselectedProgram] = useState(() => {
    if (programField.input.value) {
      return getProgramById(programField.input.value);
    }
  });

  const { fundingPurpose: fundingPurposeId, productGroup } = qs.parse(search, { ignoreQueryPrefix: true });

  // get funding purpose from the url to the form
  const urlFundingPurpose = useMemo(() => {
    let purpose;

    if (productGroup && typeof productGroup === 'string' && productGroup.toLocaleLowerCase() === 'corona') {
      purpose = getFundingPurposeById('11');
    } else if (fundingPurposeId && typeof fundingPurposeId === 'string') {
      if (!preselectedProgram || preselectedProgram.fundingPurposeIds.includes(+fundingPurposeId)) {
        purpose = getFundingPurposeById(fundingPurposeId);

        if (purpose) {
          return formatAutocompleteOptions([purpose])[0] as any;
        }
      }
    }

    return purpose;
  }, [fundingPurposeId, preselectedProgram, productGroup]);

  // set recommended programs based on the set funding purpose and programs from URL
  const [recommendedPrograms, setRecommendedPrograms] = useState<ProgramType[]>(() => {
    if (urlFundingPurpose) {
      return sortByKey(getProgramsByFundingPurpose(urlFundingPurpose.id), 'id', ORDER.ASC);
    }

    return preselectedProgram ? [preselectedProgram] : [];
  });

  useEffect(() => {
    dispatchMappDLEvent(EVENTS.MAPP_INIT_FORM_UPDATE);
  }, [recommendedPrograms]);

  const setSortedRecommendedPrograms = useCallback((programs) => {
    setRecommendedPrograms(sortByKey(programs, 'id', ORDER.ASC, (str: string) => parseInt(str, 10)));
  }, []);

  const [usagePurposeOptions, setUsagePurposeOptions] = useState<UsagePurpose[]>([]);
  const [openProductSelectionModal, setOpenProductSelectionModal] = useState<boolean>(false);
  const [originalPrograms, setOriginalPrograms] = useState<ProgramType[]>(recommendedPrograms);
  const [openFundingPurposeModal, setOpenFundingPurposeModal] = useState<boolean>(false);

  const [newFundingPurpose, setNewFundingPurpose] = useState<Components.Schemas.StrictKeyValueField>(
    fundingPurposeField.input.value,
  );

  // set funding purpose from the url to the form, should be triggered just on the initial render
  useEffect(() => {
    form.change('program.fundingPurpose', urlFundingPurpose);
    if (urlFundingPurpose) {
      const optionIds = fundingPurposes[urlFundingPurpose.id]?.usagePurposeIds as number[];
      let filteredUsagePurposes = optionIds
        ? optionIds.map((id: number) => usagePurposes[id]).filter((option: UsagePurpose) => option && !option.isHidden)
        : [];

      if (preselectedProgram) {
        filteredUsagePurposes = filteredUsagePurposes.filter((usagePurpose) =>
          preselectedProgram.usagePurposeIds.includes(usagePurpose.id),
        );
      }
      setUsagePurposeOptions(sortByKey(filteredUsagePurposes, 'title'));
    }
  }, [urlFundingPurpose, form, preselectedProgram]);

  const fundingPurposesOptions = useMemo(
    () => Object.values(fundingPurposes).filter((purpose) => !purpose.isHidden),
    [],
  );

  const handleFundingPurposeChange = useCallback(
    (selectedFundingPurpose: Components.Schemas.StrictKeyValueField) => {
      fundingPurposeField.input.onChange(selectedFundingPurpose);
      if (selectedFundingPurpose === null || selectedFundingPurpose.id === undefined) {
        setRecommendedPrograms([]);
        setOriginalPrograms([]);
        setUsagePurposeOptions([]);
        programField.input.onChange(null);
      } else {
        const optionIds = fundingPurposes[selectedFundingPurpose.id]?.usagePurposeIds as number[];

        const filteredUsagePurposes = optionIds
          ? optionIds
              .map((id: number) => usagePurposes[id])
              .filter((option: UsagePurpose) => option && !option.isHidden)
          : [];
        setUsagePurposeOptions(sortByKey(filteredUsagePurposes, 'title'));

        const programOptionIds = fundingPurposes[selectedFundingPurpose.id]?.programIds;

        const filteredPrograms: ProgramType[] = programOptionIds
          ? programOptionIds.map((id: string) => programs[id]).filter((option: ProgramType) => option)
          : [];

        setSortedRecommendedPrograms(filteredPrograms);
        setOriginalPrograms([...filteredPrograms]);

        const selectedProgram = programOptionIds.includes(programField.input.value)
          ? programField.input.value
          : undefined;
        programField.input.onChange(selectedProgram);
      }
      usagePurposeField.input.onChange(null);
    },
    [programField.input, usagePurposeField.input, fundingPurposeField.input, setSortedRecommendedPrograms],
  );

  const handleUsagePurposeChange = (selectedUsagePurpose: Components.Schemas.StrictKeyValueField) => {
    if (selectedUsagePurpose === null || selectedUsagePurpose.id === undefined) {
      setSortedRecommendedPrograms([...originalPrograms]);
      modalUsagePurposeField.input.onChange(null);
    } else {
      const optionIds = usagePurposes[selectedUsagePurpose.id]?.programIds;

      const filteredUsagePurposePrograms: ProgramType[] = optionIds
        ? optionIds
            .map((id: string) => programs[id])
            .filter((option: ProgramType) => option)
            .filter((program: ProgramType) =>
              programs[program.id].fundingPurposeIds.includes(fundingPurposeField.input.value.id),
            )
        : [];

      setSortedRecommendedPrograms(filteredUsagePurposePrograms);
      programField.input.onChange(optionIds.includes(programField.input.value) ? programField.input.value : undefined);
    }
  };

  const programSelectHandler = (selectedProgram: Components.Schemas.EnumProgram) => {
    if (selectedProgram) {
      form.change('applicant.companyNumberOfEmployees', undefined);
      const optionIds = programs[selectedProgram]?.usagePurposeIds as number[];

      const uniqueOptionIds = optionIds.reduce(
        (unique: number[], item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
      );

      const filteredUsagePurposes = uniqueOptionIds
        .map((id: number) => usagePurposes[id])
        .filter((option: UsagePurpose) => option && !option.isHidden);

      setUsagePurposeOptions(sortByKey(filteredUsagePurposes, 'title'));
    }
  };

  const openProductSelectionRequest = useCallback(
    (selectedFundingPurpose, selectedUsagePurpose) => {
      modalFundingPurposeField.input.onChange(selectedFundingPurpose);
      modalUsagePurposeField.input.onChange(selectedUsagePurpose);
      setOpenProductSelectionModal(true);
    },
    [modalFundingPurposeField.input, modalUsagePurposeField.input],
  );

  const cancelProductSelectionRequest = useCallback(() => {
    modalFundingPurposeField.input.onChange(null);
    modalUsagePurposeField.input.onChange(null);
    modalProgramField.input.onChange(null);
    setOpenProductSelectionModal(false);
  }, [modalProgramField.input, modalUsagePurposeField.input, modalFundingPurposeField.input]);

  const confirmProductSelectionRequest = useCallback(() => {
    const selectedFundingPurpose = modalFundingPurposeField.input.value;
    const selectedUsagePurpose = modalUsagePurposeField.input.value;
    const selectedProgram = modalProgramField.input.value;

    if (selectedFundingPurpose !== null && selectedFundingPurpose.id !== undefined) {
      const optionIds = fundingPurposes[selectedFundingPurpose.id]?.usagePurposeIds as number[];
      const filteredUsagePurposes = optionIds
        ? optionIds.map((id: number) => usagePurposes[id]).filter((option: UsagePurpose) => option && !option.isHidden)
        : [];
      setUsagePurposeOptions(sortByKey(filteredUsagePurposes, 'title'));
    }

    if (selectedUsagePurpose !== null && selectedUsagePurpose.id !== undefined) {
      const optionIds = usagePurposes[selectedUsagePurpose.id]?.programIds;
      const filteredUsagePurposePrograms: ProgramType[] = optionIds
        ? optionIds
            .map((id: string) => programs[id])
            .filter((option: ProgramType) => option)
            .filter((program: ProgramType) =>
              programs[program.id].fundingPurposeIds.includes(selectedFundingPurpose.id),
            )
        : [];

      setSortedRecommendedPrograms(filteredUsagePurposePrograms);
      setOriginalPrograms([...filteredUsagePurposePrograms]);
    } else {
      const programOptionIds = fundingPurposes[selectedFundingPurpose.id]?.programIds;
      const filteredPrograms: ProgramType[] = programOptionIds
        ? programOptionIds.map((id: string) => programs[id]).filter((option: ProgramType) => option)
        : [];

      setSortedRecommendedPrograms(filteredPrograms);
      setOriginalPrograms([...filteredPrograms]);
    }

    form.batch(() => {
      form.change('program.program', selectedProgram);
      form.change('program.fundingPurpose', selectedFundingPurpose);
      form.change('program.usagePurpose', selectedUsagePurpose);
      form.change('program.modalProgram', null);
      form.change('program.modalFundingPurpose', null);
      form.change('program.modalUsagePurpose', null);
    });

    setOpenProductSelectionModal(false);
  }, [
    modalFundingPurposeField.input,
    modalProgramField.input,
    modalUsagePurposeField.input,
    form,
    setSortedRecommendedPrograms,
  ]);

  const handleFundingPurposeModalOpen = useCallback(
    (selectedFundingPurpose: Components.Schemas.StrictKeyValueField) => {
      const program = programField.input.value;
      if (!program) {
        handleFundingPurposeChange(selectedFundingPurpose);
      } else {
        setNewFundingPurpose(selectedFundingPurpose);
        setOpenFundingPurposeModal(true);
      }
    },
    [handleFundingPurposeChange, programField.input.value],
  );

  const cancelFundingPurposeModal = useCallback(() => {
    setOpenFundingPurposeModal(false);
  }, []);

  const confirmFundingPurposeModal = useCallback(
    (newFundingPurpose) => {
      handleFundingPurposeChange(newFundingPurpose);
      setOpenFundingPurposeModal(false);
    },
    [handleFundingPurposeChange],
  );

  const additionalRequestedInformationProperties = {
    handleUsagePurposeChange,
    usagePurposeOptions,
    usagePurposeDisabled: !fundingPurposeField.input.value,
  };

  return (
    <Grid container item className="formSection" data-testid="program">
      <Typography variant="h2" className="header">
        {t('oneApp.Program.title')}
      </Typography>
      <Typography variant="body1" className="textContainer">
        {t('oneApp.Program.infoText')}{' '}
      </Typography>

      <Grid container item xs={12} justifyContent="flex-start" className="formGroup">
        <Grid item xs={12} md={6}>
          <FormAutocomplete
            name="program.fundingPurpose"
            className="formItem"
            label={t('oneApp.Program.fundingPurpose')}
            data={fundingPurposesOptions}
            onChange={handleFundingPurposeModalOpen}
            data-testid="fundingPurpose-autocomplete"
            preventDefaultOnChange
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} className="formGroup">
        <Grid container direction="column" item justifyContent="center" data-testid="programRecommendation">
          {recommendedPrograms.length > 0 ? (
            <>
              <Typography variant="h3" className="header">
                {t('oneApp.ProgramRecommendation.title')}
              </Typography>

              <Grid container item>
                <OnChange name="program.program">
                  {(value: any) => {
                    programSelectHandler(value);
                  }}
                </OnChange>
                {recommendedPrograms.map((program: ProgramType) => (
                  <Grid item xs={12} sm={12} key={program.id}>
                    <FormRadio
                      className={classes.switchContainer}
                      name="program.program"
                      value={program.id}
                      label={
                        <ProgramCard
                          program={program}
                          className={classnames(classes.programCard, {
                            [classes.isSelectedProgram]: programField.input.value === program.id,
                          })}
                        />
                      }
                      data-testid={`program${program.id}-radio`}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <Typography variant="body1" className="textContainer">
              {t('oneApp.ProgramRecommendation.selectProduct')}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="flex-start" className="formGroup">
        <Grid item xs={12} md={6}>
          <Typography variant="body1" className="textContainer">
            {t('oneApp.ProgramRecommendation.noProductFound')}
          </Typography>
          <Button
            className={classes.linkStyle}
            type="button"
            onClick={() => openProductSelectionRequest(fundingPurposeField.input.value, usagePurposeField.input.value)}
          >
            {t('oneApp.ProgramRecommendation.changeProduct')}
          </Button>
        </Grid>
      </Grid>
      <AdditionalRequestInformation {...additionalRequestedInformationProperties} />
      <ConfirmationModal
        id="product-selection-one-app-modal"
        content={<ProductSelectionModalContent />}
        open={openProductSelectionModal}
        header={t('oneApp.ProgramModal.title')}
        confirmButton={t('oneApp.ProgramModal.confirm')}
        className={classes.productDialog}
        onConfirm={confirmProductSelectionRequest}
        onCancel={cancelProductSelectionRequest}
        cancelButton={t('oneApp.ProgramModal.cancel')}
        wideButtonLayout
      />
      <ConfirmationModal
        id="funding-purpose-one-app-modal"
        open={openFundingPurposeModal}
        header=""
        content="Die Änderung des Förderzweckes setzt Ihre bereits getroffene Produktauswahl zurück. Möchten Sie fortfahren?"
        onConfirm={() => confirmFundingPurposeModal(newFundingPurpose)}
        onCancel={() => cancelFundingPurposeModal()}
      />
    </Grid>
  );
}
