import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import FormRadioGroup from '../../components/form/FormRadioGroup';
import FormCheckbox from '../../components/form/FormCheckbox';
import { validators } from '../formValidation';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  checkboxMargin: {
    marginBottom: '2rem',
  },
  pageTitle: {
    fontSize: '45px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '38px',
      paddingBottom: '20px !important',
    },
  },
  list: {
    marginLeft: '2rem',
    listStyleType: 'disc',
  },
  requestIdentifierWrapper: {
    width: '100%',
  },
  requestIdentifier: {
    width: '100%',
  },
  inputInfoText: {
    padding: '20px 0 10px 0',
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
}));

// Visible when 'productSpecific_renovationConcept'
// TODO: These are not available yet:
// and additionally when one InvestitionsBetragZuVerwedungszweck of the following purposes is not null
// Personalkosten Sanierungsmanager (key-id 461) - id - 1
// Verstetigung Sanierungsmanager Personalkosten (key-id 548) - id - 3

// When visible, at least one choice is mandatory. Error messsage: "Bitte wahlen Sie mindestens eine Quailifikation aus."
export default function RemediationManagement() {
  const { t } = useTranslation();
  const classes = useStyles();
  const additionsLookup: { [key: string]: string } = t('ikk.RemediationManagement.remediationsLookup', {
    returnObjects: true,
  });
  const [additions, setAdditions] = React.useState<any>(null);

  const onAdditionsChange = (evt: Event, value: any) => {
    setAdditions(value);
  };

  return (
    <Grid container item className="formSubSection" data-testid="implementationDuration">
      <Typography variant="h3">{t('ikk.RemediationManagement.title')}</Typography>
      <Typography variant="body1">{t('ikk.RemediationManagement.infoText1')}</Typography>
      <Grid container item xs={12} justifyContent="center" className="formGroup">
        <Grid container className={classes.checkboxMargin}>
          <FormCheckbox
            name="remediationManagement.qualification"
            label={t('ikk.RemediationManagement.qualification')}
            validate={validators.ikkForm.notARobot}
            data-testid="remediationManagement.qualification"
            errorMsg={t('ikk.RemediationManagement.qualificationError')}
          />
        </Grid>
        <Grid container className={classes.requestIdentifierWrapper}>
          <FormRadioGroup
            name="remediationManagement.additions"
            options={additionsLookup}
            onChange={onAdditionsChange}
            validate={validators.applicant.applicantType}
            errorMsg={t('validation.emptyField')}
            defaultValue={additions}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
