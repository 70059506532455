import 'react-app-polyfill/ie11';
import React, { useEffect, useState } from 'react';
import { scroller } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { Form, FormSpy } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { submitIKKCreditRequest, getSession } from 'common/api/apiClient';
import { getInvalidFields, getAllInputs, trimSpaces, removeEmpty, getInvalidFieldsError } from 'util/formUtils';
import { useHistory } from 'react-router';
import { IKK_SUMMARY } from 'common/constants/routes';
import { getIKKProductDetails } from 'common/api/apiClientYggs';
import { useSnackbarContext } from 'common/context/snackbarContext';
import { MAPP_DL } from 'common/constants';
import setFieldError from '../mutators/setFieldError';
import ContentProgressBar from '../components/layout/ContentProgressBar';
import IKKStyles from '../IKKStyles';
import { useIKKContext } from '../context/IKKContext';
import { menuItems as contentMenuItems } from '../helpers/menuItems';
import { dispatchMappDLEvent, updateMappDLDetailsPage, getViewportSize } from '../../../components/MappDL';
import useStyles from './IKKForm.styles';

const urlParams: { [key: string]: string | null } = {};

(() => {
  const urlP = new URLSearchParams(document.location.search);
  const keys = urlP.keys();
  let p: IteratorResult<string, any>;
  // eslint-disable-next-line no-cond-assign
  while ((p = keys.next()) && !p.done) {
    urlParams[p.value] = urlP.get(p.value);
  }
})();

const { EVENTS } = MAPP_DL;

const IKK = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const { addError } = useSnackbarContext();
  const { request, setRequest, loading, setLoading } = useIKKContext();

  const [session, setSession] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [firstError, setFirstError] = useState<string>('');
  const [mappDLErrors, setMappDLErrors] = useState<string>('');

  const [applicantType, setApplicantType] = useState<any>(null);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [productDetails, setProductDetails] = useState<any>({});
  const [productType, setProductType] = useState<any>(null);

  const initialValues = {
    applicant: { country: { id: 190, title: 'Deutschland', firstLetter: 'D' } },
    project: { country: { title: 'Deutschland', firstLetter: 'D' } },
    productSpecific: {
      chargingStation: {
        country: { id: 190, title: 'Deutschland', firstLetter: 'D' },
        totalChargingPoints: 0,
      },
    },
    requestInformation: {},
  };

  useEffect(() => {
    async function getCurrentSession() {
      const session = await getSession();
      setSession(session.session);
    }
    getCurrentSession();
  }, []);

  useEffect(() => {
    window.mappDL = window.mappDL || {};

    const viewportSize = getViewportSize();
    const valuesToUpdate: { [key: string]: string } = { pp5: `Viewport ${viewportSize}` };

    const mappDLDetailsPage = updateMappDLDetailsPage(valuesToUpdate);
    window.mappDL = mappDLDetailsPage;
    dispatchMappDLEvent(EVENTS.MAPP_DL_READY);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (request && request.requestId) {
      history.push(IKK_SUMMARY);
      window.scrollTo(0, 0);
    }
  }, [history, request]);

  const allInputs = getAllInputs();

  useEffect(() => {
    if (firstError && submitted) {
      scroller.scrollTo(firstError, {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: -120,
      });
      setSubmitted(false);
    }
  }, [firstError, submitted]);

  useEffect(() => {
    if (mappDLErrors.length) {
      window.mappDL.ep10 = mappDLErrors;
      dispatchMappDLEvent(EVENTS.MAPP_INIT_FORM_ERROR);
    }
  }, [mappDLErrors]);

  const categoryKeyLookup: { [key: string]: string } = t('ikk.FindProduct.categories.categoryKeyLookup', {
    returnObjects: true,
  });

  const handleProductTypeChange = (evt: Event, value: any) => {
    setProductType(value);
  };

  const handleApplicantTypeChange = (evt: Event, value: any) => {
    setApplicantType(value);
  };

  const handleSelectedProductChange = async (value: any) => {
    if (value) {
      // without this if statement handleSelectedProductChange is called without value and returns 500
      setSelectedProduct(value);
      const prodDetails = await getIKKProductDetails(value);
      setProductDetails(prodDetails);
    }
  };

  const onSubmit = async (data: any) => {
    const newData = data;
    try {
      setLoading(true);

      if (data.product) {
        if (data.product.id) {
          newData.product.product = productDetails?.productName || '';
        }
      }

      if (newData.projectPurpose && productDetails.fundingPurposes) {
        newData.projectPurpose.purposes = productDetails.fundingPurposes.map((purpose: any) => purpose.intendedUse);
      }

      const request = {
        ...newData,
        session,
      };
      const ids = await submitIKKCreditRequest(trimSpaces(removeEmpty(request)));
      setRequest({ ...request, id: ids.id, requestId: ids.requestId });
    } finally {
      setLoading(false);
    }
  };

  const handleErrorChange = (data: any) => {
    const { errors, submitFailed, dirtyFieldsSinceLastSubmit } = data;
    if (submitFailed) {
      const { firstError /* invalidSections */, invalidFields } = getInvalidFields(errors, allInputs);

      // do not scroll to honeypot field
      if (firstError?.name === 'requestInformation.requestDetails') {
        setFirstError('');
        return;
      }

      if (Object.keys(dirtyFieldsSinceLastSubmit).length === 0) {
        setFirstError(firstError ? firstError.name : '');
      }

      if (Object.keys(dirtyFieldsSinceLastSubmit).length === 0 && submitted && firstError) {
        const invalidFieldsError = getInvalidFieldsError(invalidFields, t).join(';');
        setMappDLErrors(invalidFieldsError);
      }
    }
  };

  const displayProductSpecificSection = () => {
    let flag = true;
    if (
      productDetails.ikkDisplayedComponents?.includes('productSpecific_existingConcepts') ||
      productDetails.ikkDisplayedComponents?.includes('productSpecific_chargingStations') ||
      productDetails.ikkDisplayedComponents?.includes('productSpecific_implementationTime') ||
      productDetails.ikkDisplayedComponents?.includes('productSpecific_renovationConcept')
    ) {
      flag = false;
    }

    return flag;
  };

  return (
    <Form
      initialValues={initialValues}
      mutators={{ setFieldError }}
      subscription={{ invalid: true, values: true }}
      onSubmit={onSubmit}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, invalid, values }) => {
        const handleOnSubmit = (e: React.SyntheticEvent) => {
          handleSubmit(e);

          if ((values as any)?.requestInformation?.requestDetails) {
            addError(t('oneApp.validation.somethingWrong'));
            setSubmitted(true);
            return;
          }

          if (invalid) {
            addError(t('oneApp.validation.invalidForm'));
            setSubmitted(true);
          }

          if (!invalid) {
            const customEcommerceParameter: { [key: number]: string } = {};

            window.wts.push([
              'send',
              'click',
              {
                linkId: 'webtrekk_ignore',
                customEcommerceParameter,
              },
            ]);

            window.wts.push(['formTrackSubmit']);
            window.wts.push(['send', 'form']);
          }
        };

        const selectedProductUsed = selectedProduct || urlParams?.programId || 0;
        const selectedProductTypeUsed = productType || urlParams?.productType || '';
        const invalidSections: string[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < contentMenuItems.length; i++) {
          if (
            (contentMenuItems[i].id === 'consent' && !productDetails.ikkConsentDetails) ||
            (contentMenuItems[i].id === 'attachments' && !productDetails.ikkAttachments) ||
            (contentMenuItems[i].id === 'productSpecificInput' && displayProductSpecificSection())
          ) {
            invalidSections.push(contentMenuItems[i].id);
          }
          if (selectedProductUsed) {
            if (
              categoryKeyLookup[selectedProductTypeUsed] !== categoryKeyLookup.credit &&
              !contentMenuItems[i].isVisibleNoCreditProductSelected
            ) {
              invalidSections.push(contentMenuItems[i].id);
            }
          } else if (!contentMenuItems[i].isVisibleNoProductSelected) {
            invalidSections.push(contentMenuItems[i].id);
          }
        }

        return (
          <Grid container item className={classes.formContainer} xs={12} justifyContent="center">
            <Grid container item xs={12} md={10} sm={10} alignItems="flex-end">
              <form onSubmit={handleOnSubmit} noValidate className={classes.formContentContainer}>
                {contentMenuItems
                  .filter((item) =>
                    // eslint-disable-next-line no-nested-ternary
                    selectedProductUsed
                      ? categoryKeyLookup[selectedProductTypeUsed] === categoryKeyLookup.credit
                        ? true
                        : item.isVisibleNoCreditProductSelected
                      : item.id === 'consent'
                      ? productDetails && productDetails.ikkConsentDetails
                      : item.isVisibleNoProductSelected,
                  )
                  .map(({ id, Component }, index) => (
                    <Grid
                      container
                      item
                      key={id}
                      className={index + 1 === contentMenuItems.length ? classes.lastSection : classes.section}
                      id={id}
                    >
                      <Component
                        urlParams={urlParams}
                        next={index + 1 < contentMenuItems.length && contentMenuItems[index + 1].id}
                        productType={selectedProductTypeUsed}
                        onProductTypeChange={handleProductTypeChange}
                        applicantType={applicantType}
                        onApplicantTypeChange={handleApplicantTypeChange}
                        onSelectedProductChange={handleSelectedProductChange}
                        selectedProduct={selectedProductUsed}
                        productDetails={productDetails}
                        creditAmountMainField="product.requestedAmount"
                        creditAmountShadowField="projectPurpose.creditAmount"
                        financingCreditAmountShadowField="financingPlan.credit"
                        loading={loading}
                      />
                    </Grid>
                  ))}
              </form>
            </Grid>
            <Grid container item xs={12} md={2} className={classes.progressBarContainer} role="navigation">
              <FormSpy
                subscription={{
                  errors: true,
                  submitFailed: true,
                  dirtyFieldsSinceLastSubmit: true,
                }}
                onChange={handleErrorChange}
              />
              <ContentProgressBar invalidSections={invalidSections} />
            </Grid>
          </Grid>
        );
      }}
    </Form>
  );
};

export default withStyles(IKKStyles)(IKK);
