export const POST_CODE_PATTERN = '^[0-9]{5}';
export const PHONE_PATTERN = '^[(+]{0,1}([0-9]+)?([ -]?([0-9]+( [0-9]+)*)[ -]?)?[0-9]+([ -/][0-9]+)*$';
export const EMAIL_PATTERN =
  '^(([a-zA-Z0-9!#$%&\'*+\\/=?^_~-]+(\\.[a-zA-Z0-9!#$%&\'*+\\/=?^_~-]+)*)|(\\".+\\"))@(([a-zA-Z0-9\\-]+\\.)+[a-zA-Z0-9\\-]{2,})$';
export const IBAN_PATTERN = '^[a-zA-Z]{2}[0-9]{20}$';
export const BIC_PATTERN = '^[a-zA-Z0-9]{0,11}$';
export const HOUSE_NUMBER_PATTERN = '^.{1,9}$';

export const PERSON_TYPE = {
  LOCAL_AUTHORITY: 'localAuthority',
  COMMUNITY_ASSOCIATION: 'communityAssoc',
};

export const ACCOUNTING_TYPE = {
  CAMERALISTIC: 'cameralistic',
  DOUBLE: 'double',
};

export enum ATTACHMENT_TYPE {
  APPLICATION_RELEVANT = 'Antragsrelevant',
  AGB = 'AGB',
  DATA_PROTECTION = 'Datenschutzrelevant',
  AFTER_APPLICATION_RELEVANT = 'Nach Antragsstellung relevant',
  INFORMATION = 'Information',
}

export const MAPP_DL = {
  EVENTS: {
    MAPP_DL_READY: 'mappDLReady',
    MAPP_INIT_FORM_UPDATE: 'mappintFormUpdate',
    MAPP_INIT_FORM_ERROR: 'mappintFormError',
  },
  VALUES: {
    NOT_APPLICABLE: 'na',
    CONTENT_ID_FORM: 'foerderassistent|v3.0|vorantrag|vorantrag_start',
    CONTENT_ID_SUMMARY: 'foerderassistent|v3.0|vorantrag|vorantrag_versendet',
    CG_4: 'Inland',
    CG_5: 'IF-Subdomains',
    CG_6: 'Foerderassistent',
  },
};

export const ADDRESS_PROPS = {
  street: {
    maxLength: 60,
    formNoValidate: true,
  },
  houseNumber: {
    formNoValidate: true,
  },
  location: {
    maxLength: 35,
  },
  postcode: {
    maxLength: 5,
  },
};

export default {
  POST_CODE_PATTERN,
  PHONE_PATTERN,
  EMAIL_PATTERN,
  PERSON_TYPE,
  MAPP_DL,
  ADDRESS_PROPS,
};
